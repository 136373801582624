import { createAction } from 'utils/reduxUtil';
import * as actions from 'modules/User/store/userActionTypes';

export const actionInProgress = value =>
  createAction(actions.ACTION_IN_PROGRESS, { value });

export const loadUsersRequest = accountId =>
  createAction(actions.LOAD_USERS_REQUEST, { accountId });
export const loadUsersSuccess = users =>
  createAction(actions.LOAD_USERS_SUCCESS, { users });

export const saveUserRequest = (accountId, user) =>
  createAction(actions.SAVE_USER_REQUEST, { accountId, user });
export const saveUserSuccess = user =>
  createAction(actions.SAVE_USER_SUCCESS, { user });
