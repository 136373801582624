import React, { Component } from 'react';
import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import { GvlMenu } from 'style/commonEmotions';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BACKEND_BASENAME } from 'utils/config';
import * as api from 'utils/api';
import { logoutRequest } from 'containers/app/store/login/loginActions';
import { connect } from 'react-redux';

class AdminMenuComponent extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    logoutRequest: PropTypes.func.isRequired
  };

  async componentDidMount() {
    try {
      // make sure our gvl cookie is still valid
      let gvlCookieValid = await api.legacyValidate();
      if (!gvlCookieValid) {
        this.props.logoutRequest();
      }
    } catch (err) {
      console.error('problem verifying account', err);
    }
  }

  render() {
    return (
      <GvlMenu selectedKeys={['/account']} mode="inline">
        <GvlMenuItem
          key="home"
          link="/"
          isTopLevel={true}
          icon="gvl-home"
          title={intl.get('home')}
        />
        <GvlMenuItem
          key="/account"
          link="/accounts"
          isTopLevel={true}
          icon="rocket"
          title={intl.get('accounts')}
        />
        <GvlMenuItem
          key="/void"
          link="/void"
          isTopLevel={true}
          icon="delete"
          title={intl.get('void.documents')}
        />
        <GvlMenuItem
          key="/support"
          link={`${BACKEND_BASENAME}/home`}
          isTopLevel={true}
          external={true}
          icon="cluster"
          title={intl.get('support.legacy')}
        />
      </GvlMenu>
    );
  }
}

const mapDispatchToProps = {
  logoutRequest
};

const AdminMenu = withRouter(
  connect(null, mapDispatchToProps)(AdminMenuComponent)
);

export { AdminMenu as default, AdminMenuComponent };
