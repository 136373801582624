import React from 'react';
import intl from 'react-intl-universal';
import { Icon } from 'antd';
import { THEME } from 'style';
import { DEPLOY_PLATFORM } from 'utils/config';

export default function SalesNumbersAndHours() {
  return (
    <React.Fragment>
      <h1 style={{ color: THEME.blue() }}>
        {DEPLOY_PLATFORM === 'university' ? (
          <a href="gvluniversity@globalvetlink.com">gvluniversity@globalvetlink.com</a>
        ) : (
          <React.Fragment>
            <Icon type="phone" theme="filled" style={{ paddingRight: '4px' }} />
            <a href="tel:+15158175703">(515) 817-5703</a>
          </React.Fragment>
        )}
      </h1>
      <p style={{ color: THEME.tabText(), margin: '0px' }}>
        {intl.get('gvl.sales.days') + ' ' + intl.get('gvl.sales.times')}
      </p>
    </React.Fragment>
  );
}
