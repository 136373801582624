import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Form, Row, Col, Radio } from 'antd';
import { Contact } from 'modules/Contact/contactIndex';
import FormField from 'elements/FormField';

export default class ContactFormFieldsComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    contact: PropTypes.object.isRequired
  };

  state = {
    producerIsPerson: this.props.contact?.contactId
      ? !!this.props.contact.firstName
      : true
  };

  renderPersonFields = () => {
    const { form } = this.props;
    return (
      <div>
        <Row gutter={8}>
          <Col span={11}>
            <FormField
              label={intl.get('firstName')}
              form={form}
              fieldName="producer.firstName"
              model={Contact}
              specialRules={{ useSubFormValidator: true, preserve: false }}
              isRequired={true}
            />
          </Col>
          <Col span={2}>
            <FormField
              label={intl.get('mi')}
              form={form}
              fieldName="producer.middleInitial"
              model={Contact}
              specialRules={{ useSubFormValidator: true, preserve: false }}
              noDecorators={true}
            />
          </Col>
          <Col span={11}>
            <FormField
              label={intl.get('lastName')}
              form={form}
              fieldName="producer.lastName"
              model={Contact}
              specialRules={{ useSubFormValidator: true, preserve: false }}
              isRequired={true}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderOrganizationField = () => {
    const { form } = this.props;
    return (
      <div>
        <Row>
          <Col span={24}>
            <FormField
              label={`${intl.get('organization.name')}`}
              form={form}
              fieldName={'producer.orgName'}
              model={Contact}
              specialRules={{ useSubFormValidator: true, preserve: false }}
              isRequired={true}
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { producerIsPerson } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col span={12}>
            <Form.Item name="producerType" label="Type">
              <Radio.Group
                defaultValue={producerIsPerson}
                buttonStyle="solid"
                onChange={e =>
                  this.setState({ producerIsPerson: e.target.value })
                }
              >
                <Radio value={true}>Person</Radio>
                <Radio value={false}>Organization</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {producerIsPerson
          ? this.renderPersonFields()
          : this.renderOrganizationField()}
      </React.Fragment>
    );
  }
}
