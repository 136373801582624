import React from 'react';
import axios from 'axios';
import { API_PREFIX, BACKEND_BASENAME } from 'utils/config';
import * as google from 'utils/analytics/google';
import { message, notification } from 'antd';
import qs from 'qs';
import intl from 'react-intl-universal';

// Uncomment this line if you want to use postman mocks in place of the API
// import { attachPostmanRequestInterceptor } from './postmanApiMock';

//Axios will use this in front of all api calls
axios.defaults.baseURL = API_PREFIX;
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.defaults.headers.common['Expires'] = -1;

export const setAuthToken = token => {
  // Setting the token to an empty string allow access to `permitAll` secured endpoints (will still throw correctly for other endpoints).
  // Setting the token as null or undefined will through a 401 unauthorized when calling a `permitAll` endpoint.
  if (!token) token = '';
  axios.defaults.headers.common['X-Auth-Token'] = token;
};

export const get = async (url, config) => {
  return (await axios.get(url, config)).data;
};

export const getBlob = async (url, config = {}, postData = null) => {
  config.responseType = 'blob';
  if (postData) {
    return await axios.post(url, postData, config);
  } else {
    return await axios.get(url, config);
  }
};

// This function was failing to convert large images to base64 due to a stack overflow error when converting the
// charcode array to a binary string.  To work around that issue, we can parse large charcode arrays 50,000 charcodes
// at a time rather than all at once.  When testing locally, I was running into the issue for arrays with over ~125,000
// charcodes.  I set this up to parse 50,000 at a time, but we can reduce that number if the issue still occurs for
// users with older computers.
const maxArrayChunk = 50000;
export const getImageBase64 = async url => {
  return await axios
    .get(url, { responseType: 'arraybuffer' })
    .then(response => {
      const charCodeArray = new Uint8Array(response.data);
      let binaryString = '';
      for (
        let startIndex = 0;
        startIndex < charCodeArray.length;
        startIndex += maxArrayChunk
      ) {
        binaryString += String.fromCharCode(
          ...new Uint8Array(response.data).slice(
            startIndex,
            startIndex + maxArrayChunk
          )
        );
      }
      return `data:${response.headers['content-type']};base64,${btoa(
        binaryString
      )}`;
    })
    .catch(error => {
      console.log('getImageBase64 failed', error);
      // TODO: pass back a default/empty/error image in the future?
      return null;
    });
};

export const post = async (url, data) => {
  return (await axios.post(url, data)).data;
};

export const postFile = async (url, data) => {
  return (
    await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  ).data;
};

export const legacyAuthenticate = async data => {
  try {
    await axios.post(
      BACKEND_BASENAME + '/login/authenticate',
      qs.stringify(data),
      {
        baseURL: '/',
        withCredentials: true, // this submits the cookie data
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  } catch (error) {
    // NOTE: at this point if we want to verify everything worked ok, we would check the
    // 302 response (which axios treats as an error) to make sure it wasn't authfail,
    // but not sure if that is worth it? If this call fails the user will need to re-enter
    // their credentials if they interact with any of the grails gsp pages
  } finally {
    localStorage.access_token = axios.defaults.headers.common['X-Auth-Token'];
  }
};

export const legacyLogout = async () => {
  try {
    await axios.get(BACKEND_BASENAME + '/logout', {
      baseURL: '/',
      withCredentials: true // this submits the cookie data
    });
  } catch (error) {
    console.log(error);
  } finally {
    localStorage.removeItem('access_token');
  }
};

export const legacyValidate = async () => {
  try {
    const result = await axios.get(BACKEND_BASENAME + '/user/getGvlToken', {
      baseURL: '/',
      withCredentials: true // this submits the cookie data
    });
    return result.status === 200;
  } catch (error) {
    return false;
  }
};

export const resetPassword = async data => {
  try {
    await axios.post(
      BACKEND_BASENAME + '/login/forgotPassword/forgotPasswordForm',
      qs.stringify(data),
      {
        baseURL: '/',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  } catch (error) {
    // the success response is 302 which axios considers an error, if we want
    // to be super sure this worked then would have to look at the location header
    console.log(error);
  }
};

export const put = async (url, data) => {
  return (await axios.put(url, data)).data;
};

export const deleteRec = async url => {
  return (await axios.delete(url)).data;
};

export const deleteRecWithData = async (url, data) => {
  return (await axios({ method: 'delete', url: url, data: data })).data;
};

export const generateApiParameters = (pagination, filters, sorter) => {
  let params = {
    limit: pagination.pageSize,
    offset: (pagination.current - 1) * pagination.pageSize,
    sort:
      sorter.field && `${sorter.order === 'descend' ? '-' : '+'}${sorter.field}`
  };
  Object.keys(filters).forEach(item => {
    if (filters[item]) {
      params[item] = filters[item][0];
    }
  });
  return params;
};

// Interceptor Functions

export const passThroughResponse = response => response;

export const handleResponseError = error => {
  google.apiError(error.response.config.url);
  return Promise.reject(error);
};

export const handleRequestError = error => {
  return Promise.reject(error);
};

export const attachInterceptors = () => {
  // Currently only Google Analytics requires Response Interceptor
  if (google.getCommandObject()) {
    axios.interceptors.response.use(passThroughResponse, handleResponseError);
  }

  // Uncomment this line if you want to use postman mocks in place of the API
  // attachPostmanRequestInterceptor();
};

// This will handle API errors that return an error in the format:
//   {"errors":[{"code":"031", "message":"Requested animal or agent not found"}]}
// It will only show the first error message in the errors array, using AntD message.error
export const handleSimpleApiError = error => {
  const { response } = error;
  if (!response || !response.data || !response.data.errors) {
    console.error(error);
    message.error(intl.get('server.error'));
    return;
  }

  response.data.errors.forEach(e => {
    let notificationMessage = '';
    if (e.field) {
      notificationMessage = `${e.field}: ${e.message}`;
    } else {
      notificationMessage = `${e.message}`;
    }
    notification.error({
      message: notificationMessage,
      duration: 0
    });
  });
};

export const handleApiErrorsWithTitle = (apiErrors, notificationMessage) => {
  if (apiErrors?.length > 0) {
    let description;
    apiErrors.forEach(e => {
      if (e.field) {
        description = `${e.field}: ${e.message}`;
      } else {
        description = `${e.message}`;
      }
      notification.warning({
        message: notificationMessage,
        description: description
      });
    });
  } else {
    notification.warning({
      message: notificationMessage
    });
  }
};

// TODO: Use the following in places where we are handling api errors
// This will extract single or multiple error message(s) from an error.response
export const extractApiErrorDescriptionFromResponse = error => {
  const { response } = error;

  if (response && response.data) {
    const { errors, errorMessage } = response.data;
    if (errors) {
      return (
        // TODO: Use a functional component for this once we decide how we will use it and how it will be styled.
        <React.Fragment>
          {errors.map((e, idx) => (
            <p style={{ marginBottom: 0 }} key={idx}>
              {e.message}
            </p>
          ))}
        </React.Fragment>
      );
    } else {
      if (errorMessage) {
        return errorMessage;
      }
    }
  }

  return null;
};
