import Model from 'models/Model';
import {
  stringRequired,
  maxLength,
  isNumber,
  numberInRange
} from 'utils/validation/common';

export default class Herd extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      herdId: {
        initialValue: undefined
      },
      name: {
        initialValue: undefined,
        validate: value => (!value ? null : maxLength(value, 100))
      },
      description: {
        initialValue: undefined
      },
      headCount: {
        initialValue: undefined,
        validate: value =>
          !value
            ? null
            : isNumber(value, 'validation.herdSize.integer') ||
              numberInRange(value, 0, 1000000)
      },
      species: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      flow: {
        initialValue: undefined
      },
      producerName: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 120);
        }
      },
      phone: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      addressLine1: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.longitude || instance.latitude
            ? null
            : stringRequired(value, 'required');
        }
      },
      city: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.longitude || instance.latitude
            ? null
            : stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      state: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.longitude || instance.latitude
            ? null
            : stringRequired(value, 'required') || maxLength(value, 2);
        }
      },
      postalCode: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.longitude || instance.latitude
            ? null
            : stringRequired(value, 'required') || maxLength(value, 12);
        }
      },
      latitude: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.addressLine1
            ? null
            : isNumber(value, 'required') || numberInRange(value, -180, 180);
        }
      },
      longitude: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.addressLine1
            ? null
            : isNumber(value, 'required') || numberInRange(value, -90, 90);
        }
      },
      premisesId: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 25);
        }
      },
      herdState: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 2);
        }
      },
      comments: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      // Returned by the api (will be transitioned to a contact and location in the future)
      contactLocation: {
        initialValue: undefined
      },
      producer: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/herd';
  }

  static get domain() {
    return 'Herd';
  }

  static get idFieldName() {
    return 'herdId';
  }

  static list = async params => await super.list(params);

  static read = async id => await super.read(id);

  static delete = async id => await super.deleteRec(id);
}
