import { createAction } from 'utils/reduxUtil';
import {
  REGULATORY_LOAD_STARTUP_DATA_REQUEST,
  REGULATORY_LOAD_STARTUP_DATA_STARTED,
  REGULATORY_LOAD_STARTUP_DATA_SUCCESS,
  REGULATORY_LOAD_STARTUP_DATA_FINISHED,
  REGULATORY_LOAD_SETTINGS_DATA_REQUEST,
  REGULATORY_LOAD_SETTINGS_DATA_STARTED,
  REGULATORY_LOAD_SETTINGS_DATA_SUCCESS,
  REGULATORY_LOAD_SETTINGS_DATA_FINISHED,
  REGULATORY_SETTINGS_DATA_SAVE_REQUEST,
  REGULATORY_SETTINGS_DATA_SAVE_SUCCESS,

  //Rules
  REGULATORY_REFRESH_RULE_LIST_REQUEST,
  REGULATORY_REFRESH_RULE_LIST_SUCCESS,
  REGULATORY_REFRESH_RULE_LIST_FAILURE,
  REGULATORY_RULE_STATUS_UPDATE_REQUEST,
  REGULATORY_RULE_ADD_FEEDBACK_REQUEST,

  //Certificates
  REGULATORY_SEARCH_CERTIFICATES_REQUEST,
  REGULATORY_SEARCH_CERTIFICATES_SUCCESS,
  REGULATORY_SEARCH_CERTIFICATES_FAILURE,

  //EECVI Search Permits
  REGULATORY_SEARCH_EECVI_PERMITS,
  REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS,
  REGULATORY_CLEAR_SEARCH_EECVI_PERMITS,
  REGULATORY_VIEW_EECVI_ITINERARY,
  REGULATORY_FETCH_EECVI_SUCCESS,
  REGULATORY_FETCH_EECVI_FAILURE,
  REGULATORY_CLOSE_EECVI_ITINERARY,

  // SmartEngine
  REGULATORY_VERIFY_CERTIFICATE_REQUEST,
  REGULATORY_VERIFY_CERTIFICATE_SUCCESS,
  REGULATORY_CLOSE_SMART_ENGINE_MODAL,
  REGULATORY_SEARCH_TESTS,
  REGULATORY_SEARCH_TESTS_SUCCESS,
  REGULATORY_CLEAR_TESTS_SEARCH,

  // Out of state lookup
  REGULATORY_DOCUMENT_LOOKUP_REQUEST,
  REGULATORY_DOCUMENT_LOOKUP_SUCCESS,
  REGULATORY_DOCUMENT_LOOKUP_FAILURE,

  // Weekly certificate reports
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST,
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS,
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE
} from 'containers/accounts/regulatory/store/accountsRegulatoryConstants';

/* istanbul ignore file */

export const regulatoryLoadStartupDataRequest = () =>
  createAction(REGULATORY_LOAD_STARTUP_DATA_REQUEST);
export const regulatoryLoadStartupDataStarted = () =>
  createAction(REGULATORY_LOAD_STARTUP_DATA_STARTED);
export const regulatoryLoadStartupDataSuccess = startupData =>
  createAction(REGULATORY_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const regulatoryLoadStartupDataFinished = () =>
  createAction(REGULATORY_LOAD_STARTUP_DATA_FINISHED);

export const regulatoryLoadSettingsDataRequest = data =>
  createAction(REGULATORY_LOAD_SETTINGS_DATA_REQUEST, { data });
export const regulatoryLoadSettingsDataStarted = () =>
  createAction(REGULATORY_LOAD_SETTINGS_DATA_STARTED);
export const regulatoryLoadSettingsDataSuccess = result =>
  createAction(REGULATORY_LOAD_SETTINGS_DATA_SUCCESS, { result });
export const regulatoryLoadSettingsDataFinished = () =>
  createAction(REGULATORY_LOAD_SETTINGS_DATA_FINISHED);

//Rules
export const regulatoryRefreshRuleListRequest = params =>
  createAction(REGULATORY_REFRESH_RULE_LIST_REQUEST, { params });
export const regulatoryRefreshRuleListSuccess = rules =>
  createAction(REGULATORY_REFRESH_RULE_LIST_SUCCESS, { rules });
export const regulatoryRefreshRuleListFailure = () =>
  createAction(REGULATORY_REFRESH_RULE_LIST_FAILURE);
export const regulatoryRuleStatusUpdateRequest = (id, status, params) =>
  createAction(REGULATORY_RULE_STATUS_UPDATE_REQUEST, {
    id,
    status,
    params
  });
export const regulatoryRuleAddFeedbackRequest = (id, feedback, params) =>
  createAction(REGULATORY_RULE_ADD_FEEDBACK_REQUEST, {
    id,
    feedback,
    params
  });

export const regulatorySettingsDataSaveRequest = data =>
  createAction(REGULATORY_SETTINGS_DATA_SAVE_REQUEST, { data });
export const regulatorySettingsDataSaveSuccess = result =>
  createAction(REGULATORY_SETTINGS_DATA_SAVE_SUCCESS, { result });
// Certificate
export const regulatorySearchCertificatesRequest = params =>
  createAction(REGULATORY_SEARCH_CERTIFICATES_REQUEST, { params });
export const regulatorySearchCertificatesSuccess = certificates =>
  createAction(REGULATORY_SEARCH_CERTIFICATES_SUCCESS, { certificates });
export const regulatorySearchCertificatesFailure = () =>
  createAction(REGULATORY_SEARCH_CERTIFICATES_FAILURE);

export const regulatorySearchEecviPermits = searchParams =>
  createAction(REGULATORY_SEARCH_EECVI_PERMITS, { searchParams });
export const regulatorySearchEecviPermitsSuccess = permits =>
  createAction(REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS, { permits });
export const regulatoryClearSearchEecviPermits = () =>
  createAction(REGULATORY_CLEAR_SEARCH_EECVI_PERMITS);
export const regulatoryViewEecviItinerary = (id, serialNumber) =>
  createAction(REGULATORY_VIEW_EECVI_ITINERARY, { id, serialNumber });
export const regulatoryCloseEecviItinerary = () =>
  createAction(REGULATORY_CLOSE_EECVI_ITINERARY);
export const regulatoryFetchEecviSuccess = eecvi =>
  createAction(REGULATORY_FETCH_EECVI_SUCCESS, { eecvi });
export const regulatoryFetchEecviFailure = () =>
  createAction(REGULATORY_FETCH_EECVI_FAILURE);

// SmartEngine
export const regulatoryVerifyCertificateRequest = certificateId =>
  createAction(REGULATORY_VERIFY_CERTIFICATE_REQUEST, { certificateId });
export const regulatoryVerifyCertificateSuccess = seResults =>
  createAction(REGULATORY_VERIFY_CERTIFICATE_SUCCESS, { seResults });
export const regulatoryCloseSmartEngineModal = () =>
  createAction(REGULATORY_CLOSE_SMART_ENGINE_MODAL);

export const regulatorySearchTests = searchParams =>
  createAction(REGULATORY_SEARCH_TESTS, { searchParams });
export const regulatorySearchTestsSuccess = tests =>
  createAction(REGULATORY_SEARCH_TESTS_SUCCESS, { tests });
export const regulatoryClearTestsSearch = () =>
  createAction(REGULATORY_CLEAR_TESTS_SEARCH);

// Out of state lookup
export const regulatoryDocumentLookupRequest = (certNumber, certType) =>
  createAction(REGULATORY_DOCUMENT_LOOKUP_REQUEST, {
    certNumber,
    certType
  });
export const regulatoryDocumentLookupSuccess = certId =>
  createAction(REGULATORY_DOCUMENT_LOOKUP_SUCCESS, { certId });
// TODO: This returns a 404 currently. Should update to return a not found exception
export const regulatoryDocumentLookupFailure = (
  msgId,
  specificDescription,
  specificMessage
) =>
  createAction(REGULATORY_DOCUMENT_LOOKUP_FAILURE, {
    msgId,
    specificDescription,
    specificMessage
  });

// Weekly certificate reports
export const regulatoryWeeklyCertificatesReportsRequest = id =>
  createAction(REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST, { id });
export const regulatoryWeeklyCertificatesReportsSuccess = reports =>
  createAction(REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS, { reports });
export const regulatoryWeeklyCertificatesReportsFailure = () =>
  createAction(REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE);
