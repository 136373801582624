import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import Vfd from 'models/documents/Vfd';
import Request from 'models/Request';
import FeedMillSearch from 'models/FeedMillSearch';
import FeedMill from 'models/FeedMill';
import UploadedDocument from 'models/documents/UploadedDocument';
import * as api from 'utils/api';
import intl from 'react-intl-universal';
import {
  distributorLoadStartupDataSuccess,
  distributorLoadStartupDataStarted,
  distributorLoadStartupDataFinished,
  distributorRefreshVfdListStarted,
  distributorRefreshVfdListSuccess,
  distributorRefreshVfdListFailure,
  distributorVfdTransferLocationSuccess,
  distributorVfdTransferLocationFailure,
  distributorRefreshUploadedDocumentListStarted,
  distributorRefreshUploadedDocumentListSuccess,
  distributorRefreshUploadedDocumentListFailure,
  distributorSearchCertificatesStarted,
  distributorSearchCertificatesSuccess,
  distributorSearchCertificatesFailure,
  distributorRefreshUploadedDocumentSearchListStarted,
  distributorRefreshUploadedDocumentSearchListSuccess,
  distributorRefreshUploadedDocumentSearchListFailure,
  distributorUploadedDocumentListUpdated,
  distributorUploadedDocumentListReady,
  distributorSearchCertificateStarted,
  distributorSearchCertificateSuccess,
  distributorSearchCertificateFailure,
  distributorClaimCertificateStarted,
  distributorClaimCertificateSuccess,
  distributorClaimCertificateFailure,
  distributorRefreshRequestsListStarted,
  distributorRefreshRequestsListSuccess,
  distributorRefreshRequestsListFailure,
  distributorUploadCertificateStarted,
  distributorUploadCertificateFailure,
  distributorUploadCertificateSuccess,
  distributorRefreshRequestSuccess,
  distributorRefreshRequestFailure,
  // distributorRequestVfdSuccess, // TODO: Should there be a reducer for this action?
  // distributorRequestVfdFailure, // TODO: Should there be a reducer for this action?
  distributorUploadedDocumentRemovedFromList,
  distributorUploadedDocumentDeleted,
  distributorUploadedDocumentsListReady,
  distributorRefreshUploadsRequestStarted,
  distributorUploadRequestUpdated,
  distributorUploadRemovedFromList,
  distributorUploadsListReady,
  distributorRefreshCertificateSuccess
} from 'containers/accounts/distributor/store/accountsDistributorActions';
import {
  DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST,
  DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST,
  DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST,
  DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST,
  DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST,
  DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST,
  DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST,
  DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_REQUEST_REQUEST,
  DISTRIBUTOR_REQUEST_VFD_REQUEST,
  DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST,
  //UPLOAD REQUEST
  DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST,
  DISTRIBUTOR_DELETE_UPLOAD_REQUEST,
  DISTRIBUTOR_REFRESH_CERTIFICATE,
  DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST
} from 'containers/accounts/distributor/store/accountsDistributorConstants';
import { getUserInfo } from 'containers/app/store/user/userSelectors';

import { apiFailure } from 'containers/app/store/data/dataActions';
import { connectionError } from 'containers/app/store/data/dataActions';
import { message, notification } from 'antd';
import { exportCsv, formatDate } from 'utils/csvUtil';

export function* onDistributorLoadStartupDataRequest() {
  try {
    yield put(distributorLoadStartupDataStarted());
    let listMills = [];
    const userInfo = yield select(getUserInfo);
    if (['Regional', 'Corporate'].includes(userInfo.distributorHierarchy)) {
      listMills = yield call(FeedMill.fetchMillList);
    }
    const [vfdDrugSpecies, ads] = yield all([
      call(api.get, '/drug/species?type=feed'),
      call(api.get, '/ad')
    ]);
    yield put(
      distributorLoadStartupDataSuccess({
        vfdDrugSpecies,
        ads,
        listMills
      })
    );
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(distributorLoadStartupDataFinished());
  }
}

// Distributor
export function* onDistributorRefreshVfdListRequest(_action) {
  try {
    yield put(distributorRefreshVfdListStarted());

    const [feedCertificates] = yield all([call(Vfd.getActiveCerts)]);

    yield put(
      distributorRefreshVfdListSuccess({
        feedCertificates
      })
    );
  } catch (err) {
    console.error(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Feed Certificates List data'));
    yield put(distributorRefreshVfdListFailure());
  }
}

export function* onDistributorVfdSaveOverageReasonRequest(action) {
  let { setShipmentData, addShipmentData } = action;
  try {
    yield* onDistributorVfdSaveLogShipmentRequest(setShipmentData);
    yield* onDistributorVfdSaveLogShipmentRequest(addShipmentData);
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onDistributorVfdTransferLocationRequest(action) {
  let { feedMillData, docId } = action;
  try {
    yield put(distributorRefreshVfdListStarted());
    const transferredMill = yield Vfd.transferToOtherLocation(
      docId,
      feedMillData
    );
    if (transferredMill) {
      yield put(distributorVfdTransferLocationSuccess(transferredMill));
      yield call(message.success, intl.get('certificate.transfered'));
    } else {
      yield put(distributorVfdTransferLocationFailure('error'));
    }
  } catch (err) {
    yield put(apiFailure('error'));
  }
}

export function* onDistributorVfdSaveLogShipmentRequest(action) {
  let { shipmentData, id, actionType, shouldShowMessage } = action;
  try {
    yield put(distributorRefreshVfdListStarted());
    yield Vfd.saveLogShipment(id, shipmentData, actionType);
    if (shouldShowMessage) {
      yield call(message.success, intl.get('shipment.save'));
    }
    const [feedCertificates] = yield all([call(Vfd.getActiveCerts)]);
    yield put(
      distributorRefreshVfdListSuccess({
        feedCertificates
      })
    );
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onDistributorSearchCertificateRequest(action) {
  const { params } = action;
  try {
    yield put(distributorSearchCertificateStarted());
    const searchedCertificate = yield Vfd.searchCertificate(params);
    if (
      searchedCertificate.feedMill &&
      !!Object.entries(searchedCertificate.feedMill).length
    ) {
      yield put(distributorSearchCertificateFailure());
      yield call(
        message.info,
        intl.get('certificates.certificateAlreadyClaimed')
      );
    } else {
      yield put(distributorSearchCertificateSuccess(searchedCertificate));
    }
  } catch (err) {
    console.error(err);
    yield put(distributorSearchCertificateFailure());
    yield call(message.error, intl.get('certificates.certificateNotFound'));
  }
}

export function* onDistributorClaimCertificateRequest(action) {
  const { id, feedMillReferenceId } = action;
  try {
    yield put(distributorClaimCertificateStarted());
    yield Vfd.claimCertificate(id, feedMillReferenceId);
    yield put(distributorClaimCertificateSuccess());
    yield call(message.success, intl.get('certificates.certificateClaimed'));
  } catch (err) {
    console.error(err);
    yield put(distributorClaimCertificateFailure());
    yield call(
      message.error,
      intl.get('certificates.error.certificateClaimed')
    );
  }
}

export function* onDistributorUploadCertificateRequest(action) {
  const { docUpload, isCreate } = action;
  try {
    yield put(distributorUploadCertificateStarted());
    if (isCreate) {
      yield UploadedDocument.uploadCertificate(docUpload);
      yield call(message.success, intl.get('certificates.uploaded'));
    } else {
      yield UploadedDocument.updateCertificate(docUpload);
      yield call(message.success, intl.get('certificates.saved'));
    }
    yield put(distributorUploadCertificateSuccess(isCreate));
  } catch (err) {
    console.error(err);
    yield put(distributorUploadCertificateFailure(isCreate));
    yield call(
      message.error,
      intl.get(
        isCreate ? 'certificates.error.upload' : 'certificates.error.edit'
      )
    );
  }
}

//requests
export function* onDistributorRefreshRequestsListRequest(action) {
  try {
    yield put(distributorRefreshRequestsListStarted());
    const results = yield all([
      call(Request.getVfdRequests),
      call(Request.getAcceptedVfdRequests),
      call(Request.getRejectedVfdRequests)
    ]);

    yield put(distributorRefreshRequestsListSuccess(results));
  } catch (err) {
    yield put(apiFailure('Error loading request List data'));
    yield put(distributorRefreshRequestsListFailure());
  }
}

export function* onDistributorRefreshRequestRequest(action) {
  const { id } = action;
  try {
    const result = yield Request.read(id);
    yield put(distributorRefreshRequestSuccess(result));
  } catch (err) {
    yield put(apiFailure('Error loading Uploaded documents List data'));
    yield put(distributorRefreshRequestFailure());
  }
}

export function* onDistributorRefreshCertificate(action) {
  const { id } = action;

  try {
    yield put(distributorSearchCertificatesStarted());
    const vfd = yield call(Vfd.read, id);
    yield put(
      distributorRefreshCertificateSuccess({
        vfd
      })
    );
  } catch (err) {
    console.error(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Feed Certificates List data'));
    yield put(distributorSearchCertificatesFailure());
  }
}

// Uploaded Document
export function* onDistributorRefreshUploadedDocumentListRequest(_action) {
  try {
    yield put(distributorRefreshUploadedDocumentListStarted());
    const result = yield UploadedDocument.getAll();
    const documents = result.results;
    yield put(
      distributorRefreshUploadedDocumentListSuccess({
        documents
      })
    );
  } catch (err) {
    yield put(apiFailure('Error loading Uploaded documents List data'));
    yield put(distributorRefreshUploadedDocumentListFailure());
  }
}

export function* onDistributorUploadedDocumentSaveOverageReasonRequest(action) {
  let { setShipmentData, addShipmentData } = action;
  try {
    yield* onDistributorUploadedDocumentSaveLogShipmentRequest(setShipmentData);
    yield* onDistributorUploadedDocumentSaveLogShipmentRequest(addShipmentData);
  } catch (err) {
    yield put(connectionError());
  }
}
export function* onDistributorUploadedDocumentSaveLogShipmentRequest(action) {
  let { shipmentData, id, actionType, shouldShowMessage } = action;
  try {
    yield put(distributorRefreshUploadedDocumentListStarted());
    yield UploadedDocument.saveLogShipment(id, shipmentData, actionType);
    if (shouldShowMessage) {
      yield call(message.success, intl.get('shipment.save'));
    }
    const result = yield UploadedDocument.getAll();
    const documents = result.results;
    yield put(
      distributorRefreshUploadedDocumentListSuccess({
        documents
      })
    );
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onDistributorSaveOverageReasonRequest(action) {
  let { setShipmentData, addShipmentData } = action;
  try {
    yield* onDistributorSaveLogShipmentRequest(setShipmentData);
    yield* onDistributorSaveLogShipmentRequest(addShipmentData);
  } catch (err) {
    yield put(connectionError());
  }
}
export function* onDistributorSaveLogShipmentRequest(action) {
  let { shipmentData, id, actionType, params, shouldShowMessage } = action;
  try {
    yield put(distributorRefreshUploadedDocumentSearchListStarted());
    yield UploadedDocument.saveLogShipment(id, shipmentData, actionType);
    if (shouldShowMessage) {
      yield call(message.success, intl.get('shipment.save'));
      const doc = yield UploadedDocument.getUploadedDocument(params);
      yield put(distributorRefreshUploadedDocumentSearchListSuccess(doc));
    }
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onDistributorEditOverageReasonRequest(action) {
  let { setShipmentData, addShipmentData } = action;
  try {
    yield* onDistributorEditLogShipmentRequest(setShipmentData);
    yield* onDistributorEditLogShipmentRequest(addShipmentData);
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onDistributorEditLogShipmentRequest(action) {
  let {
    shipmentData,
    id,
    actionType,
    shouldShowMessage,
    isEditEstimatedQuantity
  } = action;
  try {
    yield put(distributorSearchCertificatesStarted());
    yield Vfd.saveLogShipment(id, shipmentData, actionType);
    if (shouldShowMessage) {
      if (isEditEstimatedQuantity) {
        yield call(message.success, intl.get('shipment.estimatedEdit'));
      } else if (actionType === 'deleteShipment') {
        yield call(message.success, intl.get('shipment.delete'));
      } else {
        yield call(message.success, intl.get('shipment.save'));
      }
    }
    const vfd = yield call(Vfd.read, id);
    yield put(
      distributorRefreshCertificateSuccess({
        vfd
      })
    );
  } catch (err) {
    console.error(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Feed Certificates List data'));
    yield put(distributorSearchCertificatesFailure());
  }
}

export function* onDistributorUploadedDocumentDeleteRequest(action) {
  const { id } = action;
  try {
    yield put(distributorRefreshUploadedDocumentListStarted());
    yield UploadedDocument.deleteRec(id);
    yield call(
      message.success,
      intl.get('deletedThing', { thing: 'Document' })
    );
    yield put(distributorUploadedDocumentDeleted(id[0]));
  } catch (err) {
    yield put(apiFailure(err));
  }
  yield put(distributorUploadedDocumentsListReady());
}
export function* onDistributorSearchCertificatesRequest(action) {
  try {
    yield put(distributorSearchCertificatesStarted());
    const Feedmills = yield FeedMillSearch.getFeedMill(action.params);
    if (action.params.export && Feedmills.length > 0) {
      const csvLayout = [
        { heading: 'Certificate #', property: 'serialNumber' },
        { heading: 'Owner', property: 'owner.name' },
        { heading: 'Drug', property: 'drug' },
        { heading: 'Species', property: 'species' },
        {
          heading: 'Sign date',
          property: 'signingDate',
          dataFormatFunction: date => date.substring(0, 10)
        }
      ];
      let fileName = `CertificateExport_${formatDate(new Date())}.csv`;
      exportCsv(csvLayout, Feedmills, fileName);
    } else {
      if (action.params.export)
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
    }
    yield put(distributorSearchCertificatesSuccess(Feedmills));
  } catch (err) {
    console.error(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading FeedMill List data'));
    yield put(distributorSearchCertificatesFailure());
  }
}

export function* onDistributorRefreshUploadedDocumentSearchListRequest(action) {
  try {
    yield put(distributorRefreshUploadedDocumentSearchListStarted());
    const doc = yield UploadedDocument.getUploadedDocument(action.params);
    yield put(distributorRefreshUploadedDocumentSearchListSuccess(doc));
  } catch (err) {
    console.error(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading uploadedDocument List data'));
    yield put(distributorRefreshUploadedDocumentSearchListFailure());
  }
}

export function* onDistributorDeactivateUploadRequest(action) {
  const { id, deactivationReason } = action;
  let doc = null;
  try {
    yield put(distributorRefreshUploadsRequestStarted());

    doc = yield call(
      UploadedDocument.deactivateUploadedDocument,
      id,
      deactivationReason
    );
    if (doc) {
      yield put(distributorUploadRequestUpdated(doc));
    }

    yield call(message.success, intl.get('uploadedDocument.deactivated'));
  } catch (err) {
    yield put(apiFailure('failed deactivation'));
  }
  yield put(distributorUploadsListReady());
}
export function* onDistributorDeactivateUploadedDocumentRequest(action) {
  const { ids, deactivationReason } = action;
  let doc = null;
  for (var i = 0; i < ids.length; i++) {
    try {
      yield put(distributorRefreshUploadedDocumentSearchListStarted());
      let id = ids[i];

      doc = yield call(
        UploadedDocument.deactivateUploadedDocument,
        id,
        deactivationReason
      );
      if (doc) {
        yield put(distributorUploadedDocumentListUpdated(doc));
      }

      yield call(message.success, intl.get('uploadedDocument.deactivated'));
    } catch (err) {
      yield put(apiFailure('failed deactivation'));
    }
  }
  yield put(distributorUploadedDocumentListReady());
}

export function* onDistributorDeleteUploadRequest(action) {
  const { id } = action;
  try {
    yield put(distributorRefreshUploadsRequestStarted());
    yield call(UploadedDocument.deleteUploadedDocument, id);
    yield call(message.success, intl.get('uploadedDocument.deleted'));
    yield put(distributorUploadRemovedFromList(id));
  } catch (err) {
    yield put(apiFailure(err));
  }
  yield put(distributorUploadsListReady());
}

export function* onDistributorDeleteUploadedDocumentRequest(action) {
  const { ids } = action;
  for (var i = 0; i < ids.length; i++) {
    try {
      yield put(distributorRefreshUploadedDocumentSearchListStarted());
      let id = ids[i];
      yield call(UploadedDocument.deleteUploadedDocument, id);
      yield call(message.success, intl.get('uploadedDocument.deleted'));
      yield put(distributorUploadedDocumentRemovedFromList(id));
    } catch (err) {
      yield put(apiFailure(err));
    }
  }
  yield put(distributorUploadedDocumentListReady());
}

export function* onDistributorRequestVfdRequest(action) {
  let { requestedDoc, history } = action;
  try {
    yield requestedDoc.save();
    // TODO: Should there be a reducer for this action?
    // yield put(distributorRequestVfdSuccess(history));
    yield call(message.success, intl.get('vfd.request.success.message'));
    history.push('/requests');
  } catch (err) {
    console.error(err);
    // TODO: Should there be a reducer for this action?
    // yield put(distributorRequestVfdFailure());
    yield call(
      message.error,
      intl.get('error.submitting.noun', { noun: 'VFD' })
    );
  }
}

export default function* accountsDistributorSagas() {
  yield takeLatest(
    DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST,
    onDistributorLoadStartupDataRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST,
    onDistributorRefreshVfdListRequest
  );
  yield takeLatest(
    DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST,
    onDistributorVfdSaveLogShipmentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST,
    onDistributorVfdTransferLocationRequest
  );
  yield takeLatest(
    DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST,
    onDistributorVfdSaveOverageReasonRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST,
    onDistributorRefreshUploadedDocumentListRequest
  );

  yield takeLatest(
    DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST,
    onDistributorSearchCertificatesRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST,
    onDistributorRefreshUploadedDocumentSearchListRequest
  );
  yield takeLatest(
    DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST,
    onDistributorUploadedDocumentSaveLogShipmentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST,
    onDistributorUploadedDocumentSaveOverageReasonRequest
  );
  yield takeLatest(
    DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST,
    onDistributorSaveLogShipmentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST,
    onDistributorSaveOverageReasonRequest
  );
  yield takeLatest(
    DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST,
    onDistributorEditLogShipmentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST,
    onDistributorEditOverageReasonRequest
  );
  yield takeLatest(
    DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST,
    onDistributorSearchCertificateRequest
  );
  yield takeLatest(
    DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST,
    onDistributorClaimCertificateRequest
  );
  yield takeLatest(
    DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST,
    onDistributorUploadCertificateRequest
  );
  yield takeLatest(
    DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST,
    onDistributorDeactivateUploadedDocumentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST,
    onDistributorDeactivateUploadRequest
  );
  yield takeLatest(
    DISTRIBUTOR_DELETE_UPLOAD_REQUEST,
    onDistributorDeleteUploadRequest
  );
  yield takeLatest(
    DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST,
    onDistributorDeleteUploadedDocumentRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST,
    onDistributorRefreshRequestsListRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REQUEST_VFD_REQUEST,
    onDistributorRequestVfdRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_REQUEST_REQUEST,
    onDistributorRefreshRequestRequest
  );
  yield takeLatest(
    DISTRIBUTOR_REFRESH_CERTIFICATE,
    onDistributorRefreshCertificate
  );
}
