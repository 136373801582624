import Model from 'models/Model';
import Location from 'models/Location';
import { stringRequired, maxLength, isEmail } from 'utils/validation/common';

export default class Clinic extends Model {
  // Instance Methods

  validate() {
    return super.validate();
  }

  // Static Methods

  static get fields() {
    // listFields is used for updating a clinic so it's in the format that the api expect
    let listFields = {};
    this.enabledAppsList.forEach(value => {
      listFields[value] = { initialValue: false };
    });
    this.speciesList.forEach(value => {
      listFields['species' + value.split(' ').join('')] = {
        initialValue: false
      };
    });
    this.servicesList.forEach(value => {
      listFields['services' + value.split(' ').join('')] = {
        initialValue: false
      };
    });
    this.membershipsList.forEach(value => {
      listFields['memberships' + value] = { initialValue: false };
    });

    return {
      ...super.fields,
      id: {
        initialValue: undefined
      },
      uuid: {
        initialValue: undefined
      },
      premises: {
        initialValue: {},
        validate: value => {
          return new Location(value).validate();
        }
      },
      name: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      email: {
        initialValue: undefined,
        validate: value => {
          return !value
            ? null
            : isEmail(value, 'validation.email.invalid') ||
                maxLength(value, 120);
        }
      },
      webSite: {
        initialValue: null
      },
      cellPhone: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      vfdTerritory: {
        initialValue: null
      },
      equine: {
        initialValue: false
      },
      animalType: {
        initialValue: null
      },
      specialty: {
        initialValue: null
      },
      speciesList: {
        initialValue: []
      },
      acceptDocumentRequests: {
        initialValue: true
      },
      includeImagesOnLabSubmittal: {
        initialValue: true
      },
      notifyClinicOnEiaApproval: {
        initialValue: false
      },
      notifySigningVetOnEiaApproval: {
        initialValue: false
      },
      giveNewContactsMvlAccess: {
        initialValue: true
      },
      sendUserUpdateEmail: {
        initialValue: true
      },
      allowFindAVet: {
        initialValue: true
      },
      himsAccess: {
        initialValue: false
      },
      lowVolume: {
        initialValue: false
      },
      enabledApps: {
        initialValue:
          'appEquuslink,appFeedlink,appHealthlink,appManager,appScriptlink'
      },
      defaultLabId: {
        initialValue: null
      },
      defaultLabName: {
        initialValue: null
      },
      defaultLabAccountNumber: {
        initialValue: null
      },
      servicesList: {
        initialValue: []
      },
      membershipsList: {
        initialValue: []
      },
      association: {
        initialValue: null
      },
      associationNumber: {
        initialValue: null
      },
      version: {
        initialValue: null
      },
      vets: {
        initialValue: []
      },
      vetTechs: {
        initialValue: []
      },
      dateCreated: {
        initialValue: null
      },
      lastUpdated: {
        initialValue: null
      },
      ...listFields
    };
  }

  static enabledAppsList = [
    'appDatalink',
    'appEquuslink',
    'appFeedlink',
    'appHealthlink',
    'appManager',
    'appScriptlink'
  ];

  static speciesList = [
    'Aquaculture',
    'Beef',
    'Companion Animal',
    'Dairy',
    'Equine',
    'Poultry',
    'Small Ruminants',
    'Swine',
    'Other'
  ];

  static servicesList = [
    'Alternative Medicine',
    'Certificate of Veterinary Inspection (CVI)',
    'Equine Infectious Anemia (EIA)',
    'Dentistry',
    'Emergency Care',
    'House Calls/Mobile',
    'Microchipping',
    'Preventative Care',
    'Reproductive',
    'Scripts',
    'Sports Medicine',
    'Veterinary Feed Directive (VFD)',
    'Other'
  ];

  static membershipsList = [
    'AAAP',
    'AABP',
    'AAEP',
    'AAFP',
    'AAFV',
    'AAHA',
    'AASV',
    'ACVS',
    'AVC',
    'AVMA',
    'Other'
  ];

  static getFormattedSpeciesList = () => {
    let species = [];
    this.speciesList.forEach(value => {
      species.push('species' + value.split(' ').join(''));
    });
    return species;
  };

  static getFormattedServicesList = () => {
    let services = [];
    this.servicesList.forEach(value => {
      services.push('services' + value.split(' ').join(''));
    });
    return services;
  };

  static getFormattedMembershipList = () => {
    let memberships = [];
    this.membershipsList.forEach(value => {
      memberships.push('memberships' + value.split(' ').join(''));
    });
    return memberships;
  };

  static get apiPath() {
    return 'clinic';
  }

  static get domain() {
    return 'Clinic';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };
}
