import Model from 'models/Model';
import { stringRequired, maxLength, isEmail } from 'utils/validation/common';

export default class ContactV2 extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      contactId: {
        initialValue: undefined
      },
      firstName: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.orgName
            ? null
            : stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      middleInitial: {
        initialValue: undefined,
        validate: (value, instance) => {
          return !value || instance.orgName ? null : maxLength(value, 2);
        }
      },
      lastName: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.orgName
            ? null
            : stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      displayName: {
        intialValue: undefined
      },
      orgName: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.firstName ? null : stringRequired(value, 'required');
        }
      },
      primaryPhone: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      email: {
        initialValue: undefined,
        validate: value => {
          return !value
            ? null
            : isEmail(value, 'validation.email.invalid') ||
                maxLength(value, 120);
        }
      },
      primaryLocationId: {
        initialValue: undefined
      },
      type: {
        initialValue: undefined,
        validate: value => {
          if (value) {
            return this.CONTACT_TYPE.includes(value)
              ? null
              : {
                  messageId: `Contact type should be Producer, Owner or Agent`
                };
          }
        }
      },
      referenceType: {
        initialValue: undefined,
        validate: value => {
          if (value) {
            return this.CONTACT_REFERENCE_TYPE.includes(value)
              ? null
              : {
                  messageId: `Contact reference type should be Clinic, Lab or Distributor`
                };
          }
        }
      },
      referenceId: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      }
    };
  }

  static CONTACT_TYPE = ['Producer', 'Owner', 'Agent'];

  static CONTACT_REFERENCE_TYPE = ['Clinic', 'Lab', 'Distributor'];

  static list = async params => await super.list(params);

  static read = async id => await super.read(id);

  static get apiPath() {
    return 'records/contact';
  }

  static get domain() {
    return 'ContactV2';
  }

  static get idFieldName() {
    return 'contactId';
  }
}
