import { createAction } from 'utils/reduxUtil';
import {
  REFRESH_LAB_TEST_LIST_REQUEST,
  REFRESH_LAB_TEST_LIST_SUCCESS,
  REFRESH_LAB_TEST_LIST_FAILURE,
  REFRESH_LAB_TEST_REQUEST,
  RESULT_LAB_TESTS,
  LAB_CLEAR_TEST_ERRORS,
  CLEAR_LAB_TEST_SEARCH,
  CLEAR_LAB_CERTIFICATE_SEARCH,
  SIGN_LAB_TESTS,
  SEND_BACK_LAB_TEST,
  REMOVE_LAB_TEST_FROM_LIST,
  SEARCH_LAB_TESTS,
  SEARCH_LAB_TESTS_SUCCESS,
  SEARCH_LAB_CERTIFICATES,
  SEARCH_LAB_CERTIFICATES_SUCCESS,
  SIGN_LAB_TEST,
  LAB_SEND_TO_NVSL_REQUEST,
  LAB_SEND_TO_NVSL_FINISHED,
  NVSL_RESULT_LAB_TEST,
  LAB_ATTACH_NVSL_REPORT_REQUEST,
  LAB_ATTACH_NVSL_REPORT_FINISHED,
  LAB_REMOVE_NVSL_REPORT_REQUEST,
  LAB_REMOVE_NVSL_REPORT_FINISHED
} from 'containers/accounts/lab/store/accountsLabConstants';

/* istanbul ignore file */

export const refreshLabTestListRequest = () =>
  createAction(REFRESH_LAB_TEST_LIST_REQUEST);
export const refreshLabTestListSuccess = (labTests, refreshingActiveLabTest) =>
  createAction(REFRESH_LAB_TEST_LIST_SUCCESS, {
    labTests,
    refreshingActiveLabTest
  });
export const refreshLabTestListFailure = () =>
  createAction(REFRESH_LAB_TEST_LIST_FAILURE);
export const refreshLabTestRequest = labTestId =>
  createAction(REFRESH_LAB_TEST_REQUEST, { labTestId });
export const resultLabTests = (labTests, resultData, ignoreNullFields = true) =>
  createAction(RESULT_LAB_TESTS, { labTests, resultData, ignoreNullFields });
export const signLabTest = (labTest, credentials, modal) =>
  createAction(SIGN_LAB_TEST, { labTest, credentials, modal });
export const signLabTests = (ids, credentials, modal) =>
  createAction(SIGN_LAB_TESTS, { ids, credentials, modal });
export const nvslResultLabTest = (labTest, result) =>
  createAction(NVSL_RESULT_LAB_TEST, { labTest, result });
export const labClearTestErrors = () => createAction(LAB_CLEAR_TEST_ERRORS);
export const sendBackLabTest = (eia, labTest, reason, cb) =>
  createAction(SEND_BACK_LAB_TEST, { eia, labTest, reason, cb });
export const removeLabTestFromList = labTest =>
  createAction(REMOVE_LAB_TEST_FROM_LIST, { labTest });
export const searchLabTests = searchParams =>
  createAction(SEARCH_LAB_TESTS, { searchParams });
export const searchLabTestsSuccess = labTestIds =>
  createAction(SEARCH_LAB_TESTS_SUCCESS, { labTestIds });
export const searchLabCertificates = searchParams =>
  createAction(SEARCH_LAB_CERTIFICATES, { searchParams });
export const searchLabCertificatesSuccess = labCerts =>
  createAction(SEARCH_LAB_CERTIFICATES_SUCCESS, { labCerts });
export const clearLabCertificateSearch = () =>
  createAction(CLEAR_LAB_CERTIFICATE_SEARCH);
export const clearLabTestSearch = () => createAction(CLEAR_LAB_TEST_SEARCH);
export const labSendToNvslRequest = labTest =>
  createAction(LAB_SEND_TO_NVSL_REQUEST, { labTest });
export const labSendToNvslFinished = () =>
  createAction(LAB_SEND_TO_NVSL_FINISHED);
export const labAttachNvslReportRequest = (labTestId, file, signAfterAttach) =>
  createAction(LAB_ATTACH_NVSL_REPORT_REQUEST, {
    labTestId,
    file,
    signAfterAttach
  });
export const labAttachNvslReportFinished = (attachmentId, signAfterAttach) =>
  createAction(LAB_ATTACH_NVSL_REPORT_FINISHED, {
    attachmentId,
    signAfterAttach
  });
export const labRemoveNvslReportRequest = labTestId =>
  createAction(LAB_REMOVE_NVSL_REPORT_REQUEST, { labTestId });
export const labRemoveNvslReportFinished = removed =>
  createAction(LAB_REMOVE_NVSL_REPORT_FINISHED, { removed });
