import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as api from 'utils/api';
import { getUserId } from 'containers/app/store/user/userSelectors';
import { setUserSettings } from 'utils/userSettingsUtil';

import {
  //Notifications
  userRefreshNotificationsStarted,
  userNotificationsSuccess,
  userNotificationsFailure,
  userGetProfileSuccess,
  userUpdateProfileDone,
  userGetInfoSuccess
} from 'containers/app/store/user/userActions';
import { apiFailure } from 'containers/app/store/data/dataActions';
import {
  USER_NOTIFICATIONS_REQUEST,
  UPDATE_USER_SETTINGS,
  USER_GET_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_REQUEST
} from 'containers/app/store/user/userConstants';
import intl from 'react-intl-universal';
import { message } from 'antd';

//Notifications
export function* onUserNotificationsRequest(action) {
  try {
    yield put(userRefreshNotificationsStarted());
    const notifications = yield call(api.get, 'message/get');
    yield put(userNotificationsSuccess(notifications));
  } catch (err) {
    yield put(apiFailure(err));
    yield put(userNotificationsFailure());
  }
}

//Settings
export function* onUpdateUserSettings({ settings }) {
  const userId = yield select(getUserId);
  yield call(setUserSettings, userId, settings);
}

// Profile
export function* onUserGetProfileRequest(action) {
  try {
    const profile = yield call(api.get, '/profile');
    yield put(userGetProfileSuccess(profile));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onUserUpdateProfileRequest({ params }) {
  try {
    const profile = yield call(api.post, '/profile', params);
    yield put(userGetProfileSuccess(profile));
    message.success(intl.get('settings.user.updatedProfile'));
    const info = yield call(api.get, '/user/info');
    yield put(userGetInfoSuccess(info));
  } catch (err) {
    yield put(apiFailure(err));
  }
  yield put(userUpdateProfileDone());
}

export default function* appUserSagas() {
  yield takeLatest(USER_NOTIFICATIONS_REQUEST, onUserNotificationsRequest);
  yield takeLatest(UPDATE_USER_SETTINGS, onUpdateUserSettings);
  yield takeLatest(USER_GET_PROFILE_REQUEST, onUserGetProfileRequest);
  yield takeLatest(USER_UPDATE_PROFILE_REQUEST, onUserUpdateProfileRequest);
}
