import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as api from 'utils/api';
import * as types from './newportClinicActionTypes';
import * as actions from './newportClinicActions';

export function* onListRequest() {
  try {
    yield put(actions.isRefreshing(true));
    const response = yield call(api.get, '/list/newportClinicRefs');
    yield put(actions.listSuccess(response));
  } catch (err) {
    yield put(apiFailure(err, 'Error loading clinic lookup data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* newportClinicSagas() {
  yield takeLatest(types.LIST_REQUEST, onListRequest);
}
