// This store holds data used by Clinic accounts
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import {
  CLINIC_LOAD_STARTUP_DATA_SUCCESS,
  CLINIC_LOAD_STARTUP_DATA_STARTED,
  CLINIC_LOAD_STARTUP_DATA_FINISHED,
  // cvi
  CLINIC_REFRESH_CVI_LIST_SUCCESS,
  CLINIC_REFRESH_CVI_LIST_STARTED,
  CLINIC_REFRESH_CVI_LIST_FAILURE,
  CLINIC_REFRESH_EIA_LIST_SUCCESS,
  CLINIC_REFRESH_EIA_LIST_STARTED,
  CLINIC_REFRESH_EIA_LIST_FAILURE,
  CLINIC_ACTIVATE_CVI,
  CLINIC_DEACTIVATE_CVI,
  CLINIC_REFRESH_CVI_SUCCESS,
  CLINIC_REFRESH_CVI_STARTED,
  CLINIC_REFRESH_CVI_FAILURE,
  CLINIC_ACTIVATE_EIA,
  CLINIC_DEACTIVATE_EIA,
  CLINIC_REFRESH_EIA_SUCCESS,
  CLINIC_REFRESH_EIA_STARTED,
  CLINIC_REFRESH_EIA_FAILURE,
  CLINIC_ARCHIVE_EIAS_REQUEST,
  CLINIC_DELETE_EIAS_REQUEST,
  CLINIC_EIA_LIST_READY,
  CLINIC_SAVE_EIA_REQUEST,
  CLINIC_SAVE_EIA_SUCCESS,
  CLINIC_AUTO_ARCHIVE_EIA_SUCCESS,
  CLINIC_SAVE_EIA_FAILURE,
  CLINIC_SIGN_EIA_REQUEST,
  CLINIC_SIGN_EIA_SUCCESS,
  CLINIC_SIGN_EIA_FAILURE,
  CLINIC_BATCH_SIGN_EIA_REQUEST,
  CLINIC_BATCH_SIGN_EIA_SUCCESS,
  CLINIC_BATCH_SIGN_EIA_FAILURE,
  CLINIC_CREATE_CVI_FROM_EIA_REQUEST,
  CLINIC_CREATE_CVI_FROM_EIA_SUCCESS,
  CLINIC_CREATE_CVI_FROM_EIA_FAILURE,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED,
  CLINIC_SAVE_CVI_REQUEST,
  CLINIC_SIGN_CVI_REQUEST,
  CLINIC_SIGN_CVI_SUCCESS,
  CLINIC_SIGN_CVI_FAILURE,
  CLINIC_BATCH_SIGN_CVI_REQUEST,
  CLINIC_BATCH_SIGN_CVI_SUCCESS,
  CLINIC_BATCH_SIGN_CVI_FAILURE,
  CLINIC_CVI_ADD_PERMIT_SUCCESS,
  CLINIC_ARCHIVE_CVIS_REQUEST,
  CLINIC_DELETE_CVIS_REQUEST,
  CLINIC_CVI_LIST_READY,
  CLINIC_REFRESH_CONTACT_LIST_SUCCESS,
  CLINIC_REFRESH_CONTACT_SUCCESS,
  CLINIC_REFRESH_CONTACT_LIST_STARTED,
  CLINIC_REFRESH_CONTACT_LIST_FAILURE,
  CLINIC_DELETE_CVI_REQUEST,
  CLINIC_DELETE_CVI_FAILURE,
  // EECVI
  CLINIC_REFRESH_EECVI_LIST_STARTED,
  CLINIC_REFRESH_EECVI_LIST_FAILURE,
  CLINIC_REFRESH_EECVI_LIST_SUCCESS,
  CLINIC_ACTIVATE_EECVI,
  CLINIC_DEACTIVATE_EECVI,
  CLINIC_REFRESH_EECVI_SUCCESS,
  CLINIC_REFRESH_EECVI_STARTED,
  CLINIC_REFRESH_EECVI_FAILURE,
  CLINIC_EECVI_LIST_READY,
  CLINIC_SAVE_EECVI_REQUEST,
  CLINIC_COMMIT_EECVI_REQUEST,
  // vfd
  CLINIC_REFRESH_VETS_LIST_SUCCESS,
  CLINIC_REFRESH_VFDS_LIST_STARTED,
  CLINIC_REFRESH_VFDS_LIST_SUCCESS,
  CLINIC_REFRESH_VFDS_LIST_FAILURE,
  CLINIC_ACTIVATE_VFD,
  CLINIC_DEACTIVATE_VFD,
  CLINIC_REFRESH_VFD_STARTED,
  CLINIC_REFRESH_VFD_FAILURE,
  CLINIC_SAVE_VFD_REQUEST,
  CLINIC_SAVE_VFD_SUCCESS,
  CLINIC_SAVE_VFD_FAILURE,
  CLINIC_PROCESS_VFDS_REQUEST,
  CLINIC_ARCHIVE_VFDS_REQUEST,
  CLINIC_DELETE_VFDS_REQUEST,
  CLINIC_VFD_LIST_READY,
  // Rabies
  CLINIC_REFRESH_RABIES_LIST_STARTED,
  CLINIC_REFRESH_RABIES_LIST_SUCCESS,
  CLINIC_REFRESH_RABIES_LIST_FAILURE,
  CLINIC_RABIES_LIST_READY,
  CLINIC_SAVE_RABIES_REQUEST,
  CLINIC_REFRESH_RABIES_SUCCESS,
  CLINIC_REFRESH_RABIES_STARTED,
  CLINIC_REFRESH_RABIES_FAILURE,
  CLINIC_SIGN_RABIES_SUCCESS,
  CLINIC_BATCH_SIGN_RABIES_REQUEST,
  CLINIC_BATCH_SIGN_RABIES_SUCCESS,
  CLINIC_BATCH_SIGN_RABIES_FAILURE,
  // Ihcs
  CLINIC_REFRESH_IHC_LIST_STARTED,
  CLINIC_REFRESH_IHC_LIST_SUCCESS,
  CLINIC_REFRESH_IHC_LIST_FAILURE,
  CLINIC_IHC_LIST_READY,
  CLINIC_REFRESH_IHC_SUCCESS,
  CLINIC_REFRESH_IHC_STARTED,
  CLINIC_REFRESH_IHC_FAILURE,
  CLINIC_SAVE_IHC_REQUEST,
  CLINIC_SIGN_IHC_SUCCESS,
  CLINIC_BATCH_SIGN_IHC_REQUEST,
  CLINIC_BATCH_SIGN_IHC_SUCCESS,
  CLINIC_BATCH_SIGN_IHC_FAILURE,

  // Generic Document
  CLINIC_CLEAR_DOCUMENT_ERRORS,
  CLINIC_VOID_DOCUMENT_REQUEST,
  CLINIC_VOID_DOCUMENT_SUCCESS,
  CLINIC_VOID_DOCUMENT_FAILURE,
  CLINIC_SAVE_DOC_SUCCESS,
  CLINIC_REFRESH_DOC_SUCCESS,
  CLINIC_LOCK_DOC_REQUEST,
  CLINIC_UNLOCK_DOC_REQUEST,
  CLINIC_REMOVE_DOC_SUCCESS,
  CLINIC_DOC_REQUEST_DONE,
  CLINIC_COPY_DOC_REQUEST,

  //Animal
  CLINIC_REFRESH_ANIMAL_LIST_SUCCESS,
  CLINIC_REFRESH_ANIMAL_SUCCESS,
  CLINIC_REFRESH_ANIMAL_LIST_STARTED,
  CLINIC_REFRESH_ANIMAL_LIST_FAILURE,
  CLINIC_DELETE_ANIMAL_STARTED,
  CLINIC_ANIMAL_REMOVED_FROM_LIST,
  CLINIC_ANIMAL_LIST_READY,
  CLINIC_DELETE_ANIMAL_SUCCESS,
  CLINIC_DELETE_ANIMAL_FAILURE,
  CLINIC_ANIMAL_UPDATED_SUCCESS,
  //Image
  CLINIC_REFRESH_IMAGE_LIST_SUCCESS,
  CLINIC_REFRESH_IMAGE_LIST_STARTED,
  CLINIC_REFRESH_IMAGE_LIST_FAILURE,
  CLINIC_IMAGE_UPDATE_SUCCESS,
  CLINIC_IMAGE_DELETE_SUCCESS,
  // Document model collections
  CLINIC_RESET_DOCUMENT_MODELS,
  CLINIC_CONTACT_UPDATE,
  CLINIC_PREMISES_UPDATE,
  CLINIC_FETCH_CARRIERS_REQUEST,
  CLINIC_FETCH_CARRIERS_SUCCESS,
  CLINIC_FETCH_CARRIERS_FAILURE,
  CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS,
  CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE,
  // Requests
  CLINIC_REQUEST_REMOVED_FROM_LIST,
  //Contact
  CLINIC_DELETE_CONTACT_STARTED,
  CLINIC_CONTACT_REMOVED_FROM_LIST,
  CLINIC_CONTACT_LIST_READY,
  CLINIC_DELETE_CONTACT_SUCCESS,
  CLINIC_DELETE_CONTACT_FAILURE,
  //Home
  CLINIC_REFRESH_HOME_LIST_STARTED,
  CLINIC_REFRESH_HOME_LIST_SUCCESS,
  CLINIC_REFRESH_HOME_LIST_FAILURE,

  //Animal
  CLINIC_ANIMAL_LIST_UPDATE,

  // Certificate Search
  CLINIC_CERTIFICATES_SEARCH_REQUEST,
  CLINIC_CERTIFICATES_SEARCH_SUCCESS,
  CLINIC_CERTIFICATES_SEARCH_FAILURE,
  CLINIC_CERTIFICATES_SEARCH_CLEAR,

  // Create Document from Certificate
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST,
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS,
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE,

  // Generic Clinic
  CLINIC_SET_REFRESH_DOMAIN,
  CLINIC_REFRESH_DOMAIN_LIST_FAILURE
} from 'containers/accounts/clinic/store/accountsClinicConstants';
import { getFinalTestResult } from 'utils/labTestUtils';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: {
    inHouseLab: null,
    defaultLab: null,
    gvlLabs: null,
    vfdDrugSpecies: null,
    scriptDrugSpecies: null,
    ads: null,
    forSaleLookupLists: null,
    districtOffices: null,
    clinic: {}
  },
  dataLists: {
    // ***
    // WARNING: If you add a new document type to the dataLists, please add logic to saga onClinicVoidDocumentSuccess
    // ***

    // TODO: add a shared refreshing state instead of the same bool flag for each sub list
    // ex: refreshing:'cvis', refreshing: false, refreshing: 'animals'
    home: {
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    cvi: {
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    eecvi: {
      // TODO: Change eecvi to ehc so that it matches the actual document type.
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    contact: {
      data: {
        contactIds: [],
        contacts: {},
        active: {
          // Keeps track of which Contact is being actively worked on.
          id: null,
          // `true` if there is a pending api call for the active Contact.
          loading: false,
          deleting: false
        }
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      lastRefreshedId: null
    },
    eia: {
      data: {
        documents: {},
        requests: {}
      },
      requiredCountyStatus: null,
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      createdCviId: null // This will be set to the CVI Id that is created from an EIA
    },
    vfd: {
      data: {
        documents: {},
        requests: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    rabies: {
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    ihc: {
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    isolate: {
      data: {
        isolates: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    animal: {
      data: {
        animalsIds: [],
        animals: {},
        partialAnimals: {},
        active: {
          id: null,
          loading: false,
          deleting: false,
          action: null
        }
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      lastRefreshedId: null
    },
    certificatesSearch: {
      results: null, // Will be null when a search has not yet run, or when the search is cleared.
      searching: false,
      certType: null,
      dateRange: null,
      voidedCertificateCount: 0 // This is stored separately from results since it is aquired through a separate API call
    },
    documentModels: {
      key: {
        docType: '',
        id: 0
      },
      refreshingActiveDocument: false,
      handlingBatchAction: false,
      signingActiveDocument: false,
      fetchingCarriersForClass: null,
      contactsCollection: {},
      premisesCollection: {},
      partialDocSEResult: null,
      voiding: false
    },
    documentFromCertificate: {
      creating: false,
      certificate: null,
      createdDocument: null
    }
  },
  imageData: {
    images: {},
    refreshing: true,
    lastRefreshedTime: null,
    refreshFailure: false
  }
});

export const onClinicLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      inHouseLab: startupData.inHouseLab,
      defaultLab: startupData.defaultLab,
      gvlLabs: startupData.gvlLabs,
      vfdDrugSpecies: startupData.vfdDrugSpecies,
      scriptDrugSpecies: startupData.scriptDrugSpecies,
      ads: startupData.ads,
      forSaleLookupLists: startupData.forSaleLookupLists,
      districtOffices: startupData.districtOffices,
      clinic: startupData.clinic
    })
  });

export const onClinicLoadStartupDataStarted = state =>
  state.merge({
    retrievingStartupData: true
  });

export const onClinicLoadStartupDataFinished = state =>
  state.merge({
    retrievingStartupData: false
  });

// CVIs

export const onClinicRefreshCviListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(cvi => (documents[cvi.id] = cvi));
    }
  });
  return state.setIn(['dataLists', 'cvi'], {
    data: { documents: documents, active: {} },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshCviListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      cvi: {
        data: state.dataLists.cvi.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshCviListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      cvi: {
        data: initialState.dataLists.cvi.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

// eecvi
export const onClinicRefreshEecviListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(eecvi => (documents[eecvi.id] = eecvi));
    }
  });
  return state.setIn(['dataLists', 'eecvi'], {
    data: { documents: documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshEecviListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      eecvi: {
        data: state.dataLists.eecvi.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshEecviListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      eecvi: {
        data: initialState.dataLists.eecvi.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

// TODO: a common flag for dataLists 'refreshing' state since there is no way to refresh multiple lists at once.
// export const onClinicListReady = state =>
//   Immutable.setIn(state, ['dataLists', 'refreshing'], false);

export const onClinicEecviListReady = state =>
  Immutable.setIn(state, ['dataLists', 'eecvi', 'refreshing'], false);

// TODO: reuse setRefreshActiveDocToFalse or setRefreshActiveDocToTrue?
export const onClinicActivateEecvi = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    payload.id && !(payload.id in state.dataLists.eecvi.data.documents)
  );

export const onClinicRefreshEecviSuccess = (state, docs) => {
  // Replace old drafts with new drafts, instead of using deep:true which would potentially retain old data.
  const updatedDocs = state.dataLists.eecvi.data.documents.merge(docs);
  let newState = setRefreshActiveDocToFalse(state);
  return newState.updateIn(['dataLists', 'eecvi'], eecviData => {
    return {
      ...eecviData,
      refreshing: false,
      data: { documents: updatedDocs }
    };
  });
};

// Contact
export const onClinicRefreshContactListSuccess = (state, payload) => {
  const { list } = payload;
  let contactIds = [];
  let contacts = {};
  list.forEach(data => {
    contactIds.push(data.id);
    contacts[data.id] = data;
  });
  return state.merge({
    dataLists: state.dataLists.merge({
      contact: {
        data: {
          contactIds: contactIds,
          contacts: contacts
        },
        refreshing: false,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      }
    })
  });
};

export const onClinicRefreshContactSuccess = (state, { contact }) => {
  let contactIds = new Set(state.dataLists.contact.data.contactIds);
  contactIds.add(contact.id);
  let contacts = state.dataLists.contact.data.contacts.set(contact.id, contact);

  return state.merge({
    dataLists: state.dataLists.merge({
      contact: state.dataLists.contact.merge({
        data: state.dataLists.contact.data.merge({
          contactIds,
          contacts
        }),
        lastRefreshedId: contact.id
      })
    })
  });
};

export const onClinicRefreshContactListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      contact: {
        data: state.dataLists.contact.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshContactListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      contact: {
        data: initialState.dataLists.contact.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicActivateCvi = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    payload.id && !(payload.id in state.dataLists.cvi.data.documents)
  );

export const onClinicRefreshCviSuccess = (state, payload) => {
  const { cvi } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  if (!cvi) {
    // The CVI was deleted, don't need to put a new object in the store
    return newState;
  }
  return Immutable.setIn(
    newState,
    ['dataLists', 'cvi', 'data', 'documents', cvi.id],
    cvi
  );
};

export const onClinicAnimalListUpdate = (state, payload) => {
  const { animal } = payload;
  return Immutable.setIn(
    state,
    ['dataLists', 'animal', 'data', 'animals', animal.id],
    animal
  );
};

export const onClinicSignCviSuccess = (state, payload) => {
  const { cvi } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  newState = setSigningActiveDocToFalse(newState);
  return Immutable.setIn(
    newState,
    ['dataLists', 'cvi', 'data', 'documents', cvi.id],
    cvi
  );
};

export const onClinicBatchSignCviSuccess = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { signedCvis, errorIds, notReadyToSignList } = payload;

  // Updated documents
  signedCvis.forEach(signedCvi => {
    newState.dataLists.cvi.data.documents[signedCvi.id] = {
      ...newState.dataLists.cvi.data.documents[signedCvi.id],
      metadata: {
        ...newState.dataLists.cvi.data.documents[signedCvi.id].metadata,
        status: 'SIGNED'
      },
      version: signedCvi.version,
      serialNumber: signedCvi.serialNumber,
      signingDate: signedCvi.signingDate
    };
  });

  // Add error indicator to errorIds documents
  errorIds.forEach(id => {
    newState.dataLists.cvi.data.documents[id].tableRowClass = 'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignCvi => {
    newState.dataLists.cvi.data.documents[notReadyToSignCvi.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicBatchSignCviFailure = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { readyToSignList, notReadyToSignList } = payload;

  // Add error indicator to all that were ready to sign since they all failed with an api failure
  readyToSignList.forEach(cvi => {
    newState.dataLists.cvi.data.documents[cvi.id].tableRowClass =
      'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignCvi => {
    newState.dataLists.cvi.data.documents[notReadyToSignCvi.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicCviAddPermitSuccess = (state, payload) => {
  // Update document
  return Immutable.setIn(
    state,
    ['dataLists', 'cvi', 'data', 'documents', payload.cvi.id],
    payload.cvi
  );
};

export const onClinicCviListRefreshing = state =>
  Immutable.setIn(state, ['dataLists', 'cvi', 'refreshing'], true);

export const onClinicCviListReady = state =>
  Immutable.setIn(state, ['dataLists', 'cvi', 'refreshing'], false);

// TODO: use saveDocInStore
export const onClinicCopyCviSuccess = (state, payload) => {
  let newState = setRefreshActiveDocToFalse(state);
  // Update document
  return Immutable.setIn(
    newState,
    ['dataLists', 'cvi', 'data', 'documents', payload.cvi.id],
    payload.cvi
  );
};

//EIAs

export const onClinicRefreshEiaListSuccess = (state, payload) => {
  const { documents, requests } = payload;
  const documentsObject = {};

  documents.forEach(eia => {
    documentsObject[eia.id] = eia;
    const finalTestResult = getFinalTestResult(eia.test);
    if (finalTestResult && finalTestResult !== 'Negative') {
      // Add row styling for negative test results
      documentsObject[eia.id].tableRowClass = 'gvl-row-error';
    }
  });

  const requestsObject = {};
  requests.forEach(
    request =>
      (requestsObject[request.id] = removeUnnecessaryFieldsFromEiaRequest(
        request
      ))
  );
  return state.setIn(['dataLists', 'eia'], {
    data: { documents: documentsObject, requests: requestsObject },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshEiaListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      eia: {
        data: state.dataLists.eia.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      },
      documentModels: {
        ...state.dataLists.documentModels,
        premisesCollection:
          initialState.dataLists.documentModels.premisesCollection
      }
    })
  });

export const onClinicRefreshEiaListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      eia: {
        data: initialState.dataLists.eia.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicActivateEia = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    payload.id &&
      !(
        payload.id in
        state.dataLists.eia.data[payload.isRequest ? 'requests' : 'documents']
      )
  );

const removeUnnecessaryFieldsFromEiaRequest = eiaRequest => {
  if (eiaRequest.requestedObject.owner) {
    delete eiaRequest.requestedObject.owner.id;
  } else {
    eiaRequest.requestedObject.owner = {};
  }
  if (eiaRequest.requestedObject.animal) {
    delete eiaRequest.requestedObject.animal.owner;
  } else {
    eiaRequest.requestedObject.animal = {};
  }
  return eiaRequest;
};

// TODO: explote potential to replace with refreshDocInStore
export const onClinicRefreshEiaSuccess = (state, payload) => {
  const { eia } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  if (!eia) {
    // The EIA was deleted, don't need to put a new object in the store
    return newState;
  }
  return Immutable.setIn(
    newState,
    [
      'dataLists',
      'eia',
      'data',
      eia.metadata.status === 'REQUESTED' ? 'requests' : 'documents',
      eia.id
    ],
    eia.metadata.status === 'REQUESTED'
      ? removeUnnecessaryFieldsFromEiaRequest(eia)
      : eia
  );
};

export const setRefreshActiveDocToFalse = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    false
  );

export const setRefreshActiveDocToTrue = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    true
  );

export const setSigningActiveDocToFalse = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'signingActiveDocument'],
    false
  );

export const setSigningActiveDocToTrue = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'signingActiveDocument'],
    true
  );

export const onClinicEiaListRefreshing = state =>
  Immutable.setIn(state, ['dataLists', 'eia', 'refreshing'], true);

export const onClinicEiaListReady = state =>
  Immutable.setIn(state, ['dataLists', 'eia', 'refreshing'], false);

export const onClinicAddRequiredEiaCountyRequest = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'eia', 'requiredCountyStatus'],
    'pending'
  );

export const onClinicAddRequiredEiaCountySuccess = state =>
  Immutable.setIn(state, ['dataLists', 'eia', 'requiredCountyStatus'], 'added');

export const onClinicAddRequiredEiaCountyFailed = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'eia', 'requiredCountyStatus'],
    'failed'
  );

export const onClinicAutoArchiveEiaSuccess = (state, payload) => {
  const { id } = payload;

  // Update the archived flag so we don't archive again if document is access again from list.
  // Just updating the archived flag so that the document will remain in the list until refreshed.
  // This mimics the behavior from Neptr
  return Immutable.setIn(
    state,
    ['dataLists', 'eia', 'data', 'documents', id, 'metadata', 'archived'],
    true
  );
};

export const onClinicSignEiaSuccess = (state, payload) => {
  const { eia } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  newState = setSigningActiveDocToFalse(newState);
  return Immutable.setIn(
    newState,
    ['dataLists', 'eia', 'data', 'documents', eia.id],
    eia
  );
};

export const onClinicSignRabiesSuccess = (state, payload) => {
  const { rabies } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  newState = setSigningActiveDocToFalse(newState);
  return Immutable.setIn(
    newState,
    ['dataLists', 'rabies', 'data', 'documents', rabies.id],
    rabies
  );
};

export const onClinicBatchSignEiaSuccess = (state, payload) => {
  let newState = state.asMutable({ deep: true });
  const { signedEias, errorIds, notReadyToSignList } = payload;

  // Updated documents
  signedEias.forEach(signedEia => {
    newState.dataLists.eia.data.documents[signedEia.id] = {
      ...newState.dataLists.eia.data.documents[signedEia.id],
      metadata: {
        ...newState.dataLists.eia.data.documents[signedEia.id].metadata,
        status: 'PARTIALLY_SIGNED'
      },
      version: signedEia.version,
      serialNumber: signedEia.serialNumber,
      signingDate: signedEia.signingDate
    };
  });

  // Add error indicator to errorIds documents
  errorIds.forEach(id => {
    newState.dataLists.eia.data.documents[id].tableRowClass = 'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignEia => {
    newState.dataLists.eia.data.documents[notReadyToSignEia.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicBatchSignEiaFailure = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { readyToSignList, notReadyToSignList } = payload;

  // Add error indicator to all that were ready to sign since they all failed with an api failure
  readyToSignList.forEach(eia => {
    newState.dataLists.eia.data.documents[eia.id].tableRowClass =
      'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignEia => {
    newState.dataLists.eia.data.documents[notReadyToSignEia.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicCreateCviFromEiaSuccess = (state, payload) => {
  const { cvi } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  newState = Immutable.setIn(
    newState,
    ['dataLists', 'eia', 'createdCviId'],
    cvi.id
  );

  return Immutable.setIn(
    newState,
    ['dataLists', 'cvi', 'data', 'documents', cvi.id],
    cvi
  );
};

// VFDs

export const onClinicRefreshVfdsListSuccess = (state, payload) => {
  const { documents, requests } = payload;
  const documentsObject = {};
  documents.forEach(vfd => (documentsObject[vfd.id] = vfd));
  const requestsObject = {};
  requests.forEach(request => (requestsObject[request.id] = request));
  const newState = setRefreshActiveDocToFalse(state);
  return newState.setIn(['dataLists', 'vfd'], {
    data: { documents: documentsObject, requests: requestsObject },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshVetsListSuccess = (state, list) => {
  let vets = {};
  Object.values(list).forEach(vet => {
    if (vet.id) {
      vets[vet.id] = vet;
    }
  });
  return state.setIn(['dataLists', 'vet'], {
    data: { vets: vets },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshVfdsListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      vfd: {
        data: state.dataLists.vfd.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshVfdsListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      vfd: {
        data: initialState.dataLists.vfd.data,
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicActivateVfd = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'refreshingActiveDocument'],
    payload.id &&
      !(
        payload.id in
        state.dataLists.vfd.data[payload.isRequest ? 'requests' : 'documents']
      )
  );

export const onClinicVfdListReady = state =>
  Immutable.setIn(state, ['dataLists', 'vfd', 'refreshing'], false);

// rabies

export const onClinicRefreshRabiesListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(rabies => (documents[rabies.id] = rabies));
    }
  });
  return state.setIn(['dataLists', 'rabies'], {
    data: { documents: documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshRabiesListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      rabies: {
        data: state.dataLists.rabies.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshRabiesListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      rabies: {
        data: initialState.dataLists.rabies.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicRabiesListReady = state =>
  Immutable.setIn(state, ['dataLists', 'rabies', 'refreshing'], false);

export const onClinicRefreshRabiesSuccess = (state, payload) => {
  const { docs } = payload;
  // Replace old drafts with new drafts, instead of using deep:true which would potentially retain old data.
  // TODO: does refreshDocInStore need this merge line instead of the check for if doc even exists?
  const updatedDocs = state.dataLists.rabies.data.documents.merge(docs);
  let newState = setRefreshActiveDocToFalse(state);
  return newState.updateIn(['dataLists', 'rabies'], prevState => ({
    ...prevState,
    refreshing: false,
    data: { documents: updatedDocs }
  }));
};

export const onClinicBatchSignRabiesSuccess = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { signedRabies, errorIds, notReadyToSignList } = payload;

  // Updated documents
  signedRabies.forEach(signedRabiesCert => {
    newState.dataLists.rabies.data.documents[signedRabiesCert.id] = {
      ...state.dataLists.rabies.data.documents[signedRabiesCert.id],
      metadata: {
        ...state.dataLists.rabies.data.documents[signedRabiesCert.id].metadata,
        status: 'SIGNED'
      },
      version: signedRabiesCert.version,
      serialNumber: signedRabiesCert.serialNumber,
      signingDate: signedRabiesCert.signingDate
    };
  });

  // Add error indicator to errorIds documents
  errorIds.forEach(id => {
    newState.dataLists.rabies.data.documents[id].tableRowClass =
      'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignRabies => {
    newState.dataLists.rabies.data.documents[
      notReadyToSignRabies.id
    ].tableRowClass = 'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicBatchSignRabiesFailure = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { readyToSignList, notReadyToSignList } = payload;

  // Add error indicator to all that were ready to sign since they all failed with an api failure
  readyToSignList.forEach(rabies => {
    newState.dataLists.rabies.data.documents[rabies.id].tableRowClass =
      'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSignRabies => {
    newState.dataLists.rabies.data.documents[
      notReadyToSignRabies.id
    ].tableRowClass = 'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

// ihcs
export const onClinicRefreshIhcListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(ihc => (documents[ihc.id] = ihc));
    }
  });
  return state.setIn(['dataLists', 'ihc'], {
    data: { documents: documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshIhcListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      ihc: {
        data: state.dataLists.ihc.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshIhcListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      ihc: {
        data: initialState.dataLists.ihc.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicIhcListReady = state =>
  Immutable.setIn(state, ['dataLists', 'ihc', 'refreshing'], false);

export const onClinicRefreshIhcSuccess = (state, payload) => {
  const { docs } = payload;
  // Replace old drafts with new drafts, instead of using deep:true which would potentially retain old data.
  const updatedDocs = state.dataLists.ihc.data.documents.merge(docs);
  let newState = setRefreshActiveDocToFalse(state);
  return newState.updateIn(['dataLists', 'ihc'], prevState => ({
    ...prevState,
    refreshing: false,
    data: { documents: updatedDocs }
  }));
};

export const onClinicSignIhcSuccess = (state, payload) => {
  const { ihc } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  newState = setSigningActiveDocToFalse(newState);
  return Immutable.setIn(
    newState,
    ['dataLists', 'ihc', 'data', 'documents', ihc.id],
    ihc
  );
};

export const onClinicBatchSignIhcSuccess = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { signedIhcs, errorIds, notReadyToSignList } = payload;

  // Updated documents
  signedIhcs.forEach(signedCert => {
    newState.dataLists.ihc.data.documents[signedCert.id] = {
      ...state.dataLists.ihc.data.documents[signedCert.id],
      metadata: {
        ...state.dataLists.ihc.data.documents[signedCert.id].metadata,
        status: 'SIGNED'
      },
      version: signedCert.version,
      serialNumber: signedCert.serialNumber,
      signingDate: signedCert.signingDate
    };
  });

  // Add error indicator to errorIds documents
  errorIds.forEach(id => {
    newState.dataLists.ihc.data.documents[id].tableRowClass = 'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSign => {
    newState.dataLists.ihc.data.documents[notReadyToSign.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

export const onClinicBatchSignIhcFailure = (state, payload) => {
  let newState = state.asMutable({ deep: true });

  const { readyToSignList, notReadyToSignList } = payload;

  // Add error indicator to all that were ready to sign since they all failed with an api failure
  readyToSignList.forEach(ihc => {
    newState.dataLists.ihc.data.documents[ihc.id].tableRowClass =
      'gvl-row-error';
  });

  // Add warning indicator to notReadyToSignList documents
  notReadyToSignList.forEach(notReadyToSign => {
    newState.dataLists.ihc.data.documents[notReadyToSign.id].tableRowClass =
      'gvl-row-warning';
  });

  newState.dataLists.documentModels.handlingBatchAction = false;

  return Immutable.from(newState);
};

// Generic Document reducers

export const onClinicClearDocumentErrors = (state, payload) => {
  const { docType } = payload;
  const docs = Object.values(state.dataLists[docType].data.documents).filter(
    doc => doc.tableRowClass
  );

  // if no errors, nothing to change
  if (docs.length === 0) {
    return state;
  }

  // create list of documents to change
  let mergeData = {};
  for (const doc of docs) {
    mergeData[doc.id] = doc.without('tableRowClass');
  }

  // return new state
  return state.setIn(
    ['dataLists', docType, 'data', 'documents'],
    state.dataLists[docType].data.documents.merge(mergeData)
  );
};

export const voidingStarted = state =>
  Immutable.setIn(state, ['dataLists', 'documentModels', 'voiding'], true);

export const voidingFinished = state => {
  return Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'voiding'],
    false
  );
};

export const saveDocInStore = (state, payload) => {
  const { doc } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  return Immutable.setIn(
    newState,
    ['dataLists', doc.type.toLowerCase(), 'data', 'documents', doc.id],
    doc
  );
};

// TODO: possible to merge this with SaveDocSuccess.
export const refreshDocInStore = (state, payload) => {
  const { doc } = payload;
  let newState = setRefreshActiveDocToFalse(state);
  // was originally used in both a success and fail case where the doc may not have been included
  if (!doc) {
    return newState;
  }
  return Immutable.setIn(
    newState,
    [
      'dataLists',
      doc.type.toLowerCase(),
      'data',
      doc.metadata.status === 'REQUESTED' ? 'requests' : 'documents',
      doc.id
    ],
    doc
  );
};

export const setHandlingBatchActionTrue = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'handlingBatchAction'],
    true
  );

export const deleteDocFromStore = (state, payload) => {
  let newState = setRefreshActiveDocToFalse(state);
  return newState.updateIn(
    ['dataLists', payload.docType, 'data', 'documents'],
    documents => documents.without(payload.id)
  );
};

//Animal
export const onClinicRefreshAnimalListSuccess = (state, lists) => {
  let animalsIds = [];
  let animals = {};
  let partialAnimals = {};
  lists.animals.forEach(animal => {
    animalsIds.push(animal.id);
    animals[animal.id] = animal;
  });
  lists.partialAnimals.forEach(animal => {
    partialAnimals[animal.id] = animal;
  });
  return state.merge({
    dataLists: state.dataLists.merge({
      animal: state.dataLists.animal.merge({
        data: state.dataLists.animal.data.merge({
          animalsIds: animalsIds,
          animals: animals,
          partialAnimals: partialAnimals
        }),
        refreshing: false,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      })
    })
  });
};

export const onclinicVerboseAnimalListSuccess = (state, fullAnimals) => {
  let animalsIds = [];
  let animals = {};
  fullAnimals.forEach(animal => {
    animalsIds.push(animal.id);
    animals[animal.id] = animal;
  });
  return state.merge({
    dataLists: state.dataLists.merge({
      animal: state.dataLists.animal.merge({
        data: state.dataLists.animal.data.merge({
          animalsIds: animalsIds,
          animals: animals
        }),
        refreshing: false,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      })
    })
  });
};

export const onClinicRefreshAnimalSuccess = (state, { animal }) => {
  let animalsIds = new Set(state.dataLists.animal.data.animalIds);
  animalsIds.add(animal.id);
  let animals = state.dataLists.animal.data.animals.set(animal.id, animal);

  return state.merge({
    dataLists: state.dataLists.merge({
      animal: state.dataLists.animal.merge({
        data: state.dataLists.animal.data.merge({
          animalsIds: animalsIds,
          animals: animals
        }),
        lastRefreshedId: animal.id
      })
    })
  });
};

export const onClinicRefreshAnimalListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      animal: {
        data: state.dataLists.animal.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshAnimalListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      animal: {
        data: initialState.dataLists.animal.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });
export const onClinicAnimalListRefreshing = state =>
  Immutable.setIn(state, ['dataLists', 'animal', 'refreshing'], true);
export const onClinicAnimalListReady = state =>
  Immutable.setIn(state, ['dataLists', 'animal', 'refreshing'], false);
export const onAnimalRemovedFromList = (state, payload) =>
  state.updateIn(
    [
      'dataLists',
      'animal',
      'data',
      payload.isPartial ? 'partialAnimals' : 'animals'
    ],
    data => data.without(payload.id)
  );
export const onClinicDeleteAnimalSuccess = state => {
  Immutable.setIn(state, ['dataLists', 'animal', 'refreshing'], false);
  return Immutable.setIn(
    state,
    ['dataLists', 'animal', 'data', 'active', 'deleting'],
    true
  );
};
export const onClinicDeleteAnimalFailure = state => {
  Immutable.setIn(state, ['dataLists', 'animal', 'refreshing'], false);
  return Immutable.setIn(
    state,
    ['dataLists', 'animal', 'data', 'active', 'deleting'],
    false
  );
};
export const onClinicAnimalUpdatedSuccess = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'animal', 'data', 'animals', payload.animal.id],
    payload.animal
  );
//Image
export const onClinicRefreshImageListSuccess = (state, lists) => {
  let imgs = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(it => (imgs[it.id] = it));
    }
  });

  return state.setIn(['imageData'], {
    images: imgs,
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshImageListStarted = state =>
  state.merge({
    imageData: {
      images: state.imageData.images,
      refreshing: true,
      lastRefreshedTime: null,
      refreshFailure: false
    }
  });

export const onClinicRefreshImageListFailure = state =>
  state.merge({
    imageData: {
      images: state.imageData.images, // Empty lists
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: true
    }
  });

// Update image
export const onClinicImageUpdateSuccess = (state, payload) => {
  return Immutable.setIn(
    state,
    ['imageData', 'images', payload.images.id],
    payload.images
  );
};

export const onClinicImageDeleteSuccess = (state, payload) => {
  return Immutable.updateIn(state, ['imageData', 'images'], images =>
    images.without(payload.id)
  );
};

export const onClinicResetDocumentModels = (state, payload) =>
  Immutable.updateIn(state, ['dataLists', 'documentModels'], documentModels => {
    const didSaveNewDocument =
      documentModels.key.docType === payload.docType &&
      !documentModels.key.id &&
      payload.id;
    const newDocumentModels = {
      ...initialState.dataLists.documentModels,
      key: { docType: payload.docType, id: payload.id },
      // Preserve the refreshingActiveDocument flag.
      refreshingActiveDocument: documentModels.refreshingActiveDocument
    };
    if (didSaveNewDocument) {
      // If a new document was saved, preserve the contacts and premises collections
      newDocumentModels.contactsCollection = documentModels.contactsCollection;
      newDocumentModels.premisesCollection = documentModels.premisesCollection;
    }
    return newDocumentModels;
  });

export const onClinicContactUpdate = (state, payload) => {
  const { contact } = payload;

  let newState = state;

  if (contact.id > 0) {
    newState = Immutable.setIn(
      state,
      ['dataLists', 'contact', 'data', 'contacts', contact.id],
      contact
    );
  }

  return Immutable.setIn(
    newState,
    ['dataLists', 'documentModels', 'contactsCollection', contact.id],
    contact
  );
};

export const onClinicPremisesUpdate = (state, payload) => {
  if (Array.isArray(payload.premises)) {
    let workingState = state.asMutable({ deep: true });
    payload.premises.forEach(prem => {
      workingState.dataLists.documentModels.premisesCollection[prem.id] = prem;
    });
    return Immutable.from(workingState);
  } else {
    return Immutable.setIn(
      state,
      [
        'dataLists',
        'documentModels',
        'premisesCollection',
        payload.premises.id
      ],
      payload.premises
    );
  }
};

export const onClinicFetchCarriersRequest = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'fetchingCarriersForClass'],
    payload.carrierClass
  );

export const onClinicFetchCarriersSuccess = (state, payload) =>
  Immutable.updateIn(
    state,
    ['dataLists', 'documentModels'],
    documentModels => ({
      ...documentModels,
      fetchingCarriersForClass: null,
      contactsCollection: {
        ...documentModels.contactsCollection,
        ...payload.carriers.reduce(
          (carriersObj, carrier) => ({ ...carriersObj, [carrier.id]: carrier }),
          {}
        )
      }
    })
  );

export const onClinicFetchCarriersFailure = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'fetchingCarriersForClass'],
    null
  );

export const onClinicSEVerifyPartialDocSuccess = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'partialDocSEResult'],
    payload.result
  );

export const onClinicSEVerifyPartialDocFailure = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'documentModels', 'partialDocSEResult'],
    null
  );

export const onClinicRequestRemovedFromList = (state, payload) => {
  const { docType, id } = payload;
  return state.updateIn(['dataLists', docType, 'data', 'requests'], requests =>
    requests.without(id)
  );
};

//Contacts
export const onClinicContactListRefreshing = state =>
  Immutable.setIn(state, ['dataLists', 'contact', 'refreshing'], true);
export const onClinicContactListReady = state =>
  Immutable.setIn(state, ['dataLists', 'contact', 'refreshing'], false);
export const onContactRemovedFromList = (state, payload) =>
  state.updateIn(['dataLists', 'contact', 'data', 'contacts'], data =>
    data.without(payload.id)
  );
export const onClinicDeleteContactStarted = state => {
  return Immutable.setIn(state, ['dataLists', 'contact', 'refreshing'], true);
};
export const onClinicDeleteContactSuccess = state => {
  // TODO: This following line isn't doing anything. Either make it do something or remove it.
  Immutable.setIn(state, ['dataLists', 'contact', 'refreshing'], false);
  return Immutable.setIn(
    state,
    ['dataLists', 'contact', 'data', 'active', 'deleting'],
    true
  );
};

export const onClinicDeleteContactFailure = state => {
  // TODO: This following line isn't doing anything. Either make it do something or remove it.
  Immutable.setIn(state, ['dataLists', 'contact', 'refreshing'], false);
  return Immutable.setIn(
    state,
    ['dataLists', 'contact', 'data', 'active', 'deleting'],
    false
  );
};

//Home
export const onClinicRefreshHomeListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(home => (documents[home.id] = home));
    }
  });
  return state.setIn(['dataLists', 'home'], {
    data: { documents: documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onClinicRefreshHomeListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      home: {
        data: state.dataLists.home.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onClinicRefreshHomeListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      home: {
        data: initialState.dataLists.home.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onClinicCertificatesSearchRequest = (state, payload) =>
  state.setIn(['dataLists', 'certificatesSearch'], {
    results: [],
    searching: true,
    voidedCertificateCount: 0,
    certType: payload.params.certType,
    dateRange: [payload.params.startDate, payload.params.endDate]
  });

export const onClinicCertificatesSearchSuccess = (state, payload) =>
  state.updateIn(['dataLists', 'certificatesSearch'], certificatesSearch => ({
    ...certificatesSearch,
    results: payload.results,
    searching: false,
    voidedCertificateCount: payload.voidedCertificateCount
  }));

export const onClinicCertificatesSearchReset = state =>
  state.setIn(['dataLists', 'certificatesSearch'], {
    results: null,
    searching: false,
    voidedCertificateCount: 0,
    certType: null,
    dateRange: null
  });

export const onClinicCreateDocumentFromCertificateRequest = (
  state,
  payload
) => {
  const { certificate } = payload;
  return state.setIn(['dataLists', 'documentFromCertificate'], {
    creating: true,
    createdDocument: null,
    certificate
  });
};

export const onClinicCreateDocumentFromCertificateSuccess = (
  state,
  payload
) => {
  const { createdDocument } = payload;

  const newState = state.setIn(['dataLists', 'documentFromCertificate'], {
    creating: false,
    createdDocument,
    certificate: state.dataLists.documentFromCertificate.certificate
  });

  // Add new document to its proper list
  const storeDocumentType = createdDocument.type.toLowerCase();
  if (newState.dataLists[storeDocumentType]) {
    return Immutable.setIn(
      newState,
      ['dataLists', storeDocumentType, 'data', 'documents', createdDocument.id],
      createdDocument
    );
  } else {
    return newState;
  }
};

export const onClinicCreateDocumentFromCertificateFailure = state => {
  return state.setIn(['dataLists', 'documentFromCertificate'], {
    creating: false,
    createdDocument: null,
    certificate: null
  });
};

// Generic Clinic
export const onClinicSetRefreshDomain = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', payload.domain, 'refreshing'],
    payload.value
  );

export const onClinicRefreshDomainListFailure = (state, payload) =>
  state.merge({
    dataLists: state.dataLists.merge({
      [payload.domain]: {
        data: {
          [payload.domain + 's']: {} // Empty lists
        },
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

const accountsClinicReducer = createReducer(initialState, {
  [CLINIC_LOAD_STARTUP_DATA_SUCCESS]: onClinicLoadStartupDataSuccess,
  [CLINIC_LOAD_STARTUP_DATA_STARTED]: onClinicLoadStartupDataStarted,
  [CLINIC_LOAD_STARTUP_DATA_FINISHED]: onClinicLoadStartupDataFinished,
  // eecvi
  [CLINIC_REFRESH_EECVI_LIST_SUCCESS]: onClinicRefreshEecviListSuccess,
  [CLINIC_REFRESH_EECVI_LIST_STARTED]: onClinicRefreshEecviListStarted,
  [CLINIC_REFRESH_EECVI_LIST_FAILURE]: onClinicRefreshEecviListFailure,
  [CLINIC_EECVI_LIST_READY]: onClinicEecviListReady,
  [CLINIC_ACTIVATE_EECVI]: onClinicActivateEecvi,
  [CLINIC_DEACTIVATE_EECVI]: setRefreshActiveDocToFalse,
  [CLINIC_REFRESH_EECVI_SUCCESS]: onClinicRefreshEecviSuccess,
  [CLINIC_REFRESH_EECVI_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_EECVI_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_SAVE_EECVI_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_COMMIT_EECVI_REQUEST]: setRefreshActiveDocToTrue,

  // eia
  [CLINIC_REFRESH_EIA_LIST_SUCCESS]: onClinicRefreshEiaListSuccess,
  [CLINIC_REFRESH_EIA_LIST_STARTED]: onClinicRefreshEiaListStarted,
  [CLINIC_REFRESH_EIA_LIST_FAILURE]: onClinicRefreshEiaListFailure,

  [CLINIC_ACTIVATE_EIA]: onClinicActivateEia,
  [CLINIC_REFRESH_EIA_SUCCESS]: onClinicRefreshEiaSuccess,
  [CLINIC_ARCHIVE_EIAS_REQUEST]: onClinicEiaListRefreshing,
  [CLINIC_DELETE_EIAS_REQUEST]: onClinicEiaListRefreshing,
  [CLINIC_SAVE_EIA_SUCCESS]: saveDocInStore,
  [CLINIC_AUTO_ARCHIVE_EIA_SUCCESS]: onClinicAutoArchiveEiaSuccess,
  [CLINIC_SAVE_EIA_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_REFRESH_EIA_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_DEACTIVATE_EIA]: setRefreshActiveDocToFalse,
  [CLINIC_SIGN_EIA_REQUEST]: setSigningActiveDocToTrue,
  [CLINIC_SIGN_EIA_FAILURE]: setSigningActiveDocToFalse,
  [CLINIC_SIGN_EIA_SUCCESS]: onClinicSignEiaSuccess,
  [CLINIC_SAVE_EIA_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_EIA_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_BATCH_SIGN_EIA_REQUEST]: setHandlingBatchActionTrue,
  [CLINIC_BATCH_SIGN_EIA_SUCCESS]: onClinicBatchSignEiaSuccess,
  [CLINIC_BATCH_SIGN_EIA_FAILURE]: onClinicBatchSignEiaFailure,
  [CLINIC_CREATE_CVI_FROM_EIA_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_CREATE_CVI_FROM_EIA_SUCCESS]: onClinicCreateCviFromEiaSuccess,
  [CLINIC_CREATE_CVI_FROM_EIA_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_EIA_LIST_READY]: onClinicEiaListReady,
  [CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST]: onClinicAddRequiredEiaCountyRequest,
  [CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS]: onClinicAddRequiredEiaCountySuccess,
  [CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED]: onClinicAddRequiredEiaCountyFailed,

  // cvi
  [CLINIC_REFRESH_CVI_LIST_SUCCESS]: onClinicRefreshCviListSuccess,
  [CLINIC_REFRESH_CVI_LIST_STARTED]: onClinicRefreshCviListStarted,
  [CLINIC_REFRESH_CVI_LIST_FAILURE]: onClinicRefreshCviListFailure,

  [CLINIC_REFRESH_CONTACT_LIST_SUCCESS]: onClinicRefreshContactListSuccess,
  [CLINIC_REFRESH_CONTACT_SUCCESS]: onClinicRefreshContactSuccess,
  [CLINIC_REFRESH_CONTACT_LIST_STARTED]: onClinicRefreshContactListStarted,
  [CLINIC_REFRESH_CONTACT_LIST_FAILURE]: onClinicRefreshContactListFailure,

  [CLINIC_ACTIVATE_CVI]: onClinicActivateCvi,
  [CLINIC_DEACTIVATE_CVI]: setRefreshActiveDocToFalse,
  [CLINIC_REFRESH_CVI_SUCCESS]: onClinicRefreshCviSuccess,
  [CLINIC_REFRESH_CVI_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_CVI_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_SAVE_CVI_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_SIGN_CVI_REQUEST]: setSigningActiveDocToTrue,
  [CLINIC_SIGN_CVI_SUCCESS]: onClinicSignCviSuccess,
  [CLINIC_SIGN_CVI_FAILURE]: setSigningActiveDocToFalse,
  [CLINIC_BATCH_SIGN_CVI_REQUEST]: setHandlingBatchActionTrue,
  [CLINIC_BATCH_SIGN_CVI_SUCCESS]: onClinicBatchSignCviSuccess,
  [CLINIC_BATCH_SIGN_CVI_FAILURE]: onClinicBatchSignCviFailure,
  [CLINIC_CVI_ADD_PERMIT_SUCCESS]: onClinicCviAddPermitSuccess,
  [CLINIC_ARCHIVE_CVIS_REQUEST]: onClinicCviListRefreshing,
  [CLINIC_DELETE_CVIS_REQUEST]: onClinicCviListRefreshing,
  [CLINIC_CVI_LIST_READY]: onClinicCviListReady,
  [CLINIC_DELETE_CVI_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_DELETE_CVI_FAILURE]: setRefreshActiveDocToFalse,

  // vfd
  [CLINIC_REFRESH_VETS_LIST_SUCCESS]: onClinicRefreshVetsListSuccess,
  [CLINIC_REFRESH_VFDS_LIST_SUCCESS]: onClinicRefreshVfdsListSuccess,
  [CLINIC_REFRESH_VFDS_LIST_STARTED]: onClinicRefreshVfdsListStarted,
  [CLINIC_REFRESH_VFDS_LIST_FAILURE]: onClinicRefreshVfdsListFailure,
  [CLINIC_ACTIVATE_VFD]: onClinicActivateVfd,
  [CLINIC_DEACTIVATE_VFD]: setRefreshActiveDocToFalse,
  [CLINIC_REFRESH_VFD_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_VFD_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_SAVE_VFD_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_SAVE_VFD_SUCCESS]: saveDocInStore,
  [CLINIC_SAVE_VFD_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_PROCESS_VFDS_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_ARCHIVE_VFDS_REQUEST]: refreshDocInStore,
  [CLINIC_DELETE_VFDS_REQUEST]: refreshDocInStore,
  [CLINIC_VFD_LIST_READY]: onClinicVfdListReady,

  // rabies
  [CLINIC_REFRESH_RABIES_LIST_STARTED]: onClinicRefreshRabiesListStarted,
  [CLINIC_REFRESH_RABIES_LIST_SUCCESS]: onClinicRefreshRabiesListSuccess,
  [CLINIC_REFRESH_RABIES_LIST_FAILURE]: onClinicRefreshRabiesListFailure,
  [CLINIC_RABIES_LIST_READY]: onClinicRabiesListReady,
  [CLINIC_SAVE_RABIES_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_RABIES_SUCCESS]: onClinicRefreshRabiesSuccess,
  [CLINIC_REFRESH_RABIES_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_RABIES_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_SIGN_RABIES_SUCCESS]: onClinicSignRabiesSuccess,
  [CLINIC_BATCH_SIGN_RABIES_REQUEST]: setHandlingBatchActionTrue,
  [CLINIC_BATCH_SIGN_RABIES_SUCCESS]: onClinicBatchSignRabiesSuccess,
  [CLINIC_BATCH_SIGN_RABIES_FAILURE]: onClinicBatchSignRabiesFailure,

  // ihcs
  [CLINIC_REFRESH_IHC_LIST_STARTED]: onClinicRefreshIhcListStarted,
  [CLINIC_REFRESH_IHC_LIST_SUCCESS]: onClinicRefreshIhcListSuccess,
  [CLINIC_REFRESH_IHC_LIST_FAILURE]: onClinicRefreshIhcListFailure,
  [CLINIC_IHC_LIST_READY]: onClinicIhcListReady,
  [CLINIC_REFRESH_IHC_SUCCESS]: onClinicRefreshIhcSuccess,
  [CLINIC_REFRESH_IHC_STARTED]: setRefreshActiveDocToTrue,
  [CLINIC_SAVE_IHC_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_REFRESH_IHC_FAILURE]: setRefreshActiveDocToFalse,
  [CLINIC_SIGN_IHC_SUCCESS]: onClinicSignIhcSuccess,
  [CLINIC_BATCH_SIGN_IHC_REQUEST]: setHandlingBatchActionTrue,
  [CLINIC_BATCH_SIGN_IHC_SUCCESS]: onClinicBatchSignIhcSuccess,
  [CLINIC_BATCH_SIGN_IHC_FAILURE]: onClinicBatchSignIhcFailure,

  // generic document
  [CLINIC_CLEAR_DOCUMENT_ERRORS]: onClinicClearDocumentErrors,
  [CLINIC_VOID_DOCUMENT_REQUEST]: voidingStarted,
  [CLINIC_VOID_DOCUMENT_SUCCESS]: voidingFinished,
  [CLINIC_VOID_DOCUMENT_FAILURE]: voidingFinished,
  [CLINIC_SAVE_DOC_SUCCESS]: saveDocInStore,
  [CLINIC_REFRESH_DOC_SUCCESS]: refreshDocInStore,
  [CLINIC_REMOVE_DOC_SUCCESS]: deleteDocFromStore,
  [CLINIC_COPY_DOC_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_LOCK_DOC_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_UNLOCK_DOC_REQUEST]: setRefreshActiveDocToTrue,
  [CLINIC_DOC_REQUEST_DONE]: setRefreshActiveDocToFalse,

  //Animal
  [CLINIC_REFRESH_ANIMAL_LIST_SUCCESS]: onClinicRefreshAnimalListSuccess,
  [CLINIC_REFRESH_ANIMAL_SUCCESS]: onClinicRefreshAnimalSuccess,
  [CLINIC_REFRESH_ANIMAL_LIST_STARTED]: onClinicRefreshAnimalListStarted,
  [CLINIC_REFRESH_ANIMAL_LIST_FAILURE]: onClinicRefreshAnimalListFailure,
  [CLINIC_ANIMAL_REMOVED_FROM_LIST]: onAnimalRemovedFromList,
  [CLINIC_ANIMAL_LIST_READY]: onClinicAnimalListReady,
  [CLINIC_DELETE_ANIMAL_STARTED]: onClinicAnimalListRefreshing,
  [CLINIC_DELETE_ANIMAL_SUCCESS]: onClinicDeleteAnimalSuccess,
  [CLINIC_DELETE_ANIMAL_FAILURE]: onClinicDeleteAnimalFailure,
  [CLINIC_ANIMAL_UPDATED_SUCCESS]: onClinicAnimalUpdatedSuccess,
  //Image
  [CLINIC_REFRESH_IMAGE_LIST_SUCCESS]: onClinicRefreshImageListSuccess,
  [CLINIC_REFRESH_IMAGE_LIST_STARTED]: onClinicRefreshImageListStarted,
  [CLINIC_REFRESH_IMAGE_LIST_FAILURE]: onClinicRefreshImageListFailure,
  [CLINIC_IMAGE_UPDATE_SUCCESS]: onClinicImageUpdateSuccess,
  [CLINIC_IMAGE_DELETE_SUCCESS]: onClinicImageDeleteSuccess,

  // Document model collections
  [CLINIC_RESET_DOCUMENT_MODELS]: onClinicResetDocumentModels,
  [CLINIC_CONTACT_UPDATE]: onClinicContactUpdate,
  [CLINIC_PREMISES_UPDATE]: onClinicPremisesUpdate,
  [CLINIC_FETCH_CARRIERS_REQUEST]: onClinicFetchCarriersRequest,
  [CLINIC_FETCH_CARRIERS_SUCCESS]: onClinicFetchCarriersSuccess,
  [CLINIC_FETCH_CARRIERS_FAILURE]: onClinicFetchCarriersFailure,
  [CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS]: onClinicSEVerifyPartialDocSuccess,
  [CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE]: onClinicSEVerifyPartialDocFailure,
  // Requests
  [CLINIC_REQUEST_REMOVED_FROM_LIST]: onClinicRequestRemovedFromList,
  // Contact

  [CLINIC_CONTACT_REMOVED_FROM_LIST]: onContactRemovedFromList,
  [CLINIC_CONTACT_LIST_READY]: onClinicContactListReady,
  [CLINIC_DELETE_CONTACT_STARTED]: onClinicDeleteContactStarted,
  [CLINIC_DELETE_CONTACT_SUCCESS]: onClinicDeleteContactSuccess,
  [CLINIC_DELETE_CONTACT_FAILURE]: onClinicDeleteContactFailure,
  //Home
  [CLINIC_REFRESH_HOME_LIST_SUCCESS]: onClinicRefreshHomeListSuccess,
  [CLINIC_REFRESH_HOME_LIST_STARTED]: onClinicRefreshHomeListStarted,
  [CLINIC_REFRESH_HOME_LIST_FAILURE]: onClinicRefreshHomeListFailure,

  [CLINIC_ANIMAL_LIST_UPDATE]: onClinicAnimalListUpdate,

  // Certificates Search
  [CLINIC_CERTIFICATES_SEARCH_REQUEST]: onClinicCertificatesSearchRequest,
  [CLINIC_CERTIFICATES_SEARCH_SUCCESS]: onClinicCertificatesSearchSuccess,
  [CLINIC_CERTIFICATES_SEARCH_FAILURE]: onClinicCertificatesSearchReset,
  [CLINIC_CERTIFICATES_SEARCH_CLEAR]: onClinicCertificatesSearchReset,

  // Create Document from Certificate
  [CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST]: onClinicCreateDocumentFromCertificateRequest,
  [CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS]: onClinicCreateDocumentFromCertificateSuccess,
  [CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE]: onClinicCreateDocumentFromCertificateFailure,

  // Generic Clinic
  [CLINIC_SET_REFRESH_DOMAIN]: onClinicSetRefreshDomain,
  [CLINIC_REFRESH_DOMAIN_LIST_FAILURE]: onClinicRefreshDomainListFailure
});

export default accountsClinicReducer;
