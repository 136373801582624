import { getEnvironment } from 'utils/envUtil';

export const getIntercomId = () => {
  let intercomID = 'zxbwuhzx'; // Test platform
  if (getEnvironment() === 'production') {
    intercomID = 'tdmybnko'; // Production platform
  }
  return intercomID;
};

// Code taken from Neptr
// Latest doc: https://www.intercom.com/help/install-intercom-on-your-product-or-site/other-ways-to-get-started/integrate-intercom-in-a-single-page-app

export const initialize = /* istanbul ignore next */ () => {
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // eslint-disable-next-line no-undef
      ic('update', intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = () => {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + getIntercomId();
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

// Wrap Intercom for testing purposes
export const getCommandObject = () => {
  return window.Intercom;
};

export const booted = () => {
  return window.Intercom ? window.Intercom.booted : false;
};

export const userSetup = userInfo => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  let intercomData = {
    app_id: getIntercomId(),
    name: userInfo.displayName,
    user_id: userInfo.appUserId,
    user_hash: userInfo.userHash,
    email: userInfo.email,
    username: userInfo.username,
    fullName: userInfo.name,
    role: userInfo.userRole[0],
    hide_default_launcher: true,
    alignment: 'left',
    custom_launcher_selector: '.intercom_avatar',
    currentApp: 'Cricket UI' // TODO: Figure out what we need to put here.  The account type? IE: clinic, admin, lab, etc
  };

  // Accounts in our system are not currently unique
  // In order to fix the issue, I am assigning each 'type'
  // a different prefix. This will address the issue until
  // we fully implement proper accounts

  if (userInfo.account && userInfo.account.id) {
    const { account } = userInfo;
    intercomData.account = account.name;
    intercomData.company = { ...account };
    intercomData.company.id = getPrefix(account.type) + account.id;
  }

  commandObject('shutdown'); // Get rid of previous user, if present
  commandObject('boot', intercomData);
};

export const getPrefix = accountType => {
  let prefix;
  switch (accountType) {
    case 'Laboratory':
      prefix = 'L-';
      break;
    case 'NVSL Lab':
      prefix = 'N-';
      break;
    case 'Distributor':
      prefix = 'D-';
      break;
    case 'Pharmaceutical Company':
      prefix = 'P-';
      break;
    case 'Regulatory Authority':
      prefix = 'R-';
      break;
    default:
      prefix = ''; // Do not change the ID for clinics
      break;
  }

  return prefix;
};

export const userReset = () => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  commandObject('shutdown');
};

export const trackEvent = (category, action, label, payload) => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  let content = category + ' - ' + action;
  if (label) {
    content += ' - ' + label;
  }
  commandObject('trackEvent', content, payload);
};

export const startTour = tourName => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  const isProduction = getEnvironment() === 'production';

  // Tour ID's are retrieved from Intercom in the "Product Tours" area.
  // The Intercom tour needs to be setup with a "Use your tour everywhere", then the tour ID is visible on the tour URL.
  // To get the two tour ID's, setup the tour in Intercom Production, and Intercom Test
  let tourId;
  switch (tourName) {
    case 'SendToNvsl':
      tourId = isProduction ? 100393 : 100897;
      break;
    default:
      break;
  }

  if (tourId) {
    // Intercom Documentation: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomstarttour-tourid
    commandObject('startTour', tourId);
  }
};

export const locationChange = location => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }
  commandObject('update'); // This pings Intercom since we are a SPA.  No use of location at this time.
};
