import { createAction } from 'utils/reduxUtil';
import {
  ADMIN_LOAD_STARTUP_DATA_REQUEST,
  ADMIN_LOAD_STARTUP_DATA_SUCCESS,
  ADMIN_SET_RETRIEVING_STARTUP_DATA,
  // Accounts
  ADMIN_ACTIVATE_ACCOUNT,
  ADMIN_REFRESH_ACCOUNT_REQUEST,
  ADMIN_SAVE_ACCOUNT_REQUEST,
  ADMIN_CREATE_AR_ACCOUNT_REQUEST,
  // Subscriptions
  ADMIN_SAVE_SUBSCRIPTION_REQUEST,
  ADMIN_ACTIVATE_AR_SUBSCRIPTION,
  ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST,
  ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS,
  ADMIN_SYNC_SUBSCRIPTION_REQUEST,
  // Generic
  ADMIN_ACTIVATE_DOMAIN,
  ADMIN_REFRESH_DOMAIN_REQUEST,
  ADMIN_SET_REFRESH_DOMAIN,
  ADMIN_SAVE_REQUEST,
  ADMIN_DOMAIN_ACTION_SUCCESS
} from 'containers/accounts/admin/store/accountsAdminConstants';

/* istanbul ignore file */

export const adminLoadStartupDataRequest = () =>
  createAction(ADMIN_LOAD_STARTUP_DATA_REQUEST);
export const adminLoadStartupDataSuccess = startupData =>
  createAction(ADMIN_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const adminSetRetrievingStartupData = value =>
  createAction(ADMIN_SET_RETRIEVING_STARTUP_DATA, { value });

// Accounts

export const adminActivateAccount = id =>
  createAction(ADMIN_ACTIVATE_ACCOUNT, { id });
export const adminRefreshAccountRequest = id =>
  createAction(ADMIN_REFRESH_ACCOUNT_REQUEST, { id });
export const adminSaveAccountRequest = (account, history) =>
  createAction(ADMIN_SAVE_ACCOUNT_REQUEST, { account, history });
export const adminCreateArAccountRequest = id =>
  createAction(ADMIN_CREATE_AR_ACCOUNT_REQUEST, { id });

// Subscriptions

export const adminSaveSubscriptionRequest = (
  subscription,
  relatedRecord = null,
  onSave
) =>
  createAction(ADMIN_SAVE_SUBSCRIPTION_REQUEST, {
    subscription,
    relatedRecord,
    onSave
  });
export const adminSyncSubscriptionRequest = (subscription, data, afterSync) =>
  createAction(ADMIN_SYNC_SUBSCRIPTION_REQUEST, {
    subscription,
    data,
    afterSync
  });
export const adminActivateArSubcription = (id, arSubscriptionId) =>
  createAction(ADMIN_ACTIVATE_AR_SUBSCRIPTION, { id, arSubscriptionId });
export const adminRefreshArSubscriptionRequest = id =>
  createAction(ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST, { id });
export const adminRefreshArSubscriptionSuccess = arSubscription =>
  createAction(ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS, { arSubscription });

// Generic

export const adminActivateDomain = (domain, id) =>
  createAction(ADMIN_ACTIVATE_DOMAIN, { domain, id });
export const adminRefreshDomainRequest = (domain, id) =>
  createAction(ADMIN_REFRESH_DOMAIN_REQUEST, { domain, id });
export const adminSetRefreshDomain = (domain, value) =>
  createAction(ADMIN_SET_REFRESH_DOMAIN, { domain, value });
export const adminSaveRequest = (domain, model) =>
  createAction(ADMIN_SAVE_REQUEST, { domain, model });
export const adminDomainActionSuccess = (domain, model) =>
  createAction(ADMIN_DOMAIN_ACTION_SUCCESS, { domain, model });
