import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './billingActionTypes';

// store structure
const initialState = Immutable.from({
  defaultPaymentMethod: {},
  paymentMethods: {},
  billingAddress: {},
  isRefreshing: false
});

export const onActionInProgress = (state, { inProgress }) => {
  return state.merge({
    isRefreshing: inProgress
  });
};

export const onLoadPaymentMethodsSuccess = (state, { methods }) => {
  let paymentMethods = {};
  for (const method of methods) {
    paymentMethods[method.id] = method;
  }
  return state.merge({ paymentMethods });
};

export const onLoadDefaultPaymentMethodSuccess = (
  state,
  { defaultPaymentMethod }
) => {
  return state.merge({ defaultPaymentMethod });
};

export const onLoadBillingInformationSuccess = (
  state,
  { billingInformation }
) => {
  const {
    success,
    defaultPaymentMethod,
    paymentMethods,
    billingAddress,
    subscriptions
  } = billingInformation;

  let methods = {};
  for (const paymentMethod of paymentMethods) {
    methods[paymentMethod.id] = paymentMethod;
  }

  return state.merge({
    success,
    paymentMethods: methods,
    defaultPaymentMethod,
    billingAddress,
    subscriptions
  });
};

export const billingReducer = createReducer(initialState, {
  [types.ACTION_IN_PROGRESS]: onActionInProgress,
  [types.LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS]: onLoadDefaultPaymentMethodSuccess,
  [types.LOAD_PAYMENT_METHODS_SUCCESS]: onLoadPaymentMethodsSuccess,
  [types.LOAD_BILLING_INFORMATION_SUCCESS]: onLoadBillingInformationSuccess
});
