import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import { GvlMenu } from 'style/commonEmotions';
import { BACKEND_BASENAME } from 'utils/config';
import * as analytics from 'utils/analytics/analytics';

function DistributorMenuComponent({ location, user }) {
  return (
    <GvlMenu selectedKeys={[location.pathname]} mode="inline">
      <GvlMenuItem
        key="/certificates"
        link="/certificates"
        isTopLevel={true}
        icon="gvl-document"
        title={intl.get('activeFeedCertificates')}
      />
      {user.info.availableServices.indexOf('uploadVFDs') !== -1 && (
        <GvlMenuItem
          key="/uploads"
          link="/uploads"
          isTopLevel={true}
          icon="gvl-upload"
          title={intl.get('distributorsMenu.uploads')}
        />
      )}
      {user.info.availableServices.indexOf('requestVFDs') !== -1 && (
        <GvlMenuItem
          key="/requests"
          link="/requests"
          isTopLevel={true}
          icon="gvl-message"
          title={intl.get('requests')}
        />
      )}
      <GvlSubMenu key="records" title={intl.get('records')} icon="gvl-records">
        <GvlMenuItem
          key="/records/search"
          link="/records/search"
          title={intl.get('certificates')}
        />
        {user.info.availableServices.indexOf('uploadLOA') !== -1 && (
          <GvlMenuItem
            key="/records/acknowledgementLetters"
            onClick={() => {
              // INT-312 - Prioritization of updating the Acknowledgement letters page to the new UI depends on usage.
              const category = 'Acknowledgement letters';
              const action = 'sidemenu navigation';
              const label = '';
              const payload = {};
              analytics.trackEvent(category, action, label, payload);
              window.open(
                `${BACKEND_BASENAME}/feedMill/acknowledgementLetters`
              );
            }}
            external={true}
            title={intl.get('distributorsMenu.acknowledgement')}
          />
        )}
      </GvlSubMenu>
      <GvlSubMenu key="/help" title={intl.get('help')} icon="gvl-help">
        <GvlMenuItem
          key="/helpArticle"
          link="http://help.globalvetlink.com/en/"
          title={intl.get('helpArticles')}
          external={true}
        />
      </GvlSubMenu>
    </GvlMenu>
  );
}

DistributorMenuComponent.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const DistributorMenu = withRouter(DistributorMenuComponent);

export { DistributorMenu as default, DistributorMenuComponent };
