import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from 'modules/Herd/store/herdActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false
});

// general methods
export const onHerdRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

// list methods
export const onHerdListSuccess = (state, payload) => {
  let herds = {};
  Object.values(payload.herds).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(herd => (herds[herd.herdId] = herd));
    }
  });
  return state.merge({
    data: herds,
    lastSuccessfulRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onHerdListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

// exported reducer
export const herdReducer = createReducer(initialState, {
  [types.LIST_SUCCESS]: onHerdListSuccess,
  [types.LIST_FAILURE]: onHerdListFailure,
  [types.IS_REFRESHING]: onHerdRefreshing
});
