import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Modal, Table, Icon } from 'antd';
import {
  InlineButtonLink,
  VerticalDivider,
  InlineActionButton
} from 'style/commonEmotions';
import moment from 'moment';
import { THEME } from 'style';

export default class VetLicenseTable extends Component {
  static propTypes = {
    licenseList: PropTypes.array.isRequired,
    onLicenseEditRequest: PropTypes.func.isRequired,
    onLicenseDeleteRequest: PropTypes.func.isRequired
  };

  render() {
    return (
      <Table
        dataSource={this.props.licenseList}
        size="small"
        rowKey="id"
        columns={[
          {
            title: intl.get('distributor.license.number'),
            dataIndex: 'licenseNumber',
            key: 'licenseNumber'
          },
          {
            title: intl.get('issued.by'),
            dataIndex: 'issuingAuthority',
            key: 'issuingAuthority'
          },
          {
            title: intl.get('issue.date'),
            dataIndex: 'dateIssued',
            key: 'dateIssued',
            render: it => {
              return it ? moment(it).format('L') : null;
            }
          },
          {
            title: intl.get('date.expiration'),
            dataIndex: 'dateExpires',
            key: 'dateExpires',
            render: it => {
              return it ? moment(it).format('L') : null;
            }
          },
          {
            title: intl.get('actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (_text, record) => (
              <React.Fragment>
                <InlineButtonLink
                  type="link"
                  onClick={() => {
                    this.props.onLicenseEditRequest(record);
                  }}
                >
                  {intl.get('edit')}
                </InlineButtonLink>
                <VerticalDivider type="vertical" />
                <InlineActionButton
                  type="link"
                  onClick={() => {
                    Modal.confirm({
                      title: intl.get('delete.thing', {
                        thing: 'license'
                      }),
                      content: intl.get('removeConfirmBody'),
                      okText: intl.get('delete'),
                      onOk: () => {
                        this.props.onLicenseDeleteRequest(record);
                      }
                    });
                  }}
                >
                  <Icon type="close" style={{ color: THEME.red() }} />
                </InlineActionButton>
              </React.Fragment>
            )
          }
        ]}
      />
    );
  }
}
