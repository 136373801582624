import intl from 'react-intl-universal';
import { notification, message } from 'antd';
import * as actions from './herdListActions';
import * as types from './herdListActionTypes';
import { HerdList } from 'modules/HerdList/herdListIndex';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';

export function* onListRequest({ params }) {
  try {
    yield put(actions.isRefreshing(true));
    const herdLists = yield call(HerdList.list, params);
    yield put(actions.listSuccess(herdLists.results));
  } catch (err) {
    yield put(apiFailure('Error loading herd lists'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveRequest({ herdList, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    if (herdList.herdListId) {
      yield call([herdList, herdList.save]);
    } else {
      yield call([herdList, herdList.create]);
    }
    yield put(actions.refreshSuccess(herdList));
    if (typeof callback === 'function') callback(herdList);
    message.success(
      intl.get('savedWithNoun', {
        noun: intl.get('herd.list')
      })
    );
  } catch (err) {
    yield call(notification.warning, {
      message: intl.get('error.saving.noun', {
        noun: intl.get('herd.list.lc')
      }),
      description: intl.get('server.error')
    });
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* herdListSagas() {
  yield takeLatest(types.LIST_REQUEST, onListRequest);
  yield takeLatest(types.SAVE_REQUEST, onSaveRequest);
}
