import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './accountRegistrationActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  tableData: [],
  isRefreshing: false,
  isCreatingAccount: false,
  isAddingPayment: false
});

export const onActionInProgress = (state, { action, inProgress }) =>
  Immutable.setIn(state, [action], inProgress);

export const onloadRegistrationDataSuccess = (state, { data, tableData }) =>
  state.merge({
    data,
    tableData
  });

export const accountRegistrationReducer = createReducer(initialState, {
  [types.ACTION_IN_PROGRESS]: onActionInProgress,
  [types.LOAD_REGISTRATION_DATA_SUCCESS]: onloadRegistrationDataSuccess
});
