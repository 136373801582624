// Mvl Owner Constants

export const MVL_OWNER_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/MvlOwner/MVL_OWNER_LOAD_STARTUP_DATA_REQUEST';
export const MVL_OWNER_LOAD_STARTUP_DATA_STARTED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_LOAD_STARTUP_DATA_STARTED';
export const MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/MvlOwner/MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS';
export const MVL_OWNER_LOAD_STARTUP_DATA_FINISHED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_LOAD_STARTUP_DATA_FINISHED';

export const MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST';
export const MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED';
export const MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS';
export const MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE';

export const MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST';
export const MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED';
export const MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS';
export const MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE =
  'cricket/Accounts/MvlOwner/MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE';

export const MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED';
export const MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED';
export const MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST =
  'cricket/Accounts/MvlOwner/MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST';
export const MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED =
  'cricket/Accounts/MvlOwner/MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED';
export const MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING =
  'cricket/Accounts/MvlOwner/MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING';
