import { all, fork } from 'redux-saga/effects';

import appLoginSagas from 'containers/app/store/login/loginSagas';
import appUserSagas from 'containers/app/store/user/userSagas';
import accountsCommonSagas from 'containers/accounts/common/store/accountsCommonSagas';
import accountsAdminSagas from 'containers/accounts/admin/store/accountsAdminSagas';
import accountsClinicSagas from 'containers/accounts/clinic/store/accountsClinicSagas';
import accountsDistributorSagas from 'containers/accounts/distributor/store/accountsDistributorSagas';
import accountsLabSagas from 'containers/accounts/lab/store/accountsLabSagas';
import accountsRegulatorySagas from 'containers/accounts/regulatory/store/accountsRegulatorySagas';
import accountsMvlOwnerSagas from 'containers/accounts/mvlOwner/store/accountsMvlOwnerSagas';
// For some reason, importing these from the applicable Index file breaks our login process:
import newportClinicSagas from 'modules/NewportClinic/store/newportClinicSagas';
import isolateSagas from 'modules/Isolate/store/isolateSagas';
import isolateReleaseSagas from 'modules/IsolateRelease/store/isolateReleaseSagas';
import productSagas from 'modules/Product/store/productSagas';
import nonAdjacentHerdRequestSagas from 'modules/NonAdjacentHerdRequest/store/nonAdjacentHerdRequestSagas';
import contactSagas from 'modules/Contact/store/contactSagas';
import locationSagas from 'modules/Location/store/locationSagas';
import herdSagas from 'modules/Herd/store/herdSagas';
import herdListSagas from 'modules/HerdList/store/herdListSagas';
import licenseSagas from 'modules/License/store/licenseSagas';
import clinicSagas from 'modules/Clinic/store/clinicSagas';
import noteSagas from 'modules/Note/store/noteSagas';
import attachmentSagas from 'modules/Attachment/store/attachmentSagas';
import accountRegistrationSagas from 'modules/Registration/Account/store/accountRegistrationSagas';
import userRegistrationSagas from 'modules/Registration/User/store/userRegistrationSagas';
import userSagas from 'modules/User/store/userSagas';
import billingSagas from 'modules/Billing/store/billingSagas';

export function* rootSaga() {
  yield all([
    yield fork(appLoginSagas),
    yield fork(appUserSagas),
    yield fork(accountsCommonSagas),
    yield fork(accountsAdminSagas),
    yield fork(accountsClinicSagas),
    yield fork(accountsDistributorSagas),
    yield fork(accountsLabSagas),
    yield fork(accountsRegulatorySagas),
    yield fork(accountsMvlOwnerSagas),
    yield fork(newportClinicSagas),
    yield fork(isolateSagas),
    yield fork(isolateReleaseSagas),
    yield fork(productSagas),
    yield fork(nonAdjacentHerdRequestSagas),
    yield fork(contactSagas),
    yield fork(locationSagas),
    yield fork(herdSagas),
    yield fork(herdListSagas),
    yield fork(licenseSagas),
    yield fork(clinicSagas),
    yield fork(noteSagas),
    yield fork(attachmentSagas),
    yield fork(accountRegistrationSagas),
    yield fork(userRegistrationSagas),
    yield fork(userSagas),
    yield fork(billingSagas)
  ]);
}
