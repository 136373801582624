import { combineReducers } from 'redux';

import accountsCommonReducer from 'containers/accounts/common/store/accountsCommonReducer';
import accountsAdminReducer from 'containers/accounts/admin/store/accountsAdminReducer';
import accountsClinicReducer from 'containers/accounts/clinic/store/accountsClinicReducer';

import { ACCOUNTS_RESET } from 'containers/accounts/store/accountsConstants';
import accountsLabReducer from 'containers/accounts/lab/store/accountsLabReducer';
import accountsDistributorReducer from 'containers/accounts/distributor/store/accountsDistributorReducer';
import accountsRegulatoryReducer from 'containers/accounts/regulatory/store/accountsRegulatoryReducer';
import accountsMvlOwnerReducer from 'containers/accounts/mvlOwner/store/accountsMvlOwnerReducer';
import { newportClinicReducer } from 'modules/NewportClinic/store/newportClinicReducers';
import { isolateReducer } from 'modules/Isolate/store/isolateReducers';
import { isolateReleaseReducer } from 'modules/IsolateRelease/store/isolateReleaseReducers';
import { productReducer } from 'modules/Product/store/productReducers';
import { nonAdjacentHerdRequestReducer } from 'modules/NonAdjacentHerdRequest/store/nonAdjacentHerdRequestReducers';
import { contactReducer } from 'modules/Contact/store/contactReducers';
import { locationReducer } from 'modules/Location/store/locationReducers';
import { herdReducer } from 'modules/Herd/store/herdReducers';
import { herdListReducer } from 'modules/HerdList/store/herdListReducers';
import { licenseReducer } from 'modules/License/store/licenseReducers';
import { clinicReducer } from 'modules/Clinic/store/clinicReducers';
import { noteReducer } from 'modules/Note/store/noteReducers';
import { attachmentReducer } from 'modules/Attachment/store/attachmentReducers';
import { userReducer } from 'modules/User/store/userReducers';
import { billingReducer } from 'modules/Billing/store/billingReducers';

// Use an overriding Accounts reducer so we can intercept all accounts actions
export default function accountsReducer() {
  const accountsCombinedReducers = combineReducers({
    common: accountsCommonReducer,
    admin: accountsAdminReducer,
    clinic: accountsClinicReducer,
    distributor: accountsDistributorReducer,
    lab: accountsLabReducer,
    regulatory: accountsRegulatoryReducer,
    mvlOwner: accountsMvlOwnerReducer,
    newportClinics: newportClinicReducer,
    isolates: isolateReducer,
    isolateReleases: isolateReleaseReducer,
    products: productReducer,
    nonAdjacentHerdRequests: nonAdjacentHerdRequestReducer,
    contacts: contactReducer,
    locations: locationReducer,
    herds: herdReducer,
    herdLists: herdListReducer,
    licenses: licenseReducer,
    clinics: clinicReducer,
    notes: noteReducer,
    attachments: attachmentReducer,
    users: userReducer,
    billing: billingReducer
  });

  const accountsCombinedReducer = (state, action) => {
    if (action.type === ACCOUNTS_RESET) {
      state = undefined; // This will cause all of the Account stores to be set to their initial data
    }
    return accountsCombinedReducers(state, action);
  };

  return accountsCombinedReducer;
}
