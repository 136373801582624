// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Model from 'models/Model';
import Location from 'models/Location';
import { stringRequired, maxLength, isEmail } from 'utils/validation/common';
import * as api from 'utils/api';

export default class Contact extends Model {
  // Instance Methods
  validate() {
    const results = super.validate();

    // TODO: Put complex validations here.  If none, then delete this whole validate() method.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods
  static get fields() {
    return {
      ...super.fields,
      // Owner domain fields and constraints
      ownerEmail: {
        initialValue: undefined,
        validate: value => {
          return !value
            ? null
            : isEmail(value, 'validation.email.invalid') ||
                maxLength(value, 120);
        }
      },
      carrierClass: {
        initialValue: undefined
      },
      cellPhone: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 16);
        }
      },
      primaryPremises: {
        initialValue: {},
        validate: value => {
          return new Location(value).validate();
        }
      },
      id: {
        initialValue: undefined
      },
      mvlConnected: {
        initialValue: false
      },
      'clinic.id': {
        initialValue: undefined
      },

      // Person domain fields and constraints
      firstName: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.orgName
            ? null
            : stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      mi: {
        initialValue: undefined,
        validate: value => {
          maxLength(value, 2);
        }
      },
      lastName: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.orgName
            ? null
            : stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      suffix: {
        initialValue: undefined,
        validate: value => {
          maxLength(value, 10);
        }
      },

      // Organization fields and constraints
      orgName: {
        initialValue: undefined,
        validate: (value, instance) => {
          if (instance.orgName) {
            return maxLength(value, 60);
          } else {
            if (instance.firstName) return null;
            else return stringRequired(value, 'required');
          }
        }
      },

      // Ignored on Create
      name: {
        initialValue: undefined
      },
      label: {
        initialValue: undefined
      },
      premisesList: {
        initialValue: undefined
      },
      version: {
        initialValue: undefined
      },
      type: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'origin';
  }

  static get domain() {
    return 'Contact';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };

  static getFullPremisesList = async id => {
    return await api.get(`/premises/listByOwner/${id}`);
  };

  static getContactData = async () => {
    const data = await super.list({
      type: this.domain
    });
    return data;
  };

  static deleteContact = async id => {
    await super.deleteRec(id);
  };

  async updateContact(contact) {
    return await api.put(`/origin/${this.id}`, contact);
  }

  static async grantMVLAccess(contactId) {
    return await api.post(`/origin/grantMVLAccess/${contactId}`);
  }

  static async revokeMVLAccess(contactId) {
    return await api.post(`/origin/revokeMVLAccess/${contactId}`);
  }

  static async getPotentialMatches(type, data) {
    // TODO Why is this a POST request?
    return await api.post('/origin/potential', { type, data });
  }
}
