// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
// The LabTest Model is used by both EIA and CVI workflows, certain validations are performed in the UI
import { pickBy } from 'lodash';
import Model from 'models/Model';
import * as api from 'utils/api';
import {
  dateIsBefore,
  stringRequired,
  maxLength,
  disallowCharacters,
  isDate,
  isNumber,
  numberInRange,
  dateIsFutureDate
} from 'utils/validation/common';
import EiaModel from 'models/documents/Eia';
import { downloadFile } from 'utils/downloadUtil';

export default class LabTest extends Model {
  // When batch editing, we do not want to send null values on the api call
  // since they may have just not been selected. However, when editing a single
  // test, the user might actually be trying to set a field value to null.
  async resultTest(ignoreNulls = true) {
    // Use pickBy to remove null values from the object before sending to API
    let labTestJson = this.toJson();
    if (ignoreNulls) {
      labTestJson = pickBy(labTestJson, value => value !== null);
    }
    const testResult = await api.put(`labtests/${this.id}/result`, labTestJson);
    // Update the instance with the saved record that is returned so we can get things like id, version, etc.
    this.constructor.initialize(this, testResult);
    return this;
  }

  async nvslResultTest(nvslResult) {
    const testResult = await api.post(`labtests/${this.id}/resultNVSL`, {
      nvslResult
    });
    this.constructor.initialize(this, testResult);
    return this;
  }

  static isResulted(labTest) {
    return labTest.cogginsStatus === 'resulted';
  }

  isReadyToSign(userInfo) {
    return (
      !LabTest.isResulted(this) &&
      this.gvlLab_id === userInfo.account.id &&
      this.result === 'Negative' &&
      !this.validate() &&
      !(this.resultedBy_id && this.resultedB_id !== userInfo.labTechId)
    );
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      validationLevel: {
        // This property will not be persisted and is only used to provide varying levels of validation feedback eg minimum required to save vs sign
        initialValue: 'save'
      },
      accession: {
        initialValue: null,
        validate: (accession, instance) => {
          if (
            instance.validationLevel === 'sign' ||
            instance.validationLevel === 'sendToNvsl'
          ) {
            return (
              stringRequired(accession, 'isRequired', {
                thing: 'test.accessionNumber'
              }) || maxLength(accession, 50)
            );
          } else {
            return accession ? maxLength(accession, 50) : null;
          }
        }
      },
      animal_id: {
        initialValue: null,
        validate: val => {
          return (
            isNumber(val, 'validation.labTest.animal.required') ||
            numberInRange(val, 0, Number.MAX_SAFE_INTEGER)
          );
        }
      },
      animal_name: {
        initialValue: null,
        validate: val => {
          return stringRequired(val, 'validation.labTest.animal.required');
        }
      },
      clinic_id: {
        initialValue: null
      },
      clinic_name: {
        initialValue: null,
        validate: val => {
          stringRequired(val, 'validation.labTest.clinic.required');
        }
      },
      clinicAccountNumberForLabs: {
        initialValue: null
      },
      cogginsStatus: {
        initialValue: null
      },
      date: {
        initialValue: null,
        validate: (testDate, instance) => {
          //Its Blood drawn date on EIA lab Test and Test date on animal test
          const key =
            instance.gvlLab_id !== undefined
              ? 'test.dateBloodDrawn'
              : 'test.testDate';
          let err =
            isDate(testDate, 'MM/DD/YYYY', 'required') ||
            dateIsFutureDate(testDate, 'date.notFuture', {
              date: key
            });
          if (instance.gvlLab_id !== undefined && instance.sentDate) {
            err =
              err ||
              dateIsBefore(
                instance.sentDate,
                testDate,
                'date.notBeforeOtherDate',
                {
                  date: 'test.dateSent',
                  otherDate: 'test.dateBloodDrawn.lower'
                }
              );
          }
          if (err) return err;
          return null;
        }
      },
      gvlId: {
        initialValue: undefined
      },
      gvlLab_id: {
        initialValue: undefined,
        validate: val => {
          return (
            isNumber(val, 'test.lab.required') ||
            numberInRange(val, 0, Number.MAX_SAFE_INTEGER)
          );
        }
      },
      lab: {
        initialValue: null,
        validate: val => {
          return !val ? null : maxLength(val, 1020);
        }
      },
      manufacturer: {
        initialValue: '',
        validate: val => {
          return !val ? null : maxLength(val, 50);
        }
      },
      name: {
        initialValue: null,
        validate: val => {
          return stringRequired(val, 'test.name.required');
        }
      },
      owner_name: {
        initialValue: null
      },
      purpose: {
        initialValue: undefined,
        validate: (val, instance) => {
          if (instance.gvlLab_id !== undefined && !val)
            return stringRequired(val, 'required');
          return null;
        }
      },
      receivedDate: {
        initialValue: undefined,
        validate: (receivedDate, instance) => {
          if (
            !receivedDate &&
            instance.validationLevel !== 'sign' &&
            instance.validationLevel !== 'sendToNvsl'
          ) {
            // received date is not required until signing or sending to NVSL.
            return null;
          }
          const validDate =
            isDate(receivedDate, 'MM/DD/YYYY', 'required') ||
            dateIsFutureDate(receivedDate, 'date.notFuture', {
              date: 'test.dateReceived'
            });
          if (validDate) {
            return validDate;
          } else if (instance && instance.date) {
            return dateIsBefore(
              receivedDate,
              instance.date,
              'date.notBeforeOtherDate',
              { date: 'test.dateReceived', otherDate: 'test.testDate.lower' }
            );
          } else {
            return null;
          }
        }
      },
      remarks: {
        initialValue: undefined,
        validate: remarks =>
          maxLength(remarks, 220) ||
          disallowCharacters(
            remarks,
            ['\n', '\t'],
            'test.remarks.invalidCharacter'
          )
      },
      resultDate: {
        initialValue: undefined,
        validate: (resultDate, instance) => {
          if (
            !resultDate &&
            instance.validationLevel !== 'sign' &&
            instance.validationLevel !== 'sendToNvsl'
          ) {
            // result date is not required until signing or sending to NVSL.
            return null;
          }
          const validDate =
            isDate(resultDate, 'MM/DD/YYYY', 'required') ||
            dateIsFutureDate(resultDate, 'date.notFuture', {
              date: 'test.dateResulted'
            });
          if (validDate) {
            return validDate;
          } else if (instance && instance.receivedDate) {
            return dateIsBefore(
              resultDate,
              instance.receivedDate,
              'date.notBeforeOtherDate',
              {
                date: 'test.dateResulted',
                otherDate: 'test.dateReceived.lower'
              }
            );
          } else if (instance && instance.sentDate) {
            return dateIsBefore(
              resultDate,
              instance.sentDate,
              'date.notBeforeOtherDate',
              { date: 'test.dateResulted', otherDate: 'test.dateSent.lower' }
            );
          } else if (instance && instance.date) {
            return dateIsBefore(
              resultDate,
              instance.date,
              'date.notBeforeOtherDate',
              { date: 'test.dateResulted', otherDate: 'test.testDate.lower' }
            );
          } else {
            return null;
          }
        }
      },
      resultedBy_id: {
        initialValue: undefined
      },
      resultedBy_commonName: {
        initialValue: undefined
      },
      result: {
        initialValue: undefined,
        validate: (val, instance) => {
          if (instance.name === 'EIA') {
            if (
              instance.validationLevel === 'sign' ||
              instance.validationLevel === 'sendToNvsl'
            ) {
              return stringRequired(val, 'isRequired', {
                thing: 'test.result'
              });
            }
          } else if (!instance.isBatchEditMode) {
            return stringRequired(val, 'required');
          }
          return null;
        }
      },
      sentDate: {
        initialValue: null,
        validate: (sentDate, instance) => {
          const validDate = isDate(sentDate, 'MM/DD/YYYY', 'required');
          if (validDate) {
            return validDate;
          } else if (instance && instance.date) {
            return dateIsBefore(
              sentDate,
              instance.date,
              'date.notBeforeOtherDate',
              {
                date: 'test.dateSent',
                otherDate: 'test.testDate.lower'
              }
            );
          } else {
            return null;
          }
        }
      },
      serialNumber: {
        initialValue: undefined
      },
      signing_vet: {
        initialValue: undefined
      },
      type: {
        initialValue: null,
        validate: (val, instance) => {
          return instance.isBatchEditMode
            ? null
            : stringRequired(val, 'required');
        }
      },
      locked: {
        initialValue: undefined
      },
      version: {
        initialValue: undefined
      },
      nvslSubmittalDate: {
        initialValue: undefined
      },
      nvslResult: {
        initialValue: undefined
      },
      nvslRemarks: {
        initialValue: undefined
      },
      nvslResultTimestamp: {
        initialValue: undefined
      },
      bloodDrawnState: {
        initialValue: undefined
      },
      wasSampleSentToNvsl: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'labTest';
  }

  static get domain() {
    return 'labTest';
  }

  static batchSign = async (ids, username, password) => {
    return await api.put(`${this.apiPath}/batchSign`, {
      ids,
      timeOffsetMins: new Date().getTimezoneOffset(),
      username,
      password
    });
  };

  static getPendingResults = async () => {
    const listResults = await api.get(`${this.apiPath}/pendingResults`);
    return listResults.map(result => new this(result));
  };

  static getPendingNetworkResults = async () => {
    const listResults = await api.get(`${this.apiPath}/pendingNetworkResults`);
    return listResults.map(result => new this(result));
  };

  static findDocumentByTestId = async testId => {
    const document = new EiaModel(
      await api.get(`${this.apiPath}/findDocumentByTestId/${testId}`)
    );
    return document;
  };

  static read = async id => {
    const record = await api.get(`${this.apiPath}/${id}`);
    return new this(record);
  };

  static advancedSearch = async params => {
    const listResults = await api.get(`${this.apiPath}/advSearch`, { params });
    return listResults.map(result => new this(result));
  };

  static advancedCertSearch = async params => {
    const listResults = await api.get(`${this.apiPath}/advSearch`, { params });
    return listResults;
  };

  static downloadMonthlyReport = async (month, year) => {
    await downloadFile(
      `${this.apiPath}/exportLabSpreadsheet?month=${month}&year=${year}`
    );
  };

  static submitToNvsl = async labTest => {
    const submittedLabTest = await api.post(
      `${this.apiPath}/submitToNVSL/${labTest.id}`,
      labTest
    );
    return new this(submittedLabTest);
  };

  // Returns attachment ID
  static attachNvslReport = async (file, testId) => {
    const formData = new FormData();
    formData.append('file', file);
    const { nvslAttachmentId } = await api.postFile(
      // TODO: Update this to use apiPath once/if backend endpoints are normalized
      // `${this.apiPath}/${testId}/uploadNVSLReport `,
      `labtests/${testId}/uploadNVSLReport`,
      formData
    );

    return nvslAttachmentId;
  };

  static removeNvslReport = async testId => {
    // TODO: Update this to use apiPath once/if backend endpoints are normalized
    // `${this.apiPath}/${testId}/removeNVSLReport `,
    await api.put(`labtests/${testId}/removeNVSLReport `);
  };
}
