import { createAction } from 'utils/reduxUtil';
import * as types from 'modules/Registration/User/store/userRegistrationActionTypes';

export const actionInProgress = (action, inProgress) =>
  createAction(types.ACTION_IN_PROGRESS, { action, inProgress });

export const validateUsername = (params, callback) =>
  createAction(types.IS_EMAIL_DUPLICATE, { params, callback });

export const loadRegistration = (registrationId, verificationCode, callback) =>
  createAction(types.LOAD_REGISTRATION_REQUEST, {
    registrationId,
    verificationCode,
    callback
  });

export const submitRegistration = (registration, callback) =>
  createAction(types.SUBMIT_REGISTRATION, { registration, callback });
export const batchSubmitRegistrations = ({
  registrations,
  successCallback,
  failureCallback
}) =>
  createAction(types.BATCH_SUBMIT_REGISTRATIONS, {
    registrations,
    successCallback,
    failureCallback
  });
export const verifyRegistration = (registrationId, params, history) =>
  createAction(types.VERIFY_REGISTRATION, { registrationId, params, history });
export const resendVerification = registrationId =>
  createAction(types.RESEND_CONFIRMATION, { registrationId });
export const renewVerification = registrationId =>
  createAction(types.RENEW_VERIFICATION, { registrationId });

export const handleVerificationStatus = error =>
  createAction(types.HANDLE_VERIFICATION_STATUS, { error });
export const setVerificationStatus = status =>
  createAction(types.SET_VERIFICATION_STATUS, { status });
