// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Model from 'models/Model';
import * as api from 'utils/api';

export default class Request extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();

    // TODO: Put complex validations here.  If none, then delete this whole validate() method.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      data: {
        initialValue: {}
      },
      type: {
        initialValue: undefined
      },
      state: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      metadata: {
        initialValue: {}
      },
      requestedObject: {
        initialValue: {}
      }
    };
  }

  static get apiPath() {
    return 'requests';
  }

  static get domain() {
    return 'Request';
  }

  static getVfdRequests = async () => {
    const data = await super.list({
      type: 'document',
      limit: 0,
      'metadata.status': 'REQUESTED',
      'requestedObject.type': 'VFD,VFR'
    });
    return data.results;
  };

  // TODO: Combine with getVfdRequests into common get function
  static getEiaRequests = async () => {
    const data = await super.list({
      type: 'document',
      'metadata.status': 'REQUESTED',
      'requestedObject.type': 'EIA'
    });
    return data.results;
  };

  static getAcceptedVfdRequests = async () => {
    const data = await super.list({
      type: 'document',
      'metadata.status': 'ACCEPTED',
      'requestedObject.type': 'VFD'
    });
    return data.results;
  };

  static getRejectedVfdRequests = async () => {
    const data = await super.list({
      type: 'document',
      'metadata.status': 'REJECTED',
      'requestedObject.type': 'VFD'
    });
    return data.results;
  };

  static read = async id => {
    return super.read(id);
  };

  static reject = async (id, reason) =>
    await api.put(`/requests/${id}/reject`, { reason });
}
