import * as types from './productActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = newState =>
  createAction(types.IS_REFRESHING, { isRefreshing: newState });

// list
export const listRequest = () => createAction(types.LIST_REQUEST);
export const listSuccess = products =>
  createAction(types.LIST_SUCCESS, { products });
export const listFailure = () => createAction(types.LIST_FAILURE);

// search
export const searchRequest = (searchType, searchParams, cb) =>
  createAction(types.SEARCH_REQUEST, { searchType, searchParams, cb });
export const searchSuccess = products =>
  createAction(types.SEARCH_SUCCESS, { products });
export const searchFailure = () => createAction(types.SEARCH_FAILURE);

// refresh
export const refreshRequest = (productId, callback = null) =>
  createAction(types.REFRESH_REQUEST, { productId, callback });
export const refreshSuccess = product =>
  createAction(types.REFRESH_SUCCESS, { product });

// save
export const saveRequest = (product, callback = null) =>
  createAction(types.SAVE_REQUEST, {
    product,
    callback
  });

// delete
export const deleteRequest = (productId, history) =>
  createAction(types.DELETE_REQUEST, { productId, history });

// remove product from store
export const removeFromStore = productId =>
  createAction(types.REMOVE_FROM_STORE, { productId });
