import { createAction } from 'utils/reduxUtil';
import {
  API_FAILURE,
  CONNECTION_ERROR,
  REMOVE_API_ERROR,
  SET_VIEW_MODE
} from 'containers/app/store/data/dataConstants';

/* istanbul ignore file */

export const apiFailure = (error, title, notificationType) =>
  createAction(API_FAILURE, { error, title, notificationType });
export const removeApiError = timestamp =>
  createAction(REMOVE_API_ERROR, { timestamp });
export const connectionError = () => createAction(CONNECTION_ERROR);
export const setViewMode = viewMode =>
  createAction(SET_VIEW_MODE, { viewMode });
