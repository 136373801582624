// import * as locationActions from './store/locationActions';
// import * as locationReducer from './store/locationReducers';
import LocationModel from './LocationV2';
import Lookup from './components/LocationLookup';
import LookupFormItem from './components/LocationLookupFormItem';
import LocationShow from './components/LocationInlineShow';
import EditModal from './components/LocationEditModal';
import FormFields from './components/LocationFormFields';

/**
 * Currently working on exporting locationSagas from this root without breaking our sagas.js file
 * If we export locationReducer here, the path to that data in the redux store is borked
 */
// export default {
//   locationActions,
//   locationReducer
// };

export const Location = LocationModel;
export const LocationLookup = Lookup;
export const LocationLookupFormItem = LookupFormItem;
export const LocationInlineShow = LocationShow;
export const LocationFormFields = FormFields;
export const LocationEditModal = EditModal;
