import Immutable from 'seamless-immutable';
import {
  loadState,
  observeStore,
  saveStateThrottled
} from 'utils/reduxLocalStorage';
import { setAuthToken } from 'utils/api';
import { selectUser } from 'containers/app/store/user/userSelectors';

/**
 * Convenience method to simplify action class setup
 */
export const createAction = (type, payload) => {
  if (!type) {
    throw new Error('Action Type must be truthy');
  }
  if (payload && payload.type) {
    throw new Error(
      "Action payload cannot have a 'type' field - it would overwrite the Action's type"
    );
  }
  return { type, ...payload };
};

/**
 * Convenience method to simplify reducer setup
 */
export const createReducer = (initialState, config) => {
  return (state = initialState, action) => {
    if (config[action.type]) {
      return config[action.type](state, action);
    }
    return state;
  };
};

/**
 * Load the user, authToken, and language settings from localStorage
 */
export const loadPersistedState = () => {
  const persistedState = { app: {} };
  const persistedUser = loadState('user');
  if (persistedUser) {
    persistedState.app.user = persistedUser;

    //Special case to set the axios default api token header
    setAuthToken(persistedUser.authToken);
  }
  const persistedLang = loadState('language');
  if (persistedLang) {
    persistedState.language = persistedLang;
  }

  return Immutable.from(persistedState);
};

/**
 * Storing global user, authToken, and language state to localStorage
 *  so that a page refresh doesn't require a new login or reset of language
 * Most reducers should not be here, as they won't need to persist in localStorage
 */
export const setupStoreObservers = store => {
  observeStore(store, selectUser, saveStateThrottled('user'));
  observeStore(store, state => state.language, saveStateThrottled('language'));
};
