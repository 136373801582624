import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Badge, Card, Tooltip, Spin } from 'antd';
import styled from '@emotion/styled';

import { THEME } from 'style';

const setStylesFromProps = ({ viewMode, style, condensed }) => {
  //Base styling
  let baseStyling = {
    background: THEME.white(),
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
  };

  if (viewMode === 'Desktop') {
    //If width is not given use 720 px and center the content
    if (!style || !style.width) {
      baseStyling.width = '720px';
      baseStyling.marginLeft = 'auto';
      baseStyling.marginRight = 'auto';
    }
  }

  if (condensed) {
    baseStyling['& .ant-card-head'] = {
      margin: '0',
      padding: '0px 12px 0px'
    };
    baseStyling['& .ant-card-body'] = {
      padding: '0'
    };
    baseStyling['& .ant-card-body > div'] = {
      margin: '0 !important'
    };
  }

  return baseStyling;
};

// NOTE: there is a custom stylesheet for this in GvlCard.less that wasn't doable with emotion

const shouldForwardProp = prop =>
  ['viewMode', 'dispatch', 'condensed'].indexOf(prop) === -1;

/* istanbul ignore next */
const StyledCard = styled(Card, { shouldForwardProp })(setStylesFromProps);

// TODO: this should probably be a simpler functional component
class GvlCardComponent extends Component {
  static propTypes = {
    condensed: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledTooltipTitle: PropTypes.string,
    viewMode: PropTypes.string.isRequired,
    additionalHeaderContent: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    count: PropTypes.number, // Optional count that will be shown next to the Title if non-zero and title present
    children: PropTypes.node
  };

  render() {
    const {
      additionalHeaderContent,
      title,
      count,
      className,
      disabledTooltipTitle,
      disabled,
      children,
      ...rest
    } = this.props;

    let titleToUse = title ? (
      <h2 className={'responsive-height'}>{title}</h2>
    ) : null;
    if (title && count && count > 0) {
      titleToUse = (
        <h2 className={'responsive-height'}>
          {title}
          <Badge
            // style has to be used here instead of emotion.styled since AntD will not apply background color properly.
            style={{
              backgroundColor: THEME.text(),
              margin: '0 0 4px 8px'
            }}
            count={count}
            overflowCount={999999999}
          />
        </h2>
      );
    }
    if (additionalHeaderContent) {
      titleToUse = (
        <div
          className="flex-center"
          style={{ justifyContent: 'space-between' }}
        >
          {titleToUse}
          {additionalHeaderContent}
        </div>
      );
    }

    return (
      <StyledCard
        className={className ? `${className} gvl-card` : 'gvl-card'}
        title={titleToUse}
        bordered={rest.viewMode === 'Mobile' ? false : true}
        {...rest}
      >
        {disabled ? (
          <Tooltip title={disabledTooltipTitle}>
            <Spin indicator={<React.Fragment />} spinning={disabled}>
              {children}
            </Spin>
          </Tooltip>
        ) : (
          children
        )}
      </StyledCard>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  viewMode: app.data.viewMode
});

const GvlCard = connect(mapStateToProps, null)(GvlCardComponent);

export {
  GvlCard as default,
  GvlCardComponent,
  setStylesFromProps,
  shouldForwardProp
};
