import {
  all,
  apply,
  call,
  put,
  takeLatest,
  takeEvery,
  select
} from 'redux-saga/effects';
import { flatten } from 'lodash';
import * as api from 'utils/api';
import { notification, message } from 'antd';
import Cvi from 'models/documents/Cvi';
import Eecvi from 'models/documents/Eecvi';
import Eia from 'models/documents/Eia';
import Document from 'models/documents/Document';
import Contact from 'models/Contact';
import Vfd from 'models/documents/Vfd';
import Rabies from 'models/documents/Rabies';
import Ihc from 'models/documents/Ihc';
import Animal from 'models/Animal';
import Image from 'models/Image';
import Request from 'models/Request';
import Location from 'models/Location';
import RecentActivity from 'models/documents/RecentActivity';
import { trackEvent, trackSign, trackBatch } from 'utils/analytics/analytics';
import { exportCsv, formatDate } from 'utils/csvUtil';
import { convertCertTypeForDisplay } from 'utils/appUtil';
import {
  getDocumentModelForCertificateType,
  createDraftFromDocument
} from 'containers/accounts/clinic/Records/certificateHelper';

import { connectionError } from 'containers/app/store/data/dataActions';
import { apiFailure } from 'containers/app/store/data/dataActions';
import {
  getClinicDataLists,
  documentSelector
} from 'containers/accounts/clinic/store/accountClinicSelectors';
import intl from 'react-intl-universal';
import {
  getUserIncludeImage,
  isLicenseValidationFeatureEnabled
} from 'containers/app/store/user/userSelectors';
import { selectNvapLicense } from 'modules/License/store/licenseSelectors';

import {
  clinicLoadStartupDataSuccess,
  clinicLoadStartupDataStarted,
  clinicLoadStartupDataFinished,
  clinicRefreshEiaListStarted,
  clinicRefreshEiaListSuccess,
  clinicRefreshEiaListFailure,
  clinicRefreshEiaRequest,
  clinicRefreshEiaStarted,
  clinicRefreshEiaSuccess,
  clinicRefreshEiaFailure,
  clinicSaveEiaSuccess,
  clinicSaveEiaFailure,
  clinicEiaListReady,
  clinicSignEiaSuccess,
  clinicSignEiaFailure,
  clinicBatchSignEiaSuccess,
  clinicBatchSignEiaFailure,
  clinicCreateCviFromEiaSuccess,
  clinicCreateCviFromEiaFailure,
  clinicAutoArchiveEiaSuccess,
  clinicAddRequiredEiaCountySuccess,
  clinicAddRequiredEiaCountyFailed,
  // cvi
  clinicRefreshCviListStarted,
  clinicRefreshCviListSuccess,
  clinicRefreshCviListFailure,
  clinicRefreshCviRequest,
  clinicRefreshCviStarted,
  clinicRefreshCviSuccess,
  clinicRefreshCviFailure,
  clinicCviAddPermitSuccess,
  clinicSignCviSuccess,
  clinicSignCviFailure,
  clinicBatchSignCviSuccess,
  clinicBatchSignCviFailure,
  clinicCviListReady,
  clinicRefreshContactListStarted,
  clinicRefreshContactListSuccess,
  clinicRefreshContactSuccess,
  clinicRefreshContactListFailure,
  clinicDeleteCviFailure,
  // eecvi
  clinicCommitEecviFailure,
  clinicRefreshEecviListStarted,
  clinicRefreshEecviListSuccess,
  clinicRefreshEecviListFailure,
  clinicRefreshEecviRequest,
  clinicRefreshEecviStarted,
  clinicRefreshEecviSuccess,
  clinicRefreshEecviFailure,
  clinicEecviListReady,
  // vfds
  clinicRefreshVfdsListStarted,
  clinicRefreshVfdsListSuccess,
  clinicRefreshVfdsListFailure,
  clinicRefreshVfdRequest,
  clinicRefreshVfdStarted,
  clinicRefreshVfdFailure,
  clinicVfdListReady,
  // rabies
  clinicRefreshRabiesListStarted,
  clinicRefreshRabiesListSuccess,
  clinicRefreshRabiesListFailure,
  clinicRabiesListReady,
  clinicRefreshRabiesRequest,
  clinicRefreshRabiesStarted,
  clinicRefreshRabiesSuccess,
  clinicRefreshRabiesFailure,
  clinicSignRabiesSuccess,
  clinicSignRabiesFailure,
  clinicBatchSignRabiesSuccess,
  clinicBatchSignRabiesFailure,
  // Ihcs
  clinicRefreshIhcListStarted,
  clinicRefreshIhcListSuccess,
  clinicRefreshIhcListFailure,
  clinicIhcListReady,
  clinicRefreshIhcRequest,
  clinicRefreshIhcStarted,
  clinicRefreshIhcSuccess,
  clinicRefreshIhcFailure,
  clinicSignIhcFailure,
  clinicSignIhcSuccess,
  clinicBatchSignIhcSuccess,
  clinicBatchSignIhcFailure,

  // Generic Document
  clinicClearDocumentErrors,
  clinicVoidDocumentSuccess,
  clinicVoidDocumentFailure,
  clinicSaveDocSuccess,
  clinicRefreshDocSuccess,
  clinicRemoveDocSuccess,
  clinicDocRequestDone,
  //Animal
  clinicRefreshAnimalListStarted,
  clinicRefreshAnimalListSuccess,
  clinicRefreshAnimalSuccess,
  clinicRefreshAnimalListFailure,
  clinicVerboseAnimalListSuccess,
  clinicAnimalRemovedFromList,
  clinicDeleteAnimalStarted,
  clinicAnimalListReady,
  clinicAnimalUpdatedSuccess,
  //Image
  clinicRefreshImageListStarted,
  clinicRefreshImageListSuccess,
  clinicRefreshImageListFailure,
  clinicImageUpdateSuccess,
  clinicImageDeleteSuccess,
  //Document model collections
  clinicFetchCarriersSuccess,
  clinicFetchCarriersFailure,
  clinicSEVerifyPartialDocSuccess,
  clinicSEVerifyPartialDocFailure,
  clinicPremisesUpdate,
  //Request
  clinicRequestRemovedFromList,
  //Contact
  clinicContactRemovedFromList,
  clinicDeleteContactStarted,
  clinicContactListReady,
  clinicDeleteContactSuccess,
  clinicDeleteContactFailure,
  clinicContactUpdate,
  //Home
  clinicRefreshHomeListStarted,
  clinicRefreshHomeListSuccess,
  clinicRefreshHomeListFailure,
  // Analytics
  clinicTrackDocumentSigning,
  // Certificates search
  clinicCertificatesSearchSuccess,
  clinicCertificatesSearchFailure,
  // Create Document from Certificate
  clinicCreateDocumentFromCertificateSuccess,
  clinicCreateDocumentFromCertificateFailure
} from 'containers/accounts/clinic/store/accountsClinicActions';

import {
  CLINIC_LOAD_STARTUP_DATA_REQUEST,
  // cvi
  CLINIC_REFRESH_CVI_LIST_REQUEST,
  CLINIC_ACTIVATE_CVI,
  CLINIC_REFRESH_CVI_REQUEST,
  CLINIC_SAVE_CVI_REQUEST,
  CLINIC_CVI_ADD_PERMIT_REQUEST,
  CLINIC_SIGN_CVI_REQUEST,
  CLINIC_BATCH_SIGN_CVI_REQUEST,
  CLINIC_ARCHIVE_CVIS_REQUEST,
  CLINIC_DELETE_CVIS_REQUEST,
  CLINIC_REFRESH_CONTACT_LIST_REQUEST,
  CLINIC_REFRESH_CONTACT_REQUEST,
  CLINIC_DELETE_CVI_REQUEST,
  // eecvi
  CLINIC_REFRESH_EECVI_LIST_REQUEST,
  CLINIC_ACTIVATE_EECVI,
  CLINIC_REFRESH_EECVI_REQUEST,
  CLINIC_SAVE_EECVI_REQUEST,
  CLINIC_COMMIT_EECVI_REQUEST,
  CLINIC_COMMIT_EECVI_FAILURE,
  CLINIC_BATCH_COMMIT_EECVI_REQUEST,
  CLINIC_ARCHIVE_EECVIS_REQUEST,
  CLINIC_DELETE_EECVIS_REQUEST,
  // eia
  CLINIC_REFRESH_EIA_LIST_REQUEST,
  CLINIC_REFRESH_EIA_REQUEST,
  CLINIC_ACTIVATE_EIA,
  CLINIC_SAVE_EIA_REQUEST,
  CLINIC_SIGN_EIA_REQUEST,
  CLINIC_BATCH_SIGN_EIA_REQUEST,
  CLINIC_CREATE_CVI_FROM_EIA_REQUEST,
  CLINIC_CREATE_CVI_FROM_EIA_FAILURE,
  CLINIC_PROCESS_EIAS_REQUEST,
  CLINIC_ARCHIVE_EIAS_REQUEST,
  CLINIC_DELETE_EIAS_REQUEST,
  CLINIC_AUTO_ARCHIVE_EIA_REQUEST,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST,
  // vfds
  CLINIC_REFRESH_VFDS_LIST_REQUEST,
  CLINIC_ACTIVATE_VFD,
  CLINIC_REFRESH_VFD_REQUEST,
  CLINIC_SAVE_VFD_REQUEST,
  CLINIC_PROCESS_VFDS_RENEWAL_REJECT,
  CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT,
  CLINIC_PROCESS_VFD_RENEWAL_REVIEW,
  CLINIC_PROCESS_VFDS_REQUEST,
  CLINIC_SIGN_VFD_REQUEST,
  CLINIC_ARCHIVE_VFDS_REQUEST,
  CLINIC_DELETE_VFDS_REQUEST,
  // Rabies
  CLINIC_REFRESH_RABIES_LIST_REQUEST,
  CLINIC_ACTIVATE_RABIES,
  CLINIC_REFRESH_RABIES_REQUEST,
  CLINIC_SAVE_RABIES_REQUEST,
  CLINIC_SIGN_RABIES_REQUEST,
  CLINIC_SIGN_RABIES_FAILURE,
  CLINIC_DELETE_RABIES_REQUEST,
  CLINIC_ARCHIVE_RABIES_REQUEST,
  CLINIC_BATCH_SIGN_RABIES_REQUEST,
  // Ihcs
  CLINIC_REFRESH_IHC_LIST_REQUEST,
  CLINIC_ACTIVATE_IHC,
  CLINIC_REFRESH_IHC_REQUEST,
  CLINIC_SAVE_IHC_REQUEST,
  CLINIC_SIGN_IHC_REQUEST,
  CLINIC_SIGN_IHC_FAILURE,
  CLINIC_DELETE_IHC_REQUEST,
  CLINIC_CREATE_CVI_FROM_IHC_REQUEST,
  CLINIC_ARCHIVE_IHC_REQUEST,
  CLINIC_BATCH_SIGN_IHC_REQUEST,
  //Animal
  CLINIC_REFRESH_ANIMAL_LIST_REQUEST,
  CLINIC_VERBOSE_ANIMAL_LIST_REQUEST,
  CLINIC_REFRESH_ANIMAL_REQUEST,
  CLINIC_DELETE_ANIMAL_REQUEST,
  CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST,
  CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST,
  //Image
  CLINIC_REFRESH_IMAGE_LIST_REQUEST,
  CLINIC_IMAGE_UPDATE_REQUEST,
  CLINIC_IMAGE_DELETE_REQUEST,
  //Document models
  CLINIC_FETCH_CARRIERS_REQUEST,
  CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST,
  //Requests
  CLINIC_REJECT_DOCUMENT_REQUEST,
  //Contact
  CLINIC_GET_CONTACT_REQUEST,
  CLINIC_GET_PREMISES_REQUEST,
  CLINIC_DELETE_CONTACT_REQUEST,
  CLINIC_MVL_GRANT_ACCESS,
  CLINIC_MVL_REVOKE_ACCESS,
  //Home
  CLINIC_REFRESH_HOME_LIST_REQUEST,
  // Analytics
  CLINIC_TRACK_DOCUMENT_SIGNING,
  // Generic Document
  CLINIC_VOID_DOCUMENT_REQUEST,
  CLINIC_VOID_DOCUMENT_SUCCESS,
  CLINIC_VOID_DOCUMENT_FAILURE,
  CLINIC_LOCK_DOC_REQUEST,
  CLINIC_UNLOCK_DOC_REQUEST,
  CLINIC_COPY_DOC_REQUEST,
  // Certificates Search
  CLINIC_CERTIFICATES_SEARCH_REQUEST,
  // Create Document from Certificate
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST,
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE
} from 'containers/accounts/clinic/store/accountsClinicConstants';

export function getMessages(successes, action, actions, thing, things) {
  let msg;
  if (successes > 1) {
    msg = intl.get(actions, { qty: successes, things: things });
  } else if (successes === 1) {
    msg = intl.get(action, { thing: thing });
  }
  return msg;
}

export function* onClinicLoadStartupDataRequest(action) {
  try {
    yield put(clinicLoadStartupDataStarted());

    const [
      inHouseLab,
      defaultLab,
      gvlLabs,
      vfdDrugSpecies,
      scriptDrugSpecies,
      ads,
      clinic,
      forSaleLookupLists,
      districtOffices
    ] = yield all([
      call(api.get, '/lab/inHouse'),
      call(api.get, '/lab/defaultLab'),
      call(api.get, '/lab/gvlLabs'),
      call(api.get, '/drug/species?type=feed'),
      call(api.get, '/drug/species?type=script'),
      call(api.get, '/ad'),
      call(api.get, `/clinic/${action.clinicId}`),
      action.hasForSale ? call(api.get, '/list/forSaleLookup') : null,
      action.isCanadian
        ? call(
            api.get,
            '/regulatoryAuthority/list?type=Canadian District Office'
          )
        : null
    ]);
    for (var index in ads) {
      let ad = ads[index];
      if (ad.hasImage) {
        ad.base64Image = yield call(api.getImageBase64, `ad/${ad.id}/image`);
      }
    }

    yield put(
      clinicLoadStartupDataSuccess({
        inHouseLab,
        // when the response gives no default lab it gives it as an empty array instead of an empty object; this fixes a propType validation warning.
        defaultLab: Array.isArray(defaultLab) ? {} : defaultLab,
        gvlLabs,
        vfdDrugSpecies,
        scriptDrugSpecies,
        ads,
        forSaleLookupLists,
        districtOffices,
        clinic
      })
    );
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(clinicLoadStartupDataFinished());
  }
}

export function* onClinicRefreshCviListRequest(action) {
  try {
    yield put(clinicRefreshCviListStarted());
    const results = yield all([
      call(Cvi.getDrafts),
      call(Cvi.getAwaitingPermit),
      call(Cvi.getRecentlySigned),
      call(Cvi.getRequests)
    ]);
    yield put(clinicRefreshCviListSuccess(results));
  } catch (err) {
    // TODO: Maybe we want to console.error this? If so there should probably be a flag so that we don't throw errors during testing.
    // console.error('Error loading CVI List data: ', err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading CVI List data'));
    yield put(clinicRefreshCviListFailure());
  }
}

export function* onClinicRefreshEecviListRequest(action) {
  try {
    yield put(clinicRefreshEecviListStarted());

    const results = yield all([
      call(Eecvi.getDrafts),
      call(Eecvi.getRecentlyCompleted)
    ]);
    yield put(clinicRefreshEecviListSuccess(results));
  } catch (err) {
    console.log(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading EECVI List data'));
    yield put(clinicRefreshEecviListFailure());
  }
}

// EECVI
export function* clinicArchiveEecvisRequest(action) {
  const { ids } = action;
  let successes = 0;

  // TODO: It would be lovely to use this sort of syntax instead of a for loop
  // const responses = yield* ids.map(id =>
  //   call(Eecvi.commit, id);
  // );

  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Eecvi.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'eecvi'));
      successes++;
    } catch (err) {
      // TODO: Something like this for better error message handling?
      // if (err.isAxiosError) {
      //   errors.add(err.response.data.errors[0].message);
      // } else {
      //   errors.add(err.message);
      // }
      yield put(apiFailure());
    }
  }

  if (successes > 0) {
    message.success(
      getMessages(
        successes,
        'archivedThing',
        'archivedThings',
        'EECVI',
        'EECVIs'
      )
    );
  }

  yield put(clinicEecviListReady());
}

export function* clinicDeleteEecvisRequest(action) {
  const { ids, history } = action;
  let successes = 0;

  // TODO: It would be lovely to use this sort of syntax instead of a for loop
  // const responses = yield* ids.map(id =>
  //   call(Eecvi.deleteRec, id);
  // );

  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Eecvi.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'eecvi'));
      successes++;
    } catch (err) {
      // TODO: Something like this for better error message handling?
      // if (err.isAxiosError) {
      //   errors.add(err.response.data.errors[0].message);
      // } else {
      //   errors.add(err.message);
      // }
      yield put(apiFailure());
    }
  }

  let msg;
  if (successes > 1) {
    msg = intl.get('deletedThings', { qty: successes, things: 'EECVIs' });
  } else if (successes === 1) {
    if (history) {
      history.push(`/eecvis`);
    }
    msg = intl.get('deletedThing', { thing: 'EECVI' });
  }

  if (msg) {
    message.success(msg);
  }

  yield put(clinicEecviListReady());
}

export function* onClinicBatchCommitEecviRequest(action) {
  let { readyToCommitList } = action;
  let errors = new Set();
  let updatedDocs = {};
  let successes = [];
  let idsToCommit = [];
  let errorIds = [];

  const isLicenseValidationEnabled = yield select(
    isLicenseValidationFeatureEnabled
  );
  if (isLicenseValidationEnabled) {
    let nvapLicense = yield select(selectNvapLicense);
    let nvapAuthorizedStates = '';
    if (nvapLicense?.validationStatus === 'ACCREDITED') {
      nvapAuthorizedStates = nvapLicense.authorizedStates;
    }

    readyToCommitList.forEach(doc => {
      if (
        nvapLicense?.validationStatus === 'ACCREDITED' &&
        !nvapAuthorizedStates.includes(doc.ownerPremises.state)
      ) {
        errorIds.push(doc.id);
      } else {
        idsToCommit.push(doc.id);
      }
    });
  } else {
    readyToCommitList.forEach(doc => {
      idsToCommit.push(doc.id);
    });
  }

  // clear previous errors
  yield put(clinicClearDocumentErrors('eecvi'));

  // TODO: It would be lovely to use this sort of syntax instead
  // const responses = yield* readyToCommitList.map(doc =>
  //   call(Eecvi.commit, doc.id);
  // );

  // similar to VFDs
  for (const index in readyToCommitList) {
    let updatedDoc = {};
    let docHasError = false;
    if (idsToCommit.includes(readyToCommitList[index].id)) {
      try {
        updatedDoc = yield call(Eecvi.commit, readyToCommitList[index].id);
        // handle errors
        if (updatedDoc.metadata.status !== 'SIGNED') {
          docHasError = true;
          errors.add(updatedDoc.error);
        } else {
          successes.push(updatedDoc.id);
        }
        // format api data so that we can merge it with the doc
        delete updatedDoc.status;
        delete updatedDoc.error;
      } catch (err) {
        docHasError = true;
        if (err.isAxiosError) {
          errors.add(err.response.data.errors[0].message);
        } else {
          errors.add(err.message);
        }
      }
    } else if (errorIds.includes(readyToCommitList[index].id)) {
      docHasError = true;
    }

    // retrieve the doc so we can update the properties
    const storeDoc = yield select(
      documentSelector,
      'eecvi',
      readyToCommitList[index].id
    );
    if (docHasError) {
      updatedDoc = storeDoc.merge({ tableRowClass: 'gvl-row-error' });
    }
    updatedDocs[updatedDoc.id] = updatedDoc;
  }
  yield put(clinicRefreshEecviSuccess(updatedDocs));
  yield put(clinicTrackDocumentSigning(successes, 'eecvi'));

  if (errors.size > 0 || errorIds.length > 0) {
    if (errors.size > 0) {
      const errorsArray = Array.from(errors);
      for (const index in errorsArray) {
        yield call(notification.error, {
          message: intl.get('eecvi.actions.commit.errors'),
          description: errorsArray[index]
        });
      }
    }
    if (errorIds.length > 0) {
      for (const index in errorIds) {
        yield call(notification.error, {
          message: intl.get('eecvi.actions.commit.errors'),
          description: errorIds[index]
        });
      }
    }
  } else {
    let msg;
    if (successes.length > 1) {
      msg = intl.get('committedThings', { qty: successes, things: 'EECVIs' });
    } else if (successes === 1) {
      msg = intl.get('committedThing', { thing: 'EECVI' });
    }
    if (msg) {
      message.success(msg);
    }
  }
}

export function* onClinicSaveEecviRequest(action) {
  let { doc, history, successMsg } = action;

  try {
    yield apply(doc, doc.save);
    yield put(clinicRefreshEecviSuccess({ [doc.id]: doc }));
    if (successMsg) {
      message.success(successMsg);
    }
    if (history && history.location.pathname !== `/eecvis/${doc.id}/edit`) {
      history.push(`/eecvis/${doc.id}/edit`);
    }
  } catch (err) {
    console.warn(err);
    yield call(notification.warning, {
      message: intl.get('error.saving.noun', { noun: 'EECVI' }),
      description: intl.get('server.error')
    });

    return;
  }
}

export function* onClinicCommitEecviRequest(action) {
  let { doc, history } = action;
  let updatedDoc = {};
  try {
    yield apply(doc, doc.save);
    updatedDoc = yield call(Eecvi.commit, doc.id);
  } catch (err) {
    let title = intl.get('eecvi.actions.commit.errors');
    yield put(clinicCommitEecviFailure(err, doc, history, title));
    return;
  }
  if (updatedDoc.metadata && updatedDoc.metadata.status !== 'SIGNED') {
    yield put(
      clinicCommitEecviFailure(doc.error || updatedDoc.error, doc, history)
    );
    return;
  }

  if (history) {
    yield call(history.push, `/eecvis/${updatedDoc.id}/show`);
  }
  message.success(intl.get('committedThing', { thing: 'EECVI' }));
  yield put(clinicRefreshEecviSuccess({ [updatedDoc.id]: updatedDoc }));
  yield put(clinicTrackDocumentSigning(updatedDoc.id, 'eecvi'));
}

export function* onClinicCommitEecviFailure(action) {
  const { error, doc, history, title } = action;

  yield put(apiFailure(error, title));
  // Even though the commit failed, the save may have updated the eecvi, so
  // if we have an id, we should use the success action to update the
  // document in the redux store.
  yield put(
    doc.id
      ? clinicRefreshEecviSuccess({ [doc.id]: doc })
      : clinicRefreshEecviFailure()
  );
  if (
    history &&
    doc.id &&
    history.location.pathname !== `/eecvis/${doc.id}/edit`
  ) {
    yield call(history.push, `/eecvis/${doc.id}/edit`);
  }
}

export function* onClinicActivateEecvi(action) {
  const { id } = action;
  let eecvi = yield select(
    state => state.accounts.clinic.dataLists.eecvi.data.documents[id]
  );
  if (id && !eecvi) {
    yield put(clinicRefreshEecviRequest(id));
  }
}

export function* onClinicRefreshEecviRequest(action) {
  const { id } = action;
  try {
    yield put(clinicRefreshEecviStarted());
    const eecvi = yield call(Eecvi.read, id);
    yield put(clinicRefreshEecviSuccess({ [eecvi.id]: eecvi }));
  } catch (err) {
    console.log('refresh eecvi failure', err);
    yield put(apiFailure('Error loading EECVI data'));
    yield put(clinicRefreshEecviFailure());
  }
}

// EIA
export function* onClinicRefreshEiaListRequest(action) {
  try {
    yield put(clinicRefreshEiaListStarted());
    let drafts = yield call(Eia.getDrafts);
    for (let draft of drafts) {
      const premisesCollection = (yield select(getClinicDataLists))
        .documentModels.premisesCollection;
      if (draft.originPremises && draft.originPremises.id) {
        let originPremises = premisesCollection[draft.originPremises.id];
        if (!originPremises) {
          //Fetch from origin if not already in the redux store
          originPremises = yield Location.read(draft.originPremises.id);
          yield put(clinicPremisesUpdate(originPremises));
        }
        if (draft.originPremises.version < originPremises.version) {
          // Update origin premises to latest version, to ensure we have county information
          draft.originPremises = originPremises;
          try {
            yield apply(draft, draft.save);
          } catch (err) {
            console.warn('error updating EIA draft originPremises', draft);
          }
        }
      }
    }
    const documentLists = yield all([
      call(Eia.getLabApproval),
      call(Eia.getRecentlySigned)
    ]);
    documentLists.unshift(drafts);
    const requests = yield call(Request.getEiaRequests);
    yield put(clinicRefreshEiaListSuccess(flatten(documentLists), requests));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading EIA List data'));
    yield put(clinicRefreshEiaListFailure());
  }
}

export function* onClinicActivateEia(action) {
  const { id, isRequest } = action;
  let storeData = yield select(
    state => state.accounts.clinic.dataLists.eia.data
  );
  const documentsKey = isRequest ? 'requests' : 'documents';

  if (id && !storeData[documentsKey][id]) {
    yield put(clinicRefreshEiaRequest(id, isRequest));
  }
}

export function* onClinicRefreshEiaRequest(action) {
  const { id, isRequest } = action;
  try {
    yield put(clinicRefreshEiaStarted());
    const eia = yield call(isRequest ? Request.read : Eia.read, id);
    yield put(clinicRefreshEiaSuccess(eia));
  } catch (err) {
    // TODO: Maybe we want to console.error this? If so there should probably be a flag so that we don't throw errors during testing.
    // console.error('Error loading CVI data: ', err);
    yield put(apiFailure('Error loading EIA data'));
    yield put(clinicRefreshEiaFailure());
  }
}

export function* onClinicSaveEiaRequest(action) {
  let { eia, history, successMsg } = action;
  const errors = yield apply(eia, eia.validate);
  const failureNotification = msgId => {
    notification.warning({
      message: intl.get('error.saving.noun', { noun: 'EIA' }),
      description: intl.get(msgId)
    });
  };

  if (!errors) {
    try {
      const requestId = eia.requestId;
      yield apply(eia, eia.save);
      // yield put(clinicResetDocumentModels('eia', eia.id));
      // check if this document was saved from a request, if so, remove that request from the list.
      if (requestId) {
        yield put(clinicRequestRemovedFromList('eia', requestId));
        yield put(clinicRefreshEiaSuccess(eia));
      } else {
        yield put(clinicSaveEiaSuccess(eia));
      }
      if (successMsg) {
        message.success(successMsg);
      }
      if (history && history.location.pathname !== `/eias/${eia.id}/edit`) {
        history.push(`/eias/${eia.id}/edit`);
      }
    } catch (err) {
      console.log(err);
      failureNotification('server.error');
      yield put(clinicSaveEiaFailure());
      return;
    }
  } else {
    failureNotification('form.entries.error');
    yield put(clinicSaveEiaFailure());
  }
}

export function* onClinicSignEiaRequest(action) {
  let {
    eia,
    username,
    password,
    saveBeforeSign,
    redirectToShow,
    history,
    autoDownload,
    notifyClinic,
    notifyVet
  } = action;
  try {
    // We updated the origin location with the county information before batch signing, we update the originPremise in the document with the value from the redux store in this step
    //TODO This should be done in the saga associated with the county model callback
    if (!eia.originPremises.county) {
      //Update the premises from redux store if not present
      const premisesCollection = (yield select(getClinicDataLists))
        .documentModels.premisesCollection;
      eia.originPremises = premisesCollection[eia.originPremises.id];
      yield call([eia, eia.save]);
    }
    if (saveBeforeSign) {
      yield call([eia, eia.save]);
      // entry will be updated in the redux store after sign
    }
    const signedEia = yield call(
      Eia.sign,
      eia.id,
      username,
      password,
      undefined,
      notifyClinic,
      notifyVet
    );
    if (autoDownload) yield call(Eia.downloadLabSubmittalForm, [eia.id]);
    yield put(clinicSignEiaSuccess(signedEia));
    message.success(intl.get('signedThing', { thing: 'EIA' }));
    yield put(clinicTrackDocumentSigning(signedEia.id, 'eia'));
    if (redirectToShow) {
      yield call(history.push, `/eias/${eia.id}/show`);
    }
  } catch (err) {
    yield put(clinicRefreshEiaSuccess(eia)); //Update entry in the redux store in case the save preceding the sign did succeed.
    if (err && err.response && err.response.data && err.response.data.errors) {
      yield put(clinicSignEiaFailure());
      message.error(err.response.data.errors[0].message, 5);
    } else {
      yield put(clinicSignEiaFailure());
      yield call(notification.warning, {
        message: intl.get('error.signing.noun', {
          noun: 'EIA'
        }),
        description: intl.get('server.error')
      });
    }
    if (
      history &&
      history.location.pathname !== `/eias/${eia.id}/edit` &&
      saveBeforeSign
    ) {
      history.push(`/eias/${eia.id}/edit`);
    }
  }
}

export function* onClinicBatchSignEiaRequest(action) {
  let {
    readyToSignList,
    notReadyToSignList,
    username,
    password,
    autoDownload,
    notifyClinic,
    notifyVet
  } = action;

  const premisesCollection = (yield select(getClinicDataLists)).documentModels
    .premisesCollection;
  const eiasThatNeedCountySaved = readyToSignList
    .filter(eia => !eia.originPremises.county)
    .map(
      eia =>
        new Eia({
          ...eia,
          originPremises: premisesCollection[eia.originPremises.id]
        })
    );
  for (let eia of eiasThatNeedCountySaved) {
    yield apply(eia, eia.save);
  }

  const idsToSign = readyToSignList.map(eia => eia.id);
  let signedEias = [];
  let signedEiaIds = [];
  let errorIds = [];

  try {
    const results = yield call(
      Eia.batchSign,
      idsToSign,
      username,
      password,
      undefined,
      notifyClinic,
      notifyVet
    );

    idsToSign.forEach(id => {
      const eia = results[id];
      if (eia && eia.status === 200) {
        signedEiaIds.push(id);
        signedEias.push({
          id,
          version: eia.version,
          serialNumber: eia.serialNumber,
          signingDate: eia.signingDate
        });
      } else {
        errorIds.push(id);
      }
    });

    if (autoDownload) {
      const includeImage = yield select(getUserIncludeImage);
      yield call(Eia.downloadLabSubmittalForm, signedEiaIds, includeImage);
    }
    clinicBatchSignSuccessMsgs(errorIds, notReadyToSignList, 'EIA');
    yield put(
      clinicBatchSignEiaSuccess(signedEias, errorIds, notReadyToSignList)
    );
    yield put(
      clinicTrackDocumentSigning(
        signedEias.map(doc => doc.id),
        'eia'
      )
    );
  } catch (err) {
    console.log(err);
    clinicBatchSignFailureMsgs(notReadyToSignList, 'EIA');
    yield put(clinicBatchSignEiaFailure(readyToSignList, notReadyToSignList));
    return;
  }
}

export function* onClinicCreateCviFromEiaRequest(action) {
  let { eia } = action;
  try {
    const cvi = yield call(Eia.createCviFromEia, eia);
    yield put(clinicCreateCviFromEiaSuccess(cvi));
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      yield put(
        clinicCreateCviFromEiaFailure(null, err.response.data.errors[0].message)
      );
    } else if (err && err.response && err.response.status === 400) {
      yield put(
        clinicCreateCviFromEiaFailure(
          'eia.createCvi.error.missing.animal',
          null
        )
      );
    } else {
      console.log(err);
      yield put(clinicCreateCviFromEiaFailure('server.error', null));
    }
  }
}

// TODO: no need for saga generator functions if all that is happening is a message/notification
// this should be wrapped into the function that triggers it.
export function* onClinicCreateCviFromEiaFailure(action) {
  const { msgId, specificMessage } = action;

  if (specificMessage) {
    message.error(specificMessage, 5);
  } else {
    yield call(notification.warning, {
      message: intl.get('eia.createCvi.error'),
      description: intl.get(msgId)
    });
  }
}

export function* onClinicProcessEiasRequest(action) {
  const { ids, eiaMethod, history } = action;
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      let eia = yield call(Eia[eiaMethod], id);
      if (!eia) {
        // This must have been a delete call
        yield put(clinicRemoveDocSuccess(id, 'eia'));
        if (history) {
          history.push(`/eias`);
        }
      } else {
        yield put(clinicRefreshEiaSuccess(eia));
      }
    } catch (err) {
      yield put(
        apiFailure(`Unable to perform Eia ${eiaMethod} request for ${id}`)
      );
    }
  }
}

export function* onClinicArchiveEiasRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      yield call(Eia.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'eia'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  if (successes > 0) {
    message.success(
      getMessages(successes, 'archivedThing', 'archivedThings', 'EIA', 'EIAs')
    );
  }
  yield put(clinicEiaListReady());
}

export function* onClinicDeleteEiasRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      yield call(Eia.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'eia'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  if (successes > 0) {
    message.success(
      getMessages(successes, 'deletedThing', 'deletedThings', 'EIA', 'EIAs')
    );
  }
  yield put(clinicEiaListReady());
}

export function* onClinicAutoArchiveEiaRequest(action) {
  const { id } = action;
  try {
    yield call(Eia.archive, id);
    yield put(clinicAutoArchiveEiaSuccess(id));
  } catch (err) {
    yield put(apiFailure());
  }
}

export function* onClinicAddRequiredEiaCountyRequest(action) {
  const { locations } = action;
  try {
    for (let location of locations) {
      location.requireNationalId = false;
      yield call([location, location.save]);
      yield put(clinicPremisesUpdate(location));
    }
    yield put(clinicAddRequiredEiaCountySuccess());
  } catch (err) {
    yield put(apiFailure(err));
    yield put(clinicAddRequiredEiaCountyFailed());
  }
}

// CVI
export function* onClinicActivateCvi(action) {
  const { id } = action;
  let storeDocuments = yield select(
    state => state.accounts.clinic.dataLists.cvi.data.documents
  );
  if (id && !storeDocuments[id]) {
    yield put(clinicRefreshCviRequest(id));
  }
}

export function* onClinicRefreshCviRequest(action) {
  const { id } = action;
  try {
    yield put(clinicRefreshCviStarted());
    const cvi = yield call(Cvi.read, id);
    yield put(clinicRefreshCviSuccess(cvi));
  } catch (err) {
    // TODO: Maybe we want to console.error this? If so there should probably be a flag so that we don't throw errors during testing.
    // console.error('Error loading CVI data: ', err);
    yield put(apiFailure('Error loading CVI data'));
    yield put(clinicRefreshCviFailure());
  }
}

export function* onClinicSaveCviRequest(action) {
  let { cvi, history, isAutosave, afterSaved } = action;
  try {
    yield call([cvi, cvi.save]); //Perform cvi.save()
    // yield put(clinicResetDocumentModels('cvi', cvi.id));
    yield put(clinicSaveDocSuccess(cvi));
    if (
      history &&
      history.location.pathname !== `/cvis/${cvi.id}/edit` &&
      !isAutosave
    ) {
      history.push(`/cvis/${cvi.id}/edit`);
    }
    message.success(
      intl.get(isAutosave ? 'autosavedWithNoun' : 'savedWithNoun', {
        noun: 'CVI'
      })
    );
    if (afterSaved) afterSaved();
  } catch (err) {
    console.log(err);
    console.log(err.response);
    yield put(clinicDocRequestDone(cvi));
    api.handleApiErrorsWithTitle(
      err.response?.data?.errors,
      intl.get('error.saving.noun', { noun: 'CVI' })
    );
    return;
  }
}

export function* onClinicCviAddPermitRequest(action) {
  let { id, permitNumber } = action;
  try {
    const cvi = yield call(Cvi.addPermit, id, permitNumber);
    yield put(clinicCviAddPermitSuccess(cvi));
    message.success(intl.get('cvi.addPermitNumber.success', { id: cvi.id }));
  } catch (err) {
    console.log(err);
    yield call(notification.warning, {
      message: intl.get('cvi.addPermitNumber.error'),
      description: intl.get('server.error')
    });

    return;
  }
}

export function* onClinicSignCviRequest(action) {
  let {
    cvi,
    username,
    password,
    showImages,
    saveBeforeSign,
    redirectToShow,
    history
  } = action;
  try {
    if (saveBeforeSign) {
      yield call([cvi, cvi.save]); //Perform cvi.save()
      // Entry will be updated in the redux store post sign
    }
    const signedCvi = yield call(
      Cvi.sign,
      cvi.id,
      username,
      password,
      showImages
    );
    message.success(intl.get('signedThing', { thing: 'CVI' }));
    yield put(clinicSignCviSuccess(signedCvi));
    yield put(clinicTrackDocumentSigning(signedCvi.id, 'cvi'));
    if (redirectToShow && history) {
      history.push(`/cvis/${cvi.id}/show`);
    }
  } catch (err) {
    console.error(err);
    // Update entry in the redux store in case save preceeding sign succeeded
    yield put(clinicRefreshCviSuccess(cvi));
    api.handleSimpleApiError(err);
    yield put(clinicSignCviFailure());
    if (
      history &&
      history.location.pathname !== `/cvis/${cvi.id}/edit` &&
      saveBeforeSign
    ) {
      history.push(`/cvis/${cvi.id}/edit`);
    }
    return;
  }
}

export function* onClinicBatchSignCviRequest(action) {
  let {
    readyToSignList,
    notReadyToSignList,
    username,
    password,
    showImages
  } = action;
  let idsToSign = [];
  let errorIds = [];
  let signedCvis = [];

  const isLicenseValidationEnabled = yield select(
    isLicenseValidationFeatureEnabled
  );
  if (isLicenseValidationEnabled) {
    // Filter CVIs so that readyToSignList contains only CVIs where vet authorizedStates includes cvi.originState
    // CVIs that don't match go straight to errorIds without being sent to the API in idsToSign.
    let nvapLicense = yield select(selectNvapLicense);
    let nvapAuthorizedStates = '';
    if (nvapLicense?.validationStatus === 'ACCREDITED') {
      nvapAuthorizedStates = nvapLicense.authorizedStates;
    }

    readyToSignList.forEach(doc => {
      if (
        nvapLicense?.validationStatus === 'ACCREDITED' &&
        !nvapAuthorizedStates.includes(doc.originPremises.state)
      ) {
        errorIds.push(doc.id);
      } else {
        idsToSign.push(doc.id);
      }
    });
  } else {
    readyToSignList.forEach(doc => {
      idsToSign.push(doc.id);
    });
  }

  try {
    const results = yield call(
      Cvi.batchSign,
      idsToSign,
      username,
      password,
      showImages
    );

    idsToSign.forEach(id => {
      const cvi = results[id];
      if (cvi && cvi.status === 200) {
        signedCvis.push({
          id,
          version: cvi.version,
          serialNumber: cvi.serialNumber,
          signingDate: cvi.signingDate
        });
      } else {
        errorIds.push(id);
      }
    });
    clinicBatchSignSuccessMsgs(errorIds, notReadyToSignList, 'CVI');
    yield put(
      clinicBatchSignCviSuccess(signedCvis, errorIds, notReadyToSignList)
    );
    yield put(
      clinicTrackDocumentSigning(
        signedCvis.map(doc => doc.id),
        'cvi'
      )
    );
  } catch (err) {
    console.log(err);
    clinicBatchSignFailureMsgs(notReadyToSignList, 'CVI');
    yield put(clinicBatchSignCviFailure(readyToSignList, notReadyToSignList));
    return;
  }
}

export function clinicBatchSignSuccessMsgs(errorIds, notReadyList, thing) {
  if (notReadyList.length > 0) {
    message.warning(intl.get('incompleteDeselectedProperNoun', { thing }), 8);
  }
  if (errorIds.length > 0) {
    message.error(
      intl.get('someNotSignedProperNoun', { thing }),
      notReadyList.length > 0 ? 8 : 5
    );
  } else {
    message.success(
      intl.get('batchSignSuccessProperNoun', { thing }),
      notReadyList.length > 0 ? 8 : 5
    );
  }
}

export function clinicBatchSignFailureMsgs(notReadyList, thing) {
  if (notReadyList.length > 0) {
    message.warning(intl.get('incompleteDeselectedProperNoun', { thing }), 8);
  }
  message.error(intl.get('batchSignApiError'), notReadyList.length > 0 ? 8 : 5);
}

export function* onClinicArchiveCvisRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Cvi.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'cvi'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  if (successes > 0) {
    message.success(
      getMessages(successes, 'archivedThing', 'archivedThings', 'CVI', 'CVIs')
    );
  }
  yield put(clinicCviListReady());
}

export function* onClinicDeleteCvisRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Cvi.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'cvi'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  if (successes > 0) {
    message.success(
      getMessages(successes, 'deletedThing', 'deletedThings', 'CVI', 'CVIs')
    );
  }
  yield put(clinicCviListReady());
}

export function* onClinicCopyDocRequest(action) {
  let { doc } = action;
  try {
    yield call([doc, doc.save]); //Perform cvi.save()
    doc.id = null;
    if (doc.type === 'IHC') {
      doc.animal.vacs = [];
      doc.vet = null;
    }
    yield call([doc, doc.save]); //Perform cvi.save() for the copy
    yield put(clinicRefreshDocSuccess(doc));
    message.success(intl.get('doc.duplicate.message', { type: doc.type }));
    return;
  } catch (err) {
    console.log(err);
    yield put(clinicDocRequestDone());
    message.error(intl.get('error.copying.properNoun', { type: doc.type }));
    return;
  }
}

export function* onClinicDeleteCviRequest(action) {
  let { id, history } = action;
  try {
    yield call(Cvi.deleteRec, id);
    yield put(clinicRemoveDocSuccess(id, 'cvi'));
    history.push('/cvis');
    message.success(intl.get('cvi.delete.success.message'));
    return;
  } catch (err) {
    console.log(err);
    yield put(clinicDeleteCviFailure());
    message.error(
      intl.get('error.deleting.properNoun', {
        properNoun: 'CVI'
      })
    );
    return;
  }
}

//Contact
export function* onClinicRefreshContactListRequest(action) {
  try {
    yield put(clinicRefreshContactListStarted());
    const data = yield call(Contact.getContactData);
    yield put(clinicRefreshContactListSuccess(data));
  } catch (err) {
    console.log(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Contact List data'));
    yield put(clinicRefreshContactListFailure());
  }
}

export function* onClinicRefreshContactRequest(action) {
  const { contactId } = action;
  try {
    // yield put(clinicRefreshContactListStarted());
    const contact = yield call(Contact.read, contactId);
    yield put(clinicRefreshContactSuccess(contact));
  } catch (err) {
    console.log(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Contact data'));
    // yield put(clinicRefreshContactListFailure());
  }
}

export function* onClinicGetContactRequest({
  id,
  forceApiCall = false,
  callback,
  getAllPremises
}) {
  try {
    const dataLists = yield select(getClinicDataLists);
    let remoteContact = dataLists.documentModels.contactsCollection[id];
    let allPrems = [];
    if (!remoteContact || forceApiCall || getAllPremises) {
      remoteContact = yield Contact.read(id);
      yield put(clinicContactUpdate(remoteContact));
      if (getAllPremises) {
        allPrems = yield Contact.getFullPremisesList(id);
        yield put(clinicPremisesUpdate(allPrems));
      } else {
        yield put(clinicPremisesUpdate(remoteContact.primaryPremises));
      }
    }
    if (typeof callback === 'function') {
      yield callback(remoteContact, allPrems);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* onClinicGetPremisesRequest({
  id,
  forceApiCall = false,
  callback
}) {
  try {
    const dataLists = yield select(getClinicDataLists);
    let remotePrem = dataLists.documentModels.premisesCollection[id];
    if (!remotePrem || forceApiCall) {
      remotePrem = yield Location.read(id);
      yield put(clinicPremisesUpdate(remotePrem));
    }
    if (typeof callback === 'function') {
      yield callback(remotePrem);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* onClinicDeleteContactRequest({ contactIds }) {
  yield put(clinicDeleteContactStarted());
  try {
    for (var i = 0; i < contactIds.length; i++) {
      let id = contactIds[i];
      yield call(Contact.deleteContact, id);
      yield put(clinicContactRemovedFromList(id));
    }
    yield put(clinicDeleteContactSuccess(contactIds));
    message.success(
      intl.get(
        contactIds.length > 1
          ? 'contact.contactsDeleted'
          : 'contact.contactDeleted'
      )
    );
    yield put(clinicContactListReady());
  } catch (err) {
    yield put(apiFailure());
    message.error(
      intl.get('error.deleting.properNoun', {
        properNoun: intl.get('contact')
      })
    );
    yield put(clinicDeleteContactFailure());
  }
}

export function* onClinicMvlGrantAccess(action) {
  const { contacts, cb } = action;
  let apiCalls = [];
  let badContacts = [];

  for (let contact of contacts) {
    if (!Contact.fields.ownerEmail.validate(contact.ownerEmail || 'noEmail')) {
      apiCalls.push(call(Contact.grantMVLAccess, contact.id));
    } else {
      console.log('ownerEmail failed validataion', contact.ownerEmail);
      badContacts.push(contact);
    }
  }
  console.log('badContacts', badContacts);
  const updatedContacts = yield all(apiCalls);
  for (let contact of updatedContacts) {
    yield put(clinicContactUpdate(contact));
  }
  message.success(intl.get('mvlAccess.granted'));
  if (cb) cb(updatedContacts);
}

export function* onClinicMvlRevokeAccess(action) {
  const { contacts, cb } = action;
  let apiCalls = [];

  for (let contact of contacts) {
    apiCalls.push(call(Contact.revokeMVLAccess, contact.id));
  }
  const updatedContacts = yield all(apiCalls);
  for (let contact of updatedContacts) {
    yield put(clinicContactUpdate(contact));
  }
  if (cb) cb(updatedContacts);
}

// VFDs
export function* onClinicActivateVfd(action) {
  const { id, isRequest } = action;
  let storeData = yield select(
    state => state.accounts.clinic.dataLists.vfd.data
  );
  const documentsKey = isRequest ? 'requests' : 'documents';
  if (id && !storeData[documentsKey][id]) {
    yield put(clinicRefreshVfdRequest(id, isRequest));
  }
}

export function* onClinicRefreshVfdRequest(action) {
  const { id, isRequest } = action;
  try {
    yield put(clinicRefreshVfdStarted());
    const vfd = yield call(isRequest ? Request.read : Vfd.read, id);
    yield put(clinicRefreshDocSuccess(vfd));
  } catch (err) {
    yield put(apiFailure('Error loading VFD data'));
    yield put(clinicRefreshVfdFailure());
  }
}

export function* onClinicSaveVfdRequest(action) {
  let { vfd, history, successMsg } = action;
  const errors = yield apply(vfd, vfd.validate);
  if (!errors) {
    try {
      const requestId = vfd.requestId;
      // const shouldDoRedirect = !vfd.id;
      yield apply(vfd, vfd.save);
      // yield put(clinicResetDocumentModels('vfd', vfd.id));
      // check if this document was saved from a request, if so, remove that request from the list.
      if (requestId) {
        yield put(clinicRequestRemovedFromList('vfd', requestId));
      }
      yield put(clinicRefreshDocSuccess(vfd));
      if (successMsg) {
        message.success(successMsg);
      }
      if (history && history.location.pathname !== `/vfds/${vfd.id}/edit`) {
        history.push(`/vfds/${vfd.id}/edit`);
      }
    } catch (err) {
      console.log(err);
      yield put(clinicDocRequestDone());
      yield call(notification.warning, {
        message: intl.get('error.saving.noun', { noun: 'VFD' }),
        description: intl.get('server.error')
      });
      return;
    }
  } else {
    yield put(clinicDocRequestDone());
    yield call(notification.warning, {
      message: intl.get('error.saving.noun', { noun: 'VFD' }),
      description: intl.get('form.entries.error')
    });
  }
}

// NOTE: This pattern of using a flag in file scope (in this case `processingVfdRenewal`) is useful here since the API it's wrapped around should be relatively quick, but in general if you want to provide feedback to the user that some event is in-progress it would be better to store that state in redux.
let processingVfdRenewal;
export function* onClinicProcessVfdRenewalReview({ id, data }) {
  if (processingVfdRenewal) {
    return;
  }
  processingVfdRenewal = true;
  try {
    let newVfd = yield call(Vfd.renewalReview, id, data);
    // refresh the vfd document store with the returned vfd
    yield put(clinicRefreshDocSuccess(newVfd));
    // update the original vfd's renewalStatus so it is filtered in the list view
    let oldVfd = yield select(documentSelector, 'vfd', id);
    yield put(
      clinicRefreshDocSuccess(
        oldVfd.setIn(['metadata', 'renewalStatus'], 'RENEWED')
      )
    );
    // also check the special case where a renewalReview comes back as a draft, this
    // can happen if the original drug or feedsite values are no longer valid.
    if (newVfd.metadata.status === 'SAVED') {
      // TODO: all yeilds on notifications that are intended to be non-blocking should be removed.
      yield call(notification.warning, {
        message: intl.get('vfd.renewal.error'),
        description: intl.get('vfd.renewal.to.draft')
      });
    } else {
      message.success(intl.get('vfd.renewal.to.review'));
    }
  } catch (err) {
    yield put(
      apiFailure(`Unable to perform vfd renewal review request for ${id}`)
    );
  }
  processingVfdRenewal = false;
}

export function* onClinicProcessVfdsRenewalReject({ ids }) {
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      let vfd = yield call(Vfd.renewalReject, id);
      // the original vfd that was used to create the renewal should
      // be returned by the api and added back to the vfd store
      yield put(clinicRefreshDocSuccess(vfd));
      // the renewal document that was rejected should be deleted from memory
      yield put(clinicRemoveDocSuccess(id, 'vfd'));
    } catch (err) {
      yield put(apiFailure(`Unable to perform vfd renewal reject on ${id}`));
    }
  }
}

export function* onClinicProcessVfdsRenewalAccept({ ids, credentials, modal }) {
  let errors = new Set();
  let successIds = [];
  let results = {};
  // clear previous errors
  yield put(clinicClearDocumentErrors('vfd'));
  try {
    results = yield call(
      Vfd.batchSign,
      ids,
      credentials.username,
      credentials.password
    );
    for (const id in results) {
      const data = results[id];
      // handle errors
      if (data.status !== 200) {
        errors.add(data.error);
        data.tableRowClass = 'gvl-row-error';
      } else {
        successIds.push(id);
      }
      // format api data so that we can merge it with the vfd
      data.metadata = {
        status: data.status === 200 ? 'SIGNED' : 'PARTIALLY_SIGNED'
      };
      delete data.status;
      delete data.error;
      // retrieve the vfd so we can update the properties
      const storeVfd = yield select(documentSelector, 'vfd', id);
      const updatedVfd = storeVfd.merge(data, {
        deep: true
      });
      yield put(clinicRefreshDocSuccess(updatedVfd));
    }
  } catch (err) {
    errors.add(err);
  } finally {
    yield call(modal.close);
    // TODO: this could be a single saga for all batch signs :(
    if (errors.size > 0) {
      yield put(
        apiFailure([...errors], intl.get('vfds.actions.renewalAccept.errors'))
      );
    } else if (results) {
      message.success(intl.get('vfds.actions.renewalAccept.success'));
    }
    yield put(clinicTrackDocumentSigning(successIds, 'vfd'));
  }
}

export function* onClinicRefreshVfdsListRequest(_action) {
  try {
    yield put(clinicRefreshVfdsListStarted());
    const documentLists = yield all([
      call(Vfd.getDrafts),
      call(Vfd.getRecentlySigned),
      call(Vfd.getDraftReviews),
      call(Vfd.getRenewals),
      call(Vfd.getRenewalReviews)
    ]);
    const requests = yield call(Request.getVfdRequests);
    yield put(clinicRefreshVfdsListSuccess(flatten(documentLists), requests));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading Vfds List data'));
    yield put(clinicRefreshVfdsListFailure());
  }
}

export function* onClinicSignVfdRequest(action) {
  const { id, username, password, history, modal } = action;
  try {
    let vfd = yield call(Vfd.sign, id, username, password);
    yield put(clinicRefreshDocSuccess(vfd));
    history.push(`/vfds/${id}/show`);
    message.success(intl.get('signedThing', { thing: 'VFD' }));
    yield put(clinicTrackDocumentSigning(id, 'vfd'));
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield call(modal.close);
  }
}

export function* onClinicLockDocRequest(action) {
  let { doc, onSuccess } = action;
  try {
    yield call([doc, doc.save]); //Perform doc.save() will be specific to the doc type
    const lockedDoc = yield call(Document.lock, doc.id);
    yield put(clinicRefreshDocSuccess(lockedDoc));
    // if was brand new (not a saved draft), we may need to push history so we get to the show view.
    if (onSuccess) onSuccess(lockedDoc);
    message.success(
      intl.get('doc.lock.success', {
        type: doc.type === 'IHC' ? intl.get('ihc.brandName') : doc.type
      })
    );
  } catch (err) {
    console.log(err);
    yield put(clinicDocRequestDone());
    api.handleSimpleApiError(err);
  }
}

export function* onClinicUnlockDocRequest(action) {
  let { doc, onSuccess } = action;
  try {
    // yield call([doc, doc.save]); // locked docs shouldn't have changes that need to be saved upon unlock
    const unlockedDoc = yield call(Document.unlock, doc.id);
    yield put(clinicRefreshDocSuccess(unlockedDoc));
    if (onSuccess) onSuccess(unlockedDoc);
    message.success(
      intl.get('doc.unlock.success', {
        type: doc.type === 'IHC' ? intl.get('ihc.brandName') : doc.type
      })
    );
  } catch (err) {
    console.log(err);
    yield put(clinicDocRequestDone());
    api.handleSimpleApiError(err);
  }
}

export function* onClinicProcessVfdsRequest(action) {
  const { ids, vfdMethod, history } = action;
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      let vfd = yield call(Vfd[vfdMethod], id);
      if (!vfd) {
        // This must have been a delete call
        yield put(clinicRemoveDocSuccess(id));
        if (history) {
          history.push(`/vfds`);
        }
      } else {
        yield put(clinicRefreshDocSuccess(vfd));
      }
    } catch (err) {
      yield put(
        apiFailure(`Unable to perform vfd ${vfdMethod} request for ${id}`)
      );
    }
  }
}

/**
 * Loops through a set of VFDs and requests each one be archived
 * @param action array of VFD IDs to be archived
 */
export function* onClinicArchiveVfdsRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    let id = ids[i];
    try {
      yield call(Vfd.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'vfd'));
      successes++;
    } catch (err) {
      yield put(apiFailure(err));
    }
  }
  if (successes > 0) {
    yield call(
      message.success,
      getMessages(successes, 'archivedThing', 'archivedThings', 'VFD', 'VFDs')
    );
  }
  // TODO: maybe should be yield put(clinicVfdListReady()); but that action does not exist
  yield put(clinicDocRequestDone());
}

/**
 * Loops through a set of VFDs and deletes each one if possible
 * @param action array of IDs to be deleted
 */
export function* onClinicDeleteVfdsRequest(action) {
  const { ids, history } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Vfd.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'vfd'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }

  let msg;
  if (successes > 1) {
    msg = intl.get('deletedThings', {
      qty: successes,
      things: intl.get('vfds')
    });
  } else if (successes === 1) {
    if (history) {
      history.push('/vfds');
    }
    msg = intl.get('deletedThing', { thing: intl.get('vfd') });
  }
  if (msg) {
    message.success(msg);
  }
  yield put(clinicVfdListReady());
}

// Rabies
export function* onClinicRefreshRabiesListRequest(_action) {
  try {
    yield put(clinicRefreshRabiesListStarted());
    const documents = yield all([
      call(Rabies.getDrafts),
      call(Rabies.getRecentlySigned)
    ]);
    yield put(clinicRefreshRabiesListSuccess(flatten(documents)));
  } catch (err) {
    yield put(apiFailure('Error loading Rabies List data'));
    yield put(clinicRefreshRabiesListFailure());
  }
}

export function* onClinicActivateRabies(action) {
  const { id } = action;

  let doc = yield select(
    state => state.accounts.clinic.dataLists.rabies.data.documents[id]
  );
  if (id && !doc) {
    yield put(clinicRefreshRabiesRequest(id));
  }
}

export function* onClinicRefreshRabiesRequest(action) {
  const { id } = action;
  try {
    yield put(clinicRefreshRabiesStarted());
    const doc = yield call(Rabies.read, id);
    yield put(clinicRefreshRabiesSuccess({ [doc.id]: doc }));
  } catch (err) {
    yield put(apiFailure('Error loading rabies data'));
    yield put(clinicRefreshRabiesFailure());
  }
}

export function* onClinicSaveRabiesRequest(action) {
  let { doc, history, successMsg, onSuccess } = action;

  try {
    yield apply(doc, doc.save);
    yield put(clinicSaveDocSuccess(doc));
    if (successMsg) {
      message.success(successMsg);
    }
    if (history && history.location.pathname !== `/rabies/${doc.id}/edit`) {
      history.push(`/rabies/${doc.id}/edit`);
    }
    if (onSuccess) onSuccess(doc);
  } catch (err) {
    console.warn(err);
    yield put(clinicDocRequestDone());
    notification.warning({
      message: intl.get('error.saving.noun', {
        noun: intl.get('rabiesCert.lowerCase')
      }),
      description: intl.get('server.error')
    });
    return;
  }
}

export function* onClinicSignRabiesRequest(action) {
  let { doc, credentials, modal, history } = action;
  let updatedDoc;

  try {
    yield apply(doc, doc.save);
    updatedDoc = yield call(
      Rabies.sign,
      doc.id,
      credentials.username,
      credentials.password,
      credentials.showImages
    );
  } catch (err) {
    yield put(clinicSignRabiesFailure(err, doc, history));
  } finally {
    if (updatedDoc) {
      if (updatedDoc.metadata.status !== 'SIGNED') {
        yield put(
          clinicSignRabiesFailure(doc.error || updatedDoc.error, doc, history)
        );
      } else {
        if (history) {
          history.push(`/rabies/${updatedDoc.id}/show`);
        }
        yield put(clinicSignRabiesSuccess(updatedDoc));
        message.success(
          intl.get('signedThing', { thing: intl.get('rabiesCert') })
        );
        yield put(clinicRefreshRabiesSuccess({ [updatedDoc.id]: updatedDoc }));
        yield put(clinicTrackDocumentSigning(updatedDoc.id, 'rabies'));
      }
    }
    yield call(modal.close);
  }
}

export function* onClinicSignRabiesFailure(action) {
  const { error, doc, history } = action;
  yield put(apiFailure(error));
  // Even though the commit failed, the save may have updated the doc, so
  // if we have an id, we should use the success action to update the
  // document in the redux store.
  yield put(
    doc.id
      ? clinicRefreshRabiesSuccess({ [doc.id]: doc })
      : clinicRefreshRabiesFailure()
  );
  if (
    history &&
    doc.id &&
    history.location.pathname !== `/rabies/${doc.id}/edit`
  ) {
    yield call(history.push, `/rabies/${doc.id}/edit`);
  }
}

export function* onClinicDeleteRabiesRequest(action) {
  const { ids, history } = action;
  let successes = 0;
  // TODO: It would be lovely to use this sort of syntax instead of a for loop
  // const responses = yield* ids.map(id =>
  //   call(Rabies.deleteRec, id);
  // );

  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Rabies.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'rabies'));
      successes++;
    } catch (err) {
      // TODO: Something like this for better error message handling?
      // if (err.isAxiosError) {
      //   errors.add(err.response.data.errors[0].message);
      // } else {
      //   errors.add(err.message);
      // }
      yield put(apiFailure());
    }
  }

  let msg;
  if (successes > 1) {
    msg = intl.get('deletedThings', {
      qty: successes,
      things: intl.get('rabiesCert.plural')
    });
  } else if (successes === 1) {
    if (history) {
      history.push('/rabies');
    }
    msg = intl.get('deletedThing', { thing: intl.get('rabiesCert') });
  }

  if (msg) {
    message.success(msg);
  }
  yield put(clinicRabiesListReady());
}

export function* onClinicArchiveRabiesRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Rabies.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'rabies'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  if (successes > 0) {
    message.success(
      getMessages(
        successes,
        'archivedThing',
        'archivedThings',
        'rabiesCert',
        'rabiesCert.plural.lowercase'
      )
    );
  }
  yield put(clinicRabiesListReady());
}

export function* onClinicBatchSignRabiesRequest(action) {
  let { readyToSignList, notReadyToSignList, credentials, modal } = action;

  const idsToSign = readyToSignList.map(rabies => rabies.id);
  let signedRabies = [];
  let errorIds = [];

  try {
    const results = yield call(
      Rabies.batchSign,
      idsToSign,
      credentials.username,
      credentials.password,
      credentials.showImages
    );

    idsToSign.forEach(id => {
      const rabiesCerts = results[id];
      if (rabiesCerts && rabiesCerts.status === 200) {
        signedRabies.push({
          id,
          version: rabiesCerts.version,
          serialNumber: rabiesCerts.serialNumber,
          signingDate: rabiesCerts.signingDate
        });
      } else {
        errorIds.push(id);
      }
    });
    yield put(
      clinicBatchSignRabiesSuccess(signedRabies, errorIds, notReadyToSignList)
    );
    clinicBatchSignSuccessMsgs(errorIds, notReadyToSignList, 'Rabies');
    yield put(
      clinicTrackDocumentSigning(
        signedRabies.map(doc => doc.id),
        'rabies'
      )
    );
  } catch (err) {
    console.log(err);
    yield put(
      clinicBatchSignRabiesFailure(readyToSignList, notReadyToSignList, intl)
    );
    clinicBatchSignFailureMsgs(notReadyToSignList, 'Rabies');
    return;
  } finally {
    modal.close();
  }
}

// Ihc
export function* onClinicRefreshIhcListRequest(_action) {
  try {
    yield put(clinicRefreshIhcListStarted());
    const documents = yield all([
      call(Ihc.getDrafts),
      call(Ihc.getReadyToSign),
      call(Ihc.getRecentlySigned)
    ]);
    yield put(clinicRefreshIhcListSuccess(flatten(documents)));
  } catch (err) {
    yield put(apiFailure('Error loading Ihc List data'));
    yield put(clinicRefreshIhcListFailure());
  }
}

export function* onClinicActivateIhc(action) {
  const { id } = action;

  let doc = yield select(
    state => state.accounts.clinic.dataLists.ihc.data.documents[id]
  );
  if (id && !doc) {
    yield put(clinicRefreshIhcRequest(id));
  }
}

export function* onClinicRefreshIhcRequest(action) {
  const { id } = action;
  try {
    yield put(clinicRefreshIhcStarted());
    const doc = yield call(Ihc.read, id);
    yield put(clinicRefreshIhcSuccess({ [doc.id]: doc }));
  } catch (err) {
    yield put(apiFailure('Error loading ihc data'));
    yield put(clinicRefreshIhcFailure());
  }
}

export function* onClinicSaveIhcRequest(action) {
  let { doc, history, successMsg, onSuccess } = action;

  try {
    yield apply(doc, doc.save);
    yield put(clinicRefreshIhcSuccess({ [doc.id]: doc }));
    if (successMsg) {
      message.success(successMsg);
    }
    if (history && history.location.pathname !== `/ihcs/${doc.id}/edit`) {
      history.push(`/ihcs/${doc.id}/edit`);
    }
    if (onSuccess) onSuccess(doc);
  } catch (err) {
    console.warn(err);
    notification.warning({
      message: intl.get('error.saving.noun', {
        noun: intl.get('ihc.lc')
      }),
      description: intl.get('server.error')
    });
    return;
  }
}

export function* onClinicSignIhcRequest(action) {
  let { doc, credentials, modal, history } = action;
  let updatedDoc;

  try {
    updatedDoc = yield call(
      Ihc.sign,
      doc.id,
      credentials.username,
      credentials.password,
      credentials.showImages
    );
  } catch (err) {
    yield put(clinicSignIhcFailure(err, doc, history));
  } finally {
    if (updatedDoc) {
      if (updatedDoc.metadata.status !== 'SIGNED') {
        yield put(
          clinicSignIhcFailure(doc.error || updatedDoc.error, doc, history)
        );
      } else {
        if (history) {
          yield call(history.push, `/ihcs/${updatedDoc.id}/show`);
        }
        yield put(clinicSignIhcSuccess(updatedDoc));
        message.success(intl.get('signedThing', { thing: intl.get('ihc') }));
        yield put(clinicRefreshIhcSuccess({ [updatedDoc.id]: updatedDoc }));
        yield put(clinicTrackDocumentSigning(updatedDoc.id, 'ihc'));
      }
    }
    yield call(modal.close);
  }
}

export function* onClinicSignIhcFailure(action) {
  const { error, doc, history } = action;
  yield put(apiFailure(error));
  // Even though the commit failed, the save may have updated the doc, so
  // if we have an id, we should use the success action to update the
  // document in the redux store.
  yield put(
    doc.id
      ? clinicRefreshIhcSuccess({ [doc.id]: doc })
      : clinicRefreshIhcFailure()
  );
  if (
    history &&
    doc.id &&
    history.location.pathname !== `/ihcs/${doc.id}/edit`
  ) {
    yield call(history.push, `/ihcs/${doc.id}/edit`);
  }
}

export function* onClinicDeleteIhcRequest(action) {
  const { ids, history } = action;
  let successes = 0;
  // TODO: It would be lovely to use this sort of syntax instead of a for loop
  // const responses = yield* ids.map(id =>
  //   call(Ihc.deleteRec, id);
  // );

  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Ihc.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'ihc'));
      successes++;
    } catch (err) {
      // TODO: Something like this for better error message handling?
      // if (err.isAxiosError) {
      //   errors.add(err.response.data.errors[0].message);
      // } else {
      //   errors.add(err.message);
      // }
      yield put(apiFailure());
    }
  }

  let msg;
  if (successes > 1) {
    msg = intl.get('deletedThings', {
      qty: successes,
      things: intl.get('ihc.plural')
    });
  } else if (successes === 1) {
    if (history) {
      history.push('/ihcs');
    }
    msg = intl.get('deletedThing', { thing: intl.get('ihc') });
  }

  if (msg) {
    message.success(msg);
  }
  yield put(clinicIhcListReady());
}

export function* onClinicCreateCviFromIhcRequest({
  form,
  doc,
  history,
  cviWorkflow
}) {
  const {
    airline,
    carrierType,
    inspectionDate,
    purposeOfMovement,
    travelDate
  } = form;
  const {
    destination,
    destinationPremises,
    origin,
    originPremises,
    owner,
    ownerPremises,
    carrier,
    carrierPremises,
    id
  } = doc;
  let msg;
  if (id) {
    try {
      yield call(Ihc.deleteRec, id);
      yield put(clinicRemoveDocSuccess(id, 'ihc'));
      msg = intl.get('deletedThing', { thing: intl.get('ihc') });
    } catch (err) {
      yield put(apiFailure());
    }
  }

  history.push(`/cvis/new?workflow=${cviWorkflow}&animal=${form.animal.id}`, {
    ihcFormData: {
      airline: airline,
      carrier: { id: carrier?.id },
      carrierType: carrierType,
      carrierPremises: { id: carrierPremises?.id },
      destination: { id: destination?.id },
      destinationPremises: { id: destinationPremises?.id },
      // destinationCountry: destinationCountry,
      // flightNumber: flightNumber,
      inspectionDate: inspectionDate?.toDate().toUTCString(),
      origin: { id: origin?.id },
      originPremises: { id: originPremises?.id },
      owner: { id: owner?.id },
      ownerPremises: { id: ownerPremises?.id },
      purposeOfMovement: purposeOfMovement,
      travelDate: travelDate?.toDate().toUTCString()
    }
  });

  if (msg) {
    message.success(msg);
  }
}

export function* onClinicArchiveIhcRequest(action) {
  const { ids } = action;
  let successes = 0;
  for (var i = 0; i < ids.length; i++) {
    try {
      let id = ids[i];
      yield call(Ihc.archive, id);
      yield put(clinicRemoveDocSuccess(id, 'ihc'));
      successes++;
    } catch (err) {
      yield put(apiFailure());
    }
  }
  let msg;
  if (successes > 1) {
    msg = intl.get('archivedThings', {
      qty: successes,
      things: intl.get('ihc.plural.lc')
    });
  } else if (successes === 1) {
    msg = intl.get('archivedThing', {
      thing: intl.get('ihc')
    });
  }

  if (msg) {
    message.success(msg);
  }
  yield put(clinicIhcListReady());
}

export function* onClinicBatchSignIhcRequest(action) {
  let { readyToSignList, notReadyToSignList, credentials, modal } = action;
  let idsToSign = [];
  let errorIds = [];

  const isLicenseValidationEnabled = yield select(
    isLicenseValidationFeatureEnabled
  );

  if (isLicenseValidationEnabled) {
    let nvapLicense = yield select(selectNvapLicense);
    let nvapAuthorizedStates = '';
    if (nvapLicense?.validationStatus === 'ACCREDITED') {
      nvapAuthorizedStates = nvapLicense.authorizedStates;
    }
    readyToSignList.forEach(doc => {
      if (
        nvapLicense?.validationStatus === 'ACCREDITED' &&
        !nvapAuthorizedStates.includes(doc.originPremises.state)
      ) {
        errorIds.push(doc.id);
      } else {
        idsToSign.push(doc.id);
      }
    });
  } else {
    readyToSignList.forEach(doc => {
      idsToSign.push(doc.id);
    });
  }

  let signedIhcs = [];

  try {
    const results = yield call(
      Ihc.batchSign,
      idsToSign,
      credentials.username,
      credentials.password,
      credentials.showImages
    );

    idsToSign.forEach(id => {
      const ihcs = results[id];
      if (ihcs && ihcs.status === 200) {
        signedIhcs.push({
          id,
          version: ihcs.version,
          serialNumber: ihcs.serialNumber,
          signingDate: ihcs.signingDate
        });
      } else {
        errorIds.push(id);
      }
    });
    yield put(
      clinicBatchSignIhcSuccess(signedIhcs, errorIds, notReadyToSignList)
    );
    clinicBatchSignSuccessMsgs(errorIds, notReadyToSignList, 'IHC');
    yield put(
      clinicTrackDocumentSigning(
        signedIhcs.map(doc => doc.id),
        'ihc'
      )
    );
  } catch (err) {
    console.log(err);
    yield put(clinicBatchSignIhcFailure(readyToSignList, notReadyToSignList));
    clinicBatchSignFailureMsgs(notReadyToSignList, 'IHC');
    return;
  } finally {
    modal.close();
  }
}

//Animal
export function* onClinicRefreshAnimalListRequest(action) {
  const { cb } = action;
  try {
    yield put(clinicRefreshAnimalListStarted());
    const data = yield call(Contact.list);
    yield put(clinicRefreshContactListSuccess(data));
    const [animals, oldAnimals, partialAnimals] = yield all([
      call(Animal.list),
      call(Animal.list, { isOld: true }),
      call(Animal.getHorseSyncAnimals)
    ]);
    yield put(
      clinicRefreshAnimalListSuccess({
        animals: [...animals, ...oldAnimals],
        partialAnimals
      })
    );
    if (cb) cb();
  } catch (err) {
    // None of the list API calls should fail, so show the general API Failure messagev
    yield put(apiFailure('Error loading Animal List data'));
    yield put(clinicRefreshAnimalListFailure());
  }
}

export function* onClinicVerboseAnimalListRequest(action) {
  const { cb, params } = action;
  let fullAnimals = [];
  try {
    yield put(clinicRefreshAnimalListStarted());
    fullAnimals = (yield call(Animal.list, params)).results;
    yield put(
      clinicVerboseAnimalListSuccess({
        fullAnimals
      })
    );
  } catch (err) {
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading detailed Animal List data'));
    yield put(clinicRefreshAnimalListFailure());
  }
  if (typeof cb === 'function') cb(fullAnimals);
}

export function* onClinicRefreshAnimalRequest(action) {
  const { animalId } = action;
  try {
    // yield put(clinicRefreshAnimalStarted());
    const animal = yield call(Animal.read, animalId);
    yield put(clinicRefreshAnimalSuccess(animal));
  } catch (err) {
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Animal data'));
    // yield put(clinicRefreshAnimalFailure(animalId));
  }
}

export function* onClinicDeleteAnimalRequest(action) {
  const { animalIds, isPartial, showNotification } = action;
  yield put(clinicDeleteAnimalStarted());
  try {
    for (var i = 0; i < animalIds.length; i++) {
      let id = animalIds[i];
      yield Animal.deleteRec(id, isPartial);
      yield put(clinicAnimalRemovedFromList(id, isPartial));
    }
    if (showNotification) {
      message.success(
        intl.get(
          animalIds.length > 1
            ? 'animal.animalsDeleted'
            : 'animal.animalDeleted'
        )
      );
    }
    yield put(clinicAnimalListReady());
  } catch (err) {
    yield put(apiFailure());
    message.error(
      intl.get('error.deleting.properNoun', {
        properNoun: intl.get('animal')
      })
    );
  }
}

export function* onClinicAnimalClaimOwnershipRequest(action) {
  const animal = new Animal(action.animal);
  try {
    yield apply(animal, animal.claimOwnership, [action.ownerId]);
    yield put(clinicAnimalUpdatedSuccess(animal));
    message.success(intl.get('ownership.assigned'));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onClinicAnimalReleaseOwnershipRequest(action) {
  const animal = new Animal(action.animal);
  try {
    yield apply(animal, animal.releaseOwnership);
    yield put(clinicAnimalUpdatedSuccess(animal));
    message.success(intl.get('ownership.removed'));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

//Image
export function* onClinicRefreshImageListRequest(action) {
  try {
    yield put(clinicRefreshImageListStarted());
    const images = yield call(Image.getImageData);
    yield put(
      clinicRefreshImageListSuccess({
        images
      })
    );
  } catch (err) {
    console.log(err);
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Image List data'));
    yield put(clinicRefreshImageListFailure());
  }
}

//Requests
export function* onClinicRejectDocumentRequest(action) {
  const { docType, id, reason } = action;
  try {
    yield call(Request.reject, id, reason);
    yield put(clinicRequestRemovedFromList(docType, id));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error rejecting request'));
  }
}

export function* onClinicImageUpdateRequest(action) {
  let { id, description, tags, label, base64 } = action;

  try {
    const images = yield Image.updateImageRecord(id, description, tags, label);
    images.base64 = base64;
    yield put(
      clinicImageUpdateSuccess({
        images
      })
    );
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error updating Image data'));
    yield put(clinicRefreshImageListFailure());
  }
}

export function* onClinicImageDeleteRequest(action) {
  let { id, forceDelete } = action;
  try {
    yield Image.deleteRec(id, forceDelete);
    yield put(clinicImageDeleteSuccess(id));
    message.success(intl.get('deletedThing', { thing: intl.get('image') }));
  } catch (e) {
    console.log(e);
    yield put(apiFailure('Error deleting Image data'));
    message.error(
      intl.get('error.deleting.properNoun', {
        properNoun: intl.get('image')
      })
    );
  }
}

//Document models
export function* onClinicFetchCarriersRequest(action) {
  try {
    const carriers = yield call(Contact.list, {
      carrierClass: action.carrierClass
    });
    yield put(clinicFetchCarriersSuccess(carriers));
  } catch (err) {
    yield put(apiFailure(err));
    yield put(clinicFetchCarriersFailure());
  }
}

export function* onClinicSEVerifyPartialDocRequest(action) {
  const { partialDoc } = action;
  try {
    const result = yield apply(partialDoc, partialDoc.verifyPartial);
    yield put(clinicSEVerifyPartialDocSuccess(result));
  } catch (err) {
    yield put(clinicSEVerifyPartialDocFailure());
  }
}

//Home
export function* onClinicRefreshHomeListRequest(action) {
  try {
    yield put(clinicRefreshHomeListStarted());
    const home = yield RecentActivity.getHomeData(action.params);
    yield put(clinicRefreshHomeListSuccess(home));
  } catch (err) {
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure('Error loading Home List data'));
    yield put(clinicRefreshHomeListFailure());
  }
}

// Analytics
export function* onClinicTrackDocumentSigning(action) {
  const { ids, docType } = action;
  if (Array.isArray(ids)) {
    // handle batch sign tracking
    let docs = [];
    for (let id of ids) {
      docs.push(yield select(documentSelector, docType, id));
    }
    if (docs.length > 0) {
      yield call(trackBatch, docs);
    }
  } else {
    // handle single sign
    const doc = yield select(documentSelector, docType, ids);
    yield call(trackSign, doc);
  }
}

//Generic Document
export function* onClinicVoidDocumentRequest({ serialNumber, reason }) {
  try {
    const voidedDocument = yield call(
      Document.voidDocument,
      serialNumber,
      reason
    );
    yield put(clinicVoidDocumentSuccess(voidedDocument));
  } catch (err) {
    if (err && err.response) {
      if (err.response.status === 404) {
        yield put(
          clinicVoidDocumentFailure(
            null,
            intl.get('certificateNotFound.helpText', {
              certNumber: serialNumber
            }),
            intl.get('certificateNotFound'),
            intl
          )
        );
        return;
      } else {
        if (err.response.data && err.response.data.errors) {
          yield put(
            clinicVoidDocumentFailure(
              null,
              err.response.data.errors[0].message,
              null,
              intl
            )
          );
          return;
        } else {
          if (err.response.data && err.response.data.errorMessage) {
            yield put(
              clinicVoidDocumentFailure(
                null,
                err.response.data.errorMessage,
                null
              )
            );
            return;
          }
        }
      }
    }

    // Unhandled API error
    console.log(err);
    yield put(clinicVoidDocumentFailure('server.error', null, null));
  }
}

export function* onClinicVoidDocumentSuccess(action) {
  const {
    voidedDocument: { id, type }
  } = action;
  yield put(clinicRemoveDocSuccess(id, type.toLowerCase()));
  message.success(intl.get('certificateVoided'));
}

// TODO: no need for saga generator this should be wrapped into the triggering function.
export function* onClinicVoidDocumentFailure(action) {
  const { msgId, specificDescription, specificMessage } = action;
  yield call(notification.error, {
    message: specificMessage || intl.get('voidCertificateError'),
    description: specificDescription || intl.get(msgId)
  });
}

// Certificates Search

const generateCsvLayout = () => {
  return [
    {
      heading: intl.get('serialNumber'),
      property: 'certNumber'
    },
    {
      heading: intl.get('type'),
      property: 'type',
      dataFormatFunction: type => convertCertTypeForDisplay(type)
    },
    { heading: intl.get('owner'), property: 'owner_name' },
    {
      heading: intl.get('animal.species'),
      property: 'species'
    },
    {
      heading: intl.get('animals'),
      property: 'animal_names',
      dataFormatFunction: animalNames => animalNames.join(', ')
    },
    {
      heading: intl.get('billingDate'),
      property: 'cert_date',
      dataFormatFunction: date => date.substring(0, 10)
    },
    { heading: intl.get('signingVet'), property: 'vet_name' }
  ];
};

export function* onClinicCertificatesSearchRequest(action) {
  const { searchType, params } = action;

  try {
    // TODO: Normalize the backend so this uses the Documents API
    const results = yield call(api.get, 'cert/search', { params });

    let voidCount = 0;
    if (searchType === 'report') {
      voidCount = (yield call(api.get, 'cert/voidCount', { params })).count;
    }

    if (searchType === 'export') {
      if (results.length > 0) {
        const csvLayout = generateCsvLayout();
        let fileName = `CertficateArchiveExport_${formatDate(new Date())}.csv`;
        exportCsv(csvLayout, results, fileName);
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    }

    yield put(clinicCertificatesSearchSuccess(results, voidCount));
  } catch (err) {
    yield put(apiFailure(err));
    yield put(clinicCertificatesSearchFailure());
  }
}

export function* onClinicCreateDocumentFromCertificateRequest(action) {
  const { certificate } = action;

  const documentModel = yield call(
    getDocumentModelForCertificateType,
    certificate.type
  );
  if (!documentModel) {
    yield put(
      clinicCreateDocumentFromCertificateFailure(
        intl.get('notAllowedForCertificateTypeProperNoun', {
          properNoun: certificate.type
        })
      )
    );
    return;
  }

  let originalDocument;
  try {
    originalDocument = yield call(documentModel.read, certificate.id);
  } catch (error) {
    // 404 is the only typical error we should expect
    if (error && error.response && error.response.status === 404) {
      yield put(
        clinicCreateDocumentFromCertificateFailure(
          intl.get('originalDocumentNotFound')
        )
      );
      return;
    } else {
      console.error(error);
      yield put(
        clinicCreateDocumentFromCertificateFailure(
          intl.get('errorRetrievingOriginalDocument')
        )
      );
      return;
    }
  }

  // Construct draft
  const draftDocument = yield call(createDraftFromDocument, originalDocument);
  const newDocument = yield call(() => new documentModel(draftDocument));

  // Not doing a newDocument.validate() here since the user has no way to correct validation errors,
  // and we should be creating a saveable draft. The backend save will do the final validation.

  // Save it
  try {
    yield apply(newDocument, newDocument.save, [true]); // TODO: Remove the true parameter once the Eia model save method no longer has the UI logic in it.
    yield call(trackEvent, 'document', 'archive', 'quick-draft', {
      fromId: originalDocument.id,
      draftId: newDocument.id,
      docType: newDocument.type
    });
    yield put(clinicCreateDocumentFromCertificateSuccess(newDocument));
  } catch (error) {
    const errorDescription = yield call(
      api.extractApiErrorDescriptionFromResponse,
      error
    );
    if (errorDescription) {
      yield put(clinicCreateDocumentFromCertificateFailure(errorDescription));
    } else {
      console.log(error);
      yield put(
        clinicCreateDocumentFromCertificateFailure(
          intl.get('errorCreatingNewDocument')
        )
      );
    }
  }
}

// TODO: no need for saga generator this should be wrapped into the triggering function.
export function* onClinicCreateDocumentFromCertificateFailure(action) {
  const { description } = action;

  yield call(notification.error, {
    message: intl.get('unableToCreateDraftCopy'),
    description
  });
}

export default function* accountsClinicSagas() {
  yield takeLatest(
    CLINIC_LOAD_STARTUP_DATA_REQUEST,
    onClinicLoadStartupDataRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_CVI_LIST_REQUEST,
    onClinicRefreshCviListRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_RABIES_LIST_REQUEST,
    onClinicRefreshRabiesListRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_IHC_LIST_REQUEST,
    onClinicRefreshIhcListRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_EIA_LIST_REQUEST,
    onClinicRefreshEiaListRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_CONTACT_REQUEST,
    onClinicRefreshContactRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_CONTACT_LIST_REQUEST,
    onClinicRefreshContactListRequest
  );

  yield takeLatest(
    CLINIC_REFRESH_VFDS_LIST_REQUEST,
    onClinicRefreshVfdsListRequest
  );

  // eia
  yield takeLatest(CLINIC_ACTIVATE_EIA, onClinicActivateEia);
  yield takeLatest(CLINIC_REFRESH_EIA_REQUEST, onClinicRefreshEiaRequest);
  yield takeEvery(CLINIC_SAVE_EIA_REQUEST, onClinicSaveEiaRequest);
  yield takeLatest(CLINIC_SIGN_EIA_REQUEST, onClinicSignEiaRequest);
  yield takeLatest(CLINIC_BATCH_SIGN_EIA_REQUEST, onClinicBatchSignEiaRequest);
  yield takeLatest(
    CLINIC_CREATE_CVI_FROM_EIA_REQUEST,
    onClinicCreateCviFromEiaRequest
  );
  yield takeLatest(
    CLINIC_CREATE_CVI_FROM_EIA_FAILURE,
    onClinicCreateCviFromEiaFailure
  );
  yield takeEvery(CLINIC_PROCESS_EIAS_REQUEST, onClinicProcessEiasRequest);
  yield takeLatest(CLINIC_ARCHIVE_EIAS_REQUEST, onClinicArchiveEiasRequest);
  yield takeLatest(CLINIC_DELETE_EIAS_REQUEST, onClinicDeleteEiasRequest);
  yield takeLatest(
    CLINIC_AUTO_ARCHIVE_EIA_REQUEST,
    onClinicAutoArchiveEiaRequest
  );
  yield takeLatest(
    CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST,
    onClinicAddRequiredEiaCountyRequest
  );

  // cvi
  yield takeLatest(CLINIC_ACTIVATE_CVI, onClinicActivateCvi);
  yield takeLatest(CLINIC_REFRESH_CVI_REQUEST, onClinicRefreshCviRequest);
  yield takeLatest(CLINIC_SAVE_CVI_REQUEST, onClinicSaveCviRequest);
  yield takeLatest(CLINIC_CVI_ADD_PERMIT_REQUEST, onClinicCviAddPermitRequest);
  yield takeLatest(CLINIC_SIGN_CVI_REQUEST, onClinicSignCviRequest);
  yield takeLatest(CLINIC_BATCH_SIGN_CVI_REQUEST, onClinicBatchSignCviRequest);
  yield takeLatest(CLINIC_ARCHIVE_CVIS_REQUEST, onClinicArchiveCvisRequest);
  yield takeLatest(CLINIC_DELETE_CVIS_REQUEST, onClinicDeleteCvisRequest);
  yield takeLatest(CLINIC_DELETE_CVI_REQUEST, onClinicDeleteCviRequest);

  // eecvis
  yield takeLatest(
    CLINIC_REFRESH_EECVI_LIST_REQUEST,
    onClinicRefreshEecviListRequest
  );
  yield takeLatest(
    CLINIC_BATCH_COMMIT_EECVI_REQUEST,
    onClinicBatchCommitEecviRequest
  );
  yield takeLatest(CLINIC_SAVE_EECVI_REQUEST, onClinicSaveEecviRequest);
  yield takeLatest(CLINIC_COMMIT_EECVI_REQUEST, onClinicCommitEecviRequest);
  yield takeLatest(CLINIC_COMMIT_EECVI_FAILURE, onClinicCommitEecviFailure);
  yield takeLatest(CLINIC_ARCHIVE_EECVIS_REQUEST, clinicArchiveEecvisRequest);
  yield takeLatest(CLINIC_DELETE_EECVIS_REQUEST, clinicDeleteEecvisRequest);
  yield takeLatest(CLINIC_ACTIVATE_EECVI, onClinicActivateEecvi);
  yield takeLatest(CLINIC_REFRESH_EECVI_REQUEST, onClinicRefreshEecviRequest);

  // vfd
  yield takeLatest(CLINIC_ARCHIVE_VFDS_REQUEST, onClinicArchiveVfdsRequest);
  yield takeLatest(CLINIC_DELETE_VFDS_REQUEST, onClinicDeleteVfdsRequest);
  yield takeLatest(CLINIC_ACTIVATE_VFD, onClinicActivateVfd);
  yield takeLatest(CLINIC_REFRESH_VFD_REQUEST, onClinicRefreshVfdRequest);
  yield takeEvery(CLINIC_SAVE_VFD_REQUEST, onClinicSaveVfdRequest);
  yield takeEvery(
    CLINIC_PROCESS_VFDS_RENEWAL_REJECT,
    onClinicProcessVfdsRenewalReject
  );
  yield takeEvery(
    CLINIC_PROCESS_VFD_RENEWAL_REVIEW,
    onClinicProcessVfdRenewalReview
  );
  yield takeEvery(
    CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT,
    onClinicProcessVfdsRenewalAccept
  );
  yield takeEvery(CLINIC_SIGN_VFD_REQUEST, onClinicSignVfdRequest);
  yield takeEvery(CLINIC_PROCESS_VFDS_REQUEST, onClinicProcessVfdsRequest);

  // Rabies
  yield takeLatest(CLINIC_ACTIVATE_RABIES, onClinicActivateRabies);
  yield takeLatest(CLINIC_REFRESH_RABIES_REQUEST, onClinicRefreshRabiesRequest);
  yield takeLatest(CLINIC_SAVE_RABIES_REQUEST, onClinicSaveRabiesRequest);
  yield takeLatest(CLINIC_SIGN_RABIES_REQUEST, onClinicSignRabiesRequest);
  yield takeLatest(CLINIC_SIGN_RABIES_FAILURE, onClinicSignRabiesFailure);
  yield takeEvery(CLINIC_DELETE_RABIES_REQUEST, onClinicDeleteRabiesRequest);
  yield takeLatest(CLINIC_ARCHIVE_RABIES_REQUEST, onClinicArchiveRabiesRequest);
  yield takeLatest(
    CLINIC_BATCH_SIGN_RABIES_REQUEST,
    onClinicBatchSignRabiesRequest
  );
  // Ihc
  yield takeLatest(CLINIC_ACTIVATE_IHC, onClinicActivateIhc);
  yield takeLatest(CLINIC_REFRESH_IHC_REQUEST, onClinicRefreshIhcRequest);
  yield takeLatest(CLINIC_SAVE_IHC_REQUEST, onClinicSaveIhcRequest);
  yield takeLatest(CLINIC_SIGN_IHC_REQUEST, onClinicSignIhcRequest);
  yield takeLatest(CLINIC_SIGN_IHC_FAILURE, onClinicSignIhcFailure);
  yield takeEvery(CLINIC_DELETE_IHC_REQUEST, onClinicDeleteIhcRequest);
  yield takeEvery(
    CLINIC_CREATE_CVI_FROM_IHC_REQUEST,
    onClinicCreateCviFromIhcRequest
  );
  yield takeLatest(CLINIC_ARCHIVE_IHC_REQUEST, onClinicArchiveIhcRequest);
  yield takeLatest(CLINIC_BATCH_SIGN_IHC_REQUEST, onClinicBatchSignIhcRequest);

  //Animal
  yield takeLatest(
    CLINIC_REFRESH_ANIMAL_LIST_REQUEST,
    onClinicRefreshAnimalListRequest
  );
  yield takeLatest(
    CLINIC_VERBOSE_ANIMAL_LIST_REQUEST,
    onClinicVerboseAnimalListRequest
  );
  yield takeLatest(CLINIC_REFRESH_ANIMAL_REQUEST, onClinicRefreshAnimalRequest);
  yield takeLatest(CLINIC_DELETE_ANIMAL_REQUEST, onClinicDeleteAnimalRequest);
  yield takeEvery(
    CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST,
    onClinicAnimalClaimOwnershipRequest
  );
  yield takeEvery(
    CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST,
    onClinicAnimalReleaseOwnershipRequest
  );
  //Image
  yield takeLatest(
    CLINIC_REFRESH_IMAGE_LIST_REQUEST,
    onClinicRefreshImageListRequest
  );
  yield takeLatest(CLINIC_IMAGE_UPDATE_REQUEST, onClinicImageUpdateRequest);
  yield takeLatest(CLINIC_IMAGE_DELETE_REQUEST, onClinicImageDeleteRequest);
  //Document models
  yield takeLatest(CLINIC_FETCH_CARRIERS_REQUEST, onClinicFetchCarriersRequest);
  yield takeLatest(
    CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST,
    onClinicSEVerifyPartialDocRequest
  );
  //Requests
  yield takeLatest(
    CLINIC_REJECT_DOCUMENT_REQUEST,
    onClinicRejectDocumentRequest
  );
  //Contact
  yield takeEvery(CLINIC_GET_CONTACT_REQUEST, onClinicGetContactRequest);
  yield takeEvery(CLINIC_GET_PREMISES_REQUEST, onClinicGetPremisesRequest);
  yield takeLatest(CLINIC_DELETE_CONTACT_REQUEST, onClinicDeleteContactRequest);
  yield takeEvery(CLINIC_MVL_GRANT_ACCESS, onClinicMvlGrantAccess);
  yield takeEvery(CLINIC_MVL_REVOKE_ACCESS, onClinicMvlRevokeAccess);
  //Home
  yield takeLatest(
    CLINIC_REFRESH_HOME_LIST_REQUEST,
    onClinicRefreshHomeListRequest
  );
  // Analytics
  yield takeEvery(CLINIC_TRACK_DOCUMENT_SIGNING, onClinicTrackDocumentSigning);
  //Generic Document
  yield takeLatest(CLINIC_VOID_DOCUMENT_REQUEST, onClinicVoidDocumentRequest);
  yield takeLatest(CLINIC_VOID_DOCUMENT_SUCCESS, onClinicVoidDocumentSuccess);
  yield takeLatest(CLINIC_VOID_DOCUMENT_FAILURE, onClinicVoidDocumentFailure);
  yield takeLatest(CLINIC_LOCK_DOC_REQUEST, onClinicLockDocRequest);
  yield takeLatest(CLINIC_UNLOCK_DOC_REQUEST, onClinicUnlockDocRequest);
  yield takeLatest(CLINIC_COPY_DOC_REQUEST, onClinicCopyDocRequest);
  // Certificates Search
  yield takeLatest(
    CLINIC_CERTIFICATES_SEARCH_REQUEST,
    onClinicCertificatesSearchRequest
  );
  // Create Document from Certificate
  yield takeLatest(
    CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST,
    onClinicCreateDocumentFromCertificateRequest
  );
  yield takeLatest(
    CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE,
    onClinicCreateDocumentFromCertificateFailure
  );
}
