import Model from 'models/Model';
import { maxLength, stringRequired } from 'utils/validation/common';

export default class Note extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      noteId: {
        initialValue: undefined
      },
      referenceId: {
        initialValue: undefined
      },
      longReferenceId: {
        initialValue: undefined
      },
      createdBy: {
        initialValue: undefined
      },
      lastEditedBy: {
        initialValue: undefined
      },
      category: {
        initialValue: '',
        validate: value => maxLength(value, 20)
      },
      note: {
        initialValue: '',
        validate: value => stringRequired(value, 'required')
      },
      editable: {
        initialValue: true
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'records/note';
  }

  static get domain() {
    return 'Note';
  }

  static get idFieldName() {
    return 'noteId';
  }

  static list = async params => await super.list(params);
}
