import Model from 'models/Model';
import { stringRequired, maxLength, isEmail } from 'utils/validation/common';
import Location from 'models/Location';
import * as api from 'utils/api';
import AppUser from 'modules/User/AppUser';

export default class Account extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();

    // Put complex validations here.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      uuid: {
        initialValue: undefined
      },
      crmAccountId: {
        initialValue: undefined
      },
      arAccountId: {
        initialValue: undefined
      },
      billingId: {
        initialValue: null
      },
      name: {
        initialValue: '',
        validate: name =>
          stringRequired(name, 'required') || maxLength(name, 100)
      },
      email: {
        initialValue: undefined,
        validate: value => {
          return !value
            ? null
            : isEmail(value, 'validation.email.invalid') ||
                maxLength(value, 120);
        }
      },

      webSite: {
        initialValue: ''
      },
      type: {
        initialValue: 'Generic'
      },
      locationAddress: {
        initialValue: {},
        validate: value => {
          return new Location(value).validate();
        }
      },
      billingAddress: {
        initialValue: {},
        validate: value => {
          return new Location(value).validate();
        }
      },
      sameAsLocation: {
        initialValue: false
      },
      enabled: {
        initialValue: true
      },
      ratePlanCode: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      subscriptions: {
        initialValue: {}
      },
      settings: {
        initialValue: []
      },
      pharmaDrugs: {
        initialValue: []
      },
      users: {
        initialValue: []
      },
      parent: {
        initialValue: null
      },
      childAccounts: {
        initialValue: []
      },
      userAgreeVersion: {
        initialValue: null
      },
      userAgreeLabel: {
        initialValue: null
      },
      userAgree: {
        initialValue: null
      },
      userAgreeDate: {
        initialValue: undefined
      },
      arAccountSummary: {
        initialValue: {}
      },
      accountTypeSettings: {
        initialValue: {}
      },
      // from account.arAccountSummary call
      autoPay: {
        initialValue: false
      },
      billingEmail: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 120);
        }
      },
      paymentMethodId: {
        initialValue: undefined
      }
    };
  }

  getSetting(name) {
    let setting = this.settings.find(setting => setting.name === name);
    return setting ? setting.value : undefined;
  }

  static get apiPath() {
    return 'accounts';
  }

  static get domain() {
    return 'Account';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };

  static createArAccount = async id => {
    return await api.post(`${Account.apiPath}/${id}/createArAccount`);
  };

  static updateUser = async (accountId, userId, params) => {
    return await api.put(
      `${Account.apiPath}/${accountId}/users/${userId}`,
      params
    );
  };

  static disableUser = async (accountId, userId) => {
    return await api.put(
      `${Account.apiPath}/${accountId}/users/${userId}/disable`
    );
  };

  // instance methods

  fetchAdditionalData = async () => {
    let apiCalls = [this.getUsers()];
    if (this.arAccountId) {
      apiCalls.push(this.getArAccountSummary());
    }
    await Promise.all(apiCalls);
  };

  getUsers = async () => {
    const results = await api.get(`${Account.apiPath}/${this.id}/users`);
    if (results) {
      const instanceResults = results.map(result => new AppUser(result));
      this.users = instanceResults;
    }
  };

  getPaymentMethods = async () => {
    return await api.get(`${Account.apiPath}/${this.id}/paymentMethods`);
  };

  getArAccountSummary = async () => {
    this.arAccountSummary = await api.get(
      `${Account.apiPath}/${this.id}/arAccountSummary`
    );
    // handle transients from arAccountSummary call
    if (this.arAccountSummary.success) {
      this.billingEmail = this.arAccountSummary.billToContact.workEmail;
      this.autoPay = this.arAccountSummary.basicInfo.autoPay;
      if (this.arAccountSummary.basicInfo.defaultPaymentMethod) {
        this.paymentMethodId = this.arAccountSummary.basicInfo.defaultPaymentMethod.id;
      }
    }
  };

  getDefaultPaymentMethod() {
    return this.arAccountSummary?.basicInfo?.defaultPaymentMethod;
  }

  getBillingEmail() {
    return this.arAccountSummary?.billToContact?.workEmail;
  }

  getAdditionalPaymentMethods() {
    // loop over and discard the default payment and also normalize the data so
    // it matches the defaultPaymentMethod json structure. TODO: perhaps
    // handle this at the api level? The issue comes from zuora having different
    // inconsistent api formats depending on the api endpoint.
    return this.arAccountSummary?.paymentMethods
      ?.filter(payment => payment.Id !== this.getDefaultPaymentMethod()?.id)
      ?.map(payment => {
        return {
          id: payment.Id,
          creditCardNumber: payment.CreditCardMaskNumber,
          creditCardType: payment.CreditCardType,
          creditCardExpirationMonth: payment.CreditCardExpirationMonth,
          creditCardExpirationYear: payment.CreditCardExpirationYear,
          paymentMethodType: 'CreditCard'
        };
      });
  }

  getAccountManager() {
    return this.users?.find(
      user => user.rolesList.indexOf('Account Manager') > -1
    );
  }

  getHostedPaymentCredentials = async (style = 'overlay') => {
    let credentials = await api.get(
      `${Account.apiPath}/hostedPaymentCredentials`
    );
    credentials.tenantId = parseInt(credentials.tenantId);
    credentials.style = style;
    credentials.field_accountId = this.arAccountId;
    return credentials;
  };
}
