import intl from 'react-intl-universal';
import * as types from './attachmentActionTypes';
import * as actions from './attachmentActions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as api from 'utils/api';

export function* onListRequest({ referenceType, referenceId }) {
  try {
    yield put(actions.isRefreshing(true));
    let attachments = [];
    if (Number.isInteger(referenceId)) {
      attachments = yield call(api.get, 'attachments', {
        params: { type: referenceType, referenceId: referenceId, limit: 0 }
      });
    } else {
      attachments = yield call(api.get, 'attachments', {
        params: { type: referenceType, referenceUUID: referenceId, limit: 0 }
      });
    }
    yield put(actions.listSuccess(referenceId, attachments.results.reverse()));
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.documents')));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* attachmentSagas() {
  yield takeLatest(types.LIST_REQUEST, onListRequest);
}
