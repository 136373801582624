import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Row, Col } from 'antd';
import GvlCard from 'elements/GvlCard';
import GvlIcon from 'elements/GvlIcon';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';
import ClinicAddress from 'modules/Clinic/components/ClinicAddress';
import {
  NewportClinicShippingAddress,
  NewportClinicBillingAddress
} from 'modules/NewportClinic/newportClinicIndex';

const IsolateClinicCard = ({
  title,
  clinic,
  vets,
  showSharedIcon,
  additionalClinicEmail
}) => {
  // Only show that an isolate is shared. Users are SUPER protective of their isolates
  // and having 'not shared' on their isolates might upset them if they are opposed to sharing
  const additionalHeaderContent = (
    <React.Fragment>
      {showSharedIcon && (
        <div style={{ marginRight: '16px' }}>
          <GvlIcon
            type="check-circle"
            theme="filled"
            style={{ color: '#5CA329' }}
          />{' '}
          {intl.get('isolate.owner')}
        </div>
      )}
    </React.Fragment>
  );

  // The `gvl_id` field is exclusively found on clinics from newport_clinic_refs in the cache
  const clinicAddressSection = clinic.gvl_id ? (
    <Row gutter={8}>
      <Col span={12}>
        <NewportClinicShippingAddress clinic={clinic} showName={true} />
      </Col>
      {clinic?.billingAddress?.address_line_1 && (
        <Col span={12}>
          <NewportClinicBillingAddress clinic={clinic} />
        </Col>
      )}
    </Row>
  ) : (
    <Row gutter={8}>
      <Col span={12}>
        <ClinicAddress clinic={clinic} showName={true} />
      </Col>
    </Row>
  );

  return (
    <GvlCard title={title} additionalHeaderContent={additionalHeaderContent}>
      <div>
        {clinicAddressSection}
        <Row gutter={8}>
          <Col span={12}>
            <IsolateValWithIcon icon="phone" val={clinic.phone} />
            <IsolateValWithIcon icon="printer" val={clinic.fax} />
            <IsolateValWithIcon icon="mail" val={clinic.email} />
            {additionalClinicEmail && (
              <IsolateValWithIcon icon="mail" val={additionalClinicEmail} />
            )}
          </Col>
          {vets && (
            <Col span={12}>
              <IsolateVal
                name={vets.length > 1 ? 'veterinarians' : 'veterinarian'}
                val={vets.map(vet => vet.commonName).join(', ')}
              />
            </Col>
          )}
        </Row>
      </div>
    </GvlCard>
  );
};

IsolateClinicCard.propTypes = {
  title: PropTypes.string.isRequired,
  clinic: PropTypes.object.isRequired,
  vets: PropTypes.array,
  showSharedIcon: PropTypes.bool,
  additionalClinicEmail: PropTypes.string
};

export default IsolateClinicCard;
