import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Col, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import GvlSearch from 'components/GvlSearch/GvlSearch';

export default class ContactLookupComponent extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onCreateNew: PropTypes.func.isRequired
  };

  contactOptionGeneration = results => {
    return results.map(result => (
      <Select.Option key={result.contactId} value={result.contactId}>
        {result.displayName}
      </Select.Option>
    ));
  };

  render() {
    return (
      <Row gutter={8}>
        <Col md={19} sm={16}>
          <GvlSearch
            childGenerator={this.contactOptionGeneration}
            minSearchTermLength={3}
            onSelect={this.props.onSelect}
            optionLabelProp="text"
            dataSource="records/contact/autocomplete"
            dropdownClassName="gvl-select-with-custom-groups"
            emptyMsg="contact.missingWithName"
            emptySubMsg="contact.missing.subText"
          />
        </Col>
        <Col md={5} sm={8}>
          <Button block={true} type="default" onClick={this.props.onCreateNew}>
            {intl.get('create.new')}
          </Button>
        </Col>
      </Row>
    );
  }
}
