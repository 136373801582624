import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GvlCard from 'elements/GvlCard';
import { NewportClinicInlineShow } from 'modules/NewportClinic/newportClinicIndex';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

function NonAdjacentClinicShowCardComponent({ naRequest, currentUser }) {
  return (
    <GvlCard title={intl.get('naRequest.clinic.info')}>
      <div>
        {hasFeatureAccess(
          currentUser,
          FeatureEnum.NonAdjacentManagement,
          'read'
        ) ? (
          <React.Fragment>
            <NewportClinicInlineShow
              clinic={naRequest.clinic}
              vetDisplay="full"
              vet={naRequest.vet}
              primaryEmail={naRequest.clinicContactEmails[0]}
            />
            {naRequest.alternateClinicAddress ? (
              <React.Fragment>
                <br />
                <NewportClinicInlineShow
                  clinic={naRequest.alternateClinicAddress}
                  vetDisplay="none"
                />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <NewportClinicInlineShow
            clinic={naRequest.alternateClinicAddress || naRequest.clinic}
            vetDisplay="full"
            vet={naRequest.vet}
            primaryEmail={naRequest.clinicContactEmails[0]}
          />
        )}
      </div>
    </GvlCard>
  );
}

NonAdjacentClinicShowCardComponent.propTypes = {
  naRequest: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.app.user
  };
};

const NonAdjacentClinicShowCard = connect(
  mapStateToProps,
  null
)(NonAdjacentClinicShowCardComponent);

export {
  NonAdjacentClinicShowCard as default,
  NonAdjacentClinicShowCardComponent
};
