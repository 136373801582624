import { throttle } from 'lodash';
import { getLocalStorage } from 'utils/localStorage';

/*
 * Inspiration from https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
 * and
 * https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
 */

export const loadState = key => {
  try {
    const serializedState = getLocalStorage().getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    //calls to localStorage.getItem can fail if the user privacy mode does not
    // allow the use of localStorage, so just ignore the error
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    getLocalStorage().setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const saveStateThrottled = key =>
  throttle(newState => {
    saveState(key, {
      ...newState
    });
  }, 1000);

/**
 * https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 *
 * Watch for changes to specific state keys, and only save when the keys have been modified
 */
export const observeStore = (store, select, onChange) => {
  let currentState;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};
