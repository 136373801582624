import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';

class GvlDatePicker extends Component {
  static propTypes = {
    value: PropTypes.object
  };

  state = {
    openStatus: false,
    dateValue: null
  };

  handleChange = () => {
    this.setState({ openStatus: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && isMobile) {
      this.setState({ openStatus: false });
    }
  }

  render() {
    const mobileProps = {
      open: this.state.openStatus,
      onOpenChange: status => {
        if (status) {
          this.setState({ openStatus: true });
        }
      },
      allowClear: false,
      onMouseLeave: this.handleChange
    };
    return (
      <DatePicker
        className="full-width"
        format="l"
        placeholder={moment.localeData().longDateFormat('L')}
        {...this.props}
        {...(isMobile ? mobileProps : {})}
      />
    );
  }
}

export { GvlDatePicker as default };
