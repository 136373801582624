import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { ProductSearch, ProductInlineShow } from 'modules/Product/productIndex';
import { refreshRequest as productRefreshRequest } from 'modules/Product/store/productActions';
import { refreshIsolatesForProduct } from 'modules/Isolate/store/isolateActions';

class ProductSearchFormItemComponent extends Component {
  static propTypes = {
    productRefreshRequest: PropTypes.func.isRequired,
    refreshIsolatesForProduct: PropTypes.func.isRequired,
    refreshingProduct: PropTypes.bool.isRequired,
    value: PropTypes.object,
    validateProductCallback: PropTypes.func,
    removable: PropTypes.bool,
    fullRefreshOnSelect: PropTypes.bool,
    onChange: PropTypes.func,
    currentUser: PropTypes.object.isRequired
  };

  static defaultProps = {
    removable: true,
    fullRefreshOnSelect: false
  };

  /* When a product is selected from the autocomplete we have just basic product information (productId, name, clinicName)
   * but only bubble up the selected productId from product search.
   * When fullRefreshOnSelect is true, this function will trigger a get call on the product and a get call
   * for the isolates on the product so we have the most recent versions of all and the data needed to determine
   * species and display isolate tables on NonAdjacentEdit.jsx
   * Other implementations may find use in just having the productId. */
  onSelectProduct = productId => {
    if (this.props.fullRefreshOnSelect) {
      this.props.productRefreshRequest(productId, this.productRefreshCallback);
    } else {
      this.props.onChange(productId);
    }
  };

  /* Callback to perform after product is retrieved. Calls onChange with the full product
   * and grabs the most recent versions of the isolates in a product. */
  productRefreshCallback = product => {
    this.props.onChange(product);
    this.props.refreshIsolatesForProduct(
      product.productId,
      this.props.validateProductCallback
    );
  };

  render() {
    const {
      value,
      onChange,
      removable,
      refreshingProduct,
      currentUser
    } = this.props;

    return value?.productId ? (
      <ProductInlineShow
        product={value}
        onRemove={() => onChange({})}
        removable={removable}
        currentUser={currentUser}
      />
    ) : (
      // Spin shows when product selected and refreshProductSaga running
      <Spin spinning={refreshingProduct}>
        <ProductSearch onSelect={this.onSelectProduct} />
      </Spin>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  refreshingProduct: accounts.products.isRefreshing
});

const mapDispatchToProps = {
  productRefreshRequest,
  refreshIsolatesForProduct
};

const ProductSearchFormItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSearchFormItemComponent);

export { ProductSearchFormItem as default, ProductSearchFormItemComponent };
