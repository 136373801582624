import { createSelector } from 'reselect';

export const selectLicenses = state => state.accounts.licenses.data;

export const selectNvapLicense = createSelector(
  selectLicenses,
  licenses =>
    // TODO to verify with support regarding the duplicate federal licenses. Also add validation to ensure each vet only has one FEDERAL license on record
    Object.values(licenses).filter(
      license => license.issuingAuthority === 'FEDERAL'
    )[0]
);
