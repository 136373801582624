import { createAction } from 'utils/reduxUtil';
import {
  MVL_OWNER_LOAD_STARTUP_DATA_REQUEST,
  MVL_OWNER_LOAD_STARTUP_DATA_STARTED,
  MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS,
  MVL_OWNER_LOAD_STARTUP_DATA_FINISHED,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE,
  MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST,
  MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED,
  MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS,
  MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE,
  MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED,
  MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED,
  MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST,
  MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED,
  MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING
} from 'containers/accounts/mvlOwner/store/accountsMvlOwnerConstants';

export const mvlOwnerLoadStartupDataRequest = () =>
  createAction(MVL_OWNER_LOAD_STARTUP_DATA_REQUEST);
export const mvlOwnerLoadStartupDataStarted = () =>
  createAction(MVL_OWNER_LOAD_STARTUP_DATA_STARTED);
export const mvlOwnerLoadStartupDataSuccess = startupData =>
  createAction(MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const mvlOwnerLoadStartupDataFinished = () =>
  createAction(MVL_OWNER_LOAD_STARTUP_DATA_FINISHED);

export const mvlOwnerRefreshDocumentListRequest = () =>
  createAction(MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST);
export const mvlOwnerRefreshDocumentListStarted = () =>
  createAction(MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED);
export const mvlOwnerRefreshDocumentListSuccess = list =>
  createAction(MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS, { list });
export const mvlOwnerRefreshDocumentListFailure = () =>
  createAction(MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE);

export const mvlOwnerRefreshAnimalListRequest = () =>
  createAction(MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST);
export const mvlOwnerRefreshAnimalListStarted = () =>
  createAction(MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED);
export const mvlOwnerRefreshAnimalListSuccess = list =>
  createAction(MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS, { list });
export const mvlOwnerRefreshAnimalListFailure = () =>
  createAction(MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE);

export const mvlOwnerCreateEecviPermitsDrawerOpened = eecviIds =>
  createAction(MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED, { eecviIds });
export const mvlOwnerCreateEecviPermitsCancelled = () =>
  createAction(MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED);
export const mvlOwnerCreateEecviPermitsRequest = permitData =>
  createAction(MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST, { permitData });
export const mvlOwnerCreateEecviPermitsFinished = (
  updatedDocuments,
  failedIds
) =>
  createAction(MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED, {
    updatedDocuments,
    failedIds
  });
export const mvlOwnerCreateEecviPermitsNotWaiting = () =>
  createAction(MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING, {});
