import React from 'react';
import { Avatar, Badge, Typography } from 'antd';
import PropTypes from 'prop-types';
import GvlIcon from 'elements/GvlIcon';
import intl from 'react-intl-universal';
import { Tooltip } from 'antd';
import { THEME, FONT } from 'style';

const { Text } = Typography;

export default function GvlAvatar({
  avatarData,
  notificationCount,
  useIntl,
  isCollapsed,
  disabled,
  tooltipText,
  ...rest
}) {
  const label = (isCollapsed, useIntl, avatarData, disabled) => {
    if (isCollapsed) return '';
    let text = useIntl ? intl.get(avatarData.title) : avatarData.title;
    return <Text disabled={disabled}>{text}</Text>;
  };

  if (disabled) {
    avatarData.color = THEME.disabledText();
  }

  let avatar = (
    <div>
      <Badge count={notificationCount}>
        <Avatar
          style={{
            backgroundColor: avatarData.color,
            fontSize: FONT.size.large
          }}
          shape={avatarData.shape}
          size={avatarData.size}
          {...rest}
        >
          {avatarData.icon ? (
            <GvlIcon
              style={{ fontSize: FONT.size.large }}
              type={avatarData.icon}
            />
          ) : null}
          {avatarData.content || ''}
        </Avatar>
      </Badge>
      <br />
      <div>{label(isCollapsed, useIntl, avatarData, disabled)}</div>
    </div>
  );

  if (disabled) {
    return (
      <Tooltip
        placement="topLeft"
        arrowPointAtCenter={true}
        title={tooltipText}
      >
        {avatar}
      </Tooltip>
    );
  }
  return avatar;
}

GvlAvatar.propTypes = {
  avatarData: PropTypes.object.isRequired,
  notificationCount: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  useIntl: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string
};

GvlAvatar.defaultProps = {
  disabled: false,
  tooltipText: null,
  notificationCount: 0
};
