// Validations against lists
import store from 'store';

export const validateCarrierType = carrierType => {
  const lookupLists = store.getState().accounts.common.startupData.lookupLists;
  const generalCarrierTypes = lookupLists.carrierTypesList;
  const companionCarrierTypes =
    lookupLists.cviWorkflows.companion.carrierTypesList;
  if (
    generalCarrierTypes.includes(carrierType) ||
    companionCarrierTypes.includes(carrierType)
  ) {
    return null;
  } else {
    return { messageId: 'validation.carrierType.invalid' };
  }
};

// TODO: should we use this more generic method instead?
export const validateItemFromList = (lookupList, item) => {
  const validList = store.getState().accounts.common.startupData.lookupLists[
    lookupList
  ];
  if (validList.includes(item)) {
    return null;
  } else {
    return { messageId: `validation.value.not.in.${lookupList}` };
  }
};
