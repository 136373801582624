import { Modal, message } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';
import { isSafari, isIOS, isIOS13 } from 'react-device-detect';
import { saveFile } from 'utils/appUtil';

import * as api from 'utils/api';
import * as settingStorage from 'utils/settingStorage';
import store from 'store';
import { API_PREFIX } from 'utils/config';

// TODO: Add ability to style this Modal using Redux store setting app.user.colorThemeClassName.
// The only way to do it now as it is written is to pass the class since Modal.info has no context from the app.
const checkAndShowAdobeAcrobatNotice = () => {
  if (settingStorage.getValue('hasSeenAdobeAcrobatNotice')) return;

  Modal.info({
    title: intl.get('adobeNotice.title'),
    content: (
      <React.Fragment>
        <p>{intl.get('adobeNotice.p1')}</p>
        <p>{intl.get('adobeNotice.p2')}</p>
        <a
          href="https://acrobat.adobe.com/us/en/acrobat/pdf-reader.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          {intl.get('adobeNotice.p3')}
        </a>
      </React.Fragment>
    ),
    okText: intl.get('close'),
    onOk: () => {
      settingStorage.setValue('hasSeenAdobeAcrobatNotice', true);
    }
  });
};

export const downloadFile = async (
  fileUrl,
  showPdfNotice = false,
  postData = null,
  shouldShowMessage = true
) => {
  const hide = shouldShowMessage
    ? message.loading(intl.get('downloadFile.startingDownload'), 0)
    : () => {};
  const hideAndShowSuccess = shouldShowMessage
    ? () => {
        // Dismiss manually and asynchronously
        hide();
        message.success(intl.get('downloadFile.downloadStarted'), 1);
      }
    : () => {};
  let apiResponse;
  try {
    if (isIOS || (isIOS13 && !isSafari)) {
      // iOS browsers other than Safari can't use the regular saveFile() routine and must instead use window.open() or something similar with the authToken in the URL
      let delimiter = fileUrl.includes('?') ? '&' : '?';
      let authToken = store.getState().app.user.authToken;
      let fullFileUrl = `${API_PREFIX}/${fileUrl}${delimiter}authToken=${authToken}`;
      window.open(fullFileUrl);
      hide();
      return;
    } else {
      apiResponse = await api.getBlob(fileUrl, {}, postData);
    }
  } catch (error) {
    console.error(error);
    hide();
    message.error(intl.get('downloadFile.errorStartingDownload'));
    return;
  }
  if (!apiResponse.headers['content-disposition']) {
    console.warn(
      'Download will not work due to missing content-disposition header. cricket-ui and cricket server must be running on the same host? Try resetting REACT_APP_API_PREFIX=/gvl2/api in your .env file.'
    );
    hide();
    return;
  }
  let blob = new Blob([apiResponse.data]);
  const filename = apiResponse.headers['content-disposition'].split('"')[1];
  if (showPdfNotice || filename.toLowerCase().endsWith('.pdf')) {
    checkAndShowAdobeAcrobatNotice();
    blob = blob.slice(0, blob.size, 'application/pdf;charset=utf-8');
  }
  saveFile(blob, filename);
  hideAndShowSuccess();
  return apiResponse;
};
