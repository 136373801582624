import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { THEME, FONT } from 'style';
import MobileAwareDropdown from 'elements/MobileAwareDropdown';

import { DropdownButtonLink, MobileIcon } from 'style/commonEmotions';
import actionInfoPropTypes from 'components/GvlTable/actionInfoPropTypes';

const { Item: MenuItem, SubMenu } = Menu;

/* istanbul ignore next */
const HighlightedDiv = styled('div', { shouldForwardProp: isPropValid })(
  ({ highlightDanger }) => ({
    color: highlightDanger ? THEME.red() : THEME.text()
  })
);

/* istanbul ignore next */
const ActionsLinkRow = styled(DropdownButtonLink, {
  shouldForwardProp: isPropValid
})(({ isMultiselect }) =>
  isMultiselect
    ? {
        color: THEME.text(),
        fontFamily: FONT.family.header,
        padding: '5px 10px'
      }
    : { height: '19px', border: '0px' }
);

export function ActionsMenu({
  actionInfo,
  onClick,
  onClickData,
  isMultiselect,
  // Pull dispatch out of props to avoid passing to <Menu> component below.
  // eslint-disable-next-line react/prop-types
  dispatch,
  ...rest
}) {
  const menuOnClick = ({ key }) => {
    onClick();
    actionInfo.onActionClicked(key, onClickData);
  };

  return (
    <Menu {...rest} onClick={menuOnClick}>
      {actionInfo.actions.map(action => {
        if (
          (action.onlyMultiSelect && !isMultiselect) ||
          (action.onlyRow && isMultiselect)
        ) {
          return null;
        } else {
          if (action.haveSubMenu) {
            return (
              <SubMenu key={action.type} title={action.text}>
                {action.subMenu.map(subMenu => (
                  <MenuItem key={subMenu.type}>{subMenu.text}</MenuItem>
                ))}
              </SubMenu>
            );
          } else {
            return (
              <MenuItem key={action.type} disabled={action.disabled}>
                <HighlightedDiv highlightDanger={action.highlightDanger}>
                  {action.text}
                </HighlightedDiv>
              </MenuItem>
            );
          }
        }
      })}
    </Menu>
  );
}
ActionsMenu.propTypes = {
  onClickData: PropTypes.array.isRequired,
  actionInfo: PropTypes.shape(actionInfoPropTypes).isRequired,
  isMultiselect: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

class TableActionsMenuComponent extends Component {
  static propTypes = {
    onClickData: PropTypes.array.isRequired,
    actionInfo: PropTypes.shape(actionInfoPropTypes).isRequired,
    isMultiselect: PropTypes.bool,
    user: PropTypes.object,
    viewMode: PropTypes.string.isRequired
  };

  static defaultProps = {
    isMultiselect: false
  };

  // This state and functions for handling visible for the dropdown are needed to hide the
  // menu after an item is clicked.
  state = {
    visible: false
  };
  handleMenuClick = e => {
    this.setState({ visible: false });
  };
  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  render() {
    const {
      actionInfo,
      onClickData,
      isMultiselect,
      user,
      viewMode,
      ...rest
    } = this.props;

    let actionsButton;
    if (viewMode === 'Mobile') {
      actionsButton = <MobileIcon type="more" />;
    } else {
      actionsButton = (
        <ActionsLinkRow
          isMultiselect={isMultiselect}
          type={isMultiselect ? 'default' : 'link'}
          size={isMultiselect ? 'default' : 'small'}
        >
          {actionInfo.actionsTitle} <Icon type="down" />
        </ActionsLinkRow>
      );
    }
    return (
      <MobileAwareDropdown
        overlayClassName={user.colorThemeClassName}
        overlay={
          <ActionsMenu
            onClick={this.handleMenuClick}
            actionInfo={actionInfo}
            onClickData={onClickData}
            isMultiselect={isMultiselect}
            {...rest}
          />
        }
      >
        {actionsButton}
      </MobileAwareDropdown>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  user: app.user,
  viewMode: app.data.viewMode
});

const TableActionsMenu = connect(
  mapStateToProps,
  null
)(TableActionsMenuComponent);
export { TableActionsMenu as default, TableActionsMenuComponent };
