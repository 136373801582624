import CviModel from 'models/documents/Cvi';
import Document from 'models/documents/Document';
import RabiesVac from 'models/RabiesVac';
import LabTest from 'models/LabTest';
import Animal from 'models/Animal';
import * as api from 'utils/api';
import { downloadFile } from 'utils/downloadUtil';
import { objectRequired } from 'utils/validation/common';

export default class Eia extends Document {
  // Instance Methods
  async rejectFromLab(reason) {
    return await api.put(
      `${this.constructor.apiPath}/${this.id}?protectedAction=rejectFromLab`,
      { rejectReason: reason }
    );
  }

  //A function to check if the document has the minimum fields to required to be saved
  isReadyToSave() {
    // Needs an owner and an animal
    return this.owner && this.owner.id && this.animal && this.animal.id;
  }

  // handle requirements beyond minimal drafts. many validations that would not be checked if they were blank will now need to run.
  isReadyToSign(userCountry) {
    let results = this.validate();
    results || (results = []);
    // check required fields
    let fields = [
      'owner',
      'ownerPremises',
      'origin',
      'originPremises',
      'animal',
      'test'
    ];

    if (userCountry === 'Canada') {
      fields.push('districtOffice');
    }

    this.checkFieldsPresent(Eia, fields, results, 'Required to sign');

    if (userCountry === 'Canada') {
      // excluding neckAndBodyMarkings must have all markings filled in
      let invalidMarksMsg = Animal.fields.markings.validate(
        this.animal.markings,
        this.animal,
        null,
        userCountry
      );
      if (invalidMarksMsg) {
        results.push(
          Eia.formatError('animal', {
            messageId: 'eia.allMarkingsRequiredToSign'
          })
        );
      }
    }
    // Require at least one primary image for animal
    if (
      this.animal &&
      !(this.animal.left_id || this.animal.front_id || this.animal.right_id)
    ) {
      results.push(
        Eia.formatError('animal', {
          messageId: 'eia.onePrimaryImgRequiredToSign'
        })
      );
    }

    return results.length > 0 ? results : null; // if !results everything is good
  }

  async save(bypassSubObjectUpdating = false) {
    if (!bypassSubObjectUpdating) {
      //TODO: Move the logic to create sub objects to the UI component
      if (this.animal.id) {
        // trim unnecessary sub arrays on animal prior to saving the animal on the doc.
        // delete this.animal.tests;
        // delete this.animal.vacs;
        // delete this.animal.treatments;
        //TODO this is a quick way to update/delete sub objects. A system where we keep track of the change is more appropriate
        const remoteEia = this.id ? await Eia.read(this.id) : null;
        // TODO: should we detect if sub objects are changed?
        //Canadian EIA doesn't have rabies vaccination
        if (this.rabiesVac && this.rabiesVac.product) {
          let rabiesVac = new RabiesVac({
            ...this.rabiesVac,
            name: 'Rabies',
            species: 'equine'
          });
          rabiesVac.animal_id = this.animal.id;
          let errors = rabiesVac.validate();
          if (!errors) {
            await rabiesVac.save();
            this.rabiesVac = { ...rabiesVac };
          } else {
            console.error('eia.rabiesVac.error', rabiesVac, errors);
            this.rabiesVac = null;
          }
        } else if (remoteEia && remoteEia.rabiesVac && remoteEia.rabiesVac.id) {
          //Delete the RabiesVac record if present previously
          RabiesVac.deleteRec(remoteEia.rabiesVac.id);
          this.rabiesVac = null;
        }

        if (this.test.type) {
          let eiaTest = new LabTest({ ...this.test });
          eiaTest.animal_id = this.animal.id;
          eiaTest.animal_name = this.animal.name;
          let errors = eiaTest.validate();
          if (!errors) {
            await eiaTest.save();
            this.test = { ...eiaTest };
          } else {
            console.log('eia.test.error', eiaTest, errors);
            this.test = undefined;
          }
        } else {
          this.test = undefined;
        }
      }
    }

    return await super.save();
  }

  validate() {
    const results = super.validate();

    // Put complex validations here.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('CVI Special', {messageId: 'cvi.consignor.somethingWentWrong'));

    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      owner: {
        initialValue: {},
        validate: val => objectRequired('owner', val)
      },
      ownerPremises: {
        initialValue: {},
        validate: val => objectRequired('ownerPremises', val)
      },
      origin: {
        initialValue: {},
        validate: val => objectRequired('origin', val)
      },
      originPremises: {
        initialValue: {},
        validate: val => objectRequired('originPremises', val)
      },
      animal: {
        initialValue: undefined,
        validate: val => objectRequired('animal', val)
      },
      test: {
        initialValue: undefined
      },
      rabiesVac: {
        initialValue: undefined
      },
      requestId: {
        initialValue: undefined
      },
      lab: {
        initialValue: undefined
      },
      labTech: {
        initialValue: undefined
      },
      districtOffice: {
        initialValue: undefined,
        validate: (val, instance) => {
          if (instance && instance.country === 'Canada') {
            return objectRequired('eia.districtOffice', val);
          } else {
            return null;
          }
        }
      },
      species: {
        initialValue: 'Equine'
      },
      label: {
        initialValue: ''
      }
    };
  }

  static get docType() {
    return 'EIA';
  }

  static read = async id => {
    return super.read(id);
  };

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static fetchForEECVI = async (ownerId, animalId) => {
    const response = await super.list({
      type: this.docType,
      'metadata.status': 'SIGNED',
      'owner.id': ownerId,
      'animal.id': animalId,
      expirationSince: new Date().toJSON(),
      sort: '-expiration'
    });
    return response.results[0] || {};
  };

  static getDrafts = async () => {
    return super.getDrafts(this.docType);
  };

  static getLabApproval = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'PARTIALLY_SIGNED',
      limit: 0
    });
    return data.results;
  };

  static getRecentlySigned = async () => {
    return super.getRecentlySigned(this.docType);
  };

  static getRequests = async () => {
    return super.getRequests(this);
  };

  static checkSigningCredentials = (user, eia) => {
    const { info } = user;
    const { originPremises } = eia;

    if (!user.roles.includes('ROLE_VET')) {
      return 'signing.role.error';
    } else if (!info.canSignCerts) {
      return 'signing.cert.error';
    } else if (!info.licenseStates.includes('FEDERAL')) {
      // Federal liccense is required for Eia
      return 'eia.signing.federalLicenseRequired';
    }

    // Handle country specific requirements
    // TODO: What if Country is not Canada or USA?  We currently don't check anything. This is from Neptr
    if (info.userCountry === 'Canada' && originPremises.country !== 'Canada') {
      return 'eia.signing.canada.originPremiseNotCanada';
    } else {
      if (info.userCountry === 'USA' && originPremises.country !== 'USA') {
        return 'eia.signing.usa.originPremiseNotUsa';
      }
    }

    return ''; // User can sign Eia
  };

  static downloadLabSubmittalForm = (ids, includeImage) => {
    // TODO: This logic is what was done in Neptr.  Can we change it to always use the call for more than one Id?  If so, can we get rid of the single Cricket endpoint?
    if (ids.length === 1) {
      // If includImage is passed in, then use it.  Otherwise omit the includeImages param.
      const queryString =
        typeof includeImage === 'boolean'
          ? `?includeImages=${includeImage}`
          : '';
      // might not need second param here if filename ends with '.pdf'
      downloadFile(
        `${this.apiPath}/${ids[0]}/labSubmittalForm${queryString}`,
        true
      );
    } else {
      downloadFile(
        `${this.apiPath}/labSubmittalForm?ids=${ids.join(
          ','
        )}&includeImages=${includeImage}`,
        true
      );
    }
  };

  static createCviFromEia = async eia => {
    const cviData = {
      origin: { id: eia.origin.id },
      originPremises: { id: eia.originPremises.id },
      consignor: { id: eia.owner.id },
      consignorPremises: { id: eia.ownerPremises.id },
      species: 'Equine',
      animals: [{ ...eia.animal }]
    };

    cviData.animals[0].tests = [{ ...eia.test }];
    cviData.animals[0].tests[0].resultedBy = undefined; // cvi create API does not like resultedBy and document doesn't need it anyway
    cviData.animals[0].tests[0].cogginsStatus = 'resulted';

    if (eia.rabiesVac) {
      cviData.animals[0].vacs = [{ ...eia.rabiesVac }];
    }

    const cvi = new CviModel(cviData);

    await cvi.save();

    return cvi;
  };
}
