/* From React Boilerplate project
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to
 * intl i18n messages (loaded from `src/i18n/translations`)
 */

import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';

import antdLocales from 'i18n/antdLocales';
import translations from 'i18n/locales';

// This component might be unnecessary once intl-universal conversion is complete.
// move intl.init right into saga or whatever handles the change event.
export class LanguageProvider extends React.PureComponent {
  state = { isInitialized: false };

  loadLocales() {
    intl
      .init({
        currentLocale: this.props.locale,
        locales: translations
      })
      .then(() => {
        this.setState({ isInitialized: true });
      });
  }

  componentDidMount() {
    this.loadLocales();
  }

  render() {
    const { locale, children } = this.props;
    return (
      this.state.isInitialized && (
        <ConfigProvider locale={antdLocales[locale]}>
          {React.Children.only(children)}
        </ConfigProvider>
      )
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.element.isRequired
};

// TODO: since locale never changes unless page reloads could skip it being in redux at all
// and just get it directly from
// import { getLanguage } from 'utils/appUtil';
// which is what sets the value in redux anyway.
const mapStateToProps = ({ language }) => ({
  locale: language.locale
});

export default connect(mapStateToProps)(LanguageProvider);
