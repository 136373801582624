export const generateGvlAvatar = (
  title,
  icon,
  color,
  content,
  action,
  size = 'default',
  shape = 'circle'
) => {
  return { title, icon, color, content, action, shape, size };
};
