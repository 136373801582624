// Are the methods in this file helpful, or just obfuscation?

export const generateBreadcrumb = (id, title, link = null) => {
  return { id, title, link };
};

export const generateActionItem = (
  id,
  title,
  icon,
  type,
  onClick,
  showOnMobile = true,
  showTextOnMobile = false
) => {
  return { id, title, type, icon, onClick, showOnMobile, showTextOnMobile };
};
// {
//   id: id,
//   title: title,
//   icon: icon,
//   type: type,
//   onClick: onClick,
//   showOnMobile: true,
//   showTextOnMobile: false,
//   disabled: false
//   disabledTooltipTitle: intl.get('onlyVetsCanSign')
// }

export const generateHeaderItems = (
  breadcrumbs = [],
  actions = [],
  menuActions = [],
  additionalActions = [],
  predecessorComponents = []
) => {
  return {
    breadcrumbs,
    actions,
    menuActions,
    additionalActions,
    predecessorComponents
  };
};
