import * as types from './noteActionTypes';
import * as actions from './noteActions';
import Note from 'modules/Note/Note';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';

export function* onListRequest({ referenceId }) {
  try {
    yield put(actions.isRefreshing(true));
    let notes = [];
    if (Number.isInteger(referenceId)) {
      notes = yield call(Note.list, { longReferenceId: referenceId, limit: 0 });
    } else {
      notes = yield call(Note.list, { referenceId: referenceId, limit: 0 });
    }
    yield put(actions.listSuccess(referenceId, notes.results));
  } catch (err) {
    yield put(apiFailure(err, 'Error loading notes'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* noteSagas() {
  yield takeLatest(types.LIST_REQUEST, onListRequest);
}
