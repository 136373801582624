import { put, call, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as api from 'utils/api';
import * as actions from 'modules/License/store/licenseActions';
import * as types from 'modules/License/store/licenseActionTypes';
import { notification } from 'antd';
import License from 'modules/License/License';
import intl from 'react-intl-universal';

export function* onLicenseListRequest({ vetId, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    const licenses = yield call(api.get, `/vet/${vetId}/licenses`);
    yield put(actions.listSuccess(licenses));
    if (typeof callback === 'function') callback(licenses);
  } catch (err) {
    yield put(apiFailure(err, 'Error loading License data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveLicenseRequest(action) {
  let { license } = action;
  let result;
  try {
    if (!license.id) {
      result = yield call(api.put, '/license/update', license);
      yield put(actions.refreshSuccess(result));
    } else {
      result = yield call(api.put, `/license/update/${license.id}`, license);
      yield put(actions.refreshSuccess(result));
    }
  } catch (err) {
    yield call(notification.warning, {
      message: intl.get('error.saving.noun', {
        noun: intl.get('license.lc')
      }),
      description: intl.get('server.error')
    });
  }
}

export function* onDeleteLicenseRequest({ params }) {
  try {
    yield call(api.deleteRecWithData, `/license/delete`, params);
    yield put(actions.deleteSuccess(params.id));
  } catch (err) {
    yield call(notification.warning, {
      message: intl.get('error.deleting.properNoun', {
        properNoun: intl.get('license.lc')
      }),
      description: intl.get('server.error')
    });
  }
}

export function* onValidateFederalLicense({ params }) {
  try {
    const response = yield call(License.validateFederalLicense, params);
    if (response.resultCode === 404) {
      notification.warning({
        message: intl.get('license.accreditation.mismatch')
      });
    }
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.validating.license')));
  }
}

export default function* licenseSagas() {
  yield takeLatest(types.LIST_REQUEST, onLicenseListRequest);
  yield takeLatest(types.SAVE_REQUEST, onSaveLicenseRequest);
  yield takeLatest(types.DELETE_REQUEST, onDeleteLicenseRequest);
  yield takeLatest(types.VALIDATE_FEDERAL_LICENSE, onValidateFederalLicense);
}
