import Model from 'models/Model';
import GoogleDataPoint from './GoogleDataPoint';

export default class SelfSignupAnalytics extends Model {
  static get category() {
    return 'Registration';
  }

  static get fields() {
    return {
      currentAccountYes: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Current account - yes'
        })
      },
      currentAccountNo: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Current account - no'
        })
      },
      banfieldYes: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Banfield or VCA clinic - yes'
        })
      },
      banfieldNo: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Banfield or VCA clinic - no'
        })
      },
      clinicName: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Clinic section - entered name'
        })
      },
      clinicEmail: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Clinic section - entered email'
        })
      },
      clinicPhone: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Clinic section - entered phone'
        })
      },
      locationAddress: {
        initialValue: {
          addressLine1: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered address line 1'
          }),
          city: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered city'
          }),
          state: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered state'
          }),
          postalCode: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered postal code'
          })
        }
      },
      billingAddress: {
        initialValue: {
          addressLine1: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered billing address line 1'
          }),
          city: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered billing city'
          }),
          state: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered billing state'
          }),
          postalCode: new GoogleDataPoint({
            category: SelfSignupAnalytics.category,
            action: 'Clinic section - entered billing postal code'
          })
        }
      },
      firstName: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Vet section - entered first name'
        })
      },
      lastName: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Vet section - entered last name'
        })
      },
      username: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Vet section - entered username'
        })
      },
      password: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Vet section - entered password'
        })
      },
      signature: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Signature created',
          label: 'esign'
        })
      },
      accountInformation: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Finished account information'
        })
      },
      billingInformation: {
        initialValue: new GoogleDataPoint({
          category: SelfSignupAnalytics.category,
          action: 'Finished billing information'
        })
      }
    };
  }
}
