import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Form, Modal, Button, Input, notification, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { FadedText } from 'style/commonEmotions';
import StateSelect from 'elements/StateSelect';
import { postalCodeMatchesCountry } from 'utils/validation/common';

class AlternateClinicAddressModalComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    alternateClinicAddress: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.props.form.setFieldsValue({ ...this.props.alternateClinicAddress });
    }
  }

  saveAddress = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('address.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      this.props.onChange(formValues);
      this.props.closeModal();
    });
  };

  cancelEdit = () => {
    const { form, closeModal } = this.props;
    form.isFieldsTouched()
      ? Modal.confirm({
          title: intl.get('cancel.edit'),
          content: intl.get('cancel.edit.body'),
          okText: intl.get('discard'),
          onOk: closeModal
        })
      : closeModal();
  };

  render() {
    const { visible, form, alternateClinicAddress } = this.props;

    return (
      <Modal
        title={
          alternateClinicAddress
            ? intl.get('clinic.edit.information')
            : intl.get('clinic.add.information')
        }
        visible={visible}
        onCancel={this.cancelEdit}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.cancelEdit}>
            {intl.get('cancel')}
          </Button>,
          <Button key="save" type="primary" onClick={this.saveAddress}>
            {intl.get('save')}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <Form.Item label={intl.get('test.clinicName')}>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: intl.get('required')
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label={intl.get('address')}>
            {form.getFieldDecorator('addressLine1', {
              rules: [
                {
                  required: true,
                  message: intl.get('required')
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label={intl.get('city')}>
                {form.getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: intl.get('required')
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={intl.get('state')}>
                {form.getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: intl.get('required')
                    }
                  ]
                })(<StateSelect />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={intl.get('zip')}>
                {form.getFieldDecorator('postalCode', {
                  rules: [
                    {
                      required: true,
                      message: intl.get('required')
                    },
                    {
                      validator: (rule, value, callback) => {
                        const error = postalCodeMatchesCountry(value, 'USA');
                        if (value && error) {
                          callback(intl.get(error.messageId));
                        } else {
                          callback();
                        }
                      }
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={
              <span>
                {intl.get('email')}{' '}
                <FadedText>({intl.get('optional')})</FadedText>
              </span>
            }
          >
            {form.getFieldDecorator('email')(<Input />)}
          </Form.Item>
          <Form.Item
            label={
              <span>
                {intl.get('phone')}{' '}
                <FadedText>({intl.get('optional')})</FadedText>
              </span>
            }
          >
            {form.getFieldDecorator('phone')(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const AlternateClinicAddressModal = Form.create({
  name: 'alternateClinicAddressForm'
})(AlternateClinicAddressModalComponent);

export {
  AlternateClinicAddressModal as default,
  AlternateClinicAddressModalComponent
};
