import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { UserRegistration as UserRegistrationModel } from 'modules/Registration/User/userRegistrationIndex';
import GvlCard from 'elements/GvlCard';
import PasswordFormItem from 'elements/PasswordFormItem';

export default function UserRegistrationInformation({
  form,
  onValidateUsername,
  submitButtonInfo
}) {
  return (
    <GvlCard title={intl.get('user.details')}>
      <div>
        <Form.Item label={intl.get('firstName')}>
          {getModelFieldDecorator(
            form,
            'firstName',
            UserRegistrationModel,
            true
          )(<Input />)}
        </Form.Item>
        <Form.Item label={intl.get('lastName')}>
          {getModelFieldDecorator(
            form,
            'lastName',
            UserRegistrationModel,
            true
          )(<Input />)}
        </Form.Item>
        <Form.Item
          label={intl.get('email')}
          extra={intl.get('register.email.info')}
        >
          {getModelFieldDecorator(
            form,
            'username',
            UserRegistrationModel,
            true
          )(<Input onBlur={onValidateUsername} disabled />)}
        </Form.Item>
        <PasswordFormItem form={form} model={UserRegistrationModel} />
        <Button
          data-testid="userRegInfoBtn"
          style={{ float: 'right', margin: '0px 0px 24px' }}
          type="primary"
          onClick={() => submitButtonInfo.onClick({})}
        >
          {intl.get(submitButtonInfo.messageId)}
        </Button>
      </div>
    </GvlCard>
  );
}

UserRegistrationInformation.propTypes = {
  form: PropTypes.object.isRequired,
  onValidateUsername: PropTypes.func.isRequired,
  submitButtonInfo: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired
};
