import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import intl from 'react-intl-universal';
import * as api from 'utils/api';
import * as travelSettings from 'utils/travelSettingsUtil';
import { message, notification } from 'antd';
import Rules from 'models/Rules';
import LabTest from 'models/LabTest';
import {
  regulatoryLoadStartupDataSuccess,
  regulatoryLoadStartupDataStarted,
  regulatoryLoadStartupDataFinished,
  regulatoryLoadSettingsDataStarted,
  regulatoryLoadSettingsDataSuccess,
  regulatorySettingsDataSaveSuccess,
  //rules
  regulatoryRefreshRuleListSuccess,
  regulatoryRefreshRuleListFailure,
  //Certificates
  regulatorySearchCertificatesSuccess,
  regulatorySearchCertificatesFailure,
  //EECVI permit search
  regulatorySearchEecviPermitsSuccess,
  regulatoryFetchEecviSuccess,
  regulatoryFetchEecviFailure,
  // SmartEngine
  regulatoryVerifyCertificateSuccess,
  regulatoryCloseSmartEngineModal,
  regulatorySearchTestsSuccess,
  // Out of state lookup
  regulatoryDocumentLookupSuccess,
  regulatoryDocumentLookupFailure,
  //Weekly certificate reports
  regulatoryWeeklyCertificatesReportsSuccess,
  regulatoryWeeklyCertificatesReportsFailure
} from 'containers/accounts/regulatory/store/accountsRegulatoryActions';

import {
  REGULATORY_LOAD_STARTUP_DATA_REQUEST,
  REGULATORY_LOAD_SETTINGS_DATA_REQUEST,
  REGULATORY_SETTINGS_DATA_SAVE_REQUEST,
  //Rules
  REGULATORY_REFRESH_RULE_LIST_REQUEST,
  REGULATORY_RULE_STATUS_UPDATE_REQUEST,
  REGULATORY_RULE_ADD_FEEDBACK_REQUEST,
  //Certificates
  REGULATORY_SEARCH_CERTIFICATES_REQUEST,
  // EECVI permit search
  REGULATORY_SEARCH_EECVI_PERMITS,
  REGULATORY_VIEW_EECVI_ITINERARY,
  // SmartEngine
  REGULATORY_VERIFY_CERTIFICATE_REQUEST,
  REGULATORY_SEARCH_TESTS,
  // Out of state lookup
  REGULATORY_DOCUMENT_LOOKUP_REQUEST,
  REGULATORY_DOCUMENT_LOOKUP_SUCCESS,
  REGULATORY_DOCUMENT_LOOKUP_FAILURE,
  //Weekly certificate reports
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST
} from 'containers/accounts/regulatory/store/accountsRegulatoryConstants';

import Document from 'models/documents/Document';
import Eecvi from 'models/documents/Eecvi';
import { connectionError } from 'containers/app/store/data/dataActions';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { downloadFile } from 'utils/downloadUtil';
import { exportCsv, formatDate } from 'utils/csvUtil';

export function* onRegulatoryLoadStartupDataRequest() {
  try {
    yield put(regulatoryLoadStartupDataStarted());

    const { ruleTypes } = yield call(api.get, '/regulatoryRules/getLookupList');
    yield put(
      regulatoryLoadStartupDataSuccess({
        ruleTypes
      })
    );
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(regulatoryLoadStartupDataFinished());
  }
}

export function* onRegulatoryLoadSettingsDataRequest(action) {
  let { data } = action;
  try {
    yield put(regulatoryLoadSettingsDataStarted());
    const result = yield call(travelSettings.getSettings, data.userId);
    yield put(regulatoryLoadSettingsDataSuccess(result));
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onRegulatorySettingsDataSaveRequest(action) {
  let { data } = action;
  try {
    const result = yield call(
      travelSettings.saveSettings,
      data.userId,
      data.settingsData
    );
    if (result) {
      yield call(
        message.success,
        intl.get('savedWithNoun', { noun: 'Settings' })
      );
    }
    yield put(regulatorySettingsDataSaveSuccess(result));
  } catch (err) {
    yield put(connectionError());
  }
}

export function* onRegulatoryRefreshRulesListRequest(action) {
  try {
    const rules = yield Rules.getRulesData(action.params);
    yield put(regulatoryRefreshRuleListSuccess(rules));
  } catch (err) {
    yield put(apiFailure('Error loading  Rule List data'));
    yield put(regulatoryRefreshRuleListFailure());
  }
}

export function* onRegulatorySearchCertificatesRequest(action) {
  const { params } = action;
  try {
    let certificates = yield call(api.get, 'cert/search', {
      // Setting report to true sends back all results instead of just 50
      params: { ...params, report: true }
    });
    if (params.export) {
      yield call(
        downloadFile,
        `stateVet/exportSpreadsheet?seq_id=${certificates.seqId}`
      );
      certificates = certificates.certs;
    }
    yield put(regulatorySearchCertificatesSuccess(certificates));
  } catch (err) {
    // None of the list API calls should fail, so show the general API Failure message
    yield put(apiFailure(err));
    yield put(regulatorySearchCertificatesFailure());
  }
}

export function* onRegulatoryRuleStatusUpdateRequest(action) {
  let { id, status, params } = action;
  try {
    yield Rules.setRulesStatus(id, status);
    const rules = yield Rules.getRulesData(params);
    yield put(regulatoryRefreshRuleListSuccess(rules));

    message.success(
      intl.get('batchRuleSuccessProperNoun', {
        properNoun: status ? 'activated' : 'deactivated'
      })
    );
  } catch (err) {
    console.log('error', err);
    yield put(connectionError());
  }
}
export function* onRegulatoryRuleAddFeedbackRequest(action) {
  let { id, feedback, params } = action;
  try {
    yield Rules.setRulesStatus(id, false);
    yield Rules.addFeedback(id, { feedback: feedback });
    const rules = yield Rules.getRulesData(params);
    yield put(regulatoryRefreshRuleListSuccess(rules));
    message.success(intl.get('feedbackSubmitted'));
  } catch (err) {
    yield put(apiFailure('Error loading  Rule List data'));
    yield put(regulatoryRefreshRuleListFailure());
  }
}

export function* onRegulatorySearchEecviPermits(action) {
  const { searchParams } = action;
  try {
    const eecviPermits = (yield call(Document.rawList, searchParams)).results;

    if (searchParams.export) {
      if (eecviPermits.length) {
        const csvLayout = [
          { heading: 'Permit Number', property: 'serialNumber' },
          {
            heading: 'Approx. Travel Date',
            property: 'movementDate',
            dataFormatFunction: date => moment(date).format('M/D/YYYY')
          },
          {
            heading: 'Origin',
            property: 'origin',
            dataFormatFunction: origin => `${origin.city} ${origin.state}`
          },
          {
            heading: 'Destination',
            property: 'destination',
            dataFormatFunction: origin => `${origin.city} ${origin.state}`
          },
          { heading: 'Issuing Veterinarian', property: 'vet.commonName' }
        ];
        let fileName = `EECVI_Permit_Export_${formatDate(new Date())}.csv`;
        exportCsv(csvLayout, eecviPermits, fileName);
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    }
    yield put(regulatorySearchEecviPermitsSuccess(eecviPermits));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onRegulatoryViewEecviItinerary(action) {
  try {
    const eecvi = yield call(Eecvi.read, action.id);
    yield put(regulatoryFetchEecviSuccess(eecvi));
  } catch (err) {
    yield put(regulatoryFetchEecviFailure());
    yield put(apiFailure());
  }
}

export function* onRegulatoryVerifyCertificateRequest(action) {
  try {
    const seResults = yield call(
      api.get,
      `/ecvi/verify/${action.certificateId}`
    );
    yield put(regulatoryVerifyCertificateSuccess(seResults));
  } catch (err) {
    yield put(regulatoryCloseSmartEngineModal());
    yield put(apiFailure());
  }
}

export function* onRegulatorySearchTests(action) {
  try {
    const tests = yield call(LabTest.advancedSearch, action.searchParams);
    yield put(regulatorySearchTestsSuccess(tests));
  } catch (err) {
    yield put(apiFailure());
  }
}

//Out of state lookup
export function* onRegulatoryDocumentLookupRequest(action) {
  let { certNumber, certType } = action;
  try {
    const lookupDocumentResponse = yield call(
      Document.lookupDocument,
      certNumber,
      certType
    );
    yield put(regulatoryDocumentLookupSuccess(lookupDocumentResponse.certId));
  } catch (err) {
    if (err && err.response) {
      if (err.response.status === 404) {
        yield put(
          regulatoryDocumentLookupFailure(
            null,
            intl.get('certificateNotFound.helpText', { certNumber }),
            intl.get('certificateNotFound')
          )
        );
        return;
      } else {
        if (err.response.data && err.response.data.errors) {
          yield put(
            regulatoryDocumentLookupFailure(
              null,
              err.response.data.errors[0].message,
              null
            )
          );
          return;
        } else {
          if (err.response.data && err.response.data.errorMessage) {
            yield put(
              regulatoryDocumentLookupFailure(
                null,
                err.response.data.errorMessage,
                null
              )
            );
            return;
          }
        }
      }
    }

    // Unhandled API error
    yield put(regulatoryDocumentLookupFailure('server.error', null, null));
  }
}

export function* onRegulatoryDocumentLookupSuccess(action) {
  yield call(Document.downloadPdf, action.certId);
}

export function* onRegulatoryDocumentLookupFailure(action) {
  const { msgId, specificDescription, specificMessage } = action;
  yield call(notification.error, {
    message: specificMessage || intl.get('lookupDocumentError'),
    description: specificDescription || intl.get(msgId)
  });
}

export function* onRegulatoryWeeklyCertificatesReportsRequest(action) {
  try {
    const reports = yield Rules.getWeeklyReports(action.id);
    yield put(regulatoryWeeklyCertificatesReportsSuccess(reports));
  } catch (err) {
    yield put(apiFailure('Error loading  Reports List data'));
    yield put(regulatoryWeeklyCertificatesReportsFailure());
  }
}

export default function* accountsRegulatorySagas() {
  yield takeLatest(
    REGULATORY_LOAD_STARTUP_DATA_REQUEST,
    onRegulatoryLoadStartupDataRequest
  );
  yield takeLatest(
    REGULATORY_LOAD_SETTINGS_DATA_REQUEST,
    onRegulatoryLoadSettingsDataRequest
  );
  yield takeLatest(
    REGULATORY_SETTINGS_DATA_SAVE_REQUEST,
    onRegulatorySettingsDataSaveRequest
  );
  yield takeLatest(
    REGULATORY_REFRESH_RULE_LIST_REQUEST,
    onRegulatoryRefreshRulesListRequest
  );
  yield takeLatest(
    REGULATORY_SEARCH_CERTIFICATES_REQUEST,
    onRegulatorySearchCertificatesRequest
  );
  yield takeLatest(
    REGULATORY_RULE_STATUS_UPDATE_REQUEST,
    onRegulatoryRuleStatusUpdateRequest
  );
  yield takeLatest(
    REGULATORY_RULE_ADD_FEEDBACK_REQUEST,
    onRegulatoryRuleAddFeedbackRequest
  );
  yield takeLatest(
    REGULATORY_SEARCH_EECVI_PERMITS,
    onRegulatorySearchEecviPermits
  );
  yield takeLatest(
    REGULATORY_VIEW_EECVI_ITINERARY,
    onRegulatoryViewEecviItinerary
  );
  yield takeLatest(
    REGULATORY_VERIFY_CERTIFICATE_REQUEST,
    onRegulatoryVerifyCertificateRequest
  );

  yield takeLatest(REGULATORY_SEARCH_TESTS, onRegulatorySearchTests);

  // Out of state Lookup
  yield takeLatest(
    REGULATORY_DOCUMENT_LOOKUP_REQUEST,
    onRegulatoryDocumentLookupRequest
  );
  yield takeLatest(
    REGULATORY_DOCUMENT_LOOKUP_SUCCESS,
    onRegulatoryDocumentLookupSuccess
  );
  yield takeLatest(
    REGULATORY_DOCUMENT_LOOKUP_FAILURE,
    onRegulatoryDocumentLookupFailure
  );
  yield takeLatest(
    REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST,
    onRegulatoryWeeklyCertificatesReportsRequest
  );
}
