// Common validations that can be used by all models
import moment from 'moment';
import intl from 'react-intl-universal';

export const passwordStrengthValidator = value => {
  if (typeof value !== 'string') {
    return null;
  } else if (!value.match(/^[ -~]{8,}$/)) {
    // at least 8 printable ascii characters, except delete. See https://www.ascii-code.com/
    return intl.get('password.update.strength');
  } else if (
    [
      'password',
      'password1',
      'baseball',
      'football',
      'superman',
      'trustno1'
    ].includes(value)
  ) {
    return intl.get('password.update.common');
  }
  return null;
};

export const objectRequired = (objectName, value) => {
  return value && value.id
    ? null
    : { messageId: `validation.document.${objectName}.required` };
};

export const objectRequiredWithId = value => {
  return value && value.id ? null : { messageId: `required` };
};

export const objectRequiredWithField = (value, fieldName) => {
  return value && value[fieldName] ? null : { messageId: `required` };
};

export const pojoRequired = (objectName, value, useGenericTranslation) => {
  return value && typeof value === 'object'
    ? null
    : {
        messageId: useGenericTranslation
          ? 'isRequired'
          : `validation.document.${objectName}.required`,
        values: { thing: objectName }
      };
};

export const arrayRequired = (arrayName, value, useGenericTranslation) => {
  return value && Array.isArray(value) && value.length !== 0
    ? null
    : {
        messageId: useGenericTranslation
          ? 'isRequired'
          : `validation.document.${arrayName}.required`,
        values: { thing: arrayName }
      };
};

export const genericArrayRequired = (value, messageId) => {
  return value && Array.isArray(value) && value.length !== 0
    ? null
    : { messageId };
};

export const arrayOfObjectsRequiredWithIds = array => {
  if (!array || !Array.isArray(array) || !array.length) {
    return { messageId: `required` };
  }
  let message = null;
  array.forEach(object => {
    if (!object.id) {
      message = { messageId: `validation.id.missing` };
    }
  });
  return message;
};

export const getRequiredMsg = type =>
  intl.get('isRequired', {
    thing: intl.get(type)
  });

// messageValues should be an object where the values are intl codes - not already translated strings
// the intention is they will be run trhough a translator at a later stage
export const stringRequired = (value, messageId, messageValues) => {
  return !value || value.length === 0
    ? { messageId, values: messageValues }
    : null;
};

export const boolRequired = (value, messageId, messageValues) => {
  return value === undefined || value === null
    ? { messageId, values: messageValues }
    : null;
};

export const minLength = (value, minimumLength) => {
  return !value || value.length < minimumLength
    ? {
        messageId: 'validation.lessThanMinLength',
        values: { minLength: `${minimumLength}` }
      }
    : null;
};

export const maxLength = (value, maxLength) => {
  return value && value.length > maxLength
    ? {
        messageId: 'validation.exceedsMaxLength',
        values: { maxLength: `${maxLength}` }
      }
    : null;
};

export const arrayElementsMaxLength = (array, maxLength) => {
  return array.length && array.filter(el => el.length > maxLength).length
    ? {
        messageId: 'validation.arrayElMaxLength',
        values: { maxLength: `${maxLength}` }
      }
    : null;
};

export const disallowCharacters = (value, disallowedCharacters, messageId) =>
  value &&
  disallowedCharacters.some(disallowedChar => value.includes(disallowedChar))
    ? {
        messageId
      }
    : null;

export const isDate = (
  checkDate,
  format,
  messageId = 'validation.date.invalid',
  messageValues
) => {
  if (typeof checkDate === 'string') checkDate = moment(checkDate);
  if (moment(checkDate, format, true).isValid()) {
    return null;
  } else {
    return { messageId, values: messageValues };
  }
};

export const dateIsFutureDate = (date, messageId, messageValues) => {
  if (
    moment(date)
      .startOf('day')
      .isAfter(moment().endOf('day'))
  ) {
    return {
      messageId,
      values: messageValues
    };
  }
  return null;
};

export const dateIsPastDate = (date, messageId, messageValues) => {
  if (
    moment(date)
      .endOf('day')
      .isBefore(moment().startOf('day'))
  ) {
    return {
      messageId,
      values: messageValues
    };
  }
  return null;
};

export const monthIsFutureMonth = (values, messageId, messageValues) => {
  let { month, year } = values;
  if (
    (month > new Date().getMonth() + 1 && year === new Date().getFullYear()) ||
    year > new Date().getFullYear()
  ) {
    return {
      messageId,
      values: messageValues
    };
  } else {
    return null;
  }
};

export const dateIsAfter = (checkDate, maxDate, messageId, messageValues) => {
  if (moment(checkDate).isAfter(maxDate, 'day')) {
    return {
      messageId: messageId || 'validation.date.cannot.exceed.max.date',
      values: messageValues || { maxDate: moment(maxDate).format('MM/DD/YYYY') }
    };
  }
  return null;
};

export const dateIsBefore = (checkDate, minDate, messageId, messageValues) => {
  if (moment(checkDate).isBefore(minDate, 'day')) {
    return {
      messageId: messageId || 'validation.date.cannot.precede.min.date',
      values: messageValues || { minDate: moment(minDate).format('MM/DD/YYYY') }
    };
  }
  return null;
};

export const dateInRangeNoTime = (checkDate, minDate, maxDate) => {
  let err;
  if (minDate) {
    err = dateIsBefore(checkDate, minDate);
  }
  if (!err && maxDate) {
    err = dateIsAfter(checkDate, maxDate);
  }

  return err;
};

export const isInteger = (value, messageId, messageValues) => {
  if (Number.isInteger(value)) {
    return null;
  } else {
    return { messageId, values: messageValues };
  }
};

export const isNumber = (value, messageId, messageValues) => {
  if (!isNaN(value) && value !== null) {
    return null;
  } else {
    return { messageId, values: messageValues };
  }
};

// Validates comma delimited emails
export const multipleEmailValidator = (value, messageId) => {
  let result = null;

  if (value) {
    const emails = value.split(/,\s*/);
    for (let email of emails) {
      result = isEmail(email, messageId);
      if (result) break;
    }
  }

  return result;
};

export const isEmail = (value, messageId) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(value).toLowerCase())) {
    return null;
  } else {
    return { messageId };
  }
};

export const numberInRange = (checkNumber, minValue, maxValue, messageId) => {
  if (minValue !== undefined && checkNumber < minValue) {
    return {
      messageId: messageId || 'validation.integer.cannot.be.below.min.value',
      values: { minValue: `${minValue}`, maxValue: `${maxValue}` }
    };
  }

  if (maxValue !== undefined && checkNumber > maxValue) {
    return {
      messageId: messageId || 'validation.integer.cannot.exceed.max.value',
      values: { minValue: `${minValue}`, maxValue: `${maxValue}` }
    };
  }
  return null;
};

export const postalCodeMatchesCountry = (postalCode, country) => {
  if (country === 'USA' || country === 'United States of America') {
    if (!/^\d{5}(-\d{4})?$/.test(postalCode)) {
      return {
        messageId: 'validation.location.invalid.postal.code',
        values: { postalCode: postalCode }
      };
    }
  } else if (country === 'Canada') {
    if (
      !/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/.test(
        postalCode
      )
    ) {
      return {
        messageId: 'validation.location.invalid.postal.code',
        values: { postalCode: postalCode }
      };
    }
  }
  return null;
};

export const stateMatchesCountry = (state, country) => {
  if (country === 'USA' || country === 'United States of America') {
    if (
      !/^(?:A[KLRSZ]|C[AOT]|D[CE]|FL|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEINOPST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[ATI]|W[AIVY])*$/.test(
        state
      )
    ) {
      return {
        messageId: 'validation.location.invalid.state',
        values: { state: state }
      };
    }
  } else if (country === 'Canada') {
    if (!/^(?:AB|BC|MB|N[BLTSU]|ON|PE|QC|SK|YT)*$/.test(state)) {
      return {
        messageId: 'validation.location.invalid.state',
        values: { state: state }
      };
    }
  }
  return null;
};

export const isPhoneNumber = value => {
  // regex for the NANP (https://en.wikipedia.org/wiki/North_American_Numbering_Plan)
  // https://stackoverflow.com/questions/123559/a-comprehensive-regex-for-phone-number-validation/123666#123666
  return /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(
    value
  )
    ? null
    : {
        messageId: 'validation.phone.invalid',
        values: { value: value }
      };
};

export const isNotPoBox = value => {
  return !/^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/i.test(
    value
  )
    ? null
    : {
        messageId: 'validation.location.no.po.box',
        values: { value: value }
      };
};

export const validRange = (minValue, maxValue, messageId) => {
  if (typeof minValue !== 'number' || typeof maxValue !== 'number') {
    // if either field is missing, the range is invalid.
    return { messageId: messageId || 'validation.range.missingValues' };
  }
  if (minValue >= maxValue) {
    // if the min exceeds the max, the range is invalid.
    return {
      messageId: messageId || 'validation.range.minCannotExceedMax',
      values: { minValue: minValue, maxValue: maxValue }
    };
  }
  return null;
};

export const containsUpperAndLowerCaseLetters = value => {
  return /[a-z]/.test(value) && /[A-Z]/.test(value)
    ? null
    : { messageId: 'validation.password.upperAndLowerCase' };
};

export const containsNumber = value => {
  return /[0-9]/.test(value)
    ? null
    : { messageId: 'validation.password.number' };
};

export const containsSymbol = value => {
  return /[~`!@#$%^&*()_\-{}[\]|\\:;"'<>,.?/ +=]/.test(value)
    ? null
    : { messageId: 'validation.password.symbol' };
};
