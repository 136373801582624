import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { THEME } from 'style';
import { Row, Col, Icon, Button } from 'antd';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';
import { VerticalDivider } from 'components/Animal/AnimalEmotions';
import { HorizontalDivider, BorderedDiv } from 'style/commonEmotions';

export default function LocationInlineShow(props) {
  const { location, onRemoveLocation, onEditClicked } = props;
  return (
    <BorderedDiv>
      <h4 style={{ margin: '12px' }}>
        <Row>
          <Col
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>{location.name}</span>
            <span style={{ float: 'right' }}>
              <Button
                type="link"
                style={{
                  margin: 'auto',
                  padding: 0
                }}
                onClick={onEditClicked}
              >
                {intl.get('edit')}
              </Button>
              <VerticalDivider type="vertical" />
              <Button
                style={{
                  border: 'none',
                  margin: 'auto',
                  paddingLeft: 0
                }}
                onClick={onRemoveLocation}
              >
                <Icon type="close" style={{ color: THEME.red() }} />
              </Button>
            </span>
          </Col>
        </Row>
      </h4>
      <HorizontalDivider />
      <Row style={{ margin: '16px' }}>
        <Col span={12}>
          <IsolateValWithIcon
            val={
              <React.Fragment>
                {location?.addressLine1 ? (
                  <div>{location?.addressLine1}</div>
                ) : null}
                {location?.city || location?.state || location?.postalCode ? (
                  <div>
                    {location?.city}
                    {location?.state ? `, ${location?.state}` : null}{' '}
                    {location?.postalCode}
                  </div>
                ) : null}
                {location?.latitude || location?.longitude ? (
                  <div>{`(${location?.latitude}, ${location?.longitude})`}</div>
                ) : null}
              </React.Fragment>
            }
            icon="environment"
          />
          <br />
          <IsolateVal name="premiseID" val={location.pin} />
          <IsolateVal name="flow" val={location.description} />
        </Col>
        <Col span={12}>
          <Row>
            <IsolateValWithIcon icon="phone" val={location.phone} />
          </Row>
        </Col>
      </Row>
    </BorderedDiv>
  );
}

LocationInlineShow.propTypes = {
  location: PropTypes.object.isRequired,
  onRemoveLocation: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired
};
