import { combineReducers } from 'redux';

import userReducer from 'containers/app/store/user/userReducer';
import loginReducer from 'containers/app/store/login/loginReducer';
import dataReducer from 'containers/app/store/data/dataReducer';

export default function appReducer() {
  const appReducer = combineReducers({
    user: userReducer,
    login: loginReducer,
    data: dataReducer
  });

  return appReducer;
}
