import { invariant } from './util';

/*
Example usage with Emotion:

import styled from '@emotion/styled;
import { MEDIA } from '../styles';

const Card = styled.div({
  width: '100px',
  [MEDIA.greaterThan('medium')]: {
    width: '150px'
  }
})
*/

const breakpoints = {
  small: 0,
  medium: 1024,
  large: 1440
};

export const MEDIA = {
  greaterThan(property) {
    invariant(
      Object.prototype.hasOwnProperty.call(breakpoints, property),
      `The property ${property} is not a valid breakpoint`
    );
    return `@media only screen and (min-width: ${breakpoints[property] ||
      0}px)`;
  }
};
