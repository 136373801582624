import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'antd';
import GvlCard from 'elements/GvlCard';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateNameHeading from 'elements/AutogenousVaccines/IsolateNameHeading';
import IsolateExpirationVal from 'elements/AutogenousVaccines/IsolateExpirationVal';
import intl from 'react-intl-universal';

export default function IsolateReducedDetailsCard({
  isolate,
  releaseRequest,
  expandedView,
  showReleaseReason
}) {
  return (
    <GvlCard
      title={
        <IsolateNameHeading
          name={isolate.name}
          genusSpecies={isolate.genusSpecies}
          isolateNbr={isolate.isolateNbr}
        />
      }
    >
      <div>
        <Row gutter={8}>
          <Col span={12}>
            <IsolateExpirationVal expirationDate={isolate.expirationDate} />
          </Col>
          <Col span={12}>
            <IsolateVal
              name="isolate.isolation.date"
              val={moment(isolate.isolationDate).format('M/D/Y')}
            />
          </Col>
        </Row>
        {/* The expanded view is used for requesting releases to provide additional context on the lab/tissue */}
        {expandedView && (
          <React.Fragment>
            <br />
            <Row gutter={8}>
              <Col span={12}>
                <IsolateVal
                  name="isolate.diagnostic.laboratory"
                  val={isolate.diagnosticLab?.name}
                />
              </Col>
              <Col span={12}>
                <IsolateVal name="isolate.tissue.origin" val={isolate.tissue} />
              </Col>
            </Row>
            {releaseRequest && showReleaseReason && (
              <Row>
                <Col span={12}>
                  <IsolateVal
                    name="isolate.release.reason"
                    val={
                      releaseRequest.releaseReason === 'VACCINE_PRODUCTION'
                        ? intl.get('isolate.release.vaccine.only')
                        : 'Change of ownership'
                    }
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </div>
    </GvlCard>
  );
}

IsolateReducedDetailsCard.propTypes = {
  isolate: PropTypes.object.isRequired,
  releaseRequest: PropTypes.object,
  expandedView: PropTypes.bool,
  showReleaseReason: PropTypes.bool
};
