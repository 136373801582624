import Immutable from 'seamless-immutable';

export const FeaturesBySubscriptionType = Immutable.from({
  'Pharmaceutical Company': {
    ism: ['create', 'read', 'update', 'delete'],
    ismExtApr: ['full'],
    ismLab: ['read'],
    ismHst: ['read'],
    ismTag: ['full'],
    ismExt: ['read', 'delete'],
    ismRls: ['create', 'read', 'update'],
    ismRdOnly: ['read'],
    ismCsv: ['read'],
    ismDoc: ['full'],
    prmLab: ['read'],
    prmMgt: ['full'],
    nam: ['full'],
    namLab: ['full'],
    namStc: ['full'],
    namDoc: ['full'],
    namHrd: ['full'],
    namTag: ['full'],
    namNB: ['full'],
    nptCncLst: ['full']
  },
  Clinic: {
    ism: ['read'],
    ismHst: ['read'],
    ismTag: ['read'],
    ismExt: ['create', 'read', 'update'],
    ismExtSgn: ['full'],
    ismRls: ['read', 'update'],
    ismRlsApr: ['full'],
    ismRdOnly: ['read'],
    namCnc: ['full'],
    namSgn: ['full'],
    namHrd: ['full']
  },
  'Regulatory Authority': {
    namReg: ['full'],
    namRegApr: ['full'],
    namDoc: ['full']
  }
});

/**
 * A couple major limitations that need to be address when this is exposed by an API:
 * 1. Region-specific access to features (i.e. EIAs and Isolate Mgmt & Canadian users)
 * 2. Wholesale feature flagging wrt dev vs live environments (i.e. user selectors' isAutoVacEnabled)
 */
export const FeaturesByAccount = Immutable.from({
  Newport: {
    ism: ['create', 'read', 'update'],
    ismExtApr: ['full'],
    ismLab: ['read'],
    ismHst: ['read'],
    ismTag: ['full'],
    ismExt: ['read', 'delete'],
    ismRls: ['create', 'read', 'update'],
    ismRdOnly: ['read'],
    ismCsv: ['read'],
    ismDoc: ['full'],
    prmLab: ['read'],
    prmMgt: ['create', 'read', 'update'],
    nam: ['full'],
    namLab: ['create', 'read', 'update', 'delete'],
    namStc: ['update'],
    namDoc: ['full'],
    namHrd: ['full'],
    namTag: ['full'],
    namNB: ['full']
  }
  // Do we need a Ceva: {} entry here?
});

export const FeaturesByRole = Immutable.from({
  ROLE_ISOLATE_APPROVER: {
    ism: ['read'],
    ismLab: ['read'],
    ismExtApr: ['full'],
    ismExt: ['read', 'delete'],
    ismHst: ['read'],
    ismDoc: ['read'],
    prmLab: ['read'],
    namLab: ['read'],
    namTag: ['read'],
    namNB: ['read'],
    nptCncLst: ['read']
  },
  ROLE_ISOLATE_SUPPORT_MANAGER: {
    ism: ['read'],
    ismLab: ['read'],
    ismExt: ['read'],
    ismRls: ['read'],
    ismDoc: ['read'],
    prmLab: ['read'],
    prmMgt: ['read'],
    namLab: ['read'],
    ismCsv: ['read'],
    nptCncLst: ['read']
  },
  ROLE_ISOLATE_CREATOR: {
    ism: ['create', 'read', 'update', 'delete'],
    ismExtApr: ['full'],
    ismLab: ['read'],
    ismHst: ['read'],
    ismTag: ['full'],
    ismExt: ['read', 'delete'],
    ismRls: ['create', 'read', 'update'],
    ismCsv: ['read'],
    ismDoc: ['create', 'read', 'update', 'delete'],
    prmLab: ['read'],
    prmMgt: ['create', 'read', 'update', 'delete'],
    nam: ['create', 'read', 'update'],
    namLab: ['create', 'read', 'update', 'delete'],
    namStc: ['update'],
    namDoc: ['full'],
    namHrd: ['full'],
    namTag: ['full'],
    namNB: ['full'],
    nptCncLst: ['read']
  },
  ROLE_ISOLATE_QUALITY_ASSURANCE: {
    ism: ['read', 'update'],
    ismLab: ['read'],
    ismHst: ['read'],
    ismExt: ['read'],
    ismDoc: ['create', 'read', 'update', 'delete'],
    prmLab: ['read'],
    prmMgt: ['read'],
    namLab: ['read'],
    namTag: ['read'],
    namNB: ['read'],
    nptCncLst: ['read']
  },
  ROLE_ISOLATE_SUPPORT: {
    ism: ['read'],
    ismLab: ['read'],
    ismExt: ['read'],
    ismRls: ['read'],
    ismDoc: ['read'],
    prmLab: ['read'],
    prmMgt: ['read'],
    namLab: ['read'],
    namTag: ['read'],
    namNB: ['read'],
    nptCncLst: ['read']
  },
  ROLE_VET: {
    ism: ['read', 'update'],
    ismTag: ['read'],
    ismExt: ['create', 'read', 'update'],
    ismExtSgn: ['full'],
    ismHst: ['read'],
    ismRls: ['read', 'update'],
    ismRlsApr: ['full'],
    namCnc: ['read', 'update'],
    namSgn: ['full'],
    namHrd: ['full']
  },
  ROLE_VET_TECH: {
    ism: ['read'],
    ismTag: ['read'],
    ismExt: ['create', 'read', 'update'],
    ismHst: ['read'],
    ismRls: ['read', 'update'],
    namCnc: ['read', 'update'],
    namHrd: ['full']
  },
  ROLE_STATE_VET: {
    namReg: ['read', 'update', 'delete'],
    namRegApr: ['update'],
    namDoc: ['full']
  }
});
