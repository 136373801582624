import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './clinicActionTypes';

const initialState = Immutable.from({
  clinicsVets: {},
  isRefreshing: false
});

export const onIsRefreshing = (state, { isRefreshing }) =>
  Immutable.setIn(state, ['isRefreshing'], isRefreshing);

export const onListVetsForClinicSuccess = (state, { clinicId, vetsList }) => {
  let newState = state.asMutable({ deep: true });

  newState.clinicsVets = {
    ...newState.clinicsVets,
    [clinicId]: vetsList
  };

  return Immutable.from(newState);
};

export const clinicReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsRefreshing,
  [types.LIST_VETS_FOR_CLINIC_SUCCESS]: onListVetsForClinicSuccess
});
