export const ACTION_IN_PROGRESS = 'userRegistration/ACTION_IN_PROGRESS';

export const IS_EMAIL_DUPLICATE = 'userRegistration/IS_EMAIL_DUPLICATE';

export const LOAD_REGISTRATION_REQUEST =
  'userRegistration/LOAD_REGISTRATION_REQUEST';

export const SUBMIT_REGISTRATION = 'userRegistration/SUBMIT_REGISTRATION';
export const BATCH_SUBMIT_REGISTRATIONS =
  'userRegistration/BATCH_SUBMIT_REGISTRATIONS';
export const VERIFY_REGISTRATION = 'userRegistration/VERIFY_REGISTRATION';
export const RESEND_CONFIRMATION = 'userRegistration/RESEND_CONFIRMATION';
export const RENEW_VERIFICATION = 'userRegistration/RENEW_VERIFICATION';

export const HANDLE_VERIFICATION_STATUS =
  'userRegistration/HANDLE_VERIFICATION_STATUS';
export const SET_VERIFICATION_STATUS =
  'userRegistration/SET_VERIFICATION_STATUS';
