export const labTestResultOptions = [
  { value: 'Negative', text: 'negative' },
  { value: 'No test', text: 'noTest' },
  { value: 'Suspect', text: 'suspect' },
  { value: 'Positive', text: 'positive' },
  { value: 'Inconclusive', text: 'inconclusive' }
];

export const getAccessionNumber = (prefix, startingValue, index) => {
  const minSuffixLength = startingValue ? startingValue.length : 0;
  const suffix = startingValue ? `${parseInt(startingValue) + index}` : '';
  return `${prefix || ''}${suffix.padStart(minSuffixLength, '0')}`;
};

export const getFinalTestResult = test =>
  test ? test.nvslResult || test.result : null;

export const getBulkEditLabTestTypes = country =>
  country === 'Canada' ? ['ELISA'] : ['AGID', 'ELISA'];

export const labTestManufacturers = [
  'AgriLabso',
  'Bayer',
  'Biocor Inc.',
  'Boehringer Ingelheim',
  'Butler',
  'Centaur',
  'Colorado Serum',
  'Durvet',
  'Evsco',
  'Fort Dodge',
  'Grand Labs',
  'IDEXX',
  'In House',
  'Intervet Inc.',
  'Merck',
  'Merial',
  'Meridian',
  'Neogen',
  'NeoTech, LLC',
  'Novartis',
  'Other',
  'Pfizer Inc.',
  'Phoenix',
  'SafePath',
  'Schering-Plough',
  'Synantic',
  'Synbiotics',
  'VMRD',
  'Zoetis'
];
