import * as types from './contactActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = isRefreshing =>
  createAction(types.IS_REFRESHING, { isRefreshing });

// list
export const listRequest = () => createAction(types.LIST_REQUEST);
export const listSuccess = contacts =>
  createAction(types.LIST_SUCCESS, { contacts });
export const listFailure = () => createAction(types.LIST_FAILURE);

//save
export const saveRequest = (contact, callback) =>
  createAction(types.SAVE_REQUEST, { contact, callback });

// refresh
export const refreshRequest = (contactId, callback) =>
  createAction(types.REFRESH_REQUEST, { contactId, callback });
export const refreshSuccess = contact =>
  createAction(types.REFRESH_SUCCESS, { contact });
