import { Button, Upload, Icon } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import GvlIcon from 'elements/GvlIcon';
import { THEME } from 'style';
import { FadedText } from 'style/commonEmotions';
import intl from 'react-intl-universal';

export const SignatureUploader = ({
  onUpload,
  onUploadChange,
  onRemoveUpload,
  signatureImg
}) => (
  <>
    <Upload.Dragger
      accept="image/jpg, image/jpeg, image/png"
      customRequest={onUpload}
      onChange={onUploadChange}
      showUploadList={false}
      style={signatureImg ? { display: 'none' } : {}}
    >
      <p className="ant-upload-drag-icon">
        <GvlIcon type="upload" />
      </p>
      <p className="ant-upload-text" style={{ color: THEME.linkHover() }}>
        {intl.get('image.upload.area.signature')}
      </p>
    </Upload.Dragger>
    <p style={{marginBottom: "-10px", fontSize: '12px' }}>
    <FadedText>
      {intl.get('image.upload.accepted.signature')}
    </FadedText>
    </p>
    {signatureImg && (
      <div
        style={{
          border: THEME.mainBorderColor(),
          borderStyle: 'solid',
          borderWidth: '1px',
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        data-testid="signatureDisplay"
      >
        <span>
          <Icon
            id="imageIcon"
            type="file-image"
            style={{ paddingRight: '12px' }}
          />
          {signatureImg && signatureImg.file?.name}
        </span>
        <Button type="link" onClick={onRemoveUpload}>
          <GvlIcon
            type="close"
            style={{ color: THEME.red(), alignItems: 'center' }}
          />
        </Button>
      </div>
    )}
  </>
);

SignatureUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onUploadChange: PropTypes.func.isRequired,
  onRemoveUpload: PropTypes.func.isRequired,
  signatureImg: PropTypes.object
};
