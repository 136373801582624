import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGIN_VALIDATE_TOKEN_REQUEST,
  LOGIN_EXPIRED_TOKEN_REQUEST,
  LOGIN_FETCH_USER_LICENSE_AGREEMENT,
  LOGIN_ACCEPT_USER_LICENSE_AGREEMENT
} from 'containers/app/store/login/loginConstants';
import * as api from 'utils/api';
import {
  loginStarted,
  loginAccountDisabled,
  loginInvalid,
  loginTokenInvalid,
  loginExpiredTokenStarted,
  loginExpiredTokenSuccess,
  loginSuccess,
  logoutStarted,
  logoutSuccess,
  loginConnectionError,
  loginValidateTokenStarted,
  loginFetchUserLicenseAgreement,
  loginFetchUserLicenseAgreementSuccess
} from 'containers/app/store/login/loginActions';
import { BACKEND_BASENAME } from 'utils/config';

import {
  userUpdate,
  userReset,
  userAcceptLicenseAgreementSuccess
} from 'containers/app/store/user/userActions';
import { registrationReset } from 'containers/registration/store/registrationActions';
import { getUserCountry } from 'containers/app/store/user/userSelectors';
import { clinicRefreshVetsListSuccess } from 'containers/accounts/clinic/store/accountsClinicActions';
import { accountsReset } from 'containers/accounts/store/accountsActions';
import * as analytics from 'utils/analytics/analytics';
import { isAdminSupportUser } from 'utils/userUtil';

export const selectUserInfo = state => state.app.user.info;
export const selectUser = state => state.app.user;

export function* onLoginRequest(action) {
  yield put(userReset());
  yield put(loginStarted());

  try {
    const loginData = yield call(api.post, '/gettoken', {
      username: action.username,
      password: action.password,
      apiKey: 'GVL'
    });
    // fetch cookie from gvl auhentication
    // this should give us a valid cookie value that will allow users to view
    // their profiles and for suppor users to use the support pages
    yield call(api.legacyAuthenticate, {
      username: action.username,
      password: action.password
    });
    yield* handleValidUser(loginData);
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        yield put(loginInvalid());
      } else if (err.response.status === 403) {
        yield put(loginAccountDisabled());
      } else {
        yield put(loginConnectionError());
      }
    } else {
      yield put(loginConnectionError());
    }
  }
}

export function* onLoginValidateTokenRequest(action) {
  yield put(userReset());
  yield put(loginValidateTokenStarted());
  yield call(api.setAuthToken, action.authToken);
  try {
    const loginData = yield call(api.get, '/validatetoken');
    yield* handleValidUser(loginData);
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        yield put(loginTokenInvalid());
      } else {
        yield put(loginConnectionError());
      }
    } else {
      yield put(loginConnectionError());
    }
  }
}

function* handleValidUser(loginData) {
  const { access_token, username, roles } = loginData;
  yield call(api.setAuthToken, access_token);
  const info = yield call(api.get, '/user/info');
  yield put(userUpdate(access_token, username, roles, info));
  if (info.missingNecessaryLicenseAgreement) {
    yield put(loginFetchUserLicenseAgreement());
  }
  // If role is vet or vet_tech get list of vets and store in redux
  if (roles.indexOf('ROLE_VET') >= 0 || roles.indexOf('ROLE_VET_TECH') >= 0) {
    const vetsList = yield call(api.get, `/clinic/${info.clinicId}/vets`);
    yield put(clinicRefreshVetsListSuccess(vetsList));
  }
  // Since we are redirecting support users to gvl2/home, they don't need the token
  // based security till they ghost. By removing the token here we can stop the
  // situation where the cookie expires/delete and but the auth_token still exists.
  // When that happens a nasty login redirect loop happens as grails thinks the
  // user is not logged in but helen sees that they are.
  const user = yield select(selectUser);
  if (isAdminSupportUser(user)) {
    // if admin/support role send user to the /gvl2/home page which should send
    // them to the correct gvl2 controlpanel
    let reactAdmin = info.betaFeatures
      ? info.betaFeatures.includes('reactAdmin')
      : false;
    if (!reactAdmin) {
      yield put(userReset()); // yield doesn't sync fast enough
      localStorage.removeItem('user');
      window.location = BACKEND_BASENAME + '/home';
      return;
    }
  }
  // normal sign in process
  yield call(analytics.userSetup, info);
  yield put(loginSuccess());
}

export function* onLogoutRequest() {
  try {
    yield put(logoutStarted());

    const userInfo = yield select(selectUserInfo);
    if (userInfo.himsAccess) {
      try {
        yield call(api.post, '/sso/logout');
      } catch (err) {
        // Just catching this so we insure we always call killtoken
      }
    }
    yield call(api.post, '/killtoken');
    // to clean up backend login cookies and localStorage
    yield call(api.legacyLogout);
  } catch (err) {
    // We don't need to do anything with exceptions when logging out
  } finally {
    yield call(api.setAuthToken, null);
    yield call(analytics.userReset);
    yield put(userReset());
    yield put(accountsReset());
    yield put(registrationReset());
    yield put(logoutSuccess());
  }
}

// Use this when an API call finds that a token is expired/invalid
export function* onLoginExpiredTokenRequest() {
  yield put(loginExpiredTokenStarted());
  yield call(api.setAuthToken, null);
  yield put(userReset());
  yield put(accountsReset());
  yield put(loginExpiredTokenSuccess());
}

export function* onLoginFetchUserLicenseAgreement() {
  const country = yield select(getUserCountry);
  const language = yield select(state => state.language);

  const response = yield call(api.get, 'userLicense/active', {
    params: { country, locale: language.locale }
  });
  yield put(
    loginFetchUserLicenseAgreementSuccess(
      response.content,
      response.versionNumber,
      response.id
    )
  );
}

export function* onLoginAcceptUserLicenseAgreement() {
  const { versionNumber, id } = yield select(
    state => state.app.login.userLicenseAgreementInfo
  );
  yield call(
    api.post,
    '/user/agreeToLicense',
    `agree=true&licenseVersion=${versionNumber}&licenseId=${id}`
  );
  yield put(userAcceptLicenseAgreementSuccess());
}

export default function* appLoginSagas() {
  // Side-effect actions that are not handled in the reducer are handled here.
  // Watches for for the given actions and calls corresponding function.
  // By using `takeLatest` only the result of the latest action is applied to store.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOGIN_REQUEST, onLoginRequest);
  yield takeLatest(LOGOUT_REQUEST, onLogoutRequest);
  yield takeLatest(LOGIN_VALIDATE_TOKEN_REQUEST, onLoginValidateTokenRequest);
  yield takeLatest(LOGIN_EXPIRED_TOKEN_REQUEST, onLoginExpiredTokenRequest);
  yield takeLatest(
    LOGIN_FETCH_USER_LICENSE_AGREEMENT,
    onLoginFetchUserLicenseAgreement
  );
  yield takeLatest(
    LOGIN_ACCEPT_USER_LICENSE_AGREEMENT,
    onLoginAcceptUserLicenseAgreement
  );
}
