import Model from 'models/Model';
import {
  stringRequired,
  maxLength,
  isEmail,
  isPhoneNumber
} from 'utils/validation/common';
import * as api from 'utils/api';

export default class AccountRegistration extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      // Account and Clinic information
      name: {
        initialValue: undefined,
        validate: value =>
          stringRequired(value, 'required') || maxLength(value, 100)
      },
      // Relying on form field validation on the page (uses Location Model)
      locationAddress: {
        initialValue: undefined
      },
      billingAddress: {
        initialValue: undefined
      },
      fax: {
        initialValue: undefined,
        validate: value => (value ? isPhoneNumber(value) : null)
      },
      email: {
        initialValue: undefined,
        validate: value =>
          stringRequired(value, 'required') ||
          isEmail(value, 'validation.email.invalid') ||
          maxLength(value, 120)
      },
      ratePlanCode: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      promoPlanCode: {
        initialValue: undefined
      },
      sysFeatureSetName: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      token: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      }
    };
  }

  static get apiPath() {
    return 'registration/account';
  }

  static get domain() {
    return 'AccountRegistration';
  }

  submit = async userRegistration => {
    let data = this.toJson();
    if (userRegistration) {
      data.userRegistration = userRegistration.toJson();
    }
    return api.post('selfSignup', data);
  };

  static complete = async params => api.put('selfSignup/complete', params);

  static resume = async params => api.get('selfSignup/resume', params);

  static pricebooks = async () =>
    api.get(`${AccountRegistration.apiPath}/pricebooks`);

  static validateUsername = async params =>
    api.post(`${AccountRegistration.apiPath}/validateUsername`, params);

  static validateClinicEmail = async params =>
    api.post(`${AccountRegistration.apiPath}/validateEmail`, params);

  static sendInternalAnalyticsEvent = async params =>
    api.post(`${AccountRegistration.apiPath}/sendEvent`, params);
}
