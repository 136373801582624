import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Menu, Typography } from 'antd';
import intl from 'react-intl-universal';
import { FONT, THEME } from 'style';
import styled from '@emotion/styled';
import { MobileIcon } from 'style/commonEmotions';
import MobileAwareDropdown from 'elements/MobileAwareDropdown';

const { Text } = Typography;

/* istanbul ignore next */
const StyledButton = styled(Button)({
  margin: '0 8px',
  padding: '0 7px',
  height: '32px',
  color: THEME.text()
});

/* istanbul ignore next */
const StyledText = styled('span')({
  fontSize: FONT.size.medium,
  fontWeight: FONT.weight.semiBold,
  fontFamily: FONT.family.header,
  lineHeight: '16px',
  margin: 0
});

const generateMenu = menuActions => {
  // If the menuActions do not have an id, we should generate them.
  // This happens when we programmatically attach things to the list
  // ie: SmartEngine toggle on mobile.
  let content = menuActions.map((menuItem, index) => {
    if (!menuItem.id) {
      return (
        <Menu.Item key={menuItem.key}>
          <div>{menuItem}</div>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key={menuItem.id}
          onClick={menuItem.onClick}
          disabled={menuItem.disabled}
        >
          {menuItem.icon ? <Icon type={menuItem.icon} /> : null}
          <Text type={menuItem.type}>{menuItem.title}</Text>
        </Menu.Item>
      );
    }
  });
  return <Menu>{content}</Menu>;
};

export default function HeaderDropdownMenu({
  menuActions,
  viewMode,
  disabled,
  ...rest
}) {
  const menu = generateMenu(menuActions);
  return (
    <MobileAwareDropdown {...rest} overlay={menu} disabled={disabled}>
      {viewMode === 'Mobile' ? (
        <MobileIcon type="more" />
      ) : (
        <StyledButton size="small">
          <Icon type="more" />
          <StyledText>{intl.get('more')}</StyledText>
        </StyledButton>
      )}
    </MobileAwareDropdown>
  );
}

HeaderDropdownMenu.propTypes = {
  viewMode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  menuActions: PropTypes.array.isRequired
};

HeaderDropdownMenu.defaultProps = {
  menuActions: []
};
