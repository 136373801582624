import { Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import * as settingStorage from 'utils/settingStorage';

class OtherableSelectElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: ''
    };

    if (props.settingName) {
      if (this.props.settingAllowMultiple) {
        this.state.setting =
          settingStorage.getValue(this.generateStorageName()) || [];
      } else {
        this.state.setting = settingStorage.getValue(
          this.generateStorageName()
        );
      }
    }
  }

  static propTypes = {
    ...Select.propTypes,
    settingName: PropTypes.string, // This is not required, since some otherable values are not persisted settings
    settingAllowMultiple: PropTypes.bool,
    appUserId: PropTypes.number, // only used if settingAllowMultiple == true
    disabled: PropTypes.bool
  };
  static defaultProps = {
    options: [],
    settingAllowMultiple: false,
    appUserId: -1
  };

  generateStorageName = () => {
    // This naming matches what we had in Neptr
    return this.props.settingAllowMultiple
      ? `recent_${this.props.settingName}${this.props.appUserId}`
      : `${this.props.settingName}_recent`;
  };

  updateSettings = (prevState, value) => {
    if (this.props.settingAllowMultiple) {
      if (!prevState.setting.includes(value)) {
        const newSetting = prevState.setting.concat(value);
        settingStorage.setValue(this.generateStorageName(), newSetting);
        return { searchTerm: '', setting: newSetting };
      }
    } else {
      if (prevState.setting !== value) {
        settingStorage.setValue(this.generateStorageName(), value);
        return { searchTerm: '', setting: value };
      }
    }
  };

  onChange = (value, option) => {
    if (value && option.key === 'other' && this.props.settingName) {
      // User typed in a value and we need to store it
      this.setState(prevState => this.updateSettings(prevState, value));
    }

    if (this.props.onChange) {
      this.props.onChange(value, option);
    }
  };

  render() {
    const { searchTerm } = this.state;
    const { children, settingName, settingAllowMultiple, ...rest } = this.props;

    let settingsOptionGroup = null;
    if (
      settingName &&
      ((!settingAllowMultiple && this.state.setting) ||
        (settingAllowMultiple && this.state.setting.length > 0))
    ) {
      const settingArray = settingAllowMultiple
        ? this.state.setting
        : [this.state.setting];

      settingsOptionGroup = (
        <Select.OptGroup
          label={intl.get(
            settingAllowMultiple ? 'customValues' : 'recentCustomValue'
          )}
          key="settings"
        >
          {settingArray.map(customVal => {
            return (
              <Select.Option key={customVal} value={customVal}>
                {customVal}
              </Select.Option>
            );
          })}
        </Select.OptGroup>
      );
    }

    return (
      <Select
        showSearch
        showAction={['focus', 'click']}
        onSearch={newSearchTerm => this.setState({ searchTerm: newSearchTerm })}
        {...rest}
        onChange={this.onChange}
      >
        {settingsOptionGroup}
        {children}
        <Select.OptGroup label={intl.get('other.startTyping')} key="other">
          <Select.Option key={'other'} value={searchTerm || ''}>
            {searchTerm}
          </Select.Option>
        </Select.OptGroup>
      </Select>
    );
  }
}

// NOTE: the defensive style setter here is only necessary for the sake of Storybook and we should solve this in a better way
// TODO: solve sub component elements with connect wrappers for Storybook.
const mapStateToProps = ({ app }) => ({
  appUserId: app.user.info ? app.user.info.appUserId : undefined
});

const OtherableSelect = connect(mapStateToProps, null, null, {
  forwardRef: true
})(OtherableSelectElement);
export { OtherableSelect as default, OtherableSelectElement };
