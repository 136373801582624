import * as actions from './contactActions';
import * as types from './contactActionTypes';
import { Contact } from 'modules/Contact/contactIndex';
import { put, call, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';

export function* onContactListRequest() {
  try {
    yield put(actions.isRefreshing(true));
    const contacts = yield call(Contact.list);
    yield put(actions.listSuccess(contacts));
  } catch (err) {
    yield put(apiFailure(err, 'Error loading Contact data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onRefreshRequest({ contactId, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    const contact = yield call(Contact.read, contactId);
    yield put(actions.refreshSuccess(contact));
    if (callback) {
      callback(contact);
    }
  } catch (err) {
    yield put(apiFailure(err, 'Error loading contact data'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onContactSaveRequest({ contact, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([contact, contact.save]);
    yield put(actions.refreshSuccess(contact));
    if (typeof callback === 'function') {
      callback(contact);
    }
  } catch (err) {
    yield put(apiFailure(err, 'Error saving contact data'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* contactSagas() {
  yield takeLatest(types.LIST_REQUEST, onContactListRequest);
  yield takeLatest(types.SAVE_REQUEST, onContactSaveRequest);
  yield takeLatest(types.REFRESH_REQUEST, onRefreshRequest);
}
