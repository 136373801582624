import intl from 'react-intl-universal';
import { loadScript } from 'utils/externalScript';

// TODO: discuss ways to approach e2e testing of the methods in this utils file.
// Jest does not play nicely with canvas (specifically the context),
// and the workaround I found (i.e. jest-mock-canvas) mocked too much to give us a useful test.

// The convertToBase64 method is similar, where too much of FileReader would require mocking

const generateImgFromText = (text, font) => {
  const canvas = document.createElement('canvas');
  const canvasCtx = canvas.getContext('2d');
  const DEFAULT_FONT_SIZE = 96;

  canvasCtx.font = `${DEFAULT_FONT_SIZE}px ${font}`;

  const textMeasurements = canvasCtx.measureText(text);
  const newCanvasHeight =
    textMeasurements.fontBoundingBoxAscent +
    textMeasurements.fontBoundingBoxDescent;
  const newCanvasWidth =
    textMeasurements.actualBoundingBoxLeft +
    textMeasurements.actualBoundingBoxRight +
    1;

  canvasCtx.canvas.width = newCanvasWidth;
  canvasCtx.canvas.height = newCanvasHeight;

  // Need to reset font after resetting width and height. /shrug
  canvasCtx.font = `${DEFAULT_FONT_SIZE}px ${font}`;
  canvasCtx.fillText(
    text,
    textMeasurements.actualBoundingBoxLeft,
    textMeasurements.fontBoundingBoxAscent
  );

  return canvas.toDataURL('image/png', 1.0);
};

const convertToBase64 = info => {
  if (info && info.file.type?.match('image.*')) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException(intl.get('signature.parsing.exception')));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(info.file);
    });
  } else {
    throw new Error(intl.get('signature.upload.exception'));
  }
};

const loadSignatureFonts = async () => {
  await loadScript(
    'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'
  ).catch(
    /* istanbul ignore next */
    error => console.log('Unable to load google webfont external js', error)
  );
  if (!window.WebFont) return;
  window.WebFont.load({
    google: {
      families: [
        'Homemade Apple',
        'Satisfy',
        'Dancing Script',
        'Mr Dafoe',
        'Dawning of a New Day'
      ]
    }
  });
};

export { loadSignatureFonts, convertToBase64, generateImgFromText };
