import Model from 'models/Model';
import { downloadFile } from 'utils/downloadUtil';
import * as api from 'utils/api';
import { maxLength, stringRequired, isDate } from 'utils/validation/common';

export default class UploadedDocument extends Model {
  constructor(data) {
    super(data);

    // Always use type from child class
    this.type = this.constructor.docType;
  }

  // Instance Methods

  validate() {
    const results = super.validate();

    // Put complex validations here.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Document Sign', {messageId: 'document.sign.invalidUser'}));

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      drug: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 120);
        }
      },
      effectiveDate: {
        initialValue: undefined,
        validate: value => {
          return isDate(value, 'MM/DD/YYYY', 'required');
        }
      },
      expirationDate: {
        initialValue: undefined,
        validate: value => {
          return isDate(value, 'MM/DD/YYYY', 'required');
        }
      },
      type: {
        initialValue: ''
      },
      headCount: {
        initialValue: null
      },
      id: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required');
        }
      },
      owner: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 50);
        }
      },
      species: {
        initialValue: '',
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 50);
        }
      },
      vet: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 50);
        }
      },
      feedMill: {
        initialValue: '',
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 50);
        }
      },
      millReferenceNumber: {
        initialValue: ''
      },
      referenceNumber: {
        initialValue: null
      },
      shipments: {
        initialValue: []
      },
      status: {
        initialValue: ''
      },
      metadata: {
        shipmentsInfo: {
          initialValue: []
        }
      }
    };
  }

  static get apiPath() {
    return 'uploadedDocuments';
  }

  static downloadDocument = async id =>
    downloadFile(`${this.apiPath}/download/${id}`);

  static getUploadedDocument = async params => {
    const data = await super.list(params);
    return data.results;
  };

  static deactivateUploadedDocument = async (id, deactivationReason) => {
    return await api.post(`${this.apiPath}/deactivate/${id}`, {
      inactiveReason: deactivationReason
    });
  };

  static deleteUploadedDocument = async id => {
    await super.deleteRec(id);
  };

  static saveLogShipment = async (id, shipmentData, actionType) => {
    return await api.put(
      `${this.apiPath}/${id}?protectedAction=${actionType}`,
      shipmentData
    );
  };

  static async uploadCertificate(docUpload) {
    const formData = new FormData();
    const entries = Object.entries(docUpload);
    for (let index = 0; index < entries.length; index++) {
      const key = entries[index][0];
      const value = entries[index][1];
      formData.append(key, value);
    }
    return await api.postFile(`${this.apiPath}/create`, formData);
  }

  static async updateCertificate(document) {
    return await api.put(`/${this.apiPath}/${document.id}`, document);
  }

  static async getAll() {
    const results = await super.list({
      limit: 0,
      status: true
    });
    return results;
  }
}
