import { Link } from 'react-router-dom';
import { Button, Col, Divider, Menu, Row, Form, Select, Table } from 'antd';
import isPropValid from '@emotion/is-prop-valid';
import { isMobile } from 'react-device-detect';
import styled from '@emotion/styled';
import GvlIcon from 'elements/GvlIcon';
import { FONT, THEME } from 'style';

// TODO: we should just use utility css class .float-r, .float-l
export const ButtonRight = styled(Button)({
  float: 'right'
});

export const ButtonLeft = styled(Button)({
  float: 'left'
});
// should just use utility css class .pad-1space-left
export const CloseButton = styled(Button)({
  marginRight: '8px'
});

// TODO: we should just use utility css class .flex-center
export const FlexDiv = styled.div({
  display: 'flex',
  alignItems: 'center'
});

// NOTE: use .semibold utility css class
export const SemiboldDiv = styled.div({
  fontWeight: FONT.weight.semiBold,
  whiteSpace: 'normal',
  textOverflow: 'ellipsis'
});
// should just use utility css class .semibold
export const HeadingBold = styled('h5')({
  fontWeight: FONT.weight.semiBold
});
export const HeadingBoldEllipsis = styled('h5')({
  fontWeight: FONT.weight.semiBold,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});
// probably should just use AntD <Text disabled>
// also utility css class .subtext is an option
export const SubtextDiv = styled.div({
  color: THEME.disabledText(),
  whiteSpace: 'normal',
  textOverflow: 'ellipsis'
});

export const SubtextItalicDiv = styled.div({
  color: THEME.disabledText(),
  fontStyle: 'italic'
});

// if you don't want separation maybe just a <br />
// .no-vertical-margins is another option
export const InlineP = styled.p({
  marginBottom: 0
});

export const SecondaryLink = styled.a({
  color: THEME.secondaryText()
});

export const WarningDiv = styled.div({
  color: THEME.red()
});

export const SemiboldWarningDiv = styled(SemiboldDiv)({
  color: THEME.red()
});

export const GvlMenu = styled(Menu, { shouldForwardProp: isPropValid })({
  maxWidth: '253px',
  borderRight: 'unset',
  paddingBottom: '130px',
  maxHeight: 'calc(100vh - 260px)',
  overflowX: 'hidden',
  overflowY: 'auto'
});

// TODO: encase nested button styles inside of this commonEmotion
/*
    '& .ant-button:not(:last-child)': {
      marginRight: '8px'
    },
    '& .ant-button:not(:first-child)': {
      marginLeft: '8px'
    }
*/
export const DrawerToolbar = styled(Row)(
  isMobile
    ? {
        position: 'fixed',
        right: 0,
        width: THEME.drawerWidth
      }
    : { position: 'absolute', left: 0, width: '100%' },
  {
    borderTop: `1px solid ${THEME.mainBorderColor()}`,
    padding: '10px 16px',
    background: THEME.white(),
    textAlign: 'right',
    bottom: 0,
    zIndex: 10
  }
);

export const DrawerContent = styled('div')({
  paddingBottom: '37px'
});

export const DrawerRow = styled(Row)({
  padding: '0 32px'
});

export const DrawerBottomRow = styled(Row)({
  padding: '0 32px 32px 32px'
});

export const DrawerTableDiv = styled.div({
  padding: '0 32px 32px 32px'
});

export const TableBorder = styled.div({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: THEME.mainBorderColor()
});

export const FormWithMargin = styled(Form, { shouldForwardProp: isPropValid })({
  marginTop: 0,
  marginBottom: 48,
  marginLeft: 48,
  marginRight: 48
});

export const H5WithoutTopMargin = styled.h5({ marginTop: '0' });

export const FadedText = styled.span({
  color: THEME.tabText()
});

export const BoldSpan = styled.span({ fontWeight: FONT.weight.bold });

export const FormItemLeftCol = styled(Form.Item)({
  marginRight: 0
});

export const FormItemRightCol = styled(Form.Item)({
  marginLeft: 0
});

export const FormItemNoMargin = styled(Form.Item)({
  margin: '0 !important'
});

export const DrawerFormItemLeft = styled(FormItemLeftCol)({
  marginLeft: '32px'
});

export const DrawerFormItemRight = styled(FormItemRightCol)({
  marginRight: '32px'
});

export const DrawerFormItem = styled(Form.Item)({
  margin: '0 32px 24px'
});

// NOTE: just use utility css class .flex1-full-width
export const FullWidthSelect = styled(Select)({
  width: '100%',
  flexGrow: 1
});

export const BorderedTable = styled(Table)({
  border: `1px solid ${THEME.mainBorderColor()}`
});
// NOTE: seems to overlap quite a bit with DrawerToolbar...
export const DrawerBottomBar = styled.div(
  isMobile
    ? {
        position: 'fixed',
        right: 0,
        width: THEME.drawerWidth
      }
    : {
        position: 'absolute',
        left: 0,
        width: '100%'
      },
  {
    padding: '10px 16px',
    background: THEME.white(),
    borderTop: '1px solid' + THEME.mainBorderColor(),
    bottom: 0,
    zIndex: 10
  }
);

export const WideText = styled.p({
  marginTop: '8px !important',
  maxWidth: '28rem'
});

export const WideTextDivider = styled(Divider)({
  marginTop: '0',
  marginBottom: '0',
  maxWidth: '28rem',
  minWidth: 'auto'
});

export const FullRowCol = styled(Col)({
  paddingTop: '14px',
  paddingLeft: '16px'
});
/* istanbul ignore next */
export const LeftCol = styled(Col)({
  paddingLeft: '16px'
});

export const VerticalDivider = styled(Divider)({
  marginLeft: '8px',
  marginRight: '8px',
  backgroundColor: THEME.dividerColor()
});

/* istanbul ignore next */
export const RightCol = styled(Col)({
  textAlign: 'right',
  paddingRight: '16px',
  marginTop: '-4px' // Lines up with Animal Name
});

export const InlineDivider = styled(Divider)({
  top: '0.1em',
  fontSize: '16px',
  margin: '0 8px'
});

export const BorderedDiv = styled.div({
  width: '100%',
  border: THEME.mainBorderColor(),
  borderStyle: 'solid',
  borderWidth: '1px',
  margin: 'auto'
});

export const HorizontalDivider = styled.hr({
  borderWidth: '0.1px',
  color: THEME.mainBorderColor()
});

// TODO: This should be the styling for h4.  Once h4 is updated, remove this and use h4 in its place
export const MainMessage = styled('span')({
  fontSize: FONT.size.large,
  fontWeight: FONT.weight.semiBold
});
/* istanbul ignore next */
export const InlineButtonLink = styled(Button)({
  fontFamily: `${FONT.family.regular} !important`, // "Open Sans", sans-serif
  fontWeight: `${FONT.weight.semiBold} !important`, // 600
  fontSize: FONT.size.small, // 14px
  height: 'unset', // 18.6?
  padding: 'unset'
});
InlineButtonLink.__ANT_BUTTON = true; //ant-d has an issue when a disabled button is wrapped in a tool-tip this line fixes it.

/* istanbul ignore next */
export const InlineActionButton = styled(Button)({
  fontSize: FONT.size.xlarge + ' !important',
  verticalAlign: 'middle',
  padding: 0
});
/* istanbul ignore next */
export const StyledInlineButtonLink = styled(InlineButtonLink)({
  float: 'right',
  marginTop: '12px'
});

export const ButtonLink = styled(Button)({
  fontFamily: `${FONT.family.regular} !important`, // "Open Sans", sans-serif
  fontWeight: `${FONT.weight.semiBold} !important`, // 600
  fontSize: FONT.size.small, // 14px
  display: 'block'
});
ButtonLink.__ANT_BUTTON = true; //ant-d has an issue when a disabled button is wrapped in a tool-tip this line fixes it.

export const DropdownButtonLink = styled(Button)({
  fontFamily: `${FONT.family.regular} !important`, // "Open Sans", sans-serif
  fontWeight: `${FONT.weight.semiBold} !important`, // 600
  fontSize: FONT.size.small, // 14px
  height: 'unset', // 18.6?
  padding: '0 .75em 0 0'
});

export const NewInlineShowRow = styled(Row)({
  backgroundColor: THEME.white(),
  border: '1px solid',
  borderColor: THEME.mainBorderColor(),
  padding: '16px 0', // Need to use 0 for left and right since divider line spans the whole width
  margin: '8px 0'
});

export const ShowRow = styled(Row)({
  padding: '4px 0px',
  alignItems: 'center',
  borderBottom: '1px solid',
  borderBottomColor: THEME.lightBorderColor()
});

// TODO: just use a utility css class .r-text
export const ColRight = styled(Col)({
  textAlign: 'right'
});

export const MobileIcon = styled(GvlIcon)({
  padding: '10px',
  margin: '2px',
  fontSize: '24px',
  color: THEME.text()
});

export const StyledLink = styled(Link)({
  fontWeight: FONT.weight.semiBold,
  whiteSpace: 'normal'
});

export const commonOnCell = () => {
  return {
    style: {
      whiteSpace: 'normal'
    }
  };
};
