export const CLINIC_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_LOAD_STARTUP_DATA_REQUEST';
export const CLINIC_LOAD_STARTUP_DATA_STARTED =
  'cricket/Accounts/Clinic/CLINIC_LOAD_STARTUP_DATA_STARTED';
export const CLINIC_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_LOAD_STARTUP_DATA_SUCCESS';
export const CLINIC_LOAD_STARTUP_DATA_FINISHED =
  'cricket/Accounts/Clinic/CLINIC_LOAD_STARTUP_DATA_FINISHED';

export const CLINIC_REFRESH_EIA_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_LIST_REQUEST';
export const CLINIC_REFRESH_EIA_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_LIST_STARTED';
export const CLINIC_REFRESH_EIA_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_LIST_SUCCESS';
export const CLINIC_REFRESH_EIA_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_LIST_FAILURE';
export const CLINIC_ACTIVATE_EIA =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_EIA';
export const CLINIC_DEACTIVATE_EIA =
  'cricket/Accounts/Clinic/CLINIC_DEACTIVATE_EIA';

export const CLINIC_REFRESH_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_REQUEST';
export const CLINIC_REFRESH_EIA_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_STARTED';
export const CLINIC_REFRESH_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_SUCCESS';
export const CLINIC_REFRESH_EIA_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EIA_FAILURE';

export const CLINIC_ARCHIVE_EIAS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_EIAS_REQUEST';
export const CLINIC_DELETE_EIAS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_EIAS_REQUEST';
export const CLINIC_EIA_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_EIA_LIST_READY';

export const CLINIC_SAVE_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_EIA_REQUEST';
export const CLINIC_SAVE_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SAVE_EIA_SUCCESS';
export const CLINIC_SAVE_EIA_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SAVE_EIA_FAILURE';
export const CLINIC_SIGN_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SIGN_EIA_REQUEST';
export const CLINIC_SIGN_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SIGN_EIA_SUCCESS';
export const CLINIC_SIGN_EIA_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SIGN_EIA_FAILURE';
export const CLINIC_BATCH_SIGN_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_EIA_REQUEST';
export const CLINIC_BATCH_SIGN_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_EIA_SUCCESS';
export const CLINIC_BATCH_SIGN_EIA_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_EIA_FAILURE';
export const CLINIC_CREATE_CVI_FROM_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_CREATE_CVI_FROM_EIA_REQUEST';
export const CLINIC_CREATE_CVI_FROM_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_CREATE_CVI_FROM_EIA_SUCCESS';
export const CLINIC_CREATE_CVI_FROM_EIA_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_CREATE_CVI_FROM_EIA_FAILURE';
export const CLINIC_PROCESS_EIAS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_PROCESS_EIAS_REQUEST';
export const CLINIC_REMOVE_EIA_FROM_STORE =
  'cricket/Accounts/Clinic/CLINIC_REMOVE_EIA_FROM_STORE';
export const CLINIC_AUTO_ARCHIVE_EIA_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_AUTO_ARCHIVE_EIA_REQUEST';
export const CLINIC_AUTO_ARCHIVE_EIA_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_AUTO_ARCHIVE_EIA_SUCCESS';

export const CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST';
export const CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS';
export const CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED =
  'cricket/Accounts/Clinic/CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED';

export const CLINIC_REFRESH_CONTACT_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_LIST_REQUEST';
export const CLINIC_REFRESH_CONTACT_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_LIST_STARTED';
export const CLINIC_REFRESH_CONTACT_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_LIST_SUCCESS';
export const CLINIC_REFRESH_CONTACT_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_LIST_FAILURE';
export const CLINIC_REFRESH_CONTACT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_REQUEST';
export const CLINIC_REFRESH_CONTACT_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CONTACT_SUCCESS';

export const CLINIC_REFRESH_CVI_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_LIST_REQUEST';
export const CLINIC_REFRESH_CVI_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_LIST_STARTED';
export const CLINIC_REFRESH_CVI_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_LIST_SUCCESS';
export const CLINIC_REFRESH_CVI_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_LIST_FAILURE';

export const CLINIC_ACTIVATE_CVI =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_CVI';
export const CLINIC_DEACTIVATE_CVI =
  'cricket/Accounts/Clinic/CLINIC_DEACTIVATE_CVI';

export const CLINIC_REFRESH_CVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_REQUEST';
export const CLINIC_REFRESH_CVI_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_STARTED';
export const CLINIC_REFRESH_CVI_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_SUCCESS';
export const CLINIC_REFRESH_CVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_CVI_FAILURE';
export const CLINIC_SAVE_CVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_CVI_REQUEST';

export const CLINIC_CVI_ADD_PERMIT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_CVI_ADD_PERMIT_REQUEST';
export const CLINIC_CVI_ADD_PERMIT_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_CVI_ADD_PERMIT_SUCCESS';
export const CLINIC_SIGN_CVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SIGN_CVI_REQUEST';
export const CLINIC_SIGN_CVI_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SIGN_CVI_SUCCESS';
export const CLINIC_SIGN_CVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SIGN_CVI_FAILURE';
export const CLINIC_BATCH_SIGN_CVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_CVI_REQUEST';
export const CLINIC_BATCH_SIGN_CVI_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_CVI_SUCCESS';
export const CLINIC_BATCH_SIGN_CVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_CVI_FAILURE';
export const CLINIC_DELETE_CVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CVI_REQUEST';
export const CLINIC_DELETE_CVI_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CVI_SUCCESS';
export const CLINIC_DELETE_CVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CVI_FAILURE';
export const CLINIC_ARCHIVE_CVIS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_CVIS_REQUEST';
export const CLINIC_DELETE_CVIS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CVIS_REQUEST';
export const CLINIC_CVI_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_CVI_LIST_READY';

// EECVIs
export const CLINIC_REFRESH_EECVI_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_LIST_REQUEST';
export const CLINIC_REFRESH_EECVI_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_LIST_STARTED';
export const CLINIC_REFRESH_EECVI_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_LIST_SUCCESS';
export const CLINIC_REFRESH_EECVI_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_LIST_FAILURE';
export const CLINIC_ACTIVATE_EECVI =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_EECVI';
export const CLINIC_DEACTIVATE_EECVI =
  'cricket/Accounts/Clinic/CLINIC_DEACTIVATE_EECVI';
export const CLINIC_REFRESH_EECVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_REQUEST';
export const CLINIC_REFRESH_EECVI_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_STARTED';
export const CLINIC_REFRESH_EECVI_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_SUCCESS';
export const CLINIC_REFRESH_EECVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_EECVI_FAILURE';
export const CLINIC_ARCHIVE_EECVIS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_EECVIS_REQUEST';
export const CLINIC_DELETE_EECVIS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_EECVIS_REQUEST';
export const CLINIC_SAVE_EECVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_EECVI_REQUEST';
export const CLINIC_COMMIT_EECVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_COMMIT_EECVI_REQUEST';
export const CLINIC_COMMIT_EECVI_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_COMMIT_EECVI_FAILURE';
export const CLINIC_BATCH_COMMIT_EECVI_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_COMMIT_EECVI_REQUEST';
export const CLINIC_EECVI_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_EECVI_LIST_READY';

export const CLINIC_REFRESH_VETS_LIST_SUCCESS =
  'cricket/Accounts/Clinic/VETS_LIST_UPDATE_SUCCESS';

// VFDS Constant
export const CLINIC_ARCHIVE_VFDS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_VFDS_REQUEST';
export const CLINIC_DELETE_VFDS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_VFDS_REQUEST';
export const CLINIC_REFRESH_VFDS_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VDFS_LIST_REQUEST';
export const CLINIC_REFRESH_VFDS_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFDS_LIST_STARTED';
export const CLINIC_REFRESH_VFDS_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFDS_LIST_SUCCESS';
export const CLINIC_REFRESH_VFDS_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFDS_LIST_FAILURE';
export const CLINIC_ACTIVATE_VFD =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_VFD';
export const CLINIC_DEACTIVATE_VFD =
  'cricket/Accounts/Clinic/CLINIC_DEACTIVATE_VFD';
export const CLINIC_REFRESH_VFD_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFD_REQUEST';
export const CLINIC_REFRESH_VFD_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFD_STARTED';
export const CLINIC_REFRESH_VFD_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_VFD_FAILURE';
export const CLINIC_SAVE_VFD_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_VFD_REQUEST';
export const CLINIC_SAVE_VFD_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SAVE_VFD_SUCCESS';
export const CLINIC_SAVE_VFD_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SAVE_VFD_FAILURE';
export const CLINIC_PROCESS_VFDS_RENEWAL_REJECT =
  'cricket/Accounts/Clinic/CLINIC_PROCESS_VFDS_RENEWAL_REJECT';
export const CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT =
  'cricket/Accounts/Clinic/CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT';
export const CLINIC_PROCESS_VFD_RENEWAL_REVIEW =
  'cricket/Accounts/Clinic/CLINIC_PROCESS_VFD_RENEWAL_REVIEW';
export const CLINIC_UPDATE_VFD_IN_STORE =
  'cricket/Accounts/Clinic/CLINIC_UPDATE_VFD_IN_STORE';
export const CLINIC_SIGN_VFD_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SIGN_VFD_REQUEST';
export const CLINIC_PROCESS_VFDS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_PROCESS_VFDS_REQUEST';
export const CLINIC_VFD_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_VFD_LIST_READY';

// IHC
export const CLINIC_REFRESH_IHC_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_LIST_REQUEST';
export const CLINIC_REFRESH_IHC_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_LIST_STARTED';
export const CLINIC_REFRESH_IHC_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_LIST_SUCCESS';
export const CLINIC_REFRESH_IHC_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_LIST_FAILURE';
export const CLINIC_IHC_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_IHC_LIST_READY';
export const CLINIC_IHC_REMOVED_FROM_LIST =
  'cricket/Accounts/Clinic/CLINIC_IHC_REMOVED_FROM_LIST';
export const CLINIC_ACTIVATE_IHC =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_IHC';
export const CLINIC_REFRESH_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_REQUEST';
export const CLINIC_REFRESH_IHC_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_STARTED';
export const CLINIC_REFRESH_IHC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_SUCCESS';
export const CLINIC_REFRESH_IHC_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IHC_FAILURE';
export const CLINIC_SAVE_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_IHC_REQUEST';
export const CLINIC_SIGN_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SIGN_IHC_REQUEST';
export const CLINIC_SIGN_IHC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SIGN_IHC_SUCCESS';
export const CLINIC_SIGN_IHC_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SIGN_IHC_FAILURE';
export const CLINIC_DELETE_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_IHC_REQUEST';
export const CLINIC_CREATE_CVI_FROM_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_CREATE_CVI_FROM_IHC_REQUEST';
export const CLINIC_BATCH_DELETE_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_DELETE_IHC_REQUEST';
export const CLINIC_DELETE_IHC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_DELETE_IHC_SUCCESS';
export const CLINIC_DELETE_IHC_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_DELETE_IHC_FAILURE';
export const CLINIC_BATCH_SIGN_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_IHC_REQUEST';
export const CLINIC_BATCH_SIGN_IHC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_IHC_SUCCESS';
export const CLINIC_BATCH_SIGN_IHC_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_IHC_FAILURE';
export const CLINIC_ARCHIVE_IHC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_IHC_REQUEST';

// Rabies
export const CLINIC_REFRESH_RABIES_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_LIST_REQUEST';
export const CLINIC_REFRESH_RABIES_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_LIST_STARTED';
export const CLINIC_REFRESH_RABIES_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_LIST_SUCCESS';
export const CLINIC_REFRESH_RABIES_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_LIST_FAILURE';
export const CLINIC_RABIES_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_RABIES_LIST_READY';
export const CLINIC_ACTIVATE_RABIES =
  'cricket/Accounts/Clinic/CLINIC_ACTIVATE_RABIES';
export const CLINIC_REFRESH_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_REQUEST';
export const CLINIC_REFRESH_RABIES_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_STARTED';
export const CLINIC_REFRESH_RABIES_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_SUCCESS';
export const CLINIC_REFRESH_RABIES_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_RABIES_FAILURE';
export const CLINIC_SAVE_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SAVE_RABIES_REQUEST';
export const CLINIC_SIGN_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SIGN_RABIES_REQUEST';
export const CLINIC_SIGN_RABIES_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SIGN_RABIES_FAILURE';
export const CLINIC_DELETE_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_RABIES_REQUEST';
export const CLINIC_BATCH_DELETE_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_DELETE_RABIES_REQUEST';
export const CLINIC_DELETE_RABIES_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_DELETE_RABIES_SUCCESS';
export const CLINIC_DELETE_RABIES_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_DELETE_RABIES_FAILURE';
export const CLINIC_BATCH_SIGN_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_RABIES_REQUEST';
export const CLINIC_SIGN_RABIES_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SIGN_RABIES_SUCCESS';
export const CLINIC_BATCH_SIGN_RABIES_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_RABIES_SUCCESS';
export const CLINIC_BATCH_SIGN_RABIES_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_BATCH_SIGN_RABIES_FAILURE';
export const CLINIC_ARCHIVE_RABIES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ARCHIVE_RABIES_REQUEST';

// Generic Document
export const CLINIC_CLEAR_DOCUMENT_ERRORS =
  'cricket/Accounts/Clinic/CLINIC_CLEAR_DOCUMENT_ERRORS';
export const CLINIC_VOID_DOCUMENT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_VOID_DOCUMENT_REQUEST';
export const CLINIC_VOID_DOCUMENT_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_VOID_DOCUMENT_SUCCESS';
export const CLINIC_VOID_DOCUMENT_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_VOID_DOCUMENT_FAILURE';
export const CLINIC_SAVE_DOC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SAVE_DOC_SUCCESS';
export const CLINIC_REFRESH_DOC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_DOC_SUCCESS';
export const CLINIC_REMOVE_DOC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REMOVE_DOC_SUCCESS';
export const CLINIC_LOCK_DOC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_LOCK_DOC_REQUEST';
export const CLINIC_UNLOCK_DOC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_UNLOCK_DOC_REQUEST';
export const CLINIC_DOC_REQUEST_DONE =
  'cricket/Accounts/Clinic/CLINIC_DOC_REQUEST_DONE';
export const CLINIC_COPY_DOC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_COPY_DOC_REQUEST';

//Animal
export const CLINIC_REFRESH_ANIMAL_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_LIST_REQUEST';
export const CLINIC_REFRESH_ANIMAL_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_STARTED';
export const CLINIC_REFRESH_ANIMAL_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_LIST_SUCCESS';
export const CLINIC_REFRESH_ANIMAL_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_FAILURE';
export const CLINIC_VERBOSE_ANIMAL_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_VERBOSE_ANIMAL_LIST_REQUEST';
export const CLINIC_VERBOSE_ANIMAL_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_VERBOSE_ANIMAL_LIST_SUCCESS';
export const CLINIC_REFRESH_ANIMAL_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_REQUEST';
export const CLINIC_REFRESH_ANIMAL_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_SUCCESS';
export const CLINIC_ANIMAL_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_LIST_READY';
export const CLINIC_ANIMAL_REMOVED_FROM_LIST =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_REMOVED_FROM_LIST';
export const CLINIC_DELETE_ANIMAL_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_ANIMAL_REQUEST';
export const CLINIC_DELETE_ANIMAL_STARTED =
  'cricket/Accounts/Clinic/CLINIC_DELETE_ANIMAL_STARTED';
export const CLINIC_DELETE_ANIMAL_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_DELETE_ANIMAL_SUCCESS';
export const CLINIC_DELETE_ANIMAL_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_ANIMAL_FAILURE';
export const CLINIC_ANIMAL_UPDATED_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_UPDATED_SUCCESS';

export const CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST';
export const CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST';

//Image
export const CLINIC_REFRESH_IMAGE_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IMAGE_LIST_REQUEST';
export const CLINIC_REFRESH_IMAGE_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IMAGE_LIST_STARTED';
export const CLINIC_REFRESH_IMAGE_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IMAGE_LIST_SUCCESS';
export const CLINIC_REFRESH_IMAGE_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_IMAGE_LIST_FAILURE';

export const CLINIC_IMAGE_UPDATE_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_IMAGE_UPDATE_REQUEST';
export const CLINIC_IMAGE_UPDATE_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_IMAGE_UPDATE_SUCCESS';
export const CLINIC_IMAGE_DELETE_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_IMAGE_DELETE_REQUEST';
export const CLINIC_IMAGE_DELETE_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_IMAGE_DELETE_SUCCESS';

// Document model collections
export const CLINIC_RESET_DOCUMENT_MODELS =
  'cricket/Accounts/Clinic/CLINIC_RESET_DOCUMENT_MODELS';
export const CLINIC_GET_CONTACT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_GET_CONTACT_REQUEST';
export const CLINIC_GET_PREMISES_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_GET_PREMISES_REQUEST';
export const CLINIC_CONTACT_UPDATE =
  'cricket/Accounts/Clinic/CLINIC_CONTACT_UPDATE';
export const CLINIC_PREMISES_UPDATE =
  'cricket/Accounts/Clinic/CLINIC_PREMISES_UPDATE';
export const CLINIC_FETCH_CARRIERS_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_FETCH_CARRIERS_REQUEST';
export const CLINIC_FETCH_CARRIERS_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_FETCH_CARRIERS_SUCCESS';
export const CLINIC_FETCH_CARRIERS_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_FETCH_CARRIERS_FAILURE';
export const CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST';
export const CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS';
export const CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE';

// Requests
export const CLINIC_REJECT_DOCUMENT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REJECT_DOCUMENT_REQUEST';
export const CLINIC_REQUEST_REMOVED_FROM_LIST =
  'cricket/Accounts/Clinic/CLINIC_REQUEST_REMOVED_FROM_LIST';

// Contacts
export const CLINIC_DELETE_CONTACT_STARTED =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CONTACT_STARTED';
export const CLINIC_DELETE_CONTACT_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CONTACT_REQUEST';
export const CLINIC_CONTACT_REMOVED_FROM_LIST =
  'cricket/Accounts/Clinic/CLINIC_CONTACT_REMOVED_FROM_LIST';
export const CLINIC_CONTACT_LIST_READY =
  'cricket/Accounts/Clinic/CLINIC_CONTACT_LIST_READY';
export const CLINIC_DELETE_CONTACT_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CONTACT_SUCCESS';
export const CLINIC_DELETE_CONTACT_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_DELETE_CONTACT_FAILURE';
export const CLINIC_MVL_REVOKE_ACCESS =
  'cricket/Accounts/Clinic/CLINIC_MVL_REVOKE_ACCESS';
export const CLINIC_MVL_GRANT_ACCESS =
  'cricket/Accounts/Clinic/CLINIC_MVL_GRANT_ACCESS';

// Home
export const CLINIC_REFRESH_HOME_LIST_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_HOME_LIST_REQUEST';
export const CLINIC_REFRESH_HOME_LIST_STARTED =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_HOME_LIST_STARTED';
export const CLINIC_REFRESH_HOME_LIST_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_HOME_LIST_SUCCESS';
export const CLINIC_REFRESH_HOME_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_HOME_LIST_FAILURE';

export const CLINIC_ANIMAL_LIST_UPDATE =
  'cricket/Accounts/Clinic/CLINIC_ANIMAL_LIST_UPDATE';

// Analytics
export const CLINIC_TRACK_DOCUMENT_SIGNING =
  'cricket/Accounts/Clinic/CLINIC_TRACK_DOCUMENT_SIGNING';

// Certificates Search
export const CLINIC_CERTIFICATES_SEARCH_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_CERTIFICATES_SEARCH_REQUEST';
export const CLINIC_CERTIFICATES_SEARCH_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_CERTIFICATES_SEARCH_SUCCESS';
export const CLINIC_CERTIFICATES_SEARCH_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_CERTIFICATES_SEARCH_FAILURE';
export const CLINIC_CERTIFICATES_SEARCH_CLEAR =
  'cricket/Accounts/Clinic/CLINIC_CERTIFICATES_SEARCH_CLEAR';

// Create Document from Certificate
export const CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST =
  'cricket/Accounts/Clinic/CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST';
export const CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Clinic/CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS';
export const CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE';

// Generic Clinic
export const CLINIC_SET_REFRESH_DOMAIN =
  'cricket/Accounts/Clinic/CLINIC_SET_REFRESH_DOMAIN';
export const CLINIC_REFRESH_DOMAIN_LIST_FAILURE =
  'cricket/Accounts/Clinic/CLINIC_REFRESH_DOMAIN_LIST_FAILURE';
