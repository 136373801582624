import PropTypes from 'prop-types';

import actionInfoPropTypes from 'components/GvlTable/actionInfoPropTypes';

const gvlPropTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.array,
  dynamicActions: PropTypes.bool,
  noRowActions: PropTypes.bool,
  columns: PropTypes.array.isRequired, // AntD Columns object
  actionInfo: PropTypes.shape(actionInfoPropTypes),
  searchTextPlaceholder: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isRowSelectionDisabled: PropTypes.func,
  additionalSearchComponent: PropTypes.object,
  hasAdZone: PropTypes.bool
};

export default gvlPropTypes;
