// TODO: Why isn't this just a .json file?
// These should match in App.less
export const FONT = {
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  },
  family: {
    regular: "'Open Sans', sans-serif",
    header: "'Raleway', sans-serif"
  },
  size: {
    xlarge: '1.25rem',
    large: '1rem',
    medium: '.875rem',
    small: '.75rem'
  }
};
