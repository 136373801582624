import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Col, Row, Select, Spin } from 'antd';
import { listRequest } from 'modules/HerdList/store/herdListActions';
import { HerdListCreateModal } from 'modules/HerdList/herdListIndex';

class HerdListSelectComponent extends Component {
  static propTypes = {
    listRequest: PropTypes.func.isRequired,
    refreshingHerdLists: PropTypes.bool.isRequired,
    herdLists: PropTypes.object.isRequired,
    naRequest: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.object
  };

  state = {
    herdListModalVisible: false
  };

  componentDidMount() {
    this.props.listRequest({
      limit: 0,
      'clinic.id': this.props.naRequest?.clinic?.id
    });
  }

  onSelect = herdListId => {
    this.props.onChange(this.props.herdLists[herdListId]);
  };

  generateSelectOptions = () => {
    const herdLists = Object.values(this.props.herdLists);
    return herdLists.map(herdList => (
      <Select.Option key={herdList.herdListId} value={herdList.herdListId}>
        {herdList.name}
      </Select.Option>
    ));
  };

  filterHerdLists = (inputValue, option) =>
    option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  render() {
    return (
      <Row gutter={8}>
        <Col xs={24} md={16} xxl={18}>
          <Spin size="small" spinning={this.props.refreshingHerdLists}>
            <Select
              className="flex1-full-width"
              showSearch
              showAction={['focus', 'click']}
              onSelect={this.onSelect}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={this.props.value?.herdListId}
            >
              {this.generateSelectOptions()}
            </Select>
          </Spin>
        </Col>
        <Col xs={24} md={8} xxl={6}>
          <Button
            block={true}
            type="default"
            onClick={() => this.setState({ herdListModalVisible: true })}
          >
            {intl.get('create.new')}
          </Button>
          <HerdListCreateModal
            visible={this.state.herdListModalVisible}
            closeModal={() => this.setState({ herdListModalVisible: false })}
            onHerdListCreated={herdList => this.props.onChange(herdList)}
            naRequest={this.props.naRequest}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  herdLists: state.accounts.herdLists.data,
  refreshingHerdLists: state.accounts.herdLists.isRefreshing
});

const mapDispatchToProps = {
  listRequest
};

const HerdListSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(HerdListSelectComponent);

export { HerdListSelect as default, HerdListSelectComponent };
