// Latest Google Analytics doc: https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications

// Initialization code taken from Neptr

export const initialize = /* istanbul ignore next */ () => {
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    // eslint-disable-next-line no-unused-expressions
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
        // eslint-disable-next-line no-sequences
      }),
      (i[r].l = 1 * new Date());
    // eslint-disable-next-line no-unused-expressions, no-sequences
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    '//www.google-analytics.com/analytics.js',
    'ga'
  );

  window.ga('create', 'UA-60323233-3', 'auto');
  window.ga('require', 'GTM-TBTJZM6');
  window.ga('send', 'pageview');
};

// Wrap ga for testing purposes
export const getCommandObject = () => {
  return window.ga;
};

export const userSetup = userInfo => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  commandObject('set', 'userId', userInfo.appUserId);
};

export const userReset = () => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  // Not doing anything here for GA since we didn't do anything in the Neptr.
  // The userId will be reset when user logs in
};

export const trackEvent = (category, action, label) => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  commandObject('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  });
};

export const trackTiming = (category, action, label, value) => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  commandObject('send', {
    hitType: 'timing',
    timingCategory: category,
    timingVar: action,
    timingValue: value,
    timingLabel: label
  });
};

export const locationChange = location => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  commandObject('set', 'page', location);
  commandObject('send', 'pageview');
};

export const apiError = location => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  if (!location.endsWith('api/killtoken')) {
    commandObject('send', 'event', 'Error', 'Ajax', location);
  }
};

export const jsError = error => {
  const commandObject = getCommandObject();
  if (!commandObject) {
    return;
  }

  const exception = {
    exDescription:
      'JavaScript Error ' +
      error.message +
      ' ' +
      error.filename +
      ': ' +
      error.lineno,
    exFatal: false
  };
  commandObject('send', 'exception', exception);
};
