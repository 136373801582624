import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Form, Icon, Input } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { THEME } from 'style';
import {
  minLength,
  containsUpperAndLowerCaseLetters,
  containsNumber,
  containsSymbol
} from 'utils/validation/common';

export default function PasswordFormItem({ form, model, onChange = () => {} }) {
  const value = form.getFieldValue('password');

  const getIcon = hasValidationPassed => {
    return (
      <Icon
        type={hasValidationPassed ? 'check' : 'close'}
        style={{ marginRight: 8 }}
      />
    );
  };

  const getColor = hasValidationPassed => {
    if (!value) {
      return THEME.tabText();
    }
    return hasValidationPassed ? THEME.green() : THEME.red();
  };

  // Validation return true if it fails so negate the result to know if it passed.
  const hasMinLength = !minLength(value, 8);
  const hasUpperAndLowerCase = !containsUpperAndLowerCaseLetters(value);
  const hasNumber = !containsNumber(value);
  const hasSymbol = !containsSymbol(value);

  const extraText = (
    <span>
      <span style={{ color: getColor(hasMinLength) }}>
        {getIcon(value && hasMinLength)}
        {intl.get('password.characters')}
      </span>
      <br />
      <span style={{ color: getColor(hasUpperAndLowerCase) }}>
        {getIcon(value && hasUpperAndLowerCase)}
        {intl.get('password.letters')}
      </span>
      <br />
      <span style={{ color: getColor(hasNumber) }}>
        {getIcon(value && hasNumber)}
        {intl.get('password.number')}
      </span>
      <br />
      <span style={{ color: getColor(hasSymbol) }}>
        {getIcon(value && hasSymbol)}
        {intl.get('password.symbol')}
      </span>
    </span>
  );

  return (
    <Form.Item label={intl.get('password')} extra={extraText}>
      {getModelFieldDecorator(
        form,
        'password',
        model,
        true
      )(<Input.Password onChange={onChange} />)}
    </Form.Item>
  );
}

PasswordFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  model: PropTypes.func.isRequired,
  onChange: PropTypes.func
};
