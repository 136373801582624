import { saveFile } from 'utils/appUtil';
export const formatDate = date => {
  let dd = date.getDate();
  if (dd < 10) dd = '0' + dd;
  let mm = date.getMonth() + 1;
  if (mm < 10) mm = '0' + mm;
  let yyyy = date.getFullYear();
  return mm + '-' + dd + '-' + yyyy;
};

export const exportCsv = (layout, data, fileName, shouldFormatData) => {
  let csv = '"';
  layout.forEach(item => {
    csv += csvify(item.heading) + '","';
  });
  csv = csv.replace(/,"$/, '\r\n');

  data.forEach(dataItem => {
    csv += '"';
    layout.forEach(layoutItem => {
      let val = !layoutItem.dataFormatFunction
        ? getDataValue(dataItem, layoutItem.property)
        : layoutItem.dataFormatFunction(
            getDataValue(dataItem, layoutItem.property)
          );
      csv += csvify(val, shouldFormatData) + '","';
    });
    csv = csv.replace(/,"$/, '\r\n');
  });
  let blob = new Blob([csv], { type: 'text/csv' });
  saveFile(blob, fileName);
};
const csvify = (val, shouldFormatData = true) => {
  if ([undefined, null].includes(val)) return '';
  if (shouldFormatData) {
    let newVal = val.toString().replace(/,/g, '');
    newVal = newVal.toString().replace(/"/g, "'");
    return newVal;
  } else {
    return val;
  }
};
const getDataValue = (dataItem, property) => {
  while (property.includes('.')) {
    let leftmost = property.split('.', 1);
    property = property.replace(leftmost + '.', '');
    dataItem = dataItem[leftmost];
  }
  return dataItem[property];
};
