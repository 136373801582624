import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import GvlCard from 'elements/GvlCard';
import GvlTable from 'components/GvlTable/GvlTable';
import nonAdjacentListColumns from 'containers/accounts/clinic/AutogenousVaccines/nonAdjacentListColumns';

export default function NonAdjacentIsolateTables({ currentIsolates }) {
  const nonAdjacentColumns = nonAdjacentListColumns.generateColumns();

  return (
    <React.Fragment>
      <GvlCard
        title={intl.get('naRequest.herd.origin')}
        style={{ width: '100%' }}
        condensed={true}
      >
        <GvlTable
          data={currentIsolates}
          columns={nonAdjacentColumns.herdsOfOrigin}
          keyField="isolateId"
          hideSearch={true}
          noRowActions={true}
          searchTextPlaceholder=""
        />
      </GvlCard>
      <br />
      <GvlCard
        title={intl.get('isolate.history')}
        style={{ width: '100%' }}
        condensed={true}
      >
        <GvlTable
          data={currentIsolates}
          columns={nonAdjacentColumns.isolateHistory}
          keyField="isolateId"
          hideSearch={true}
          noRowActions={true}
          footer={() => (
            <h3>
              {intl.get('isolates.used.number', {
                number: currentIsolates?.length
              })}
            </h3>
          )}
          searchTextPlaceholder=""
        />
      </GvlCard>
    </React.Fragment>
  );
}

NonAdjacentIsolateTables.propTypes = {
  currentIsolates: PropTypes.arrayOf(PropTypes.object)
};
