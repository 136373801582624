import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './herdListActionTypes';

const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false
});

export const onIsRefreshing = (state, { isRefreshing }) =>
  Immutable.setIn(state, ['isRefreshing'], isRefreshing);

export const onListSuccess = (state, { herdLists }) => {
  let newState = state.asMutable({ deep: true });

  for (const herdList of herdLists) {
    newState.data[herdList.herdListId] = herdList;
  }
  newState.lastSuccessfulRefreshedTime = new Date().getTime();
  newState.refreshFailure = false;

  return Immutable.from(newState);
};

export const onListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onRefreshSuccess = (state, { herdList }) =>
  Immutable.setIn(state, ['data', herdList.herdListId], herdList);

export const herdListReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsRefreshing,
  [types.LIST_SUCCESS]: onListSuccess,
  [types.LIST_FAILURE]: onListFailure,
  [types.REFRESH_SUCCESS]: onRefreshSuccess
});
