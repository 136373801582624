import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Alert, Button, Form, Input } from 'antd';
import { concat, debounce, without } from 'lodash';
import { loadScript } from 'utils/externalScript';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { validateFederalLicense } from 'modules/License/store/licenseActions';
import { UserRegistration as UserRegistrationModel } from 'modules/Registration/User/userRegistrationIndex';
import EditLicenseModal from 'components/EditLicenseModal';
import VetLicenseTable from 'components/VetLicenseTable';
import GvlCard from 'elements/GvlCard';
import PasswordFormItem from 'elements/PasswordFormItem';
import { SignatureInput } from 'modules/User/components/SignatureInput';

export default function VetRegistrationInformation({
  form,
  onValidateUsername,
  vetDetailsAlert,
  submitButtonInfo
}) {
  const dispatch = useDispatch();
  const [signatureInputMode, setSignatureInputMode] = useState('esign');
  const [license, setLicense] = useState(null);
  const [licenseList, setLicenseList] = useState([]);
  const [licenseModalVisible, setLicenseModalVisible] = useState(false);
  const [
    updateSignatureTextDebounced,
    setUpdateSignatureTextDebounced
  ] = useState();

  useEffect(() => {
    async function loadFonts() {
      await loadScript(
        'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js'
      ).catch(
        /* istanbul ignore next */
        error => console.log('Unable to load google webfont external js', error)
      );
      if (!window.WebFont) return;
      window.WebFont.load({
        google: {
          families: [
            'Homemade Apple',
            'Satisfy',
            'Dancing Script',
            'Mr Dafoe',
            'Dawning of a New Day'
          ]
        }
      });
    }
    loadFonts();
  }, []);

  useEffect(() => {
    function setSignatureText() {
      // If signature field has been modified by the user, then do not change it when name updates
      if (form.isFieldTouched('signatureText')) return;

      let first = form.getFieldValue('firstName') || '';
      let last = form.getFieldValue('lastName') || '';
      if (!first && !last) return;
      if (form.getFieldValue('signatureText') !== `${first} ${last}`) {
        form.setFieldsValue({ signatureText: `${first} ${last}` });
      }
    }
    // Debounce ensures that when setting signature, form values are their most current
    setUpdateSignatureTextDebounced(debounce(setSignatureText, 500));
  }, [form, setUpdateSignatureTextDebounced]);

  function addLicense(licenseToAdd) {
    let updatedLicenseList;
    // If editing a license, remove the old version before adding the new
    if (licenseToAdd) {
      updatedLicenseList = without(licenseList, license);
    }
    // Validate the federal license (only type that can be validated through our system)
    if (licenseToAdd.issuingAuthority === 'FEDERAL') {
      dispatch(
        validateFederalLicense({
          lastName: form.getFieldValue('lastName'),
          licenseNumber: licenseToAdd.licenseNumber
        })
      );
    }
    updatedLicenseList = concat(licenseList, licenseToAdd);
    setLicense(null);
    setLicenseList(updatedLicenseList);
  }

  function removeLicense(licenseToRemove) {
    setLicenseList(without(licenseList, licenseToRemove));
  }

  return (
    <React.Fragment>
      <GvlCard title={intl.get('vet.details')}>
        <div>
          {vetDetailsAlert}
          <Form.Item label={intl.get('firstName')}>
            {getModelFieldDecorator(
              form,
              'firstName',
              UserRegistrationModel,
              true
            )(<Input onChange={updateSignatureTextDebounced} />)}
          </Form.Item>
          <Form.Item label={intl.get('lastName')}>
            {getModelFieldDecorator(
              form,
              'lastName',
              UserRegistrationModel,
              true
            )(<Input onChange={updateSignatureTextDebounced} />)}
          </Form.Item>
          <Form.Item
            label={intl.get('email')}
            extra={intl.get('register.email.info')}
          >
            {getModelFieldDecorator(
              form,
              'username',
              UserRegistrationModel,
              true
            )(<Input onBlur={onValidateUsername} disabled />)}
          </Form.Item>
          <PasswordFormItem form={form} model={UserRegistrationModel} />
        </div>
        <br />
        <SignatureInput
          form={form}
          inputMode={signatureInputMode}
          setInputMode={mode => setSignatureInputMode(mode)}
        />
      </GvlCard>
      <br />
      <GvlCard
        title={intl.get('license.details')}
        extra={
          <Button type="link" onClick={() => setLicenseModalVisible(true)}>
            {intl.get('settings.user.addLicense')}
          </Button>
        }
      >
        <div>
          <EditLicenseModal
            destroyOnClose
            visible={licenseModalVisible}
            onCancel={() => {
              setLicenseModalVisible(false);
              setLicense(null);
            }}
            country={form.getFieldValue('locationAddress.country') || 'USA'}
            license={license}
            licenseList={licenseList}
            onLicenseSaveRequest={addLicense}
          />
          <Alert
            message={intl.get('register.license.alert')}
            description={intl.get('register.requiredAccreditation.message')}
            type="info"
            showIcon
            style={{ marginBottom: '24px' }}
          />
          <VetLicenseTable
            licenseList={licenseList}
            onLicenseEditRequest={licenseToEdit => {
              setLicense(licenseToEdit);
              setLicenseModalVisible(true);
            }}
            onLicenseDeleteRequest={removeLicense}
          />
          <br />
          <Button
            data-testid="VetRegInfoBtn"
            style={{ float: 'right', margin: '0px 0px 24px' }}
            type="primary"
            onClick={() =>
              submitButtonInfo.onClick({ licenseList, signatureInputMode })
            }
          >
            {intl.get(submitButtonInfo.messageId)}
          </Button>
        </div>
      </GvlCard>
    </React.Fragment>
  );
}

VetRegistrationInformation.propTypes = {
  form: PropTypes.object.isRequired,
  onValidateUsername: PropTypes.func.isRequired,
  vetDetailsAlert: PropTypes.element,
  submitButtonInfo: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired
};
