import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Modal, Spin, message, notification } from 'antd';
import { cloneDeep, concat } from 'lodash';
import { Herd, HerdFormFields } from 'modules/Herd/herdIndex';
import { HerdList } from 'modules/HerdList/herdListIndex';
import { saveRequest as saveHerdRequest } from 'modules/Herd/store/herdActions';
import {
  saveRequest as saveHerdListRequest,
  refreshSuccess as refreshHerdListSuccess
} from 'modules/HerdList/store/herdListActions';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

/*
 * The current implementation of this modal expects the herds to be part of a herd list.
 * It also expects a vet to verify that the herdState is one where the vet is licensed.
 */
class HerdEditModalComponent extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    saveHerdRequest: PropTypes.func.isRequired,
    saveHerdListRequest: PropTypes.func.isRequired,
    refreshHerdListSuccess: PropTypes.func.isRequired,
    refreshingHerd: PropTypes.bool.isRequired,
    refreshingHerdList: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onUpdateComplete: PropTypes.func.isRequired,
    vet: PropTypes.object.isRequired,
    herdList: PropTypes.object.isRequired,
    herd: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.props.form.setFieldsValue({ ...this.props.herd });
    }
  }

  handleSave = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('herd.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      // Ensure vet is licensed in the herd state before saving.
      const licensedStates = this.props.vet?.licenses?.map(
        license => license.state
      );
      if (!licensedStates.includes(formValues.herdState)) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('herd.state.error', {
            state: formValues.herdState
          })
        });
        return;
      }

      let herd = new Herd({
        ...this.props.herd,
        ...formValues
      });

      const modelValidationErrors = herd.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('herd.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      // Send the herd off to be updated after validation has passed
      if (
        hasFeatureAccess(
          this.props.currentUser,
          FeatureEnum.NonAdjacentLabInterface,
          'update'
        )
      ) {
        this.labSaveHerd(herd);
      } else if (herd.herdId) {
        this.saveHerd(herd);
      } else {
        this.saveNewHerd(herd);
      }
    });
  };

  /*
   * This will close the herd modal and pass the updated herd list back to handle any local updates needed.
   * The current implementation for nonadjacents will update the form value of herdList for example.
   */
  handleSaveComplete = herdList => {
    this.props.closeModal();
    this.props.onUpdateComplete(herdList);
  };

  // Herds are tied to a herd list, this will update the herd list locally with the updated herd after it has been saved.
  getUpdatedHerdList = herd => {
    let herdList = cloneDeep(this.props.herdList);
    herdList.herds.forEach((herdListHerd, index) => {
      if (herdListHerd.herdId === herd.herdId) {
        herdList.herds[index] = herd;
        return;
      }
    });
    return herdList;
  };

  /*
   * Isolate users can only make updates to the herd on the nonadjacent request. The changes are never persisted to the herd in the db.
   * This is due to clinics owning the herd lists, so we don't want users outside that clinic to make persistent changes to a herd.
   * Because of this, we shape the herd into what would be returned by the api, and then apply that change to the local herd list.
   * The herd list in this case is not in the store, so can just call handleSaveComplete.
   */
  labSaveHerd = herd => {
    // Shape the herd into what would be returned by the api
    herd.producer = {
      name: herd.producerName,
      phone: herd.phone
    };
    herd.contactLocation = {
      addressLine1: herd.addressLine1,
      city: herd.city,
      state: herd.state,
      postalCode: herd.postalCode,
      latitude: herd.latitude,
      longitude: herd.longitude,
      premisesId: herd.premisesId
    };

    const herdList = this.getUpdatedHerdList(herd);
    // Give feedback to let the user know the edit was successful.
    message.success(intl.get('herd.list.updated'));
    this.handleSaveComplete(herdList);
  };

  /*
   * This handles updating a herd with changes that persist to the db.
   * After the save, we need to update the store so it's current with what would be returned by a herd list refresh request.
   */
  saveHerd = herd => {
    // Update the herd list in the store
    const updateHerdListInStore = updatedHerd => {
      const herdList = this.getUpdatedHerdList(updatedHerd);
      this.props.refreshHerdListSuccess(herdList);
      this.handleSaveComplete(herdList);
    };

    this.props.saveHerdRequest(herd, updateHerdListInStore);
  };

  /*
   * This handles creating a new herd with changes that persist to the db.
   * Creating a herd does not tie it to a herd list so after creating the herd, tie it to the existing herd list.
   */
  saveNewHerd = herd => {
    // Update the herd list herds
    const saveHerdListCallback = newHerd => {
      let herdList = new HerdList({
        herdListId: this.props.herdList.herdListId,
        herds: this.props.herdList.herds.map(
          herdListHerd => herdListHerd.herdId
        )
      });
      herdList.herds = concat(herdList.herds, newHerd.herdId);

      this.props.saveHerdListRequest(herdList, this.handleSaveComplete);
    };

    // After saving the herd, add it to the herd list.
    this.props.saveHerdRequest(herd, saveHerdListCallback, false);
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      const closeModalCallback = () => {
        this.props.closeModal();
      };
      Modal.confirm({
        title: intl.get('cancel.edit'),
        content: intl.get('cancel.edit.body'),
        okText: intl.get('discard'),
        onOk() {
          closeModalCallback();
        }
      });
    } else {
      this.props.closeModal();
    }
  };

  render() {
    const {
      form,
      visible,
      herd,
      refreshingHerd,
      refreshingHerdList
    } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={this.handleCancel}
        forceRender={true}
        destroyOnClose={true}
        title={herd?.herdId ? intl.get('herd.edit') : intl.get('herd.create')}
        footer={
          <Spin spinning={refreshingHerd || refreshingHerdList} size="small">
            <Button key="cancel" onClick={this.handleCancel}>
              {intl.get('cancel')}
            </Button>
            <Button
              id="herd-edit-form-submit"
              type="primary"
              form="herdEditForm"
              htmlType="submit"
            >
              {intl.get('save')}
            </Button>
          </Spin>
        }
      >
        <Form layout="vertical" id="herdEditForm" onSubmit={this.handleSave}>
          <HerdFormFields form={form} herd={herd} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    refreshingHerd: state.accounts.herds.isRefreshing,
    refreshingHerdList: state.accounts.herdLists.isRefreshing,
    currentUser: state.app.user
  };
};

const mapDispatchToProps = {
  saveHerdRequest,
  saveHerdListRequest,
  refreshHerdListSuccess
};

const HerdEditModalForm = Form.create({ name: 'herdEditModal' })(
  HerdEditModalComponent
);

const HerdEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(HerdEditModalForm);

export { HerdEditModal as default, HerdEditModalComponent };
