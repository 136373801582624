/* eslint-disable react/display-name */
import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { logoutRequest } from 'containers/app/store/login/loginActions';
import { commonLoadStartupDataRequest } from 'containers/accounts/common/store/accountsCommonActions';

import { isIsolateUser } from 'utils/userUtil';
import { listRequest as newportClinicListRequest } from 'modules/NewportClinic/store/newportClinicActions';

import SideMenu from 'containers/accounts/common/SideMenu';
import GvlHeader from 'components/GvlHeader/GvlHeader';
import LicenseAgreementModal from 'components/LicenseAgreement';
import SpeciesCollectionModal from 'components/SpeciesCollectionModal';
import ConnectionError from 'elements/ConnectionError';

import { THEME } from 'style';
import * as userUtil from 'utils/userUtil';
import * as appAccounts from 'utils/appAccounts';
import * as analytics from 'utils/analytics/analytics';
import NvapLicenseWarningModal from 'components/NvapLicenseWarningModal';
import { listRequest as licenseListRequest } from 'modules/License/store/licenseActions';
import { isLicenseValidationFeatureEnabled } from 'containers/app/store/user/userSelectors';
import { selectNvapLicense } from 'modules/License/store/licenseSelectors';
import * as userRoles from 'utils/userRoles';

const AdminRoutes = Loadable({
  loader: () => import('containers/accounts/admin/AdminRoutes'),
  loading: () => <Spin size="large" />
});

const ClinicRoutes = Loadable({
  loader: () => import('containers/accounts/clinic/ClinicRoutes'),
  loading: () => <Spin size="large" />
});

const DistributorRoutes = Loadable({
  loader: () => import('containers/accounts/distributor/DistributorRoutes'),
  loading: () => <Spin size="large" />
});

const LabRoutes = Loadable({
  loader: () => import('containers/accounts/lab/LabRoutes'),
  loading: () => <Spin size="large" />
});

const RegulatoryRoutes = Loadable({
  loader: () => import('containers/accounts/regulatory/RegulatoryRoutes'),
  loading: () => <Spin size="large" />
});

const MvlOwnerRoutes = Loadable({
  loader: () => import('containers/accounts/mvlOwner/MvlOwnerRoutes'),
  loading: () => <Spin size="large" />
});

const IsolateRoutes = Loadable({
  loader: () =>
    import('containers/accounts/clinic/AutogenousVaccines/IsolateRoutes'),
  loading: () => <Spin size="large" />
});

const GvlContent = styled(Layout.Content, {
  shouldForwardProp: isPropValid
})(({ viewMode, sidebarCollapsed }) => {
  // base styling is for Desktop view mode and sidebar not collapsed
  // If any of the syles are changed that affect vertical height, padding, or margin, please update
  // "App.less" class fill-view-vertically as needed
  // This is also tied to the height of the GvlHeader
  let baseStyling = {
    padding: '32px',
    marginTop: '70px',
    minHeight: 'calc(100vh - 70px)',
    backgroundColor: THEME.secondaryBackgroundColor()
  };

  if (viewMode === 'Mobile') {
    baseStyling.padding = '8px 0 58px 0';
  } else {
    baseStyling.marginLeft = sidebarCollapsed ? '80px' : '254px';
  }

  return baseStyling;
});

class HomeComponent extends Component {
  static propTypes = {
    user: PropTypes.object,
    connectionError: PropTypes.bool.isRequired,
    logoutRequest: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    tokenValidated: PropTypes.bool.isRequired,
    retrievingStartupData: PropTypes.bool.isRequired,
    startupDataRetrieved: PropTypes.bool.isRequired,
    viewMode: PropTypes.string.isRequired,
    commonLoadStartupDataRequest: PropTypes.func.isRequired,
    newportClinicListRequest: PropTypes.func.isRequired,
    newportClinicListRetrieved: PropTypes.bool.isRequired,
    licenseListRequest: PropTypes.func.isRequired,
    history: PropTypes.shape({ listen: PropTypes.func.isRequired }).isRequired,
    isLicenseValidationEnabled: PropTypes.bool.isRequired,
    nvapLicense: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  state = {
    sidebarCollapsed: false,
    isNvapModalVisible: undefined,
    isSpeciesCollectionModalVisible: undefined
  };

  listener = (location, _action) => {
    analytics.locationChange(location.pathname);
  };

  componentWillUnmount() {
    this.unregisterHistoryListener();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.unregisterHistoryListener = this.props.history.listen(this.listener);
    if (
      !this.props.connectionError &&
      this.props.user.authToken &&
      this.props.tokenValidated
    ) {
      if (!this.props.startupDataRetrieved) {
        this.props.commonLoadStartupDataRequest();
      }
      if (
        isIsolateUser(this.props.user) &&
        !this.props.newportClinicListRetrieved
      ) {
        // TODO: Remove this once the long term solution for managing Newport's clinic data is implemented
        // Can this move this to the commonLoadStartupDataRequest?
        // Could the commonLoadStartupDataRequest even be moved into the Login action (whatever that's named)?
        this.props.newportClinicListRequest();
      }
    }
    if (this.props.isLicenseValidationEnabled) {
      this.retrieveUsdaLicense();
    }
  }

  getUserAppRoutes(user) {
    const appAccountForUser = userUtil.getAppAccountForUser(user);
    // TODO: Vets need to access Isolates/Products/naRequests etc. This switch/case might not be the best approach going forward, considering this new feature access logic.
    switch (appAccountForUser) {
      case appAccounts.APP_ACCOUNT_ADMIN:
        return AdminRoutes;
      case appAccounts.APP_ACCOUNT_CLINIC:
        return ClinicRoutes;
      case appAccounts.APP_DISTRIBUTOR_VFD:
        return DistributorRoutes;
      case appAccounts.APP_ACCOUNT_LABTECH:
      case appAccounts.APP_ACCOUNT_NVSL_LABTECH:
        return LabRoutes;
      case appAccounts.APP_ACCOUNT_REGULATORY:
        return RegulatoryRoutes;
      case appAccounts.APP_ACCOUNT_ISOLATES:
        return IsolateRoutes;
      case appAccounts.APP_ACCOUNT_MVL_OWNER:
      case appAccounts.APP_ACCOUNT_AGENT:
        return MvlOwnerRoutes;
      default:
        return null;
    }
  }

  retrieveUsdaLicense = () => {
    const { user, licenseListRequest, nvapLicense } = this.props;
    if (user.roles.includes('ROLE_VET')) {
      licenseListRequest(user.info.vetId, _licenseList => {
        let isNvapModalVisible = false;
        if (
          nvapLicense?.validationStatus !== 'ACCREDITED' &&
          user.info.userCountry === 'USA'
        ) {
          isNvapModalVisible = true;
        }

        this.setState({
          isNvapModalVisible
        });
      });
    }
  };

  handleSpeciesCollectionModalVisibility = () => {
    const user = this.props.user;
    if (
      user.info?.account?.type !== 'Clinic' ||
      !user.roles.includes(userRoles.ROLE_ACCOUNT_MANAGER)
    )
      return;

    const speciesCollectionNeeded =
      !user.info.practiceTypeCollectionDate ||
      moment().diff(user.info.practiceTypeCollectionDate, 'years') > 0;
    const visibleStateNotSet =
      this.state.isSpeciesCollectionModalVisible === undefined;
    const noOtherModalsVisible =
      !user.info.missingNecessaryLicenseAgreement &&
      !this.state.isNvapModalVisible;

    if (speciesCollectionNeeded && visibleStateNotSet && noOtherModalsVisible) {
      this.setState({ isSpeciesCollectionModalVisible: true });
    }
  };

  render() {
    const {
      user,
      connectionError,
      logoutRequest,
      location,
      tokenValidated,
      retrievingStartupData,
      startupDataRetrieved,
      viewMode,
      isLicenseValidationEnabled
    } = this.props;

    if (connectionError) {
      return <ConnectionError />;
    }

    if (!user.authToken || (user.authToken && !tokenValidated)) {
      return (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      );
    }

    this.handleSpeciesCollectionModalVisibility();
    const UserAppRoutes = this.getUserAppRoutes(user);
    return (
      <React.Fragment>
        <Spin spinning={retrievingStartupData} size="large">
          <LicenseAgreementModal
            visible={user.info.missingNecessaryLicenseAgreement}
          />
          {isLicenseValidationEnabled &&
          this.state.isNvapModalVisible !== undefined &&
          !user.info.missingNecessaryLicenseAgreement ? (
            <NvapLicenseWarningModal
              visible={this.state.isNvapModalVisible}
              onContinue={() => {
                this.setState({ isNvapModalVisible: false });
              }}
              variant="login"
              onCancel={() => {
                this.setState({ isNvapModalVisible: false });
              }}
            />
          ) : null}
          <SpeciesCollectionModal
            visible={this.state.isSpeciesCollectionModalVisible}
            closeModal={() =>
              this.setState({ isSpeciesCollectionModalVisible: false })
            }
          />
          <Layout className={user.colorThemeClassName}>
            <SideMenu
              location={location}
              collapsedChanged={sidebarCollapsed =>
                this.setState({ sidebarCollapsed })
              }
              user={user}
              logoutRequest={logoutRequest}
            />
            <Layout>
              {/* TODO: GVLHeader should watch account common store for changes to breadcrumbs or header buttons */}
              <GvlHeader sidebarCollapsed={this.state.sidebarCollapsed} />
              <GvlContent
                viewMode={viewMode}
                sidebarCollapsed={this.state.sidebarCollapsed}
              >
                {startupDataRetrieved && UserAppRoutes && (
                  <UserAppRoutes
                    setHeaderBreadcrumbs={this.onSetHeaderBreadcrumbs}
                    setHeaderButtons={this.onSetHeaderButtons}
                    user={user}
                  />
                )}
              </GvlContent>
            </Layout>
          </Layout>
        </Spin>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  logoutRequest,
  licenseListRequest,
  commonLoadStartupDataRequest,
  newportClinicListRequest
};

const mapStateToProps = state => {
  const { accounts, app } = state;
  return {
    user: app.user,
    connectionError: app.data.connectionError,
    tokenValidated: app.login.tokenValidated,
    retrievingStartupData: accounts.common.retrievingStartupData,
    startupDataRetrieved: accounts.common.startupDataRetrieved,
    newportClinicListRetrieved: accounts.newportClinics.dataRetrieved,
    viewMode: app.data.viewMode,
    isLicenseValidationEnabled: isLicenseValidationFeatureEnabled(state),
    nvapLicense: selectNvapLicense(state)
  };
};

const Home = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeComponent)
);

export { Home as default, HomeComponent };
