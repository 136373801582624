import React from 'react';
import { Icon } from 'antd';
import * as gvlIcons from 'elements/GvlIcons';
import PropTypes from 'prop-types';

export default function GvlIcon(props) {
  const { type, ...rest } = props;
  if (!type) return <Icon {...props} />;

  //Check if it is a gvl or material design icon
  if (
    type.startsWith('gvl') ||
    type.startsWith('Gvl') ||
    type.startsWith('material')
  ) {
    let gvlIcon = type.replace(/-/gi, '_'); // Rename from gvl-animal-circle-large to gvl_animal_circle_large since we use this key to fetch the component
    let component = gvlIcons[gvlIcon];
    if (component) {
      return <Icon component={component} {...rest} />;
    }
    console.error('Invalid icon type: ', gvlIcon);
    return <Icon {...rest} />;
  }

  // Render as regular icons
  return <Icon {...props} />;
}

GvlIcon.propTypes = {
  type: PropTypes.string
};
