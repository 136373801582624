// general constants
export const IS_REFRESHING = 'license/IS_REFRESHING';

// list constants
export const LIST_REQUEST = 'license/LIST_REQUEST';
export const LIST_SUCCESS = 'license/LIST_SUCCESS';
export const LIST_FAILURE = 'license/LIST_FAILURE';

// refresh
export const REFRESH_REQUEST = 'license/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'license/REFRESH_SUCCESS';

// save
export const SAVE_REQUEST = 'license/SAVE_REQUEST';

// delete
export const DELETE_REQUEST = 'license/DELETE_REQUEST';
export const DELETE_SUCCESS = 'license/DELETE_SUCCESS';

// validate
export const VALIDATE_FEDERAL_LICENSE = 'license/VALIDATE_FEDERAL_LICENSE';
