import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './contactActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false
});

// explicitly exporting every reducer just to expose them to the spec file seems like an antipattern
// a future adventure in redux should include examining a better pattern of testing reducers.
export const onContactRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

export const onContactListSuccess = (state, payload) => {
  let contacts = {};
  Object.values(payload.contacts).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(contact => (contacts[contact.contactId] = contact));
    }
  });
  return state.merge({
    data: contacts,
    lastSuccessfulRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onContactListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onRefreshSuccess = (state, { contact }) =>
  Immutable.setIn(state, ['data', contact.contactId], contact);

export const contactReducer = createReducer(initialState, {
  [types.LIST_SUCCESS]: onContactListSuccess,
  [types.LIST_FAILURE]: onContactListFailure,
  [types.IS_REFRESHING]: onContactRefreshing,
  [types.REFRESH_SUCCESS]: onRefreshSuccess
});
