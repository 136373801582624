import Document from 'models/documents/Document';
import {
  isDate,
  isInteger,
  maxLength,
  dateInRangeNoTime,
  objectRequired
} from 'utils/validation/common';
import { validateCarrierType } from 'utils/validation/lists';
import * as api from 'utils/api';
import moment from 'moment';
import { getFinalTestResult } from 'utils/labTestUtils';

export default class Cvi extends Document {
  // Instance Methods
  /*
  validate() {
    const results = super.validate();
    // Example of how you would indicate an error:
    // results.push(Model.formatError('CVI Special', {messageId: 'cvi.consignor.somethingWentWrong'));
    return Array.isArray(results) && results.length > 0 ? results : null;
  }
  */

  // handle requirements beyond minimal drafts. many validations that would not be checked if they were blank will now need to run.
  isReadyToSign() {
    // TODO: This logic appears to be missing some logic that is in neptr 'verifyForSigning' method in healthlink\app\controllers\main_edit.coffee
    //    - SE Questions through the logic: callback2 = -> ctrl.addQuestions
    //    - SE verification that is handled in verifyCert callback passed to
    let results = this.validate();
    results || (results = []);
    // check required fields
    let fields = [
      'consignor',
      'consignorPremises',
      'consignee',
      'consigneePremises',
      'origin',
      'originPremises',
      'destination',
      'destinationPremises',
      'carrier',
      'carrierPremises',
      'carrierType',
      'purposeOfMovement',
      'species',
      'inspectionDate'
    ];
    this.checkFieldsPresent(Cvi, fields, results, 'Required to lock or sign');
    // check for animals
    if (this.animals && this.animals.length === 0) {
      results.push(
        Document.formatError('Cvi signing requirements', {
          messageId:
            'You must have at least one animal or animal group assigned to this certificate to lock or sign.'
        })
      );
    } else {
      // make sure we don't have any pending tests
      for (var animal of this.animals) {
        animal.tests || (animal.tests = []); // TODO: Unhandled Rejection (TypeError): Cannot add property tests, object is not extensible
        for (var test of animal.tests) {
          if (!test) {
            continue;
          }
          let status = test.cogginsStatus
            ? test.cogginsStatus.toLowerCase()
            : null;
          if (!status) {
            continue;
          }
          if (status === 'pending') {
            results.push(
              Document.formatError('Cvi signing requirements', {
                // TODO this probably needs to be moved into the translation files.
                messageId: `The EIA test (${test.gvlId}) on animal ${animal.name} must be resulted before you can lock or sign this certificate.`
              })
            );
          }
          if (
            status === 'resulted' &&
            getFinalTestResult(test).toLowerCase() === 'positive'
          ) {
            results.push(
              Document.formatError('Cvi signing requirements', {
                // TODO this probably needs to be moved into the translation files.
                messageId: `This certificate cannot be locked or signed because the EIA test (${test.gvlId}) on animal ${animal.name} is marked as POSITIVE.`
              })
            );
          }
        }
      }
    }
    return results.length > 0 ? results : null; // if !results everything is good
  }

  //A function to check if the document has the minimum fields to required to be saved
  isReadyToSave() {
    return (
      (this.consignor && this.consignor.id) ||
      (this.consignee && this.consignee.id) ||
      this.herdStatusNumber ||
      (this.origin && this.origin.id) ||
      (this.destination && this.destination.id) ||
      this.permitNumber
    );
  }

  //function to verify the document against smart engine rules
  // TODO : Refactor this to use redux store in the UI.
  async smartEngineVerify() {
    const result = await api.put(
      `${Cvi.apiPath}/${this.id}?protectedAction=verify`,
      { questions: this.questions }
    );
    this.ruleList = result.ruleList;
  }

  // Static methods

  static get fields() {
    return {
      ...super.fields,
      animals: {
        initialValue: [] // Array of animal objects
      },
      areaStatuses: {
        initialValue: [] // Array of strings
      },
      brandInspectionDate: {
        initialValue: undefined,
        validate: val => (val ? isDate(val, 'MM/DD/YYYY') : null)
      },
      brandInspectionNumber: {
        initialValue: undefined
      },
      carrier: {
        initialValue: {},
        validate: val => {
          return objectRequired('carrier', val);
        }
      },
      carrierPremises: {
        initialValue: {}
      },
      carrierType: {
        initialValue: '',
        validate: val => (val ? validateCarrierType(val) : null)
      },
      consignee: {
        initialValue: {}
      },
      consigneePremises: {
        initialValue: {}
      },
      consignor: {
        initialValue: {},
        validate: val => objectRequired('consignor', val)
      },
      consignorPremises: {
        initialValue: {},
        validate: val => objectRequired('consignorPremises', val)
      },
      destination: {
        initialValue: {}
      },
      destinationPremises: {
        initialValue: {}
      },
      headCount: {
        initialValue: undefined,
        validate: val =>
          val ? isInteger(val, 'validation.headCount.integer') : null
      },
      herdFreeFor: {
        initialValue: []
      },
      herdStatusNumber: {
        initialValue: '',
        validate: val => maxLength(val, 60)
      },
      inspectionDate: {
        initialValue: undefined,
        validate: val => {
          if (!val) {
            return null;
          } else {
            return isDate(val, 'MM/DD/YYYY') ||
              moment(val).isAfter(new Date(), 'day')
              ? { messageId: 'validation.inspection.date.exceeds.today' }
              : null;
          }
        }
      },
      origin: {
        initialValue: {}
      },
      originPremises: {
        initialValue: {}
      },
      permitNumber: {
        initialValue: undefined
      },
      purposeOfMovement: {
        initialValue: undefined,
        validate: val => maxLength(val, 60)
      },
      questions: {
        initialValue: [] // Array of question objects
      },
      remarks: {
        initialValue: [] // Array of strings
      },
      ruleList: {
        initialValue: {} // Not really a list, just an object
      },
      shippingDate: {
        initialValue: undefined,
        validate: val =>
          !val
            ? null
            : isDate(val, 'MM/DD/YYYY') ||
              dateInRangeNoTime(val, new Date(), null)
      },
      species: {
        initialValue: undefined,
        validate: val => maxLength(val, 50)
      },
      speciesType: {
        initialValue: undefined
      },
      verified: {
        initialValue: false
      },
      vet: {
        initialValue: null
      }
    };
  }

  // TODO: Once we have no more drafts from neptr we can remove this function
  static updateLegacyCvi(cvi) {
    for (let [fieldName, fieldInfo] of Object.entries(Cvi.fields)) {
      if (!cvi[fieldName] && !!fieldInfo.initialValue) {
        cvi[fieldName] = fieldInfo.initialValue;
      }
    }
  }

  static get docType() {
    return 'CVI';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    const cvi = await super.read(id);
    this.updateLegacyCvi(cvi);
    return cvi;
  };

  static getDrafts = async () => {
    let drafts = await super.getDrafts(this.docType);
    if (Array.isArray(drafts)) {
      drafts.forEach(cvi => {
        this.updateLegacyCvi(cvi);
      });
    }
    return drafts;
  };

  static getAwaitingPermit = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'READY_TO_SIGN',
      limit: 0
    });
    return data.results;
  };

  static getRecentlySigned = async () => {
    return super.getRecentlySigned(this.docType);
  };

  static getRequests = async () => {
    return super.getRequests(this);
  };

  static addPermit = async (id, permitNumber) => {
    return await api.put(
      `${this.apiPath}/${id}?protectedAction=addPermitNumber`,
      { permitNumber }
    );
  };

  static checkSigningCredentials = (state, user) => {
    if (!user.roles.includes('ROLE_VET')) {
      return 'signing.role.error';
    } else if (!state) {
      return 'signing.origin.error';
    } else if (!user.info.licenseStates.includes(state)) {
      return 'signing.license.error.properNoun';
    } else if (!user.info.canSignCerts) {
      return 'signing.cert.error';
    } else {
      return '';
    }
  };
}
