import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Row, Col } from 'antd';
import GvlCard from 'elements/GvlCard';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';

function IsolateProducerCard({
  location,
  producerName,
  herdSpecies,
  headCount
}) {
  return (
    <GvlCard title={intl.get('isolate.producer')}>
      <div>
        <Row gutter={8}>
          <Col span={12}>
            <IsolateValWithIcon
              val={
                <React.Fragment>
                  <div>{`${producerName}`}</div>
                  {location?.addressLine1 ? (
                    <div>{location?.addressLine1}</div>
                  ) : null}
                  {location?.city || location?.state || location?.postalCode ? (
                    <div>
                      {location?.city}
                      {location?.state ? `, ${location?.state}` : null}{' '}
                      {location?.postalCode}
                    </div>
                  ) : null}
                  {location?.latitude || location?.longitude ? (
                    <div>{`(${location?.latitude}, ${location?.longitude})`}</div>
                  ) : null}
                </React.Fragment>
              }
              icon="environment"
            />
            <br />
          </Col>
          <Col span={12}>
            <IsolateVal name="species" val={herdSpecies} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <IsolateValWithIcon icon="phone" val={location?.phone} />
          </Col>
          <Col span={12}>
            <IsolateVal name="herd.size" val={headCount} />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <IsolateVal name="premisesIDNoPin" val={location?.pin} />
          </Col>
        </Row>
      </div>
    </GvlCard>
  );
}

IsolateProducerCard.propTypes = {
  location: PropTypes.object.isRequired,
  producerName: PropTypes.string.isRequired,
  herdSpecies: PropTypes.string.isRequired,
  headCount: PropTypes.string
};

export { IsolateProducerCard as default };
