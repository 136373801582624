import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select, Spin, Empty, Alert } from 'antd';
import { throttle, debounce } from 'lodash';
import NoResult from 'components/NoResult';
import { InlineButtonLink } from 'style/commonEmotions';
import { retrieveData } from 'utils/searchUtil';
import intl from 'react-intl-universal';

const Option = Select.Option;

class GvlSearchComponent extends Component {
  static propTypes = {
    dataSource: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired
    ]),
    emptyMsg: PropTypes.string.isRequired,
    emptySubMsg: PropTypes.string,
    // Function to generate the options from the search result
    childGenerator: PropTypes.func.isRequired,

    // Not required:
    onSearchLessThanMinimum: PropTypes.func,

    // Has Defaults:
    filterFunction: PropTypes.func,
    selectMode: PropTypes.bool,
    minSearchTermLength: PropTypes.number,
    cachedOptions: PropTypes.array,
    debounceMilliseconds: PropTypes.number,
    useEagerSearch: PropTypes.bool,
    clearResultsWhenNoValue: PropTypes.bool,
    generateParameters: PropTypes.func,

    // From Redux:
    viewMode: PropTypes.string.isRequired,
    user: PropTypes.object
  };

  static defaultProps = {
    selectMode: false,
    filterFunction: () => true,
    minSearchTermLength: 0,
    cachedOptions: [],
    debounceMilliseconds: 500,
    useEagerSearch: true,
    clearResultsWhenNoValue: false,
    generateParameters: term => ({ term })
  };

  constructor(props) {
    super(props);
    const { dataSource, clearResultsWhenNoValue } = props;
    this.state = {
      result:
        Array.isArray(dataSource) && !clearResultsWhenNoValue ? dataSource : [],
      fetching: false,
      searchValue: ''
    };
    this.searchDebounced = debounce(
      this.search,
      this.props.debounceMilliseconds
    );
    this.searchThrottled = throttle(this.search, 500);
    this._isMounted = false;
  }

  generateParameters = term => {
    let params = this.props.generateParameters(term);
    if (this.state.showAll) {
      params.limit = 200;
    } else {
      params.limit = 10;
    }
    return params;
  };

  onLimitReached = () => this.setState({ searchLimitReached: true });

  // use this.props instead of passing values?
  search = async value => {
    const {
      dataSource,
      filterFunction,
      minSearchTermLength,
      cachedOptions,
      clearResultsWhenNoValue,
      selectMode
    } = this.props;

    if (!value && clearResultsWhenNoValue && !selectMode) {
      this.setState({ value, result: [], empty: false });
      return;
    }

    this.setState({ value, empty: false });
    let result = [];
    if (value.length === 0 && cachedOptions.length > 0) {
      result = cachedOptions;
    } else if (value.length < minSearchTermLength) {
      // Empty is set to false to hide the dropdown until a long enough search term is entered.
      this.setState({ result: result, empty: false });
      if (this.props.onSearchLessThanMinimum) {
        this.props.onSearchLessThanMinimum(true);
      }
      return;
    } else if (Array.isArray(dataSource)) {
      result = dataSource;
      if (filterFunction) {
        if (!this._isMounted) return;
        if (filterFunction) {
          result = result.filter(word => filterFunction(value, word));
        }
      }
    } else if (typeof dataSource === 'string') {
      this.setState({ fetching: true, searchLimitReached: false });
      result = await retrieveData(
        dataSource,
        this.generateParameters(value),
        this.onLimitReached
      );
      if (!this._isMounted) return;
      if (filterFunction) {
        result = result.filter(word => filterFunction(value, word));
      }
      this.setState({ fetching: false });
      if (this.props.onSearchLessThanMinimum) {
        this.props.onSearchLessThanMinimum(false);
      }
    }

    this.setState({ result: result, empty: result.length === 0 });
  };

  loading = () => {
    return (
      <Option key="loading" disabled={true}>
        <div>
          <Spin size="small" />
        </div>
      </Option>
    );
  };

  throttledSearch = value => {
    // If the query term is short or ends with a space, trigger the more eager version.
    if (
      this.props.useEagerSearch &&
      (value.length < 4 || value.endsWith(' '))
    ) {
      this.searchThrottled(value);
    } else {
      this.searchDebounced(value);
    }
  };

  empty = val => {
    return (
      <Option key="emptyResult" disabled={true}>
        <div style={{ padding: '8px' }}>
          <Empty
            description={
              <NoResult
                name={val ? val : ''}
                headerMsgId={this.props.emptyMsg}
                subTextMsgId={this.props.emptySubMsg}
              />
            }
          />
        </div>
      </Option>
    );
  };

  componentDidMount() {
    this._isMounted = true;
    // NOTE: this preloads the select options for drop downs that allow
    // users to select without typing first
    if (this.props.selectMode) {
      this.search('');
    }
  }

  // As with the componentDidMount if the dataSource changes, reset the search to preload dropdown results
  componentDidUpdate(prevProps) {
    if (
      this.props.selectMode &&
      this.props.dataSource !== prevProps.dataSource
    ) {
      this.search('');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      dataSource,
      childGenerator,
      selectMode,
      generateParameters,
      user,
      ...rest
    } = this.props;

    let unfilteredChildren = childGenerator(this.state.result);
    let children = [];
    let childrenKeys = [];
    unfilteredChildren.forEach(child => {
      if (!childrenKeys.includes(child.key)) {
        // Avoid rendering option elements with duplicate keys
        children.push(child);
        childrenKeys.push(child.key);
      }
    });
    if (this.state.searchLimitReached) {
      if (this.state.showAll) {
        children.unshift(
          <Option style={{ whiteSpace: 'unset' }} disabled key="limit">
            <Alert message={intl.get('search.limitReached')} type="warning" />
          </Option>
        );
      } else {
        children.push(
          <Option disabled key="all" className="show-all">
            <InlineButtonLink
              type="link"
              onClick={() => {
                this.setState({ showAll: true }, () =>
                  this.throttledSearch(this.state.searchValue)
                );
              }}
            >
              {intl.get('search.viewAllResults')}
            </InlineButtonLink>
          </Option>
        );
      }
    }

    // TODO: need to adjust some aspects if in mobile mode,
    // but better to present Desktop version than nothing for now at least
    return (
      <Select
        className="flex1-full-width"
        showSearch
        showAction={['focus', 'click']}
        filterOption={false}
        onSearch={term => {
          this.setState({ searchValue: term, showAll: false }, () =>
            this.throttledSearch(term)
          );
        }}
        notFoundContent={null}
        showArrow={selectMode}
        defaultActiveFirstOption={false}
        dropdownClassName={user.colorThemeClassName} // This is needed on the Select since AntD puts the select in a div child of body
        {...rest}
      >
        {this.state.empty
          ? this.empty(this.state.value)
          : this.state.fetching
          ? this.loading()
          : children}
      </Select>
    );
  }
}

// this is aleady connected to redux, should just get viewMode from there.
const mapStateToProps = ({ app }) => ({
  user: app.user,
  viewMode: app.data.viewMode
});

const GvlSearch = connect(mapStateToProps, null, null, { forwardRef: true })(
  GvlSearchComponent
);
export { GvlSearch as default, GvlSearchComponent };
