import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetPassword } from 'utils/api';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Spin,
  Card,
  Row,
  Col,
  message
} from 'antd';
import { Redirect } from 'react-router-dom';
import intl from 'react-intl-universal';
import styled from '@emotion/styled';
import { MEDIA, THEME } from 'style';
import {
  loginRequest,
  loginValidateTokenRequest
} from 'containers/app/store/login/loginActions';
import { LOGIN_STATUS } from 'containers/app/store/login/loginConstants';
import BackgroundSVG from 'images/logos/logo-gradient.svg';
import Logo from 'elements/Logo';
import SuccessKitty from 'images/success_kitty.svg';
import { DEPLOY_PLATFORM } from 'utils/config';

const ContainerRow = styled(Row)({
  height: '100vh',
  backgroundColor: THEME.secondaryBackgroundColor(),
  backgroundImage: `url(${BackgroundSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left 10% bottom -100px',
  backgroundSize: '200vw',

  [MEDIA.greaterThan('medium')]: {
    backgroundPosition: 'left -50% bottom 50%',
    backgroundSize: '90vw'
  }
});

const ContainerCol = styled(Col)({
  minWidth: '320px',
  maxWidth: '400px',
  width: '-webkit-fill-available'
});

const LoginBox = styled(Card)({
  boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.16)',
  padding: '16px 16px 0 16px'
});

const ClearIcon = styled(Icon)({
  color: 'rgba(0,0,0,.25)'
});

function getAlertMessage(loginStatus, onResetPassword) {
  switch (loginStatus) {
    case LOGIN_STATUS.invalid:
      return <Alert message={intl.get('login.invalid')} type="error" />;
    case LOGIN_STATUS.connectionError:
      return (
        <Alert
          message={intl.get('login.connectionError.message')}
          description={intl.get('login.connectionError.description')}
          type="error"
        />
      );
    case LOGIN_STATUS.exceededMaxAttempts:
      return (
        <Alert message={intl.get('login.exceededMaxAttempts')} type="error" />
      );
    case LOGIN_STATUS.accountDisabled:
      return (
        <Alert
          message={intl.get('login.accountDisabled.message')}
          description={intl.getHTML('login.accountDisabled.description')}
          type="error"
        />
      );
    case LOGIN_STATUS.tokenExpired:
      return (
        <Alert
          message={intl.get('login.tokenExpired.message')}
          description={intl.get('login.tokenExpired.description')}
          type="error"
        />
      );
    case LOGIN_STATUS.forgotPassword:
      return (
        <Alert
          message={intl.get('login.forgotPassword.message')}
          description={
            <span>
              {intl.get('login.forgotPassword.description.part1')}{' '}
              <Button
                className="unpadded"
                style={{ fontWeight: 'normal' }}
                type="link"
                size="small"
                onClick={onResetPassword}
              >
                {intl.get('login.forgotPassword.description.part2')}
              </Button>
              .
            </span>
          }
          type="error"
        />
      );
    default:
      return;
  }
}

class LoginComponent extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    loginStatus: PropTypes.oneOf(Object.keys(LOGIN_STATUS)),
    validatingLogin: PropTypes.bool.isRequired,
    tokenValidated: PropTypes.bool.isRequired,
    loginRequest: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    handlingExpiredToken: PropTypes.bool.isRequired,
    loginValidateTokenRequest: PropTypes.func.isRequired,
    loggingOut: PropTypes.bool,
    validatingToken: PropTypes.bool,
    variant: PropTypes.string,
    verificationStatus: PropTypes.string
  };

  static defaultProps = {
    error: null,
    variant: DEPLOY_PLATFORM === 'university' ? 'university' : 'default'
  };

  state = {};

  componentDidMount() {
    if (
      !this.props.handlingExpiredToken &&
      !this.props.loggingOut &&
      this.props.user &&
      this.props.user.authToken &&
      !this.props.tokenValidated
    ) {
      this.props.loginValidateTokenRequest(this.props.user.authToken);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(this.onValidate);
  };

  handleResetSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username } = values;
        resetPassword({ username });
        this.setState({ showReset: false, showResetSuccess: true, username });
      }
    });
  };

  // At this point username has been validated. Just need to resend the email and show success.
  handleResendEmail = async () => {
    await resetPassword({ username: this.state.username });
    message.success(intl.get('emailSent'));
  };

  onValidate = (err, values) => {
    if (!err) {
      const { userName, password } = values;
      if (document.cookie.indexOf('JSESSIONID')) {
        document.cookie =
          'JSESSIONID=; Path=/gvl2/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie =
          'JSESSIONID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
      this.props.loginRequest(userName, password);
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      user,
      loginStatus,
      validatingLogin,
      validatingToken,
      location,
      tokenValidated,
      loggingOut,
      handlingExpiredToken,
      variant,
      verificationStatus
    } = this.props;

    let { from } = location.state || { from: { pathname: '/' } };

    if (user.authToken && tokenValidated) {
      return <Redirect to={from} />;
    }

    let showLoginForm = true;
    let disableLogin = loginStatus === LOGIN_STATUS.exceededMaxAttempts;
    let message;

    if (
      handlingExpiredToken ||
      validatingToken ||
      loggingOut ||
      this.state.showLoginForm === false
    ) {
      // Just show the spinner when actions are being performed
      showLoginForm = false;
    } else {
      message = getAlertMessage(loginStatus, () =>
        this.setState({ showReset: true, showLoginForm: false })
      );
    }

    let logoProps = {};
    switch (variant) {
      case 'default':
        logoProps.style = {
          height: '72px',
          margin: '8px 8px 48px 8px',
          backgroundPosition: 'center',
          width: 'unset'
        };
        logoProps.type = 'color';
        break;
      case 'university':
        logoProps.style = {
          height: '122px',
          margin: '16px',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          width: 'unset'
        };
        logoProps.type = 'gvlUniversity';
        break;
      case 'newport':
        logoProps.style = {
          height: '72px',
          margin: '8px 8px 32px 8px',
          backgroundPosition: 'center',
          width: 'unset'
        };
        logoProps.type = 'herdpointPowered';
        break;
      default:
        break;
    }

    // Image is here so there it's available right when needed, rather than loading in after the confirmation page.
    const successKitty = (
      <img
        src={`${SuccessKitty}`}
        style={{ display: 'inline-block', height: '256px' }}
        alt=""
      />
    );

    return (
      <Spin
        spinning={
          handlingExpiredToken ||
          validatingLogin ||
          validatingToken ||
          loggingOut
        }
        size="large"
      >
        <ContainerRow type="flex" justify="space-around" align="middle">
          <ContainerCol>
            {verificationStatus === 'COMPLETED' && (
              <React.Fragment>
                <Alert
                  type="success"
                  showIcon
                  message={intl.get('register.success.login.message')}
                  description={intl.get('register.success.login.description')}
                />
                <br />
              </React.Fragment>
            )}
            <LoginBox bordered={false}>
              {DEPLOY_PLATFORM === 'university' && (
                <div style={{ textAlign: 'right' }}>
                  <Button href="register">{intl.get('login.register')}</Button>
                </div>
              )}
              {!this.state.showResetSuccess && <Logo {...logoProps} />}
              {message && (
                <React.Fragment>
                  {message}
                  <br />
                </React.Fragment>
              )}
              {showLoginForm && (
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item>
                    {/* TODO: figure out a pattern to simplify validation with antd */}
                    {getFieldDecorator('userName', {
                      rules: [
                        {
                          required: true,
                          message: intl.get('login.username.required')
                        }
                      ]
                    })(
                      <Input
                        prefix={<ClearIcon type="user" />}
                        placeholder={intl.get('login.username.placeholder')}
                        autoCapitalize="none"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: intl.get('login.password.required')
                        }
                      ]
                    })(
                      <Input.Password
                        prefix={<ClearIcon type="lock" />}
                        placeholder={intl.get('login.password')}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="full-width"
                      type="primary"
                      htmlType="submit"
                      disabled={disableLogin}
                    >
                      {intl.get('login')}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Checkbox>{intl.get('login.rememberMe')}</Checkbox>
                    {/* TODO: When work is done to finish the following link, also do it above on the "Exceeded login attempts" Alert */}
                    <Button
                      className="float-r unpadded"
                      style={{ fontWeight: 'normal' }}
                      type="link"
                      size="small"
                      onClick={() =>
                        this.setState({ showReset: true, showLoginForm: false })
                      }
                    >
                      {intl.get('login.resetPassword')}
                    </Button>
                  </Form.Item>
                </Form>
              )}
              {this.state.showReset && (
                <Form onSubmit={this.handleResetSubmit}>
                  <Alert
                    message={intl.get('login.resetPassword.message')}
                    type="info"
                  />
                  <br />
                  <Form.Item>
                    {getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: intl.get('login.username.placeholder')
                        }
                      ]
                    })(
                      <Input
                        name="username"
                        placeholder={intl.get('login.username.placeholder')}
                        autoCapitalize="none"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="full-width"
                      type="primary"
                      htmlType="submit"
                      disabled={disableLogin}
                    >
                      {intl.get('login.resetPassword')}
                    </Button>
                  </Form.Item>
                  <Button
                    className="float-r"
                    type="link"
                    size="small"
                    onClick={() =>
                      this.setState({ showReset: false, showLoginForm: true })
                    }
                  >
                    {intl.get('cancel')}
                  </Button>
                </Form>
              )}
              {this.state.showResetSuccess && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: '0px',
                    textAlign: 'center'
                  }}
                >
                  <h1>{intl.get('reset.checkEmail')}</h1>
                  {successKitty}
                  <p style={{ fontSize: '1.25rem', marginBottom: '32px' }}>
                    {intl.get('reset.emailSent')}
                  </p>
                  <Button type="link" onClick={this.handleResendEmail}>
                    {intl.get('resendEmail')}
                  </Button>
                </div>
              )}
              {variant === 'newport' ? (
                <p style={{ margin: '0 -24px -8px' }}>
                  {intl.get('herdpointTrademarkMessage')}
                </p>
              ) : null}
            </LoginBox>
          </ContainerCol>
        </ContainerRow>
      </Spin>
    );
  }
}

const LoginForm = Form.create({
  name: 'loginForm'
})(LoginComponent);

const mapStateToProps = ({ app, registration }) => ({
  user: app.user,
  loginStatus: app.login.loginStatus,
  validatingLogin: app.login.validatingLogin,
  validatingToken: app.login.validatingToken,
  tokenValidated: app.login.tokenValidated,
  loggingOut: app.login.loggingOut,
  handlingExpiredToken: app.login.handlingExpiredToken,
  verificationStatus: registration.user.verificationStatus
});

const mapDispatchToProps = {
  loginRequest,
  loginValidateTokenRequest
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export { Login as default, LoginComponent };
