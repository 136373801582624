import { invariant } from './util';

const COLORS = {
  black: {
    500: 'rgb(100, 100, 100)',
    700: 'rgb(50, 50, 50)',
    900: 'rgb(0, 0, 0)'
  },
  blue: {
    500: '#00ADE5',
    600: '#00A3DF',
    300: '#27C6F2', // This matches the AntD Link hover color
    700: '#E6FCFF'
  },
  aqua: {
    500: '#6BCGCE'
  },
  teal: {
    500: '#6BC9CE'
  },
  green: {
    500: '#AFD04F'
  },
  white: {
    100: '#FFFFFF',
    300: '#EEEEEE',
    500: '#DDDDDD',
    700: '#CCCCCC',
    900: '#AAAAAA'
  },
  text: {
    100: '#808F99',
    300: '#3F5766',
    500: '#001F33'
  },
  background: {
    100: '#F8F8F8',
    300: '#F4F4F4'
  },
  border: {
    100: '#E8E8E8',
    300: '#D3DBE0'
  },
  red: {
    // Imported from \ant-design\components\style\color\colors.less @red-6
    500: '#f5222d'
  },

  //Secondary Color Palette
  darkblue: {
    500: '#2d2d96'
  },
  purple: {
    500: '#8777d1'
  },
  orange: {
    500: '#f4aa3d'
  },
  yellow: {
    500: '#efd84f'
  },
  // TODO: fix this unwise conflation of font-weight or color shade with color tone.
  // 200 is green, 400 is red. how would anyone know that or expect that to be the case?
  autogenousVaccines: {
    100: '#00ADE5',
    200: '#5CA329',
    300: '#8777D1',
    400: '#ED5A56',
    500: '#00A5AD',
    600: '#001f33'
  }
};

const getColor = (base, defaultShade) => shade => {
  const baseColor = COLORS[base] || {};
  const color = baseColor[shade || defaultShade];
  invariant(
    color,
    `The shade ${shade} was not found in ${Object.keys(baseColor).join(' | ')}`
  );
  return color ? color : '#000000';
};

//User Theme
export let THEME = {
  red: getColor('red', 500),
  blue: getColor('blue', 500),
  black: getColor('black', 500),
  white: getColor('white', 100),
  aqua: getColor('aqua', 500),
  teal: getColor('teal', 500),
  green: getColor('green', 500),
  mainBackgroundColor: getColor('background', 100),
  secondaryBackgroundColor: getColor('background', 300),
  lightBorderColor: getColor('border', 100),
  mainBorderColor: getColor('border', 300),
  dividerColor: getColor('border', 300),
  brand: getColor('blue', 500),
  text: getColor('text', 500),
  disabledText: getColor('text', 100),
  tabText: getColor('text', 100),
  tabTextSelected: getColor('blue', 600),
  secondaryText: getColor('text', 300),
  brandHighlight: getColor('blue', 700),
  linkHover: getColor('blue', 300),

  //Secondary colors
  darkblue: getColor('darkblue', 500),
  purple: getColor('purple', 500),
  orange: getColor('orange', 500),
  yellow: getColor('yellow', 500),

  //Autogenous vaccine status colors
  statusBlue: getColor('autogenousVaccines', 100),
  statusGreen: getColor('autogenousVaccines', 200),
  statusPurple: getColor('autogenousVaccines', 300),
  statusRed: getColor('autogenousVaccines', 400),
  statusTeal: getColor('autogenousVaccines', 500),
  isolateBlack: getColor('autogenousVaccines', 600),

  // Property Values
  drawerWidth: window.innerWidth > 720 ? 720 : window.innerWidth,
  subDrawerWidth: window.innerWidth > 432 ? 432 : window.innerWidth
};

let resizeDebounceTimer;

const adjustThemeOnResize = () => {
  clearTimeout(resizeDebounceTimer);
  resizeDebounceTimer = setTimeout(() => {
    THEME.drawerWidth = window.innerWidth > 720 ? 720 : window.innerWidth;
    THEME.subDrawerWidth = window.innerWidth > 432 ? 432 : window.innerWidth;
  }, 1000);
};

window.addEventListener('resize', adjustThemeOnResize);
