import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Modal, Button, Input, Radio, Form, Tooltip, Icon } from 'antd';
import GvlDatePicker from 'elements/GvlDatePicker';
import License from 'modules/License/License';
import {
  AlertExpired,
  AlertTerminated,
  AlertSuspended,
  AlertRevoked,
  AlertIncorrect,
  AlertAccredited,
  NameAndNumber
} from 'components/NvapLicenseWarningModal';
import moment from 'moment';
import StateSelect from 'elements/StateSelect';

class EditLicenseModalComponent extends Component {
  static propTypes = {
    license: PropTypes.object,
    licenseList: PropTypes.array,
    country: PropTypes.string.isRequired,
    vetId: PropTypes.string,
    displayName: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    form: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onLicenseSaveRequest: PropTypes.func.isRequired
  };

  static defaultProps = {
    country: 'USA'
  };

  handleSubmit = event => {
    event.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      let license;
      if (!err) {
        license = new License({
          ...this.props.license,
          ...values
        });
        license.vetId = this.props.vetId;
        if (this.props.license) license.licenseId = this.props.license.id;
        this.props.onLicenseSaveRequest(license);
        this.resetFormAndClose();
        return;
      }
    });
  };

  resetFormAndClose = () => {
    const { form, onCancel } = this.props;
    form.resetFields();
    onCancel();
  };

  getValueForIssuingAuthority(form, license) {
    const issuingAuthority = license?.issuingAuthority;
    if (form.getFieldValue('licenseType') === 'Federal') {
      // make sure the issuingAuthority is not currently a state
      return ['FEDERAL', 'DEA'].includes(issuingAuthority)
        ? issuingAuthority
        : null;
    } else {
      // make sure the issuingAuthority is not currently a federal type
      return !['FEDERAL', 'DEA'].includes(issuingAuthority)
        ? issuingAuthority
        : null;
    }
  }

  getValueForLicenseType(license) {
    if (license) {
      return ['FEDERAL', 'DEA'].includes(license.issuingAuthority)
        ? 'Federal'
        : 'State';
    } else {
      return null;
    }
  }

  render() {
    const { form, license, country, visible, licenseList } = this.props;
    const AlertComponent = {
      NOT_FOUND: AlertIncorrect,
      EXPIRED: AlertExpired,
      REVOKED: AlertRevoked,
      SUSPENDED: AlertSuspended,
      TERMINATED: AlertTerminated,
      ACCREDITED: AlertAccredited
    }[license?.validationStatus];

    let preExistingLicenseAuthoritiesList = [];
    let noFedOption = false;
    if (licenseList) {
      preExistingLicenseAuthoritiesList = licenseList.map(
        item => item.issuingAuthority
      );
      noFedOption = preExistingLicenseAuthoritiesList.includes('FEDERAL');
      if (license) {
        preExistingLicenseAuthoritiesList = preExistingLicenseAuthoritiesList.filter(
          item => item !== license.issuingAuthority
        );
      }
    }

    const alert = AlertComponent ? (
      <AlertComponent key="alert">
        <NameAndNumber
          name={this.props.displayName}
          number={license?.licenseNumber}
        />
      </AlertComponent>
    ) : null;

    return (
      <Modal
        destroyOnClose
        title={license ? intl.get('edit.license') : intl.get('add.license')}
        visible={visible}
        closable={false}
        footer={
          <React.Fragment>
            <Button
              type="default"
              onClick={() => {
                form.isFieldsTouched()
                  ? Modal.confirm({
                      title: intl.get('cancel.edit'),
                      content: intl.get('cancel.edit.body'),
                      okText: intl.get('discard'),
                      onOk: this.resetFormAndClose
                    })
                  : this.resetFormAndClose();
              }}
            >
              {intl.get('cancel')}
            </Button>
            <Button
              id="license-form-submit"
              type="primary"
              form="licenseForm"
              htmlType="submit"
            >
              {intl.get('save')}
            </Button>
          </React.Fragment>
        }
      >
        <Form id="licenseForm" onSubmit={this.handleSubmit}>
          <Form.Item label="License type">
            {form.getFieldDecorator('licenseType', {
              initialValue: this.getValueForLicenseType(license),
              rules: [
                {
                  required: true,
                  message: intl.get('required')
                }
              ]
            })(
              <Radio.Group name="licenseType">
                <Radio value="Federal">{intl.get('federal')}</Radio>
                <Radio value="State">
                  {intl.get(country === 'USA' ? 'state' : 'province')}
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                {intl.get('issuing.authority')}
                <Tooltip title={intl.get('license.preexisting.tooltip')}>
                  <Icon
                    type="info-circle"
                    className="pad-1space-left"
                    style={{
                      verticalAlign: 'text-top'
                    }}
                  />
                </Tooltip>
              </>
            }
          >
            {form.getFieldDecorator('issuingAuthority', {
              initialValue: this.getValueForIssuingAuthority(form, license),
              rules: [
                {
                  required: true,
                  message: intl.get('required')
                }
              ]
            })(
              <StateSelect
                alternateOptions={[
                  { value: 'FEDERAL', text: 'FEDERAL', disabled: noFedOption },
                  { value: 'DEA', text: 'DEA', disabled: false }
                ]}
                areAlternateOptionsShown={
                  form.getFieldValue('licenseType') === 'Federal'
                }
                disabledStatesList={preExistingLicenseAuthoritiesList}
                selectedCountry={country}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              form.getFieldValue('licenseType') === 'Federal' &&
              country === 'USA'
                ? intl.get('accreditation.number')
                : intl.get('license.number')
            }
          >
            {form.getFieldDecorator('licenseNumber', {
              initialValue: license?.licenseNumber,
              rules: [
                {
                  required: true,
                  message: intl.get('license.federal.invalid'),
                  validator: (_rule, value, callback) => {
                    if (
                      form.getFieldValue('issuingAuthority') === 'FEDERAL' &&
                      country === 'USA'
                    ) {
                      const passesValidation = /^\d{6}$/.test(value);
                      passesValidation ? callback() : callback(true);
                    } else {
                      callback();
                    }
                  }
                }
              ]
            })(<Input />)}
          </Form.Item>
          {license?.issuingAuthority === 'FEDERAL' && country === 'USA'
            ? alert
            : null}
          <Form.Item label={intl.get('issue.date')}>
            {form.getFieldDecorator('dateIssued', {
              initialValue: license?.dateIssued
                ? moment(license.dateIssued)
                : null
            })(<GvlDatePicker />)}
          </Form.Item>
          <Form.Item label={intl.get('date.expiration')}>
            {form.getFieldDecorator('dateExpires', {
              initialValue: license?.dateExpires
                ? moment(license.dateExpires)
                : null
            })(<GvlDatePicker />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const EditLicenseModal = Form.create({ name: 'licenseEdit' })(
  EditLicenseModalComponent
);

export { EditLicenseModal as default, EditLicenseModalComponent };
