import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment';
import Vfd from 'models/documents/Vfd';
import { sortEntityListByReverseId } from 'utils/appUtil';

export const getClinicDataLists = state => state.accounts.clinic.dataLists;
const getDocuments = docState => docState.data.documents;

export const getDrafts = createSelector(getDocuments, documents =>
  Object.values(documents).filter(
    doc => (doc.metadata || {}).status === 'SAVED'
  )
);

export const getRecentlyCompleted = createSelector(getDocuments, documents =>
  Object.values(documents).filter(
    doc =>
      (doc.metadata || {}).status === 'SIGNED' &&
      moment()
        .subtract(14, 'days')
        .isBefore(doc.signingDate)
  )
);

export const getPartiallySigned = createSelector(getDocuments, documents =>
  Object.values(documents).filter(
    doc => (doc.metadata || {}).status === 'PARTIALLY_SIGNED'
  )
);

export const getIsReadyToSign = createSelector(getDocuments, documents =>
  Object.values(documents).filter(
    doc => (doc.metadata || {}).status === 'READY_TO_SIGN'
  )
);

export const getRenewals = createSelector(getDocuments, documents => {
  let dateRange = Vfd.getRenewalsDateRange();
  return Object.values(documents).filter(
    doc =>
      (doc.metadata || {}).status === 'SIGNED' &&
      doc.metadata.renewalStatus === 'NONE' &&
      dateRange.since.isBefore(doc.expirationDate) &&
      dateRange.until.isAfter(doc.expirationDate)
  );
});

export const getRequests = docState => Object.values(docState.data.requests);

// TODO remove this and use the other `getRequests` selector once cvi requests are restrucutred to match the vfd approach
export const getCviRequests = createSelector(getDocuments, documents =>
  Object.values(documents).filter(
    doc => doc.requestId // Only requests will have this value
  )
);

export const getVfdDocumentLists = createStructuredSelector({
  drafts: getDrafts,
  recentlyCompleted: getRecentlyCompleted,
  renewalReviews: getPartiallySigned,
  draftReviews: getIsReadyToSign,
  renewals: getRenewals,
  requests: getRequests
});

export const getEiaDocumentLists = createStructuredSelector({
  drafts: getDrafts,
  labApprovals: getPartiallySigned,
  recentlyCompleted: getRecentlyCompleted,
  requests: getRequests
});

// TODO if rabies & ihcs could accept "recentlyCompleted" could share selectors even more
export const getDraftsAndRecentlySigned = createStructuredSelector({
  drafts: getDrafts,
  recentlySigned: getRecentlyCompleted
});

export const getCviDocumentLists = createStructuredSelector({
  drafts: getDrafts,
  recentlyCompleted: getRecentlyCompleted,
  awaitingPermit: getIsReadyToSign,
  requests: getCviRequests
});

export const getIhcDocumentLists = createStructuredSelector({
  drafts: getDrafts,
  readyToSign: getIsReadyToSign,
  recentlyCompleted: getRecentlyCompleted
});

export const getContactLists = createStructuredSelector({
  requests: getCviRequests
});

export const getEecviDocumentLists = createStructuredSelector({
  drafts: getDrafts,
  recentlyCompleted: getRecentlyCompleted
});

// used by sagas to retrieve a specific document from store

export const documentSelector = (state, type, id) =>
  state.accounts.clinic.dataLists[type].data.documents[id];

const getContacts = contactState => contactState.data.contacts;

export const getContactList = createSelector(getContacts, contacts =>
  sortEntityListByReverseId(Object.values(contacts))
);

const getHomeList = docState => docState.data.documents;

export const getHomeDocumentLists = createSelector(getHomeList, home =>
  Object.values(home).filter(doc => doc.id)
);

const getImages = imageState => imageState.images;

export const getImageList = createSelector(getImages, images =>
  sortEntityListByReverseId(Object.values(images))
);
const getAnimals = animalState => animalState.data.animals;

export const getAnimalList = createSelector(getAnimals, animals =>
  sortEntityListByReverseId(Object.values(animals))
);

const getHorseSyncAnimals = animalState => animalState.data.partialAnimals;

export const getHorseSyncAnimalList = createSelector(
  getHorseSyncAnimals,
  animals => Object.values(animals)
);

const getCertificatesSearchResults = certificatesSearchState =>
  certificatesSearchState.results;

export const getActiveCertificatesSearchResults = createSelector(
  // Filter out old document types that we don't want displayed.
  // Using a blacklist since we want to get any new Document types that might be added in the future.
  // Type === 'PC' is a "Green Tag" document
  getCertificatesSearchResults,
  certificates => {
    if (!certificates) {
      return null;
    }

    return certificates.filter(
      certificate => certificate.type !== 'PC' && certificate.type !== 'VFR'
    );
  }
);
