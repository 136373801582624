import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import { GvlMenu } from 'style/commonEmotions';
import { BACKEND_BASENAME } from 'utils/config';

function MvlOwnerMenuComponent({ location }) {
  return (
    <GvlMenu selectedKeys={[location.pathname]} mode="inline">
      <GvlMenuItem
        key="/animals"
        link="/animals"
        isTopLevel={true}
        icon="gvl-animal"
        title={intl.get('animals')}
      />
      <GvlMenuItem
        key="/certificates"
        link="/certificates"
        isTopLevel={true}
        icon="gvl-document"
        title={intl.get('certificates')}
      />
      <GvlSubMenu
        popupClassName="gvl-theme-purple"
        key="help"
        title={intl.get('help')}
        icon="gvl-help"
      >
        <GvlMenuItem
          key="animalRegs"
          external={true}
          link="https://animalregs.com/"
          title={intl.get('animalRegs.com')}
        />
        <GvlMenuItem
          key="findAVet"
          external={true}
          link={`${BACKEND_BASENAME}/find-a-vet`}
          title={intl.get('findAVet')}
        />
      </GvlSubMenu>
    </GvlMenu>
  );
}

MvlOwnerMenuComponent.propTypes = {
  location: PropTypes.object.isRequired
};

const MvlOwnerMenu = withRouter(MvlOwnerMenuComponent);

export { MvlOwnerMenu as default, MvlOwnerMenuComponent };
