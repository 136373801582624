import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Form, Skeleton, Spin } from 'antd';
import { commonLoadStartupDataRequest as loadStartupData } from 'containers/accounts/common/store/accountsCommonActions';
import {
  loadRegistration,
  renewVerification,
  verifyRegistration
} from 'modules/Registration/User/store/userRegistrationActions';
import {
  generateImgFromText,
  convertToBase64
} from 'containers/registration/RegistrationUtils';
import { RegistrationLayout } from 'modules/Registration/Account/accountRegistrationIndex';
import {
  UserRegistration,
  UserRegistrationInformation,
  VetRegistrationInformation
} from 'modules/Registration/User/userRegistrationIndex';
import GvlCard from 'elements/GvlCard';

const RegistrationDisplayTypeEnum = {
  GENERIC: 'GENERIC',
  VET: 'VET',
  EXPIRED: 'EXPIRED',
  CODE_MISMATCH: 'CODE_MISMATCH',
  FAILURE: 'FAILURE',
  LINK_USED: 'LINK_USED'
};

function UserRegistrationVerificationPage({ history, match, form }) {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.registration.user.isRefreshing);
  const failState = useSelector(
    state => state.registration.user.verificationStatus
  );
  const [displayType, setDisplayType] = useState();

  useEffect(() => {
    function onRegistrationLoaded(registration) {
      if (registration.appUser.rolesList.includes('Vet')) {
        setDisplayType(RegistrationDisplayTypeEnum.VET);
      } else {
        setDisplayType(RegistrationDisplayTypeEnum.GENERIC);
      }

      form.setFieldsValue({
        firstName: registration.appUser.person.firstName,
        lastName: registration.appUser.person.lastName,
        username: registration.appUser.email
      });
    }
    dispatch(
      loadRegistration(match.params.id, match.params.code, onRegistrationLoaded)
    );
    dispatch(loadStartupData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function verify({ licenseList = [], signatureInputMode = '' }) {
    form.validateFieldsAndScroll({ force: true }, async (err, formValues) => {
      if (err) return;

      let userRegistration = new UserRegistration({
        ...formValues,
        verificationCode: match.params.code
      });

      if (displayType === RegistrationDisplayTypeEnum.VET) {
        let signature = '';
        if (signatureInputMode === 'upload') {
          const signatureFile = form.getFieldValue('signatureImg');
          signature = await convertToBase64(signatureFile);
        } else if (signatureInputMode === 'esign') {
          const text = form.getFieldValue('signatureText');
          const font = form.getFieldValue('signatureFont');
          signature = generateImgFromText(text, font);
        }

        userRegistration.signature = signature;
        userRegistration.licenses = licenseList;
      }

      dispatch(
        verifyRegistration(match.params.id, userRegistration.toJson(), history)
      );
    });
  }

  const informationProps = {
    form,
    onValidateUsername: () => {},
    submitButtonInfo: {
      messageId: 'account.create',
      onClick: verify
    }
  };

  return (
    <RegistrationLayout>
      <Spin spinning={loading} size="large">
        {displayType === RegistrationDisplayTypeEnum.GENERIC && (
          <UserRegistrationInformation {...informationProps} />
        )}
        {displayType === RegistrationDisplayTypeEnum.VET && (
          <VetRegistrationInformation {...informationProps} />
        )}
        {failState === RegistrationDisplayTypeEnum.EXPIRED && (
          <FailStateCard>
            <h1 style={{ margin: '32px' }}>
              {intl.get('register.expired.link')}
            </h1>
            <Button
              data-testid="expiredBtn"
              type="link"
              onClick={() => dispatch(renewVerification(match.params.id))}
            >
              {intl.get('register.request.link')}
            </Button>
          </FailStateCard>
        )}
        {failState === RegistrationDisplayTypeEnum.LINK_USED && (
          <FailStateCard>
            <h1 style={{ margin: '32px' }}>{intl.get('register.used.link')}</h1>
            <Button
              data-testid="usedLinkBtn"
              type="link"
              onClick={() => history.push('/login')}
            >
              {intl.get('login.screen.prompt')}
            </Button>
          </FailStateCard>
        )}
        {failState === RegistrationDisplayTypeEnum.CODE_MISMATCH && (
          <FailStateCard>
            <h1 style={{ margin: '32px' }}>
              {intl.get('register.expired.link')}
            </h1>
            <h4 style={{ margin: '32px' }}>
              {intl.get('register.updated.link')}
            </h4>
          </FailStateCard>
        )}
        {failState === RegistrationDisplayTypeEnum.FAILURE && (
          <FailStateCard>
            <h1 style={{ margin: '32px' }}>
              {intl.get('login.connectionError.message')}
            </h1>
            <h4>{intl.get('connectionError.heading')}</h4>
            <h4>{intl.get('register.contact.support')}</h4>
          </FailStateCard>
        )}
        {loading && !displayType && (
          <GvlCard>
            <div>
              <Skeleton paragraph={{ rows: 1 }} />
              <Skeleton paragraph={{ rows: 1 }} />
              <Skeleton paragraph={{ rows: 1 }} />
              <Skeleton paragraph={{ rows: 1 }} />
            </div>
          </GvlCard>
        )}
      </Spin>
    </RegistrationLayout>
  );
}

UserRegistrationVerificationPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

const UserRegistrationVerification = Form.create({ name: 'userVerification' })(
  UserRegistrationVerificationPage
);

export { UserRegistrationVerification as default };

function FailStateCard({ children }) {
  return (
    <GvlCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        {children}
      </div>
    </GvlCard>
  );
}

FailStateCard.propTypes = {
  children: PropTypes.array
};
