import Model from 'models/Model';
import { stringRequired, genericArrayRequired } from 'utils/validation/common';
import * as api from 'utils/api';

export default class Product extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      productId: {
        initialValue: undefined
      },
      name: {
        initialValue: ''
      },
      productNbr: {
        initialValue: '',
        validate: productNbr => stringRequired(productNbr, 'required')
      },
      serialNbr: {
        initialValue: '',
        validate: serialNbr => stringRequired(serialNbr, 'required')
      },
      initialOrderDate: {
        initialValue: undefined,
        validate: initialOrderDate =>
          stringRequired(initialOrderDate, 'required')
      },
      shipDate: {
        initialValue: undefined
      },
      clinics: {
        initialValue: undefined
      },
      vets: {
        initialValue: undefined
      },
      isolateIds: {
        initialValue: undefined,
        validate: isolateIds => genericArrayRequired(isolateIds, 'required')
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/product';
  }

  static get domain() {
    return 'Product';
  }

  static get idFieldName() {
    return 'productId';
  }

  static list = async params => await super.list(params);

  static read = async productId => await super.read(productId);

  static delete = async productId => await super.deleteRec(productId);

  static isolates = async productId =>
    await api.get(`${Product.apiPath}/${productId}/isolates`);
}
