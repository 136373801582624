import intl from 'react-intl-universal';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notification, message } from 'antd';
import * as actions from './productActions';
import * as types from './productActionTypes';
import * as isolateActions from 'modules/Isolate/store/isolateActions';
import { Product } from 'modules/Product/productIndex';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { difference, cloneDeep, uniq } from 'lodash';
import { exportCsv, formatDate } from 'utils/csvUtil';
// Would be nice to use minified version of objectExporter, but has some errors. Probably since it wasn't built for use inside a React app.
import objectExporter from 'utils/objectExporter/objectexporter';

export function* onProductSearchRequest({ searchType, searchParams, cb }) {
  try {
    yield put(actions.isRefreshing(true));
    const list = yield call(Product.list, searchParams);

    if (searchType === 'export') {
      if (list.results.length > 0) {
        const csvLayout = [
          {
            heading: intl.get('product'),
            property: 'name'
          },
          {
            heading: intl.get('clinic'),
            property: 'clinics',
            dataFormatFunction: clinics =>
              clinics.map(clinic => clinic.name).join('; ')
          },
          {
            heading: intl.get('veterinarians'),
            property: 'vets',
            dataFormatFunction: vets =>
              uniq(vets.map(vet => vet.commonName)).join('; ')
          }
        ];
        const fileName = `ProductsExport_${formatDate(new Date())}.csv`;
        exportCsv(csvLayout, list.results, fileName);
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    } else if (searchType === 'exportPdf') {
      if (list.results.length > 0) {
        const fileName = `ProductsExport_${formatDate(new Date())}`;
        let exportable = [];
        for (let result of list.results) {
          exportable.push({
            product: result.name || '',
            clinic: result.clinics.map(clinic => clinic.name).join('; ') || '',
            vets: uniq(result.vets.map(vet => vet.commonName)).join('; ') || '',
            _padding: undefined // objectExporter won't populate the last column without this padding column...
          });
        }
        objectExporter({
          exportable: exportable,
          type: 'pdf',
          headers: [
            intl.get('product'),
            intl.get('clinic'),
            intl.get('veterinarians')
          ],
          fileName: fileName,
          headerStyle:
            'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
          cellStyle: 'border: 1px solid lightgray; margin-bottom: -1px;',
          documentTitle: fileName
          // documentTitleStyle
          // repeatHeader: true
        });
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    }
    yield put(actions.searchSuccess(list.results));
    if (typeof cb === 'function') cb(list);
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.products')));
    yield put(actions.searchFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onProductListRequest() {
  try {
    yield put(actions.isRefreshing(true));
    let ninetyDays = new Date();
    ninetyDays.setDate(-90);
    let response = yield call(Product.list, {
      limit: 250, // default limit of 25 at a time is too slow
      lastUpdatedSince: ninetyDays // TODO: 18 months (547.501 days) would be more helpful?
    });
    yield put(actions.listSuccess(response.results));
    while (response.offset < response.count) {
      response = yield call(Product.list, {
        limit: 250, // default limit of 25 at a time is too slow
        offset: response.offset + response.limit,
        lastUpdatedSince: ninetyDays // TODO: 18 months (547.501 days) would be more helpful?
      });
      if (response.results?.length > 0) {
        yield put(actions.listSuccess(response.results));
      }
    }
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.products')));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onRefreshProductRequest({ productId, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    const product = yield call(Product.read, productId);
    yield put(actions.refreshSuccess(product));
    if (callback) {
      callback(product);
    }
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.products')));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveProductRequest({ product, callback }) {
  try {
    const isolates = yield select(state => state.accounts.isolates.data);
    const productInState = yield select(
      state => state.accounts.products.data[product.productId]
    );
    const isolatesToUnlink = difference(
      productInState?.isolateIds,
      product.isolateIds
    );

    yield put(actions.isRefreshing(true));
    yield call([product, product.save]);
    yield put(actions.refreshSuccess(product));

    //Remove products from the isolate store
    for (let i = 0; i < isolatesToUnlink.length; i++) {
      // Remove the product from the list of product numbers
      let isolate = cloneDeep(isolates[isolatesToUnlink[i]]);
      isolate.products = isolate.products.filter(
        productNbr => productNbr !== product.productNbr
      );
      yield put(isolateActions.refreshSuccess(isolate));
    }

    for (let i = 0; i < product.isolateIds.length; i++) {
      // Update cached isolates instead of triggering an API call to refresh.
      const cachedIsolate = isolates[product.isolateIds[i]];
      if (cachedIsolate) {
        let isolate = cloneDeep(cachedIsolate);
        // If isolate initial ship date is later than the product ship date, update the product (will already have been updated on the backend)
        if (
          product.shipDate &&
          (!isolate.initialShipDate ||
            moment(product.shipDate).diff(moment(isolate.initialShipDate)) < 0)
        ) {
          isolate.initialShipDate = product.shipDate;
        }

        // Add the product to the isolate's products (reflects what the backend looks like)
        if (!isolate.products.includes(product.name)) {
          isolate.products.push(product.name);
        }
        yield put(isolateActions.refreshSuccess(isolate));
      }
    }

    if (callback) {
      callback();
    }
    message.success(intl.get('savedWithNoun', { noun: intl.get('product') }));
  } catch (err) {
    console.error('onSaveProductRequest', err);
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('product.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeleteRequest({ productId, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(Product.delete, productId);
    yield put(actions.removeFromStore(productId));
    if (history) {
      history.push(`/products`);
      message.success(intl.get('deletedThing', { thing: intl.get('product') }));
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.deleting.properNoun', {
          properNoun: intl.get('product.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* productSagas() {
  yield takeLatest(types.LIST_REQUEST, onProductListRequest);
  yield takeLatest(types.SEARCH_REQUEST, onProductSearchRequest);
  yield takeLatest(types.REFRESH_REQUEST, onRefreshProductRequest);
  yield takeLatest(types.SAVE_REQUEST, onSaveProductRequest);
  yield takeLatest(types.DELETE_REQUEST, onDeleteRequest);
}
