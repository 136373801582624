import Model from 'models/Model';
import * as api from 'utils/api';
import { isNumber, numberInRange } from 'utils/validation/common';
import { downloadFile } from 'utils/downloadUtil';
export default class Rules extends Model {
  // Instance Methods

  validate() {
    let superValidation = super.validate();
    let results = superValidation ? superValidation : [];

    // Put complex validations here.  If none, then delete this whole validate() method.

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        validate: val => {
          isNumber(val, 'validation.id.number') ||
            numberInRange(val, 0, Number.MAX_SAFE_INTEGER);
        }
      },
      rule: {
        initialValue: null
      },
      feedback: {
        initialValue: null
      },
      active: {
        initialValue: false
      }
    };
  }

  static get apiPath() {
    return 'regulatoryRules';
  }

  static get domain() {
    return 'RegulatoryRules';
  }

  static getRulesData = async params => {
    const data = await super.list(params);
    return data;
  };

  // active represents the desired state of the rule: (true if rule to be set active, false if rule is to be inactive)
  // in practice, rules can be active in two tiers: set active by GVL and set active by RegAuth
  // if you want to 'upgrade' the active status to RegAuth approved/active
  // then you want to call this method as Rule.setRuleStatus(##, true)
  static setRulesStatus = async (id, active) => {
    const status = active ? 'activate' : 'deactivate';
    return await api.put(`${this.apiPath}/${id}/${status}`);
  };

  static addFeedback = async (id, feedback) => {
    return await api.put(`${this.apiPath}/${id}/feedback`, feedback);
  };
  static getWeeklyReports = async id => {
    const data = await api.get(`regulatoryAuthority/${id}/zipFiles`);
    return data.results;
  };

  static downloadWeeklyReports = (id, fileName) =>
    downloadFile(`regulatoryAuthority/zipFile/${id}/?fileName=${fileName}`);
}
