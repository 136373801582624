export const ADMIN_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/Admin/ADMIN_LOAD_STARTUP_DATA_REQUEST';
export const ADMIN_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/Admin/ADMIN_LOAD_STARTUP_DATA_SUCCESS';
export const ADMIN_SET_RETRIEVING_STARTUP_DATA =
  'cricket/Accounts/Admin/ADMIN_SET_RETRIEVING_STARTUP_DATA';

// Accounts

export const ADMIN_ACTIVATE_ACCOUNT =
  'cricket/Accounts/Admin/ADMIN_ACTIVATE_ACCOUNT';
export const ADMIN_REFRESH_ACCOUNT_REQUEST =
  'cricket/Accounts/Admin/ADMIN_REFRESH_ACCOUNT_REQUEST';
export const ADMIN_SAVE_ACCOUNT_REQUEST =
  'cricket/Accounts/Admin/ADMIN_SAVE_ACCOUNT_REQUEST';
export const ADMIN_CREATE_AR_ACCOUNT_REQUEST =
  'cricket/Accounts/Admin/ADMIN_CREATE_AR_ACCOUNT_REQUEST';

// Subscriptions

export const ADMIN_SAVE_SUBSCRIPTION_REQUEST =
  'cricket/Accounts/Admin/ADMIN_SAVE_SUBSCRIPTION_REQUEST';
export const ADMIN_SAVE_SUBSCRIPTION_SUCCESS =
  'cricket/Accounts/Admin/ADMIN_SAVE_SUBSCRIPTION_SUCCESS';

export const ADMIN_SYNC_SUBSCRIPTION_REQUEST =
  'cricket/Accounts/Admin/ADMIN_SYNC_SUBSCRIPTION_REQUEST';

export const ADMIN_ACTIVATE_AR_SUBSCRIPTION =
  'cricket/Accounts/Admin/ADMIN_ACTIVATE_AR_SUBSCRIPTION';
export const ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST =
  'cricket/Accounts/Admin/ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST';
export const ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS =
  'cricket/Accounts/Admin/ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS';

// Generic

export const ADMIN_ACTIVATE_DOMAIN =
  'cricket/Accounts/Admin/ADMIN_ACTIVATE_DOMAIN';
export const ADMIN_SET_REFRESH_DOMAIN =
  'cricket/Accounts/Admin/ADMIN_SET_REFRESH_DOMAIN';
export const ADMIN_REFRESH_DOMAIN_REQUEST =
  'cricket/Accounts/Admin/ADMIN_REFRESH_DOMAIN_REQUEST';
export const ADMIN_SAVE_REQUEST = 'cricket/Accounts/Admin/ADMIN_SAVE_REQUEST';
export const ADMIN_DOMAIN_ACTION_SUCCESS =
  'cricket/Accounts/Admin/ADMIN_DOMAIN_ACTION_SUCCESS';
