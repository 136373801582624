// Holds user info
// This store is persisted and auto-synced to local storage, thus the reason it is in its own store and not in app.data store.
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import * as userRoles from 'utils/userRoles';
import * as settingStorage from 'utils/settingStorage';
import moment from 'moment';
import { getUserSettings } from 'utils/userSettingsUtil';

import {
  USER_UPDATE,
  USER_RESET,
  USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS,
  USER_GET_INFO_SUCCESS,
  //Notifications
  USER_REFRESH_NOTIFICATIONS_STARTED,
  USER_NOTIFICATIONS_SUCCESS,
  USER_NOTIFICATIONS_FAILURE,

  // Smart Engine
  USER_TOGGLE_SMART_ENGINE_STATUS,

  //User settings
  UPDATE_USER_SETTINGS,

  // Profile
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_DONE,
  USER_GET_PROFILE_SUCCESS
} from 'containers/app/store/user/userConstants';

export const initialState = Immutable.from({
  authToken: null, // From api/gettoken data
  username: null, // From api/gettoken data
  roles: [], // From api/gettoken data
  info: null, // From api/user/info data
  profile: { status: 'loading' },
  colorThemeClassName: '', // '' = use default color scheme.
  notifications: {
    // From api/message data
    data: [],
    refreshing: false,
    lastRefreshedTime: null,
    refreshFailure: false
  },
  settings: {
    defaultPageSize: 50
  }
});

export const onUserUpdate = (state, { authToken, username, roles, info }) => {
  // Set color theme
  let colorThemeClassName = '';
  if (roles.length === 1) {
    if (
      roles.includes(userRoles.ROLE_ANIMAL_OWNER) ||
      roles.includes(userRoles.ROLE_AGENT)
    ) {
      colorThemeClassName = 'gvl-theme-purple';
    }
  }
  const settings = { ...state.settings, ...getUserSettings(info.appUserId) };
  let newState = state.merge({
    info,
    authToken,
    username,
    roles,
    colorThemeClassName, // TODO: Consider moving this (and other Redux items) to use Context instead of Redux,
    settings
  });
  // Tried to put this in the `initialState` immutable object, but then it was not being updated on login.
  return newState.setIn(
    ['isSmartEngineEnabled'],
    settingStorage.getValue('seCheckedV2') === undefined
      ? true
      : settingStorage.getValue('seCheckedV2')
  );
};

export const onUserToggleSmartEngineStatus = state => {
  const newSEStatus = !state.isSmartEngineEnabled;
  settingStorage.setValue('seCheckedV2', newSEStatus);
  return state.setIn(['isSmartEngineEnabled'], newSEStatus);
};

export const onUserReset = () => initialState;

export const onUserAcceptLicenseAgreementSuccess = state =>
  Immutable.setIn(state, ['info', 'missingNecessaryLicenseAgreement'], false);

export const onUserGetInfoSuccess = (state, { info }) => {
  let newState = state.asMutable({ deep: true });
  newState.info = info;
  return Immutable.from(newState);
};
//Notifications
export const onUserRefreshNotificationsStarted = state => {
  // TODO: Move this value and everewhere it's read to redux instead of local storage
  settingStorage.setValue('messagesLastPolled', moment());
  return state.merge({
    notifications: state.notifications.merge({
      data: state.notifications.data,
      refreshing: true,
      lastRefreshedTime: null,
      refreshFailure: false
    })
  });
};

export const onUserNotificationsSuccess = (state, payload) => {
  const newState = Immutable.setIn(
    state,
    ['notifications', 'refreshing'],
    false
  );
  return Immutable.setIn(
    newState,
    ['notifications', 'data'],
    payload.notifications
  );
};

export const onUserNotificationsFailure = state =>
  state.updateIn(['notifications'], notifications => ({
    ...notifications,
    data: initialState.notifications.data,
    refreshing: false,
    lastRefreshedTime: null,
    refreshFailure: true
  }));

export const onUpdateUserSettings = (state, { settings }) =>
  state.merge({ settings: state.settings.merge(settings) });

export const onUserUpdateProfileRequest = state => {
  return Immutable.setIn(state, ['profile', 'status'], 'updating');
};

export const onUserGetProfileSuccess = (state, { profile }) => {
  let newState = state.asMutable({ deep: true });
  newState.profile = profile;
  newState.profile.status = 'idle';
  return Immutable.from(newState);
};

export const onUserUpdateProfileDone = state => {
  return Immutable.setIn(state, ['profile', 'status'], 'idle');
};

const userReducer = createReducer(initialState, {
  [USER_UPDATE]: onUserUpdate,
  [USER_RESET]: onUserReset,
  [USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS]: onUserAcceptLicenseAgreementSuccess,
  [USER_GET_INFO_SUCCESS]: onUserGetInfoSuccess,
  //Notifications
  [USER_REFRESH_NOTIFICATIONS_STARTED]: onUserRefreshNotificationsStarted,
  [USER_NOTIFICATIONS_SUCCESS]: onUserNotificationsSuccess,
  [USER_NOTIFICATIONS_FAILURE]: onUserNotificationsFailure,
  // Smart Engine
  [USER_TOGGLE_SMART_ENGINE_STATUS]: onUserToggleSmartEngineStatus,
  // User settings
  [UPDATE_USER_SETTINGS]: onUpdateUserSettings,
  [USER_UPDATE_PROFILE_REQUEST]: onUserUpdateProfileRequest,
  [USER_UPDATE_PROFILE_DONE]: onUserUpdateProfileDone,
  [USER_GET_PROFILE_SUCCESS]: onUserGetProfileSuccess
});
export default userReducer;
