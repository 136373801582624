// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models

// This model is to support rabies vaccine creation for Rabies Documents
// May be useful for Equine too on Eias

import Vaccination from 'models/Vaccination';
import {
  dateIsAfter,
  dateIsBefore,
  isDate,
  maxLength,
  stringRequired
} from 'utils/validation/common';
import moment from 'moment';

export default class RabiesVac extends Vaccination {
  // Static Methods

  static get fields() {
    const superFields = super.fields;
    return {
      ...superFields,

      expirationDate: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.expirationDate.validate(
            val,
            instance
          );
          if (superValidation) return superValidation;

          if (!val) return null;

          if (!(instance && instance.vaccinationDate)) return null;

          let dateErr = dateIsAfter(
            instance.vaccinationDate,
            val,
            'vaccination.expirationDate.error.beforeVaccinationDate'
          );
          if (!dateErr && instance.licensedDuration) {
            let expirationDateMax = moment(instance.vaccinationDate)
              .clone()
              .add(instance.licensedDuration, 'years');
            dateErr = dateIsBefore(
              expirationDateMax,
              val,
              'vaccination.expirationDate.error.exceedsLicensedDuration'
            );
          }
          if (!dateErr && ['initial', 'primary'].includes(instance.exposure)) {
            // -IF the rabies vaccination is an INITIAL vaccination ONLY a 1 year vaccine is allowed and it must have an expiration date of 12 months minus 1 day from the vaccine administered date.
            dateErr = moment(val).isSameOrAfter(
              moment(instance.vaccinationDate).add(12, 'months'),
              'day'
            )
              ? {
                  messageId:
                    'validation.vaccination.initialExpirationWithinRange'
                }
              : null;
          }
          if (
            !dateErr &&
            ['booster', 'secondary', 'additional'].includes(instance.exposure)
          ) {
            // -IF the rabies vaccination is a BOOSTER then the expiration date must be within 1,094 days from vaccine administered date.
            dateErr = moment(val).isSameOrAfter(
              moment(instance.vaccinationDate).add(36, 'months'),
              'day'
            )
              ? {
                  messageId:
                    'validation.vaccination.boosterExpirationWithinRange'
                }
              : null;
          }
          return dateErr;
        }
      },
      name: {
        //This field is always rabies for rabies vacs
        initialValue: 'Rabies'
      },
      type: {
        initialValue: undefined,
        validate: (val, instance) => {
          // TODO: generic vaccine model requires this field if the name is rabies or if it's not a companion species... ?
          //let superValidation = superFields.type.validate(val, instance);
          //if (superValidation) return superValidation;
          // TODO: this is requried in the IHC workflow, but not elsewhere. Should this actually be required everywhere?
          // return stringRequired(val, 'required') || maxLength(val, 50);
          return maxLength(val, 50);
        }
      },
      exposure: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.exposure.validate(val, instance);
          if (superValidation) return superValidation;

          if (instance.species && instance.species.toLowerCase() === 'equine') {
            return !val ? null : maxLength(val, 50);
          }

          return stringRequired(val, 'required') || maxLength(val, 50);
        }
      },
      licensedDuration: {
        initialValue: undefined
        // TODO: this is requried in the IHC workflow, but not elsewhere. Should this actually be required everywhere?
        // validate: (val, instance) => isNumber(val, 'required')
      },
      remarks: {
        //RabiesVac uses this field for "Administered By"
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.remarks.validate(val, instance);
          if (superValidation) return superValidation;
          if (instance.species && instance.species.toLowerCase() === 'equine') {
            return stringRequired(val, 'required') || maxLength(val, 100);
          } else {
            return !val ? stringRequired(val, 'required') : maxLength(val, 100);
          }
        }
      },
      manufacturer: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.manufacturer.validate(
            val,
            instance
          );
          if (superValidation) return superValidation;
          if (
            instance.product &&
            instance.species &&
            instance.species.toLowerCase() === 'equine'
          ) {
            return maxLength(val, 100);
          } else {
            return stringRequired(val, 'required') || maxLength(val, 100);
          }
        }
      },
      product: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.product.validate(val, instance);
          if (superValidation) return superValidation;
          if (
            instance.manufacturer &&
            instance.species &&
            instance.species.toLowerCase() === 'equine'
          ) {
            return maxLength(val, 120);
          } else {
            return stringRequired(val, 'required') || maxLength(val, 120);
          }
        }
      },
      lotNumber: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.lotNumber.validate(val, instance);
          if (superValidation) return superValidation;
          if (instance.species && instance.species.toLowerCase() === 'equine') {
            return !val ? null : maxLength(val, 50);
          } else {
            return stringRequired(val, 'required') || maxLength(val, 50);
          }
        }
      },
      vaccinationDate: {
        initialValue: undefined,
        validate: (val, instance) => {
          let superValidation = superFields.vaccinationDate.validate(
            val,
            instance
          );
          if (superValidation) return superValidation;

          const basicDateValidationError =
            isDate(val, 'MM/DD/YYYY', 'required') ||
            dateIsAfter(val, new Date());
          if (basicDateValidationError) return basicDateValidationError;

          if (instance && instance.expirationDate) {
            //Check with expiration date
            const afterExpirationError = dateIsAfter(
              val,
              instance.expirationDate,
              'vaccination.vaccinationDate.error.afterExpirationDate'
            );
            if (afterExpirationError) return afterExpirationError;
          }
          return null;
        }
      }
    };
  }
}
