/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import { rootSaga } from 'sagas';
import { loadPersistedState, setupStoreObservers } from 'utils/reduxUtil';

const sagaMiddleware = createSagaMiddleware();

// Create the store with two middlewares
// 1. sagaMiddleware: Makes redux-sagas work

const middlewares = [sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle, indent */
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

const persistedState = loadPersistedState();

const store = createStore(
  rootReducer(),
  persistedState,
  composeEnhancers(...enhancers)
);
setupStoreObservers(store);

sagaMiddleware.run(rootSaga);

// Make reducers hot reloadable, see http://mxs.is/googmo
/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(rootReducer());
  });
}

// We need to access the store from multiple places, like index.js, validation routines, etc.
// Exporting the store directly from index.js causes problems with tests.
// Exporting the store in this separate file helps webpack keep things straight.
//
// WARNING: If we move to using server-side rendering, this will not work, as all users will get the same store
//
// See the following for more info: https://daveceddia.com/access-redux-store-outside-react/

export default store;
