// Sorts array of objects in place using key property lower-case string value
export const sortArrayOfObjectsAlphaNoCase = (arrayToSort, key) => {
  arrayToSort.sort((first, second) => {
    const firstKeyValueLowerCase = first[key].toLowerCase();
    const secondKeyValueLowerCase = second[key].toLowerCase();

    if (firstKeyValueLowerCase < secondKeyValueLowerCase) {
      return -1;
    }
    if (firstKeyValueLowerCase > secondKeyValueLowerCase) {
      return 1;
    }
    return 0;
  });
};

export const generateNumberSorter = sortProperty => {
  return (a, b) => {
    var aNumber, bNumber;
    if (typeof sortProperty === 'function') {
      try {
        aNumber = sortProperty(a);
      } catch (error) {
        aNumber = 0;
      }
      try {
        bNumber = sortProperty(b);
      } catch (error) {
        bNumber = 0;
      }
    } else {
      aNumber = a[sortProperty];
      bNumber = b[sortProperty];
    }
    // Treat null values as 0
    if (aNumber == null) {
      aNumber = 0;
    }
    if (bNumber == null) {
      bNumber = 0;
    }

    return aNumber - bNumber;
  };
};

export const generateAlphaSorter = sortProperty => {
  return (a, b) => {
    var aName, bName;
    if (typeof sortProperty === 'function') {
      try {
        aName = sortProperty(a);
      } catch (error) {
        aName = '';
      }
      try {
        bName = sortProperty(b);
      } catch (error) {
        bName = '';
      }
    } else {
      aName = a[sortProperty];
      bName = b[sortProperty];
    }
    // Treat null values as empty string so they will sort as lowest
    aName = aName ? aName.toUpperCase().trim() : '';
    bName = bName ? bName.toUpperCase().trim() : '';
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  };
};
