import * as types from './newportClinicActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });

// list
export const listRequest = () => createAction(types.LIST_REQUEST);
export const listSuccess = clinicList =>
  createAction(types.LIST_SUCCESS, { clinicList });
export const listFailure = () => createAction(types.LIST_FAILURE);
