import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { isTablet, isIOS13 } from 'react-device-detect';
import { Icon, Layout, Popover, Menu } from 'antd';
import GvlIcon from 'elements/GvlIcon';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Logo from 'elements/Logo';
import NotificationsModal from 'components/NotificationsModal';
import JustLogo from 'images/logos/Icon/GVL_logo_icon.png';
import { setViewMode } from 'containers/app/store/data/dataActions';
import { userNotificationsRequest } from 'containers/app/store/user/userActions';
import { getNotifications } from 'containers/app/store/user/userSelectors';
import { Link, withRouter } from 'react-router-dom';
import GvlAvatar from 'components/GvlBottombar/GvlAvatar';
import * as gvlAvatarUtil from 'utils/gvlAvatarUtil';
import * as userUtil from 'utils/userUtil';
import * as analytics from 'utils/analytics/analytics';
import * as userRoles from 'utils/userRoles';
import { THEME } from 'style';
import { BACKEND_BASENAME, DEPLOY_PLATFORM } from 'utils/config';
import * as settingStorage from 'utils/settingStorage';
import moment from 'moment';
import { isBillingPageEnabled } from 'containers/app/store/user/userSelectors';

const { Sider } = Layout;

/* TODO: Using @emotion styled is unnecessary here. We should be able to
 * get by with simple less styling and applying styles/classes inline on
 * the following styled components: StyledSider, OuterSider, and AvatarSider
 */
const StyledSider = styled(Sider, { shouldForwardProp: () => true })({
  // Fixed sider so it doesn't scroll with content
  overflow: 'auto',
  position: 'fixed',
  top: 70,
  bottom: 174,
  left: 0,
  zIndex: 1000,
  height: 'calc(100vh - 70px)'
});

const OuterSider = styled(Sider, { shouldForwardProp: () => true })(
  ({ viewMode, collapsed }) => ({
    zIndex: 980,
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    boxShadow:
      viewMode === 'Mobile' && !collapsed
        ? '0px 0px 0px 100vw rgba(0,0,0,.2)'
        : '4px 0px 12px rgba(0,0,0,.04)'
  })
);

const AvatarSider = styled(Sider, { shouldForwardProp: () => true })(
  ({ viewMode }) => ({
    textAlign: 'center',
    backgroundColor: THEME.mainBackgroundColor(),
    position: 'fixed',
    overflowY: 'hidden',
    bottom: viewMode === 'Mobile' ? 0 : 37,
    height: '127px',
    fontSize: '12px'
  })
);

const StyledChildDiv = styled.div(({ isCollapsed, position }) => ({
  textAlign: 'center',
  width: isCollapsed ? '100%' : '50%',
  height: isCollapsed ? '50%' : '100%',
  cursor: 'pointer',
  paddingTop: 12,
  float: position,
  display: 'inline-block'
}));

const StyledTriggerDiv = styled.div({
  borderTopColor: THEME.mainBorderColor(),
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  backgroundColor: THEME.mainBackgroundColor(),
  color: THEME.tabText()
});

const StyledIcon = styled(GvlIcon)({
  fontSize: '24px !important',
  position: 'relative',
  top: '4px'
});

/* Popover Styling */
const StyledMenuItem = styled(Menu.Item)({
  borderBottom: '1px solid #D3DBE0',
  padding: '0px !important',
  margin: '8px 12px 0 12px !important',
  lineHeight: '30px !important',
  height: '40px !important'
});

/* Legal link Styling */
const StyledLegalLink = styled('a')({
  position: 'absolute',
  bottom: '165px',
  width: '100%',
  textAlign: 'center',
  fontSize: '.75em'
});

class GvlSiderComponent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired,
    refreshing: PropTypes.bool.isRequired,
    lastRefreshedTime: PropTypes.number,
    logoutRequest: PropTypes.func.isRequired,
    collapsedChanged: PropTypes.func.isRequired,
    viewMode: PropTypes.string.isRequired,
    setViewMode: PropTypes.func.isRequired,
    userNotificationsRequest: PropTypes.func.isRequired,
    accountMenuItem: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    msgCount: PropTypes.number,
    location: PropTypes.object.isRequired,
    staticContext: PropTypes.any, // not used just want it removed from rest prop that gets passed along
    billingPageEnabled: PropTypes.bool.isRequired
  };

  state = {
    collapsed: false,
    userPopoverVisible: false,
    isNotificationModalVisible: false
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
    this.props.collapsedChanged(collapsed);
  };

  onBreakpoint = isSmallerThanBreakpoint => {
    this.setState({ collapsed: isSmallerThanBreakpoint });
    this.props.collapsedChanged(isSmallerThanBreakpoint);
    this.props.setViewMode(isSmallerThanBreakpoint ? 'Mobile' : 'Desktop');
  };

  componentDidMount() {
    this.props.userNotificationsRequest();
    if (isTablet || isIOS13) this.onCollapse(true);
  }

  // TODO: Rename this function to be less ambiguous if/when we add a badge count to the Intercom avatar
  getBadgeCount = () => {
    const { notifications } = this.props;
    const messagesLastViewed = settingStorage.getValue('messagesLastViewed');
    if (!messagesLastViewed) return notifications.length;
    return notifications.filter(notification => {
      return notification.timestamp > messagesLastViewed;
    }).length;
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (
      moment(settingStorage.getValue('messagesLastPolled')) <
      moment().subtract(2, 'minutes')
    ) {
      this.props.userNotificationsRequest();
    }
  }

  onRouteChanged() {
    if (this.props.viewMode === 'Mobile') {
      this.setState({ collapsed: true });
    }
  }

  render() {
    const {
      children,
      user,
      refreshing,
      logoutRequest,
      viewMode,
      notifications,
      accountMenuItem,
      billingPageEnabled,
      // Pull these items out of ...rest to avoid passing unnecessary props that generate console warnings
      // eslint-disable-next-line react/prop-types
      collapsedChanged,
      setViewMode,
      staticContext,
      lastRefreshedTime,
      userNotificationsRequest,
      ...rest
    } = this.props;

    const collapsedWidth = viewMode === 'Mobile' ? 0 : 80;
    const isGvlUniversity = DEPLOY_PLATFORM === 'university';
    const profileContent = (
      <Menu
        className={`userPopover ${user.colorThemeClassName}`}
        mode="vertical"
      >
        <StyledMenuItem key="1">
          {/* This is where we could put a flag to allow us to merge this to main, but flip a switch to enable this in testing */}
          <Link to="/settings/user">
            <StyledIcon type="user" />
            {intl.get('profile')}
          </Link>
        </StyledMenuItem>
        {accountMenuItem && (
          <StyledMenuItem key="2">
            <a
              href={`${BACKEND_BASENAME}${accountMenuItem}`}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="opener"
              onClick={() => this.setState({ userPopoverVisible: false })}
            >
              <StyledIcon type="gvl-organization" />
              {intl.get(userUtil.getAppAccountNameIdForUser(user))}
            </a>
          </StyledMenuItem>
        )}
        {user.roles.includes(userRoles.ROLE_ACCOUNT_MANAGER) && (
          <StyledMenuItem key="6">
            <Link to="/settings/users">
              <StyledIcon type="usergroup-add" />
              {intl.get('users')}
            </Link>
          </StyledMenuItem>
        )}
        <StyledMenuItem
          style={{ display: 'none' }} // TODO: CONTACT-5 Remove this style once notifications are ready
          key="3"
          onClick={() =>
            this.setState({
              isNotificationModalVisible: true,
              userPopoverVisible: false
            })
          }
        >
          <StyledIcon type="gvl-message" />
          {intl.get('notifications')}
        </StyledMenuItem>

        {billingPageEnabled && (
          <StyledMenuItem key="4">
            <Link to={`/account/billing`}>
              <StyledIcon type="gvl-credit-card" />
              {intl.get('payment')}
            </Link>
          </StyledMenuItem>
        )}

        <StyledMenuItem key="5">
          <Link to="/" onClick={logoutRequest}>
            <StyledIcon type="gvl-logout" />
            {intl.get('Logout')}
          </Link>
        </StyledMenuItem>
      </Menu>
    );

    // copied and simplified a few lines from antd to set custom style on the trigger
    // see: https://github.com/ant-design/ant-design/blob/master/components/layout/Sider.tsx#L212
    const customTrigger = (
      <StyledTriggerDiv>
        <Icon type={this.state.collapsed ? 'right' : 'left'} />
      </StyledTriggerDiv>
    );

    let logoType = 'color';
    if (isGvlUniversity) {
      logoType = 'gvlUniversityLong';
    } else if (
      user.roles.length === 1 &&
      (user.roles[0] === userRoles.ROLE_ANIMAL_OWNER || // Single role of animal onwer means MVL Owner
        user.roles[0] === userRoles.ROLE_AGENT) // Agents get same logo as MVL Owner
    ) {
      logoType = 'mvlPurple';
    }

    const siderLogo = (
      <Link to="/">
        {this.state.collapsed ? (
          <img
            src={JustLogo}
            height="32px"
            width="32px"
            style={{ margin: '17px 23px' }}
            alt="GVL logo"
          />
        ) : (
          <Logo
            type={logoType}
            size={viewMode === 'Mobile' ? 'small' : 'medium'}
            height="50px"
            width={isGvlUniversity ? '230px' : undefined}
            style={{ backgroundPosition: 'left center', marginLeft: '12px' }}
          />
        )}
      </Link>
    );

    //TODO: Separate this into its own component
    const siderAvatar = (
      <AvatarSider
        collapsible
        breakpoint="lg"
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        onBreakpoint={this.onBreakpoint}
        width="254"
        collapsedWidth={collapsedWidth}
        zeroWidthTriggerStyle={{ display: 'none' }}
        viewMode={viewMode}
        style={{
          paddingBottom: this.state.collapsed ? '15px' : '35px',
          paddingTop: this.state.collapsed ? '15px' : '35px'
        }}
      >
        <StyledChildDiv isCollapsed={this.state.collapsed} position="left">
          <GvlAvatar
            className="intercom_avatar"
            avatarData={gvlAvatarUtil.generateGvlAvatar(
              'support',
              'gvl-intercom-filled',
              THEME.text(),
              '',
              '',
              'default',
              'circle'
            )}
            // TODO: Add a badge for intercom notification count?
            disabled={!analytics.isTrackingEnabled()}
            tooltipText={intl.get('intercom.disabled')}
            isCollapsed={this.state.collapsed}
            useIntl={true}
          />
        </StyledChildDiv>
        <StyledChildDiv isCollapsed={this.state.collapsed} position="right">
          <Popover
            trigger="click"
            placement="rightBottom"
            style={{ padding: '0' }}
            visible={this.state.userPopoverVisible}
            content={profileContent}
            onVisibleChange={newState => {
              if (newState === false)
                this.setState({ userPopoverVisible: false });
            }}
          >
            <div>
              <GvlAvatar
                className="userAvatar"
                avatarData={gvlAvatarUtil.generateGvlAvatar(
                  user.info.name,
                  '',
                  THEME.aqua(),
                  userUtil.generateInitials(user.info.name),
                  '',
                  'default',
                  'circle'
                )}
                isCollapsed={this.state.collapsed}
                // notificationCount={this.getBadgeCount()} // TODO: CONTACT-5 add this once notifications are ready
                useIntl={false}
                onClick={() =>
                  this.setState({
                    userPopoverVisible: !this.state.userPopoverVisible
                  })
                }
              />
            </div>
            <NotificationsModal
              refreshing={refreshing}
              notifications={notifications}
              isVisible={this.state.isNotificationModalVisible}
              onCancel={() => {
                this.setState({
                  isNotificationModalVisible: false
                });
              }}
            />
          </Popover>
        </StyledChildDiv>
      </AvatarSider>
    );

    return (
      <OuterSider
        collapsible
        breakpoint="lg"
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        onBreakpoint={this.onBreakpoint}
        theme="light"
        width="254"
        collapsedWidth={collapsedWidth}
        zeroWidthTriggerStyle={{ top: '12px' }}
        viewMode={viewMode}
      >
        {siderLogo}
        <StyledSider
          id="gvlSider" // using an id so we can 'find' this Styled element in the wrapper during tests.
          theme="light"
          width="254"
          collapsible
          breakpoint="lg"
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          onBreakpoint={this.onBreakpoint}
          trigger={viewMode === 'Mobile' ? null : customTrigger}
          collapsedWidth={collapsedWidth}
          zeroWidthTriggerStyle={{ display: 'none' }}
          {...rest}
        >
          {children}
          <StyledLegalLink
            className="privacy_policy"
            href={`${BACKEND_BASENAME}/legal`}
            target="_blank"
          >
            {intl.get('settings.user.privacyPolicy')}
          </StyledLegalLink>
          {siderAvatar}
        </StyledSider>
      </OuterSider>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  user: state.app.user,
  viewMode: state.app.data.viewMode,
  refreshing: state.app.user.notifications.refreshing,
  lastRefreshedTime: state.app.user.notifications.lastRefreshedTime,
  notifications: getNotifications(state),
  billingPageEnabled: isBillingPageEnabled(state)
});

/* istanbul ignore next */
const mapDispatchToProps = {
  setViewMode,
  userNotificationsRequest
};
/* istanbul ignore next */

const GvlSider = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GvlSiderComponent)
);

export { GvlSider as default, GvlSiderComponent, OuterSider, AvatarSider };
