import { createAction } from 'utils/reduxUtil';
import * as types from 'modules/Registration/Account/store/accountRegistrationActionTypes';

export const actionInProgress = (action, inProgress) =>
  createAction(types.ACTION_IN_PROGRESS, { action, inProgress });

export const loadRegistrationData = () =>
  createAction(types.LOAD_REGISTRATION_DATA);
export const loadRegistrationDataSuccess = (data, tableData) =>
  createAction(types.LOAD_REGISTRATION_DATA_SUCCESS, { data, tableData });

export const validateClinicEmail = (params, callback) =>
  createAction(types.IS_CLINIC_EMAIL_DUPLICATE, { params, callback });

export const resumeRegistration = (params, callback) =>
  createAction(types.RESUME_REGISTRATION, { params, callback });

export const sendInternalAnalyticsEvent = (params, callback) =>
  createAction(types.SEND_INTERNAL_ANALYTICS_EVENT, { params, callback });

export const submitRegistration = (
  accountRegistration,
  userRegistration,
  callback
) =>
  createAction(types.SUBMIT_REGISTRATION, {
    accountRegistration,
    userRegistration,
    callback
  });
export const completeRegistration = (params, history) =>
  createAction(types.COMPLETE_REGISTRATION, {
    params,
    history
  });
