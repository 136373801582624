import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Icon, Spin, Row, Col, Divider } from 'antd';
import { ContactLookup, ContactEditModal } from 'modules/Contact/contactIndex';
import { refreshRequest as contactRefreshRequest } from 'modules/Contact/store/contactActions';
import { BorderedDiv } from 'style/commonEmotions';
import { THEME } from 'style';

class ContactSearchFormItemComponent extends Component {
  static propTypes = {
    contactRefreshRequest: PropTypes.func.isRequired,
    refreshingContact: PropTypes.bool.isRequired,
    contacts: PropTypes.object,
    value: PropTypes.object,
    form: PropTypes.object,
    onChange: PropTypes.func,
    removable: PropTypes.bool,
    onCreateNew: PropTypes.func
  };

  static defaultProps = {
    removable: true
  };

  state = {
    isEditContactModalVisible: false,
    isRemoveClicked: false
  };

  onSelectProducer = contactId => {
    this.setState({ isRemoveClicked: false });
    this.props.contactRefreshRequest(contactId, this.props.onChange);
  };

  onProducerChange = () => {
    this.props.form.resetFields(['herdLocation']);
  };

  render() {
    const {
      refreshingContact,
      value,
      onChange,
      removable,
      onCreateNew
    } = this.props;
    return value && !this.state.isRemoveClicked ? (
      <React.Fragment>
        <ContactEditModal
          visible={this.state.isEditContactModalVisible}
          contact={value}
          forceRender={true}
          closeModal={() => this.setState({ isEditContactModalVisible: false })}
          onChange={onChange}
        />
        <BorderedDiv>
          <h4 style={{ margin: '12px' }}>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {value.displayName}
                <span style={{ float: 'right' }}>
                  <Button
                    type="link"
                    style={{
                      margin: 'auto',
                      padding: 0
                    }}
                    onClick={() =>
                      this.setState({ isEditContactModalVisible: true })
                    }
                  >
                    {intl.get('edit')}
                  </Button>
                  <Divider type="vertical" />
                  {removable ? (
                    <Button
                      style={{
                        border: 'none',
                        margin: 'auto',
                        paddingLeft: 0
                      }}
                      onClick={() => {
                        this.setState({ isRemoveClicked: true });
                        this.onProducerChange();
                        onChange();
                      }}
                    >
                      <Icon type="close" style={{ color: THEME.red() }} />
                    </Button>
                  ) : null}
                </span>
              </Col>
            </Row>
          </h4>
        </BorderedDiv>
      </React.Fragment>
    ) : (
      <Spin spinning={refreshingContact}>
        <ContactLookup
          onSelect={this.onSelectProducer}
          onCreateNew={onCreateNew}
        />
      </Spin>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  refreshingContact: accounts.contacts.isRefreshing,
  contacts: accounts.contacts.data
});

const mapDispatchToProps = {
  contactRefreshRequest
};

const ContactSearchFormItem = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ContactSearchFormItemComponent);

export { ContactSearchFormItem as default, ContactSearchFormItemComponent };
