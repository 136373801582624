import Model from 'models/Model';
import { objectRequired, stringRequired } from 'utils/validation/common';
import * as api from 'utils/api';

export default class License extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      licenseId: {
        initialValue: undefined
      },
      licenseNumber: {
        initialValue: undefined
      },
      issuingAuthority: {
        initialValue: undefined
      },
      dateIssued: {
        initialValue: undefined,
        validate: dateIssued => stringRequired(dateIssued, 'required')
      },
      dateExpires: {
        initialValue: undefined,
        validate: dateExpires => stringRequired(dateExpires, 'required')
      },
      validationStatus: {
        initialValue: undefined
      },
      vet: {
        initialValue: undefined,
        validate: val => objectRequired('vet', val)
      }
    };
  }

  static read = async licenseId => {
    return await api.get(`/license/${licenseId}`);
  };

  static validateFederalLicense = async params =>
    await api.post(`${License.apiPath}/validateFederalLicense`, params);

  static async updateLicense(license, vetId) {
    let apiParams = { ...license };
    apiParams.vetId = vetId;
    if (!license.id) {
      apiParams.issuingAuthority = 'FEDERAL';
      return await api.put(`/license/update`, apiParams);
    } else {
      apiParams.licenseId = license.id;
      return await api.put(`/license/update/${license.id}`, apiParams);
    }
  }

  static async checkAccreditationStatus(licenseId) {
    let result;
    try {
      result = await api.get(`/license/validate?id=${licenseId}`);
    } catch (e) {
      console.info('license did not pass accreditation check');
    }
    return result;
  }

  // Some endpoints use "license" and others use "licenses"... SMH
  static get apiPath() {
    return 'license';
  }
}
