import React from 'react';
import PropTypes from 'prop-types';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';

export default function NewportClinicShippingAddress({
  clinic,
  showName = false
}) {
  return (
    <IsolateVal
      name="shipping.address"
      val={
        <IsolateValWithIcon
          icon="environment"
          val={
            <React.Fragment>
              {showName ? (
                <div>
                  {clinic?.name}
                  <br />
                  {clinic?.newport_id}
                </div>
              ) : null}
              <div>{clinic?.shippingAddress?.address_line_1}</div>
              <div>
                {`${clinic?.shippingAddress?.city}, ${clinic?.shippingAddress?.state} ${clinic?.shippingAddress?.postal_code}`}
              </div>
            </React.Fragment>
          }
        />
      }
    />
  );
}

NewportClinicShippingAddress.propTypes = {
  clinic: PropTypes.object.isRequired,
  showName: PropTypes.bool
};
