import { createAction } from 'utils/reduxUtil';
import {
  DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST,
  DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED,
  DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS,
  DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED,
  // Distributor
  DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_VFD_LIST_STARTED,
  DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE,
  DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE,
  //Claim certificate
  DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST,
  DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED,
  DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE,
  DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR,
  DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST,
  DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED,
  DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE,
  DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED,
  //Uploaded Document
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE,
  DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED,
  //DISTRIBUTOR SEARCH
  DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST,
  DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED,
  DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS,
  DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE,
  //UPLOADEDDOCUMENT SEARCH
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE,
  //UPLOADEDDOCUMENT DEACTIVATE
  DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST,
  //UPLOAD REQUEST
  DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST,
  DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED,
  DISTRIBUTOR_UPLOAD_REQUEST_UPDATED,
  DISTRIBUTOR_UPLOADS_LIST_READY,
  DISTRIBUTOR_DELETE_UPLOAD_REQUEST,
  DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST,
  //UPLOADEDDOCUMENT DELETE
  DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED,
  DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY,
  DISTRIBUTOR_UPLOADED_DOCUMENT_DELETE_REQUEST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED,
  DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY,
  //requests
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE,
  DISTRIBUTOR_REFRESH_REQUEST_REQUEST,
  DISTRIBUTOR_REFRESH_REQUEST_SUCCESS,
  DISTRIBUTOR_REFRESH_REQUEST_FAILURE,
  //Vfd Request
  DISTRIBUTOR_REQUEST_VFD_REQUEST,
  DISTRIBUTOR_REQUEST_VFD_SUCCESS,
  DISTRIBUTOR_REQUEST_VFD_FAILURE,
  DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST,
  DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_REFRESH_CERTIFICATE,
  DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST,
  DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST
} from 'containers/accounts/distributor/store/accountsDistributorConstants';

export const distributorLoadStartupDataRequest = () =>
  createAction(DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST);
export const distributorLoadStartupDataStarted = () =>
  createAction(DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED);
export const distributorLoadStartupDataSuccess = startupData =>
  createAction(DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const distributorLoadStartupDataFinished = () =>
  createAction(DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED);

// Distributor Constant
export const distributorRefreshVfdListRequest = () =>
  createAction(DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST);
export const distributorRefreshVfdListStarted = () =>
  createAction(DISTRIBUTOR_REFRESH_VFD_LIST_STARTED);
export const distributorRefreshVfdListSuccess = lists =>
  createAction(DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS, lists);
export const distributorRefreshVfdListFailure = () =>
  createAction(DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE);
export const distributorVfdSaveLogShipmentRequest = (
  shipmentData,
  id,
  actionType
) =>
  createAction(DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST, {
    shipmentData,
    id,
    actionType
  });
export const distributorVfdSaveOverageReasonRequest = (
  setShipmentData,
  addShipmentData
) =>
  createAction(DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST, {
    setShipmentData,
    addShipmentData
  });
export const distributorVfdTransferLocationRequest = (feedMillData, docId) =>
  createAction(DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST, {
    feedMillData,
    docId
  });
export const distributorVfdTransferLocationSuccess = transferredMill =>
  createAction(DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS, { transferredMill });
export const distributorVfdTransferLocationFailure = () =>
  createAction(DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE);
export const distributorSearchCertificateRequest = params =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST, { params });
export const distributorSearchCertificateStarted = () =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED);
export const distributorSearchCertificateSuccess = searchedCertificate =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS, { searchedCertificate });
export const distributorSearchCertificateFailure = () =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE);
export const distributorClearSearchedCertificate = () =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR);

export const distributorClaimCertificateRequest = (id, feedMillReferenceId) =>
  createAction(DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST, {
    id,
    feedMillReferenceId
  });
export const distributorClaimCertificateStarted = () =>
  createAction(DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED);
export const distributorClaimCertificateSuccess = () =>
  createAction(DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS);
export const distributorClaimCertificateFailure = () =>
  createAction(DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE);
export const distributorClaimCertificateEnded = () =>
  createAction(DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED);

//Upload documents
export const distributorRefreshUploadedDocumentListRequest = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST);
export const distributorRefreshUploadedDocumentListStarted = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED);
export const distributorRefreshUploadedDocumentListSuccess = lists =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS, lists);
export const distributorRefreshUploadedDocumentListFailure = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE);
export const distributorUploadedDocumentSaveLogShipmentRequest = (
  shipmentData,
  id,
  actionType
) =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST, {
    shipmentData,
    id,
    actionType
  });
export const distributorUploadedDocumentSaveOverageReasonRequest = (
  setShipmentData,
  addShipmentData
) =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST, {
    setShipmentData,
    addShipmentData
  });
export const distributorSaveLogShipmentRequest = (
  shipmentData,
  id,
  actionType,
  params,
  shouldShowMessage
) =>
  createAction(DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST, {
    shipmentData,
    id,
    actionType,
    params,
    shouldShowMessage
  });
export const distributorEditOverageReasonRequest = (
  setShipmentData,
  addShipmentData
) =>
  createAction(DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST, {
    setShipmentData,
    addShipmentData
  });
export const distributorEditLogShipmentRequest = (
  shipmentData,
  id,
  actionType,
  shouldShowMessage,
  isEditEstimatedQuantity
) =>
  createAction(DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST, {
    shipmentData,
    id,
    actionType,
    shouldShowMessage,
    isEditEstimatedQuantity
  });
export const distributorSaveOverageReasonRequest = (
  setShipmentData,
  addShipmentData
) =>
  createAction(DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST, {
    setShipmentData,
    addShipmentData
  });
export const distributorUploadCertificateRequest = (docUpload, isCreate) =>
  createAction(DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST, {
    docUpload,
    isCreate
  });
export const distributorUploadCertificateStarted = () =>
  createAction(DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED);
export const distributorUploadCertificateSuccess = isCreate =>
  createAction(DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS, { isCreate });
export const distributorUploadCertificateFailure = isCreate =>
  createAction(DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE, { isCreate });
export const distributorUploadCertificateEnded = () =>
  createAction(DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED);
export const distributorUploadedDocumentDeleteRequest = id =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_DELETE_REQUEST, { id });
export const distributorUploadedDocumentDeleted = id =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED, { id });
export const distributorUploadedDocumentsListReady = () =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY);

// Distributor Feedmill  search
export const distributorSearchCertificatesRequest = params =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST, {
    params
  });
export const distributorSearchCertificatesStarted = () =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED);
export const distributorSearchCertificatesSuccess = list =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS, { list });
export const distributorSearchCertificatesFailure = () =>
  createAction(DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE);

// Distributor UPLOADOC  search
export const distributorRefreshUploadedDocumentSearchListRequest = params =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST, {
    params
  });
export const distributorRefreshUploadedDocumentSearchListStarted = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED);
export const distributorRefreshUploadedDocumentSearchListSuccess = list =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS, {
    list
  });
export const distributorRefreshUploadedDocumentSearchListFailure = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE);

//Deactivated certificate
export const distributorDeactivateUploadedDocumentRequest = (
  ids,
  deactivationReason
) =>
  createAction(DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST, {
    ids,
    deactivationReason
  });
//Deactivate Upload
export const distributorDeactivateUploadRequest = (id, deactivationReason) =>
  createAction(DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST, {
    id,
    deactivationReason
  });

export const distributorRefreshUploadsRequestStarted = () =>
  createAction(DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED);

export const distributorUploadRequestUpdated = uploadedDocument =>
  createAction(DISTRIBUTOR_UPLOAD_REQUEST_UPDATED, {
    uploadedDocument
  });
export const distributorUploadsListReady = () =>
  createAction(DISTRIBUTOR_UPLOADS_LIST_READY);
export const distributorDeleteUploadRequest = id =>
  createAction(DISTRIBUTOR_DELETE_UPLOAD_REQUEST, { id });
export const distributorUploadRemovedFromList = id =>
  createAction(DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST, { id });
// Distributor DELETE UPLOADOC
export const distributorDeleteUploadedDocumentRequest = ids =>
  createAction(DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST, { ids });
export const distributorUploadedDocumentRemovedFromList = id =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST, { id });
export const distributorUploadedDocumentListUpdated = uploadedDocument =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED, {
    uploadedDocument
  });
export const distributorUploadedDocumentListReady = () =>
  createAction(DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY);

//requests
export const distributorRefreshRequestsListRequest = () =>
  createAction(DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST);
export const distributorRefreshRequestsListStarted = () =>
  createAction(DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED);
export const distributorRefreshRequestsListSuccess = lists =>
  createAction(DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS, lists);
export const distributorRefreshRequestsListFailure = () =>
  createAction(DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE);

//feedmill show

export const distributorRefreshRequestRequest = id =>
  createAction(DISTRIBUTOR_REFRESH_REQUEST_REQUEST, { id });
export const distributorRefreshRequestSuccess = request =>
  createAction(DISTRIBUTOR_REFRESH_REQUEST_SUCCESS, { request });
export const distributorRefreshRequestFailure = () =>
  createAction(DISTRIBUTOR_REFRESH_REQUEST_FAILURE);
// Vfd request
export const distributorRequestVfdRequest = (requestedDoc, history) =>
  createAction(DISTRIBUTOR_REQUEST_VFD_REQUEST, { requestedDoc, history });
export const distributorRequestVfdSuccess = history =>
  createAction(DISTRIBUTOR_REQUEST_VFD_SUCCESS, { history });
export const distributorRequestVfdFailure = () =>
  createAction(DISTRIBUTOR_REQUEST_VFD_FAILURE);
//certificate show
export const distributorRefreshCertificate = id =>
  createAction(DISTRIBUTOR_REFRESH_CERTIFICATE, { id });

export const distributorRefreshCertificateSuccess = vfd =>
  createAction(DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS, vfd);
