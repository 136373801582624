import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Form, Modal, Button, notification } from 'antd';
import PropTypes from 'prop-types';
import { saveRequest as contactSaveRequest } from 'modules/Contact/store/contactActions';
import { Contact, ContactFormFields } from 'modules/Contact/contactIndex';

class ContactEditModalComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    contactSaveRequest: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    forceRender: PropTypes.bool,
    contact: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    contacts: PropTypes.object,
    onChange: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.props.form.setFieldsValue({
        producer: this.props.contact
      });
    }
  }

  cancelEdit = () => {
    const { form, closeModal } = this.props;
    form.isFieldsTouched()
      ? Modal.confirm({
          title: intl.get('cancel.edit'),
          content: intl.get('cancel.edit.body'),
          okText: intl.get('discard'),
          onOk: closeModal
        })
      : closeModal();
  };

  saveContact = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('producer.name')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }
      //This check makes sure that the producer display name is not overwritten by orgName when it is updated to a person name.
      if (formValues.producer.firstName) {
        formValues.producer.orgName = null;
      }
      const contact = new Contact({
        ...this.props.contact,
        ...formValues.producer
      });

      const modelValidationErrors = contact.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('producer.name')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }
      this.props.contactSaveRequest(contact, this.handleSavedContact);
    });
  };

  handleSavedContact = contact => {
    const { closeModal, onChange } = this.props;
    closeModal();
    onChange(contact);
  };

  render() {
    const { form, visible, contact, forceRender } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={this.cancelEdit}
        //Need to force render so fields can be set in component did update initally
        forceRender={forceRender ? true : false}
        footer={[
          <Button key="cancel" onClick={this.cancelEdit}>
            {intl.get('cancel')}
          </Button>,
          <Button key="save" type="primary" onClick={this.saveContact}>
            {intl.get('save')}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <ContactFormFields form={form} contact={contact} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  contacts: accounts.contacts.data
});

const mapDispatchToProps = {
  contactSaveRequest
};

const ContactEditModalForm = Form.create()(ContactEditModalComponent);

const ContactEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactEditModalForm);

export { ContactEditModal as default, ContactEditModalComponent };
