import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import moment from 'moment';
import { Row } from 'antd';
import { THEME } from 'style';
import { FadedText, BoldSpan } from 'style/commonEmotions';

export default function IsolateExpirationVal({ expirationDate }) {
  const daysFromNow = moment(expirationDate).diff(moment(), 'days');
  // To make the table easier to understand at a glance, this rounds up to the nearest month
  const monthsFromNow = moment(expirationDate)
    .add(1, 'M') // by default, .diff() rounds down.
    .diff(moment(), 'months');

  return daysFromNow > 0 ? (
    <React.Fragment>
      <Row>
        <BoldSpan>{intl.get('isolate.expires.on')}</BoldSpan>
      </Row>
      <Row>
        <h2
          style={
            daysFromNow <= 30
              ? { color: THEME.statusRed(), margin: 0 }
              : { margin: 0 }
          }
        >
          {moment(expirationDate).format('M/D/Y')}
        </h2>
      </Row>
      <Row>
        <FadedText>
          {intl.get('in')}{' '}
          {monthsFromNow > 6
            ? `${monthsFromNow} ${intl.get('months')}`
            : `${daysFromNow} ${intl.get('days')}`}
        </FadedText>
      </Row>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Row>
        <BoldSpan>{intl.get('isolate.expired.on')}</BoldSpan>
      </Row>
      <Row>
        <h2 style={{ margin: 0 }}>
          {`${moment(expirationDate).format('M/D/Y')}`}
        </h2>
      </Row>
    </React.Fragment>
  );
}

IsolateExpirationVal.propTypes = {
  expirationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
