import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';

import {
  MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS,
  MVL_OWNER_LOAD_STARTUP_DATA_STARTED,
  MVL_OWNER_LOAD_STARTUP_DATA_FINISHED,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE,
  MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS,
  MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED,
  MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE,
  MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED,
  MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED,
  MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST,
  MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED,
  MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING
} from 'containers/accounts/mvlOwner/store/accountsMvlOwnerConstants';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: Immutable.from({
    vfdDrugSpecies: null,
    scriptDrugSpecies: null
  }),
  dataLists: Immutable.from({
    // Owners get all their documents in a single list
    document: {
      data: {
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      creatingPermitsForEecvis: [],
      creatingPermitsWaiting: false
    },
    animal: {
      data: {
        animals: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    }
  })
});

export const onMvlOwnerLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      vfdDrugSpecies: startupData.vfdDrugSpecies,
      scriptDrugSpecies: startupData.scriptDrugSpecies
    })
  });

export const onMvlOwnerLoadStartupDataStarted = state =>
  state.merge({
    retrievingStartupData: true
  });

export const onMvlOwnerLoadStartupDataFinished = state =>
  state.merge({
    retrievingStartupData: false
  });

export const onMvlOwnerRefreshDocumentListSuccess = (state, payload) => {
  const { list } = payload;
  let documents = {};

  list.forEach(doc => (documents[doc.id] = doc));

  return state.updateIn(['dataLists', 'document'], document => ({
    ...document,
    data: { documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  }));
};

export const onMvlOwnerRefreshDocumentListStarted = state =>
  state.updateIn(['dataLists', 'document'], document => ({
    ...document,
    refreshing: true,
    lastRefreshedTime: null,
    refreshFailure: false
  }));

export const onMvlOwnerRefreshDocumentListFailure = state =>
  state.updateIn(['dataLists', 'document'], document => ({
    ...document,
    data: initialState.dataLists.document.data,
    refreshing: false,
    lastRefreshedTime: null,
    refreshFailure: true
  }));

export const onMvlOwnerRefreshAnimalListSuccess = (state, payload) => {
  const { list } = payload;
  let animals = {};
  list.forEach(animal => {
    animals[animal.id] = animal;
  });

  return state.setIn(['dataLists', 'animal'], {
    data: { animals },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onMvlOwnerRefreshAnimalListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      animal: {
        data: state.dataLists.animal.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onMvlOwnerRefreshAnimalListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      animal: {
        data: initialState.dataLists.animal.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onMvlOwnerCreateEecviPermitsDrawerOpened = (state, payload) =>
  state.setIn(
    ['dataLists', 'document', 'creatingPermitsForEecvis'],
    payload.eecviIds
  );

export const onMvlOwnerCreateEecviPermitsCancelled = state =>
  state.updateIn(['dataLists', 'document'], document => ({
    ...document,
    creatingPermitsForEecvis: [],
    creatingPermitsWaiting: false
  }));

export const onMvlOwnerCreateEecviPermitsRequest = state =>
  state.setIn(['dataLists', 'document', 'creatingPermitsWaiting'], true);

export const onMvlOwnerCreateEecviPermitsFinished = (state, payload) => {
  const { updatedDocuments } = payload;
  const updatedDocumentsObject = updatedDocuments.reduce(
    (updatedDocs, document) => ({ ...updatedDocs, [document.id]: document }),
    {}
  );
  return state.updateIn(['dataLists', 'document'], document => ({
    ...document,
    data: document.data.update('documents', documents =>
      documents.merge(updatedDocumentsObject)
    ),
    creatingPermitsForEecvis: [],
    creatingPermitsWaiting: false
  }));
};

export const onMvlOwnerCreateEecviPermitsNotWaiting = state =>
  state.setIn(['dataLists', 'document', 'creatingPermitsWaiting'], false);

const accountsMvlOwnerReducer = createReducer(initialState, {
  [MVL_OWNER_LOAD_STARTUP_DATA_SUCCESS]: onMvlOwnerLoadStartupDataSuccess,
  [MVL_OWNER_LOAD_STARTUP_DATA_STARTED]: onMvlOwnerLoadStartupDataStarted,
  [MVL_OWNER_LOAD_STARTUP_DATA_FINISHED]: onMvlOwnerLoadStartupDataFinished,

  [MVL_OWNER_REFRESH_DOCUMENT_LIST_SUCCESS]: onMvlOwnerRefreshDocumentListSuccess,
  [MVL_OWNER_REFRESH_DOCUMENT_LIST_STARTED]: onMvlOwnerRefreshDocumentListStarted,
  [MVL_OWNER_REFRESH_DOCUMENT_LIST_FAILURE]: onMvlOwnerRefreshDocumentListFailure,

  [MVL_OWNER_REFRESH_ANIMAL_LIST_SUCCESS]: onMvlOwnerRefreshAnimalListSuccess,
  [MVL_OWNER_REFRESH_ANIMAL_LIST_STARTED]: onMvlOwnerRefreshAnimalListStarted,
  [MVL_OWNER_REFRESH_ANIMAL_LIST_FAILURE]: onMvlOwnerRefreshAnimalListFailure,

  [MVL_OWNER_CREATE_EECVI_PERMITS_DRAWER_OPENED]: onMvlOwnerCreateEecviPermitsDrawerOpened,
  [MVL_OWNER_CREATE_EECVI_PERMITS_CANCELLED]: onMvlOwnerCreateEecviPermitsCancelled,
  [MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST]: onMvlOwnerCreateEecviPermitsRequest,
  [MVL_OWNER_CREATE_EECVI_PERMITS_FINISHED]: onMvlOwnerCreateEecviPermitsFinished,
  [MVL_OWNER_CREATE_EECVI_PERMITS_NOT_WAITING]: onMvlOwnerCreateEecviPermitsNotWaiting
});

export default accountsMvlOwnerReducer;
