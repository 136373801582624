import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import GvlCard from 'elements/GvlCard';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';

export default function NonAdjacentProductShowCard({ naRequest, currentUser }) {
  return (
    <GvlCard title={intl.get('naRequest.product.info')}>
      <div>
        <h4 style={{ marginTop: '0px' }}>{naRequest.product?.name}</h4>
        <Row gutter={8}>
          <Col span={12}>
            <IsolateVal
              name="naRequest.doses"
              val={naRequest.requestedDosage}
            />
          </Col>
          <Col span={12}>
            <IsolateVal
              name="naRequest.product.desc"
              val={naRequest.productDescription}
            />
          </Col>
        </Row>
        <IsolateVal
          name="naRequest.vaccination.schedule"
          val={
            <div>
              {naRequest.vaccinationSchedule?.map((entry, index) => (
                <span key={index}>
                  {entry}
                  <br />
                </span>
              ))}
            </div>
          }
        />
      </div>
    </GvlCard>
  );
}

NonAdjacentProductShowCard.propTypes = {
  naRequest: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};
