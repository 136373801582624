import { put, call, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { message } from 'antd';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { UserRegistration } from 'modules/Registration/User/userRegistrationIndex';
import * as actions from 'modules/Registration/User/store/userRegistrationActions';
import * as types from 'modules/Registration/User/store/userRegistrationActionTypes';

export function* onLoadRegistration({
  registrationId,
  verificationCode,
  callback
}) {
  try {
    yield put(actions.actionInProgress('isRefreshing', true));
    const userRegistration = yield call(
      UserRegistration.read,
      registrationId,
      verificationCode
    );
    callback(userRegistration);
  } catch (err) {
    yield put(actions.handleVerificationStatus(err));
  } finally {
    yield put(actions.actionInProgress('isRefreshing', false));
  }
}

export function* onSubmitRegistration({ registration, callback }) {
  try {
    yield put(actions.actionInProgress('isCreatingUser', true));
    const response = yield call([registration, registration.submit]);
    if (callback) {
      callback(response);
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.creating.noun', {
          noun: intl.get('user').toLowerCase()
        })
      )
    );
  } finally {
    yield put(actions.actionInProgress('isCreatingUser', false));
  }
}

export function* onBatchSubmitRegistrations({
  registrations,
  successCallback,
  failureCallback
}) {
  try {
    let failedRegistrations = [];
    yield put(actions.actionInProgress('isCreatingUser', true));

    for (let index = 0; index < registrations.length; index++) {
      try {
        const registration = registrations[index];
        yield call([registration, registration.submit]);
      } catch (_err) {
        failedRegistrations.push(registrations[index]);
      }
    }

    if (!failedRegistrations.length) {
      message.success(intl.get('register.invites.sent'));
      successCallback();
    } else {
      failureCallback(failedRegistrations);
    }
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress('isCreatingUser', false));
  }
}

export function* onVerifyRegistration({ registrationId, params, history }) {
  try {
    yield call(UserRegistration.verify, registrationId, params);
    yield put(actions.setVerificationStatus('COMPLETED'));
    history.push('/login');
  } catch (err) {
    yield put(actions.handleVerificationStatus(err));
  }
}

export function* onResendVerification({ registrationId }) {
  try {
    yield call(UserRegistration.resendVerification, registrationId);
    message.success(intl.get('register.confirmation.sent'));
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.sending.confirmation')));
  }
}

export function* onRenewVerification({ registrationId }) {
  try {
    yield call(UserRegistration.renewVerification, registrationId);
    message.success(intl.get('register.confirmation.sent'));
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.sending.confirmation')));
  }
}

export function* onValidateUsername({ params, callback }) {
  try {
    yield call(UserRegistration.validateUsername, params);
    callback({
      fieldName: 'username',
      isDuplicate: false,
      hasExistingRegistration: false
    });
  } catch (err) {
    if (err.isAxiosError && err.response.status) {
      callback({
        fieldName: 'username',
        isDuplicate: true,
        hasExistingRegistration: false
      });
    } else {
      console.error(err);
    }
  }
}

export function* onHandleVerificationStatus({ error }) {
  if (!error.isAxiosError) {
    yield put(actions.setVerificationStatus('FAILURE'));
    return;
  }

  const status = error.response.status;
  const errorMessage = error.response.data?.errors?.[0].message;
  const field = error.response.data?.errors?.[0].field;

  if (status === 400) {
    if (field === 'licenseNumber') {
      yield put(apiFailure(error));
      return;
    }
    switch (errorMessage) {
      case 'Registration has already been completed':
        yield put(actions.setVerificationStatus('LINK_USED'));
        break;
      case 'Registration has expired':
        yield put(actions.setVerificationStatus('EXPIRED'));
        break;
      case 'Verification code is not valid':
        yield put(actions.setVerificationStatus('CODE_MISMATCH'));
        break;
      default:
        yield put(actions.setVerificationStatus('FAILURE'));
        break;
    }
  } else {
    yield put(actions.setVerificationStatus('FAILURE'));
  }
}

export default function* userRegistrationSagas() {
  yield takeLatest(types.LOAD_REGISTRATION_REQUEST, onLoadRegistration);
  yield takeLatest(types.SUBMIT_REGISTRATION, onSubmitRegistration);
  yield takeLatest(
    types.BATCH_SUBMIT_REGISTRATIONS,
    onBatchSubmitRegistrations
  );
  yield takeLatest(types.VERIFY_REGISTRATION, onVerifyRegistration);
  yield takeLatest(types.RESEND_CONFIRMATION, onResendVerification);
  yield takeLatest(types.RENEW_VERIFICATION, onRenewVerification);
  yield takeLatest(types.IS_EMAIL_DUPLICATE, onValidateUsername);
  yield takeLatest(
    types.HANDLE_VERIFICATION_STATUS,
    onHandleVerificationStatus
  );
}
