// This store holds data common to all account types
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import {
  COMMON_LOAD_STARTUP_DATA_FINISHED,
  COMMON_LOAD_STARTUP_DATA_STARTED,
  COMMON_LOAD_STARTUP_DATA_SUCCESS,
  SET_GVLHEADER_ITEMS,
  EMAIL_CERTIFICATE_REQUEST,
  EMAIL_CERTIFICATE_SUCCESS,
  EMAIL_CERTIFICATE_FAILURE
} from 'containers/accounts/common/store/accountsCommonConstants';
import { generateHeaderItems } from 'utils/gvlHeaderUtil';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: Immutable.from({
    lookupLists: {}
  }),
  gvlHeaderItems: Immutable.from(generateHeaderItems()),
  emailing: false
});

export const onCommonLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      lookupLists: startupData.lookupLists,
      environment: startupData.environment
    })
  });

export const onCommonLoadStartupDataStarted = state =>
  state.merge({
    retrievingStartupData: true
  });

export const onCommonLoadStartupDataFinished = state =>
  state.merge({
    retrievingStartupData: false
  });

export const onSetGvlHeaderItems = (state, { headerItems }) => {
  return state.merge({
    gvlHeaderItems: state.gvlHeaderItems.merge(headerItems)
  });
};

export const onEmailCertificateRequest = state =>
  Immutable.set(state, 'emailing', true);

export const onEmailCertificateFinished = state =>
  Immutable.set(state, 'emailing', false);

const accountsCommonReducer = createReducer(initialState, {
  [COMMON_LOAD_STARTUP_DATA_SUCCESS]: onCommonLoadStartupDataSuccess,
  [COMMON_LOAD_STARTUP_DATA_STARTED]: onCommonLoadStartupDataStarted,
  [COMMON_LOAD_STARTUP_DATA_FINISHED]: onCommonLoadStartupDataFinished,
  [SET_GVLHEADER_ITEMS]: onSetGvlHeaderItems,
  [EMAIL_CERTIFICATE_REQUEST]: onEmailCertificateRequest,
  [EMAIL_CERTIFICATE_SUCCESS]: onEmailCertificateFinished,
  [EMAIL_CERTIFICATE_FAILURE]: onEmailCertificateFinished
});
export default accountsCommonReducer;
