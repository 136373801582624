import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { THEME } from 'style';

export default function RegistrationFooterVal({ lineOne, lineTwo, lineThree }) {
  return (
    <Col style={{ color: THEME.white(), margin: '0px 4px' }}>
      <p>
        {lineOne}
        <br />
        {lineTwo}
        <br />
        {lineThree}
      </p>
    </Col>
  );
}

RegistrationFooterVal.propTypes = {
  lineOne: PropTypes.string.isRequired,
  lineTwo: PropTypes.string.isRequired,
  lineThree: PropTypes.string.isRequired
};
