import * as actions from './isolateActions';
import * as types from './isolateActionTypes';
import {
  Isolate,
  IsolateExtensionModel as IsolateExtension,
  IsolateReleaseModel as IsolateRelease
} from 'modules/Isolate/isolateIndex';
import * as isolateReleaseActions from 'modules/IsolateRelease/store/isolateReleaseActions';
import { Contact } from 'modules/Contact/contactIndex';
import { Location } from 'modules/Location/locationIndex';
import { Product } from 'modules/Product/productIndex';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { notification, message } from 'antd';
import intl from 'react-intl-universal';
import * as api from 'utils/api';
import { exportCsv, formatDate } from 'utils/csvUtil';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';
import { dateRangeToParamsForApiService } from 'utils/appUtil';
import moment from 'moment';
// Would be nice to use minified version of objectExporter, but has some errors. Probably since it wasn't built for use inside a React app.
import objectExporter from 'utils/objectExporter/objectexporter';
import { cloneDeep } from 'lodash';

const getStatusForExport = isolate => {
  let displayStatus;
  if (
    // Special case for readability
    isolate.status === 'NOT_ELIGIBLE_FOR_EXTENSION' &&
    isolate.isExtended
  ) {
    displayStatus = 'EXTENDED';
  } else {
    // Let spaces break so the columns can shrink enough to fit the whole table on the PDF page
    displayStatus = isolate.status.replace(/_/g, ' ') || '';
  }
  return displayStatus;
};

export function* onIsolateSearchRequest({ searchType, searchParams, cb }) {
  try {
    yield put(actions.isRefreshing(true));
    const list = yield call(Isolate.list, searchParams);
    const newportClinics = yield select(
      state => state.accounts.newportClinics.data
    );

    const mappedList = cloneDeep(list.results).map(isolate => {
      isolate.clinicOwner = newportClinics[isolate.clinicOwner?.id];
      isolate.status = getStatusForExport(isolate);
      return isolate;
    });

    if (searchType === 'export') {
      if (list.results?.length) {
        const csvLayout = [
          {
            heading: 'isolate number',
            property: 'isolateNbr'
          },
          {
            heading: 'genus species',
            property: 'genusSpecies'
          },
          {
            heading: 'subtype',
            property: 'subtype',
            dataFormatFunction: subtype => {
              if (!subtype) return '';
              subtype = subtype?.replaceAll(', ', '*');
              subtype = subtype?.split(',').join(' ');
              subtype = subtype?.replaceAll('*', ', ');
              return subtype;
            }
          },
          {
            heading: 'isolation date',
            property: 'isolationDate',
            dataFormatFunction: date => date?.substring(0, 10) || ''
          },
          {
            heading: 'harvest date',
            property: 'harvestDate',
            dataFormatFunction: date => date?.substring(0, 10) || ''
          },
          {
            heading: 'expiration date',
            property: 'expirationDate',
            dataFormatFunction: date => date?.substring(0, 10) || ''
          },
          {
            heading: 'initial ship date',
            property: 'initialShipDate',
            dataFormatFunction: date => date?.substring(0, 10) || ''
          },
          {
            heading: 'clinic name',
            property: 'clinicOwner',
            dataFormatFunction: clinic => clinic?.name
          },
          {
            heading: 'producer',
            property: 'producer',
            dataFormatFunction: producer => producer?.displayName
          },
          {
            heading: 'products',
            property: 'products',
            dataFormatFunction: products => products?.join(' ')
          },
          {
            heading: 'status',
            property: 'status'
          },
          {
            heading: 'location box',
            property: 'locationBox'
          },
          {
            heading: 'location slot',
            property: 'locationSlot'
          },
          {
            heading: 'shared',
            property: 'isolateShared'
          },
          {
            heading: 'extended',
            property: 'isExtended',
            dataFormatFunction: isExtended => (isExtended ? 'yes' : 'no')
          },
          {
            heading: 'extension date',
            property: 'extensionDate',
            dataFormatFunction: date => date?.substring(0, 10) || ''
          },
          {
            heading: 'archived',
            property: 'dateDeleted',
            dataFormatFunction: dateDeleted => (dateDeleted ? 'yes' : 'no')
          }
        ];

        const clinicDetailColumns = [
          {
            heading: 'clinic ID',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.newport_id ? clinic.newport_id : 'no ID on file'
          },
          {
            heading: 'clinic email',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.email ? clinic.email : 'no email on file'
          },
          {
            heading: 'clinic address',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.shippingAddress?.address_line_1
                ? clinic.shippingAddress.address_line_1
                : 'no address on file'
          },
          {
            heading: 'clinic city',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.shippingAddress?.city
                ? clinic.shippingAddress.city
                : 'no city on file'
          },
          {
            heading: 'clinic state',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.shippingAddress?.state
                ? clinic.shippingAddress.state
                : 'no state on file'
          },
          {
            heading: 'clinic zip',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.shippingAddress?.postal_code
                ? clinic.shippingAddress.postal_code
                : 'no ZIP on file'
          },
          {
            heading: 'clinic phone',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.phone ? clinic.phone : 'no phone number on record'
          },
          {
            heading: 'clinic fax',
            property: 'clinicOwner',
            dataFormatFunction: clinic =>
              clinic?.fax ? clinic.fax : 'no fax number on record'
          }
        ];
        csvLayout.splice(8, 0, ...clinicDetailColumns);

        const fileName = `IsolatesExport_${formatDate(new Date())}.csv`;
        exportCsv(csvLayout, mappedList, fileName, false);
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    } else if (searchType === 'exportPdf') {
      if (list.results.length > 0) {
        const fileName = `IsolatesExport_${formatDate(new Date())}`;
        let exportable = [];
        for (let result of list.results) {
          exportable.push({
            'isolate number': result.isolateNbr || '',
            'genus species': result.genusSpecies || '',
            subtype: result.subtype || '',
            'isolation date': result.isolationDate?.substring(0, 10) || '',
            'expiration date': result.expirationDate?.substring(0, 10) || '',
            clinic: result.clinicOwner?.name || '',
            producer: result.producer?.displayName || '',
            products: result.products?.join(', ') || '',
            status: getStatusForExport(result),
            'location box': result.locationBox || '',
            'location slot': result.locationSlot || '',
            shared: result.isolateShared ? 'shared' : '',
            'extension date': result.extensionDate?.substring(0, 10) || '',
            extended: result.isExtended ? 'Yes' : 'No',
            _padding: undefined // objectExporter won't populate the last column without this padding column...
          });
        }
        objectExporter({
          exportable: exportable,
          type: 'pdf',
          headers: [
            'isolate number',
            'genus species',
            'subtype',
            'isolation date',
            'expiration date',
            'clinic',
            'producer',
            'products',
            'status',
            'location box',
            'location slot',
            'shared',
            'extension date',
            'extended'
          ],
          fileName: fileName,
          headerStyle:
            'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
          cellStyle: 'border: 1px solid lightgray; margin-bottom: -1px;',
          documentTitle: fileName
        });
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    }
    yield put(actions.searchSuccess(list.results));
    if (typeof cb === 'function') cb(list);
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, 'Error loading Isolate Search data'));
    yield put(actions.searchFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onIsolateListRequest({ currentUser }) {
  try {
    yield put(actions.isRefreshing(true));
    let listCallProps;
    let response;

    if (
      hasFeatureAccess(currentUser, FeatureEnum.IsolateLabInterface, 'read')
    ) {
      // If we have this feature access, we typically only need to see isolates that have some open pending action
      // If we need to see all isolates, that will be handled elsewhere
      // Get all with extension requests that have not yet been approved (using extensionDate)
      listCallProps = {
        limit: 200,
        extensionRequestExists: true,
        extensionDateExists: false
      };
      response = yield call(Isolate.list, listCallProps);
      yield put(actions.listSuccess(response.results));
      while (response.offset < response.count) {
        response = yield call(Isolate.list, {
          ...listCallProps,
          offset: response.offset + response.limit
        });
        if (response.results?.length > 0) {
          yield put(actions.listSuccess(response.results));
        }
      }

      // Get all with release requests
      listCallProps = {
        limit: 200,
        releaseRequestExists: true
      };
      response = yield call(Isolate.list, listCallProps);
      yield put(actions.listSuccess(response.results));
      while (response.offset < response.count) {
        response = yield call(Isolate.list, {
          ...listCallProps,
          offset: response.offset + response.limit
        });
        if (response.results?.length > 0) {
          yield put(actions.listSuccess(response.results));
        }
      }

      // Get all expiring within the next week
      listCallProps = {
        limit: 200
      };
      dateRangeToParamsForApiService('expirationDate', listCallProps, [
        moment(),
        moment().add(1, 'week')
      ]);
      response = yield call(Isolate.list, listCallProps);
      yield put(actions.listSuccess(response.results));
      while (response.offset < response.count) {
        response = yield call(Isolate.list, {
          ...listCallProps,
          offset: response.offset + response.limit
        });
        if (response.results?.length > 0) {
          yield put(actions.listSuccess(response.results));
        }
      }
    } else {
      // Get all. expecting ~20-50 results for the typical clinic, but maybe up to ~500.
      // TODO: Limit 1000 is just to get it to work without running out of memory with the imported 22k+ results set.
      // We could trim down the response to possibly resolve, or just keep it like this.
      // leaving it this way shouldn't be a big concern.
      listCallProps = {
        limit: 1000
      };
      response = yield call(Isolate.list, listCallProps);
      yield put(actions.listSuccess(response.results));
      while (response.offset < response.count) {
        response = yield call(Isolate.list, {
          ...listCallProps,
          offset: response.offset + response.limit
        });
        if (response.results?.length > 0) {
          yield put(actions.listSuccess(response.results));
        }
      }
    }
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, 'Error loading Isolate List data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onActivateIsolate({ isolateId }) {
  const isolate = yield select(
    state => state.accounts.isolates.data[isolateId]
  );
  if (isolateId && !isolate) {
    yield put(actions.refreshRequest(isolateId));
  }
}

export function* onRefreshIsolateRequest({ isolateId, refreshReleases }) {
  try {
    yield put(actions.isRefreshing(true));
    const isolate = yield call(Isolate.read, isolateId);

    if (refreshReleases) {
      // Grab approved releases for the isolate
      yield put(isolateReleaseActions.refreshReleasesForIsolate(isolate));
    }

    yield put(actions.refreshSuccess(isolate));
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, 'Error loading Isolate data'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onRefreshIsolatesForProduct({ productId, callbackFn }) {
  try {
    yield put(actions.isRefreshing(true));
    const isolates = yield call(Product.isolates, productId);
    yield put(actions.refreshIsolatesForProductSuccess(isolates));
    if (callbackFn) {
      callbackFn();
    }
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, 'Error loading Isolates data'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

const handleNavigationAfterSave = (typeOfSave, history, isolate) => {
  //depending on the type of Save either stay on the new isolate page or navigate to the isolate page of the isolate you just made
  if (typeOfSave === 'SaveIsolateAndCreateNewIsolate') {
    //goes to the top of the form after the fields are reset
    document
      .getElementById('clinicCard')
      .scrollIntoView({ behavior: 'smooth' });
  } else {
    if (history) {
      history.push(`/isolates/${isolate.isolateId}`);
    }
  }
};
export function* onSaveIsolateRequest({
  isolate,
  history,
  attachments,
  callback,
  typeOfSave
}) {
  try {
    yield put(actions.isRefreshing(true));
    // create subobjects before saving the isolate if new contact or location
    if (!isolate.producer.contactId) {
      const producer = new Contact(isolate.producer);
      yield call([producer, producer.save]);
      isolate.producer = producer;
    }
    if (isolate.herdLocation && !isolate.herdLocation.locationId) {
      const herdLocation = new Location({
        ...isolate.herdLocation,
        contactId: isolate.producer.contactId
      });
      yield call([herdLocation, herdLocation.save]);
      isolate.herdLocation = herdLocation;
    }

    yield call([isolate, isolate.save]);
    yield put(actions.auditReportRequest(isolate.isolateId));
    yield put(actions.refreshSuccess(isolate));

    // Attachments require an id so add attachments after
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        const formData = new FormData();
        formData.append('file', attachments[i]);
        formData.append('type', 'isolate');
        formData.append('referenceUUID', isolate.isolateId);
        yield call(api.postFile, 'attachments', formData);
      }
    }

    if (callback) {
      // Resets 'isFieldsTouched' and the attachments array so the user can navigate away without being prompted.
      callback();
    }

    handleNavigationAfterSave(typeOfSave, history, isolate);

    message.success(intl.get('savedWithNoun', { noun: intl.get('isolate') }));
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('isolate.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onArchiveIsolateRequest({ isolateId, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(Isolate.archive, isolateId);
    yield put(actions.removeIsolateFromStore(isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(intl.get('archivedNoun', { noun: intl.get('isolate') }));
    }
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.archiving.noun', {
          noun: intl.get('isolate.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeleteIsolateRequest({ isolateId, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(Isolate.delete, isolateId);
    yield put(actions.removeIsolateFromStore(isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(intl.get('deletedThing', { thing: intl.get('isolate') }));
    }
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.deleting.properNoun', {
          properNoun: intl.get('isolate.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

// Audit reporting
export function* onIsolateAuditReportRequest(action) {
  const { isolateId } = action;
  try {
    yield put(actions.isRefreshingEvents(true));
    const events = yield call(Isolate.auditReport, isolateId);
    yield put(actions.auditReportSuccess(isolateId, events));
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, 'Error loading Isolate Events'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshingEvents(false));
  }
}

// Extension Requests
export function* onSaveExtensionRequest({ extension }) {
  const isolateId = extension.isolateId;
  try {
    yield put(actions.isRefreshing(true));
    yield call([extension, extension.saveExtension]);
    yield put(actions.refreshRequest(isolateId));
    yield call(
      message.success,
      intl.get('savedWithNoun', { noun: intl.get('extension.request') })
    );
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('extension.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSignExtensionRequest({ extension }) {
  const isolateId = extension.isolateId;
  try {
    yield put(actions.isRefreshing(true));
    // Save the extension to get any changes, then sign (same as documents)
    yield call([extension, extension.saveExtension]);
    yield call(
      IsolateExtension.signExtension,
      isolateId,
      extension.isolateExtensionId
    );
    yield put(actions.refreshRequest(isolateId));
    yield call(message.success, intl.get('isolate.extension.requested'));
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.signing.noun', {
          noun: intl.get('extension.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeleteExtensionRequest({ isolate, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(
      IsolateExtension.deleteExtension,
      isolate.isolateId,
      isolate.extensionRequest.isolateExtensionId
    );
    yield put(actions.refreshRequest(isolate.isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(intl.get('extension.deleted'));
    }
  } catch (err) {
    console.error(err);
    yield call(notification.warning, {
      message: intl.get('error.deleting.properNoun', {
        properNoun: intl.get('extension.request.lc')
      }),
      description: intl.get('server.error')
    });
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onApproveExtensionRequest({ isolate, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(
      IsolateExtension.approveExtension,
      isolate.isolateId,
      isolate.extensionRequest.isolateExtensionId
    );
    yield put(actions.refreshRequest(isolate.isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(
        intl.get('extension.approved.noun', { noun: intl.get('isolate.lc') })
      );
    }
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, intl.get('extension.approved.error')));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onExtensionChangesRequest({ extension, history }) {
  try {
    const isolateId = extension.isolateId;
    yield put(actions.isRefreshing(true));
    yield call([extension, extension.requestChanges]);
    yield put(actions.refreshRequest(isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(intl.get('extension.changesRequested'));
    }
  } catch (err) {
    console.error(err);
    yield put(apiFailure(err, intl.get('extension.changesRequested.error')));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

// Release requests
export function* onSaveReleaseRequest(action) {
  let { release, history } = action;
  const isolateId = release.isolateId;

  try {
    yield put(actions.isRefreshing(true));
    yield call([release, release.saveRelease]); // fire a save request
    if (history) {
      yield call(history.push, `/isolates`); // navigate to the list view
    }
    yield put(actions.refreshRequest(isolateId)); // refresh the isolate to grab updates to history
    yield call(message.success, intl.get('isolate.release.requested'));
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('isolate.release.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeleteReleaseRequest(action) {
  const isolate = action.isolate;
  try {
    yield put(actions.isRefreshing(true));
    yield call(
      IsolateRelease.deleteRelease,
      isolate.isolateId,
      isolate.releaseRequest.isolateReleaseId
    );
    yield put(actions.refreshRequest(isolate.isolateId));
  } catch (err) {
    console.error(err);
    yield call(notification.warning, {
      message: intl.get('error.deleting.properNoun', {
        properNoun: intl.get('isolate.release.request.lc')
      }),
      description: intl.get('server.error')
    });
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onApproveReleaseRequest({ release, history }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([release, release.approveRelease]);
    if (release.releaseReason === 'OWNERSHIP_CHANGE') {
      yield put(actions.removeIsolateFromStore(release.isolateId));
    }
    if (history) {
      history.push(`/isolates`);
      message.success(
        intl.get('release.action', { action: intl.get('approved.lc') })
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.submitting.noun', {
          noun: intl.get('isolate.release.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeclineReleaseRequest({ release, history }) {
  try {
    const isolateId = release.isolateId;
    yield put(actions.isRefreshing(true));
    yield call([release, release.declineRelease]);
    yield put(actions.refreshRequest(isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(
        intl.get('release.action', { action: intl.get('declined.lc') })
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.submitting.noun', {
          noun: intl.get('isolate.release.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onReleaseInfoRequest({ release, history }) {
  try {
    const isolateId = release.isolateId;
    yield put(actions.isRefreshing(true));
    yield call([release, release.requestInfo]);
    yield put(actions.refreshRequest(isolateId));
    if (history) {
      history.push(`/isolates`);
      message.success(
        intl.get('release.action', {
          action: intl.get('sentBack.withComments.lc')
        })
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      apiFailure(
        err,
        intl.get('error.submitting.noun', {
          noun: intl.get('isolate.release.request.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onStoreDefaultDiagnosticLab() {
  try {
    let defaultDiagnosticLab = yield select(
      state => state.accounts.isolates.defaultDiagnosticLab
    );
    if (!defaultDiagnosticLab?.id) {
      const list = yield call(
        api.get,
        'lab/autocomplete?term=Newport+Laboratories&includeAllCountries=true'
      );
      yield put(actions.storeDefaultDiagnosticLab(list[0]));
    }
  } catch (err) {
    console.error('error in onStoreDefaultDiagnosticLab', err);
  }
}

export default function* isolateSagas() {
  yield takeLatest(types.SEARCH_REQUEST, onIsolateSearchRequest);
  yield takeLatest(types.LIST_REQUEST, onIsolateListRequest);
  yield takeLatest(types.ACTIVATE_ISOLATE, onActivateIsolate);
  yield takeLatest(types.REFRESH_REQUEST, onRefreshIsolateRequest);
  yield takeLatest(
    types.REFRESH_ISOLATES_FOR_PRODUCT,
    onRefreshIsolatesForProduct
  );
  yield takeLatest(types.SAVE_REQUEST, onSaveIsolateRequest);
  yield takeLatest(types.ARCHIVE_REQUEST, onArchiveIsolateRequest);
  yield takeLatest(types.DELETE_REQUEST, onDeleteIsolateRequest);
  yield takeLatest(types.AUDIT_REPORT_REQUEST, onIsolateAuditReportRequest);
  yield takeLatest(types.EXTENSION_SAVE_REQUEST, onSaveExtensionRequest);
  yield takeLatest(types.EXTENSION_SIGN_REQUEST, onSignExtensionRequest);
  yield takeLatest(types.DELETE_EXTENSION_REQUEST, onDeleteExtensionRequest);
  yield takeLatest(types.APPROVE_EXTENSION_REQUEST, onApproveExtensionRequest);
  yield takeLatest(types.EXTENSION_CHANGES_REQUEST, onExtensionChangesRequest);
  yield takeLatest(types.RELEASE_SAVE_REQUEST, onSaveReleaseRequest);
  yield takeLatest(types.DELETE_RELEASE_REQUEST, onDeleteReleaseRequest);
  yield takeLatest(types.APPROVE_RELEASE_REQUEST, onApproveReleaseRequest);
  yield takeLatest(types.DECLINE_RELEASE_REQUEST, onDeclineReleaseRequest);
  yield takeLatest(types.RELEASE_INFO_REQUEST, onReleaseInfoRequest);
  yield takeLatest(
    types.DEFAULT_DIAGNOSTIC_LAB_REQUEST,
    onStoreDefaultDiagnosticLab
  );
}
