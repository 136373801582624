import React, { Component } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Form, Select } from 'antd';
import { cloneDeep } from 'lodash';

export default class OrgContactSelect extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    contactLabel: PropTypes.string,
    contacts: PropTypes.array,
    shouldRequireContacts: PropTypes.bool,
    allowMultipleSelections: PropTypes.bool.isRequired,
    contactFormField: PropTypes.string,
    selectedContacts: PropTypes.array,
    onChange: PropTypes.func,
    allowUnknownOption: PropTypes.bool
  };

  static defaultProps = {
    allowUnknownOption: false
  };

  getSelectedContactIds = () => {
    const { contacts, selectedContacts, allowMultipleSelections } = this.props;
    let selectedContactIds = selectedContacts?.length
      ? cloneDeep(selectedContacts)?.map(contact => contact.id)
      : [];
    // This check makes sure that the org hasn't changes. If it has then remove any selected contacts.
    // We could do a check that selectedContact[0].[orgType + 'Id] === orgType.id instead if we return clinicId/labId in the contacts call but we don't currently.
    if (
      selectedContactIds &&
      !contacts.find(contact => contact.id === selectedContactIds[0])
    ) {
      selectedContactIds = [];
    }
    //To allow the user select Unknown as an option for a labTechnician
    if (selectedContacts?.[0] === 'Unknown') {
      selectedContactIds = selectedContacts;
    }
    // For 'default' style select need to turn the initial value of select from '[id]' into 'id' so the initial field value matches how default select returns after a selection occurs.
    // This will end up as the id of the only contact or undefined if the above if statement executes or if no selected contacts
    if (!allowMultipleSelections) {
      selectedContactIds = selectedContactIds[0];
    }
    return selectedContactIds;
  };

  render() {
    const {
      form,
      contactLabel,
      selectedContacts,
      contacts,
      shouldRequireContacts,
      contactFormField,
      allowMultipleSelections,
      allowUnknownOption
    } = this.props;
    const selectedContactIds = this.getSelectedContactIds();
    // Fetches the contacts of only the vets which are not already in the isolate from the store
    let remainingContacts = allowMultipleSelections
      ? contacts.filter(it => !selectedContactIds.includes(it.id))
      : contacts.filter(it => selectedContactIds !== it.id);
    return (
      <Form.Item label={contactLabel} style={{ margin: '0px 12px 12px 12px' }}>
        {form.getFieldDecorator(contactFormField, {
          rules: [
            { required: shouldRequireContacts, message: intl.get('required') }
          ],
          onChange: this.props.onChange,
          initialValue: selectedContactIds
        })(
          <Select
            showSearch
            showAction={['focus', 'click']}
            mode={allowMultipleSelections ? 'multiple' : 'default'}
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectedContacts?.map(it => (
              <Select.Option key={it.id} value={it.id}>
                {it.lastName && it.firstName
                  ? it.lastName + ', ' + it.firstName
                  : it.commonName}
              </Select.Option>
            ))}
            {remainingContacts?.map(it => (
              <Select.Option key={it.id} value={it.id}>
                {it.lastName + ', ' + it.firstName}
              </Select.Option>
            ))}
            {allowUnknownOption ? (
              <Select.Option value={'Unknown'}>
                {intl.get('unknown')}
              </Select.Option>
            ) : (
              undefined
            )}
          </Select>
        )}
      </Form.Item>
    );
  }
}
