import Model from 'models/Model';
import { downloadFile } from 'utils/downloadUtil';

export default class Attachment extends Model {
  // Instance Methods

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      name: {},
      contentType: {},
      dateCreated: {},
      owner: {},
      rolesWithAccess: {},
      reference: {},
      type: {}
    };
  }

  static get apiPath() {
    return 'attachments';
  }

  static get domain() {
    return 'Attachments';
  }

  static download = async id => {
    downloadFile(`${this.apiPath}/${id}/download`);
  };
}
