import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import intl from 'react-intl-universal';

import {
  commonLoadStartupDataSuccess,
  commonLoadStartupDataStarted,
  commonLoadStartupDataFinished,
  emailCertificateSuccess,
  emailCertificateFailure
} from 'containers/accounts/common/store/accountsCommonActions';
import {
  COMMON_LOAD_STARTUP_DATA_REQUEST,
  EMAIL_CERTIFICATE_REQUEST,
  EMAIL_CERTIFICATE_SUCCESS
} from 'containers/accounts/common/store/accountsCommonConstants';
import {
  apiFailure,
  connectionError
} from 'containers/app/store/data/dataActions';
import Document from 'models/documents/Document';
import * as api from 'utils/api';

export function* onCommonLoadStartupDataRequest() {
  try {
    yield put(commonLoadStartupDataStarted());

    let lookupLists = yield call(api.get, '/list/lookup');
    let env;

    try {
      env = yield call(api.get, '/report/env');
    } catch (err) {
      yield put(apiFailure(err));
      env = { environment: 'local' };
    }

    [
      lookupLists.speciesMap,
      lookupLists.speciesTypesMap
    ] = addAdditionalSeSpecies(
      lookupLists.speciesMap,
      lookupLists.seSpecies,
      lookupLists.speciesTypesMap
    );

    yield put(
      commonLoadStartupDataSuccess({
        lookupLists,
        environment: env.environment
      })
    );
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(commonLoadStartupDataFinished());
  }
}

function addAdditionalSeSpecies(species, seSpecies, speciesTypesMap) {
  let currentSpeciesInList = Object.values(species);
  let index = currentSpeciesInList.length;
  seSpecies.map(it => {
    if (!currentSpeciesInList.includes(it.species)) {
      species[index] = it.species;
      index++;
      speciesTypesMap[it.species] = it.types;
    }
    return true;
  });
  return [species, speciesTypesMap];
}

export function* onEmailCertificateRequest(action) {
  let { emailAddresses, certificateIds } = action;
  try {
    yield call(Document.batchEmail, certificateIds, emailAddresses);
    yield put(emailCertificateSuccess());
  } catch (err) {
    yield put(apiFailure(err));
    yield put(emailCertificateFailure());
  }
}

export function* onEmailCertificateSuccess(_action) {
  yield call(message.success, intl.get('emailsSent'));
}

export default function* accountsCommonSagas() {
  yield takeLatest(
    COMMON_LOAD_STARTUP_DATA_REQUEST,
    onCommonLoadStartupDataRequest
  );
  yield takeLatest(EMAIL_CERTIFICATE_REQUEST, onEmailCertificateRequest);
  yield takeLatest(EMAIL_CERTIFICATE_SUCCESS, onEmailCertificateSuccess);
}
