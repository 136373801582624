import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

function IncompleteRegistrationModal({ visible, onCloseModal, onConfirm }) {
  return (
    <Modal
      visible={visible}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onCloseModal}>
          {intl.get('register.continue.no')}
        </Button>,
        <Button key="continue" type="primary" onClick={onConfirm}>
          {intl.get('register.continue.yes')}
        </Button>
      ]}
    >
      <h2>{intl.get('hello.again')}</h2>
      <p>{intl.get('register.continue.info')}</p>
      <p>{intl.get('register.continue.question')}</p>
    </Modal>
  );
}

IncompleteRegistrationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export { IncompleteRegistrationModal as default };
