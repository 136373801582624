import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Form, Modal, Button, notification } from 'antd';
import PropTypes from 'prop-types';
import { Location, LocationFormFields } from 'modules/Location/locationIndex';
import { saveRequest as locationSaveRequest } from 'modules/Location/store/locationActions';

class LocationEditModalComponent extends Component {
  static propTypes = {
    locationSaveRequest: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    locations: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      this.props.form.setFieldsValue({ ...this.props.location });
    }
  }

  removeUnusedLocationFormFields(formValues, location) {
    //if the any address info  is not filled on the form then  do not send values for the respective missing address info
    if (!formValues.city) location.city = null;
    if (!formValues.state) location.state = null;
    if (!formValues.postalCode) location.postalCode = null;
    if (!formValues.addressLine1) location.addressLine1 = null;
    if (!formValues.latitude) location.latitude = null;
    if (!formValues.longitude) location.longitude = null;
  }
  saveLocation = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      const { locationSaveRequest } = this.props;
      if (err) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('location.lowerCase')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      const location = new Location({
        ...this.props.location,
        ...formValues
      });

      //Do not send undefined form fields or old location data to the new location PUT request, the API response is not overwriting the old Location Object completely.
      this.removeUnusedLocationFormFields(formValues, location);

      const modelValidationErrors = location.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('location.lowerCase')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      locationSaveRequest(location, this.handleLocationSaved);
    });
  };

  handleLocationSaved = () => {
    // Since location updated, send the change to the parent form
    const { location, locations, onChange, closeModal } = this.props;
    const updatedLocation = locations[location.locationId];
    closeModal();
    onChange(updatedLocation);
  };

  cancelEdit = () => {
    const { form, closeModal } = this.props;
    form.isFieldsTouched()
      ? Modal.confirm({
          title: intl.get('cancel.edit'),
          content: intl.get('cancel.edit.body'),
          okText: intl.get('discard'),
          onOk: closeModal
        })
      : closeModal();
  };

  render() {
    const { visible, form, location } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={this.cancelEdit}
        //Need to force render so fields can be set in component did update initally
        forceRender={true}
        footer={[
          <Button key="cancel" onClick={this.cancelEdit}>
            {intl.get('cancel')}
          </Button>,
          <Button key="save" type="primary" onClick={this.saveLocation}>
            {intl.get('save')}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <LocationFormFields form={form} location={location} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  locations: accounts.locations.data
});

const mapDispatchToProps = {
  locationSaveRequest
};

const LocationEditModalForm = Form.create()(LocationEditModalComponent);

const LocationEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationEditModalForm);

export { LocationEditModal as default, LocationEditModalComponent };
