import { getRecaptchaSiteKey } from 'utils/envUtil';
import { loadScript } from 'utils/externalScript';

export const loadRecaptcha = () => {
  loadScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${getRecaptchaSiteKey()}`
  ).catch(error => console.error('Unable to load recaptcha', error));
};

export const getRecaptchaToken = async action => {
  if (!window.grecaptcha) return '';

  return await window.grecaptcha.enterprise.execute(getRecaptchaSiteKey(), {
    action
  });
};
