import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Col, Input, Row, Button } from 'antd';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FONT, THEME } from 'style';

import actionInfoPropTypes from 'components/GvlTable/actionInfoPropTypes';
import TableActionsMenu from 'components/GvlTable/TableActionsMenu';
import GvlIcon from 'elements/GvlIcon';
import { MobileIcon } from 'style/commonEmotions';

/* istanbul ignore next */
const StyledSearch = styled(Input, {
  shouldForwardProp: prop => prop !== 'additionalSearchComponentPresent'
})(({ additionalSearchComponentPresent }) => {
  const style = {
    width: '250px'
  };

  if (additionalSearchComponentPresent) {
    style.width = '250px';
    style.height = '32px';
    style.float = 'left';
  }

  return style;
});

const EllipsisInput = styled(Input)({
  '& input': {
    textOverflow: 'ellipsis'
  }
});

class TableSearchAndActionsComponent extends Component {
  static propTypes = {
    viewMode: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    actionInfo: PropTypes.shape(actionInfoPropTypes),
    selectedRows: PropTypes.array,
    placeholderText: PropTypes.string.isRequired,
    editColumnProps: PropTypes.shape({
      areColumnsMovable: PropTypes.bool,
      onColumnsEditBtnToggle: PropTypes.func
    }),
    additionalSearchComponent: PropTypes.object
  };

  state = {
    isMobileSearchFocused: false
  };

  renderSearch = () => {
    const {
      additionalSearchComponent,
      onSearchChange,
      searchText,
      placeholderText,
      viewMode
    } = this.props;
    if (viewMode !== 'Mobile') {
      // For non-mobile, use a regular search box
      return (
        <StyledSearch
          placeholder={placeholderText}
          value={searchText}
          onChange={onSearchChange}
          allowClear={true}
          additionalSearchComponentPresent={additionalSearchComponent || false}
        />
      );
    } else if (this.state.isMobileSearchFocused) {
      // For mobile when the search is focused, render a usable input with mobile-friendly styling
      return (
        <Input
          className="gvl-input-large-icons"
          placeholder={placeholderText}
          value={searchText}
          onChange={onSearchChange}
          onPressEnter={() => this.setState({ isMobileSearchFocused: false })}
          autoFocus={true}
          prefix={
            <GvlIcon
              type="gvl-search"
              style={{ fontSize: '24px' }}
              onClick={() => this.setState({ isMobileSearchFocused: false })}
            />
          }
          suffix={
            <GvlIcon
              type="gvl-x"
              style={{ fontSize: '24px' }}
              onClick={() => onSearchChange({ target: { value: '' } })}
            />
          }
          onBlur={() =>
            // This is wrapped in setTimeout so the onChange handler can run before this input unmounts.
            setTimeout(() => this.setState({ isMobileSearchFocused: false }))
          }
        />
      );
    } else if (searchText) {
      // For mobile when the search is not active but there is a search term, show the search term in a disabled input
      return (
        <div onClick={() => this.setState({ isMobileSearchFocused: true })}>
          <EllipsisInput
            className="gvl-input-large-icons"
            disabled={true}
            value={searchText}
            prefix={<GvlIcon type="gvl-search" style={{ fontSize: '24px' }} />}
          />
        </div>
      );
    } else {
      // For mobile when there is no search term and the search is not active, render just a search icon
      return (
        <MobileIcon
          type="gvl-search"
          onClick={() => this.setState({ isMobileSearchFocused: true })}
        />
      );
    }
  };

  render() {
    const {
      actionInfo,
      additionalSearchComponent,
      selectedRows,
      editColumnProps,
      viewMode
    } = this.props;

    let multiSelectText = null;
    if (selectedRows.length === 0 && actionInfo && actionInfo.noSelectedText) {
      multiSelectText = actionInfo.noSelectedText;
    } else if (
      actionInfo &&
      actionInfo.allowMultiSelect &&
      selectedRows.length > 0
    ) {
      if (viewMode === 'Mobile') {
        multiSelectText = `${selectedRows.length} ${intl
          .get('selected')
          .toLowerCase()}`;
      } else {
        multiSelectText = `${selectedRows.length} ${
          selectedRows.length > 1
            ? actionInfo.multipleSelectedText
            : actionInfo.singleSelectedText
        }:`;
      }
    }

    const isColumnReorderAllowed =
      editColumnProps && Object.keys(editColumnProps).length > 0;
    const actionsSelectorStyle = {
      display:
        viewMode === 'Mobile' && this.state.isMobileSearchFocused
          ? 'none'
          : 'unset'
    };
    if (!isColumnReorderAllowed) {
      actionsSelectorStyle.flexGrow = 1;
      actionsSelectorStyle.textAlign = 'right';
    }

    return (
      <Row
        type="flex"
        justify="start"
        gutter={viewMode === 'Mobile' ? 0 : 16}
        align="middle"
        style={{
          padding: viewMode === 'Mobile' ? 'unset' : '16px 16px 0px 16px',
          height: viewMode === 'Mobile' ? '48px' : 'unset',
          width: '100%',
          fontWeight: FONT.weight.regular
        }}
      >
        <Col
          style={
            viewMode === 'Mobile'
              ? {
                  flexGrow: 1,
                  textAlign: 'left',
                  maxWidth:
                    // If the mobile search is not focused and there are selected actions, shrink the search bar to make room for the actions button.
                    !this.state.isMobileSearchFocused && multiSelectText
                      ? '125px'
                      : 'unset'
                }
              : {}
          }
        >
          {this.renderSearch()}
        </Col>
        {additionalSearchComponent ? (
          <Col>{additionalSearchComponent}</Col>
        ) : null}
        {multiSelectText || isColumnReorderAllowed ? (
          <React.Fragment>
            {isColumnReorderAllowed ? (
              <Col
                style={{
                  flexGrow: 1,
                  textAlign: 'right',
                  display: viewMode === 'Mobile' ? 'none' : 'unset'
                }}
              >
                <Button onClick={editColumnProps.onColumnsEditBtnToggle}>
                  {intl.get(
                    editColumnProps.areColumnsMovable
                      ? 'columns.reorder.save'
                      : 'columns.reorder.start'
                  )}
                </Button>
              </Col>
            ) : null}
            {multiSelectText ? (
              <React.Fragment>
                <Col style={actionsSelectorStyle}>
                  <span style={{ color: THEME.text() }}>{multiSelectText}</span>
                </Col>
                <Col
                  style={
                    viewMode === 'Mobile' && this.state.isMobileSearchFocused
                      ? {
                          display: 'none'
                        }
                      : {}
                  }
                >
                  <TableActionsMenu
                    isMultiselect={true}
                    actionInfo={actionInfo}
                    onClickData={selectedRows}
                  />
                </Col>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  viewMode: state.app.data.viewMode
});

const TableSearchAndActions = connect(
  mapStateToProps,
  null
)(TableSearchAndActionsComponent);
export { TableSearchAndActions as default, TableSearchAndActionsComponent };
