import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from 'modules/Location/store/locationActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false
});

// general methods
export const onLocationRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

// list methods
export const onLocationListSuccess = (state, payload) => {
  let locations = {};
  Object.values(payload.locations).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(location => (locations[location.locationId] = location));
    }
  });
  return state.merge({
    data: locations,
    lastSuccessfulRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onLocationListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

// refresh methods
export const onLocationRefreshSuccess = (state, payload) =>
  Immutable.setIn(
    state,
    ['data', payload.location.locationId],
    payload.location
  );

// exported reducer
export const locationReducer = createReducer(initialState, {
  [types.LIST_SUCCESS]: onLocationListSuccess,
  [types.LIST_FAILURE]: onLocationListFailure,
  [types.REFRESH_SUCCESS]: onLocationRefreshSuccess,
  [types.IS_REFRESHING]: onLocationRefreshing
});
