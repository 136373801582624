import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  apply
} from 'redux-saga/effects';
import { message, notification } from 'antd';

import LabTest from 'models/LabTest';
import {
  refreshLabTestListSuccess,
  refreshLabTestListFailure,
  labClearTestErrors,
  searchLabTestsSuccess,
  searchLabCertificatesSuccess,
  removeLabTestFromList,
  labSendToNvslFinished,
  labAttachNvslReportFinished,
  labRemoveNvslReportFinished
} from 'containers/accounts/lab/store/accountsLabActions';
import {
  REFRESH_LAB_TEST_LIST_REQUEST,
  REFRESH_LAB_TEST_REQUEST,
  RESULT_LAB_TESTS,
  SIGN_LAB_TESTS,
  SIGN_LAB_TEST,
  NVSL_RESULT_LAB_TEST,
  SEND_BACK_LAB_TEST,
  SEARCH_LAB_TESTS,
  SEARCH_LAB_CERTIFICATES,
  LAB_SEND_TO_NVSL_REQUEST,
  LAB_ATTACH_NVSL_REPORT_REQUEST,
  LAB_REMOVE_NVSL_REPORT_REQUEST
} from 'containers/accounts/lab/store/accountsLabConstants';
import intl from 'react-intl-universal';

import { apiFailure } from 'containers/app/store/data/dataActions';
import { testSelector } from 'containers/accounts/lab/store/accountsLabSelectors';
import { exportCsv, formatDate } from 'utils/csvUtil';
import { getContactCommonName } from 'components/Contact/contactHelpers';
import * as api from 'utils/api';
import { getAccessionNumber, getFinalTestResult } from 'utils/labTestUtils';

export function* onRefreshLabTestListRequest() {
  const network = yield select(state => state.app.user.info.networkedLab);
  try {
    let labTests = yield call(LabTest.getPendingResults);
    if (network) {
      labTests.push(...(yield call(LabTest.getPendingNetworkResults)));
    }
    yield put(refreshLabTestListSuccess(labTests));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading lab test list data'));
    yield put(refreshLabTestListFailure());
  }
}

export function* onRefreshLabTestRequest(action) {
  const { labTestId } = action;
  try {
    const labTest = yield call(LabTest.read, labTestId);
    yield put(refreshLabTestListSuccess([labTest], true));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading lab test list data'));
    yield put(refreshLabTestListFailure());
  }
}

export function* onResultLabTests(action) {
  const { labTests, resultData, ignoreNullFields } = action;
  const updatedLabTests = yield all(
    labTests.map((labTest, index) => {
      const individualResultData = resultData;

      // TODO: This section is specific to batch edit, refactor to put this either in a separate action or contained in the LabTestBatchEdit component
      if (resultData.prefix || resultData.startingNumber) {
        individualResultData.accession = resultData.startingNumber
          ? getAccessionNumber(
              resultData.prefix,
              resultData.startingNumber,
              index
            )
          : `${resultData.prefix || ''}${labTest.accession || ''}`;
      }

      // TODO: refactor uses of this saga to pass in LabTest objects that already have everything formatted and ready to pass to the API
      const newLabTest = new LabTest({
        ...individualResultData,
        id: labTest.id || individualResultData.id
      });
      return apply(newLabTest, newLabTest.resultTest, [ignoreNullFields]);
    })
  );
  yield put(refreshLabTestListSuccess(updatedLabTests));

  let messageId;
  let messageParams;
  if (labTests.length === 1) {
    if (updatedLabTests.length === 1) {
      messageId = 'savedWithNoun';
      messageParams = { noun: intl.get('test') };
    } else {
      messageId = 'error.saving.noun';
      messageParams = {
        noun: intl.get('test').toLowerCase()
      };
    }
  } else if (labTests.length > 1) {
    if (updatedLabTests.length === labTests.length) {
      messageId = 'savedWithNoun';
      messageParams = { noun: intl.get('animal.tests') };
    } else if (updatedLabTests.length === 0) {
      messageId = 'error.saving.noun';
      messageParams = {
        noun: intl.get('animal.tests').toLowerCase()
      };
    } else if (updatedLabTests.length < labTests.length) {
      // TODO: better message for this case?
      // messageId = 'test.batchEdit.partialSuccess';
      messageId = 'error.saving.noun';
      messageParams = {
        noun: intl.get('animal.tests').toLowerCase()
      };
    }
  }
  if (messageId) {
    yield call(message.success, intl.get(messageId, messageParams));
  }
}

export function* onSignLabTests(action) {
  const { ids, credentials, modal } = action;
  let errors = new Set();
  let results = {};
  // clear previous errors
  yield put(labClearTestErrors());
  try {
    results = yield call(
      LabTest.batchSign,
      ids,
      credentials.username,
      credentials.password
    );
    for (const id in results) {
      const data = results[id];
      let storeTest = yield select(testSelector, id);
      // handle errors
      if (data.status !== 200) {
        errors.add(data.error.message);
        storeTest = { ...storeTest, tableRowClass: 'gvl-row-error' };
      } else {
        storeTest = { ...storeTest, cogginsStatus: 'resulted' };
      }
      yield put(refreshLabTestListSuccess([storeTest]));
    }
  } catch (err) {
    errors.add(err);
  } finally {
    yield call(modal.close);
    const messageType = ids.length > 1 ? 'batch' : 'single';
    // handle errors/success
    if (errors.size > 0) {
      yield put(
        apiFailure([...errors], intl.get('test.sign.errors.' + messageType))
      );
    } else if (results) {
      yield call(message.success, intl.get('test.sign.success.' + messageType));
    }
  }
}

export function* onSignLabTest(action) {
  const { labTest, credentials, modal } = action;
  yield* onResultLabTests({
    labTests: [labTest],
    resultData: labTest
  });
  yield* onSignLabTests({ ids: [labTest.id], credentials, modal });
}

export function* onNvslResultLabTest(action) {
  try {
    const { labTest, result } = action;
    const resultedLabTest = yield apply(labTest, labTest.nvslResultTest, [
      result
    ]);
    yield put(refreshLabTestListSuccess([resultedLabTest]));
  } catch (e) {
    yield put(apiFailure(e));
  }
}

export function* onSendBackLabTest(action) {
  const { eia, labTest, reason, cb } = action;
  yield apply(eia, eia.rejectFromLab, [reason]);
  yield put(removeLabTestFromList(labTest));
  if (cb) {
    cb();
  }
}

export function* onSearchLabTests(action) {
  try {
    const labTests = yield call(LabTest.advancedSearch, action.searchParams);
    if (labTests.length >= 200) {
      notification.warning({
        message: intl.get('lab.warn.tooManyResults.title'),
        description: intl.get('lab.warn.tooManyResults.message')
      });
    }
    yield put(refreshLabTestListSuccess(labTests));
    yield put(searchLabTestsSuccess(labTests.map(labTest => labTest.id)));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onSearchLabCertificates(action) {
  try {
    const labCerts = yield call(
      LabTest.advancedCertSearch,
      action.searchParams
    );
    if (labCerts.length >= 200) {
      notification.warning({
        message: intl.get('lab.warn.tooManyResults.title'),
        description: intl.get('lab.warn.tooManyResults.message')
      });
    }
    if (action.searchParams.report && labCerts.length > 0) {
      const csvLayout = [
        { heading: 'Certificate #', property: 'serialNumber' },
        { heading: 'Owner', property: 'owner.name' },
        { heading: 'Origin', property: 'origin.name' },
        { heading: 'Animal', property: 'animal.name' },
        {
          heading: 'Result',
          property: 'test',
          dataFormatFunction: test => getFinalTestResult(test)
        },
        {
          heading: 'Date Resulted',
          property: 'test.resultDate',
          dataFormatFunction: date => date.substring(0, 10)
        },
        {
          heading: 'Signing Vet',
          property: 'vet',
          dataFormatFunction: getContactCommonName
        },
        { heading: 'Accession', property: 'test.accession' }
      ];
      let fileName = `CertificateExport_${formatDate(new Date())}.csv`;
      exportCsv(csvLayout, labCerts, fileName);
    } else {
      if (action.searchParams.report)
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
    }
    yield put(searchLabCertificatesSuccess(labCerts));
  } catch (err) {
    yield put(apiFailure(err));
  }
}

export function* onLabSendToNvslRequest(action) {
  const { labTest } = action;

  try {
    const submittedLabTest = yield call(LabTest.submitToNvsl, labTest);
    yield put(refreshLabTestListSuccess([submittedLabTest]));
    yield put(labSendToNvslFinished());
    yield call(notification.success, {
      message: intl.get('sendToNvslSuccessMessage'),
      description: intl.get('sendToNvslSuccessDescription')
    });
  } catch (error) {
    const errorDescription = yield call(
      api.extractApiErrorDescriptionFromResponse,
      error
    );
    if (errorDescription) {
      yield call(notification.error, {
        message: intl.get('sendToNvslError'),
        description: errorDescription
      });
    } else {
      console.log(error);
      yield put(apiFailure(error));
    }
    yield put(labSendToNvslFinished());
  }
}

export function* onLabAttachNvslReportRequest(action) {
  const { labTestId, file, signAfterAttach } = action;

  try {
    const attachmentId = yield call(LabTest.attachNvslReport, file, labTestId);
    yield put(labAttachNvslReportFinished(attachmentId, signAfterAttach));
    yield call(
      message.success,
      intl.get('eia.nvsl.labTech.attach.report.attached')
    );
  } catch (error) {
    const errorDescription = yield call(
      api.extractApiErrorDescriptionFromResponse,
      error
    );
    if (errorDescription) {
      yield call(notification.error, {
        message: intl.get('eia.nvsl.labTech.attach.report.attach.error'),
        description: errorDescription
      });
    } else {
      console.log(error);
      yield put(apiFailure(error));
    }
    yield put(labAttachNvslReportFinished());
  }
}

export function* onLabRemoveNvslReportRequest(action) {
  const { labTestId } = action;

  try {
    yield call(LabTest.removeNvslReport, labTestId);
    yield put(labRemoveNvslReportFinished(true));
    yield call(
      message.success,
      intl.get('eia.nvsl.labTech.attach.report.removed')
    );
  } catch (error) {
    const errorDescription = yield call(
      api.extractApiErrorDescriptionFromResponse,
      error
    );
    if (errorDescription) {
      yield call(notification.error, {
        message: intl.get('eia.nvsl.labTech.attach.report.remove.error'),
        description: errorDescription
      });
    } else {
      console.log(error);
      yield put(apiFailure(error));
    }
    yield put(labRemoveNvslReportFinished());
  }
}

export default function* accountsLabSagas() {
  yield takeLatest(REFRESH_LAB_TEST_LIST_REQUEST, onRefreshLabTestListRequest);
  yield takeLatest(REFRESH_LAB_TEST_REQUEST, onRefreshLabTestRequest);
  yield takeEvery(RESULT_LAB_TESTS, onResultLabTests);
  yield takeEvery(SIGN_LAB_TESTS, onSignLabTests);
  yield takeEvery(SIGN_LAB_TEST, onSignLabTest);
  yield takeEvery(NVSL_RESULT_LAB_TEST, onNvslResultLabTest);
  yield takeEvery(SEND_BACK_LAB_TEST, onSendBackLabTest);
  yield takeLatest(SEARCH_LAB_TESTS, onSearchLabTests);
  yield takeLatest(SEARCH_LAB_CERTIFICATES, onSearchLabCertificates);
  yield takeLatest(LAB_SEND_TO_NVSL_REQUEST, onLabSendToNvslRequest);
  yield takeLatest(
    LAB_ATTACH_NVSL_REPORT_REQUEST,
    onLabAttachNvslReportRequest
  );
  yield takeLatest(
    LAB_REMOVE_NVSL_REPORT_REQUEST,
    onLabRemoveNvslReportRequest
  );
}
