import { put, call, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { message } from 'antd';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { Herd } from 'modules/Herd/herdIndex';
import * as actions from 'modules/Herd/store/herdActions';
import * as types from 'modules/Herd/store/herdActionTypes';

export function* onHerdListRequest(action) {
  try {
    yield put(actions.isRefreshing(true));
    const herds = yield call(Herd.list, action.params);
    yield put(actions.listSuccess(herds));
  } catch (err) {
    yield put(apiFailure(err, 'Error loading Herd data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveRequest({ herd, callback, showSuccessMessage }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([herd, herd.save]);
    if (callback) {
      callback(herd);
    }
    if (showSuccessMessage) {
      message.success(intl.get('savedWithNoun', { noun: intl.get('herd') }));
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('herd.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onDeleteRequest({ herdId, callback, showSuccessMessage }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(Herd.delete, herdId);
    if (callback) {
      callback(herdId);
    }
    if (showSuccessMessage) {
      message.success(intl.get('deletedThing', { thing: intl.get('herd.lc') }));
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.deleting.properNoun', {
          properNoun: intl.get('herd.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* herdSagas() {
  yield takeLatest(types.LIST_REQUEST, onHerdListRequest);
  yield takeLatest(types.SAVE_REQUEST, onSaveRequest);
  yield takeLatest(types.DELETE_REQUEST, onDeleteRequest);
}
