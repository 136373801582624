// This store holds data used by Regulatory accounts
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import {
  REGULATORY_LOAD_STARTUP_DATA_SUCCESS,
  REGULATORY_LOAD_STARTUP_DATA_STARTED,
  REGULATORY_LOAD_STARTUP_DATA_FINISHED,
  REGULATORY_LOAD_SETTINGS_DATA_STARTED,
  REGULATORY_LOAD_SETTINGS_DATA_SUCCESS,
  REGULATORY_LOAD_SETTINGS_DATA_FINISHED,
  REGULATORY_SETTINGS_DATA_SAVE_SUCCESS,
  //Rules
  REGULATORY_REFRESH_RULE_LIST_REQUEST,
  REGULATORY_REFRESH_RULE_LIST_SUCCESS,
  REGULATORY_REFRESH_RULE_LIST_FAILURE,
  //Certificates
  REGULATORY_SEARCH_CERTIFICATES_REQUEST,
  REGULATORY_SEARCH_CERTIFICATES_SUCCESS,
  REGULATORY_SEARCH_CERTIFICATES_FAILURE,
  //EECVI permits search
  REGULATORY_SEARCH_EECVI_PERMITS,
  REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS,
  REGULATORY_CLEAR_SEARCH_EECVI_PERMITS,
  REGULATORY_VIEW_EECVI_ITINERARY,
  REGULATORY_CLOSE_EECVI_ITINERARY,
  REGULATORY_FETCH_EECVI_SUCCESS,
  REGULATORY_FETCH_EECVI_FAILURE,
  // SmartEngine
  REGULATORY_VERIFY_CERTIFICATE_REQUEST,
  REGULATORY_VERIFY_CERTIFICATE_SUCCESS,
  REGULATORY_CLOSE_SMART_ENGINE_MODAL,
  REGULATORY_SEARCH_TESTS,
  REGULATORY_SEARCH_TESTS_SUCCESS,
  REGULATORY_CLEAR_TESTS_SEARCH,

  // Weekly certificate reports
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST,
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS,
  REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE
} from 'containers/accounts/regulatory/store/accountsRegulatoryConstants';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: {
    ruleTypes: null,
    lookUpData: null
  },
  settings: {
    eecvi: {
      permitWarning: null,
      restrictedStates: [],
      restrictionMessage: null
    },
    getFailure: false,
    lastRefreshedTime: null
  },
  dataLists: {
    rule: {
      rules: [],
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    certificate: {
      certificates: null,
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      runSearchReport: false
    },
    eecvi: {
      permits: null,
      searching: false,
      viewItineraryForEecvi: null
    },
    tests: {
      entities: {},
      searching: false,
      searchComplete: false
    },
    report: {
      reports: [],
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    }
  },
  smartEngine: {
    certificateId: null,
    results: null
  }
});

export const onRegulatoryLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      ruleTypes: startupData.ruleTypes
    })
  });

export const onRegulatoryLoadStartupDataStarted = state =>
  state.merge({
    retrievingStartupData: true
  });

export const onRegulatoryLoadStartupDataFinished = state =>
  state.merge({
    retrievingStartupData: false
  });

export const onRegulatoryLoadSettingsDataSuccess = (state, payload) => {
  const { result } = payload;
  return state.merge({
    settings: {
      eecvi: {
        permitWarning: result.eecvi.permitWarning,
        restrictedStates: result.eecvi.restrictedStates,
        restrictionMessage: result.eecvi.restrictionMessage
      },
      lastRefreshedTime: new Date().getTime()
    }
  });
};

export const onRegulatoryLoadSettingsDataStarted = state =>
  state.merge({
    settings: {
      eecvi: {
        permitWarning: null,
        restrictedStates: [],
        restrictionMessage: null
      },
      lastRefreshedTime: null
    }
  });

export const onRegulatoryLoadSettingsDataFinished = state =>
  state.merge({
    settings: {
      eecvi: {
        permitWarning: null,
        restrictedStates: [],
        restrictionMessage: null
      },
      lastRefreshedTime: null
    }
  });

export const onRegulatorySettingsDataSaveSuccess = (state, payload) => {
  const { result } = payload;
  return state.merge({
    settings: {
      eecvi: {
        permitWarning: result.eecvi.permitWarning,
        restrictedStates: result.eecvi.restrictedStates,
        restrictionMessage: result.eecvi.restrictionMessage
      },
      lastRefreshedTime: null,
      getFailure: false
    }
  });
};

//Rules
export const onRegulatoryRefreshRuleListSuccess = (state, payload) =>
  state.setIn(['dataLists', 'rule'], {
    rules: payload.rules,
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });

export const onRegulatoryRefreshRuleListRequest = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      rule: {
        rules: state.dataLists.rule.rules,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onRegulatoryRefreshRuleListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      rule: {
        rules: initialState.dataLists.rule.rules, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

//Certificates
export const onRegulatorySearchCertificatesRequest = (state, payload) =>
  state.updateIn(['dataLists', 'certificate'], certInfo => ({
    ...certInfo,
    refreshing: true,
    lastRefreshedTime: null,
    refreshFailure: false,
    runSearchReport: payload.params.report
  }));

export const onRegulatorySearchCertificatesSuccess = (state, payload) => {
  const { certificates } = payload;
  return state.updateIn(['dataLists', 'certificate'], certInfo => ({
    ...certInfo,
    certificates: certificates,
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  }));
};

export const onRegulatorySearchCertificatesFailure = state =>
  state.setIn(['dataLists', 'certificate'], {
    ...initialState.dataLists.certificate,
    refreshFailure: true
  });

export const onRegulatorySearchEecviPermits = state =>
  state.setIn(['dataLists', 'eecvi', 'searching'], true);

export const onRegulatorySearchEecviPermitsSuccess = (state, payload) =>
  state.setIn(['dataLists', 'eecvi'], {
    permits: payload.permits,
    searching: false,
    viewItineraryForEecvi: null
  });
export const onRegulatoryClearSearchEecviPermits = state =>
  state.setIn(['dataLists', 'eecvi'], initialState.dataLists.eecvi);

export const onRegulatoryViewEecviItinerary = (state, payload) =>
  state.setIn(['dataLists', 'eecvi', 'viewItineraryForEecvi'], {
    id: payload.id,
    serialNumber: payload.serialNumber
  });

export const onRegulatoryCloseEecviItinerary = state =>
  state.setIn(['dataLists', 'eecvi', 'viewItineraryForEecvi'], null);

export const onRegulatoryFetchEecviSuccess = (state, payload) =>
  state.setIn(['dataLists', 'eecvi', 'viewItineraryForEecvi'], payload.eecvi);

export const onRegulatoryFetchEecviFailure = state =>
  state.setIn(['dataLists', 'eecvi', 'viewItineraryForEecvi'], null);

export const onRegulatoryVerifyCertificateRequest = (state, payload) =>
  state.setIn(['smartEngine', 'certificateId'], payload.certificateId);

export const onRegulatoryVerifyCertificateSuccess = (state, payload) =>
  state.setIn(['smartEngine', 'results'], payload.seResults);

export const onRegulatoryCloseSmartEngineModal = state =>
  state.set('smartEngine', initialState.smartEngine);

export const onRegulatorySearchTests = (state, payload) =>
  state.updateIn(['dataLists', 'tests'], tests =>
    tests.merge({
      searching: true
    })
  );

export const onregulatorySearchTestsSuccess = (state, payload) =>
  state.updateIn(['dataLists', 'tests'], tests => ({
    ...tests,
    entities: payload.tests.reduce(
      (entities, tests) => ({
        ...entities,
        [tests.id]: tests
      }),
      {}
    ),
    searching: false,
    searchComplete: true
  }));

export const onRegulatoryClearTestsSearch = state =>
  state.updateIn(['dataLists', 'tests'], tests =>
    tests.merge({
      searching: false,
      searchComplete: false
    })
  );

//Weekly certificate reports
export const onRegulatoryWeeklyCertificatesReportsSuccess = (state, payload) =>
  state.setIn(['dataLists', 'report'], {
    reports: payload.reports,
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });

export const onRegulatoryWeeklyCertificatesReportsRequest = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      report: {
        reports: state.dataLists.report.reports,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onRegulatoryWeeklyCertificatesReportsFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      report: {
        reports: initialState.dataLists.report.reports, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

const accountsRegulatoryReducer = createReducer(initialState, {
  [REGULATORY_LOAD_STARTUP_DATA_SUCCESS]: onRegulatoryLoadStartupDataSuccess,
  [REGULATORY_LOAD_STARTUP_DATA_STARTED]: onRegulatoryLoadStartupDataStarted,
  [REGULATORY_LOAD_STARTUP_DATA_FINISHED]: onRegulatoryLoadStartupDataFinished,

  [REGULATORY_LOAD_SETTINGS_DATA_STARTED]: onRegulatoryLoadSettingsDataStarted,
  [REGULATORY_LOAD_SETTINGS_DATA_SUCCESS]: onRegulatoryLoadSettingsDataSuccess,
  [REGULATORY_LOAD_SETTINGS_DATA_FINISHED]: onRegulatoryLoadSettingsDataFinished,

  //Rules
  [REGULATORY_REFRESH_RULE_LIST_REQUEST]: onRegulatoryRefreshRuleListRequest,
  [REGULATORY_REFRESH_RULE_LIST_SUCCESS]: onRegulatoryRefreshRuleListSuccess,
  [REGULATORY_REFRESH_RULE_LIST_FAILURE]: onRegulatoryRefreshRuleListFailure,

  [REGULATORY_SETTINGS_DATA_SAVE_SUCCESS]: onRegulatorySettingsDataSaveSuccess,

  //Certificates
  [REGULATORY_SEARCH_CERTIFICATES_REQUEST]: onRegulatorySearchCertificatesRequest,
  [REGULATORY_SEARCH_CERTIFICATES_SUCCESS]: onRegulatorySearchCertificatesSuccess,
  [REGULATORY_SEARCH_CERTIFICATES_FAILURE]: onRegulatorySearchCertificatesFailure,

  //EECVI permits search
  [REGULATORY_SEARCH_EECVI_PERMITS]: onRegulatorySearchEecviPermits,
  [REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS]: onRegulatorySearchEecviPermitsSuccess,
  [REGULATORY_CLEAR_SEARCH_EECVI_PERMITS]: onRegulatoryClearSearchEecviPermits,
  [REGULATORY_VIEW_EECVI_ITINERARY]: onRegulatoryViewEecviItinerary,
  [REGULATORY_CLOSE_EECVI_ITINERARY]: onRegulatoryCloseEecviItinerary,
  [REGULATORY_FETCH_EECVI_SUCCESS]: onRegulatoryFetchEecviSuccess,
  [REGULATORY_FETCH_EECVI_FAILURE]: onRegulatoryFetchEecviFailure,

  //SmartEngine
  [REGULATORY_VERIFY_CERTIFICATE_REQUEST]: onRegulatoryVerifyCertificateRequest,
  [REGULATORY_VERIFY_CERTIFICATE_SUCCESS]: onRegulatoryVerifyCertificateSuccess,
  [REGULATORY_CLOSE_SMART_ENGINE_MODAL]: onRegulatoryCloseSmartEngineModal,

  [REGULATORY_SEARCH_TESTS]: onRegulatorySearchTests,
  [REGULATORY_SEARCH_TESTS_SUCCESS]: onregulatorySearchTestsSuccess,
  [REGULATORY_CLEAR_TESTS_SEARCH]: onRegulatoryClearTestsSearch,

  //Weekly certificate reports
  [REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST]: onRegulatoryWeeklyCertificatesReportsRequest,
  [REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS]: onRegulatoryWeeklyCertificatesReportsSuccess,
  [REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE]: onRegulatoryWeeklyCertificatesReportsFailure
});
export default accountsRegulatoryReducer;
