import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styled from '@emotion/styled';
import { debounce, isEqual, cloneDeep } from 'lodash';
import { THEME } from 'style';
import TableSearchAndActions from 'components/GvlTable/TableSearchAndActions';
import gvlTablePropTypes from 'components/GvlTable/gvlTablePropTypes';
import TableActionsMenu from 'components/GvlTable/TableActionsMenu';
import { updateUserSettings } from 'containers/app/store/user/userActions';
import { commonOnCell } from 'style/commonEmotions';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';

/* istanbul ignore next */
const MainBackgroundColorDiv = styled('div')({
  backgroundColor: THEME.mainBackgroundColor()
});

const DraggableHandle = sortableHandle(props => (
  <span {...props} className="draggable-handle"></span>
));
const DraggableElementTH = sortableElement(props => (
  <th {...props}>
    {props.colIndex !== undefined ? <DraggableHandle /> : undefined}
    {props.children}
  </th>
));
const DraggableContainerTR = sortableContainer(props => <tr {...props} />);
var columnsSorterStore = {};

// TODO: How do we make it so we do not need to put !important everywhere?
const ResponsiveTable = styled(Table)(({ viewMode, rowSelection }) => ({
  whiteSpace: 'nowrap',
  backgroundColor: THEME.secondaryBackgroundColor(),
  // Nested DOM element styling
  '& tbody > tr': {
    backgroundColor: '#FFFFFF'
  },
  '& thead > tr > th:first-of-type, & tbody > tr > td:first-of-type': {
    textAlign: 'left',
    paddingLeft: viewMode === 'Mobile' ? 'unset' : '16px !important'
  },
  '& tbody > tr > td:last-child': {
    paddingRight: viewMode === 'Mobile' ? 'unset' : '16px !important'
  },
  '& thead > tr > th': {
    padding: viewMode === 'Mobile' ? 'unset' : '8px !important',
    backgroundColor: `${THEME.mainBackgroundColor()} !important`
  },
  '& thead > tr > th.gvl-mobile-search-col-header': {
    // If we have a checkbox column, then we don't want left padding, but if there is no checkbox column, then we do want left padding.
    padding: rowSelection ? '0 8px 0 0 !important' : '0 8px !important'
  },
  '& .ant-table': {
    borderBottom: `1px solid ${THEME.mainBorderColor()}`
  },
  '& .ant-table-fixed-left .ant-table-fixed, & .ant-table-fixed-right .ant-table-fixed': {
    backgroundColor: `${THEME.mainBackgroundColor()} !important`
    // width: '100%'
  },
  '.ant-table-pagination.ant-pagination': {
    marginRight: '8px'
  },
  '& .ant-table-thead > tr > th .ant-table-header-column': {
    width: viewMode === 'Mobile' ? '100%' : 'unset'
  }
}));

class GvlTableComponent extends Component {
  static propTypes = {
    ...gvlTablePropTypes,
    viewMode: PropTypes.string.isRequired,
    userSettings: PropTypes.object,
    setDefaultTablePageSize: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    tableNameForColumnSettings: PropTypes.string,
    defaultPageSize: PropTypes.number.isRequired
  };

  static defaultProps = {
    shouldResetSelectedRowsOnDataChange: () => true
  };
  constructor(props) {
    super(props);
    this.searchDebounced = debounce(this.search, 500);
  }

  state = {
    areColumnsMovable: false,
    searchText: '',
    tableData: this.props.data,
    tableColumns: this.props.columns,
    selectedRows: [],
    selectedRowKeys: []
  };

  onColumnDrop = ({ oldIndex, newIndex }) => {
    var reorderedColumns = Array.from(this.state.tableColumns);
    if (oldIndex !== newIndex) {
      var draggedColumn = reorderedColumns.splice(oldIndex, 1)[0];
      reorderedColumns.splice(newIndex, 0, draggedColumn);
    }
    //TODO show column moving along with header;
    this.setState({ tableColumns: reorderedColumns });
  };

  onColumnDragStart = ({ node }) => {
    node.style.visible = 'hidden';
    node.style.opacity = 0;
  };

  onColumnsEditBtnToggle = async () => {
    const { areColumnsMovable, tableColumns } = this.state;
    if (areColumnsMovable) {
      this.props.updateUserSettings({
        columnsOrder: {
          ...this.props.columnsOrder,
          [this.props.tableNameForColumnSettings]: tableColumns.map(
            col => col.title
          )
        }
      });
    }
    this.setState({ areColumnsMovable: !areColumnsMovable });
  };

  componentDidMount() {
    this.setColumns();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      // Received new data, so use it and remove any selections
      const tableUpdate = { tableData: this.props.data };
      if (
        this.props.shouldResetSelectedRowsOnDataChange(
          this.props.data,
          prevProps.data
        )
      ) {
        tableUpdate.selectedRows = [];
        tableUpdate.selectedRowKeys = [];
      }
      this.setState(tableUpdate);

      // Reapply search text if present
      if (this.state.searchText) {
        this.onSearchTextChanged({ target: { value: this.state.searchText } });
      }
    }

    if (!isEqual(prevProps.columns, this.props.columns)) {
      this.setColumns();
    }
  }

  setColumns = () => {
    if (this.props.columnsOrder) {
      const currentTableOrder = this.props.columnsOrder[
        this.props.tableNameForColumnSettings
      ];
      if (currentTableOrder) {
        const { tableColumns } = this.state;
        var reorderedColumns = Array.from(tableColumns);
        // TODO: Since these settings are saved with column.title, we should add safeguards to ensure that any alterations to column titles shouldn't break App
        if (
          currentTableOrder &&
          currentTableOrder.length === tableColumns.length
        ) {
          currentTableOrder.forEach((title, index) => {
            if (title !== tableColumns[index].title) {
              reorderedColumns[index] = tableColumns.find(
                col => col.title === title
              );
            }
          });
          this.setState({
            tableColumns: reorderedColumns
          });
        }
      }
    } else {
      this.setState({ tableColumns: this.props.columns });
    }
  };

  onSearchTextChanged = event => {
    const value = event.target.value;
    this.setState({
      searchText: value
    });

    this.searchDebounced(value);
  };

  search = value => {
    let searchKey = value.toLowerCase();

    if (!searchKey) {
      // Search text was cleared, so show all data
      this.setState({ tableData: this.props.data });
      return;
    }

    let matchingRecords = [];

    this.props.data.forEach(record => {
      // Using 'some' so it will stop after first matching column
      this.props.columns.some(column => {
        if (!column.generateSearchText) {
          return false; // Continue search on next column
        }

        const searchText = column.generateSearchText(record) || '';
        if (
          searchText
            .toString()
            .toLowerCase()
            .indexOf(searchKey) !== -1
        ) {
          matchingRecords.push(record);
          return true; // This ends the search within this record
        } else {
          return false; // Continue search on next column
        }
      });
    });
    this.setState({ tableData: matchingRecords });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // Recalculate state.selectedRows based on the keys - antd only returns _visible_ rows in `selectedRows`
    let selectedRowItems = selectedRowKeys.map(id =>
      this.props.data.find(it => it.id === id)
    );
    this.setState({ selectedRows: selectedRowItems, selectedRowKeys });
  };

  renderActionDropdown = (actionInfo, record) => (
    <TableActionsMenu actionInfo={actionInfo} onClickData={[record]} />
  );

  render() {
    const {
      additionalSearchComponent,
      data,
      columns,
      actionInfo,
      keyField,
      dynamicActions,
      noRowActions,
      searchTextPlaceholder,
      showHeader,
      pagination,
      hideSearch,
      isRowSelectionDisabled,
      viewMode,
      defaultPageSize,
      rowClassName,
      hasAdZone,
      tableNameForColumnSettings,
      ...rest
    } = this.props;
    const { areColumnsMovable } = this.state;
    let columnsForTable = cloneDeep([...this.state.tableColumns]); // Make a copy so we can add actions column if needed
    let showActionsColumn = actionInfo && !dynamicActions && !noRowActions;

    // TODO: We should probably move this to componentDidMount since the columns dont change on render... Moving it to componentDidMount would also make it possible to change the position of this column as well.
    if (showActionsColumn) {
      // When there are actions, every row gets an "Actions menu" column
      columnsForTable.push({
        title: actionInfo.actionsTitle,
        key: 'action',
        width: 100,
        render: (_text, record) => this.renderActionDropdown(actionInfo, record)
      });
    }

    const search = hideSearch ? null : (
      <TableSearchAndActions
        searchText={this.state.searchText}
        onSearchChange={this.onSearchTextChanged}
        actionInfo={actionInfo}
        selectedRows={this.state.selectedRows}
        placeholderText={searchTextPlaceholder}
        additionalSearchComponent={additionalSearchComponent}
        editColumnProps={
          tableNameForColumnSettings
            ? {
                areColumnsMovable,
                onColumnsEditBtnToggle: this.onColumnsEditBtnToggle
              }
            : {}
        }
      />
    );

    if (viewMode === 'Mobile') {
      // Align the 'more' button to the right
      if ((actionInfo || dynamicActions) && !noRowActions) {
        columnsForTable[columnsForTable.length - 1].align = 'right';
      }
      for (
        let columnIndex = 0;
        columnIndex < columnsForTable.length;
        columnIndex++
      ) {
        columnsForTable[columnIndex].colSpan = columnIndex
          ? 0
          : columnsForTable.length + 1;
        columnsForTable[columnIndex].className = 'gvl-mobile-search-col-header';
        columnsForTable[columnIndex].sorter = null;
      }
      columnsForTable[0].title = search;
    } else {
      columnsForTable.forEach((tableColumn, i) => {
        if (
          i !== columnsForTable.length - 1 &&
          !Object.prototype.hasOwnProperty.call(tableColumn, 'width')
        )
          tableColumn.width = 200;
        if (rowClassName !== 'editable-row') {
          tableColumn.onCell = commonOnCell;
        }

        if (areColumnsMovable) {
          if (
            (showActionsColumn && i !== columnsForTable.length - 1) ||
            !showActionsColumn
          ) {
            tableColumn.onHeaderCell = () => ({
              colIndex: i
            });
            if (tableColumn.sorter !== undefined) {
              columnsSorterStore[tableColumn.title] = tableColumn.sorter;
              tableColumn.sorter = undefined;
            }
          }
        } else {
          if (columnsSorterStore[tableColumn.title] !== undefined) {
            tableColumn.sorter = columnsSorterStore[tableColumn.title];
            columnsSorterStore[tableColumn.title] = undefined;
          }
        }
      });
      // Make first and last columns sticky so inner columns can scroll horizontally within them
      // NOTE: Need to have some value for width on fixed columns, otherwise leaves white space
      // when container wider than needed width
      // We had this code to fix the table columns on the left and right end.
      // We might want to revisit it once we figure out the gutter issue when the vertical scroll is added.
      // if (!columnsForTable[0].hasOwnProperty('fixed')) {
      //   columnsForTable[0].width = 200;
      //   columnsForTable[0].fixed = 'left'
      //   columnsForTable[0].aligned = 'left';
      // }

      // if ((actionInfo || dynamicActions) && !noRowActions) {
      //   // We only need fixed actions column when table has actions
      // columnsForTable[columnsForTable.length - 1].fixed = 'right';
      if (
        columnsForTable[columnsForTable.length - 1].title.length > 12 ||
        !Object.prototype.hasOwnProperty.call(
          columnsForTable[columnsForTable.length - 1],
          'width'
        )
      ) {
        columnsForTable[columnsForTable.length - 1].width = 180;
      }
    }

    let tableHeightOffset = 346;
    if (hasAdZone) tableHeightOffset += 90;

    return (
      <MainBackgroundColorDiv>
        {viewMode === 'Mobile' ? null : search}
        <ResponsiveTable
          pagination={
            pagination
              ? pagination === 'false'
                ? false
                : pagination
              : {
                  // Default Pagination
                  showSizeChanger: true,
                  onShowSizeChange: (_current, pageSize) =>
                    this.props.setDefaultTablePageSize(pageSize),
                  size: 'large',
                  pageSizeOptions: ['10', '25', '50', '100'],
                  defaultCurrent: 1,
                  defaultPageSize: defaultPageSize
                }
          }
          size="small"
          showHeader={showHeader}
          // TECH_DEBT: setting scroll {x: 'max-content'} makes the scroll behave strangely.
          scroll={{
            x: 'max-content',
            y:
              viewMode === 'Mobile'
                ? ''
                : `calc(100vh - ` + tableHeightOffset + `px)`
          }}
          // scroll={{ x: 'max-content' }}
          rowKey={keyField}
          rowClassName={(record, index) => record.tableRowClass}
          dataSource={this.state.tableData}
          columns={columnsForTable}
          components={
            areColumnsMovable
              ? {
                  header: {
                    row: props => (
                      <DraggableContainerTR
                        {...props}
                        helperClass="col-dragging"
                        style={{ ...props.style, backgroundColor: 'gray' }}
                        axis="x"
                        useDragHandle={true}
                        onSortStart={this.onColumnDragStart}
                        onSortEnd={this.onColumnDrop}
                        hideSortableGhost={false}
                      />
                    ),
                    cell: props => (
                      <DraggableElementTH
                        {...props}
                        index={props['colIndex']}
                        disabled={props['colIndex'] === undefined}
                      />
                    )
                  }
                }
              : undefined
          }
          rowSelection={
            actionInfo && actionInfo.allowMultiSelect
              ? {
                  onChange: this.onSelectChange,
                  selectedRowKeys: this.state.selectedRowKeys,
                  getCheckboxProps: record =>
                    isRowSelectionDisabled && isRowSelectionDisabled(record)
                      ? { disabled: true, style: { display: 'none' } }
                      : {}
                }
              : null
          }
          viewMode={viewMode}
          footer={this.props.footer}
          {...rest}
        />
      </MainBackgroundColorDiv>
    );
  }
}

const mapStateToProps = state => ({
  viewMode: state.app.data.viewMode,
  columnsOrder: state.app.user.settings.columnsOrder,
  defaultPageSize: state.app.user.settings.defaultPageSize
});

const mapDispatchToProps = {
  updateUserSettings,
  setDefaultTablePageSize: pageSize =>
    updateUserSettings({ defaultPageSize: pageSize })
};

const GvlTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(GvlTableComponent);
export { GvlTable as default, GvlTableComponent };
