import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Radio, Form, message } from 'antd';

import { FadedText } from 'style/commonEmotions';
import { SignatureUploader } from 'elements/SignatureUploader';
import { SignatureFormItem } from 'elements/SignatureFormItem';
import {
  generateImgFromText,
  convertToBase64,
  loadSignatureFonts
} from 'containers/registration/RegistrationUtils';

const OnSignatureUploadChange = info => {
  const { status, name } = info.file;
  if (status === 'done') {
    message.success(intl.get('file.upload.success', { file: name }));
  } else if (status === 'error') {
    message.error(intl.get('file.upload.failure', { file: name }));
  }
};

const HandleSignatureSubmission = async (inputMode, signatureObject) => {
  let signature = '';

  if (inputMode === 'upload') {
    signature = await convertToBase64(signatureObject.file);
  } else if (inputMode === 'esign') {
    const text = signatureObject.text;
    const font = signatureObject.font;
    signature = generateImgFromText(text, font);
  }

  // the file reader methods we use return additional info, i.e. `data:image/png;base64,`
  return signature;
};

const SignatureInput = ({
  form,
  signatureInputMode = 'esign',
  setInputMode
}) => {
  useEffect(() => {
    loadSignatureFonts();
  }, []);

  return (
    <>
      <h4>{intl.get('signature')}</h4>
      <Radio.Group
        defaultValue={signatureInputMode}
        buttonStyle="solid"
        onChange={e => setInputMode(e.target.value)}
        style={{
          paddingBottom: '18px'
        }}
      >
        <Radio.Button data-testid="esignBtn" value="esign">
          {intl.get('create.esignature')}
        </Radio.Button>
        <Radio.Button data-testid="uploadBtn" value="upload">
          {intl.get('upload.image')}
        </Radio.Button>
      </Radio.Group>
      {signatureInputMode === 'upload' ? (
        <Form.Item label={intl.get('signature')}>
          {form.getFieldDecorator('signatureImg', {
            rules: [
              {
                required: true,
                message: intl.get('required')
              }
            ],
            preserve: false
          })(
            <SignatureUploader
              onUpload={info => {
                const validImageTypes = [
                  'image/png',
                  'image/jpeg',
                  'image/jpg'
                ];
                if (!validImageTypes.includes(info.file.type)) {
                  message.error(
                    intl.get('image.upload.invalid.signature.type')
                  );
                  return;
                }
                form.setFieldsValue({ signatureImg: info });
              }}
              onUploadChange={OnSignatureUploadChange}
              onRemoveUpload={() => form.setFieldsValue({ signatureImg: null })}
              signatureImg={form.getFieldValue('signatureImg')}
            />
          )}
        </Form.Item>
      ) : (
        <SignatureFormItem form={form} />
      )}
      <div style={{ fontSize: '12px' }}>
        <FadedText>{intl.get('signature.acknowledgement')}</FadedText>
      </div>
    </>
  );
};

SignatureInput.propTypes = {
  form: PropTypes.object.isRequired,
  signatureInputMode: PropTypes.string,
  setInputMode: PropTypes.func.isRequired
};

export { SignatureInput, HandleSignatureSubmission };
