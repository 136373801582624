import intl from 'react-intl-universal';

// Factory to create an AntD FieldDecorator rules validator function that uses our Model validation pattern
export const validationWrapper = (
  fieldName,
  model,
  form,
  additionalValidator,
  subfieldName
) => {
  return (_rule, _value, cb) => {
    const formValues = form.getFieldsValue();
    let result = model.validateField(
      fieldName,
      formValues[fieldName],
      formValues,
      subfieldName
    );
    if (!result && additionalValidator) {
      let error = additionalValidator(formValues[fieldName], formValues);
      if (error) {
        result = model.formatError(fieldName, error);
      }
    }
    if (result) {
      // TODO: Only need this when using sub-model validation, but probably shouldn't do things this way.
      while (Array.isArray(result.error)) {
        result = result.error[0];
      }
      if (result.error && typeof result.error === 'string') {
        cb(result.error);
      } else {
        cb(translateValidationError((result || {}).error));
      }
    } else {
      cb();
    }
  };
};

// TODO: combine with validationWrapper -- mostly similar
export const subFormValidationWrapper = (
  subObject,
  model,
  form,
  additionalValidator,
  subfieldName
) => {
  return (_rule, _value, cb) => {
    const formValues = form.getFieldsValue();
    let result = model.validateField(
      subfieldName,
      formValues[subObject][subfieldName],
      formValues[subObject],
      null
    );
    if (!result && additionalValidator) {
      let error = additionalValidator(formValues[subObject], formValues);
      if (error) {
        result = model.formatError(subfieldName, error);
      }
    }
    cb(translateValidationError((result || {}).error));
  };
};

export const translateValidationError = error => {
  if (!error) return;
  return intl.get(error.messageId, getTranslatedValues(error.values));
};

const getTranslatedValues = errorValues => {
  let translatedValues = {};
  for (let [key, value] of Object.entries(errorValues || {})) {
    // If there is no value, use 'undefined'
    // If there is a value, but it is not a valid translation key (possibly a number), use the original value
    // If it is a translation key, use the translation for it
    translatedValues[key] = value ? intl.get(value).d(value) : 'undefined';
  }
  return translatedValues;
};

// Factory to create an AntD FieldDecorator rules validator function that uses our Model validation pattern
export const getModelFieldDecorator = (
  form,
  fieldName,
  model,
  isRequired = false,
  additionalOptions = {},
  additionalValidator = () => null
) => {
  const isNestedFieldName = fieldName.indexOf('.') !== -1;
  let whichValidator = additionalOptions.useSubFormValidator
    ? subFormValidationWrapper
    : validationWrapper;
  return form.getFieldDecorator(fieldName, {
    preserve: true,
    initialValue: isNestedFieldName
      ? undefined
      : model.fields[fieldName].initialValue,
    rules: [
      {
        required: isRequired,
        validator: whichValidator(
          fieldName.split('.', 1)[0],
          model,
          form,
          additionalValidator,
          fieldName.split('.', 2)[1]
        )
      }
    ],
    ...additionalOptions
  });
};

// Directly calling validateFields within an onChange callback will not work properly since the form data hasn't been updated yet.
// This helper makes the validation async so it will happen after the form data is updated.
export const revalidateFieldsOnChange = (form, fieldNames) => () =>
  Promise.resolve().then(() =>
    form.validateFields(fieldNames, { force: true }, () => {
      // this callback prevents validation errors from showing on console.
    })
  );
