import Model from 'models/Model';
import {
  dateIsAfter,
  stringRequired,
  maxLength,
  isDate,
  isInteger,
  numberInRange
} from 'utils/validation/common';
import * as api from 'utils/api';

export default class Animal extends Model {
  //Instance field
  recentCerts;

  // Instance Methods
  async save() {
    this.tests = undefined;
    this.vacs = undefined;
    this.treatments = undefined;
    return await super.save();
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      age: {
        initialValue: '',
        validate: (age, instance) => {
          if (!age && instance && instance.dob) {
            return null; // Requires age or dob
          }

          // Always validate age if it is present
          return stringRequired(age, 'required') || maxLength(age, 30);
        }
      },
      brandDescription: {
        initialValue: '',
        validate: brandDescription =>
          !brandDescription ? null : maxLength(brandDescription, 2040)
      },
      brandInspectionNumber: {
        initialValue: '',
        validate: brandInspectionNumber =>
          !brandInspectionNumber ? null : maxLength(brandInspectionNumber, 255)
      },
      breed: {
        initialValue: '',
        validate: breed =>
          stringRequired(breed, 'required') || maxLength(breed, 100)
      },
      color: {
        initialValue: '',
        validate: (color, instance) => {
          const { isGroup, species } = instance;
          if (instance && !color) {
            if (!isGroup || ['Feline', 'Equine', 'Canine'].includes(species)) {
              return { messageId: 'required' };
            }
          }
          return null;
        }
      },
      dob: {
        initialValue: null,
        validate: (dob, instance) => {
          if (!dob && instance && instance.age) {
            return null; // Requires age or dob
          }

          // Always validate dob if it is present
          return (
            isDate(dob, 'MM/DD/YYYY', 'required') ||
            dateIsAfter(dob, new Date())
          );
        }
      },
      dobAccuracy: {
        initialValue: '',
        validate: dobAccuracy =>
          !dobAccuracy
            ? null
            : ['hours', 'days', 'weeks', 'months', 'years'].includes(
                dobAccuracy
              )
            ? null
            : { messageId: 'validation.animal.dob.accuracy.invalid' }
      },
      dod: {
        //date of death
        initialValue: null
      },
      front_id: {
        initialValue: ''
      },
      gender: {
        initialValue: '',
        validate: gender =>
          stringRequired(gender, 'required') || maxLength(gender, 100)
      },
      headCount: {
        initialValue: undefined,
        validate: (headCount, instance) =>
          headCount || instance.isGroup
            ? isInteger(parseInt(headCount), 'required') ||
              numberInRange(
                headCount,
                1,
                1000000,
                headCount > 1
                  ? 'validation.animal.headCount.maxRange'
                  : 'validation.animal.headCount.minRange'
              )
            : null
      },
      ids: {
        initialValue: ''
        // TODO: Implement ID validation?  This might be delegated to the ID component, so we may not need validation here
      },
      idTypes: {
        initialValue: '',
        // TODO: Implement more ID validation?  This might be delegated to the ID component, so we may not need validation here
        validate: idTypes => (!idTypes ? '' : maxLength(idTypes, 120))
      },
      idDates: {
        initialValue: []
        // TODO: Implement more ID validation?  This might be delegated to the ID component, so we may not need validation here
      },
      isGroup: {
        initialValue: false
      },
      label: {
        initialValue: ''
      },
      lastUpdated: {
        initialValue: undefined
      },
      left_id: {
        initialValue: ''
      },
      markings: {
        initialValue: {},
        validate: (markings, instance, _subfieldName, country) => {
          // Equine with headCount of 1 requires at least 1 marking
          if (
            !instance ||
            instance.species !== 'Equine' ||
            instance.headCount !== 1
          ) {
            return null;
          }
          try {
            if (country === 'Canada') {
              // Canadian EIAs don't have neck and body
              const canadianMarkings = Object.keys(markings).filter(
                marking => marking !== 'neckAndBodyMarkings'
              );
              let hasAllMarkings = canadianMarkings.every(key => {
                return markings[key] && markings[key].length > 0;
              });
              if (!hasAllMarkings || Object.keys(markings).length < 6) {
                return { messageId: 'validation.animal.markingAll.required' };
              }
            } else {
              let hasOneMarking = Object.keys(markings).some(key => {
                return markings[key] && markings[key].length > 0;
              });
              if (!hasOneMarking) {
                return { messageId: 'validation.animal.markingAny.required' };
              }
            }
            return null;
          } catch (err) {
            return { messageId: 'validation.animal.markings.parse.error' };
          }
        }
      },
      name: {
        initialValue: '',
        validate: name =>
          stringRequired(name, 'required') || maxLength(name, 100)
      },
      origin_id: {
        initialValue: null
      },
      otherImages: {
        initialValue: []
      },
      remarks: {
        initialValue: undefined,
        validate: remarks => (!remarks ? null : maxLength(remarks, 1020))
      },
      right_id: {
        initialValue: ''
      },
      species: {
        initialValue: '',
        validate: species =>
          stringRequired(species, 'required') || maxLength(species, 50)
      },
      tests: {
        initialValue: []
      },
      treatments: {
        initialValue: []
      },
      vacs: {
        initialValue: []
      },
      version: {
        initialValue: undefined
      }
    };
  }

  // TODO: this wouldn't be needed after GMA-55
  // IF!!! we moved to using new regular animal API to get partials.
  // old partialAnimal API still returns objects with divergent markings structure
  static fromPartial = partialAnimal => {
    let a = new Animal({ ...partialAnimal });
    if (!a.markings) {
      a.markings = {
        headMarkings: partialAnimal.markingsHead,
        leftForeMarkings: partialAnimal.markingsLeftFore,
        leftHindMarkings: partialAnimal.markingsLeftHind,
        neckAndBodyMarkings: partialAnimal.markingsNeckAndBody,
        otherMarkings: partialAnimal.markingsOther,
        rightForeMarkings: partialAnimal.markingsRightFore,
        rightHindMarkings: partialAnimal.markingsRightHind
      };
    }
    return a;
  };

  static list = async params => await super.list(params);

  static get apiPath() {
    return 'animal';
  }

  static get domain() {
    return 'Animal';
  }

  static parseSimpleIndividualIds(idsRawVal) {
    if (!idsRawVal) {
      return [['']];
    }
    return [idsRawVal.split(',').map(s => s.trim())];
  }

  static parseIds(idsRawVal) {
    // incoming data stuct expects list of lists. top level items correspond to individual animals
    // so if idTypes is something like
    // ['number','letter','custom']
    // then ids would look something like
    //[[1,'a','24c'],[2,'b','56b'],[3,'c','42c']]
    if (!idsRawVal) {
      return [['']];
    } else {
      let x;
      try {
        x = JSON.parse(idsRawVal);
        return x;
      } catch (e) {
        try {
          // each item in the csv list represents an animal
          x = [];
          const items = idsRawVal.split(','); // should trim?
          for (let ai of items) {
            x.push([ai]);
          }
          return x;
        } catch (er) {
          return [['']];
        }
      }
    }
  }

  static validateAnimalIds(parsedTypes, parsedIds) {
    let msg = '';
    for (let id of parsedIds) {
      if (id.length > 255) {
        msg += 'maximumIDlengthExceeded';
        break;
      }
    }
    // make sure ids and idTypes match
    if (
      (parsedIds != null ? parsedIds.length : undefined) !==
      (parsedTypes != null ? parsedTypes.length : undefined)
    ) {
      msg += 'IDtypesAndIDsCountMismatch';
    } else {
      // Validate ids for some ID types
      for (let col = 0; col < parsedTypes?.length; col++) {
        const type = parsedTypes[col];
        let parsedId = parsedIds?.[col];
        if (typeof parsedId === 'string') {
          // Ignore all whitespace
          parsedId = parsedId.replace(/\s/g, '');
        }
        if (type.indexOf('840') > -1) {
          // Validate 840 type ids
          if (!this.pattern840.test(parsedId)) {
            msg += 'invalid840tagAnimal'; // (type)
          }
        } else if (type.indexOf('NUES') > -1) {
          // Validate NUES type ids
          if (!this.patternNUES.test(parsedId)) {
            msg += 'invalidNUEStagAnimal'; // (type)
          }
        } else if (type.indexOf('American ID') > -1) {
          // Validate American ID type ids
          if (!this.patternAmericanID.test(parsedId)) {
            msg += 'invalidAmericanIDtagAnimal'; // (type)
          }
        }
      }
    }
    return msg;
  }

  static validateGroupIds(parsedTypes, idsArray) {
    let col, id, parsedId, row, type;
    let msg = '';
    // Validate 840 type ids
    let msg840 = '';
    for (col = 0; col < parsedTypes.length; col++) {
      type = parsedTypes[col];
      if (type.indexOf('840') > -1) {
        for (row = 0; row < idsArray.length; row++) {
          id = idsArray[row];
          parsedId = id[col].toString();
          if (!this.pattern840.test(parsedId.replace(/\s/g, ''))) {
            if (msg840) {
              msg840 += ', ';
            }
            msg840 += '' + (row + 1);
          }
        }
      }
    }
    if (msg840) {
      msg += 'invalid840tagGroup'; // (msg840)
    }
    // Validate NUES type ids
    let msgNUES = '';
    for (col = 0; col < parsedTypes.length; col++) {
      type = parsedTypes[col];
      if (type.indexOf('NUES') > -1) {
        for (row = 0; row < idsArray.length; row++) {
          id = idsArray[row];
          parsedId = id[col];
          if (typeof parsedId === 'string') {
            // Ignore all whitespace
            parsedId = parsedId.replace(/\s/g, '');
          }
          if (!this.patternNUES.test(parsedId)) {
            if (msgNUES) {
              msgNUES += ', ';
            }
            msgNUES += '' + (row + 1);
          }
        }
      }
    }
    if (msgNUES) {
      msg += 'invalidNUEStagGroup'; // (msgNUES)
    }
    // Validate American ID type ids
    let msgAmericanID = '';
    for (col = 0; col < parsedTypes.length; col++) {
      type = parsedTypes[col];
      if (type.indexOf('American ID') > -1) {
        for (row = 0; row < idsArray.length; row++) {
          id = idsArray[row];
          parsedId = id[col];
          if (typeof parsedId === 'string') {
            // Ignore all whitespace
            parsedId = parsedId.replace(/\s/g, '');
          }
          if (!this.patternAmericanID.test(parsedId)) {
            if (msgAmericanID) {
              msgAmericanID += ', ';
            }
            msgAmericanID += '' + (row + 1);
          }
        }
      }
    }
    if (msgAmericanID) {
      msg += 'invalidAmericanIDtagGroup'; // (msgAmericanID)
    }
    return msg;
  }

  // Animal Tag Ids validation patterns
  static pattern840 = /^840([ -]?\d{3}){4}$/;
  static patternNUES = /^([0-9]{2}|AL|AK|AS|AZ|AR|CA|CO|MP|CT|DE|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NN|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|US|UT|VT|VI|VA|WA|WV|WI|WY)[A-Za-z]{2,3}[0-9]{4}$/;
  static patternAmericanID = /^(USA)([0-9]{8,9}|[0-9]{12})$/;

  async claimOwnership(ownerId) {
    const data = {
      owner_id: ownerId,
      version: this.version
    };
    const result = await api.post(`/animal/claimOwnership/${this.id}`, data);
    // Update the instance with the record that is returned so we can get things like origin_id, version, etc.
    this.constructor.initialize(this, result);
  }

  async releaseOwnership() {
    const data = {
      version: this.version,
      owner_id: this.origin_id
    };
    const result = await api.deleteRecWithData(
      `/animal/releaseOwnership/${this.id}`,
      data
    );
    // Update the instance with the record that is returned so we can get things like origin_id, version, etc.
    this.constructor.initialize(this, result);
  }

  static async getMvlOwnerAnimals() {
    return await api.get(`${Animal.apiPath}/listMVLAnimals`, {
      _cacheBuster: new Date().getTime()
    });
  }

  static getHorseSyncAnimals = async () => {
    // could potentially use 'animal?partials=only' instead - haven't had time to test it.
    return await api.get('partialAnimal?asAnimal=true');
  };

  static async getAgentsForAnimal(animalId) {
    return await api.get(`${Animal.apiPath}/agentsForAnimal/${animalId}`);
  }

  static async grantAgentAccess(animalId, agentId) {
    await api.get(`${Animal.apiPath}/grantAgentAccess/${animalId}`, {
      params: { agentId }
    });
  }

  static async revokeAgentAccess(animalId, agentId) {
    await api.get(`${Animal.apiPath}/revokeAgentAccess/${animalId}`, {
      params: { agentId }
    });
  }

  static async deleteRec(id, isPartial) {
    if (isPartial) {
      await api.deleteRec(`partialAnimal/${id}`);
    } else {
      await super.deleteRec(id);
    }
  }

  // fullAnimalId is unnecessary after GMA-55. id will not be different or change
  static async transitionToFullAnimal(animalId) {
    await api.post(`partialAnimal/transitionToFullAnimal/${animalId}`);
  }

  static read = async id => {
    return super.read(id);
  };

  //Enable caching by removing the flag forceUpdate
  getRecentCerts = async (forceUpdate = true) => {
    if (!this.recentCerts || forceUpdate) {
      const result = await api.get(`/animal/${this.id}`, {
        params: { verbose: 'recentCerts' }
      });
      this.recentCerts = result.recentCerts;
    }
    return this.recentCerts;
  };
}
