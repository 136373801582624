import Model from 'models/Model';
import { stringRequired } from 'utils/validation/common';

//TODO: Flesh this out
export default class SysFeatureSet extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();
    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        initialValue: undefined
      },
      name: {
        initialValue: undefined,
        validate: name => stringRequired(name, 'required')
      },
      solutionClass: {
        initialValue: undefined
      },
      solutionLevel: {
        initialValue: undefined
      },
      arProductId: {
        initialValue: undefined
      },
      crmProductId: {
        initalValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'sysFeatureSet';
  }

  static get domain() {
    return 'SysFeatureSet';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };
}
