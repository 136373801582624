import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { List, Select, Spin } from 'antd';
import { concat } from 'lodash';
import { BASENAME } from 'utils/config';
import { InlineButtonLink } from 'style/commonEmotions';
import Search from 'components/GvlSearch/GvlSearch';
import { activateIsolate } from 'modules/Isolate/store/isolateActions';

class IsolateMultiSelectComponent extends Component {
  static propTypes = {
    activateIsolate: PropTypes.func.isRequired,
    refreshingIsolates: PropTypes.bool.isRequired,
    isolates: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    value: PropTypes.array,
    fieldName: PropTypes.string.isRequired
  };

  isolateOptionGeneration = results => {
    return results.map(result => (
      <Select.Option key={result.isolateId} value={result.isolateId}>
        <span style={{ marginRight: 12 }}>{result.isolateNbr}</span>
        {`${result.name} - ${result.clinicName}`}
      </Select.Option>
    ));
  };

  // Don't show isolates if already selected
  isolateFilterFunction = () => (searchTerm, isolate) => {
    const selectedIds = this.props.value || [];
    return !selectedIds.some(isolateId => isolateId === isolate.isolateId);
  };

  onSelectIsolate = isolateId => {
    const { form, fieldName } = this.props;
    // If the isolate isn't in the store, add it so we have access to it when saving the product
    this.props.activateIsolate(isolateId);
    // Duplicate protection. Search list doesn't filter out selected isolates until search list changes
    const selectedIds = form.getFieldValue(fieldName) || [];
    if (!selectedIds.includes(isolateId)) {
      form.setFieldsValue({ [fieldName]: concat(selectedIds, isolateId) });
    }
  };

  onRemoveIsolate = isolate => {
    const { form, fieldName } = this.props;
    let isolateIds = form.getFieldValue(fieldName);
    isolateIds = isolateIds.filter(
      isolateId => isolateId !== isolate.isolateId
    );
    form.setFieldsValue({ [fieldName]: isolateIds });
  };

  render() {
    const { refreshingIsolates, value, isolates } = this.props;
    let selectedIsolates = [];
    if (!refreshingIsolates && value) {
      value.forEach(isolateId => selectedIsolates.push(isolates[isolateId]));
    }
    return (
      <React.Fragment>
        <Search
          childGenerator={this.isolateOptionGeneration}
          minSearchTermLength={3}
          optionLabelProp="text"
          dataSource="autogenousVaccines/isolate/autocomplete"
          dropdownClassName="gvl-select-with-custom-groups"
          onSelect={this.onSelectIsolate}
          filterFunction={this.isolateFilterFunction()}
          emptyMsg="isolate.missingWithNumber"
          emptySubMsg="isolate.missing.subText"
        />
        <Spin spinning={refreshingIsolates} size="large">
          {value && value.length ? (
            <List
              size="large"
              dataSource={selectedIsolates}
              renderItem={isolate => (
                <List.Item
                  actions={[
                    <InlineButtonLink
                      type="link"
                      onClick={() => this.onRemoveIsolate(isolate)}
                      key="list-delete"
                    >
                      {intl.get('remove')}
                    </InlineButtonLink>
                  ]}
                >
                  <div>
                    {/* Open a new tab if isolate in the list is clicked */}
                    <a
                      href={`${BASENAME}/isolates/${isolate.isolateId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: 12 }}
                    >
                      {isolate.isolateNbr}
                    </a>
                    {`${isolate.name} - ${isolate.clinicOwner.name}`}
                  </div>
                </List.Item>
              )}
            />
          ) : null}
        </Spin>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isolates: state.accounts.isolates.data,
  refreshingIsolates: state.accounts.isolates.isRefreshing
});

const mapDispatchToProps = {
  activateIsolate
};

const IsolateMultiSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(IsolateMultiSelectComponent);

export { IsolateMultiSelect as default, IsolateMultiSelectComponent };
