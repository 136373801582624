import moment from 'moment';
import Document from 'models/documents/Document';
import { downloadFile } from 'utils/downloadUtil';
import Location from 'models/Location';
import Animal from 'models/Animal';
import Contact from 'models/Contact';
import * as api from 'utils/api';
import {
  isNotPoBox,
  dateIsBefore,
  dateIsPastDate,
  // dateIsFutureDate,
  objectRequired,
  isDate,
  isNumber,
  numberInRange,
  stringRequired,
  maxLength,
  dateIsAfter
} from 'utils/validation/common';

export default class Ihc extends Document {
  // Instance Methods
  validate() {
    const results = super.validate();

    // TODO: Put complex validations here.  If none, then delete this whole validate() method.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods
  static get fields() {
    return {
      ...super.fields,
      validationLevel: {
        // This property will not be persisted and is only used to provide varying levels of validation feedback eg minimum required to save draft vs ready-to-commit
        initialValue: 'save'
      },
      rules: [],
      id: {
        initialValue: undefined
      },
      animal: {
        initialValue: {},
        validate: (val, instance) => {
          // (optional) API: Must be a valid Animal, could just send id or no id
          let err = val ? new Animal(val).validate() : null;
          // if (!err) {
          // run extra validation for TTVs?
          // for (let rule in instance.rules) {
          //   todo: check the rule
          // }
          // }
          return err;
        }
      },
      owner: {
        initialValue: {},
        validate: val =>
          objectRequired('owner', val) || new Contact(val).validate()
      },
      ownerPremises: {
        initialValue: {},
        validate: (val, _instance) =>
          // (optional)
          // Must be a valid Premises object, including state and addressLine1
          // cannot be a PO Box
          val
            ? new Location(val).validate() || isNotPoBox(val.addressLine1)
            : null
      },
      origin: {
        initialValue: {},
        validate: val =>
          objectRequired('origin', val) || new Contact(val).validate()
      },
      originPremises: {
        initialValue: {},
        validate: (val, _instance) =>
          // (optional)
          // Must be a valid Premises object, including state and addressLine1
          // cannot be a PO Box
          val
            ? new Location(val).validate() || isNotPoBox(val.addressLine1)
            : null
      },
      rabiesDoc: {
        initialValue: undefined
      },

      travelDate: {
        initialValue: undefined,
        validate: (val, instance) =>
          stringRequired(val, 'isRequired', {
            thing: 'departureDate'
          }) ||
          isDate(val, 'MM/DD/YYYY', 'isRequired', {
            thing: 'travel.date'
          }) ||
          dateIsPastDate(val, 'date.notPast', { date: 'departureDate' }) ||
          dateIsAfter(
            val,
            instance.arrivalTimestamp,
            'ihc.validation.departureDateBeforearrivalTimestamp',
            { date: 'arrivalTimestamp' }
          )
      },

      arrivalTimestamp: {
        initialValue: undefined,
        validate: (val, instance) =>
          stringRequired(val, 'isRequired', {
            thing: 'ihc.arrival.date'
          }) ||
          isDate(val, 'MM/DD/YYYY', 'isRequired', {
            thing: 'ihc.arrival.date'
          }) ||
          dateIsBefore(
            val,
            instance.travelDate,
            'ihc.validation.arrivalTimestampAfterDepartureDate',
            { date: 'arrivalTimestamp' }
          )
      },

      inspectionDate: {
        initialValue: undefined,
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? // Note: # of days between inspection and travel validation should be handled by the rules engine
              stringRequired(val, 'isRequired', {
                thing: 'departureDate'
              }) ||
              isDate(val, 'MM/DD/YYYY', 'isRequired', {
                thing: 'examination.date'
              }) ||
              (instance.travelDate &&
                dateIsBefore(
                  instance.travelDate,
                  val,
                  'ihc.validation.examBeforeTravel'
                ))
            : null
      },
      weight: {
        initialValue: undefined,
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? stringRequired(val, 'isRequired', { thing: 'pet.weight' }) ||
              isNumber(val, 'validation.pet.weight.number') ||
              numberInRange(val, 1, 350, 'validation.pet.weight.range')
            : val === 0 || val
            ? numberInRange(val, 1, 350, 'validation.pet.weight.range')
            : null
      },
      weightUnits: {
        initialValue: 'lbs',
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? stringRequired(val, 'isRequired', { thing: 'pet.weight' })
            : null
      },
      microchipImplantLocation: {
        initialValue: undefined,
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? stringRequired(val, 'isRequired', {
                thing: 'pet.microchipImplantLocation'
              })
            : null
      },
      microchipType: {
        initialValue: 'IsoCompliant',
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? stringRequired(val, 'isRequired', {
                thing: 'microchip.type'
              })
            : null
      },
      carrierType: {
        initialValue: undefined,
        validate: val =>
          stringRequired(val, 'isRequired', {
            thing: 'ihc.methodOfTravel'
          })
      },
      tripDuration: {
        initialValue: undefined,
        validate: val =>
          stringRequired(val, 'isRequired', {
            thing: 'ihc.tripDuration'
          })
      },
      airline: {
        initialValue: undefined,
        validate: (val, instance) =>
          maxLength(val, 120) ||
          (['Air Cargo', 'Air Cabin'].includes(instance.carrierType) &&
            stringRequired(val, 'isRequired', { thing: 'airline' }))
      },
      portOfEntry: {
        initialValue: undefined,
        validate: val =>
          maxLength(val, 85) ||
          stringRequired(val, 'isRequired', {
            thing: 'ihc.portOfEntry'
          })
      },
      flightNumber: {
        initialValue: undefined,
        validate: (val, instance) =>
          maxLength(val, 12) ||
          (['Air Cargo', 'Air Cabin'].includes(instance.carrierType) &&
            stringRequired(val, 'isRequired', {
              thing: 'flight.number'
            }))
      },
      isDirectFlight: {
        initialValue: false
      },
      transitCountries: {
        initialValue: undefined,
        validate: (val, instance) =>
          !instance.isDirectFlight
            ? stringRequired(val, 'isRequired', {
                thing: 'ihc.validation.transit.countries'
              })
            : null
      },
      purposeOfMovement: {
        initialValue: 'Pleasure',
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? stringRequired(val, 'ihc.validation.purposeofmovement.required')
            : null
      },
      carrier: {
        initialValue: {},
        validate: val => objectRequired('carrier', val) // is it required, how to specify car travel to Canda or Mexico?
      },
      carrierPremises: {
        initialValue: undefined,
        validate: (val, instance) =>
          // Must be a valid Premises object, including state and addressLine1
          // cannot be a PO Box
          val
            ? new Location(val).validate() || isNotPoBox(val.addressLine1)
            : null
      },
      destinationCountry: {
        initialValue: undefined,
        validate: val => stringRequired(val, 'isRequired', { thing: 'country' })
      },
      destination: {
        initialValue: undefined,
        validate: (val, instance) =>
          instance.validationLevel === 'sign'
            ? objectRequired('destination', val) || new Contact(val).validate()
            : null
      },
      destinationPremises: {
        initialValue: undefined,
        validate: (val, instance) =>
          // (optional)
          // Must be a valid Premises object, including state and addressLine1
          // cannot be a PO Box
          val
            ? new Location(val).validate() || isNotPoBox(val.addressLine1)
            : null
      },
      species: {
        initialValue: 'Canine',
        validate: val => stringRequired(val, 'isRequired', { thing: 'species' })
      },
      usdaAddress: {
        initialValue: undefined
      },
      remarks: {
        initialValue: [] // Array of strings
      }
    };
  }

  static get apiPath() {
    return 'documents';
  }

  // TODO: could this be a class prop instead of a method?
  // static docType = 'IHC';
  static get docType() {
    return 'IHC';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    return super.read(id);
  };

  static getDrafts = async () => {
    return super.getDrafts(this.docType);
  };

  static getReadyToSign = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'READY_TO_SIGN',
      limit: 0
    });
    return data.results;
  };

  static useInkSignature = async (id, useInkSignature) => {
    return api.put(`${this.apiPath}/${id}?protectedAction=useInkSignature`, {
      useInkSignature: useInkSignature
    });
  };

  static getRecentlySigned = async () => {
    const data = await super.list({
      'metadata.status': 'SIGNED',
      signedSince: moment()
        .subtract(14, 'days')
        .toJSON(),
      'metadata.archived': false,
      type: this.docType,
      limit: 0
    });
    return data.results;
  };

  static downloadPdfPreview = async (
    id,
    shouldShowMessage = true,
    type,
    includeImages = false
  ) => {
    let queryString = type ? `?type=${type}` : '';
    if (includeImages) queryString += `&includeImages=${includeImages}`;
    downloadFile(
      `${this.apiPath}/${id}/pdfPreview${queryString}`,
      undefined,
      undefined,
      shouldShowMessage
    );
  };
}
