import { isEmail, stringRequired } from 'utils/validation/common';

// Validations specific to Documents

const validDocTypes = ['CVI', 'EHC', 'EIA', 'VFD', 'Rabies', 'IHC'];

export const validateDocType = type => {
  if (validDocTypes.includes(type)) {
    return null;
  }

  return {
    messageId: 'validation.document.type.invalid',
    values: { validDocTypes: validDocTypes }
  };
};

export const validateEecviOwner = owner => {
  let err = isEmail(owner.ownerEmail, 'validation.email.invalid');
  if (owner.mvlConnected !== true && !err) {
    err = {
      messageId: 'validation.eecvi.owner.must.be.mvl.connected'
    };
  }
  return err;
};

export const validateEecviQualifyingEia = qualifyingEia => {
  if (
    qualifyingEia.accession &&
    qualifyingEia.lab &&
    qualifyingEia.bloodDrawn &&
    qualifyingEia.issued
  ) {
    return null;
  } else {
    return {
      messageId: 'validation.eecvi.qualifying.eia.incomplete',
      values: { qualifyingEIA: qualifyingEia }
    };
  }
};

export const officialIdType = (officialId, ehcIdTypesAllowed) => {
  if (ehcIdTypesAllowed.includes(officialId.type)) {
    return null;
  } else {
    return {
      messageId: 'validation.eecvi.official.id.type.invalid'
    };
  }
};

export const validateOfficialIdValue = officialId => {
  switch (officialId.type) {
    case 'eiaAccessionNumber':
      return null;
    case 'photoEia':
      return stringRequired(officialId.value, 'required');
    case 'microchip':
      return stringRequired(officialId.value, 'required');
    case 'lifetimeBrandInspection':
      if (
        !(
          officialId.value.number &&
          officialId.value.date &&
          officialId.value.state
        )
      ) {
        return {
          messageId: 'validation.eecvi.official.id.lbi.incomplete',
          values: { officialId: officialId }
        };
      }
      return null;
    default:
      return null;
  }
};
