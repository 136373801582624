import { Button, Typography } from 'antd';
import styled from '@emotion/styled';
import { FONT, THEME } from 'style';

const { Text } = Typography;

export const StyledButton = styled(Button)({
  fontWeight: FONT.weight.semiBold,
  fontFamily: FONT.family.header,
  fontSize: FONT.size.medium,
  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.05);'
});

export const StyledOptionsHeader = styled(Text)({
  fontWeight: FONT.weight.regular,
  fontFamily: FONT.family.regular,
  fontSize: FONT.size.medium
});

export const StyledOptionsSubText = styled(Text)({
  fontWeight: FONT.weight.regular,
  fontFamily: FONT.family.regular,
  fontSize: FONT.size.small,
  color: THEME.disabledText(),
  display: 'block'
});
