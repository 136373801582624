// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Document from 'models/documents/Document';
import Contact from 'models/Contact';
// import Animal from 'models/Animal';
import {
  stringRequired,
  numberInRange,
  maxLength,
  isInteger,
  arrayRequired,
  isEmail
} from '../../utils/validation/common';

export default class Script extends Document {
  // Instance Methods

  validate() {
    const results = super.validate();

    // TODO: Put complex validations here.  If none, then delete this whole validate() method.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      // TODO: Put the model fields here.  Each should include an initialValue, and optional validate function
      // From neptr ajax create call:
      additionalEmails: {
        // Validate emails
        // "'#{email}' is an invalid email address for #{field}."
        initialValue: [],
        validate: value => {
          if (!value || !value.length) {
            return null;
          }
          let validationResult;
          for (let i = 0; i < value.length; i++) {
            validationResult = isEmail(
              value[i],
              'validation.script.distributor.invalidEmail'
            );
            if (validationResult) {
              return {
                messageId: 'validation.script.additionalEmails.invalidEmail',
                values: { address: value[i] }
              };
            }
          }
          return null;
        }
      },
      ageUnits: {
        initialValue: ''
      },
      animal: {
        initialValue: {}
        // TODO: Get the animal model and do this
        // validate: value => {
        //   return !value ? null : new Animal(value).validate()
        // }
      },
      avgAge: {
        initialValue: null
      },
      avgWeight: {
        initialValue: null
      },
      distributor: {
        // Validate email
        initialValue: {},
        validate: value => {
          return !value || !value.email
            ? null
            : isEmail(
                value.email,
                'validation.script.distributor.invalidEmail'
              );
        }
      },
      drugs: {
        initialValue: []
      },
      expirationDate: {
        // looks weird, but it's just adding 90 days to today's date, same as neptr
        // "Expiration Date is required to save."
        initialValue: new Date(new Date().setDate(new Date().getDate() + 90))
      },
      feedSites: {
        // this is an array of Contacts
        initialValue: [],
        validate: value => {
          let validationResult;
          validationResult = arrayRequired(value, 'feedSites');
          if (validationResult) {
            return validationResult;
          }
          if (value) {
            for (let i = 0; i < value.length; i++) {
              validationResult = new Contact(value[i]).validate();
              if (validationResult) {
                return {
                  messageId: 'validation.script.feedSites.invalidContact',
                  values: { reason: validationResult }
                };
              }
            }
          }
          return null;
        }
      },
      groupName: {
        initialValue: '',
        validate: value => {
          return !value ? null : maxLength(value, 120);
        }
      },
      headCount: {
        initialValue: null,
        validate: value => {
          // "Number to be Treated must be a number less than 1,000,000,001."
          return (
            isInteger(value, 'validation.script.headCount.invalid') ||
            numberInRange(
              value,
              0,
              1000000000,
              'validation.script.headCount.invalid'
            )
          );
        }
      },
      id: {
        initialValue: null
      },
      owner: {
        // "An owner AND species are required to save."
        initialValue: {},
        validate: value => {
          return new Contact(value).validate();
        }
      },
      ownerPremises: {
        initialValue: {}
      },
      sendToDistributor: {
        initialValue: null
      },
      species: {
        // "An owner AND species are required to save."
        initialValue: '',
        validate: value => {
          return (
            stringRequired(value, 'validation.script.species.required') ||
            maxLength(value, 60)
          );
        }
      },
      type: {
        initialValue: 'Script'
      },
      vet: {
        initialValue: {}
      },
      // From neptr model:
      label: {
        initialValue: ''
      },
      lastUpdated: {
        initialValue: null
      },
      productionClass: {
        initialValue: ''
      },
      serialNumber: {
        initialValue: ''
      },
      template: {
        initialValue: ''
      },
      version: {
        initialValue: ''
      },
      requestId: {
        initialValue: ''
      }
    };
  }

  static get docType() {
    return 'Script';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    return super.read(id);
  };
}
