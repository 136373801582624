// list constants
export const LIST_REQUEST = 'location/LIST_REQUEST';
export const LIST_SUCCESS = 'location/LIST_SUCCESS';
export const LIST_FAILURE = 'location/LIST_FAILURE';

// refresh
export const REFRESH_SUCCESS = 'location/REFRESH_SUCCESS';

// save constants
export const SAVE_REQUEST = 'location/SAVE_REQUEST';

// General constants
export const IS_REFRESHING = 'location/IS_REFRESHING';
