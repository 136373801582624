// import * as isolateActions from './store/isolateActions';
// import * as isolateReducer from './store/isolateReducers';
import IsolateModel from './Isolate';
import IsolateExtension from './IsolateExtension';
import IsolateRelease from './IsolateRelease';
import ExpirationComponent from './components/IsolateExpirationComponent';
import ClinicCard from './components/IsolateClinicCard';
import ProducerCard from './components/IsolateProducerCard';
import ReducedDetailsCard from './components/IsolateReducedDetailsCard';
import IsolateMultiSelectComponent from './components/IsolateMultiSelect';
import RequestChangesModal from './components/ExtensionRequestChangesModal';
import IsolateDocTables from './components/IsolateDocumentTables';

// export default {
//   isolateActions,
//   isolateReducer
// };

// models
export const Isolate = IsolateModel;
export const IsolateExtensionModel = IsolateExtension;
export const IsolateReleaseModel = IsolateRelease;

// components
export const IsolateExpiration = ExpirationComponent;
export const IsolateClinicCard = ClinicCard;
export const IsolateProducerCard = ProducerCard;
export const IsolateReducedDetailsCard = ReducedDetailsCard;
export const ExtensionRequestChangesModal = RequestChangesModal;
export const IsolateMultiSelect = IsolateMultiSelectComponent;
export const IsolateDocumentTables = IsolateDocTables;
