import Model from 'models/Model';
import {
  objectRequiredWithId,
  stringRequired,
  arrayRequired
} from 'utils/validation/common';
import * as api from 'utils/api';

export default class HerdList extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      herdListId: {
        initialValue: undefined
      },
      name: {
        initialValue: undefined,
        validate: name => stringRequired(name, 'required')
      },
      herds: {
        initialValue: undefined,
        validate: herds => arrayRequired(herds)
      },
      clinic: {
        initialValue: undefined,
        validate: clinic => objectRequiredWithId(clinic)
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      // used for creating a herd list, isn't returned by the api
      naRequestId: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/herdList';
  }

  static get domain() {
    return 'HerdList';
  }

  static get idFieldName() {
    return 'herdListId';
  }

  static list = async params => await super.list(params);

  async create() {
    let result;
    result = await api.post(`${HerdList.apiPath}`, {
      herdList: this.toJson(),
      naRequestId: this.naRequestId
    });
    this.constructor.initialize(this, result);
  }
}
