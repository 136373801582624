export const ROLE_SUPERUSER = 'ROLE_SUPERUSER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPPORT = 'ROLE_SUPPORT';
export const ROLE_SUPPORT_ADMIN = 'ROLE_SUPPORT_ADMIN';
export const ROLE_VET = 'ROLE_VET';
export const ROLE_VET_TECH = 'ROLE_VET_TECH';
export const ROLE_LAB_TECH = 'ROLE_LAB_TECH';
export const ROLE_STATE_VET = 'ROLE_STATE_VET';
export const ROLE_ANIMAL_OWNER = 'ROLE_ANIMAL_OWNER';
export const ROLE_DISTRIBUTOR_VFD = 'ROLE_DISTRIBUTOR_VFD';
export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_LABLINK_HIMS_ONLY_ADMIN = 'ROLE_LABLINK_HIMS_ONLY_ADMIN';
export const ROLE_NVSL_LAB_TECH = 'ROLE_NVSL_LAB_TECH';
export const ROLE_BILLING_SPECIALIST = 'ROLE_BILLING_SPECIALIST';
export const ROLE_ISOLATE_CREATOR = 'ROLE_ISOLATE_CREATOR';
export const ROLE_ISOLATE_QUALITY_ASSURANCE = 'ROLE_ISOLATE_QUALITY_ASSURANCE';
export const ROLE_ISOLATE_APPROVER = 'ROLE_ISOLATE_APPROVER';
export const ROLE_ISOLATE_SUPPORT = 'ROLE_ISOLATE_SUPPORT';
export const ROLE_ISOLATE_SUPPORT_MANAGER = 'ROLE_ISOLATE_SUPPORT_MANAGER';
export const ROLE_ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MANAGER';
