import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import { GvlMenu } from 'style/commonEmotions';
import { getHimsUrl } from 'utils/envUtil';
import { hasForSale, isCanadian } from 'utils/userUtil';
import {
  isRabiesFeatureEnabled,
  isIhcFeatureEnabled,
  isVaccineFeatureEnabled
} from 'containers/app/store/user/userSelectors';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

function ClinicMenuComponent({
  location,
  user,
  ehcParticipants,
  rabiesEnabled,
  isCanadianUser,
  ihcFeatureEnabled,
  autogenousVaccineEnabled
}) {
  const enabledApps = user.info.enabledApps || [];
  const ehcStates = Object.keys(ehcParticipants);
  const licenseStates = user.info.licenseStates || [];
  const clinicName = user.info.clinicName || '';
  // Banfield clinic has specific access restrictions based on their 30 day CVI contract status
  const banfieldClinic = clinicName.toLowerCase().includes('banfield');
  //Hide EECVI for Banfield clinic
  const showEECVI =
    !banfieldClinic && ehcStates.some(state => licenseStates.includes(state));
  const showForSale = hasForSale(user);

  let showAnimalMovement = enabledApps.some(app =>
    ['healthlink', 'equuslink'].includes(app)
  );

  showAnimalMovement = showAnimalMovement || showEECVI || showForSale;
  let showHealthManagement = enabledApps.some(app =>
    ['feedlink', 'scriptlink'].includes(app)
  );
  showHealthManagement = showHealthManagement || user.info.himsAccess;

  const showIHC =
    ihcFeatureEnabled && enabledApps.includes('healthlink') && !banfieldClinic;

  // TODO: Should this still be restricted from Canadian users when it goes live?
  const showAutogenousVaccines =
    autogenousVaccineEnabled &&
    hasFeatureAccess(user, FeatureEnum.IsolateManagement, 'read') &&
    enabledApps.includes('autogenousvaccines');

  // TODO: Update the menu to only show menu items available to specific role(s).  See AdminRoute.jsx
  return (
    <GvlMenu
      selectedKeys={[location.pathname]}
      defaultOpenKeys={['/cvi']}
      mode="inline"
    >
      <GvlMenuItem
        key="home"
        link="/"
        isTopLevel={true}
        icon="gvl-home"
        title={intl.get('home')}
      />
      {showAnimalMovement && (
        <GvlSubMenu
          key="domesticMovement"
          title={intl.get('domesticMovement')}
          icon="gvl-arrows-opposite"
        >
          {enabledApps.includes('healthlink') && (
            <GvlMenuItem key="/cvi" link="/cvis" title={intl.get('cvis')} />
          )}
          {enabledApps.includes('equuslink') && (
            <GvlMenuItem key="/eia" link="/eias" title={intl.get('eias')} />
          )}
          {showEECVI && (
            <GvlMenuItem
              key="/eecvi"
              link="/eecvis"
              title={intl.get('eecvis')}
            />
          )}
          {showForSale && (
            <GvlMenuItem
              key="/forsale"
              link="/forsale"
              title={intl.get('forsale')}
            />
          )}
        </GvlSubMenu>
      )}
      {showIHC && (
        <GvlSubMenu
          key="internationalMovement"
          title={intl.get('internationalMovement')}
          icon="global"
        >
          <GvlMenuItem
            key="/ihcs"
            link="/ihcs"
            title={intl.get('ihc.brandName')}
          />
        </GvlSubMenu>
      )}
      {showHealthManagement && (
        <GvlSubMenu
          key="healthManagement"
          title={intl.get('healthManagement')}
          icon="gvl-cross"
        >
          {enabledApps.includes('feedlink') && (
            <GvlMenuItem key="/vfds" link="/vfds" title={intl.get('vfds')} />
          )}
          {rabiesEnabled && enabledApps.includes('healthlink') ? (
            <GvlMenuItem
              key="/rabies"
              link="/rabies"
              title={intl.get('rabiesCert.plural')}
            />
          ) : null}
          {enabledApps.includes('scriptlink') && (
            <GvlMenuItem
              key="/scripts"
              link="/scripts"
              title={intl.get('scripts')}
            />
          )}
          {user.info.himsAccess && (
            <GvlMenuItem
              key="/lablinkhims"
              link={getHimsUrl()}
              title={intl.get('lablinkhims')}
              external={true}
            />
          )}
        </GvlSubMenu>
      )}

      <GvlSubMenu key="records" title={intl.get('records')} icon="gvl-records">
        <GvlMenuItem
          key="/animals"
          link="/animals"
          title={intl.get('animals')}
        />
        <GvlMenuItem
          key="/contacts"
          link="/contacts"
          title={intl.get('contacts')}
        />
        <GvlMenuItem
          key="/certificates"
          link="/certificates"
          title={intl.get('certificates')}
        />
        <GvlMenuItem key="/images" link="/images" title={intl.get('Images')} />
        {showAutogenousVaccines && (
          <GvlMenuItem
            key="/vaccines"
            link="/vaccines"
            title={intl.get('vaccines')}
          />
        )}
      </GvlSubMenu>

      {showAutogenousVaccines && (
        <GvlSubMenu
          key="autogenousVaccines"
          title={intl.get('vaccines.customMade')}
          icon="gvl-syringe"
        >
          <GvlMenuItem
            key="/isolates"
            link="/isolates"
            title={intl.get('isolates')}
          />
          <GvlMenuItem
            key="/products"
            link="/products"
            title={intl.get('isolate.products')}
          />
        </GvlSubMenu>
      )}
      <GvlSubMenu key="help" title={intl.get('help')} icon="gvl_help">
        <GvlMenuItem
          key="/helpArticles"
          link="http://help.globalvetlink.com"
          title={intl.get('helpArticles')}
          external={true}
        />
        <GvlMenuItem
          key="/animalRegs"
          link="https://animalregs.com"
          title={intl.get('animalRegs.com')}
          external={true}
        />
      </GvlSubMenu>
    </GvlMenu>
  );
}

ClinicMenuComponent.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  ehcParticipants: PropTypes.object.isRequired,
  rabiesEnabled: PropTypes.bool.isRequired,
  ihcFeatureEnabled: PropTypes.bool.isRequired,
  isCanadianUser: PropTypes.bool.isRequired,
  autogenousVaccineEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { accounts } = state;
  return {
    ehcParticipants:
      accounts.common.startupData.lookupLists.ehcParticipants || {},
    rabiesEnabled: isRabiesFeatureEnabled(state),
    ihcFeatureEnabled: isIhcFeatureEnabled(state),
    isCanadianUser: isCanadian(state.app.user),
    autogenousVaccineEnabled: isVaccineFeatureEnabled(state)
  };
};

const clinicMenuWthRedux = connect(mapStateToProps)(ClinicMenuComponent);
const ClinicMenu = withRouter(clinicMenuWthRedux);

export { ClinicMenu as default, ClinicMenuComponent };
