import { put, call, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { notification } from 'antd';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { Location } from 'modules/Location/locationIndex';
import * as actions from 'modules/Location/store/locationActions';
import * as types from 'modules/Location/store/locationActionTypes';

export function* onLocationListRequest(action) {
  try {
    yield put(actions.isRefreshing(true));
    const locations = yield call(Location.list, action.params);
    yield put(actions.listSuccess(locations));
  } catch (err) {
    yield put(apiFailure(err, 'Error loading Location data'));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onLocationSaveRequest(action) {
  let { location, callback } = action;

  try {
    yield put(actions.isRefreshing(true));
    yield call([location, location.save]);
    yield put(actions.refreshSuccess(location));
    if (callback) {
      callback();
    }
  } catch (err) {
    yield call(notification.warning, {
      message: intl.get('error.saving.noun', {
        noun: intl.get('location.lowerCase')
      }),
      description: intl.get('server.error')
    });
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* locationSagas() {
  yield takeLatest(types.LIST_REQUEST, onLocationListRequest);
  yield takeLatest(types.SAVE_REQUEST, onLocationSaveRequest);
}
