import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { listRequest as herdListRequest } from 'modules/Herd/store/herdActions';
import { generateSelectOptions } from 'utils/appUtil';
import styled from '@emotion/styled';
import { FONT, THEME } from 'style';

const { Text } = Typography;

const OptionPrimaryInfo = styled(Text)({
  fontWeight: FONT.weight.regular,
  fontFamily: FONT.family.regular,
  fontSize: FONT.size.medium
});

const OptionSecondaryInfo = styled(Text)({
  fontWeight: FONT.weight.regular,
  fontFamily: FONT.family.regular,
  fontSize: FONT.size.small,
  color: THEME.disabledText()
});

class HerdLookupComponent extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    searchParams: PropTypes.object.isRequired,
    herdListRequest: PropTypes.func.isRequired,
    herds: PropTypes.object,
    disableSelect: PropTypes.bool,
    tooltipText: PropTypes.string
  };

  componentDidUpdate(prevProps) {
    const { searchParams, herdListRequest } = this.props;
    if (
      searchParams.contactId &&
      prevProps.searchParams.contactId !== searchParams.contactId
    ) {
      herdListRequest(searchParams);
    }
  }

  createSelectText = herd => (
    <span>
      <OptionPrimaryInfo>{herd.species} | </OptionPrimaryInfo>
      <OptionSecondaryInfo>
        ({`Headcount: ${herd.headCount}`})
      </OptionSecondaryInfo>
    </span>
  );

  render() {
    const { herds, disableSelect, onChange, tooltipText } = this.props;
    return (
      <Tooltip title={disableSelect ? tooltipText : null}>
        <Select
          showSearch
          showAction={['focus', 'click']}
          onChange={onChange}
          disabled={disableSelect}
        >
          {generateSelectOptions(
            Object.values(herds).map(herd => ({
              value: herd.herdId,
              text: this.createSelectText(herd)
            }))
          )}
        </Select>
      </Tooltip>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  herds: accounts.herds.data
});

const mapDispatchToProps = { herdListRequest };

const HerdLookup = connect(
  mapStateToProps,
  mapDispatchToProps
)(HerdLookupComponent);
export { HerdLookup as default, HerdLookupComponent };
