import Document from 'models/documents/Document';
import RabiesVac from 'models/RabiesVac';
import {
  maxLength,
  objectRequired,
  stringRequired,
  isNumber,
  numberInRange
} from 'utils/validation/common';
import Contact from 'models/Contact';
import moment from 'moment';

export default class Rabies extends Document {
  // Instance Methods
  async save(bypassSubObjectUpdating = false) {
    if (!bypassSubObjectUpdating) {
      if (this.vaccination && this.vaccination.manufacturer) {
        let rabiesVac = new RabiesVac({ ...this.vaccination });
        // this has potential to reassign a vac record to a different animal that it was first associated to
        // but given at this point we create a new vac for each rabies doc that should theoretically be okay
        rabiesVac.animal_id = this.animal.id;
        let errors = rabiesVac.validate();
        if (!errors) {
          await rabiesVac.save();
          this.vaccination = { ...rabiesVac };
        } else {
          console.error('eia.rabiesVac.error', rabiesVac, errors);
          this.vaccination = null;
        }
      }
      // trim unnecessary sub arrays on animal prior to saving the animal on the doc.
      if (this.animal && this.animal.id) {
        delete this.animal.tests;
        delete this.animal.vacs;
        delete this.animal.treatments;
      }
    }
    return await super.save();
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      animal: {
        initialValue: {},
        validate: val => objectRequired('pet', val)
      },
      owner: {
        initialValue: {},
        validate: owner => {
          return (
            objectRequired('owner', owner) || new Contact(owner).validate()
          );
        }
      },
      ownerPremises: {
        initialValue: {},
        validate: val => objectRequired('ownerPremises', val)
      },
      species: {
        initialValue: undefined,
        validate: val => maxLength(val, 50)
      },
      weight: {
        initialValue: null,
        validate: val => {
          if (val) {
            return (
              isNumber(val, 'validation.pet.weight.number') ||
              numberInRange(val, 1, 500, 'validation.pet.weight.range')
            );
          } else {
            return stringRequired(val, 'validation.pet.weight.required');
          }
        }
      },
      weightUnits: {
        initialValue: 'lbs',
        validate: val => stringRequired(val, 'validation.pet.weight.required')
      },
      rabiesTag: {
        initialValue: undefined,
        validate: val =>
          stringRequired(val, 'isRequired', {
            thing: 'rabies.tag'
          })
      },
      microchip: {
        initialValue: undefined
      },
      vaccination: {
        initialValue: {},
        validate: val => objectRequired('vaccination', val)
      }
    };
  }

  static get apiPath() {
    return 'documents';
  }

  static get docType() {
    return 'Rabies';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    return super.read(id);
  };

  static getDrafts = async () => {
    return super.getDrafts(this.docType);
  };

  static getRecentlySigned = async () => {
    const data = await super.list({
      'metadata.status': 'SIGNED',
      signedSince: moment()
        .subtract(14, 'days')
        .toJSON(),
      'metadata.archived': false,
      type: this.docType,
      limit: 0
    });
    return data.results;
  };
}
