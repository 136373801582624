import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './productActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false,
  searchResults: {}
});

export const onProductRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

export const onProductListSuccess = (state, payload) => {
  const { products } = payload;
  let newState = state.asMutable({ deep: true });

  /*
  TODO: Do we still want to clear out the list,
  potentially as a first step in the list request?
  The behavior here mirrors isolateReducers.js
  */
  for (const product of products) {
    newState.data[product.productId] = product;
  }
  newState.lastSuccessfulRefreshedTime = new Date().getTime();
  newState.refreshFailure = false;

  return Immutable.from(newState);
};

export const onProductListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onProductSearchSuccess = (state, { products }) => {
  let searchResults = {};
  products.forEach(product => (searchResults[product.productId] = product));
  return state.merge({
    searchResults
  });
};

export const onProductSearchFailure = state =>
  state.merge({
    searchResults: {}
  });

export const onRefreshProductSuccess = (state, { product }) =>
  Immutable.setIn(state, ['data', product.productId], product);

export const onRemoveFromStore = (state, { productId }) =>
  state.updateIn(['data'], data => data.without(productId));

export const productReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onProductRefreshing,
  [types.LIST_SUCCESS]: onProductListSuccess,
  [types.LIST_FAILURE]: onProductListFailure,
  [types.SEARCH_SUCCESS]: onProductSearchSuccess,
  [types.SEARCH_FAILURE]: onProductSearchFailure,
  [types.REFRESH_SUCCESS]: onRefreshProductSuccess,
  [types.REMOVE_FROM_STORE]: onRemoveFromStore
});
