import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import moment from 'moment';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { Button, Col, Form, Input, Modal, Row, Spin, notification } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { dateIsFutureDate } from 'utils/validation/common';
import { Product } from 'modules/Product/productIndex';
import { saveRequest as productSaveRequest } from 'modules/Product/store/productActions';
import { refreshIsolatesForProduct } from 'modules/Isolate/store/isolateActions';
import { FadedText } from 'style/commonEmotions';
import GvlDatePicker from 'elements/GvlDatePicker';
import IsolateMultiSelect from 'modules/Isolate/components/IsolateMultiSelect';

class ProductModalComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    productSaveRequest: PropTypes.func.isRequired,
    refreshIsolatesForProduct: PropTypes.func.isRequired,
    isolates: PropTypes.object.isRequired,
    refreshingProduct: PropTypes.bool.isRequired,
    product: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { visible, product, isolates, form } = this.props;
    if (!prevProps.visible && visible) {
      // reset fields and errors
      form.resetFields();
      if (product && product.productId !== prevProps.product?.productId) {
        form.setFieldsValue({
          productNbr: product.productNbr,
          serialNbr: product.serialNbr,
          initialOrderDate: moment(product.initialOrderDate),
          shipDate: product.shipDate ? moment(product.shipDate) : null,
          isolateIds: product.isolateIds
        });
        // Get isolates for the store if needed; would only happen if isolate list call hasn't been made
        if (product.isolateIds.some(isolateId => !isolates[isolateId])) {
          this.props.refreshIsolatesForProduct(product.productId);
        }
      }
    }
  }

  saveProduct = () => {
    const { form, isolates, closeModal } = this.props;
    form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('product.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      const product = new Product({
        ...this.props.product,
        ...formValues
      });

      // Assumption that all isolates are from the same clinic because isolate need to be shared if the clinic is different.
      // At that point that clinic would have their own version of the product with isolates from their clinic in it.
      product.clinic = {
        id: isolates[product.isolateIds[0]].clinicOwner.id
      };
      // Get all the unique vets for the product
      let vets = [];
      product.isolateIds.forEach(isolateId =>
        vets.push(isolates[isolateId].vets)
      );
      product.vets = uniqBy(vets.flat(), 'id');

      const modelValidationErrors = product.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('product.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      // If any isolates will have their ship dates update then present a modal to confirm the save, otherwise save the product
      if (
        product.shipDate &&
        product.isolateIds.some(
          isolateId =>
            !isolates[isolateId].initialShipDate ||
            moment(product.shipDate).diff(
              moment(isolates[isolateId].initialShipDate)
            ) < 0
        )
      ) {
        Modal.confirm({
          title: intl.get('product.save.title'),
          content: intl.get('product.save.body'),
          okText: intl.get('save'),
          onOk: () => {
            this.props.productSaveRequest(product, closeModal);
          }
        });
      } else {
        this.props.productSaveRequest(product, closeModal);
      }
    });
  };

  cancelEdit = () => {
    const { form, closeModal } = this.props;
    form.isFieldsTouched()
      ? Modal.confirm({
          title: intl.get('cancel.edit'),
          content: intl.get('cancel.edit.body'),
          okText: intl.get('discard'),
          onOk: closeModal
        })
      : closeModal();
  };

  render() {
    const { visible, form, product, refreshingProduct } = this.props;
    return (
      <Modal
        title={intl.get(product ? 'edit.product' : 'create.product')}
        visible={visible}
        onCancel={this.cancelEdit}
        destroyOnClose={true}
        footer={[
          <Spin key="spin" spinning={refreshingProduct} size="small">
            <Button key="cancel" onClick={this.cancelEdit}>
              {intl.get('cancel')}
            </Button>
            <Button key="save" type="primary" onClick={this.saveProduct}>
              {intl.get('save')}
            </Button>
          </Spin>
        ]}
        width={600}
      >
        <Form layout="vertical">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label={intl.get('product.nbr')}>
                {getModelFieldDecorator(
                  form,
                  'productNbr',
                  Product,
                  true
                )(<Input onPressEnter={this.saveProduct} />)}
              </Form.Item>
              <Form.Item label={intl.get('serialNumber')}>
                {getModelFieldDecorator(
                  form,
                  'serialNbr',
                  Product,
                  true
                )(<Input onPressEnter={this.saveProduct} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={intl.get('product.initial.order.date')}>
                {getModelFieldDecorator(
                  form,
                  'initialOrderDate',
                  Product,
                  true
                )(<GvlDatePicker disabledDate={dateIsFutureDate} />)}
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    {intl.get('ship.date')}{' '}
                    <FadedText>({intl.get('optional')})</FadedText>
                  </span>
                }
              >
                {getModelFieldDecorator(
                  form,
                  'shipDate',
                  Product,
                  false
                )(<GvlDatePicker disabledDate={dateIsFutureDate} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item label={intl.get('isolate.numbers')}>
              {getModelFieldDecorator(
                form,
                'isolateIds',
                Product,
                true
              )(<IsolateMultiSelect form={form} fieldName={'isolateIds'} />)}
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  isolates: state.accounts.isolates.data,
  refreshingProduct: state.accounts.products.isRefreshing
});

const mapDispatchToProps = {
  productSaveRequest,
  refreshIsolatesForProduct
};

const ProductModalForm = Form.create()(ProductModalComponent);

const ProductModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductModalForm);

export { ProductModal as default, ProductModalComponent };
