import * as api from 'utils/api';
import Model from 'models/Model';
import {
  stringRequired,
  objectRequiredWithId,
  maxLength
} from 'utils/validation/common';
import { objectRequiredWithGvlId } from 'utils/validation/autogenousVaccines';

export default class IsolateRelease extends Model {
  validate() {
    return super.validate();
  }

  static get fields() {
    return {
      ...super.fields,
      isolateReleaseId: {
        initialValue: undefined
      },
      isolateId: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      isolateNbr: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      approvingClinic: {
        initialValue: undefined,
        validate: value => objectRequiredWithGvlId(value)
      },
      approvingVet: {
        initialValue: undefined,
        validate: value => objectRequiredWithId(value)
      },
      recipientClinic: {
        initialValue: undefined,
        validate: value => objectRequiredWithGvlId(value)
      },
      recipientVet: {
        initialValue: undefined,
        validate: value => objectRequiredWithId(value)
      },
      releaseReason: {
        initialValue: undefined,
        inList: ['VACCINE_PRODUCTION', 'OWNERSHIP_CHANGE']
      },
      status: {
        initialValue: undefined,
        inList: ['REQUESTED', 'APPROVED', 'DECLINED', 'NEEDS_INFORMATION']
      },
      vetComments: {
        initialValue: undefined,
        validate: value => {
          return value ? maxLength(value, 255) : null;
        }
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      }
    };
  }

  // The isolate release is exclusively accessed through its parent isolate.
  // The apiPath value reflects that, and requests to the API will be managed in the IsolateController
  static get apiPath() {
    return 'autogenousVaccines/isolate';
  }

  static get domain() {
    return 'IsolateRelease';
  }

  // List request goes through isolateRelease instead of isolate api path
  static list = async params =>
    await api.get(`autogenousVaccines/isolateRelease`, { params });

  // we manage both creation and updating with this method by flipping on the existence of the ID
  async saveRelease() {
    let result;
    if (this.isolateReleaseId) {
      result = await api.put(
        `${IsolateRelease.apiPath}/${this.isolateId}/release/${this.isolateReleaseId}`,
        this.toJson()
      );
    } else {
      result = await api.post(
        `${IsolateRelease.apiPath}/${this.isolateId}/release`,
        this.toJson()
      );
    }
    this.constructor.initialize(this, result);
  }

  static async deleteRelease(isolateId, isolateReleaseId) {
    await api.deleteRec(
      `${IsolateRelease.apiPath}/${isolateId}/release/${isolateReleaseId}`
    );
  }

  async approveRelease() {
    await api.put(
      `${IsolateRelease.apiPath}/${this.isolateId}/release/${this.isolateReleaseId}/approve`,
      this.toJson()
    );
  }

  async declineRelease() {
    await api.deleteRecWithData(
      `${IsolateRelease.apiPath}/${this.isolateId}/release/${this.isolateReleaseId}/decline`,
      this.toJson()
    );
  }

  async requestInfo() {
    await api.put(
      `${IsolateRelease.apiPath}/${this.isolateId}/release/${this.isolateReleaseId}/needsInformation`,
      this.toJson()
    );
  }
}
