import Model from 'models/Model';
import {
  isEmail,
  stringRequired,
  maxLength,
  isNumber,
  numberInRange,
  postalCodeMatchesCountry,
  stateMatchesCountry
} from 'utils/validation/common';

export default class Lab extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();
    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        initialValue: undefined
      },
      uuid: {
        initialValue: undefined
      },
      name: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      email: {
        initialValue: undefined,
        validate: value => {
          return !value
            ? null
            : isEmail(value, 'validation.email.invalid') ||
                maxLength(value, 120);
        }
      },
      cellPhone: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      type: {
        initialValue: null
      },
      network: {
        initialValue: null
      },
      gvlLab: {
        initialValue: true
      },
      accountNumberRequired: {
        initialValue: false
      },
      barCodeRequired: {
        initialValue: false
      },
      enabled: {
        initialValue: true
      },
      version: {
        initialValue: null
      },
      addressLine1: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 120);
        }
      },
      addressLine2: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 120);
        }
      },
      city: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      state: {
        initialValue: null,
        validate: (value, instance) => {
          // State abbreviations must match country ['Canada', 'USA', 'United States of America'];
          return (
            stringRequired(value, 'required') ||
            maxLength(value, 60) ||
            (instance
              ? stateMatchesCountry(instance.state, instance.country)
              : undefined)
          );
        }
      },
      postalCode: {
        initialValue: null,
        validate: (value, instance) => {
          // Postal code must match country ['Canada', 'USA', 'United States of America']
          return (
            stringRequired(value, 'required') ||
            maxLength(value, 15) ||
            (instance
              ? postalCodeMatchesCountry(instance.postalCode, instance.country)
              : undefined)
          );
        }
      },
      plus4: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 4);
        }
      },
      county: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 60);
        }
      },
      country: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      phone: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      fax: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      latitude: {
        initialValue: null,
        validate: value => {
          return value === undefined || value === null
            ? null
            : isNumber(value, 'number') || numberInRange(value, -90, 90);
        }
      },
      longitude: {
        initialValue: null,
        validate: value => {
          return value === undefined || value === null
            ? null
            : isNumber(value, 'number') || numberInRange(value, -180, 180);
        }
      },
      ftpAccountActive: {
        initialValue: false
      },
      ftpAccountUsername: {
        initialValue: null
      },
      ftpAccountPassword: {
        initialValue: null
      },
      ftpAccountEmails: {
        initialValue: null
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      lastLogin: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'lab';
  }

  static get domain() {
    return 'Lab';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };
}
