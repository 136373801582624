import Model from 'models/Model';
export default class FeedMillSearch extends Model {
  static get apiPath() {
    return 'documents/feedMillSearch';
  }

  static get domain() {
    return 'Document';
  }

  static getFeedMill = async params => {
    const data = await super.list(params);
    return data;
  };
}
