import Model from 'models/Model';

export default class AppUser extends Model {
  // Instance Methods

  validate() {
    return super.validate();
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        initialValue: null
      },
      betaFeatures: {
        initialValue: []
      },
      email: {
        initialValue: null
      },
      enabled: {
        initialValue: false
      },
      g1_enabled_status: {
        initialValue: false
      },
      passwordExpired: {
        initialValue: false
      },
      person: {
        initialValue: {}
      },
      rolesList: {
        initialValue: []
      },
      settings: {
        initialValue: []
      },
      signatureAllowed: {
        initialValue: false
      },
      signatureType: {
        initialValue: null
      },
      unverifiedEmail: {
        initialValue: null
      },
      username: {
        initialValue: null
      },
      emailFrequency: {
        initialValue: 'Never'
      },
      webhook: {
        initialValue: null
      },
      dateCreated: {
        initialValue: null
      },
      lastUpdated: {
        initialValue: null
      },
      lastLogin: {
        initialValue: null
      },
      messagesLastRead: {
        initialValue: null
      }
    };
  }

  static get apiPath() {
    return 'appUser';
  }

  static get domain() {
    return 'AppUser';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };
}
