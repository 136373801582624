import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Modal, Row, List, Spin, Divider } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as settingStorage from 'utils/settingStorage';
import { InlineButtonLink } from 'style/commonEmotions';
import { capitalizeFirstLetter } from 'utils/appUtil';

class NotificationsModalComponent extends Component {
  static propTypes = {
    refreshing: PropTypes.bool,
    isVisible: PropTypes.bool,
    notifications: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired
  };
  static defaultProps = {
    isVisible: true
  };

  componentDidUpdate() {
    if (this.props.isVisible) {
      settingStorage.setValue('messagesLastViewed', moment());
    }
  }

  renderNotificationsListItem = notification => {
    return (
      <List.Item>
        <Row>
          <InlineButtonLink
            type="link"
            onClick={() => {
              console.log('clicked notification', notification);
            }}
          >
            {capitalizeFirstLetter(notification.comment)}
          </InlineButtonLink>
          <br />
          <span>
            {`About ${moment(notification.timestamp).fromNow()}`}
            <Divider type="vertical" />
            {moment(notification.timestamp).format('M/D/Y')}
            <Divider type="vertical" />
            {moment(notification.timestamp).format('hh:mm A')}
          </span>
        </Row>
      </List.Item>
    );
  };
  render() {
    const { refreshing, isVisible, notifications, onCancel } = this.props;
    return (
      <Modal
        className="notificationsModalScroll"
        visible={isVisible}
        title={<h3>{intl.get('notifications')}</h3>}
        zIndex={10000}
        onCancel={onCancel}
        destroyOnClose={true}
        footer={null}
        bodyStyle={{ paddingRight: '8px', marginRight: '8px' }} // center the scrollbar in the white space on the right edge of the modal
      >
        <Spin spinning={refreshing} size="large">
          <List
            size="large"
            dataSource={notifications}
            renderItem={item => this.renderNotificationsListItem(item)}
          />
        </Spin>
      </Modal>
    );
  }
}

export default NotificationsModalComponent;
