import { createAction } from 'utils/reduxUtil';
import {
  CLINIC_LOAD_STARTUP_DATA_REQUEST,
  CLINIC_LOAD_STARTUP_DATA_STARTED,
  CLINIC_LOAD_STARTUP_DATA_SUCCESS,
  CLINIC_LOAD_STARTUP_DATA_FINISHED,
  CLINIC_REFRESH_VETS_LIST_SUCCESS,
  // Contacts
  CLINIC_REFRESH_CONTACT_LIST_REQUEST,
  CLINIC_REFRESH_CONTACT_LIST_STARTED,
  CLINIC_REFRESH_CONTACT_LIST_SUCCESS,
  CLINIC_REFRESH_CONTACT_LIST_FAILURE,
  CLINIC_REFRESH_CONTACT_REQUEST,
  CLINIC_REFRESH_CONTACT_SUCCESS,
  // EIA
  CLINIC_REFRESH_EIA_LIST_REQUEST,
  CLINIC_REFRESH_EIA_LIST_STARTED,
  CLINIC_REFRESH_EIA_LIST_SUCCESS,
  CLINIC_REFRESH_EIA_LIST_FAILURE,
  CLINIC_ACTIVATE_EIA,
  CLINIC_DEACTIVATE_EIA,
  CLINIC_REFRESH_EIA_REQUEST,
  CLINIC_REFRESH_EIA_STARTED,
  CLINIC_REFRESH_EIA_SUCCESS,
  CLINIC_REFRESH_EIA_FAILURE,
  CLINIC_ARCHIVE_EIAS_REQUEST,
  CLINIC_DELETE_EIAS_REQUEST,
  CLINIC_EIA_LIST_READY,
  CLINIC_SAVE_EIA_REQUEST,
  CLINIC_SAVE_EIA_SUCCESS,
  CLINIC_SAVE_EIA_FAILURE,
  CLINIC_SIGN_EIA_REQUEST,
  CLINIC_SIGN_EIA_SUCCESS,
  CLINIC_SIGN_EIA_FAILURE,
  CLINIC_BATCH_SIGN_EIA_REQUEST,
  CLINIC_BATCH_SIGN_EIA_SUCCESS,
  CLINIC_BATCH_SIGN_EIA_FAILURE,
  CLINIC_CREATE_CVI_FROM_EIA_REQUEST,
  CLINIC_CREATE_CVI_FROM_EIA_SUCCESS,
  CLINIC_CREATE_CVI_FROM_EIA_FAILURE,
  CLINIC_REMOVE_EIA_FROM_STORE,
  CLINIC_AUTO_ARCHIVE_EIA_REQUEST,
  CLINIC_AUTO_ARCHIVE_EIA_SUCCESS,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS,
  CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED,
  // CVI
  CLINIC_REFRESH_CVI_LIST_REQUEST,
  CLINIC_REFRESH_CVI_LIST_STARTED,
  CLINIC_REFRESH_CVI_LIST_SUCCESS,
  CLINIC_REFRESH_CVI_LIST_FAILURE,
  CLINIC_ACTIVATE_CVI,
  CLINIC_DEACTIVATE_CVI,
  CLINIC_REFRESH_CVI_REQUEST,
  CLINIC_REFRESH_CVI_STARTED,
  CLINIC_REFRESH_CVI_SUCCESS,
  CLINIC_REFRESH_CVI_FAILURE,
  CLINIC_DELETE_CVI_REQUEST,
  CLINIC_DELETE_CVI_FAILURE,
  CLINIC_SAVE_CVI_REQUEST,
  CLINIC_CVI_ADD_PERMIT_REQUEST,
  CLINIC_CVI_ADD_PERMIT_SUCCESS,
  CLINIC_SIGN_CVI_REQUEST,
  CLINIC_SIGN_CVI_SUCCESS,
  CLINIC_SIGN_CVI_FAILURE,
  CLINIC_BATCH_SIGN_CVI_REQUEST,
  CLINIC_BATCH_SIGN_CVI_SUCCESS,
  CLINIC_BATCH_SIGN_CVI_FAILURE,
  CLINIC_ARCHIVE_CVIS_REQUEST,
  CLINIC_DELETE_CVIS_REQUEST,
  CLINIC_CVI_LIST_READY,
  // EECVI
  CLINIC_EECVI_LIST_READY,
  CLINIC_ARCHIVE_EECVIS_REQUEST,
  CLINIC_DELETE_EECVIS_REQUEST,
  CLINIC_SAVE_EECVI_REQUEST,
  CLINIC_COMMIT_EECVI_REQUEST,
  CLINIC_COMMIT_EECVI_FAILURE,
  CLINIC_BATCH_COMMIT_EECVI_REQUEST,
  CLINIC_REFRESH_EECVI_LIST_REQUEST,
  CLINIC_REFRESH_EECVI_LIST_STARTED,
  CLINIC_REFRESH_EECVI_LIST_SUCCESS,
  CLINIC_REFRESH_EECVI_LIST_FAILURE,
  CLINIC_ACTIVATE_EECVI,
  CLINIC_DEACTIVATE_EECVI,
  CLINIC_REFRESH_EECVI_REQUEST,
  CLINIC_REFRESH_EECVI_STARTED,
  CLINIC_REFRESH_EECVI_SUCCESS,
  CLINIC_REFRESH_EECVI_FAILURE,

  // vfd
  CLINIC_REFRESH_VFDS_LIST_REQUEST,
  CLINIC_REFRESH_VFDS_LIST_STARTED,
  CLINIC_REFRESH_VFDS_LIST_SUCCESS,
  CLINIC_REFRESH_VFDS_LIST_FAILURE,
  CLINIC_ACTIVATE_VFD,
  CLINIC_DEACTIVATE_VFD,
  CLINIC_REFRESH_VFD_REQUEST,
  CLINIC_REFRESH_VFD_STARTED,
  CLINIC_REFRESH_VFD_FAILURE,
  CLINIC_SAVE_VFD_REQUEST,
  CLINIC_SAVE_VFD_SUCCESS,
  CLINIC_SAVE_VFD_FAILURE,
  CLINIC_PROCESS_VFDS_RENEWAL_REJECT,
  CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT,
  CLINIC_PROCESS_VFDS_REQUEST,
  CLINIC_PROCESS_VFD_RENEWAL_REVIEW,
  CLINIC_SIGN_VFD_REQUEST,
  CLINIC_ARCHIVE_VFDS_REQUEST,
  CLINIC_DELETE_VFDS_REQUEST,
  CLINIC_VFD_LIST_READY,

  // Rabies
  CLINIC_REFRESH_RABIES_LIST_REQUEST,
  CLINIC_REFRESH_RABIES_LIST_STARTED,
  CLINIC_REFRESH_RABIES_LIST_SUCCESS,
  CLINIC_REFRESH_RABIES_LIST_FAILURE,
  CLINIC_RABIES_LIST_READY,
  CLINIC_BATCH_DELETE_RABIES_REQUEST,
  CLINIC_DELETE_RABIES_REQUEST,
  CLINIC_ACTIVATE_RABIES,
  CLINIC_REFRESH_RABIES_REQUEST,
  CLINIC_REFRESH_RABIES_STARTED,
  CLINIC_REFRESH_RABIES_SUCCESS,
  CLINIC_REFRESH_RABIES_FAILURE,
  CLINIC_SAVE_RABIES_REQUEST,
  CLINIC_SIGN_RABIES_REQUEST,
  CLINIC_SIGN_RABIES_SUCCESS,
  CLINIC_SIGN_RABIES_FAILURE,
  CLINIC_ARCHIVE_RABIES_REQUEST,
  CLINIC_BATCH_SIGN_RABIES_REQUEST,
  CLINIC_BATCH_SIGN_RABIES_SUCCESS,
  CLINIC_BATCH_SIGN_RABIES_FAILURE,

  // IHC
  CLINIC_REFRESH_IHC_LIST_REQUEST,
  CLINIC_REFRESH_IHC_LIST_STARTED,
  CLINIC_REFRESH_IHC_LIST_SUCCESS,
  CLINIC_REFRESH_IHC_LIST_FAILURE,
  CLINIC_IHC_LIST_READY,
  CLINIC_BATCH_DELETE_IHC_REQUEST,
  CLINIC_DELETE_IHC_REQUEST,
  CLINIC_CREATE_CVI_FROM_IHC_REQUEST,
  CLINIC_IHC_REMOVED_FROM_LIST,
  CLINIC_ACTIVATE_IHC,
  CLINIC_REFRESH_IHC_REQUEST,
  CLINIC_REFRESH_IHC_STARTED,
  CLINIC_REFRESH_IHC_SUCCESS,
  CLINIC_REFRESH_IHC_FAILURE,
  CLINIC_SAVE_IHC_REQUEST,
  CLINIC_SIGN_IHC_REQUEST,
  CLINIC_SIGN_IHC_SUCCESS,
  CLINIC_SIGN_IHC_FAILURE,
  CLINIC_ARCHIVE_IHC_REQUEST,
  CLINIC_BATCH_SIGN_IHC_REQUEST,
  CLINIC_BATCH_SIGN_IHC_SUCCESS,
  CLINIC_BATCH_SIGN_IHC_FAILURE,

  // Genereic Document
  CLINIC_CLEAR_DOCUMENT_ERRORS,
  CLINIC_VOID_DOCUMENT_REQUEST,
  CLINIC_VOID_DOCUMENT_SUCCESS,
  CLINIC_VOID_DOCUMENT_FAILURE,
  CLINIC_SAVE_DOC_SUCCESS,
  CLINIC_REFRESH_DOC_SUCCESS,
  CLINIC_LOCK_DOC_REQUEST,
  CLINIC_UNLOCK_DOC_REQUEST,
  CLINIC_REMOVE_DOC_SUCCESS,
  CLINIC_DOC_REQUEST_DONE,
  CLINIC_COPY_DOC_REQUEST,

  //Animal
  CLINIC_REFRESH_ANIMAL_LIST_REQUEST,
  CLINIC_REFRESH_ANIMAL_LIST_STARTED,
  CLINIC_REFRESH_ANIMAL_LIST_SUCCESS,
  CLINIC_REFRESH_ANIMAL_LIST_FAILURE,
  CLINIC_VERBOSE_ANIMAL_LIST_REQUEST,
  CLINIC_VERBOSE_ANIMAL_LIST_SUCCESS,
  CLINIC_REFRESH_ANIMAL_REQUEST,
  CLINIC_REFRESH_ANIMAL_SUCCESS,
  CLINIC_ANIMAL_LIST_READY,
  CLINIC_ANIMAL_REMOVED_FROM_LIST,
  CLINIC_DELETE_ANIMAL_REQUEST,
  CLINIC_DELETE_ANIMAL_STARTED,
  CLINIC_DELETE_ANIMAL_SUCCESS,
  CLINIC_DELETE_ANIMAL_FAILURE,
  CLINIC_ANIMAL_UPDATED_SUCCESS,
  CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST,
  CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST,
  //Image
  CLINIC_REFRESH_IMAGE_LIST_REQUEST,
  CLINIC_REFRESH_IMAGE_LIST_STARTED,
  CLINIC_REFRESH_IMAGE_LIST_SUCCESS,
  CLINIC_REFRESH_IMAGE_LIST_FAILURE,
  CLINIC_IMAGE_UPDATE_REQUEST,
  CLINIC_IMAGE_UPDATE_SUCCESS,
  CLINIC_IMAGE_DELETE_REQUEST,
  CLINIC_IMAGE_DELETE_SUCCESS,
  // Document model collections
  CLINIC_RESET_DOCUMENT_MODELS,
  CLINIC_GET_CONTACT_REQUEST,
  CLINIC_GET_PREMISES_REQUEST,
  CLINIC_CONTACT_UPDATE,
  CLINIC_PREMISES_UPDATE,
  CLINIC_FETCH_CARRIERS_REQUEST,
  CLINIC_FETCH_CARRIERS_SUCCESS,
  CLINIC_FETCH_CARRIERS_FAILURE,
  CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST,
  CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS,
  CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE,
  // Requests
  CLINIC_REJECT_DOCUMENT_REQUEST,
  CLINIC_REQUEST_REMOVED_FROM_LIST,
  //Contacts
  CLINIC_DELETE_CONTACT_REQUEST,
  CLINIC_CONTACT_REMOVED_FROM_LIST,
  CLINIC_CONTACT_LIST_READY,
  CLINIC_DELETE_CONTACT_STARTED,
  CLINIC_DELETE_CONTACT_SUCCESS,
  CLINIC_DELETE_CONTACT_FAILURE,
  CLINIC_MVL_REVOKE_ACCESS,
  CLINIC_MVL_GRANT_ACCESS,
  //Home
  CLINIC_REFRESH_HOME_LIST_REQUEST,
  CLINIC_REFRESH_HOME_LIST_STARTED,
  CLINIC_REFRESH_HOME_LIST_SUCCESS,
  CLINIC_REFRESH_HOME_LIST_FAILURE,
  //Animal
  CLINIC_ANIMAL_LIST_UPDATE,
  // Analytics
  CLINIC_TRACK_DOCUMENT_SIGNING,
  // Certificates Search
  CLINIC_CERTIFICATES_SEARCH_REQUEST,
  CLINIC_CERTIFICATES_SEARCH_SUCCESS,
  CLINIC_CERTIFICATES_SEARCH_FAILURE,
  CLINIC_CERTIFICATES_SEARCH_CLEAR,

  // Create Document from Certificate
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST,
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS,
  CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE,

  // Generic Clinic
  CLINIC_SET_REFRESH_DOMAIN,
  CLINIC_REFRESH_DOMAIN_LIST_FAILURE
} from 'containers/accounts/clinic/store/accountsClinicConstants';

/* istanbul ignore file */

export const clinicLoadStartupDataRequest = (
  isCanadian,
  hasForSale,
  clinicId
) =>
  createAction(CLINIC_LOAD_STARTUP_DATA_REQUEST, {
    isCanadian,
    hasForSale,
    clinicId
  });
export const clinicLoadStartupDataStarted = () =>
  createAction(CLINIC_LOAD_STARTUP_DATA_STARTED);
export const clinicLoadStartupDataSuccess = startupData =>
  createAction(CLINIC_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const clinicLoadStartupDataFinished = () =>
  createAction(CLINIC_LOAD_STARTUP_DATA_FINISHED);

export const clinicRefreshVetsListSuccess = list =>
  createAction(CLINIC_REFRESH_VETS_LIST_SUCCESS, list);

// EIA

export const clinicRefreshEiaListRequest = () =>
  createAction(CLINIC_REFRESH_EIA_LIST_REQUEST);
export const clinicRefreshEiaListStarted = () =>
  createAction(CLINIC_REFRESH_EIA_LIST_STARTED);
export const clinicRefreshEiaListSuccess = (documents, requests) =>
  createAction(CLINIC_REFRESH_EIA_LIST_SUCCESS, { documents, requests });
export const clinicRefreshEiaListFailure = () =>
  createAction(CLINIC_REFRESH_EIA_LIST_FAILURE);
export const clinicActivateEia = (id, isRequest) =>
  createAction(CLINIC_ACTIVATE_EIA, { id, isRequest });
export const clinicDeactivateEia = () => createAction(CLINIC_DEACTIVATE_EIA);

export const clinicRefreshEiaRequest = (id, isRequest) =>
  createAction(CLINIC_REFRESH_EIA_REQUEST, { id, isRequest });
export const clinicRefreshEiaStarted = () =>
  createAction(CLINIC_REFRESH_EIA_STARTED);
export const clinicRefreshEiaSuccess = eia =>
  createAction(CLINIC_REFRESH_EIA_SUCCESS, { eia });
export const clinicRefreshEiaFailure = () =>
  createAction(CLINIC_REFRESH_EIA_FAILURE);

export const clinicArchiveEiasRequest = ids =>
  createAction(CLINIC_ARCHIVE_EIAS_REQUEST, { ids });
export const clinicDeleteEiasRequest = ids =>
  createAction(CLINIC_DELETE_EIAS_REQUEST, { ids });
export const clinicEiaListReady = () => createAction(CLINIC_EIA_LIST_READY);

export const clinicSaveEiaRequest = (eia, history, successMsg) =>
  createAction(CLINIC_SAVE_EIA_REQUEST, {
    eia,
    history,
    successMsg
  });
export const clinicSaveEiaSuccess = doc =>
  createAction(CLINIC_SAVE_EIA_SUCCESS, { doc });
export const clinicSaveEiaFailure = () => createAction(CLINIC_SAVE_EIA_FAILURE);

export const clinicSignEiaRequest = (
  eia,
  username,
  password,
  saveBeforeSign,
  redirectToShow,
  history,
  autoDownload,
  notifyClinic,
  notifyVet
) =>
  createAction(CLINIC_SIGN_EIA_REQUEST, {
    eia,
    username,
    password,
    saveBeforeSign,
    redirectToShow,
    history,
    autoDownload,
    notifyClinic,
    notifyVet
  });
export const clinicSignEiaSuccess = eia =>
  createAction(CLINIC_SIGN_EIA_SUCCESS, { eia });
export const clinicSignEiaFailure = () => createAction(CLINIC_SIGN_EIA_FAILURE);
export const clinicBatchSignEiaRequest = (
  readyToSignList,
  notReadyToSignList,
  username,
  password,
  autoDownload,
  notifyClinic,
  notifyVet
) =>
  createAction(CLINIC_BATCH_SIGN_EIA_REQUEST, {
    readyToSignList,
    notReadyToSignList,
    username,
    password,
    autoDownload,
    notifyClinic,
    notifyVet
  });
export const clinicBatchSignEiaSuccess = (
  signedEias,
  errorIds,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_EIA_SUCCESS, {
    signedEias,
    errorIds,
    notReadyToSignList
  });
export const clinicBatchSignEiaFailure = (
  readyToSignList,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_EIA_FAILURE, {
    readyToSignList,
    notReadyToSignList
  });
export const clinicCreateCviFromEiaRequest = eia =>
  createAction(CLINIC_CREATE_CVI_FROM_EIA_REQUEST, {
    eia
  });
export const clinicCreateCviFromEiaSuccess = cvi =>
  createAction(CLINIC_CREATE_CVI_FROM_EIA_SUCCESS, { cvi });
export const clinicCreateCviFromEiaFailure = (msgId, specificMessage) =>
  createAction(CLINIC_CREATE_CVI_FROM_EIA_FAILURE, {
    msgId,
    specificMessage
  });
export const clinicAutoArchiveEiaRequest = id =>
  createAction(CLINIC_AUTO_ARCHIVE_EIA_REQUEST, { id });
export const clinicAutoArchiveEiaSuccess = id =>
  createAction(CLINIC_AUTO_ARCHIVE_EIA_SUCCESS, { id });
export const clinicAddRequiredEiaCountyRequest = locations =>
  createAction(CLINIC_ADD_REQUIRED_EIA_COUNTY_REQUEST, { locations });
export const clinicAddRequiredEiaCountySuccess = () =>
  createAction(CLINIC_ADD_REQUIRED_EIA_COUNTY_SUCCESS);
export const clinicAddRequiredEiaCountyFailed = () =>
  createAction(CLINIC_ADD_REQUIRED_EIA_COUNTY_FAILED);
// Contact
export const clinicRefreshContactListRequest = () =>
  createAction(CLINIC_REFRESH_CONTACT_LIST_REQUEST);
export const clinicRefreshContactListStarted = () =>
  createAction(CLINIC_REFRESH_CONTACT_LIST_STARTED);
export const clinicRefreshContactListSuccess = list =>
  createAction(CLINIC_REFRESH_CONTACT_LIST_SUCCESS, { list });
export const clinicRefreshContactListFailure = () =>
  createAction(CLINIC_REFRESH_CONTACT_LIST_FAILURE);
export const clinicRefreshContactRequest = contactId =>
  createAction(CLINIC_REFRESH_CONTACT_REQUEST, { contactId });
export const clinicRefreshContactSuccess = contact =>
  createAction(CLINIC_REFRESH_CONTACT_SUCCESS, { contact });

export const clinicRemoveEiaFromStore = id =>
  createAction(CLINIC_REMOVE_EIA_FROM_STORE, { id });
export const clinicDeleteContactStarted = () =>
  createAction(CLINIC_DELETE_CONTACT_STARTED);
export const clinicDeleteContactRequest = contactIds =>
  createAction(CLINIC_DELETE_CONTACT_REQUEST, { contactIds });
export const clinicContactRemovedFromList = id =>
  createAction(CLINIC_CONTACT_REMOVED_FROM_LIST, { id });
export const clinicContactListReady = () =>
  createAction(CLINIC_CONTACT_LIST_READY);
export const clinicDeleteContactSuccess = contactIds =>
  createAction(CLINIC_DELETE_CONTACT_SUCCESS, { contactIds });
export const clinicDeleteContactFailure = () =>
  createAction(CLINIC_DELETE_CONTACT_FAILURE);

// CVI

export const clinicRefreshCviListRequest = () =>
  createAction(CLINIC_REFRESH_CVI_LIST_REQUEST);
export const clinicRefreshCviListStarted = () =>
  createAction(CLINIC_REFRESH_CVI_LIST_STARTED);
export const clinicRefreshCviListSuccess = lists =>
  createAction(CLINIC_REFRESH_CVI_LIST_SUCCESS, lists);
export const clinicRefreshCviListFailure = () =>
  createAction(CLINIC_REFRESH_CVI_LIST_FAILURE);

export const clinicActivateCvi = id =>
  createAction(CLINIC_ACTIVATE_CVI, { id });
export const clinicDeactivateCvi = () => createAction(CLINIC_DEACTIVATE_CVI);

export const clinicRefreshCviRequest = id =>
  createAction(CLINIC_REFRESH_CVI_REQUEST, { id });
export const clinicRefreshCviStarted = () =>
  createAction(CLINIC_REFRESH_CVI_STARTED);
export const clinicRefreshCviSuccess = cvi =>
  createAction(CLINIC_REFRESH_CVI_SUCCESS, { cvi });
export const clinicRefreshCviFailure = () =>
  createAction(CLINIC_REFRESH_CVI_FAILURE);

export const clinicSaveCviRequest = (cvi, history, isAutosave, afterSaved) =>
  createAction(CLINIC_SAVE_CVI_REQUEST, {
    cvi,
    history,
    isAutosave,
    afterSaved
  });

export const clinicCviAddPermitRequest = (id, permitNumber) =>
  createAction(CLINIC_CVI_ADD_PERMIT_REQUEST, { id, permitNumber });
export const clinicCviAddPermitSuccess = cvi =>
  createAction(CLINIC_CVI_ADD_PERMIT_SUCCESS, { cvi });

export const clinicSignCviRequest = (
  cvi,
  username,
  password,
  showImages,
  saveBeforeSign,
  redirectToShow,
  history
) =>
  createAction(CLINIC_SIGN_CVI_REQUEST, {
    cvi,
    username,
    password,
    saveBeforeSign,
    showImages,
    redirectToShow,
    history
  });

export const clinicSignCviSuccess = cvi =>
  createAction(CLINIC_SIGN_CVI_SUCCESS, { cvi });

export const clinicSignCviFailure = () => createAction(CLINIC_SIGN_CVI_FAILURE);

export const clinicBatchSignCviRequest = (
  readyToSignList,
  notReadyToSignList,
  username,
  password,
  showImages
) =>
  createAction(CLINIC_BATCH_SIGN_CVI_REQUEST, {
    readyToSignList,
    notReadyToSignList,
    username,
    password,
    showImages
  });
export const clinicBatchSignCviSuccess = (
  signedCvis,
  errorIds,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_CVI_SUCCESS, {
    signedCvis,
    errorIds,
    notReadyToSignList
  });
export const clinicBatchSignCviFailure = (
  readyToSignList,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_CVI_FAILURE, {
    readyToSignList,
    notReadyToSignList
  });

export const clinicDeleteCviRequest = (id, history) =>
  createAction(CLINIC_DELETE_CVI_REQUEST, { id, history });
export const clinicDeleteCviFailure = () =>
  createAction(CLINIC_DELETE_CVI_FAILURE);

export const clinicArchiveCvisRequest = ids =>
  createAction(CLINIC_ARCHIVE_CVIS_REQUEST, { ids });
export const clinicDeleteCvisRequest = ids =>
  createAction(CLINIC_DELETE_CVIS_REQUEST, { ids });
export const clinicCviListReady = () => createAction(CLINIC_CVI_LIST_READY);

//EECVIS
export const clinicRefreshEecviListRequest = () =>
  createAction(CLINIC_REFRESH_EECVI_LIST_REQUEST);
export const clinicRefreshEecviListStarted = () =>
  createAction(CLINIC_REFRESH_EECVI_LIST_STARTED);
export const clinicRefreshEecviListSuccess = lists =>
  createAction(CLINIC_REFRESH_EECVI_LIST_SUCCESS, lists);
export const clinicRefreshEecviListFailure = () =>
  createAction(CLINIC_REFRESH_EECVI_LIST_FAILURE);
export const clinicEecviListReady = () => createAction(CLINIC_EECVI_LIST_READY);
export const clinicArchiveEecvisRequest = ids =>
  createAction(CLINIC_ARCHIVE_EECVIS_REQUEST, { ids });
export const clinicDeleteEecvisRequest = (ids, history) =>
  createAction(CLINIC_DELETE_EECVIS_REQUEST, { ids, history });
export const clinicSaveEecviRequest = (doc, history, successMsg) =>
  createAction(CLINIC_SAVE_EECVI_REQUEST, {
    doc,
    history,
    successMsg
  });
export const clinicCommitEecviRequest = (doc, history) =>
  createAction(CLINIC_COMMIT_EECVI_REQUEST, { doc, history });
export const clinicCommitEecviFailure = (
  error,
  doc,
  history,
  title,
  notificationType
) =>
  createAction(CLINIC_COMMIT_EECVI_FAILURE, {
    error,
    doc,
    history,
    title,
    notificationType
  });
export const clinicBatchCommitEecviRequest = readyToCommitList =>
  createAction(CLINIC_BATCH_COMMIT_EECVI_REQUEST, { readyToCommitList });
export const clinicActivateEecvi = id =>
  createAction(CLINIC_ACTIVATE_EECVI, { id });
export const clinicDeactivateEecvi = () =>
  createAction(CLINIC_DEACTIVATE_EECVI);
export const clinicRefreshEecviRequest = id =>
  createAction(CLINIC_REFRESH_EECVI_REQUEST, { id });
export const clinicRefreshEecviStarted = () =>
  createAction(CLINIC_REFRESH_EECVI_STARTED);
export const clinicRefreshEecviSuccess = docs =>
  createAction(CLINIC_REFRESH_EECVI_SUCCESS, docs);
export const clinicRefreshEecviFailure = () =>
  createAction(CLINIC_REFRESH_EECVI_FAILURE);

// VFDS

export const clinicRefreshVfdsListRequest = () =>
  createAction(CLINIC_REFRESH_VFDS_LIST_REQUEST);
export const clinicRefreshVfdsListStarted = () =>
  createAction(CLINIC_REFRESH_VFDS_LIST_STARTED);
export const clinicRefreshVfdsListSuccess = (documents, requests) =>
  createAction(CLINIC_REFRESH_VFDS_LIST_SUCCESS, { documents, requests });
export const clinicRefreshVfdsListFailure = () =>
  createAction(CLINIC_REFRESH_VFDS_LIST_FAILURE);
export const clinicActivateVfd = (id, isRequest) =>
  createAction(CLINIC_ACTIVATE_VFD, { id, isRequest });
export const clinicDeactivateVfd = () => createAction(CLINIC_DEACTIVATE_VFD);
export const clinicRefreshVfdRequest = (id, isRequest) =>
  createAction(CLINIC_REFRESH_VFD_REQUEST, { id, isRequest });
export const clinicRefreshVfdStarted = () =>
  createAction(CLINIC_REFRESH_VFD_STARTED);
export const clinicRefreshVfdFailure = () =>
  createAction(CLINIC_REFRESH_VFD_FAILURE);
export const clinicSaveVfdRequest = (vfd, history, successMsg) =>
  createAction(CLINIC_SAVE_VFD_REQUEST, {
    vfd,
    history,
    successMsg
  });
export const clinicArchiveVfdsRequest = ids =>
  createAction(CLINIC_ARCHIVE_VFDS_REQUEST, { ids });
export const clinicDeleteVfdsRequest = (ids, history) =>
  createAction(CLINIC_DELETE_VFDS_REQUEST, { ids, history });
export const clinicSaveVfdSuccess = (vfd, successMsg) =>
  createAction(CLINIC_SAVE_VFD_SUCCESS, { vfd, successMsg });
export const clinicSaveVfdFailure = msgId =>
  createAction(CLINIC_SAVE_VFD_FAILURE, { msgId });
export const clinicProcessVfdsRequest = (ids, vfdMethod, history) =>
  createAction(CLINIC_PROCESS_VFDS_REQUEST, { ids, vfdMethod, history });
export const clinicProcessVfdsRenewalReject = ids =>
  createAction(CLINIC_PROCESS_VFDS_RENEWAL_REJECT, { ids });
export const clinicProcessVfdsRenewalAccept = (ids, credentials, modal) =>
  createAction(CLINIC_PROCESS_VFDS_RENEWAL_ACCEPT, {
    ids,
    credentials,
    modal
  });
export const clinicProcessVfdRenewalReview = (id, data) =>
  createAction(CLINIC_PROCESS_VFD_RENEWAL_REVIEW, { id, data });
export const clinicSignVfdRequest = (id, username, password, history, modal) =>
  createAction(CLINIC_SIGN_VFD_REQUEST, {
    id,
    username,
    password,
    history,
    modal
  });
export const clinicVfdListReady = () => createAction(CLINIC_VFD_LIST_READY);

// Rabies
export const clinicRefreshRabiesListRequest = () =>
  createAction(CLINIC_REFRESH_RABIES_LIST_REQUEST);
export const clinicRefreshRabiesListStarted = () =>
  createAction(CLINIC_REFRESH_RABIES_LIST_STARTED);
export const clinicRefreshRabiesListSuccess = documents =>
  createAction(CLINIC_REFRESH_RABIES_LIST_SUCCESS, { documents });
export const clinicRefreshRabiesListFailure = () =>
  createAction(CLINIC_REFRESH_RABIES_LIST_FAILURE);
export const clinicRabiesListReady = () =>
  createAction(CLINIC_RABIES_LIST_READY);
export const clinicActivateRabies = id =>
  createAction(CLINIC_ACTIVATE_RABIES, { id });
export const clinicRefreshRabiesRequest = id =>
  createAction(CLINIC_REFRESH_RABIES_REQUEST, { id });
export const clinicRefreshRabiesStarted = () =>
  createAction(CLINIC_REFRESH_RABIES_STARTED);
export const clinicRefreshRabiesSuccess = docs =>
  createAction(CLINIC_REFRESH_RABIES_SUCCESS, { docs });
export const clinicRefreshRabiesFailure = () =>
  createAction(CLINIC_REFRESH_RABIES_FAILURE);
export const clinicSaveRabiesRequest = (doc, history, successMsg, onSuccess) =>
  createAction(CLINIC_SAVE_RABIES_REQUEST, {
    doc,
    history,
    successMsg,
    onSuccess
  });
export const clinicSignRabiesRequest = (doc, credentials, modal, history) =>
  createAction(CLINIC_SIGN_RABIES_REQUEST, {
    doc,
    credentials,
    modal,
    history
  });
export const clinicSignRabiesSuccess = rabies =>
  createAction(CLINIC_SIGN_RABIES_SUCCESS, { rabies });
export const clinicSignRabiesFailure = (error, doc, history) =>
  createAction(CLINIC_SIGN_RABIES_FAILURE, { error, doc, history });
export const clinicDeleteRabiesRequest = (ids, history) =>
  createAction(CLINIC_DELETE_RABIES_REQUEST, { ids, history });
export const clinicBatchDeleteRabiesRequest = ids =>
  createAction(CLINIC_BATCH_DELETE_RABIES_REQUEST, { ids });
export const clinicArchiveRabiesRequest = ids =>
  createAction(CLINIC_ARCHIVE_RABIES_REQUEST, { ids });
export const clinicBatchSignRabiesRequest = (
  readyToSignList,
  notReadyToSignList,
  credentials,
  modal,
  history
) =>
  createAction(CLINIC_BATCH_SIGN_RABIES_REQUEST, {
    readyToSignList,
    notReadyToSignList,
    credentials,
    modal,
    history
  });
export const clinicBatchSignRabiesFailure = (
  readyToSignList,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_RABIES_FAILURE, {
    readyToSignList,
    notReadyToSignList
  });
export const clinicBatchSignRabiesSuccess = (
  signedRabies,
  errorIds,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_RABIES_SUCCESS, {
    signedRabies,
    errorIds,
    notReadyToSignList
  });

// Ihc
export const clinicRefreshIhcListRequest = () =>
  createAction(CLINIC_REFRESH_IHC_LIST_REQUEST);
export const clinicRefreshIhcListStarted = () =>
  createAction(CLINIC_REFRESH_IHC_LIST_STARTED);
export const clinicRefreshIhcListSuccess = documents =>
  createAction(CLINIC_REFRESH_IHC_LIST_SUCCESS, { documents });
export const clinicRefreshIhcListFailure = () =>
  createAction(CLINIC_REFRESH_IHC_LIST_FAILURE);
export const clinicIhcListReady = () => createAction(CLINIC_IHC_LIST_READY);
export const clinicIhcRemovedFromList = id =>
  createAction(CLINIC_IHC_REMOVED_FROM_LIST, { id });
export const clinicActivateIhc = id =>
  createAction(CLINIC_ACTIVATE_IHC, { id });
export const clinicRefreshIhcRequest = id =>
  createAction(CLINIC_REFRESH_IHC_REQUEST, { id });
export const clinicRefreshIhcStarted = () =>
  createAction(CLINIC_REFRESH_IHC_STARTED);
export const clinicRefreshIhcSuccess = docs =>
  createAction(CLINIC_REFRESH_IHC_SUCCESS, { docs });
export const clinicRefreshIhcFailure = () =>
  createAction(CLINIC_REFRESH_IHC_FAILURE);
export const clinicSaveIhcRequest = (doc, history, successMsg, onSuccess) =>
  createAction(CLINIC_SAVE_IHC_REQUEST, {
    doc,
    history,
    successMsg,
    onSuccess
  });
export const clinicSignIhcRequest = (doc, credentials, modal, history) =>
  createAction(CLINIC_SIGN_IHC_REQUEST, {
    doc,
    credentials,
    modal,
    history
  });
export const clinicSignIhcSuccess = ihc =>
  createAction(CLINIC_SIGN_IHC_SUCCESS, { ihc });
export const clinicSignIhcFailure = (error, doc, history) =>
  createAction(CLINIC_SIGN_IHC_FAILURE, { error, doc, history });
export const clinicDeleteIhcRequest = (ids, history) =>
  createAction(CLINIC_DELETE_IHC_REQUEST, { ids, history });
export const clinicCreateCviFromIhcRequest = (
  form,
  doc,
  history,
  cviWorkflow
) =>
  createAction(CLINIC_CREATE_CVI_FROM_IHC_REQUEST, {
    form,
    doc,
    history,
    cviWorkflow
  });
export const clinicBatchDeleteIhcRequest = ids =>
  createAction(CLINIC_BATCH_DELETE_IHC_REQUEST, { ids });
export const clinicArchiveIhcRequest = ids =>
  createAction(CLINIC_ARCHIVE_IHC_REQUEST, { ids });
export const clinicBatchSignIhcRequest = (
  readyToSignList,
  notReadyToSignList,
  credentials,
  modal,
  history
) =>
  createAction(CLINIC_BATCH_SIGN_IHC_REQUEST, {
    readyToSignList,
    notReadyToSignList,
    credentials,
    modal,
    history
  });
export const clinicBatchSignIhcFailure = (
  readyToSignList,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_IHC_FAILURE, {
    readyToSignList,
    notReadyToSignList
  });
export const clinicBatchSignIhcSuccess = (
  signedIhcs,
  errorIds,
  notReadyToSignList
) =>
  createAction(CLINIC_BATCH_SIGN_IHC_SUCCESS, {
    signedIhcs,
    errorIds,
    notReadyToSignList
  });

// generic Document

export const clinicClearDocumentErrors = docType =>
  createAction(CLINIC_CLEAR_DOCUMENT_ERRORS, { docType });
export const clinicVoidDocumentRequest = (serialNumber, reason) =>
  createAction(CLINIC_VOID_DOCUMENT_REQUEST, {
    serialNumber,
    reason
  });
export const clinicVoidDocumentSuccess = voidedDocument =>
  createAction(CLINIC_VOID_DOCUMENT_SUCCESS, { voidedDocument });
export const clinicVoidDocumentFailure = (
  msgId,
  specificDescription,
  specificMessage
) =>
  createAction(CLINIC_VOID_DOCUMENT_FAILURE, {
    msgId,
    specificDescription,
    specificMessage
  });
export const clinicSaveDocSuccess = doc =>
  createAction(CLINIC_SAVE_DOC_SUCCESS, { doc });
export const clinicRefreshDocSuccess = doc =>
  createAction(CLINIC_REFRESH_DOC_SUCCESS, { doc });
export const clinicRemoveDocSuccess = (id, docType) =>
  createAction(CLINIC_REMOVE_DOC_SUCCESS, { id, docType });
export const clinicLockDocRequest = (doc, onSuccess) =>
  createAction(CLINIC_LOCK_DOC_REQUEST, { doc, onSuccess });
export const clinicUnlockDocRequest = (doc, onSuccess) =>
  createAction(CLINIC_UNLOCK_DOC_REQUEST, { doc, onSuccess });
export const clinicDocRequestDone = () => createAction(CLINIC_DOC_REQUEST_DONE);
export const clinicCopyDocRequest = doc =>
  createAction(CLINIC_COPY_DOC_REQUEST, { doc });

//Animal
export const clinicRefreshAnimalListRequest = cb =>
  createAction(CLINIC_REFRESH_ANIMAL_LIST_REQUEST, { cb });
export const clinicRefreshAnimalListStarted = () =>
  createAction(CLINIC_REFRESH_ANIMAL_LIST_STARTED);
export const clinicRefreshAnimalListSuccess = animal =>
  createAction(CLINIC_REFRESH_ANIMAL_LIST_SUCCESS, animal);
export const clinicRefreshAnimalListFailure = () =>
  createAction(CLINIC_REFRESH_ANIMAL_LIST_FAILURE);
export const clinicVerboseAnimalListRequest = (params, cb) =>
  createAction(CLINIC_VERBOSE_ANIMAL_LIST_REQUEST, { params, cb });
export const clinicVerboseAnimalListSuccess = fullAnimals =>
  createAction(CLINIC_VERBOSE_ANIMAL_LIST_SUCCESS, fullAnimals);
export const clinicRefreshAnimalRequest = animalId =>
  createAction(CLINIC_REFRESH_ANIMAL_REQUEST, { animalId });
export const clinicRefreshAnimalSuccess = animal =>
  createAction(CLINIC_REFRESH_ANIMAL_SUCCESS, { animal });
export const clinicAnimalListReady = () =>
  createAction(CLINIC_ANIMAL_LIST_READY);
export const clinicAnimalRemovedFromList = (id, isPartial) =>
  createAction(CLINIC_ANIMAL_REMOVED_FROM_LIST, { id, isPartial });
export const clinicDeleteAnimalRequest = (
  animalIds,
  isPartial,
  showNotification
) =>
  createAction(CLINIC_DELETE_ANIMAL_REQUEST, {
    animalIds,
    isPartial,
    showNotification
  });
export const clinicDeleteAnimalStarted = () =>
  createAction(CLINIC_DELETE_ANIMAL_STARTED);
export const clinicDeleteAnimalSuccess = animalIds =>
  createAction(CLINIC_DELETE_ANIMAL_SUCCESS, { animalIds });
export const clinicDeleteAnimalFailure = () =>
  createAction(CLINIC_DELETE_ANIMAL_FAILURE);
export const clinicAnimalUpdatedSuccess = animal =>
  createAction(CLINIC_ANIMAL_UPDATED_SUCCESS, { animal });
export const clinicAnimalClaimOwnershipRequest = (animal, ownerId) =>
  createAction(CLINIC_ANIMAL_CLAIM_OWNERSHIP_REQUEST, { animal, ownerId });
export const clinicAnimalReleaseOwnershipRequest = animal =>
  createAction(CLINIC_ANIMAL_RELEASE_OWNERSHIP_REQUEST, { animal });

//Image
export const clinicRefreshImageListRequest = () =>
  createAction(CLINIC_REFRESH_IMAGE_LIST_REQUEST);
export const clinicRefreshImageListStarted = () =>
  createAction(CLINIC_REFRESH_IMAGE_LIST_STARTED);
export const clinicRefreshImageListSuccess = images =>
  createAction(CLINIC_REFRESH_IMAGE_LIST_SUCCESS, images);
export const clinicRefreshImageListFailure = () =>
  createAction(CLINIC_REFRESH_IMAGE_LIST_FAILURE);

export const clinicImageUpdateRequest = (
  id,
  description,
  tags,
  label,
  base64
) =>
  createAction(CLINIC_IMAGE_UPDATE_REQUEST, {
    id,
    description,
    tags,
    label,
    base64
  });

export const clinicImageUpdateSuccess = images =>
  createAction(CLINIC_IMAGE_UPDATE_SUCCESS, images);
export const clinicImageDeleteRequest = (id, forceDelete) =>
  createAction(CLINIC_IMAGE_DELETE_REQUEST, { id, forceDelete });
export const clinicImageDeleteSuccess = id =>
  createAction(CLINIC_IMAGE_DELETE_SUCCESS, { id });

// Document Model Collections
export const clinicResetDocumentModels = (docType, id) =>
  createAction(CLINIC_RESET_DOCUMENT_MODELS, { docType, id });
export const clinicGetContactRequest = (
  id,
  forceApiCall,
  callback,
  getAllPremises
) =>
  createAction(CLINIC_GET_CONTACT_REQUEST, {
    id,
    forceApiCall,
    callback,
    getAllPremises
  });
export const clinicGetPremisesRequest = (id, forceApiCall, callback) =>
  createAction(CLINIC_GET_PREMISES_REQUEST, {
    id,
    forceApiCall,
    callback
  });
export const clinicContactUpdate = contact =>
  createAction(CLINIC_CONTACT_UPDATE, { contact });
export const clinicPremisesUpdate = premises =>
  createAction(CLINIC_PREMISES_UPDATE, { premises });
export const clinicFetchCarriersRequest = carrierClass =>
  createAction(CLINIC_FETCH_CARRIERS_REQUEST, { carrierClass });
export const clinicFetchCarriersSuccess = carriers =>
  createAction(CLINIC_FETCH_CARRIERS_SUCCESS, { carriers });
export const clinicFetchCarriersFailure = () =>
  createAction(CLINIC_FETCH_CARRIERS_FAILURE);
export const clinicSEVerifyPartialDocRequest = partialDoc =>
  createAction(CLINIC_SE_VERIFY_PARTIAL_DOC_REQUEST, { partialDoc });
export const clinicSEVerifyPartialDocSuccess = result =>
  createAction(CLINIC_SE_VERIFY_PARTIAL_DOC_SUCCESS, { result });
export const clinicSEVerifyPartialDocFailure = () =>
  createAction(CLINIC_SE_VERIFY_PARTIAL_DOC_FAILURE);

// Requests
export const clinicRejectDocumentRequest = (docType, id, reason) =>
  createAction(CLINIC_REJECT_DOCUMENT_REQUEST, { docType, id, reason });
export const clinicRequestRemovedFromList = (docType, id) =>
  createAction(CLINIC_REQUEST_REMOVED_FROM_LIST, { docType, id });

//Home
export const clinicRefreshHomeListRequest = params =>
  createAction(CLINIC_REFRESH_HOME_LIST_REQUEST, { params });
export const clinicRefreshHomeListStarted = () =>
  createAction(CLINIC_REFRESH_HOME_LIST_STARTED);
export const clinicRefreshHomeListSuccess = list =>
  createAction(CLINIC_REFRESH_HOME_LIST_SUCCESS, { list });
export const clinicRefreshHomeListFailure = () =>
  createAction(CLINIC_REFRESH_HOME_LIST_FAILURE);

//Animal
export const clinicAnimalListUpdate = animal =>
  createAction(CLINIC_ANIMAL_LIST_UPDATE, { animal });

// Analytics
export const clinicTrackDocumentSigning = (ids, docType) =>
  createAction(CLINIC_TRACK_DOCUMENT_SIGNING, { ids, docType });

// Certificates Search
export const clinicCertificatesSearchRequest = (searchType, params) =>
  createAction(CLINIC_CERTIFICATES_SEARCH_REQUEST, {
    searchType,
    params
  });
export const clinicCertificatesSearchSuccess = (
  results,
  voidedCertificateCount
) =>
  createAction(CLINIC_CERTIFICATES_SEARCH_SUCCESS, {
    results,
    voidedCertificateCount
  });
export const clinicCertificatesSearchFailure = () =>
  createAction(CLINIC_CERTIFICATES_SEARCH_FAILURE);
export const clinicCertificatesSearchClear = () =>
  createAction(CLINIC_CERTIFICATES_SEARCH_CLEAR);

export const clinicMvlRevokeAccess = (contacts, cb) =>
  createAction(CLINIC_MVL_REVOKE_ACCESS, { contacts, cb });
export const clinicMvlGrantAccess = (contacts, cb) =>
  createAction(CLINIC_MVL_GRANT_ACCESS, { contacts, cb });

// Create Document from Certificate
export const clinicCreateDocumentFromCertificateRequest = certificate =>
  createAction(CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_REQUEST, {
    certificate
  });
export const clinicCreateDocumentFromCertificateSuccess = createdDocument =>
  createAction(CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_SUCCESS, {
    createdDocument
  });
export const clinicCreateDocumentFromCertificateFailure = description =>
  createAction(CLINIC_CREATE_DOCUMENT_FROM_CERTIFICATE_FAILURE, {
    description
  });

// Generic Clinic
export const clinicSetRefreshDomain = (domain, value) =>
  createAction(CLINIC_SET_REFRESH_DOMAIN, { domain, value });
export const clinicRefreshDomainListFailure = domain =>
  createAction(CLINIC_REFRESH_DOMAIN_LIST_FAILURE, { domain });
