export const REGULATORY_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_STARTUP_DATA_REQUEST';
export const REGULATORY_LOAD_STARTUP_DATA_STARTED =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_STARTUP_DATA_STARTED';
export const REGULATORY_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_STARTUP_DATA_SUCCESS';
export const REGULATORY_LOAD_STARTUP_DATA_FINISHED =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_STARTUP_DATA_FINISHED';

export const REGULATORY_LOAD_SETTINGS_DATA_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_SETTINGS_DATA_REQUEST';
export const REGULATORY_LOAD_SETTINGS_DATA_STARTED =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_SETTINGS_DATA_STARTED';
export const REGULATORY_LOAD_SETTINGS_DATA_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_SETTINGS_DATA_SUCCESS';
export const REGULATORY_LOAD_SETTINGS_DATA_FINISHED =
  'cricket/Accounts/Regulatory/REGULATORY_LOAD_SETTINGS_DATA_FINISHED';

export const REGULATORY_SETTINGS_DATA_SAVE_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_SETTINGS_DATA_SAVE_REQUEST';
export const REGULATORY_SETTINGS_DATA_SAVE_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_SETTINGS_DATA_SAVE_SUCCESS';

//Rules
export const REGULATORY_REFRESH_RULE_LIST_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_REFRESH_RULE_LIST_REQUEST';
export const REGULATORY_REFRESH_RULE_LIST_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_REFRESH_RULE_LIST_SUCCESS';
export const REGULATORY_REFRESH_RULE_LIST_FAILURE =
  'cricket/Accounts/Regulatory/REGULATORY_REFRESH_RULE_LIST_FAILURE';
export const REGULATORY_RULE_STATUS_UPDATE_REQUEST =
  'cricket/Accounts/REGULATORY_RULE_STATUS_UPDATE_REQUEST';
export const REGULATORY_RULE_ADD_FEEDBACK_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_RULE_ADD_FEEDBACK_REQUEST';

//Certificates
export const REGULATORY_SEARCH_CERTIFICATES_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_SEARCH_CERTIFICATES_REQUEST';
export const REGULATORY_SEARCH_CERTIFICATES_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_SEARCH_CERTIFICATES_SUCCESS';
export const REGULATORY_SEARCH_CERTIFICATES_FAILURE =
  'cricket/Accounts/Regulatory/REGULATORY_SEARCH_CERTIFICATES_FAILURE';

// EECVI searching
export const REGULATORY_SEARCH_EECVI_PERMITS =
  'cricket/Accounts/Regulatory/REGULATORY_SEARCH_EECVI_PERMITS';
export const REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_SEARCH_EECVI_PERMITS_SUCCESS';
export const REGULATORY_CLEAR_SEARCH_EECVI_PERMITS =
  'cricket/Accounts/Regulatory/REGULATORY_CLEAR_SEARCH_EECVI_PERMITS';
export const REGULATORY_VIEW_EECVI_ITINERARY =
  'cricket/Accounts/Regulatory/REGULATORY_VIEW_EECVI_ITINERARY';
export const REGULATORY_CLOSE_EECVI_ITINERARY =
  'cricket/Accounts/Regulatory/REGULATORY_CLOSE_EECVI_ITINERARY';
export const REGULATORY_FETCH_EECVI_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_FETCH_EECVI_SUCCESS';
export const REGULATORY_FETCH_EECVI_FAILURE =
  'cricket/Accounts/Regulatory/REGULATORY_FETCH_EECVI_FAILURE';

// SmartEngine
export const REGULATORY_VERIFY_CERTIFICATE_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_VERIFY_CERTIFICATE_REQUEST';
export const REGULATORY_VERIFY_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_VERIFY_CERTIFICATE_SUCCESS';
export const REGULATORY_CLOSE_SMART_ENGINE_MODAL =
  'cricket/Accounts/Regulatory/REGULATORY_CLOSE_SMART_ENGINE_MODAL';

export const REGULATORY_SEARCH_TESTS =
  'cricket/Accounts/Distributor/REGULATORY_SEARCH_TESTS';
export const REGULATORY_SEARCH_TESTS_SUCCESS =
  'cricket/Accounts/Distributor/REGULATORY_SEARCH_TESTS_SUCCESS';
export const REGULATORY_CLEAR_TESTS_SEARCH =
  'cricket/Accounts/Distributor/REGULATORY_CLEAR_TESTS_SEARCH';

// Out of sate lookup
export const REGULATORY_DOCUMENT_LOOKUP_REQUEST =
  'cricket/Accounts/Clinic/REGULATORY_DOCUMENT_LOOKUP_REQUEST';
export const REGULATORY_DOCUMENT_LOOKUP_SUCCESS =
  'cricket/Accounts/Clinic/REGULATORY_DOCUMENT_LOOKUP_SUCCESS';
export const REGULATORY_DOCUMENT_LOOKUP_FAILURE =
  'cricket/Accounts/Clinic/REGULATORY_DOCUMENT_LOOKUP_FAILURE';

// Weekly certificate reports
export const REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST =
  'cricket/Accounts/Regulatory/REGULATORY_WEEKLY_CERTIFICATES_REPORTS_REQUEST';
export const REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS =
  'cricket/Accounts/Regulatory/REGULATORY_WEEKLY_CERTIFICATES_REPORTS_SUCCESS';
export const REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE =
  'cricket/Accounts/Regulatory/REGULATORY_WEEKLY_CERTIFICATES_REPORTS_FAILURE';
