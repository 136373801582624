// import * as nonAdjacentHerdRequestActions from './store/nonAdjacentHerdRequestActions';
// import * as nonAdjacentHerdRequestReducer from './store/nonAdjacentHerdRequestReducers';
import NonAdjacentHerdRequestModel from './NonAdjacentHerdRequest';
import NonAdjacentClinicShowCardComponent from './components/NonAdjacentClinicShowCard';
import NonAdjacentProductShowCardComponent from './components/NonAdjacentProductShowCard';
import NonAdjacentIsolateTablesComponent from './components/NonAdjacentIsolateTables';
import NonAdjacentHerdListTableComponent from './components/NonAdjacentHerdListTable';
import NonAdjacentStateApprovalsTableComponent from './components/NonAdjacentStateApprovalsTable';
import NonAdjacentVetAssessmentShowCardComponent from './components/NonAdjacentVetAssessmentShowCard';
import NonAdjacentSendBackModalComponent from './components/NonAdjacentSendBackModal';
import AlternateClinicAddressModalComponent from './components/AlternateClinicAddressModal';

// export default {
//   nonAdjacentHerdRequestActions,
//   nonAdjacentHerdRequestReducer
// };

// model
export const NonAdjacentHerdRequest = NonAdjacentHerdRequestModel;
// components
export const NonAdjacentClinicShowCard = NonAdjacentClinicShowCardComponent;
export const NonAdjacentProductShowCard = NonAdjacentProductShowCardComponent;
export const NonAdjacentIsolateTables = NonAdjacentIsolateTablesComponent;
export const NonAdjacentHerdListTable = NonAdjacentHerdListTableComponent;
export const NonAdjacentVetAssessmentShowCard = NonAdjacentVetAssessmentShowCardComponent;
export const NonAdjacentSendBackModal = NonAdjacentSendBackModalComponent;
export const NonAdjacentStateApprovalsTable = NonAdjacentStateApprovalsTableComponent;
export const AlternateClinicAddressModal = AlternateClinicAddressModalComponent;
