// import * as productActions from './store/productActions';
// import * as productReducer from './store/productReducers';
import ProductMangementModal from './components/ProductModal';
import ProductSearchComponent from './components/ProductSearch';
import ProductSearchFormItemComponent from './components/ProductSearchFormItem';
import ProductInlineShowComponent from './components/ProductInlineShow';
import ProductModel from './Product';

// export default {
//   productActions,
//   productReducer
// };

// model
export const Product = ProductModel;
export const ProductModal = ProductMangementModal;
export const ProductSearch = ProductSearchComponent;
export const ProductSearchFormItem = ProductSearchFormItemComponent;
export const ProductInlineShow = ProductInlineShowComponent;
