import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import intl from 'react-intl-universal'; No need to use intl as this is only applicable to USA veterinarians
import {
  Modal,
  Button,
  Input,
  Alert,
  Divider,
  Checkbox,
  Row,
  Col,
  Form
} from 'antd';
import { BACKEND_BASENAME } from 'utils/config';
import * as settingStorage from 'utils/settingStorage';
import moment from 'moment';
import GvlIcon from 'elements/GvlIcon';
import License from 'modules/License/License';
import { listRequest as licenseListRequest } from 'modules/License/store/licenseActions';
import { selectNvapLicense } from 'modules/License/store/licenseSelectors';
import * as analytics from 'utils/analytics/analytics';
import { userUpdateProfileRequest } from 'containers/app/store/user/userActions';
function NvapLink({
  children = ['National Veterinary Accreditation Program (NVAP)']
}) {
  return (
    <a
      href="https://www.aphis.usda.gov/aphis/ourfocus/animalhealth/nvap/ct_areavet"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

NvapLink.propTypes = {
  children: PropTypes.node
};

function ProfileLink({ children = ['your profile'] }) {
  return (
    <a
      href={`${BACKEND_BASENAME}/user/profile`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

ProfileLink.propTypes = {
  children: PropTypes.node
};

function NameAndNumber({ name, number }) {
  return (
    <ul>
      <li>
        <b>Last Name:</b> {name}
      </li>
      <li>
        <b>NVAP #:</b> {number}
      </li>
    </ul>
  );
}
NameAndNumber.propTypes = {
  name: PropTypes.string,
  number: PropTypes.string
};

function AlertNoneOnFile({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is missing."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, there is{' '}
            <b>no accreditation number on file with the NVAP</b>.
          </p>
          {children}
          <p>
            Please visit the <NvapLink /> to update an existing or apply for a
            new accreditation number.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertNoneOnFile.propTypes = {
  children: PropTypes.node
};

function AlertIncorrect({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is incorrect."
      description={
        <React.Fragment>
          <p>
            The information in your GVL profile does not match a valid
            accreditation number on the NVAP website.
          </p>
          {children}
          <p>
            Please visit the{' '}
            <NvapLink>
              <b>National Veterinary Accreditation Program (NVAP) website</b>
            </NvapLink>{' '}
            to verify that your information is accurate.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertIncorrect.propTypes = {
  children: PropTypes.node
};

function AlertExpired({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is expired."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP is <b>expired</b>.
          </p>
          {children}
          <p>
            Please visit the <NvapLink /> to renew your accreditation number.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertExpired.propTypes = {
  children: PropTypes.node
};

function AlertRevoked({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is revoked."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP is <b>revoked</b>.
          </p>
          {children}
          <p>
            Please visit the <NvapLink /> to renew your accreditation number.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertRevoked.propTypes = {
  children: PropTypes.node
};

function AlertSuspended({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is suspended."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP is <b>suspended</b>.
          </p>
          {children}
          <p>
            Please visit the <NvapLink /> to reinstate your accreditation
            number.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertSuspended.propTypes = {
  children: PropTypes.node
};

function AlertTerminated({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is terminated."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP is <b>terminated</b>.
          </p>
          {children}
          <p>
            Please visit the <NvapLink /> to apply for a new accreditation
            number.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertTerminated.propTypes = {
  children: PropTypes.node
};

function AlertNotApprovedForState({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is not approved for the selected origin state."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP{' '}
            <b>
              does not authorize you to sign certificates from this origin state
            </b>
            .
          </p>
          {children}
          <p>
            Contact the <NvapLink /> to learn how to become accredited in
            additional states.
          </p>
        </React.Fragment>
      }
      type="error"
      showIcon
    />
  );
}
AlertNotApprovedForState.propTypes = {
  children: PropTypes.node
};

function AlertAccredited({ children }) {
  return (
    <Alert
      message="Federal Accreditation number is approved."
      description={
        <React.Fragment>
          <p>
            Based on the information in your GVL account profile, the
            accreditation number on file with the NVAP <b>is approved</b>.
          </p>
          {children}
        </React.Fragment>
      }
      type="success"
      showIcon
    />
  );
}
AlertAccredited.propTypes = {
  children: PropTypes.node
};

function VerifyFragment({
  afterVerify,
  nvapLicense,
  form,
  vetId,
  updateUserLastName
}) {
  return (
    <Form>
      <Form.Item extra="Provided by the National Veterinary Accreditation Program (NVAP)">
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item
              style={{ marginTop: '20px', marginBottom: 0 }}
              label="Federal Accreditation number"
            >
              {form.getFieldDecorator('licenseNumber', {
                initialValue: nvapLicense?.licenseNumber,
                rules: [
                  {
                    required: true,
                    pattern: /^[0-9]{6}$/,
                    message:
                      'Enter all six digits of your accreditation number (include leading zeros)'
                  }
                ]
              })(<Input className="full-width" />)}
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              style={{ marginTop: '20px', marginBottom: 0 }}
              label="Last Name"
            >
              {form.getFieldDecorator('lastName', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Enter your last name'
                  }
                ]
              })(<Input className="full-width" />)}
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              style={{ marginTop: '20px', marginBottom: 0 }}
              label="  "
            >
              <Button
                // Could use this to show the API is chugging
                // loading={this.state.loading}
                style={{ paddingLeft: '12px' }}
                className="full-width"
                key="verify"
                onClick={() => {
                  form.validateFields(async (err, values) => {
                    if (err) return;
                    // TODO: These async/await calls should probably be redux actions/sagas/reducers... at least they're confined to a button onClick event.
                    // Save the new license via the API
                    let updatedLastName = {
                      person: {
                        lastName: values.lastName
                      }
                    };
                    if (typeof updateUserLastName === 'function')
                      updateUserLastName(updatedLastName);
                    let updatedNvapLicense = await License.updateLicense(
                      {
                        ...nvapLicense,
                        licenseNumber: values.licenseNumber
                      },
                      vetId
                    );
                    // re-validate with new license number
                    await License.checkAccreditationStatus(
                      updatedNvapLicense.id
                    );
                    if (typeof afterVerify === 'function') afterVerify();
                  });
                }}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}
VerifyFragment.propTypes = {
  afterVerify: PropTypes.func,
  form: PropTypes.object,
  nvapLicense: PropTypes.object,
  vetId: PropTypes.number.isRequired,
  updateUserLastName: PropTypes.func
};
const VerifyFragmentForm = Form.create({ name: 'verify' })(VerifyFragment);

async function determineAlertState(
  nvapLicense,
  originState,
  shouldAutoVerifyOnNullStatus,
  afterVerify
) {
  if (!nvapLicense) return 'NONE_ON_FILE';
  if (!nvapLicense.validationStatus) {
    if (shouldAutoVerifyOnNullStatus) {
      // Note: Server MUST update the validationStatus property to something not falsy as part of this API in order to prevent an infininte loop.
      await License.checkAccreditationStatus(nvapLicense.id);
      if (typeof afterVerify === 'function') afterVerify();
    }
    return 'NOT_FOUND';
  }
  if (nvapLicense.validationStatus.includes('SUSPENDED')) return 'SUSPENDED';
  if (nvapLicense.validationStatus === 'ACCREDITED') {
    if (originState && !nvapLicense.authorizedStates.includes(originState)) {
      return 'NOT_APPROVED_FOR_STATE';
    }
  }
  return nvapLicense.validationStatus;
}

function InvalidAccreditationFooter({
  onCancel,
  onContinue,
  shouldIgnoreFutureModals,
  variant,
  user
}) {
  return (
    <React.Fragment>
      <Button
        type="link"
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
      {variant === 'sign' ? null : (
        <Button
          onClick={() => {
            const category = 'NVAP_Modal';
            const action = `continue_${variant}`;
            const label = '';
            const payload = {
              vet: user.info.vetId,
              clinic: user.info.clinicId
            };
            analytics.trackEvent(category, action, label, payload);
            settingStorage.setValue(
              'nvapLicenseWarningDismissedTimestamp',
              moment()
            );
            settingStorage.setValue(
              'nvapLicenseWarningIgnored',
              shouldIgnoreFutureModals
            );

            if (shouldIgnoreFutureModals) {
              const category = 'NVAP_Modal';
              const action = 'confirmed_not_applicable';
              const label = '';
              const payload = {
                vet: user.info.vetId,
                clinic: user.info.clinicId
              };
              analytics.trackEvent(category, action, label, payload);
            }

            onContinue();
          }}
        >
          Continue without accreditation
        </Button>
      )}
      <span className="pad-1space-left">
        <NvapLink>
          <Button key="button" type="primary">
            Visit NVAP site <GvlIcon type="material-open-in-new" />
          </Button>
        </NvapLink>
      </span>
    </React.Fragment>
  );
}
InvalidAccreditationFooter.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  shouldIgnoreFutureModals: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

function ValidAccreditationFooter({ onCancel, onContinue }) {
  return (
    <React.Fragment>
      <Button type="link" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" onClick={onContinue}>
        Continue
      </Button>
    </React.Fragment>
  );
}
ValidAccreditationFooter.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func
};

function shouldShowModal(visible, variant, originalAlertState, userCountry) {
  let dismissedTimestamp = settingStorage.getValue(
    'nvapLicenseWarningDismissedTimestamp'
  );
  let warningIgnored = settingStorage.getValue('nvapLicenseWarningIgnored');

  return !(
    visible &&
    (userCountry !== 'USA' ||
      (variant === 'login' &&
        (originalAlertState === 'ACCREDITED' ||
          warningIgnored ||
          (dismissedTimestamp &&
            moment(dismissedTimestamp) >= moment().subtract(7, 'days')))))
  );
}

class NvapLicenseWarningModalComponent extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    nvapLicense: PropTypes.object,
    variant: PropTypes.string.isRequired,
    originState: PropTypes.string, // TODO: (INT-275) Maybe this should be an array type, in the case of batch signing??
    user: PropTypes.object,
    licenseListRequest: PropTypes.func.isRequired,
    userUpdateProfileRequest: PropTypes.func.isRequired
  };

  static defaultProps = {
    variant: 'sign',
    visible: false,
    onCancel: () => {},
    onContinue: () => {}
  };

  state = {
    nvapLicenseWarningDismissedTimestamp: false,
    nvapLicenseWarningIgnored: false,
    alertState: undefined
  };

  updateIntercomNvapModalReason = licenseStatus => {
    const { user, variant } = this.props;
    const category = 'NVAP_Modal';
    const action = `show_${variant}`;
    const label = '';
    const payload = {
      vet: user.info.vetId,
      clinic: user.info.clinicId,
      licenseStatus: licenseStatus
    };
    analytics.trackEvent(category, action, label, payload);
    const ic = analytics.getCommandObjects().intercom;
    if (ic) ic('update', { NVAP_modal_reason: licenseStatus });
  };

  componentDidMount() {
    const { nvapLicense, originState, user, variant, visible } = this.props;
    determineAlertState(
      nvapLicense,
      variant === 'sign' ? originState : undefined,
      true,
      () => {
        // This saga will update props and trigger componentDidUpdate to pickup the updates from License.checkAccreditationStatus in determineAlertState()
        licenseListRequest(user.info.vetId);
      }
    ).then(originalAlertState => {
      this.setState({
        alertState: originalAlertState,
        originalAlertState
      });
      if (
        shouldShowModal(
          visible,
          variant,
          originalAlertState,
          this.props.user.info.userCountry
        ) ||
        originalAlertState === 'ACCREDITED' // Update intercom for marketing and CS so they don't message people who have successfully updated thier license info
      ) {
        this.updateIntercomNvapModalReason(originalAlertState);
      }
    });
  }

  componentDidUpdate(_prevProps) {
    determineAlertState(
      this.props.nvapLicense,
      this.props.variant === 'sign' ? this.props.originState : undefined
    ).then(alertState => {
      if (this.state.alertState !== alertState) {
        this.setState({ alertState });
      }
    });
  }

  render() {
    let {
      onCancel,
      onContinue,
      variant,
      nvapLicense,
      visible,
      user,
      ...rest
    } = this.props;
    if (
      !shouldShowModal(
        visible,
        variant,
        this.state.originalAlertState,
        this.props.user.info.userCountry
      )
    ) {
      console.log(
        'userCountry !== USA OR vet accreditation is already good OR checkbox was checked OR it was dismissed less than a week ago, automatically onContinue'
      );
      onContinue();
      return null;
    }

    // feedback dynamic based on accreditation validation
    const AlertComponent = {
      NONE_ON_FILE: AlertNoneOnFile,
      NOT_FOUND: AlertIncorrect,
      EXPIRED: AlertExpired,
      REVOKED: AlertRevoked,
      SUSPENDED: AlertSuspended,
      TERMINATED: AlertTerminated,
      NOT_APPROVED_FOR_STATE: AlertNotApprovedForState,
      ACCREDITED: AlertAccredited
    }[this.state.alertState];
    const alert = AlertComponent ? (
      <AlertComponent key="alert">
        <NameAndNumber
          name={this.props.user.info.displayName}
          number={nvapLicense?.licenseNumber}
        />
      </AlertComponent>
    ) : null;

    let input = [
      'NONE_ON_FILE',
      'NOT_FOUND',
      'EXPIRED',
      'SUSPENDED',
      'NOT_APPROVED_FOR_STATE'
      // Don't bother showing the verify button for revoked and terminated, since they can't get it back anyway.
      // 'REVOKED',
      // 'TERMINATED',
    ].includes(this.state.alertState) ? (
      <VerifyFragmentForm
        key="verifyFragment"
        afterVerify={() => {
          // Refresh license in redux
          this.props.licenseListRequest(this.props.user.info.vetId);
        }}
        updateUserLastName={updatedLastName => {
          this.props.userUpdateProfileRequest(updatedLastName);
        }}
        nvapLicense={nvapLicense}
        vetId={this.props.user.info.vetId}
      />
    ) : null;

    return (
      <Modal
        {...rest}
        width={560}
        title={`Federal Accreditation ${
          variant === 'login' ? 'Improvements' : 'Check'
        }`}
        visible={visible}
        maskClosable={false}
        footer={
          this.state.alertState === 'ACCREDITED' ? (
            <ValidAccreditationFooter
              onCancel={onCancel}
              onContinue={onContinue}
            />
          ) : (
            <InvalidAccreditationFooter
              alertState={this.state.alertState}
              onCancel={onCancel}
              onContinue={onContinue}
              shouldIgnoreFutureModals={this.state.nvapLicenseWarningIgnored}
              variant={variant}
              user={user}
            />
          )
        }
        onCancel={onCancel}
      >
        {variant === 'login' ? (
          <React.Fragment>
            <p>
              We want to help you write valid certificates, and it looks like we
              don&apos;t have a Federal Accreditation number saved on your
              account.
            </p>
            <p style={{ marginBottom: '0' }}>
              A Federal Accreditation number is required to sign:
            </p>
            <ul>
              <li>CVIs</li>
              <li>EIAs</li>
              <li>EECVIs</li>
              <li>International health certificates</li>
            </ul>
          </React.Fragment>
        ) : null}

        {/* On the login variant, alert will only show if they don't have a number on file or if it's incorrect for some reason. 
        We want to explain to them why we are asking for this info, give them a space to enter their #, and then give them feedback after they verify. 
        On the sign variant, it is something that should be more in their face because what they are doing is illegal, 
        so the error messaging comes first, then in certain scenarios, we'll show the accred# field */}
        {{ login: [input, alert], sign: [alert, input] }[variant]}

        <p className="label">
          <i>
            <b>
              As of September 1, GVL requires all CVI, EIA, EECVI and
              international health documents to be signed by a federally
              accredited veterinarian. Learn more about the <NvapLink />.
            </b>
          </i>
        </p>
        {variant === 'login' ? (
          <React.Fragment>
            <Divider />
            <Row>
              <Col span={1}>
                <Checkbox
                  onChange={e => {
                    this.setState({
                      nvapLicenseWarningIgnored: e.target.checked
                    });
                  }}
                />
              </Col>
              <Col span={23}>
                I don&apos;t write any of these certificates, don&apos;t show
                this message again
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  user: state.app.user,
  nvapLicense: selectNvapLicense(state)
});

const mapDispatchToProps = {
  licenseListRequest,
  userUpdateProfileRequest
  // TODO: Move these API requests to Redux?
  // update vet last name
  // update license
  // validate license ?
};

const NvapLicenseWarningModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(NvapLicenseWarningModalComponent);

export {
  NvapLicenseWarningModal as default,
  NvapLicenseWarningModalComponent,
  NvapLink,
  ProfileLink,
  AlertNoneOnFile,
  AlertIncorrect,
  AlertExpired,
  AlertRevoked,
  AlertSuspended,
  AlertTerminated,
  AlertNotApprovedForState,
  AlertAccredited,
  InvalidAccreditationFooter,
  ValidAccreditationFooter,
  VerifyFragment,
  NameAndNumber
};
