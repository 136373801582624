import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Form, Row, Col, Radio } from 'antd';
import StateSelect from 'elements/StateSelect';
import { Location } from 'modules/Location/locationIndex';
import FormField from 'elements/FormField';
import Herd from 'modules/Herd/Herd';

const FormItem = Form.Item;

/*
 * Currently LocationFormFields supports two models: Location and Herd
 * isLocation determines which model to use, defaulting to Location.
 * If attempting to use this outside the Autogenous Vaccines app check comments below
 */
export default class LocationFormFields extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    // Expects fieldNamePrefix to be a nested model. 'submodel.' for example.
    // If you want to use a prefix but not the subFormValidator, then this will need to be refactored.
    fieldNamePrefix: PropTypes.string,
    isLocation: PropTypes.bool
  };

  static defaultProps = {
    fieldNamePrefix: '',
    isLocation: true
  };

  state = {
    hasPhysicalAddress:
      this.props.location?.locationId || this.props.location?.herdId
        ? !!this.props.location?.addressLine1
        : true,
    useSubFormValidator: this.props.fieldNamePrefix !== ''
  };

  renderNameField = () => (
    <FormField
      label={`${intl.get('site.name')}`}
      form={this.props.form}
      fieldName={this.props.fieldNamePrefix + 'name'}
      model={Location}
      isRequired={true}
      specialRules={{
        useSubFormValidator: this.state.useSubFormValidator,
        preserve: false
      }}
    />
  );

  renderLocationTypeSelector = () => (
    <FormItem
      name="hasPhysicalAddress"
      label={intl.get('site.hasPhysicalAddress')}
      required={false}
    >
      <Radio.Group
        defaultValue={this.state.hasPhysicalAddress}
        buttonStyle="solid"
        onChange={e => this.setState({ hasPhysicalAddress: e.target.value })}
      >
        <Radio value={true}>{intl.get('yes')}</Radio>
        <Radio value={false}>{intl.get('no')}</Radio>
      </Radio.Group>
    </FormItem>
  );

  renderPhysicalAddressFields = () => {
    const { form, fieldNamePrefix, isLocation } = this.props;
    const useSubFormValidator = this.state.useSubFormValidator;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <FormField
              label={intl.get('site.physical.address')}
              form={form}
              fieldName={fieldNamePrefix + 'addressLine1'}
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={14}>
            <FormField
              label={intl.get('City')}
              form={form}
              fieldName={fieldNamePrefix + 'city'}
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Col>
          <Col span={5}>
            <FormField
              label={intl.get('state')}
              form={form}
              fieldName={fieldNamePrefix + 'state'}
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
              fieldTemplate={<StateSelect />}
            />
          </Col>
          <Col span={5}>
            <FormField
              label={intl.get('postalCode')}
              form={form}
              fieldName={fieldNamePrefix + 'postalCode'}
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderLatAndLongFields = () => {
    const { form, fieldNamePrefix, isLocation } = this.props;
    return (
      <Row gutter={8}>
        <Col span={12}>
          <FormField
            label={intl.get('latitude')}
            form={form}
            fieldName={fieldNamePrefix + 'latitude'}
            model={isLocation ? Location : Herd}
            specialRules={{
              useSubFormValidator: this.state.useSubFormValidator,
              preserve: false
            }}
            isRequired={false}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={intl.get('longitude')}
            form={form}
            fieldName={fieldNamePrefix + 'longitude'}
            model={isLocation ? Location : Herd}
            specialRules={{
              useSubFormValidator: this.state.useSubFormValidator,
              preserve: false
            }}
            isRequired={false}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { form, location, fieldNamePrefix, isLocation } = this.props;
    const hasPhysicalAddress = this.state.hasPhysicalAddress;

    return (
      <div>
        {/*
         * This check will determine whether to stack the fields.
         * Currently, new locations have the two fields next to each other because it's within a card.
         * All other cases are in a modal where we want the fields stacked on one another
         */}
        {isLocation && !location?.locationId ? (
          <Row gutter={8}>
            <Col span={12}>{this.renderNameField()}</Col>
            <Col span={12}>{this.renderLocationTypeSelector()}</Col>
          </Row>
        ) : (
          <Row>
            {/* Herds do not have a site name at this time */}
            {isLocation ? this.renderNameField() : null}
            {this.renderLocationTypeSelector()}
          </Row>
        )}
        {hasPhysicalAddress
          ? this.renderPhysicalAddressFields()
          : this.renderLatAndLongFields()}
        <Row gutter={8}>
          <Col span={12}>
            <FormField
              label={intl.get('phone')}
              form={form}
              fieldName={fieldNamePrefix + 'phone'}
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: this.state.useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Col>
          <Col span={12}>
            <FormField
              label={intl.get('premiseID')}
              form={form}
              // Locations use pin, Herds use premisesId
              fieldName={
                isLocation
                  ? fieldNamePrefix + 'pin'
                  : fieldNamePrefix + 'premisesId'
              }
              model={isLocation ? Location : Herd}
              specialRules={{
                useSubFormValidator: this.state.useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Col>
        </Row>
        {/* The herd domain supports flow but currently it is not used on herd lists
         * In the future if this changes, will need a different check whether to render the flow form field
         */}
        {isLocation ? (
          <Row>
            <FormField
              label={intl.get('flow')}
              form={form}
              fieldName={fieldNamePrefix + 'description'}
              model={Location}
              specialRules={{
                useSubFormValidator: this.state.useSubFormValidator,
                preserve: false
              }}
              isRequired={false}
            />
          </Row>
        ) : null}
      </div>
    );
  }
}
