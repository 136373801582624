import { getLocalStorage } from 'utils/localStorage';

export const getValue = name => {
  try {
    const serializedState = getLocalStorage().getItem(name);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const setValue = (name, value) => {
  try {
    const serializedState = JSON.stringify(value);
    getLocalStorage().setItem(name, serializedState);
  } catch (err) {
    // ignore write errors
  }
};
