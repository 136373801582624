import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Search from 'components/GvlSearch/GvlSearch';
import {
  StyledOptionsHeader,
  StyledOptionsSubText
} from 'components/GvlSearch/commonSearchEmotions';

export default function ProductSearchComponent({ onSelect }) {
  const productOptionGenerator = results => {
    return results.map(result => (
      <Select.Option key={result.productId} value={result.productId}>
        <StyledOptionsHeader>{result.name}</StyledOptionsHeader>
        <StyledOptionsSubText>{result.clinics.join(', ')}</StyledOptionsSubText>
      </Select.Option>
    ));
  };

  return (
    <Search
      childGenerator={productOptionGenerator}
      onSelect={onSelect}
      minSearchTermLength={3}
      optionLabelProp="text"
      dataSource="autogenousVaccines/product/autocomplete"
      dropdownClassName="gvl-select-with-custom-groups"
      emptyMsg="product.missingWithName"
      emptySubMsg="product.missing.subText"
    />
  );
}

ProductSearchComponent.propTypes = {
  onSelect: PropTypes.func.isRequired
};
