import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GvlSearch from 'components/GvlSearch/GvlSearch';
import { Select } from 'antd';

import { listVetsForClinicRequest } from 'modules/Clinic/store/clinicActions';
import {
  StyledOptionsHeader,
  StyledOptionsSubText
} from 'components/GvlSearch/commonSearchEmotions';
import { NewportClinicInlineShow } from 'modules/NewportClinic/newportClinicIndex';
import { FadedText } from 'style/commonEmotions';

class NewportClinicSearchFormItemComponent extends Component {
  static propTypes = {
    listVetsForClinicRequest: PropTypes.func.isRequired,
    newportClinics: PropTypes.object.isRequired,
    clinicsVets: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    formFieldName: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func,
    selectedVets: PropTypes.array,
    allowMultipleSelections: PropTypes.bool,
    shouldRequireVets: PropTypes.bool,
    showRemove: PropTypes.bool,
    onVetChange: PropTypes.func
  };

  static defaultProps = {
    shouldRequireVets: false,
    allowMultipleSelections: false,
    showRemove: true
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value?.gvl_id && prevProps.value?.gvl_id !== value.gvl_id) {
      this.props.listVetsForClinicRequest(value.gvl_id);
    }
  }

  onSelect = id => {
    this.props.onChange({ ...this.props.newportClinics[id], id: parseInt(id) });
    this.props.listVetsForClinicRequest(id);
  };

  onRemove = () => {
    this.props.onChange({});
    // If clinic changes, reset the vet(s) field
    this.props.form.resetFields([this.props.formFieldName]);
  };

  clinicOptionGenerator = results => {
    return results.map(result => (
      <Select.Option key={result.gvl_id} value={result.gvl_id}>
        <StyledOptionsHeader>
          {result.name} <FadedText>{result.newport_id}</FadedText>
        </StyledOptionsHeader>
        <StyledOptionsSubText>{`${result.shippingAddress.city}, ${result.shippingAddress.state}`}</StyledOptionsSubText>
      </Select.Option>
    ));
  };

  filterClinics = (inputValue, option) =>
    option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
    option.newport_id.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

  render() {
    const {
      form,
      value,
      selectedVets,
      allowMultipleSelections,
      shouldRequireVets,
      formFieldName,
      newportClinics,
      showRemove,
      onVetChange
    } = this.props;

    let vets = this.props.clinicsVets?.[value?.gvl_id];

    //For returned release requests approvingClinic component does not update so listVetsForClinicRequest does not get called, we need to make sure we pull the vets if we do have them
    if (!vets && value?.gvl_id) {
      vets = this.props.listVetsForClinicRequest(value.gvl_id);
    }

    return value?.gvl_id ? (
      <NewportClinicInlineShow
        form={form}
        clinic={value}
        vetDisplay="select"
        showRemove={showRemove}
        onRemove={this.onRemove}
        contacts={vets}
        contactFormField={formFieldName}
        selectedVets={selectedVets}
        allowMultipleSelections={allowMultipleSelections}
        shouldRequireVets={shouldRequireVets}
        onVetChange={onVetChange}
      />
    ) : (
      <GvlSearch
        dataSource={Object.values(newportClinics)}
        childGenerator={this.clinicOptionGenerator}
        filterFunction={this.filterClinics}
        onSelect={this.onSelect}
        minSearchTermLength={3}
        clearResultsWhenNoValue={true}
        optionLabelProp="text"
        dropdownClassName="gvl-select-with-custom-groups"
        emptyMsg="clinic.missingWithzipcode" // Translates to 'No clinics found'
      />
    );
  }
}

const mapStateToProps = state => ({
  clinicsVets: state.accounts.clinics.clinicsVets,
  newportClinics: state.accounts.newportClinics.data
});

const mapDispatchToProps = {
  listVetsForClinicRequest
};

const NewportClinicSearchFormItem = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(NewportClinicSearchFormItemComponent);

export {
  NewportClinicSearchFormItem as default,
  NewportClinicSearchFormItemComponent
};
