import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './isolateReleaseActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false
});

export const onIsRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

export const onRefreshIsolatesForProductSuccess = (
  state,
  { isolateId, releases }
) => {
  let orderedReleases = {};
  Object.values(releases).forEach(
    release => (orderedReleases[release.isolateReleaseId] = release)
  );
  const updatedReleases = state.data.merge({
    [isolateId]: orderedReleases
  });
  return state.merge({
    data: updatedReleases
  });
};

export const isolateReleaseReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsRefreshing,
  [types.REFRESH_RELEASES_FOR_ISOLATE_SUCCESS]: onRefreshIsolatesForProductSuccess
});
