import { Modal, Select, notification } from 'antd';
import { isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import intl from 'react-intl-universal';
import Immutable from 'seamless-immutable';
import { isSafari, isIOS, isIOS13 } from 'react-device-detect';

const Option = Select.Option;

// A helper function to generate options for a selection
// Accepts an array of {value:**, text:**, disabled: true/false } OR simply an array of Strings
export const generateSelectOptions = (data, formatOptionsText = false) => {
  if (!Array.isArray(data)) return null;
  return data.map(it => {
    if (typeof it === 'string') {
      it = { text: it, value: it };
    }
    return (
      <Option key={it.value} value={it.value} disabled={it.disabled}>
        {formatOptionsText ? intl.get(it.text) : it.text}
      </Option>
    );
  });
};

//A function to get the language preference of the user from the browser
export const getLanguage = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang =
    lang ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;
  if (!lang) lang = 'en-US';
  if (lang.indexOf('-') !== -1) lang = lang.split('-')[0];
  if (lang.indexOf('_') !== -1) lang = lang.split('_')[0];
  lang = ['en', 'fr'].indexOf(lang) > -1 ? lang : 'en';
  return lang;
};

let today;
export const disabledDate = (startDate, endDate) => current => {
  if (!today) {
    // Set `today` as a shared variable so it doesn't need to be regenerated,
    // but clear it after 1 second to avoid a stale value
    today = moment();
    setTimeout(() => (today = undefined), 1000);
  }
  let validStartDate = startDate === 'today' ? today : startDate;
  let validEndDate = endDate === 'today' ? today : endDate;
  let isDisabled = false;
  if (validStartDate && current.endOf('day') < validStartDate.endOf('day')) {
    isDisabled = true;
  } else if (validEndDate && current.endOf('day') > validEndDate.endOf('day')) {
    isDisabled = true;
  } //else if (!validStartDate && !validEndDate) {
  //   isDisabled = false;
  // }

  return isDisabled;
};

//TODO flip the values around since we are disabling the dates
export const presentOrFutureDate = disabledDate('today', undefined);
export const presentOrPastDate = disabledDate(undefined, 'today');
export const toOrFromDate = (from, to) =>
  disabledDate(moment(from), moment(to));
export const getRelativeTimeInString = timestamp => {
  return moment.utc(timestamp).fromNow();
};

export const getIsoDateShortString = dateThing => {
  if (typeof dateThing == 'string') return dateThing.substring(0, 10);
  if (dateThing instanceof Date) return dateThing.toJSON().substring(0, 10);
  if (dateThing === null) return new Date().toJSON().substring(0, 10);
  else return null;
};

export const filterArrayBasedOnIndex = (arr, toFilter = []) => {
  if (!Array.isArray(arr)) return [];
  return arr.filter((_it, i) => !toFilter.includes(i));
};

// A simple function to remove a particular index from an array.
export const arrayWithoutIndex = (array, index) => {
  let result;
  if (Array.isArray(array)) {
    result = array.slice(0, index).concat(array.slice(index + 1));
  }
  return result;
};

export const saveFile = (blob, filename) => {
  if (navigator.msSaveOrOpenBlob) {
    // Works for Internet Explorer and Microsoft Edge
    navigator.msSaveOrOpenBlob(blob, filename);
    return;
  } else if (isIOS || (isIOS13 && !isSafari)) {
    // Note: This alert probably will only get coverage when a user on iOS in a browser other than safari tries to export a CSV report
    alert(
      'Downloads on iOS are currently only supported in Safari for iOS 13+'
    );
  }

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
};

export const getRoute = ({
  match: {
    params: { p1, p2 }
  }
}) => {
  let id, action;
  if (p1 && p2) {
    // If UUID, assign the id string, otherwise parse the int
    id = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      p1
    )
      ? p1
      : parseInt(p1);
    action = p2;
  } else if (p1) {
    // if "new", otherwise parseInt()
    if (p1 === 'new') {
      action = 'new';
    } else {
      id = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        p1
      )
        ? p1
        : parseInt(p1);
    }
  }

  // convert NaN to undefined
  id = id ? id : undefined;
  return { id: id, action: action };
};

export const generateDocLink = (id, type, status) => {
  let urlAction = getUrlAction(status);

  switch (type) {
    case 'CVI':
      return generateUrl('cvis', id, urlAction);
    case 'EIA':
      return generateUrl('eias', id, urlAction);
    case 'EHC':
      return generateUrl('eecvis', id, urlAction);
    case 'VFD':
    case 'VFR':
      return generateUrl('vfds', id, urlAction);
    case 'Script':
      return generateUrl('scripts', id, urlAction);
    case 'ForSale':
      return generateUrl('forsale', id, urlAction);
    case 'Rabies':
      return generateUrl('rabies', id, urlAction);
    case 'IHC':
      return generateUrl('ihcs', id, urlAction);
    default:
      return '/'; // Link to Home for unknown doc types
  }
};

export const handleIframeClick = (docType, e) => {
  // If it is an href, got ot the correct location
  if (
    e.target.tagName.toLowerCase() === 'a' &&
    !e.target.classList.contains('iframeBypass')
  ) {
    e.preventDefault();
    // Get everything after # in the old URL
    return handleIframeHashChange(docType, e.target.href);
  }
};

export const handleIframeHashChange = (docType, url) => {
  try {
    let urlData = url.split('#/')[1];
    urlData = urlData.split('/');
    // format in Neptr is `edit/{id}`
    if (urlData.length > 1) {
      let idField = urlData[1];
      let actionField = urlData[0];
      return '/' + docType + '/' + idField + '/' + actionField;
    }
  } catch (e) {
    console.error(e);
  }
};

function getUrlAction(status) {
  return status === 'SAVED' || status === 'DRAFT' ? 'edit' : 'show';
}

function generateUrl(urlDocType, urlId, urlAction) {
  return `${urlDocType}/${urlId}/${urlAction}`;
}

export const convertCertTypeForDisplay = type => {
  switch (type) {
    case 'EHC':
      return 'EECVI';
    case 'OCVI':
      return 'CVI';
    case 'For Sale':
      return 'ForSale';
    case 'Coggins':
      return 'EIA';
    case 'Passport':
      return 'GoPass';
    case 'PC':
      return 'Green Tag';
    case 'RX':
      return 'Script';
    default:
      return type;
  }
};

export const getDecimalPlaces = number => {
  if (number && number > 0) {
    if (
      number % 1 !== 0 &&
      !isNaN(parseFloat(number)) &&
      number.toString().split('.')[1].length > 2
    ) {
      return parseFloat(number).toFixed(2);
    }
    return number;
  }
  return 0;
};

export const dateRangeToParams = (
  params,
  dateRange,
  startDateKey = 'startDate',
  endDateKey = 'endDate'
) => {
  if (!dateRange) return;
  params[startDateKey] = dateRange[0]
    .startOf('day')
    .toJSON()
    .substring(0, 10);
  params[endDateKey] = dateRange[1]
    .startOf('day')
    .toJSON()
    .substring(0, 10);
};

export const dateRangeToParamsForApiService = (
  apiPropKey,
  params,
  dateRange
) => {
  const startDateKey = `${apiPropKey}Since`;
  const endDateKey = `${apiPropKey}Until`;

  if (!dateRange) return;
  params[startDateKey] = dateRange[0]
    .startOf('day')
    .toJSON()
    .substring(0, 10);
  params[endDateKey] = dateRange[1]
    .startOf('day')
    .toJSON()
    .substring(0, 10);
};

// This function assumes ids are numbers generated incrementally.  It will not work for any lists of entities where ids are randomly generated.
export const sortEntityListByReverseId = list =>
  list.sort((a, b) => b.id - a.id);

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const didDocChange = (prevDoc, currentDoc) => {
  // If either doc is immutable, we can trust the built-in equality check
  if (Immutable.isImmutable(prevDoc) || Immutable.isImmutable(currentDoc)) {
    return prevDoc !== currentDoc;
  }
  // Otherwise compare the json output
  const prevDocJson = prevDoc ? prevDoc.toJson() : {};
  const currentDocJson = currentDoc ? currentDoc.toJson() : {};
  return !isEqual(currentDocJson, prevDocJson);
};

/*
To use this function, do the following in component constructor:
    this.state.unblockNavigation = generateNavigationBlocker(
      this.checkBlockNavigation,
      this.props.history
    );
this.checkBlockNavigation should be a component function that returns true or false
Make sure to unblock navigation in componentWillUnmount
    componentWillUnmount() {
      this.state.unblockNavigation(); // Remove block function from this.props.history
    }
For more info on React Router Blocking see: https://github.com/ReactTraining/history/blob/master/docs/Blocking.md
*/
export const generateNavigationBlocker = (checkBlockNavigation, history) => {
  // This attaches the blocker and returns an unblock function, which should be executed in componentWillUnmount
  return history.block(({ pathname, state }) => {
    if (state && state.noBlock) {
      // User confirmed to discard changes
      state = undefined; // Remove the history state we added in push below
      return true; // Navigate
    }

    if (pathname === history.location.pathname) {
      // User clicked on link to the page they are on, so just ignore it
      return false;
    }

    if (!checkBlockNavigation()) {
      return true; // Navigate
    } else {
      Modal.confirm({
        title: intl.get('cancel.edit'),
        content: intl.get('cancel.edit.navigate.body'),
        okText: intl.get('discard'),
        onOk: () => {
          history.push(pathname, { noBlock: true }); // Need to set history state noBlock so we can actually navigate.  See above
        },
        onCancel: () => {} // Don't navigate
      });
      return false; // Don't navigate since we are showing modal
    }
  });
};

export const checkUploadFileName = file => {
  // Return true if the there aren't any special char in the string
  //Here is the list of chars to avoid in AWS S3 https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html#object-key-guidelines-special-handling
  const re = /[ @#$%^&+=[\]{};'`:"\\|,<>/?~]/;
  const containsSpecialChars = re.test(file.name);
  if (containsSpecialChars) {
    notification.warning({
      message: intl.get('attachments.filename.warning.header'),
      description: intl.get('attachments.filename.warning.body'),
      duration: 8
    });
  }
  return !containsSpecialChars;
};
