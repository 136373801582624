// import * as contactActions from './store/contactActions';
// import * as contactReducer from './store/contactReducers';
import ContactModel from './ContactV2';
import Lookup from './components/ContactLookup';
import SearchFormItem from './components/ContactSearchFormItem';
import ContactEditModalComponent from './components/ContactEditModal';
import ContactFormFieldsComponent from './components/ContactFormFields';

// export default {
//   contactActions,
//   contactReducer
// };

export const Contact = ContactModel;
export const ContactLookup = Lookup;
export const ContactSearchFormItem = SearchFormItem;
export const ContactEditModal = ContactEditModalComponent;
export const ContactFormFields = ContactFormFieldsComponent;
