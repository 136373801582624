import { call, put, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { notification, message } from 'antd';
import * as actions from './nonAdjacentHerdRequestActions';
import * as types from './nonAdjacentHerdRequestActionTypes';
import { NonAdjacentHerdRequest } from 'modules/NonAdjacentHerdRequest/nonAdjacentHerdRequestIndex';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as api from 'utils/api';
import { exportCsv, formatDate } from 'utils/csvUtil';
// Would be nice to use minified version of objectExporter, but has some errors. Probably since it wasn't built for use inside a React app.
import objectExporter from 'utils/objectExporter/objectexporter';

export function* onNonadjacentSearchRequest({
  searchType,
  searchParams,
  callback
}) {
  try {
    yield put(actions.isRefreshing(true));
    const list = yield call(NonAdjacentHerdRequest.list, searchParams);

    if (searchType === 'export') {
      if (list.results.length > 0) {
        const csvLayout = [
          {
            heading: intl.get('product'),
            property: 'product',
            dataFormatFunction: product =>
              `${product?.productNbr}-${product?.serialNbr}`
          },
          {
            heading: intl.get('naRequest.product.desc'),
            property: 'productDescription'
          },
          {
            heading: intl.get('additional.details'),
            property: 'additionalDetails'
          },
          {
            heading: intl.get('clinic'),
            property: 'clinic.name'
          },
          {
            heading: intl.get('clinicAddress'),
            property: 'clinic',
            dataFormatFunction: c =>
              `${c.addressLine1} ${c.city} ${c.state} ${c.postalCode}`
          },
          {
            heading: intl.get('veterinarian'),
            property: 'vet.commonName'
          }
        ];
        const fileName = `NonadjacentHerdRequestsExport_${formatDate(
          new Date()
        )}.csv`;
        exportCsv(csvLayout, list.results, fileName);
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    } else if (searchType === 'exportPdf') {
      if (list.results.length > 0) {
        const fileName = `NonadjacentHerdRequestsExport_${formatDate(
          new Date()
        )}`;
        let exportable = [];
        for (let result of list.results) {
          exportable.push({
            product:
              `${result.product?.productNbr}-${result.product?.serialNbr}` ||
              '',
            productDescription: result.productDescription || '',
            additionalDetails: result.additionalDetails || '',
            clinic: result.clinic.name || '',
            clinicAddress:
              `${result.clinic.addressLine1} ${result.clinic.city} ${result.clinic.state} ${result.clinic.postalCode}` ||
              '',
            veterinarian: result.vet.commonName || '',
            _padding: undefined // objectExporter won't populate the last column without this padding column...
          });
        }
        objectExporter({
          exportable: exportable,
          type: 'pdf',
          headers: [
            intl.get('product'),
            intl.get('naRequest.product.desc'),
            intl.get('additional.details'),
            intl.get('clinic'),
            intl.get('clinicAddress'),
            intl.get('veterinarian')
          ],
          fileName: fileName,
          headerStyle:
            'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
          cellStyle: 'border: 1px solid lightgray; margin-bottom: -1px;',
          documentTitle: fileName
          // documentTitleStyle
          // repeatHeader: true
        });
      } else {
        notification.warning({
          message: intl.get('noResults.toExport'),
          description: intl.get('certificates.search.empty')
        });
      }
    }
    yield put(actions.searchSuccess(list.results));
    if (typeof callback === 'function') callback(list);
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.naRequests')));
    yield put(actions.searchFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onNonAdjacentHerdRequestListRequest() {
  try {
    yield put(actions.isRefreshing(true));
    let response = yield call(NonAdjacentHerdRequest.list, {
      limit: 250,
      statusNotIn: 'COMPLETED'
    });
    yield put(actions.listSuccess(response.results));
    while (response.offset < response.count) {
      response = yield call(NonAdjacentHerdRequest.list, {
        limit: 250,
        statusNotIn: 'COMPLETED',
        offset: response.offset + response.limit
      });
      if (response.results?.length > 0) {
        yield put(actions.listSuccess(response.results));
      }
    }
    //
    let ninetyDays = new Date();
    ninetyDays.setDate(-90);
    response = yield call(NonAdjacentHerdRequest.list, {
      dateCompletedSince: ninetyDays
    });
    yield put(actions.listSuccess(response.results));
    while (response.offset < response.count) {
      response = yield call(NonAdjacentHerdRequest.list, {
        dateCompletedSince: ninetyDays,
        offset: response.offset + response.limit
      });
      if (response.results?.length > 0) {
        yield put(actions.listSuccess(response.results));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(apiFailure(err, intl.get('error.loading.naRequests')));
    yield put(actions.listFailure());
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onRefreshRequest({ id, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    const naRequest = yield call(NonAdjacentHerdRequest.read, id);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.naRequests')));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSendToClinicRequest({ naRequest, callback, attachments }) {
  try {
    yield put(actions.isRefreshing(true));
    if (!naRequest.id) {
      yield call([naRequest, naRequest.save]);
    }
    yield call([naRequest, naRequest.sendToClinic]);
    yield put(actions.refreshSuccess(naRequest));
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        const formData = new FormData();
        formData.append('file', attachments[i]);
        formData.append('type', 'naRequest');
        formData.append('reference', naRequest.id);
        yield call(api.postFile, 'attachments', formData);
      }
    }
    if (typeof callback === 'function') {
      callback();
    }
    message.success(intl.get('naRequest.sentToClinic'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onUpdateHerdListInformation({
  naRequest,
  successMessage,
  callback
}) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.updateHerdListInformation]);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
    // successMessage instead of successMessageId due to translations with nested objects like saveWithNoun
    if (successMessage) {
      message.success(successMessage);
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.submitting.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSignNonAdjacentHerdRequest({
  id,
  username,
  password,
  callback
}) {
  try {
    yield put(actions.isRefreshing(true));
    const signedNARequest = yield call(
      NonAdjacentHerdRequest.signNARequest,
      id,
      username,
      password
    );
    yield put(actions.refreshSuccess(signedNARequest));
    if (typeof callback === 'function') {
      callback();
    }
    message.success(intl.get('naRequest.submitted'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.signing.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveRequest({ naRequest, callback, showSuccessMessage }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.save]);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
    if (showSuccessMessage) {
      message.success(
        intl.get('savedWithNoun', { noun: intl.get('naRequest') })
      );
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSaveTagsAndNotesRequest({ naRequest, closeModalCallback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.updateTagsAndNotes]);
    yield put(actions.refreshSuccess(naRequest));
    if (closeModalCallback) {
      closeModalCallback();
    }
    message.success(intl.get('savedWithNoun', { noun: intl.get('naRequest') }));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSendToStateVetRequest({ naRequest, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.sendToStateVet]);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
    message.success(intl.get('naRequest.approved'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.approving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onReturnToLabRequest({ naRequest, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.returnToLab]);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
    message.success(intl.get('naRequest.sentBack'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onApproveNaRequest({ naRequest, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call([naRequest, naRequest.approveNaRequest]);
    yield put(actions.refreshSuccess(naRequest));
    if (typeof callback === 'function') {
      callback();
    }
    message.success(intl.get('naRequest.approved'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.approving.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onMarkNaRequestComplete({ id }) {
  try {
    yield put(actions.isRefreshing(true));
    const naRequest = yield call(
      NonAdjacentHerdRequest.markNaRequestComplete,
      id
    );
    yield put(actions.refreshSuccess(naRequest));
    message.success(intl.get('naRequest.complete'));
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.completing.noun', {
          noun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onNonAdjacentHerdRequestDeleteRequest({ id }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(NonAdjacentHerdRequest.deleteRec, id);
    yield put(actions.deleteSuccess(id));
    message.success(
      intl.get('deletedThing', {
        thing: intl.get('naRequest')
      })
    );
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.deleting.properNoun', {
          properNoun: intl.get('naRequest.lc')
        })
      )
    );
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* nonAdjacentHerdRequestSagas() {
  yield takeLatest(types.SEARCH_REQUEST, onNonadjacentSearchRequest);
  yield takeLatest(types.LIST_REQUEST, onNonAdjacentHerdRequestListRequest);
  yield takeLatest(types.REFRESH_REQUEST, onRefreshRequest);
  yield takeLatest(types.SEND_TO_CLINIC, onSendToClinicRequest);
  yield takeLatest(types.UPDATE_HERDLIST_INFO, onUpdateHerdListInformation);
  yield takeLatest(types.SIGN_REQUEST, onSignNonAdjacentHerdRequest);
  yield takeLatest(types.SAVE_REQUEST, onSaveRequest);
  yield takeLatest(types.SAVE_TAGS_AND_NOTES, onSaveTagsAndNotesRequest);
  yield takeLatest(types.SEND_TO_STATE_VET, onSendToStateVetRequest);
  yield takeLatest(types.RETURN_TO_LAB, onReturnToLabRequest);
  yield takeLatest(types.APPROVE_NA_REQUEST, onApproveNaRequest);
  yield takeLatest(types.MARK_NA_REQUEST_COMPLETE, onMarkNaRequestComplete);
  yield takeLatest(types.DELETE_REQUEST, onNonAdjacentHerdRequestDeleteRequest);
}
