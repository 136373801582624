import Model from 'models/Model';
import { maxLength, isNumber } from 'utils/validation/common';
import * as api from 'utils/api';
export default class Image extends Model {
  // Instance Methods

  validate() {
    let superValidation = super.validate();
    let results = superValidation ? superValidation : [];

    // Put complex validations here.  If none, then delete this whole validate() method.

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        validate: val => {
          isNumber(val, 'validation.id.number');
        }
      },
      owner: {
        initialValue: null
      },
      animalIds: {
        initialValue: []
      },
      label: {
        inList: ['brand', 'image']
      },
      description: {
        initialValue: undefined,
        validate: val => {
          maxLength(val, 255);
        }
      },
      tags: {
        initialValue: []
      }
    };
  }

  static get apiPath() {
    return 'gallery';
  }

  static get domain() {
    return 'Image';
  }

  static getImageData = async () => {
    const data = await super.list();
    return data;
  };

  static getBase64 = async id => {
    return await api.get(`${this.apiPath}/base64Encoded/${id}`);
  };

  static async deleteRec(id, force) {
    let path = this.apiPath + '/deleteImage/' + id;
    if (force) {
      path += '?forceDelete=true';
    }
    await api.deleteRec(path);
  }

  static async filter(params) {
    return await api.get(`${this.apiPath}/filter`, { params });
  }

  static async upload(imageFile, description, tags, label) {
    description = description || '';
    tags = tags || [];
    let formData = new FormData();

    formData.append('files[]', imageFile);
    formData.append('description', description);
    formData.append('tags', tags);
    formData.append('label', label);

    return await api.postFile(`${this.apiPath}/addImage`, formData);
  }

  static async updateImageRecord(id, description, tags, label) {
    let formData = new FormData();

    formData.append('description', description);
    formData.append('tags', tags);
    formData.append('label', label);

    return await api.postFile(`${this.apiPath}/updateImage/${id}`, formData);
  }
}
