import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from 'containers/accounts/common/Home';
import Login from 'containers/app/Login';
import AccountRegistration from 'containers/registration/AccountRegistration';
import UserRegistrationVerification from 'containers/registration/UserRegistrationVerification';
import * as analytics from 'utils/analytics/analytics';
import * as api from 'utils/api';
import { BASENAME, DEPLOY_PLATFORM } from 'utils/config';

analytics.initialize();
api.attachInterceptors();

function NewportLogin(props) {
  return <Login {...props} variant="newport" />;
}

export default function AppComponent() {
  React.useEffect(() => {
    // Only include adserver script in non-local environments.
    if (DEPLOY_PLATFORM === 'local') return;
    // Revive Adserver Asynchronous JS Tag - Generated with Revive Adserver v4.1.4
    const script = document.createElement('script');
    script.src = 'https://ads.globalvetlink.com/www/delivery/asyncjs.php';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Router basename={BASENAME}>
      <Switch>
        <Route
          exact
          path="/register?couponCode=:code"
          component={AccountRegistration}
        />
        <Route exact path="/register" component={AccountRegistration} />
        <Route
          exact
          path="/registration/user/:id/:code"
          component={UserRegistrationVerification}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/newport/login" component={NewportLogin} />
        <Route path="/" component={Home} />
        <Route component={Home} />
      </Switch>
    </Router>
  );
}
