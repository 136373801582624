// General constants
export const IS_REFRESHING = 'isolate/IS_REFRESHING';

// list constants
export const LIST_REQUEST = 'isolate/LIST_REQUEST';
export const LIST_SUCCESS = 'isolate/LIST_SUCCESS';
export const LIST_FAILURE = 'isolate/LIST_FAILURE';

// search constants
export const SEARCH_REQUEST = 'isolate/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'isolate/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'isolate/SEARCH_FAILURE';

// refresh
export const ACTIVATE_ISOLATE = 'isolate/ACTIVATE_ISOLATE';
export const REFRESH_REQUEST = 'isolate/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'isolate/REFRESH_SUCCESS';
export const REFRESH_ISOLATES_FOR_PRODUCT =
  'isolate/REFRESH_ISOLATES_FOR_PRODUCT';
export const REFRESH_ISOLATES_FOR_PRODUCT_SUCCESS =
  'isolate/REFRESH_ISOLATES_FOR_PRODUCT_SUCCESS';

// save
export const SAVE_REQUEST = 'isolate/SAVE_REQUEST';

// archive/delete
export const ARCHIVE_REQUEST = 'isolate/ARCHIVE_REQUEST';
export const DELETE_REQUEST = 'isolate/DELETE_REQUEST';

// remove from store
export const REMOVE_ISOLATE_FROM_STORE = 'isolate/REMOVE_ISOLATE_FROM_STORE';

// audit reporting
export const AUDIT_REPORT_REQUEST = 'isolate/AUDIT_REPORT_REQUEST';
export const AUDIT_REPORT_SUCCESS = 'isolate/AUDIT_REPORT_SUCCESS';
export const IS_REFRESHING_EVENTS = 'isolate/IS_REFRESHING_EVENTS';

// extension requests
export const EXTENSION_SAVE_REQUEST = 'isolate/EXTENSION_SAVE_REQUEST';
export const EXTENSION_SIGN_REQUEST = 'isolate/EXTENSION_SIGN_REQUEST';
export const DELETE_EXTENSION_REQUEST = 'isolate/DELETE_EXTENSION_REQUEST';
export const APPROVE_EXTENSION_REQUEST = 'isolate/APPROVE_EXTENSION_REQUEST';
export const EXTENSION_CHANGES_REQUEST = 'isolate/EXTENSION_CHANGES_REQUEST';

// release requests
export const RELEASE_SAVE_REQUEST = 'isolate/RELEASE_SAVE_REQUEST';
export const DELETE_RELEASE_REQUEST = 'isolate/DELETE_RELEASE_REQUEST';
export const APPROVE_RELEASE_REQUEST = 'isolate/APPROVE_RELEASE_REQUEST';
export const DECLINE_RELEASE_REQUEST = 'isolate/DECLINE_RELEASE_REQUEST';
export const RELEASE_INFO_REQUEST = 'isolate/RELEASE_INFO_REQUEST';

// store default diagnostic list in the store
export const DEFAULT_DIAGNOSTIC_LAB_REQUEST =
  'isolate/DEFAULT_DIAGNOSTIC_LAB_REQUEST';
