import { createAction } from 'utils/reduxUtil';
import {
  COMMON_LOAD_STARTUP_DATA_FINISHED,
  COMMON_LOAD_STARTUP_DATA_REQUEST,
  COMMON_LOAD_STARTUP_DATA_STARTED,
  COMMON_LOAD_STARTUP_DATA_SUCCESS,
  SET_GVLHEADER_ITEMS,
  //EMAIL CERTIFICATE
  EMAIL_CERTIFICATE_REQUEST,
  EMAIL_CERTIFICATE_SUCCESS,
  EMAIL_CERTIFICATE_FAILURE
} from 'containers/accounts/common/store/accountsCommonConstants';

/* istanbul ignore file */

export const commonLoadStartupDataRequest = () =>
  createAction(COMMON_LOAD_STARTUP_DATA_REQUEST);
export const commonLoadStartupDataStarted = () =>
  createAction(COMMON_LOAD_STARTUP_DATA_STARTED);
export const commonLoadStartupDataSuccess = startupData =>
  createAction(COMMON_LOAD_STARTUP_DATA_SUCCESS, startupData);
export const commonLoadStartupDataFinished = () =>
  createAction(COMMON_LOAD_STARTUP_DATA_FINISHED);
export const setGvlHeaderItems = headerItems =>
  createAction(SET_GVLHEADER_ITEMS, { headerItems });

// Email Certificate
export const emailCertificateRequest = (emailAddresses, certificateIds) =>
  createAction(EMAIL_CERTIFICATE_REQUEST, {
    emailAddresses,
    certificateIds
  });
export const emailCertificateSuccess = () =>
  createAction(EMAIL_CERTIFICATE_SUCCESS);
export const emailCertificateFailure = () =>
  createAction(EMAIL_CERTIFICATE_FAILURE);
