import * as google from 'utils/analytics/google';
import * as intercom from 'utils/analytics/intercom';
import { getLocalStorage } from 'utils/localStorage';
import { getEnvironment } from 'utils/envUtil';

export const serviceEnum = {
  ALL: 'all',
  INTERCOM: 'intercom',
  GOOGLE: 'google'
};

export const isTrackingEnabled = () => {
  if (
    window.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.doNotTrack === '1' ||
    navigator.msDoNotTrack === '1' ||
    ('external' in window &&
      'msTrackingProtectionEnabled' in window.external &&
      window.external.msTrackingProtectionEnabled())
  ) {
    return false;
  }
  return true;
};

export const initialize = () => {
  const environment = getEnvironment();
  if (isTrackingEnabled()) {
    const localStorage = getLocalStorage();
    const overridePlatformForGoogleAnalytics = localStorage.getItem(
      'overridePlatformForGoogleAnalytics'
    );
    if (
      overridePlatformForGoogleAnalytics === 'true' ||
      environment === 'production'
    ) {
      google.initialize();

      // Track JS Errors for Google
      window.addEventListener('error', error => {
        google.jsError(error);
      });
    }

    const overridePlatformForIntercom = localStorage.getItem(
      'overridePlatformForIntercom'
    );
    if (overridePlatformForIntercom === 'true' || environment !== 'local') {
      intercom.initialize();
    }
  }
};

export const getCommandObjects = () => {
  return {
    google: google.getCommandObject(),
    intercom: intercom.getCommandObject()
  };
};

export const userSetup = userInfo => {
  if (isTrackingEnabled()) {
    google.userSetup(userInfo);
    intercom.userSetup(userInfo);
  }
};

export const userReset = () => {
  google.userReset();
  intercom.userReset();
};

/**
 * Send events to both the GoogleAnalytics and Intercom APIs to track and analyze user events within the application
 * @param  {String} Category A category is a name that you supply as a way to group objects that you want to analyze. Typically, you will use the same category name multiple times over related UI elements that you want to group under a given category.
 * @param  {String} Action The action parameter is used to name the type of event or interaction you want to measure for a particular category. For example, if your category was Video, you might have an action that is play or stop.
 * @param  {String} Label Labels provide additional information for events that you want to analyze, such as movie titles in videos, or the names of downloaded files.
 * @param  {String} Payload The payload is an additional object that can pass additional information that is relevant to the event. Please note that this data cannot be tracked and should not be used for high level reports.
 * @return {null}
 */
export const trackEvent = (
  category,
  action,
  label,
  payload,
  service = serviceEnum.ALL
) => {
  if (getEnvironment() !== 'production') {
    console.log('Analytics Track Event', { category, action, label, payload });
    return;
  }
  if (service === serviceEnum.GOOGLE || service === serviceEnum.ALL) {
    google.trackEvent(category, action, label);
  }
  if (service === serviceEnum.INTERCOM || service === serviceEnum.ALL) {
    intercom.trackEvent(category, action, label, payload);
  }
};

export const trackSign = document => {
  let metadata = {
    sign_date: Math.floor(Date.now() / 1000),
    cert_id: document.id,
    cert_number: document.serialNumber
  };
  // Add timing if applicable
  if (document.timing) {
    metadata.seconds_to_sign =
      (Math.round(new Date().getTime()) - document.timing) / 1000;
  }
  // smartengine info
  if (['CVI', 'EHC'].includes(document.type)) {
    metadata.origin = (document.originPremises || {}).state;
    metadata.destination = (document.destinationPremises || {}).state;
    metadata.species = document.species;
    metadata.smart_engine_status = getSmartEngineStatus(document);

    trackEvent(
      'smart engine',
      'verification',
      metadata.smart_engine_status,
      metadata
    );
  }
  const event = 'EHC' === document.type ? 'commit' : 'sign';
  trackEvent('document', event, document.type, metadata);
  trackTiming(
    'document',
    event,
    document.type,
    metadata.seconds_to_sign * 1000
  );
};

export const trackTiming = (category, action, label, valueInMilliseconds) => {
  if (getEnvironment() !== 'production' && category !== 'Registration') {
    console.log('Analytics Track Timing', {
      category,
      action,
      label,
      valueInMilliseconds
    });
    return;
  }
  google.trackTiming(category, action, label, valueInMilliseconds);
};

export const trackBatch = documents => {
  let metadata = {
    sign_date: Math.floor(Date.now() / 1000),
    cert_ids: documents.map(doc => doc.id),
    cert_numbers: documents.map(doc => doc.serialNumber)
  };
  // smartengine
  if (['CVI', 'EHC'].includes(documents[0].type)) {
    metadata.origins = documents.map(doc => (doc.originPremises || {}).state);
    metadata.destinations = documents.map(
      doc => (doc.destinationPremises || {}).state
    );
    metadata.species = documents.map(doc => doc.species);
    metadata.smart_engine_statuses = documents.map(doc =>
      getSmartEngineStatus(doc)
    );
    trackEvent('smart engine', 'verification', '[multiple]', metadata);
  }
  const event = documents[0].type === 'EHC' ? 'commit' : 'sign';
  trackEvent('document', 'batch ' + event, documents[0].type, metadata);
};

export const getSmartEngineStatus = document => {
  if (document.verified === true) {
    return 'passed';
  } else if (document.verified === false) {
    return 'failed';
  }
  return 'skipped';
};
export const locationChange = location => {
  google.locationChange(location);
  intercom.locationChange(location);
};
