import * as api from 'utils/api';
import Model from 'models/Model';
import SysFeatureSet from 'models/SysFeatureSet';

export default class Subscription extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();
    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        initialValue: null
      },
      enabled: {
        initialValue: true
      },
      billingFrequency: {
        initialValue: '',
        validate: value => {
          if (!['Monthly', 'Annually'].includes(value)) {
            return {
              messageId: 'required',
              value: value
            };
          }
          return null;
        }
      },
      ownerAccountId: {
        initialValue: null,
        validate: value => {
          if (!value) {
            return {
              messageId: 'required',
              value: value
            };
          }
          return null;
        }
      },
      invoicedAccountId: {
        initialValue: null,
        validate: value => {
          if (!value) {
            return {
              messageId: 'required',
              value: value
            };
          }
          return null;
        }
      },
      invoicedAccountName: {
        initialValue: null
      },
      relatedRecordId: {
        initialValue: null
        // Conditionally required (for Clinic/Lab). What is the best way to enforce this?
        // validate: value => {
        //   if (!value) {
        //     return {
        //       messageId: 'required',
        //       value: value
        //     };
        //   }
        //   return null;
        // }
      },
      relatedRecordData: {
        initialValue: null
      },
      sysFeatureSet: {
        initialValue: {},
        validate: value => {
          return new SysFeatureSet(value).validate();
        }
      },
      sysFeatureSetId: {
        initialValue: null
      },
      arSubscriptionId: {
        initialValue: null
      },
      arSubscriptionNumber: {
        initialValue: null
      },
      dateCreated: {
        initialValue: null
      },
      lastUpdated: {
        initialValue: null
      },
      arSyncDate: {
        initialValue: null
      },
      promoPlanCode: {
        initialValue: null
      }
    };
  }

  static get apiPath() {
    return 'accountSubscription';
  }

  static get domain() {
    return 'AccountSubscription';
  }

  static list = async params => await super.list(params);

  static read = async id => {
    return super.read(id);
  };

  static sync = async (id, data) => {
    return await api.post(`${this.apiPath}/${id}/sync`, data);
  };

  static getArSubscription = async id => {
    return await api.get(`${this.apiPath}/${id}/arSubscription`);
  };
}
