import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { FONT } from 'style';
import { THEME } from '../../style/theme';

/* istanbul ignore next */
const StyledBreadcrumb = styled(Breadcrumb)({
  fontSize: FONT.size.xlarge,
  fontWeight: FONT.weight.semiBold,
  fontFamily: FONT.family.header,
  color: THEME.secondaryText(),
  lineHeight: '24px',
  margin: 0,
  textOverflow: 'ellipsis',
  overflow: 'hidden'
});

/* istanbul ignore next */
// For accounts that don't have a dashboard, need to use breadcrumb to show title of page
const SingleBreadcrumb = styled('span')({
  color: THEME.text()
});

/* istanbul ignore next */
const LinkBlue = styled(Link)({
  // AntD uses a slightly more specific selector than emotion to try to force
  // all links within breakcrumbs to be grey, so the !important keyword is
  // needed to override that.
  color: `${THEME.blue()} !important`
});

export default class HeaderBreadcrumbs extends Component {
  static propTypes = {
    viewMode: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired
  };

  static defaultProps = {
    viewMode: 'Desktop',
    breadcrumbs: []
  };

  render() {
    const { viewMode, breadcrumbs, ...rest } = this.props;
    const breadcrumbItems = breadcrumbs.map(breadcrumb => {
      return (
        <Breadcrumb.Item key={breadcrumb.id}>
          {breadcrumb.link ? (
            <LinkBlue to={breadcrumb.link}>{breadcrumb.title}</LinkBlue>
          ) : breadcrumbs.length === 1 ? (
            <SingleBreadcrumb>{breadcrumb.title}</SingleBreadcrumb>
          ) : (
            breadcrumb.title
          )}
        </Breadcrumb.Item>
      );
    });
    return (
      <StyledBreadcrumb separator="\" {...rest}>
        {viewMode === 'Mobile'
          ? breadcrumbItems[breadcrumbItems.length - 1]
          : breadcrumbItems}
      </StyledBreadcrumb>
    );
  }
}
