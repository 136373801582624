// import * as herdActions from './store/herdActions';
// import * as herdReducer from './store/herdReducers';
import HerdModel from './Herd';
import Lookup from './components/HerdLookup';
import HerdEditModalComponent from './components/HerdEditModal';
import HerdFormFieldsComponent from './components/HerdFormFields';

// export default {
//   herdActions,
//   herdReducer
// };

export const Herd = HerdModel;
export const HerdLookup = Lookup;
export const HerdEditModal = HerdEditModalComponent;
export const HerdFormFields = HerdFormFieldsComponent;
