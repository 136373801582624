import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import GvlCard from 'elements/GvlCard';
import nonAdjacentListColumns from 'containers/accounts/clinic/AutogenousVaccines/nonAdjacentListColumns';

export default function NonAdjacentStateApprovalsTableComponent({
  usageStates
}) {
  const nonAdjacentColumns = nonAdjacentListColumns.generateColumns();

  return (
    <GvlCard
      title={intl.get('naRequest.state.approvals')}
      condensed={true}
      style={{ marginBottom: '20px' }}
    >
      <Table
        dataSource={usageStates}
        columns={nonAdjacentColumns.stateApprovals}
        keyField="id"
        pagination={false}
      />
    </GvlCard>
  );
}

NonAdjacentStateApprovalsTableComponent.propTypes = {
  usageStates: PropTypes.arrayOf(PropTypes.object)
};
