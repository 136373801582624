import { createAction } from 'utils/reduxUtil';
import {
  USER_UPDATE,
  USER_RESET,
  USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS,
  USER_GET_INFO_SUCCESS,
  // Notifications
  USER_NOTIFICATIONS_REQUEST,
  USER_REFRESH_NOTIFICATIONS_STARTED,
  USER_NOTIFICATIONS_SUCCESS,
  USER_NOTIFICATIONS_FAILURE,
  // Smart Engine
  USER_TOGGLE_SMART_ENGINE_STATUS,
  UPDATE_USER_SETTINGS,
  // Profile
  USER_GET_PROFILE_REQUEST,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_DONE
} from 'containers/app/store/user/userConstants';

/* istanbul ignore file */

export const userUpdate = (authToken, username, roles, info) =>
  createAction(USER_UPDATE, {
    authToken,
    username,
    roles,
    info
  });

export const userReset = () => createAction(USER_RESET);

export const userAcceptLicenseAgreementSuccess = () =>
  createAction(USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS);
// Notifications
export const userNotificationsRequest = () =>
  createAction(USER_NOTIFICATIONS_REQUEST);
export const userRefreshNotificationsStarted = () =>
  createAction(USER_REFRESH_NOTIFICATIONS_STARTED);
export const userNotificationsSuccess = notifications =>
  createAction(USER_NOTIFICATIONS_SUCCESS, {
    notifications
  });
export const userNotificationsFailure = () =>
  createAction(USER_NOTIFICATIONS_FAILURE);

export const userToggleSmartEngineStatus = () =>
  createAction(USER_TOGGLE_SMART_ENGINE_STATUS);

export const updateUserSettings = settings =>
  createAction(UPDATE_USER_SETTINGS, { settings });

export const userGetProfileRequest = () =>
  createAction(USER_GET_PROFILE_REQUEST);
export const userGetProfileSuccess = profile =>
  createAction(USER_GET_PROFILE_SUCCESS, { profile });
export const userGetProfileFailure = () =>
  createAction(USER_GET_PROFILE_FAILURE);
export const userUpdateProfileRequest = params =>
  createAction(USER_UPDATE_PROFILE_REQUEST, { params });
export const userUpdateProfileDone = () =>
  createAction(USER_UPDATE_PROFILE_DONE);
export const userGetInfoSuccess = info =>
  createAction(USER_GET_INFO_SUCCESS, { info });
