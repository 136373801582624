// https://stackoverflow.com/questions/42847126/script-load-in-react
export const loadScript = src => {
  return new Promise(function(resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function() {
      resolve();
    });
    script.addEventListener('error', function(e) {
      reject(e);
    });
    document.body.appendChild(script);
  });
};
