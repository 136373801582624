export const COMMON_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/Common/COMMON_LOAD_STARTUP_DATA_REQUEST';
export const COMMON_LOAD_STARTUP_DATA_STARTED =
  'cricket/Accounts/Common/COMMON_LOAD_STARTUP_DATA_STARTED';
export const COMMON_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/Common/COMMON_LOAD_STARTUP_DATA_SUCCESS';
export const COMMON_LOAD_STARTUP_DATA_FINISHED =
  'cricket/Accounts/Common/COMMON_LOAD_STARTUP_DATA_FINISHED';
export const SET_GVLHEADER_ITEMS =
  'cricket/Accounts/Common/SET_GVLHEADER_ITEMS';

export const EMAIL_CERTIFICATE_REQUEST =
  'cricket/Accounts/Common/EMAIL_CERTIFICATE_REQUEST';
export const EMAIL_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Common/EMAIL_CERTIFICATE_SUCCESS';
export const EMAIL_CERTIFICATE_FAILURE =
  'cricket/Accounts/Common/EMAIL_CERTIFICATE_FAILURE';
