import Model from 'models/Model';
import moment from 'moment';
import { validateDocType } from 'utils/validation/document';
import { maxLength } from 'utils/validation/common';
import * as api from 'utils/api';
import { downloadFile } from 'utils/downloadUtil';

export default class Document extends Model {
  constructor(data) {
    super(data);

    // Always use type from child class
    this.type = this.constructor.docType;
  }

  // Instance Methods

  validate() {
    const results = super.validate();

    // Put complex validations here.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Document Sign', {messageId: 'document.sign.invalidUser'}));

    return results;
  }

  async verifyPartial() {
    return await api.post(
      `${this.constructor.apiPath}/verifyPartial?docType=${this.type}`,
      this.toJson()
    );
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      clinic: {
        initialValue: {}
      },
      clinic_id: {
        initialValue: undefined
      },
      effectiveDate: {
        initialValue: undefined
      },
      expirationDate: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      metadata: {
        initialValue: {}
      },
      serialNumber: {
        initialValue: undefined,
        validate: value => {
          maxLength(value, 40);
        }
      },
      signingDate: {
        initialValue: undefined
      },
      signingTimestamp: {
        initialValue: undefined
      },
      type: {
        initialValue: '',
        validate: validateDocType
      },
      version: {
        initialValue: undefined
      },
      vet: {
        initialValue: {}
      }
    };
  }

  static get apiPath() {
    return 'documents';
  }

  static get domain() {
    return 'Document';
  }

  static get docType() {
    return '';
  }

  static async getDrafts(type) {
    const data = await super.list({
      type,
      'metadata.status': 'SAVED',
      limit: 0
    });
    return data.results;
  }

  static async getRecentlySigned(type) {
    // let since = new Date();
    // since.setDate(new Date().getDate() - 14);
    const data = await super.list({
      'metadata.status': 'SIGNED',
      signedSince: moment()
        .subtract(14, 'days')
        .toJSON(),
      'metadata.archived': false,
      type,
      limit: 0
    });
    return data.results;
  }

  static async getRequests(docClass) {
    const listResponse = await api.get('requests', {
      params: {
        type: 'document',
        limit: 0,
        'metadata.status': 'REQUESTED',
        'requestedObject.type': docClass.docType
      }
    });

    // Convert the requests to specific doc Type
    return listResponse.results.map(request => {
      const docFromRequest = new docClass(request.requestedObject);
      docFromRequest.requestId = request.id; // Save the request id so we can get back to it
      return docFromRequest;
    });
  }

  static archive = async id => {
    await api.put(`${this.apiPath}/${id}?protectedAction=archive`);
  };

  static rawList = async params => await super.rawList(params);

  static deleteRec = async id => {
    await super.deleteRec(id);
  };

  static downloadPdf = async (
    id,
    shouldShowMessage = true,
    includeImage = false
  ) => {
    const queryString = includeImage ? `?includeImages=${includeImage}` : '';
    return await downloadFile(
      `${this.apiPath}/${id}/pdf${queryString}`,
      undefined,
      undefined,
      shouldShowMessage
    );
  };

  static downloadPng = async (
    id,
    shouldShowMessage = true,
    includeImage = false
  ) => {
    const queryString = includeImage ? `?includeImages=${includeImage}` : '';
    return await downloadFile(
      `${this.apiPath}/${id}/png${queryString}`,
      undefined,
      undefined,
      shouldShowMessage
    );
  };

  static batchDownloadPdfs = async ids => {
    for (let index = 0; index < ids.length; index++) {
      try {
        await this.downloadPdf(ids[index], false);
      } catch (e) {
        console.error(`Error downloading document ${ids[index]}`);
      }
    }
  };

  static batchDownloadPngs = async ids => {
    for (let index = 0; index < ids.length; index++) {
      try {
        await this.downloadPng(ids[index], false);
      } catch (e) {
        console.error(`Error downloading document ${ids[index]}`);
      }
    }
  };

  static lock = async id => {
    return await api.put(
      `${this.apiPath}/${id}?protectedAction=markReadyToSign`
    );
  };

  static commit = async id => {
    return await api.put(`${this.apiPath}/${id}?protectedAction=commit`);
  };

  static unlock = async id => {
    return await api.put(`${this.apiPath}/${id}?protectedAction=backToDraft`);
  };

  static sign = async (
    id,
    username,
    password,
    showImages,
    notifyClinic,
    notifyVet
  ) => {
    return await api.put(`${this.apiPath}/${id}/sign`, {
      username,
      password,
      timeOffsetMins: new Date().getTimezoneOffset(),
      includeImages: showImages,
      notifyClinic: notifyClinic,
      notifyVet: notifyVet
    });
  };

  static batchSign = async (
    ids,
    username,
    password,
    showImages,
    notifyClinic,
    notifyVet
  ) => {
    return await api.put(`${this.apiPath}/batchSign`, {
      ids,
      timeOffsetMins: new Date().getTimezoneOffset(),
      username,
      password,
      includeImages: showImages,
      notifyClinic: notifyClinic,
      notifyVet: notifyVet
    });
  };

  // ids - Array of id numbers
  // emails - comma delimited string of email addresses
  static batchEmail = async (ids, emails) => {
    // TODO: Normalize the backend endpoint so we can use document endpoint instead of cert
    return await api.post('cert/batchEmail', {
      certIds: ids,
      emails
    });
  };

  static batchPrint = ids =>
    downloadFile('cert/batchPrint', true, { certIds: ids });

  static async getMvlOwnerDocuments() {
    // Get up to 500 of the most recent signed documents up to a year old
    const data = await Document.rawList({
      signedSince: moment()
        .subtract(1, 'years')
        .toJSON(),
      type: 'CVI,EHC,EIA,FORSALE,VFD,VFR,SCRIPT,RABIES',
      sort: '-serialNumber',
      limit: 500
    });
    return data.results;
  }

  static getHomeData = async params => {
    const data = await api.get(`${this.apiPath}`, { params });
    return data.results;
  };

  static voidDocument = async (serialNumber, reasonForVoiding) => {
    return await api.post(`/documents/${serialNumber}/void`, {
      reasonForVoiding
    });
  };

  static lookupDocument = async (certNumber, certType) => {
    // TODO: Normalize the backend endpoint so we can use document endpoint instead of cert
    return await api.get('cert/outOfStateLookup', {
      params: { certNumber, certType }
    });
  };
}
