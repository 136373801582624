// general constants
export const IS_REFRESHING = 'nonAdjacentHerdRequest/IS_REFRESHING';

// list constants
export const LIST_REQUEST = 'nonAdjacentHerdRequest/LIST_REQUEST';
export const LIST_SUCCESS = 'nonAdjacentHerdRequest/LIST_SUCCESS';
export const LIST_FAILURE = 'nonAdjacentHerdRequest/LIST_FAILURE';

// list constants
export const SEARCH_REQUEST = 'nonAdjacentHerdRequest/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'nonAdjacentHerdRequest/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'nonAdjacentHerdRequest/SEARCH_FAILURE';

// refresh constants
export const REFRESH_REQUEST = 'nonAdjacentHerdRequest/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'nonAdjacentHerdRequest/REFRESH_SUCCESS';

// workflow constants
export const SEND_TO_CLINIC = 'nonAdjacentHerdRequest/SEND_TO_CLINIC';
export const UPDATE_HERDLIST_INFO =
  'nonAdjacentHerdRequest/UPDATE_HERDLIST_INFO';
export const SIGN_REQUEST = 'nonAdjacentHerdRequest/SIGN_REQUEST';
export const SAVE_REQUEST = 'nonAdjacentHerdRequest/SAVE_REQUEST';
export const SEND_TO_STATE_VET = 'nonAdjacentHerdRequest/SEND_TO_STATE_VET';
export const RETURN_TO_LAB = 'nonAdjacentHerdRequest/RETURN_TO_LAB';
export const APPROVE_NA_REQUEST = 'nonAdjacentHerdRequest/APPROVE_NA_REQUEST';
export const MARK_NA_REQUEST_COMPLETE =
  'nonAdjacentHerdRequest/MARK_NA_REQUEST_COMPLETE';
export const SAVE_TAGS_AND_NOTES = 'nonAdjacentHerdRequest/SAVE_TAGS_AND_NOTES';

// delete constants
export const DELETE_REQUEST = 'nonAdjacentHerdRequest/DELETE_REQUEST';
export const DELETE_SUCCESS = 'nonAdjacentHerdRequest/DELETE_SUCCESS';
