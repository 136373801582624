import AccountRegistrationModel from './AccountRegistration';
import PlanSelectTableComponent from './components/PlanSelectTable';
import RegistrationFooterValComponent from './components/RegistrationFooterVal';
import ReviewInfoModalComponent from './components/ReviewInfoModal';
import RegLayout from './components/RegistrationLayout';
import IncompleteRegModal from './components/IncompleteRegistrationModal';

export const AccountRegistration = AccountRegistrationModel;

export const PlanSelectTable = PlanSelectTableComponent;
export const RegistrationFooterVal = RegistrationFooterValComponent;
export const ReviewInfoModal = ReviewInfoModalComponent;
export const RegistrationLayout = RegLayout;
export const IncompleteRegistrationModal = IncompleteRegModal;
