import Document from 'models/documents/Document';
import moment from 'moment';
import {
  multipleEmailValidator,
  isInteger,
  isNumber,
  maxLength,
  numberInRange,
  objectRequired,
  stringRequired,
  validRange,
  arrayRequired,
  dateIsBefore
} from 'utils/validation/common';
import * as api from 'utils/api';
import { downloadFile } from 'utils/downloadUtil';
import { tylanLowerDosageFDACheck } from 'utils/tylanHelper';

export default class Vfd extends Document {
  static fieldsWithExtraLockValidation = [
    'affirmStatement',
    'concentration1',
    'concentration1Days',
    'specialInstructions1',
    'expirationDate',
    'feedSites',
    'headCount',
    'owner',
    'ownerPremises',
    'productionClass'
  ];

  // Instance Methods

  validate() {
    const results = super.validate();
    // Put complex validations here.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('CVI Special', {messageId: 'cvi.consignor.somethingWentWrong'));
    return results;
  }

  static get fields() {
    const bovineTylanValidateHelper = (value, instance) => {
      return instance.minorSpecies &&
        instance.species &&
        tylanLowerDosageFDACheck(value, instance)
        ? { messageId: 'validation.vfd.feedConcentrations.tylanBovine' }
        : null;
    };
    return {
      ...super.fields,
      validationLevel: {
        // This property will not be persisted and is only used to provide varying levels of validation feedback eg minimum required to save draft vs ready-to-sign
        initialValue: 'save'
      },
      additionalEmail: {
        initialValue: null,
        validate: additionalEmail => {
          return !additionalEmail
            ? null
            : multipleEmailValidator(
                additionalEmail,
                'validation.vfd.additionalEmail.invalid'
              );
        }
      },
      administrationInstructions: {
        initialValue: ''
      },
      affirmStatement: {
        initialValue: '',
        validate: (affirmStatement, instance) =>
          instance.validationLevel === 'lock'
            ? stringRequired(
                affirmStatement,
                'vfd.combinationDrugs.authStatement.isRequired'
              )
            : null
      },
      comboDrugs: {
        initialValue: [],
        validate: (comboDrugs, instance) => {
          // TODO the affirm statement field should probably be refactored to use enums which would simplify this check.
          const customComboDrugsAffirmStatement =
            'This VFD authorizes the use of the VFD drug(s) cited in this order in THE FOLLOWING FDA-approved, conditionally approved, or indexed combination(s) in medicated feed that contains the VFD drug(s) as a component.';
          if (
            instance.validationLevel === 'lock' &&
            instance.affirmStatement === customComboDrugsAffirmStatement
          ) {
            return arrayRequired('vfd.combinationDrugs', comboDrugs, true);
          }
        }
      },

      // Concentration 1
      concentration1: {
        initialValue: null,
        validate: (concentration1, instance) => {
          // Run validation for draft
          let validationResult = bovineTylanValidateHelper(
            concentration1,
            instance
          );
          if (instance.validationLevel === 'lock' && !validationResult) {
            // Draft validation passes, run lock validation
            if (
              !instance.concentrationRange ||
              !instance.concentrationRange.min ||
              !instance.concentrationRange.max
            )
              validationResult = isNumber(
                concentration1,
                'vfd.feedConcentrations.dosageRequired'
              );
          }
          return validationResult;
        }
      },
      concentration1Days: {
        initialValue: null,
        validate: (concentration1Days, instance) =>
          instance.validationLevel === 'lock' &&
          (!instance.durationRange ||
            !instance.durationRange.min ||
            !instance.durationRange.max)
            ? isInteger(
                concentration1Days,
                'vfd.feedConcentrations.durationRequired'
              )
            : null
      },
      specialInstructions1: {
        initialValue: ''
      },
      // Concentration 2
      concentration2: {
        initialValue: null,
        validate: (concentration2, instance) =>
          instance.validationLevel === 'lock' && instance.concentration2Days
            ? isNumber(concentration2, 'vfd.feedConcentrations.dosageRequired')
            : null
      },
      concentration2Days: {
        initialValue: null,
        validate: (concentration2Days, instance) =>
          instance.validationLevel === 'lock' && instance.concentration2
            ? isInteger(
                concentration2Days,
                'vfd.feedConcentrations.durationRequired'
              )
            : null
      },
      specialInstructions2: {
        initialValue: ''
      },

      // Concentration 3
      concentration3: {
        initialValue: null,
        validate: (concentration3, instance) =>
          instance.validationLevel === 'lock' && instance.concentration3Days
            ? isNumber(concentration3, 'vfd.feedConcentrations.dosageRequired')
            : null
      },
      concentration3Days: {
        initialValue: null,
        validate: (concentration3Days, instance) =>
          instance.validationLevel === 'lock' && instance.concentration3
            ? isInteger(
                concentration3Days,
                'vfd.feedConcentrations.durationRequired'
              )
            : null
      },
      specialInstructions3: {
        initialValue: ''
      },

      concentrationRange: {
        initialValue: null,
        validate: (concentrationRange, instance) => {
          let validationResult = bovineTylanValidateHelper(
            concentrationRange?.min,
            instance
          );
          const hasRange = !!(
            concentrationRange &&
            (Number.isInteger(concentrationRange.min) ||
              Number.isInteger(concentrationRange.max))
          );
          if (
            hasRange ||
            (instance.validationLevel === 'lock' && !instance.concentration1)
          ) {
            if (validationResult == null) {
              validationResult = validRange(
                concentrationRange.min,
                concentrationRange.max
              );
            }
          }
          return validationResult;
        }
      },
      disallowDrugSub: {
        initialValue: undefined
      },
      drug: {
        initialValue: '',
        validate: drug =>
          stringRequired(drug, 'required') || maxLength(drug, 120)
      },
      drug_id: {
        initialValue: null,
        validate: drugId => isInteger(drugId, 'required')
      },
      durationRange: {
        initialValue: null,
        validate: (durationRange, instance) => {
          const hasRange = !!(
            durationRange &&
            (Number.isInteger(durationRange.min) ||
              Number.isInteger(durationRange.max))
          );
          if (
            hasRange ||
            (instance.validationLevel === 'lock' &&
              !instance.concentration1Days)
          ) {
            return validRange(durationRange.min, durationRange.max);
          }
        }
      },
      expirationDate: {
        initialValue: null,
        validate: (expirationDate, instance) => {
          if (expirationDate) {
            return dateIsBefore(
              expirationDate,
              new Date(),
              'expirationDate.notPast'
            );
          }
          return instance.validationLevel === 'lock'
            ? stringRequired(expirationDate, 'required')
            : null;
        }
      },
      feedMill: {
        initialValue: {}
      },
      feedMillEmail: {
        initialValue: '',
        validate: feedMillEmail => {
          return !feedMillEmail
            ? null
            : multipleEmailValidator(
                feedMillEmail,
                'validation.vfd.feedMillEmail.invalid'
              );
        }
      },
      feedSites: {
        initialValue: [],
        validate: (feedSites, instance) =>
          instance.validationLevel === 'lock'
            ? arrayRequired('feedSites', feedSites)
            : null
      },
      fulfilled: {
        initialValue: false
      },
      groupName: {
        initialValue: '',
        validate: (groupName, instance) => {
          if (!groupName && instance && instance.headCount) {
            return null; // Requires groupName or headCount
          }
          return (
            maxLength(groupName, 120) || stringRequired(groupName, 'required')
          );
        }
      },
      headCount: {
        initialValue: null,
        validate: (headCount, instance) => {
          if (
            instance.validationLevel !== 'lock' &&
            !headCount &&
            headCount !== 0 &&
            instance &&
            instance.groupName
          ) {
            return null; // save draft requires groupName or headCount
          } else {
            if (instance.groupName && !headCount && headCount !== 0) {
              return stringRequired(headCount, 'required', null);
            } else if (!instance.groupName && !headCount && headCount !== 0) {
              return stringRequired(headCount, 'required', null);
            }
            return (
              isInteger(headCount, 'validation.vfd.headCount.integer') ||
              numberInRange(
                headCount,
                1,
                1000000000,
                headCount < 1
                  ? 'validation.vfd.headCount.minRange'
                  : 'validation.vfd.headCount.maxRange'
              )
            );
          }
        }
      },
      indication: {
        initialValue: '',
        validate: (indication, instance) =>
          instance.validationLevel === 'lock'
            ? stringRequired(indication, 'required')
            : null
      },
      justifyStatement: {
        initialValue: '',
        validate: (justifyStatement, instance) => {
          return instance.minorSpecies
            ? stringRequired(
                justifyStatement,
                'vfd.minorSpecies.vetRecommendationRequired'
              )
            : null;
        }
      },
      label: {
        initialValue: ''
      },
      minorSpecies: {
        initialValue: ''
      },
      minorSpeciesWithdrawalDays: {
        initialValue: null
      },
      owner: {
        initialValue: {},
        validate: (owner, instance) => {
          return instance.validationLevel === 'lock'
            ? objectRequired('owner', owner)
            : null;
        }
      },
      ownerPremises: {
        initialValue: {},
        validate: (ownerPremises, instance) =>
          instance.validationLevel === 'lock'
            ? objectRequired('ownerPremises', ownerPremises)
            : null
      },
      productionClass: {
        initialValue: '',
        validate: (productionClass, instance) =>
          instance.validationLevel === 'lock' ||
          instance.validationLevel === 'request'
            ? stringRequired(productionClass, 'required')
            : null
      },
      requestId: {
        initialValue: undefined
      },
      shipments: {
        initialValue: []
      },
      species: {
        initialValue: '',
        validate: species =>
          stringRequired(species, 'required') || maxLength(species, 50)
      },

      clinic: {
        initialValue: '',
        validate: (clinic, instance) =>
          instance.validationLevel === 'request'
            ? stringRequired(clinic, 'required')
            : null
      },

      vet: {
        initialValue: null
      }
    };
  }

  static get docType() {
    return 'VFD';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    return super.read(id);
  };

  static getDrafts = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'SAVED',
      limit: 0
    });
    return data.results;
  };

  static getRenewalsDateRange = () => {
    // TODO: Maybe can reuse lib function here
    // return [moment().subtract(3, 'months'), moment().add(14, 'days')];
    return {
      until: moment().add(14, 'days'),
      since: moment().subtract(3, 'months')
    };
  };

  static getRenewals = async () => {
    const dateRange = this.getRenewalsDateRange();
    let searchParams = {
      type: this.docType,
      'metadata.status': 'SIGNED',
      'metadata.renewalStatus': 'NONE',
      expirationSince: dateRange.since.toJSON(),
      expirationUntil: dateRange.until.toJSON(),
      limit: 0
    };
    // dateRangeToParams(searchParams, dateRange, 'expirationSince', 'expirationUntil')
    const data = await super.list(searchParams);
    return data.results;
  };

  static getRenewalReviews = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'PARTIALLY_SIGNED',
      limit: 0
    });
    return data.results;
  };

  static getDraftReviews = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'READY_TO_SIGN',
      limit: 0
    });
    return data.results;
  };

  static getRecentlySigned = async () => {
    return super.getRecentlySigned(this.docType);
  };

  static getActiveCerts = async () => {
    const data = await super.list({
      type: this.docType,
      'metadata.status': 'SIGNED',
      expirationSince: moment().toJSON(),
      limit: 0,
      sort: '-effectiveDate'
    });
    return data.results;
  };

  static renewalRemove = async id => {
    const record = await api.put(
      `${this.apiPath}/${id}?protectedAction=markDoNotRenew`
    );
    return new this(record);
  };

  static renewalReject = async id => {
    const record = await api.put(
      `${this.apiPath}/${id}?protectedAction=rejectAsRenewal`
    );
    // for some reason the api returns an enum for renewalStatus, we need
    // to make it a string for the selectors to work
    if (record.metadata.renewalStatus.name) {
      record.metadata.renewalStatus = record.metadata.renewalStatus.name;
    }
    return new this(record);
  };

  static renewalReview = async (id, data) => {
    const record = await api.put(`${this.apiPath}/renew/${id}`, data);
    return new this(record);
  };

  static saveLogShipment = async (id, shipmentData, actionType) => {
    return await api.put(
      `${this.apiPath}/${id}?protectedAction=${actionType}`,
      shipmentData
    );
  };

  static searchCertificate = async params => {
    const data = await api.get(`${this.apiPath}/verify`, { params });
    return new this(data);
  };

  static claimCertificate = async (id, feedMillReferenceId) => {
    const data = await api.put(`${this.apiPath}/${id}`, {
      protectedAction: 'claim',
      entity: 'feedMill',
      reference: feedMillReferenceId
    });
    return new this(data);
  };

  static downloadAuditReport = async docId =>
    downloadFile(`${this.apiPath}/${docId}/auditReport`);

  static transferToOtherLocation = async (docId, feedMillData) => {
    return await api.put(
      `${this.apiPath}/${docId}?protectedAction=switchMill`,
      feedMillData
    );
  };
}
