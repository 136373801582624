import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Table } from 'antd';
import { TableBorder } from 'style/commonEmotions';
import isolateDocumentTableColumns from 'modules/Isolate/components/isolateDocumentTableColumns';

function IsolateDocumentTablesComponent({
  isolate,
  releases,
  attachments = [],
  deleteAttachment = () => {},
  actionableAttachments = true,
  isAttachmentTableVisible = true
}) {
  const isolateReleases = releases[isolate.isolateId]
    ? Object.values(releases[isolate.isolateId])
    : [];
  const columns = isolateDocumentTableColumns.generateColumns(
    deleteAttachment,
    isolate.isolateId
  );
  const getAttachmentColumnType = () => {
    if (actionableAttachments) {
      return isolate.isolateId
        ? columns.savedAttachment
        : columns.newAttachment;
    } else {
      return columns.nonActionableAttachment;
    }
  };

  return (
    <React.Fragment>
      {attachments.length ||
      (isAttachmentTableVisible &&
        !isolateReleases.length &&
        !isolate.extensionRequest?.status === 'APPROVED') ? (
        <TableBorder>
          <Table
            className="attachments-table-long-filenames"
            size="small"
            dataSource={attachments}
            columns={getAttachmentColumnType()}
            pagination={false}
            rowKey={isolate.isolateId ? 'id' : 'uid'}
          />
        </TableBorder>
      ) : null}
      {isolateReleases.length ? (
        <React.Fragment>
          {attachments.length ? <br /> : null}
          <h4>{intl.get('releases')}</h4>
          <TableBorder>
            <Table
              size="small"
              dataSource={isolateReleases}
              columns={columns.release}
              pagination={false}
              rowKey="isolateReleaseId"
            />
          </TableBorder>
        </React.Fragment>
      ) : null}
      {isolate.extensionRequest?.status === 'APPROVED' ? (
        <React.Fragment>
          {attachments.length && releases.length ? <br /> : null}
          <h4>{intl.get('extension.electronic')}</h4>
          <TableBorder>
            <Table
              size="small"
              dataSource={[isolate.extensionRequest]}
              columns={columns.extension}
              pagination={false}
              rowKey="extensionRequestId"
            />
          </TableBorder>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

IsolateDocumentTablesComponent.propTypes = {
  isolate: PropTypes.object.isRequired,
  releases: PropTypes.object,
  attachments: PropTypes.array,
  deleteAttachment: PropTypes.func,
  actionableAttachments: PropTypes.bool,
  isAttachmentTableVisible: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    releases: state.accounts.isolateReleases.data
  };
};

const IsolateDocumentTables = connect(
  mapStateToProps,
  null
)(IsolateDocumentTablesComponent);

export { IsolateDocumentTables as default, IsolateDocumentTablesComponent };
