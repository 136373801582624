import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';

import {
  DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS,
  DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED,
  DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED,
  DISTRIBUTOR_REFRESH_VFD_LIST_STARTED,
  DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS,
  DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE,
  DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS,
  DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED,
  DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED,
  DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED,
  DISTRIBUTOR_UPLOAD_REQUEST_UPDATED,
  DISTRIBUTOR_UPLOADS_LIST_READY,
  DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST,
  DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE,
  DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST,
  DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED,
  DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE,
  DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED,
  DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED,
  DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE,
  DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR,
  DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED,
  DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS,
  DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE,
  DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS,
  DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE,
  DISTRIBUTOR_REFRESH_REQUEST_REQUEST,
  DISTRIBUTOR_REFRESH_REQUEST_SUCCESS,
  DISTRIBUTOR_REFRESH_REQUEST_FAILURE,
  DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED,
  DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY,
  DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS
} from 'containers/accounts/distributor/store/accountsDistributorConstants';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: {
    vfdDrugSpecies: null,
    ads: null,
    listMills: null
  },
  dataLists: {
    Vfd: {
      data: {
        documents: {}
      },
      searchedCertificate: null,
      refreshing: false,
      loading: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      certificateClaimed: false
    },

    uploadedDocuments: {
      data: {
        documents: {}
      },
      refreshing: false,
      documentUploaded: false,
      loading: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    Feedmills: {
      data: {
        feedIds: [],
        documents: {}
      },
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    },
    uploadedDocument: {
      // Search uploaded documents
      data: {
        uploadedDocIds: [],
        documents: {}
      },
      refreshing: false,
      searchComplete: false,
      lastRefreshedTime: null,
      refreshFailure: false
    }
  },
  requestsLists: {
    request: {
      documents: {},
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false
    }
  }
});

export const onDistributorLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      vfdDrugSpecies: startupData.vfdDrugSpecies,
      ads: startupData.ads,
      listMills: startupData.listMills
    })
  });

export const onDistributorLoadStartupDataStarted = state =>
  state.merge({
    retrievingStartupData: true
  });

export const onDistributorLoadStartupDataFinished = state =>
  state.merge({
    retrievingStartupData: false
  });

export const onDistributorRefreshVfdListSuccess = (state, lists) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(vfd => (documents[vfd.id] = vfd));
    }
  });
  return state.setIn(['dataLists', 'Vfd'], {
    data: { documents: documents },
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};
export const onDistributorTransferLocationSuccess = (state, payload) => {
  const newState = Immutable.setIn(
    state,
    ['dataLists', 'Vfd', 'refreshing'],
    false
  );
  return Immutable.setIn(
    newState,
    ['dataLists', 'Vfd', 'data', 'documents', payload.transferredMill.id],
    payload.transferredMill
  );
};
export const onDistributorTransferLocationFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      Vfd: {
        data: initialState.dataLists.Vfd.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });
export const onDistributorRefreshVfdListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      Vfd: {
        data: state.dataLists.Vfd.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onDistributorRefreshVfdListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      Vfd: {
        data: initialState.dataLists.Vfd.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

//certificatesShow
export const onDistributorCertificateRequestSuccess = (state, payload) => {
  const { vfd } = payload;
  return state.updateIn(['dataLists', 'Feedmills'], vfdInfo => ({
    ...vfdInfo,
    refreshing: false,
    data: { documents: { ...vfdInfo.documents, [vfd.id]: vfd } }
  }));
};

export const onDistributorSearchCertificateStarted = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        searchedCertificate: state.searchedCertificate,
        loading: true
      })
    })
  });
};
export const onDistributorSearchCertificateSuccess = (state, data) => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        searchedCertificate: data.searchedCertificate,
        loading: false
      })
    })
  });
};
export const onDistributorSearchCertificateInit = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        searchedCertificate: initialState.searchedCertificate,
        loading: false
      })
    })
  });
};

export const onDistributorSearchCertificateClear = state =>
  state.setIn(
    ['dataLists', 'Vfd', 'searchedCertificate'],
    initialState.dataLists.Vfd.searchedCertificate
  );

export const onDistributorClaimCertificateStarted = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        loading: true,
        certificateClaimed: false
      })
    })
  });
};
export const onDistributorClaimCertificateSuccess = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        loading: false,
        certificateClaimed: true
      })
    })
  });
};
export const onDistributorClaimCertificateFailure = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        loading: false,
        certificateClaimed: false
      })
    })
  });
};
export const onDistributorClaimCertificateEnded = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      Vfd: state.dataLists.Vfd.merge({
        loading: false,
        certificateClaimed: false
      })
    })
  });
};

export const onDistributorRefreshUploadedDocumentListSuccess = (
  state,
  lists
) => {
  let documents = {};
  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(document => {
        documents[document.id] = document;
      });
    }
  });
  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: state.dataLists.uploadedDocuments.merge({
        data: state.dataLists.uploadedDocuments.data.merge({
          documents: documents
        }),
        refreshing: false,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      })
    })
  });
};

export const onDistributorRefreshUploadedDocumentListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: {
        data: state.dataLists.uploadedDocuments.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onDistributorRefreshUploadedDocumentListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: {
        data: state.dataLists.uploadedDocuments.data,
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onDistributorUploadCertificateStarted = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: state.dataLists.uploadedDocuments.merge({
        loading: true,
        documentUploaded: false
      })
    })
  });
};
export const onDistributorUploadCertificateSuccess = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: state.dataLists.uploadedDocuments.merge({
        loading: false,
        documentUploaded: true
      })
    })
  });
};
export const onDistributorUploadCertificateFailure = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: state.dataLists.uploadedDocuments.merge({
        loading: false,
        documentUploaded: false
      })
    })
  });
};
export const onDistributorUploadCertificateEnded = state => {
  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: state.dataLists.uploadedDocuments.merge({
        loading: false,
        documentUploaded: false
      })
    })
  });
};

export const onDistributorSearchCertificatesSuccess = (state, payload) => {
  const { list } = payload;
  let feedIds = [];
  let documents = {};
  list.forEach(Feedmills => {
    feedIds.push(Feedmills.id);
    documents[Feedmills.id] = Feedmills;
  });

  return state.merge({
    dataLists: state.dataLists.merge({
      Feedmills: state.dataLists.Feedmills.merge({
        data: state.dataLists.Feedmills.data.merge({
          feedIds: feedIds,
          documents: documents
        }),
        refreshing: false,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      })
    })
  });
};

export const onDistributorSearchCertificatesStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      Feedmills: {
        data: state.dataLists.Feedmills.data,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onDistributorSearchCertificatesFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      Feedmills: {
        data: initialState.dataLists.Feedmills.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onDistributorRefreshUploadedDocumentSearchListSuccess = (
  state,
  payload
) => {
  const { list } = payload;
  let uploadedDocIds = [];
  let documents = {};
  list.forEach(uploadedDocument => {
    uploadedDocIds.push(uploadedDocument.id);
    documents[uploadedDocument.id] = uploadedDocument;
  });

  return state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocument: state.dataLists.uploadedDocument.merge({
        data: state.dataLists.uploadedDocument.data.merge({
          uploadedDocIds: uploadedDocIds,
          documents: documents
        }),
        refreshing: false,
        searchComplete: true,
        lastRefreshedTime: new Date().getTime(),
        refreshFailure: false
      })
    })
  });
};

export const onDistributorRefreshUploadedDocumentSearchListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocument: {
        data: state.dataLists.uploadedDocument.data,
        refreshing: true,
        searchComplete: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onDistributorRefreshUploadedDocumentSearchListFailure = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocument: {
        data: initialState.dataLists.uploadedDocument.data, // Empty lists
        refreshing: false,
        lastRefreshedTime: null,
        refreshFailure: true
      }
    })
  });

export const onDistributorRefreshUploadsListStarted = state =>
  state.merge({
    dataLists: state.dataLists.merge({
      uploadedDocuments: {
        data: state.dataLists.uploadedDocuments.data,
        refreshing: true,
        searchComplete: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    })
  });

export const onDistributorUploadRequestUpdated = (state, payload) =>
  Immutable.setIn(
    state,
    [
      'dataLists',
      'uploadedDocuments',
      'data',
      'documents',
      payload.uploadedDocument.id
    ],
    payload.uploadedDocument
  );

export const onDistributorUploadsListReady = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'uploadedDocuments', 'refreshing'],
    false
  );

export const onDistributorUploadRemovedFromList = (state, payload) =>
  state.updateIn(
    ['dataLists', 'uploadedDocuments', 'data', 'documents'],
    documents => documents.without(payload.id)
  );

export const onDistributorUploadedDocumentRemovedFromList = (state, payload) =>
  state.updateIn(
    ['dataLists', 'uploadedDocument', 'data', 'documents'],
    documents => documents.without(payload.id)
  );

export const onDistributorUploadedDocumentListUpdated = (state, payload) =>
  Immutable.setIn(
    state,
    [
      'dataLists',
      'uploadedDocument',
      'data',
      'documents',
      payload.uploadedDocument.id
    ],
    payload.uploadedDocument
  );

export const onDistributorUploadedDocumentListReady = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'uploadedDocument', 'refreshing'],
    false
  );

export const onDistributorUploadedDocumentDeleted = (state, payload) =>
  state.updateIn(['dataLists', 'uploadedDocument', 'data', 'documents'], data =>
    data.without(payload.id)
  );
export const onDistributorUploadedDocumentsListReady = state =>
  Immutable.setIn(
    state,
    ['dataLists', 'uploadedDocument', 'refreshing'],
    false
  );
//requests
export const onDistributorRefreshRequestsListSuccess = (state, lists) => {
  let documents = {};

  Object.values(lists).forEach(list => {
    if (Array.isArray(list)) {
      list.forEach(Requested => (documents[Requested.id] = Requested));
    }
  });

  return state.setIn(['requestsLists', 'request'], {
    documents: documents,
    refreshing: false,
    lastRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onDistributorRefreshRequestsListStarted = state =>
  state.merge({
    requestsLists: {
      request: {
        documents: state.requestsLists.request.documents,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    }
  });

export const onDistributorRefreshRequestsListFailure = state =>
  state.merge({
    requestsLists: {
      request: {
        documents: state.requestsLists.request.documents,
        refreshing: true,
        lastRefreshedTime: null,
        refreshFailure: false
      }
    }
  });

//feedmillShow
export const onDistributorRefreshRequestSuccess = (state, payload) => {
  const { request } = payload;
  return state.updateIn(['requestsLists', 'request'], requestInfo => ({
    ...requestInfo,
    refreshing: false,
    documents: { ...requestInfo.documents, [request.id]: request }
  }));
};

export const onDistributorRefreshRequestRequest = state => {
  return state.updateIn(['requestsLists', 'request'], requestInfo => ({
    ...requestInfo,
    refreshing: true,
    lastRefreshedTime: null,
    refreshFailure: false
  }));
};

export const onDistributorRefreshRequestFailure = state => {
  return state.updateIn(['requestsLists', 'request'], requestInfo => ({
    ...requestInfo,
    refreshing: false,
    refreshFailure: true
  }));
};

const accountsDistributorReducer = createReducer(initialState, {
  [DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS]: onDistributorLoadStartupDataSuccess,
  [DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED]: onDistributorLoadStartupDataStarted,
  [DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED]: onDistributorLoadStartupDataFinished,

  [DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS]: onDistributorRefreshVfdListSuccess,
  [DISTRIBUTOR_REFRESH_VFD_LIST_STARTED]: onDistributorRefreshVfdListStarted,
  [DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE]: onDistributorRefreshVfdListFailure,
  [DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS]: onDistributorTransferLocationSuccess,
  [DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE]: onDistributorTransferLocationFailure,

  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED]: onDistributorRefreshUploadedDocumentListStarted,
  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS]: onDistributorRefreshUploadedDocumentListSuccess,
  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE]: onDistributorRefreshUploadedDocumentListFailure,

  [DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS]: onDistributorSearchCertificatesSuccess,
  [DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED]: onDistributorSearchCertificatesStarted,
  [DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE]: onDistributorSearchCertificatesFailure,

  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS]: onDistributorRefreshUploadedDocumentSearchListSuccess,
  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED]: onDistributorRefreshUploadedDocumentSearchListStarted,
  [DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE]: onDistributorRefreshUploadedDocumentSearchListFailure,

  [DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST]: onDistributorUploadedDocumentRemovedFromList,
  [DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED]: onDistributorUploadedDocumentListUpdated,
  [DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY]: onDistributorUploadedDocumentListReady,

  [DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED]: onDistributorRefreshUploadsListStarted,
  [DISTRIBUTOR_UPLOAD_REQUEST_UPDATED]: onDistributorUploadRequestUpdated,
  [DISTRIBUTOR_UPLOADS_LIST_READY]: onDistributorUploadsListReady,
  [DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST]: onDistributorUploadRemovedFromList,

  [DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED]: onDistributorUploadedDocumentDeleted,
  [DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY]: onDistributorUploadedDocumentsListReady,

  [DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS]: onDistributorSearchCertificateSuccess,
  [DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED]: onDistributorSearchCertificateStarted,
  [DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE]: onDistributorSearchCertificateInit,
  [DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR]: onDistributorSearchCertificateClear,

  [DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED]: onDistributorClaimCertificateStarted,
  [DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS]: onDistributorClaimCertificateSuccess,
  [DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE]: onDistributorClaimCertificateFailure,
  [DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED]: onDistributorClaimCertificateEnded,
  //request
  [DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED]: onDistributorRefreshRequestsListStarted,
  [DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS]: onDistributorRefreshRequestsListSuccess,
  [DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE]: onDistributorRefreshRequestsListFailure,

  [DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED]: onDistributorUploadCertificateStarted,
  [DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS]: onDistributorUploadCertificateSuccess,
  [DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE]: onDistributorUploadCertificateFailure,
  [DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED]: onDistributorUploadCertificateEnded,
  //request
  [DISTRIBUTOR_REFRESH_REQUEST_REQUEST]: onDistributorRefreshRequestRequest,
  [DISTRIBUTOR_REFRESH_REQUEST_SUCCESS]: onDistributorRefreshRequestSuccess,
  [DISTRIBUTOR_REFRESH_REQUEST_FAILURE]: onDistributorRefreshRequestFailure,

  [DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS]: onDistributorCertificateRequestSuccess
});
export default accountsDistributorReducer;
