import React from 'react';
import PropTypes from 'prop-types';
import GvlIcon from 'elements/GvlIcon';
import { Row, Col } from 'antd';

export default function IsolateValWithIcon({ icon, val }) {
  return (
    <Row type="flex">
      <Col flex="auto">
        <GvlIcon type={icon} style={{ paddingRight: '4px' }} />
      </Col>
      <Col flex="auto">{val ? val : '-'}</Col>
    </Row>
  );
}

IsolateValWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  val: PropTypes.any
};
