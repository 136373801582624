import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as types from 'modules/User/store/userActionTypes';
import * as actions from 'modules/User/store/userActions';
import * as api from 'utils/api';
import Account from 'models/Account';

export function* onLoadUsersRequest({ accountId }) {
  try {
    yield put(actions.actionInProgress(true));
    const users = yield call(api.get, `accounts/${accountId}/users`);
    yield put(actions.loadUsersSuccess(users));
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress(false));
  }
}

export function* onSaveUserRequest({ accountId, user }) {
  try {
    yield put(actions.actionInProgress(true));
    const updatedUser = yield call(
      Account.updateUser,
      accountId,
      user.id,
      user
    );
    yield put(actions.saveUserSuccess(updatedUser));
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress(false));
  }
}

export default function* userSagas() {
  yield takeLatest(types.LOAD_USERS_REQUEST, onLoadUsersRequest);
  yield takeLatest(types.SAVE_USER_REQUEST, onSaveUserRequest);
}
