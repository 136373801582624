import * as userRoles from 'utils/userRoles';
import * as appAccounts from 'utils/appAccounts';

export const hasOneOfRoles = (user, roles) => {
  if (
    !user ||
    !user.roles ||
    !(user.roles instanceof Array) ||
    user.roles.length === 0
  ) {
    return false;
  }

  if (!roles || !(roles instanceof Array) || roles.length === 0) {
    return false;
  }

  return user.roles.some(role => roles.includes(role));
};

// This is used to route a user by their role.
// Since user.roles can contain more than one role, make sure to assign higher level app first.
// For example, a vet can have ROLE_VET and ROLE_ANIMAL_OWNER.  They should get the clinic app, not the animal owner app.
export const getAppAccountForUser = user => {
  if (isAdminSupportUser(user)) {
    return appAccounts.APP_ACCOUNT_ADMIN;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_VET, userRoles.ROLE_VET_TECH])) {
    return appAccounts.APP_ACCOUNT_CLINIC;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_LAB_TECH])) {
    return appAccounts.APP_ACCOUNT_LABTECH;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_STATE_VET])) {
    return appAccounts.APP_ACCOUNT_REGULATORY;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_ANIMAL_OWNER])) {
    // User that has this role at this point in the logic is an MVL Owner
    return appAccounts.APP_ACCOUNT_MVL_OWNER;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_DISTRIBUTOR_VFD])) {
    return appAccounts.APP_DISTRIBUTOR_VFD;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_AGENT])) {
    return appAccounts.APP_ACCOUNT_AGENT;
  }
  if (hasOneOfRoles(user, [userRoles.ROLE_NVSL_LAB_TECH])) {
    return appAccounts.APP_ACCOUNT_NVSL_LABTECH;
  }
  if (
    hasOneOfRoles(user, [
      userRoles.ROLE_ISOLATE_CREATOR,
      userRoles.ROLE_ISOLATE_QUALITY_ASSURANCE,
      userRoles.ROLE_ISOLATE_APPROVER,
      userRoles.ROLE_ISOLATE_SUPPORT,
      userRoles.ROLE_ISOLATE_SUPPORT_MANAGER
    ])
  ) {
    return appAccounts.APP_ACCOUNT_ISOLATES;
  }
};

export const getAppAccountLinkForUser = user => {
  const appAccountForUser = getAppAccountForUser(user);
  const appAccountLink = appAccounts.accountLink[appAccountForUser];
  return appAccountLink ? appAccountLink + user.info.account.id : null;
};

export const getAppAccountNameIdForUser = user => {
  const appAccountForUser = getAppAccountForUser(user);
  return appAccounts.accountNameId[appAccountForUser];
};

export const hasForSale = user => {
  if (!user || !user.info || !user.info.betaFeatures) {
    return false;
  }
  return user.info.betaFeatures.includes('forSaleAccess');
};

// TODO: Move this, and other user logics to userSelectors
export const isCanadian = user => {
  if (!user || !user.info || !user.info.userCountry) {
    return false;
  }
  return user.info.userCountry === 'Canada';
};

export const isAccountManager = user => {
  return hasOneOfRoles(user, [userRoles.ROLE_ACCOUNT_MANAGER]);
};

export const generateInitials = name => {
  if (name === null || name === undefined || typeof name !== 'string') {
    return '';
  }
  name = name.toUpperCase();
  let names = name.split(' ');
  let initials = names[0].charAt(0);
  if (names.length > 1) {
    return initials.concat(names[names.length - 1].charAt(0));
  }
  return initials;
};

export const isAdminSupportUser = user => {
  return hasOneOfRoles(user, [
    userRoles.ROLE_SUPPORT,
    userRoles.ROLE_SUPPORT_ADMIN,
    userRoles.ROLE_ADMIN,
    userRoles.ROLE_SUPERUSER,
    userRoles.ROLE_LABLINK_HIMS_ONLY_ADMIN,
    userRoles.ROLE_BILLING_SPECIALIST
  ]);
};

export const isIsolateUser = user => {
  return hasOneOfRoles(user, [
    userRoles.ROLE_ISOLATE_CREATOR,
    userRoles.ROLE_ISOLATE_QUALITY_ASSURANCE,
    userRoles.ROLE_ISOLATE_APPROVER,
    userRoles.ROLE_ISOLATE_SUPPORT,
    userRoles.ROLE_ISOLATE_SUPPORT_MANAGER
  ]);
};
