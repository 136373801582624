import * as types from 'modules/License/store/licenseActionTypes';
import { createAction } from 'utils/reduxUtil';

// Generic Actions
export const isRefreshing = isRefreshing =>
  createAction(types.IS_REFRESHING, { isRefreshing });

// List Actions
export const listRequest = (vetId, callback) =>
  createAction(types.LIST_REQUEST, { vetId, callback });
export const listSuccess = licenses =>
  createAction(types.LIST_SUCCESS, { licenses });
export const listFailure = () => createAction(types.LIST_FAILURE);

// refresh
export const refreshRequest = licenseId =>
  createAction(types.REFRESH_REQUEST, { licenseId });
export const refreshSuccess = license =>
  createAction(types.REFRESH_SUCCESS, { license });

// save Actions
export const saveRequest = (license, history) =>
  createAction(types.SAVE_REQUEST, { license, history });

// delete Actions
export const deleteRequest = params =>
  createAction(types.DELETE_REQUEST, { params });
export const deleteSuccess = licenseId =>
  createAction(types.DELETE_SUCCESS, { licenseId });

// verification Actions
export const validateFederalLicense = params =>
  createAction(types.VALIDATE_FEDERAL_LICENSE, { params });
