import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import Document from 'models/documents/Document';
import Eecvi from 'models/documents/Eecvi';
import Animal from 'models/Animal';
import * as api from 'utils/api';
import intl from 'react-intl-universal';

import {
  mvlOwnerLoadStartupDataSuccess,
  mvlOwnerLoadStartupDataStarted,
  mvlOwnerLoadStartupDataFinished,
  mvlOwnerRefreshDocumentListRequest,
  mvlOwnerRefreshDocumentListStarted,
  mvlOwnerRefreshDocumentListSuccess,
  mvlOwnerRefreshDocumentListFailure,
  mvlOwnerRefreshAnimalListRequest,
  mvlOwnerRefreshAnimalListStarted,
  mvlOwnerRefreshAnimalListSuccess,
  mvlOwnerRefreshAnimalListFailure,
  mvlOwnerCreateEecviPermitsFinished,
  mvlOwnerCreateEecviPermitsNotWaiting
} from 'containers/accounts/mvlOwner/store/accountsMvlOwnerActions';
import {
  MVL_OWNER_LOAD_STARTUP_DATA_REQUEST,
  MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST,
  MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST,
  MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST
} from 'containers/accounts/mvlOwner/store/accountsMvlOwnerConstants';

import { apiFailure } from 'containers/app/store/data/dataActions';
import { connectionError } from 'containers/app/store/data/dataActions';

export function* onMvlOwnerLoadStartupDataRequest(action) {
  try {
    yield put(mvlOwnerLoadStartupDataStarted());

    const [vfdDrugSpecies, scriptDrugSpecies] = yield all([
      call(api.get, '/drug/species?type=feed'),
      call(api.get, '/drug/species?type=script')
    ]);

    yield put(
      mvlOwnerLoadStartupDataSuccess({
        vfdDrugSpecies,
        scriptDrugSpecies
      })
    );
    yield put(mvlOwnerRefreshAnimalListRequest());
    yield put(mvlOwnerRefreshDocumentListRequest());
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(mvlOwnerLoadStartupDataFinished());
  }
}

export function* onMvlOwnerRefreshDocumentListRequest(action) {
  try {
    yield put(mvlOwnerRefreshDocumentListStarted());

    const documents = yield call(Document.getMvlOwnerDocuments);

    yield put(mvlOwnerRefreshDocumentListSuccess(documents));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading MVL Owner Document List data'));
    yield put(mvlOwnerRefreshDocumentListFailure());
  }
}

export function* onMvlOwnerRefreshAnimalListRequest(action) {
  try {
    yield put(mvlOwnerRefreshAnimalListStarted());
    const animals = yield call(Animal.getMvlOwnerAnimals);
    yield put(mvlOwnerRefreshAnimalListSuccess(animals));
  } catch (err) {
    console.log(err);
    yield put(apiFailure('Error loading MVL Owner Animal List data'));
    yield put(mvlOwnerRefreshAnimalListFailure());
  }
}

export function* onMvlOwnerCreateEecviPermitsRequest(action) {
  const eecviIds = yield select(
    state => state.accounts.mvlOwner.dataLists.document.creatingPermitsForEecvis
  );
  const updatedEecvis = [];
  const failedIds = [];
  const apiErrors = [];
  for (let eecviIndex = 0; eecviIndex < eecviIds.length; eecviIndex++) {
    const eecviId = eecviIds[eecviIndex];
    try {
      const updatedEecvi = yield call(
        Eecvi.commitPermit,
        eecviId,
        action.permitData
      );
      updatedEecvis.push(updatedEecvi);
    } catch (e) {
      failedIds.push(eecviId);
      apiErrors.push(e);
    }
  }
  if (failedIds.length > 0) {
    apiErrors.forEach(e => {
      api.handleApiErrorsWithTitle(
        e.response?.data?.errors,
        intl.get('error.saving.noun', { noun: intl.get('cvi.permit') })
      );
    });
    yield put(mvlOwnerCreateEecviPermitsNotWaiting());
  } else {
    yield put(mvlOwnerCreateEecviPermitsFinished(updatedEecvis, failedIds));
  }
}

export default function* accountsMvlOwnerSagas() {
  yield takeLatest(
    MVL_OWNER_LOAD_STARTUP_DATA_REQUEST,
    onMvlOwnerLoadStartupDataRequest
  );
  yield takeLatest(
    MVL_OWNER_REFRESH_DOCUMENT_LIST_REQUEST,
    onMvlOwnerRefreshDocumentListRequest
  );
  yield takeLatest(
    MVL_OWNER_REFRESH_ANIMAL_LIST_REQUEST,
    onMvlOwnerRefreshAnimalListRequest
  );
  yield takeLatest(
    MVL_OWNER_CREATE_EECVI_PERMITS_REQUEST,
    onMvlOwnerCreateEecviPermitsRequest
  );
}
