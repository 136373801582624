export const tylanLowerDosageFDACheck = (dosage, formValues) => {
  return tylanBovineCheck(formValues) && dosage < 8 && dosage > 0;
};

export const tylanBovineCheck = formValues => {
  //VFD-181 ---- 110 and 109 are the drug ids of Bovine Tylan
  return (
    formValues.species === 'Bovine' && [110, 109].includes(formValues.drug_id)
  );
};

export const getTylanLowerDosageFDAStatement = (
  prevState,
  formValues,
  dosageText
) => {
  //that is the statement that the FDA requires to be on the certificate. Because it comes from them, we can not change it
  return {
    ...prevState.requiredFDAStatements,
    tylanLowerDosageSpecialInstructions: `The level of tylosin in this VFD feed should contain ${
      dosageText ? dosageText : formValues.concentration1
    } g/ton tylosin phosphate to ensure that the intake of tylosin phosphate will be within the 60-90 mg/h/day daily intake range. Reference FDA Dear Veterinarian letter of 12/19/2018.`
  };
};
