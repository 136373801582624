import * as types from './isolateActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });

// list
export const listRequest = currentUser =>
  createAction(types.LIST_REQUEST, { currentUser });
export const listSuccess = isolates =>
  createAction(types.LIST_SUCCESS, { isolates });
export const listFailure = () => createAction(types.LIST_FAILURE);
export const searchRequest = (searchType, searchParams, cb) =>
  createAction(types.SEARCH_REQUEST, { searchType, searchParams, cb });
export const searchSuccess = isolates =>
  createAction(types.SEARCH_SUCCESS, { isolates });
export const searchFailure = () => createAction(types.SEARCH_FAILURE);

// refresh
export const activateIsolate = isolateId =>
  createAction(types.ACTIVATE_ISOLATE, { isolateId });
export const refreshRequest = (isolateId, refreshReleases = false) =>
  createAction(types.REFRESH_REQUEST, { isolateId, refreshReleases });
export const refreshSuccess = isolate =>
  createAction(types.REFRESH_SUCCESS, { isolate });
export const refreshIsolatesForProduct = (productId, callbackFn) =>
  createAction(types.REFRESH_ISOLATES_FOR_PRODUCT, { productId, callbackFn });
export const refreshIsolatesForProductSuccess = isolates =>
  createAction(types.REFRESH_ISOLATES_FOR_PRODUCT_SUCCESS, { isolates });

// save
export const saveRequest = (
  isolate,
  history,
  attachments,
  callback,
  typeOfSave
) =>
  createAction(types.SAVE_REQUEST, {
    isolate,
    history,
    attachments,
    callback,
    typeOfSave
  });

// archive/delete
export const archiveRequest = (isolateId, history) =>
  createAction(types.ARCHIVE_REQUEST, { isolateId, history });
export const deleteRequest = (isolateId, history) =>
  createAction(types.DELETE_REQUEST, { isolateId, history });

export const removeIsolateFromStore = isolateId =>
  createAction(types.REMOVE_ISOLATE_FROM_STORE, { isolateId });

// audit report
export const auditReportRequest = isolateId =>
  createAction(types.AUDIT_REPORT_REQUEST, { isolateId });

export const auditReportSuccess = (isolateId, events) =>
  createAction(types.AUDIT_REPORT_SUCCESS, { isolateId, events });

export const isRefreshingEvents = newStatus =>
  createAction(types.IS_REFRESHING_EVENTS, { isRefreshingEvents: newStatus });

// extension requests
export const extensionSaveRequest = extension =>
  createAction(types.EXTENSION_SAVE_REQUEST, { extension });
export const extensionSignRequest = extension =>
  createAction(types.EXTENSION_SIGN_REQUEST, { extension });
export const deleteExtensionRequest = (isolate, history = null) =>
  createAction(types.DELETE_EXTENSION_REQUEST, { isolate, history });
export const approveExtensionRequest = (isolate, history) =>
  createAction(types.APPROVE_EXTENSION_REQUEST, { isolate, history });
export const extensionChangesRequest = (extension, history) =>
  createAction(types.EXTENSION_CHANGES_REQUEST, { extension, history });

// release requests
export const releaseSaveRequest = (release, history) =>
  createAction(types.RELEASE_SAVE_REQUEST, { release, history });
export const deleteReleaseRequest = isolate =>
  createAction(types.DELETE_RELEASE_REQUEST, { isolate });
export const approveReleaseRequest = (release, history) =>
  createAction(types.APPROVE_RELEASE_REQUEST, { release, history });
export const declineReleaseRequest = (release, history) =>
  createAction(types.DECLINE_RELEASE_REQUEST, { release, history });
export const releaseInfoRequest = (release, history) =>
  createAction(types.RELEASE_INFO_REQUEST, { release, history });

// store default diagnostic list in the store
export const storeDefaultDiagnosticLab = defaultDiagnosticLab =>
  createAction(types.DEFAULT_DIAGNOSTIC_LAB_REQUEST, {
    defaultDiagnosticLab: defaultDiagnosticLab
  });
