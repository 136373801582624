// general constants
export const IS_REFRESHING = 'product/IS_REFRESHING';

// list constants
export const LIST_REQUEST = 'product/LIST_REQUEST';
export const LIST_SUCCESS = 'product/LIST_SUCCESS';
export const LIST_FAILURE = 'product/LIST_FAILURE';

// search constants
export const SEARCH_REQUEST = 'product/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'product/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'product/SEARCH_FAILURE';

// refresh
export const REFRESH_REQUEST = 'product/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'product/REFRESH_SUCCESS';

// save
export const SAVE_REQUEST = 'product/SAVE_REQUEST';

// delete
export const DELETE_REQUEST = 'product/DELETE_REQUEST';

// remove from store
export const REMOVE_FROM_STORE = 'product/REMOVE_FROM_STORE';
