import React from 'react';
import { Menu, Typography } from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GvlIcon from 'elements/GvlIcon';
import { FONT, THEME } from 'style';

const { Text } = Typography;

// TODO: Need to simplify menu item styles. This could and should be refactored post project Helen completion.

const StyledMenuItem = styled(Menu.Item)({
  height: '32px !important',
  lineHeight: '32px !important',
  fontWeight: FONT.weight.bold
  // paddingLeft: '12px !important',
});

const StyledTopMenuItem = styled(Menu.Item)({
  height: '32px !important',
  lineHeight: '32px !important',
  fontWeight: FONT.weight.bold,
  paddingLeft: '12px !important',
  color: THEME.text() + '!important'
});

const StyledIcon = styled(GvlIcon)({
  fontSize: '20px !important',
  position: 'relative',
  top: '2px',
  color: THEME.text() + '!important'
});

const StyledText = styled(Text)({
  color: THEME.text() + '!important'
});

export default function GvlMenuItem({
  link,
  title,
  children,
  external,
  isTopLevel,
  icon,
  ...rest
}) {
  return isTopLevel ? (
    <StyledTopMenuItem key={link} {...rest}>
      {external ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a target="_blank" href={link} rel="noopener">
          <StyledIcon type={icon} />
          {title ? title : children}
        </a>
      ) : (
        <Link to={link}>
          <StyledIcon type={icon} />
          <StyledText>{title}</StyledText>
        </Link>
      )}
    </StyledTopMenuItem>
  ) : (
    <StyledMenuItem {...rest}>
      {external ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a target="_blank" href={link} rel="noopener">
          {title ? title : children}
        </a>
      ) : (
        <Link to={link}>{title ? title : children}</Link>
      )}
    </StyledMenuItem>
  );
}

GvlMenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  external: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  icon: PropTypes.node
};

GvlMenuItem.defaultProps = {
  external: false
};
