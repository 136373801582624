/**
 * process.env items are configured in .env files or environment variables
 *   and can be overwritten at build time
 * see https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
 */

/** prefix for all api calls */
export const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const DEPLOY_PLATFORM =
  'localhost' === window.location.hostname || 'lcl' === window.location.hostname
    ? 'local'
    : 'user.globalvetlink.com' === window.location.hostname
    ? 'production'
    : 'university.globalvetlink.com' === window.location.hostname
    ? 'university'
    : 'quality';
export const BASENAME = process.env.REACT_APP_BASENAME;
export const BACKEND_BASENAME = process.env.REACT_APP_BACKEND_BASENAME;
