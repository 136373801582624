import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Col, Layout, Row } from 'antd';
import { THEME } from 'style';
import { setViewMode } from 'containers/app/store/data/dataActions';
import { RegistrationFooterVal } from 'modules/Registration/Account/accountRegistrationIndex';
import IconWithLink from 'elements/IconWithLink';
import Logo from 'elements/Logo';
import SalesNumbersAndHours from 'elements/SalesNumbersAndHours';
import { DEPLOY_PLATFORM } from 'utils/config';

export default function RegistrationLayout({ children }) {
  const dispatch = useDispatch();
  const viewMode = useSelector(state => state.app.viewMode);
  const isUniversity = DEPLOY_PLATFORM === 'university';

  return (
    <Layout
      style={{
        minHeight: '100vh'
      }}
    >
      {/* This is here purely to set the view mode so GvlCards respond correctly.
       * GvlCards look for viewMode set when the breakpoint is hit for their width.
       * This is the easiest way I found to keep breakpoints consistent without re-working the GvlCard. */}
      <Layout.Sider
        style={{ display: 'none' }}
        breakpoint="lg"
        onBreakpoint={isSmallerThanBreakpoint => {
          dispatch(setViewMode(isSmallerThanBreakpoint ? 'Mobile' : 'Desktop'));
        }}
      />
      <Layout>
        <Layout.Header
          style={{
            background: THEME.white(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: viewMode === 'Mobile' ? '0px' : '0px 50px'
          }}
        >
          <Row>
            <Logo
              type={isUniversity ? 'gvlUniversity' : 'color'}
              size={'medium'}
              height="50px"
            />
          </Row>
        </Layout.Header>
        <Layout.Content
          style={{
            background: THEME.mainBackgroundColor(),
            padding: '32px 0px'
          }}
        >
          {children}
        </Layout.Content>
        <Layout.Footer style={{ padding: '32px 0px 0px' }}>
          <Row style={{ background: THEME.blue(), paddingBottom: '32px' }}>
            <Col>
              <div
                style={{
                  background: THEME.white(),
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  paddingBottom: '32px'
                }}
              >
                <h3 style={{ marginBottom: '16px' }}>
                  {intl.get('gvl.questions')}
                </h3>
                <SalesNumbersAndHours />
              </div>
              <Row
                type="flex"
                justify="space-around"
                style={{ marginTop: '32px' }}
              >
                <Logo type={'white'} size={'medium'} height="50px" />
                <RegistrationFooterVal
                  lineOne="GVL"
                  lineTwo={intl.get('gvl.address')}
                  lineThree={intl.get('gvl.cityStateZip')}
                />
                <RegistrationFooterVal
                  lineOne={intl.get('gvl.support.hours')}
                  lineTwo={intl.get('gvl.support.days')}
                  lineThree={intl.get('gvl.support.times')}
                />
                <RegistrationFooterVal
                  lineOne={intl.get('gvl.sales.hours')}
                  lineTwo={intl.get('gvl.sales.days')}
                  lineThree={intl.get('gvl.sales.times')}
                />
                <RegistrationFooterVal
                  lineOne={intl.get('contactUs.capitalized')}
                  lineTwo={intl.get('gvl.support.number')}
                  lineThree={intl.get('gvl.sales.number')}
                />
              </Row>
              <br />
              <Row type="flex" justify="center">
                <IconWithLink
                  type="facebook"
                  link="https://www.facebook.com/gvlsoftware/"
                />
                <IconWithLink
                  type="twitter"
                  link="https://twitter.com/globalvetlink"
                />
                <IconWithLink
                  type="linkedin"
                  link="https://www.linkedin.com/company/globalvetlink-lc/"
                />
                <IconWithLink
                  type="youtube"
                  link="https://www.youtube.com/channel/UCmsMCwRwvp5lXXbg92460uw"
                />
              </Row>
              <br />
              <Row type="flex" justify="center">
                <Button
                  style={{ color: THEME.white(), background: THEME.blue() }}
                  href="https://www.globalvetlink.com/join-gvl-email-list/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.get('gvl.newsletter')}
                </Button>
              </Row>
            </Col>
          </Row>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

RegistrationLayout.propTypes = {
  children: PropTypes.node
};
