/* eslint-disable react/display-name */
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import GvlIcon from 'elements/GvlIcon';
import { Link } from 'react-router-dom';
import { FONT, THEME } from 'style';
import moment from 'moment';
import Attachment from 'models/Attachment';

// attachments

const nameColumn = (existingIsolate = true) => ({
  title: intl.get('name'),
  render: (_text, record, _index) =>
    existingIsolate ? (
      <Link
        style={{ fontWeight: FONT.weight.semiBold, whiteSpace: 'nowrap' }}
        to={'#'}
        onClick={() => Attachment.download(record.id)}
      >
        {record.name}
      </Link>
    ) : (
      record.name
    ),
  dataIndex: 'name'
});

const userColumn = () => ({
  title: intl.get('user'),
  render: (_text, record, _index) => record.createdBy?.name
});

const dateColumn = () => ({
  title: intl.get('date'),
  render: (_text, record, _index) => moment(record.dateCreated).format('M/D/Y')
});

const deleteColumn = (onDeleteClick, existingIsolate = true) => ({
  render: (_text, record, _index) => (
    <Button
      type="link"
      style={{ padding: 0, float: 'right' }}
      onClick={() => onDeleteClick(existingIsolate ? record.id : record.uid)}
    >
      <GvlIcon
        type="close"
        style={{ color: THEME.red(), alignItems: 'center' }}
      />
    </Button>
  )
});

// releases

const releaseClinicColumn = isolateId => ({
  title: intl.get('clinic.recipient'),
  render: (_text, record, _index) => (
    <Link
      to={`/isolates/${isolateId}/releases/${record.isolateReleaseId}`}
      className="semibold"
    >
      {record.recipientClinic.name}
    </Link>
  ),
  dataIndex: 'recipientClinic.name'
});

const releaseVetColumn = () => ({
  title: intl.get('vet.approving'),
  render: (_text, record, _index) => record.approvingVet.commonName
});

const releaseApprovedColumn = () => ({
  title: intl.get('approved.date'),
  render: (_text, record, _index) => moment(record.lastUpdated).format('M/D/Y')
});

// extension request

const extensionClinicColumn = isolateId => ({
  title: intl.get('clinic'),
  render: (_text, record, _index) => (
    <Link to={`/isolates/${isolateId}/extension`} className="semibold">
      {record.clinic.name}
    </Link>
  ),
  dataIndex: 'clinic.name'
});

const extensionVetColumn = () => ({
  title: intl.get('vet.approving'),
  render: (_text, record, _index) => record.vet.commonName
});

const extensionApprovedColumn = () => ({
  title: intl.get('approved.date'),
  render: (_text, record, _index) => moment(record.approvedDate).format('M/D/Y')
});

const generateColumns = (onDeleteClick, isolateId) => {
  return {
    nonActionableAttachment: [nameColumn(), userColumn(), dateColumn()],
    savedAttachment: [
      nameColumn(),
      userColumn(),
      dateColumn(),
      deleteColumn(onDeleteClick)
    ],
    newAttachment: [nameColumn(false), deleteColumn(onDeleteClick, false)],
    release: [
      releaseClinicColumn(isolateId),
      releaseVetColumn(),
      releaseApprovedColumn()
    ],
    extension: [
      extensionClinicColumn(isolateId),
      extensionVetColumn(),
      extensionApprovedColumn()
    ]
  };
};

const exportFunctions = {
  generateColumns,
  nameColumn,
  userColumn,
  dateColumn,
  deleteColumn,
  releaseClinicColumn,
  releaseVetColumn,
  releaseApprovedColumn,
  extensionClinicColumn,
  extensionVetColumn,
  extensionApprovedColumn
};

export default exportFunctions;
