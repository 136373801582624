export const APP_ACCOUNT_ADMIN = 'APP_ACCOUNT_ADMIN';
export const APP_ACCOUNT_CLINIC = 'APP_ACCOUNT_CLINIC';
export const APP_ACCOUNT_LABTECH = 'APP_ACCOUNT_LabTech';
export const APP_ACCOUNT_REGULATORY = 'APP_ACCOUNT_REGULATORY';
export const APP_ACCOUNT_MVL_OWNER = 'APP_ACCOUNT_MVL_OWNER';
export const APP_DISTRIBUTOR_VFD = 'APP_DISTRIBUTOR_VFD';
export const APP_ACCOUNT_AGENT = 'APP_ACCOUNT_AGENT';
export const APP_ACCOUNT_NVSL_LABTECH = 'APP_ACCOUNT_NVSL_LABTECH';
export const APP_ACCOUNT_ISOLATES = 'APP_ACCOUNT_ISOLATES';

export const accountNameId = {
  [APP_ACCOUNT_ADMIN]: 'admin',
  [APP_ACCOUNT_CLINIC]: 'clinic',
  [APP_ACCOUNT_REGULATORY]: 'regulatory',
  [APP_ACCOUNT_LABTECH]: 'labs',
  [APP_ACCOUNT_MVL_OWNER]: 'mvlOwner',
  [APP_DISTRIBUTOR_VFD]: 'distributors',
  [APP_ACCOUNT_AGENT]: 'agent',
  [APP_ACCOUNT_NVSL_LABTECH]: 'nvslLab',
  [APP_ACCOUNT_ISOLATES]: 'isolates'
};

export const accountLink = {
  [APP_ACCOUNT_CLINIC]: '/clinic/show/',
  [APP_ACCOUNT_REGULATORY]: '/regulatoryAuthority/show/',
  [APP_ACCOUNT_LABTECH]: '/lab/show/',
  [APP_ACCOUNT_ISOLATES]: '/isolates/show'
};
