import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { concat, debounce, without } from 'lodash';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  Steps,
  notification
} from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { loadScript } from 'utils/externalScript';
import {
  isEmail as isInvalidEmail,
  isPhoneNumber as isInvalidPhoneNumber
} from 'utils/validation/common';
import { loadRecaptcha, getRecaptchaToken } from 'utils/recaptchaUtil';
import { commonLoadStartupDataRequest as loadStartupData } from 'containers/accounts/common/store/accountsCommonActions';
import {
  loadRegistrationData,
  validateClinicEmail,
  sendInternalAnalyticsEvent,
  submitRegistration as submitAccountRegistration,
  completeRegistration as completeAccountRegistration,
  resumeRegistration
} from 'modules/Registration/Account/store/accountRegistrationActions';
import { validateUsername } from 'modules/Registration/User/store/userRegistrationActions';
import { validateFederalLicense } from 'modules/License/store/licenseActions';
import {
  AccountRegistration as AccountRegistrationModel,
  PlanSelectTable,
  ReviewInfoModal,
  RegistrationLayout,
  IncompleteRegistrationModal
} from 'modules/Registration/Account/accountRegistrationIndex';
import { clinicPricebooks } from 'modules/Registration/Account/availablePricebooks';
import { UserRegistration as UserRegistrationModel } from 'modules/Registration/User/userRegistrationIndex';
import SelfSignupAnalytics from 'modules/Registration/SelfSignup/SelfSignupAnalytics';
import LocationModel from 'models/Location';
import EditLicenseModal from 'components/EditLicenseModal';
import VetLicenseTable from 'components/VetLicenseTable';
import GvlCard from 'elements/GvlCard';
import PasswordFormItem from 'elements/PasswordFormItem';
import StateSelect from 'elements/StateSelect';
import TooltipWrapper from 'elements/TooltipWrapper';
import { THEME } from 'style';
import {
  SignatureInput,
  HandleSignatureSubmission
} from 'modules/User/components/SignatureInput';
import { DEPLOY_PLATFORM } from 'utils/config';

const antdFormShape = {
  getFieldDecorator: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  resetFields: PropTypes.func.isRequired,
  getFieldsValue: PropTypes.func.isRequired
};

function CurrentAccountField({ form, selfSignupAnalytics }) {
  return (
    <Form.Item label={intl.get('register.currentAccount')}>
      {form.getFieldDecorator('currentAccount', {
        initialValue: false,
        rules: [{ required: true }]
      })(
        <Radio.Group
          onChange={e =>
            e.target.value
              ? selfSignupAnalytics.currentAccountYes.send()
              : selfSignupAnalytics.currentAccountNo.send()
          }
        >
          <Radio data-testid="existingAcctBtn" value={true}>
            {intl.get('yes')}
          </Radio>
          <Radio data-testid="newAcctBtn" value={false}>
            {intl.get('no')}
          </Radio>
        </Radio.Group>
      )}
    </Form.Item>
  );
}
CurrentAccountField.propTypes = {
  form: PropTypes.shape(antdFormShape).isRequired,
  selfSignupAnalytics: PropTypes.shape({
    currentAccountYes: PropTypes.func.isRequired,
    currentAccountNo: PropTypes.func.isRequired
  }).isRequired
};

function ManagedAccountField({ form, selfSignupAnalytics }) {
  return (
    <Form.Item label={intl.get('register.managedAccount')}>
      {form.getFieldDecorator('managedAccount', {
        initialValue: false,
        rules: [{ required: true }]
      })(
        <Radio.Group
          onChange={e =>
            e.target.value
              ? selfSignupAnalytics.banfieldYes.send()
              : selfSignupAnalytics.banfieldNo.send()
          }
        >
          <Radio value={true} data-testid="banfieldYes">
            {intl.get('yes')}
          </Radio>
          <Radio value={false} data-testid="banfieldNo">
            {intl.get('no')}
          </Radio>
        </Radio.Group>
      )}
    </Form.Item>
  );
}
ManagedAccountField.propTypes = {
  form: PropTypes.shape(antdFormShape).isRequired,
  selfSignupAnalytics: PropTypes.shape({
    banfieldYes: PropTypes.func.isRequired,
    banfieldNo: PropTypes.func.isRequired
  }).isRequired
};

function SameAsLocationCheckbox({ form }) {
  return (
    <Form.Item>
      {form.getFieldDecorator('sameAsLocation', {
        valuePropName: 'checked',
        initialValue: true
      })(<Checkbox>{intl.get('address.sameAs')}</Checkbox>)}
    </Form.Item>
  );
}
SameAsLocationCheckbox.propTypes = {
  form: PropTypes.shape(antdFormShape).isRequired
};

function LicenseDetailsCard({
  form,
  onAddLicense,
  modalVisible,
  onLicenseEditCancel,
  license,
  licenseList,
  addLicense,
  onLicenseEditRequest,
  removeLicense,
  validateAccountInformationPage
}) {
  return (
    <GvlCard
      title={intl.get('license.details')}
      extra={
        <Button type="link" onClick={onAddLicense}>
          {intl.get('settings.user.addLicense')}
        </Button>
      }
    >
      <div>
        <EditLicenseModal
          destroyOnClose
          visible={modalVisible === 'LICENSE'}
          onCancel={onLicenseEditCancel}
          country={form.getFieldValue('locationAddress.country')}
          license={license}
          licenseList={licenseList}
          onLicenseSaveRequest={addLicense}
        />
        <Alert
          message={intl.get('register.license.alert')}
          description={intl.get('register.requiredAccreditation.message')}
          type="info"
          showIcon
          style={{ marginBottom: '24px' }}
        />
        <VetLicenseTable
          licenseList={licenseList}
          onLicenseEditRequest={onLicenseEditRequest}
          onLicenseDeleteRequest={removeLicense}
        />
        <br />
        <Button
          style={{
            float: 'right',
            margin: '0px 0px 24px'
          }}
          type="primary"
          onClick={() =>
            form.validateFieldsAndScroll({ force: true }, (err, formValues) => {
              validateAccountInformationPage(err, formValues);
            })
          }
        >
          {intl.get('account.create.new')}
        </Button>
      </div>
    </GvlCard>
  );
}
LicenseDetailsCard.propTypes = {
  form: PropTypes.shape(antdFormShape).isRequired,
  onAddLicense: PropTypes.func.isRequired,
  modalVisible: PropTypes.string.isRequired,
  onLicenseEditCancel: PropTypes.func.isRequired,
  license: PropTypes.object,
  licenseList: PropTypes.array,
  addLicense: PropTypes.func.isRequired,
  onLicenseEditRequest: PropTypes.func.isRequired,
  removeLicense: PropTypes.func.isRequired,
  validateAccountInformationPage: PropTypes.func.isRequired
};

class AccountRegistrationPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    loadStartupData: PropTypes.func.isRequired,
    loadRegistrationData: PropTypes.func.isRequired,
    submitAccountRegistration: PropTypes.func.isRequired,
    completeAccountRegistration: PropTypes.func.isRequired,
    validateUsername: PropTypes.func.isRequired,
    validateClinicEmail: PropTypes.func.isRequired,
    validateFederalLicense: PropTypes.func.isRequired,
    viewMode: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    creatingAccount: PropTypes.bool.isRequired,
    creatingUser: PropTypes.bool.isRequired,
    addingPayment: PropTypes.bool.isRequired,
    sendInternalAnalyticsEvent: PropTypes.func.isRequired,
    resumeRegistration: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const selfSignupAnalytics = new SelfSignupAnalytics();
    this.state = {
      currentStep: 0,
      modalVisible: 'NONE',
      accountRegistrationModel: {},
      userRegistrationModel: {},
      license: null,
      licenseList: [],
      trackingTimestamp: null,
      emailDuplicate: false,
      selfSignupAnalytics: selfSignupAnalytics,
      signatureInputMode: 'esign'
    };
    // Debounce ensures that when setting signature, form values are their most current
    this.setSignatureDebounced = debounce(this.setSignatureText, 500);
    this.sendInternalAnalyticsDebounced = debounce(
      this.sendInternalAnalytics,
      1000
    );
  }

  componentDidMount() {
    // remove any previous login data, there is the potential of an old token
    // associated with a previous session in localStorage that will prevent
    // some of the permitAll api calls from working
    localStorage.removeItem('user');
    this.props.loadStartupData();
    this.props.loadRegistrationData();
    const couponCode = this.parseCouponCodeFromUrl(this.props.location.search);
    // Set the default field values
    this.props.form.setFieldsValue({
      'locationAddress.country': 'USA',
      ratePlanCode: !couponCode
        ? clinicPricebooks.noSubFee.ratePlanCode
        : clinicPricebooks.withSubFee.ratePlanCode,
      sysFeatureSetName: clinicPricebooks.noSubFee.sysFeatureSetName
    });
    loadRecaptcha();
    loadScript(
      'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js'
    ).catch(
      /* istanbul ignore next */
      error => console.log('Unable to load zuora external js', error)
    );

    this.setState({ trackingTimestamp: new Date() });
  }

  componentDidUpdate() {
    this.sendInternalAnalyticsDebounced();
  }

  sendInternalAnalytics = () => {
    if (this.state.internalAnalyticSent) {
      return;
    }

    const email = this.props.form.getFieldValue('email');
    const clinicName = this.props.form.getFieldValue('name');
    const phone = this.props.form.getFieldValue('locationAddress.phone');

    const missingRequiredData =
      !clinicName ||
      isInvalidEmail(email, 'validation.email.invalid') ||
      isInvalidPhoneNumber(phone);
    if (missingRequiredData) {
      return;
    }

    this.props.sendInternalAnalyticsEvent({ email, clinicName, phone }, () =>
      this.setState({ internalAnalyticSent: true })
    );
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  formErrorsNotification = (warningMsg, err) => {
    console.warn('registration form err:', err);
    notification.warning({
      message: warningMsg,
      description: intl.get('form.entries.error')
    });
  };

  checkUsernameAvailability = async () => {
    const form = this.props.form;
    const username = form.getFieldValue('username');
    // Registration Model returns if there is an issue
    if (!username || UserRegistrationModel.fields.username.validate(username)) {
      return;
    }

    const token = await getRecaptchaToken('validateUsername');
    this.props.validateUsername(
      {
        email: username,
        token
      },
      this.duplicateFieldValidationCallback
    );
  };

  checkClinicEmailAvailability = async () => {
    const form = this.props.form;
    const email = form.getFieldValue('email');
    // Registration Model returns if there is an issue
    if (!email || AccountRegistrationModel.fields.email.validate(email)) {
      return;
    }

    const token = await getRecaptchaToken('validateClinicEmail');
    this.props.validateClinicEmail(
      { email, token },
      this.duplicateFieldValidationCallback
    );
  };

  duplicateFieldValidationCallback = ({
    fieldName,
    isDuplicate = false,
    hasExistingRegistration = false
  }) => {
    if (isDuplicate) {
      this.setInUseFieldError(fieldName);
    }
    if (hasExistingRegistration) {
      this.setState({ modalVisible: 'INCOMPLETE_REGISTRATION' });
    }
    this.setState({ [`${fieldName}Duplicate`]: isDuplicate });
  };

  setInUseFieldError = fieldName => {
    const form = this.props.form;
    form.setFields({
      [fieldName]: {
        value: form.getFieldValue(fieldName),
        errors: [{ message: intl.get('email.inUse') }]
      }
    });
  };

  parseCouponCodeFromUrl = pathSearchString => {
    return pathSearchString.match(/couponCode=(.*)/)?.[1];
  };

  attemptResumeRegistration = async () => {
    const token = await getRecaptchaToken('resumeRegistration');
    const email = this.props.form.getFieldValue('email');
    this.setState({ modalVisible: 'NONE' });
    this.props.resumeRegistration(
      {
        params: {
          email,
          recaptchaToken: token
        }
      },
      this.accountInformationCompletedCallback
    );
  };

  validateAccountInformationPage = async (err, formValues) => {
    if (err || this.state.emailDuplicate || this.state.usernameDuplicate) {
      this.formErrorsNotification(
        intl.get('error.creating.noun', {
          noun: intl.get('account.lc')
        }),
        err
      );
      if (this.state.usernameDuplicate) {
        this.setInUseFieldError('username');
      }
      if (this.state.emailDuplicate) {
        this.setInUseFieldError('email');
      }
      return;
    }

    const token = await getRecaptchaToken('register');

    const accountRegistrationModel = this.createAccountRegistrationModel(
      formValues,
      token
    );
    if (!accountRegistrationModel) return;

    const userRegistrationModel = await this.createUserRegistrationModel(
      formValues,
      token
    );
    if (!userRegistrationModel) return;

    this.setState({
      modalVisible: 'REVIEW',
      accountRegistrationModel,
      userRegistrationModel
    });
  };

  createAccountRegistrationModel = (formValues, token) => {
    const accountRegistrationModel = new AccountRegistrationModel({
      ...formValues,
      promoPlanCode: this.parseCouponCodeFromUrl(this.props.location.search),
      token: token
    });

    if (!formValues.sameAsLocation) {
      // Form only has one country/phone input so add the country/phone to the billing location.
      accountRegistrationModel.billingAddress.country =
        accountRegistrationModel.locationAddress.country;
      accountRegistrationModel.billingAddress.phone =
        accountRegistrationModel.locationAddress.phone;
    }

    const accountRegistrationErrors = accountRegistrationModel.validate();
    if (accountRegistrationErrors) {
      this.formErrorsNotification(
        intl.get('error.creating.noun', {
          noun: intl.get('account.lc')
        }),
        accountRegistrationErrors
      );
      return;
    }

    return accountRegistrationModel;
  };

  createUserRegistrationModel = async formValues => {
    const base64Signature = await HandleSignatureSubmission(
      this.state.signatureInputMode,
      {
        file: formValues.signatureImg,
        text: formValues.signatureText,
        font: formValues.signatureFont
      }
    );
    const userRegistrationModel = new UserRegistrationModel({
      ...formValues,
      licenses: this.state.licenseList,
      signature: base64Signature
    });

    const userRegistrationErrors = userRegistrationModel.validate();
    if (userRegistrationErrors) {
      this.formErrorsNotification(
        intl.get('error.creating.noun', {
          noun: intl.get('user').toLowerCase()
        }),
        userRegistrationErrors
      );
      return;
    }

    return userRegistrationModel;
  };

  submitRegistrations = () => {
    this.setState({ modalVisible: 'NONE' });
    this.props.submitAccountRegistration(
      this.state.accountRegistrationModel,
      this.state.userRegistrationModel,
      this.accountInformationCompletedCallback
    );
  };

  accountInformationCompletedCallback = async response => {
    await this.loadCreditCardEntry(response).then(() => {
      this.setState(
        {
          currentStep: 1
        },
        () => this.scrollToTop()
      );

      const selfSignupAnalytics = this.state.selfSignupAnalytics;
      selfSignupAnalytics.signature.label = this.state.signatureInputMode;
      selfSignupAnalytics.signature.send();
      selfSignupAnalytics.accountInformation.send();
    });
  };

  loadCreditCardEntry = async response => {
    if (!window.Z || DEPLOY_PLATFORM === 'university') {
      return;
    }

    const zCallback = payment => {
      if (!payment.success) {
        return;
      }

      this.state.selfSignupAnalytics.billingInformation.send();
      this.props.completeAccountRegistration(
        {
          accountRegistrationId:
            response.accountRegistration.accountRegistrationId,
          paymentMethodId: payment.refId
        },
        this.props.history
      );
    };

    await window.Z.render(
      {
        ...response.hostedPaymentCredentials,
        style: 'inline',
        submitEnabled: true
      },
      {},
      zCallback
    );
  };

  addLicense = license => {
    let licenseList = this.state.licenseList;
    // If editing a license, remove the old version before adding the new
    if (this.state.license) {
      licenseList = without(licenseList, this.state.license);
    }
    // Validate the federal license (only type that can be validated through our system)
    if (license.issuingAuthority === 'FEDERAL') {
      this.props.validateFederalLicense({
        lastName: this.props.form.getFieldValue('lastName'),
        licenseNumber: license.licenseNumber
      });
    }
    licenseList = concat(licenseList, license);
    this.setState({ licenseList, license: null });
  };

  removeLicense = license => {
    const licenseList = without(this.state.licenseList, license);
    this.setState({ licenseList });
  };

  setSignatureText = () => {
    const form = this.props.form;
    // If signature field has been modified by the user, then do not change it when name updates
    if (form.isFieldTouched('signatureText')) return;

    let first = form.getFieldValue('firstName') || '';
    let last = form.getFieldValue('lastName') || '';
    if (!first && !last) return;
    if (form.getFieldValue('signatureText') !== `${first} ${last}`) {
      form.setFieldsValue({ signatureText: `${first} ${last}` });
    }
  };

  render() {
    const currentStep = this.state.currentStep;
    const selfSignupAnalytics = this.state.selfSignupAnalytics;
    const {
      form,
      viewMode,
      loading,
      creatingAccount,
      creatingUser,
      addingPayment,
      location
    } = this.props;
    const formValues = form.getFieldsValue();

    // Conditional formatting for non-prod environments
    const isGVLUniversity = DEPLOY_PLATFORM === 'university';

    const sameAsLocation = isGVLUniversity ? true : formValues.sameAsLocation;

    const isAgMovePromo =
      formValues.ratePlanCode ===
        clinicPricebooks.withSubFee.ratePlanCode &&
      this.parseCouponCodeFromUrl(location.search) === 'AgMove';
    return (
      <RegistrationLayout>
        <IncompleteRegistrationModal
          visible={this.state.modalVisible === 'INCOMPLETE_REGISTRATION'}
          onCloseModal={() => this.setState({ modalVisible: 'NONE' })}
          onConfirm={this.attemptResumeRegistration}
        />
        <Spin spinning={loading || addingPayment} size="large">
          <Form layout="vertical">
            {currentStep < 2 && !isGVLUniversity ? (
              <Steps
                current={currentStep}
                style={{
                  justifyContent: 'center',
                  margin: '0px 24px 24px'
                }}
              >
                <Steps.Step
                  style={{ flex: 'unset' }}
                  title={intl.get('accountInfo')}
                />
                <Steps.Step
                  style={{ flex: 'unset' }}
                  title={intl.get('account.billinginfo')}
                />
              </Steps>
            ) : null}
            {currentStep !== 1 && isGVLUniversity ? (
              <React.Fragment>
                <GvlCard title="Signup for a GVL University Account">
                  <Alert
                    message="With GVL University, you can explore the GVL platform and try your hand at creating movement documents, prescriptions, and other health related records. In order to sign up, you will be required to enter some basic information. If you do not currently work at a clinic, you can enter some default information for the clinic field to get started."
                    type="info"
                    showIcon
                    style={{ marginBottom: '24px' }}
                  />
                </GvlCard>
                <br />
              </React.Fragment>
            ) : null}
            {isAgMovePromo ? (
              <Alert
                data-testid="pageAGMovePromoAlert"
                type="success"
                style={{
                  width: '720px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '24px'
                }}
                showIcon
                message={intl.getHTML('self.sign.up.Coupon.code.alert.title')}
                description={intl.getHTML(
                  'self.sign.up.agMove.alert.description'
                )}
              />
            ) : null}
            <div
              id="accountInformation"
              style={currentStep !== 0 ? { display: 'none' } : undefined}
            >
              <GvlCard title={intl.get('clinic.details')}>
                <div>
                  {isGVLUniversity ? null : (
                    <CurrentAccountField {...{ form, selfSignupAnalytics }} />
                  )}
                  {formValues.currentAccount && (
                    <Alert
                      type="info"
                      style={{ marginBottom: '24px' }}
                      showIcon
                      message={
                        <span>
                          {intl.get('register.currentAccount.temporaryMessage')}
                          <br />
                          (515) 817-5704
                        </span>
                      }
                      description={intl.get(
                        'register.currentAccount.temporaryDescription'
                      )}
                    />
                  )}
                  {!formValues.currentAccount && (
                    <React.Fragment>
                      {isGVLUniversity ? null : (
                        <ManagedAccountField
                          form={form}
                          selfSignupAnalytics={selfSignupAnalytics}
                        />
                      )}
                      {formValues.managedAccount && (
                        <Alert
                          type="info"
                          showIcon
                          message={
                            <span>
                              <span>
                                {intl.get('register.banfield.message')}
                              </span>
                              <br />
                              <p
                                style={{
                                  color: '#3f5766',
                                  fontSize: '14px '
                                }}
                              >
                                {intl.get('register.banfield.description')}
                              </p>
                              <span>{intl.get('register.vca.message')}</span>
                              <br />
                            </span>
                          }
                          description={
                            <p>
                              {intl.get('register.vca.description')}{' '}
                              <a
                                href="https://globalvetlink.com/vca"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {'https://globalvetlink.com/vca.'}
                              </a>
                            </p>
                          }
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
                {!formValues.currentAccount && !formValues.managedAccount && (
                  <React.Fragment>
                    <div>
                      <Form.Item label={intl.get('test.clinicName')}>
                        {getModelFieldDecorator(
                          form,
                          'name',
                          AccountRegistrationModel,
                          true
                        )(
                          <Input
                            onChange={() =>
                              selfSignupAnalytics.clinicName.send()
                            }
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('clinic.email')}>
                        {getModelFieldDecorator(
                          form,
                          'email',
                          AccountRegistrationModel,
                          true
                        )(
                          <Input
                            onChange={() => {
                              selfSignupAnalytics.clinicEmail.send();
                            }}
                            onBlur={this.checkClinicEmailAvailability}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('clinic.phone')}>
                        {getModelFieldDecorator(
                          form,
                          'locationAddress.phone',
                          LocationModel,
                          true,
                          { useSubFormValidator: true }
                        )(
                          <Input
                            onChange={() =>
                              selfSignupAnalytics.clinicPhone.send()
                            }
                          />
                        )}
                      </Form.Item>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            label={
                              <TooltipWrapper
                                label={intl.get('country')}
                                tooltipTitle={
                                  <span>
                                    <p>{intl.get('register.country.line1')}</p>
                                    {intl.get('register.country.line2')}
                                  </span>
                                }
                              />
                            }
                          >
                            {getModelFieldDecorator(
                              form,
                              'locationAddress.country',
                              LocationModel,
                              true,
                              { useSubFormValidator: true }
                            )(<Input disabled />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      {sameAsLocation ? null : (
                        <h4>{intl.get('address.physical')}</h4>
                      )}
                      <AddressFields
                        form={form}
                        viewMode={viewMode}
                        prefix="locationAddress"
                        selfSignupAnalytics={selfSignupAnalytics}
                      />
                      {isGVLUniversity ? (
                        <div style={{ display: 'none' }}>
                       	  <SameAsLocationCheckbox {...{ form }} />
                        </div>
                      ) : (
                        <SameAsLocationCheckbox {...{ form }} />
                      )}
                      {sameAsLocation ? null : (
                        <React.Fragment>
                          <h4>{intl.get('address.billing')}</h4>
                          <AddressFields
                            form={form}
                            viewMode={viewMode}
                            prefix="billingAddress"
                            selfSignupAnalytics={selfSignupAnalytics}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </GvlCard>
              {formValues.currentAccount || formValues.managedAccount ? null : (
                <React.Fragment>
                  <br />
                  <GvlCard
                    title={intl.get('plan.details')}
                    style={isGVLUniversity ? { display: 'none' } : undefined}
                  >
                    {isAgMovePromo ? (
                      <Alert
                        data-testid="planDetailsAGMovePromoAlert"
                        type="success"
                        style={{ marginBottom: '24px' }}
                        showIcon
                        message={intl.getHTML(
                          'self.sign.up.Coupon.code.alert.title'
                        )}
                        description={
                          <>
                            {intl.get(
                              'self.sign.up.agMove.alert.plan.details.description.header'
                            )}
                            {intl.getHTML(
                              'self.sign.up.agMove.alert.plan.details.description.body'
                            )}
                          </>
                        }
                      />
                    ) : null}
                    <PlanSelectTable form={form} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Divider style={{ marginTop: '0px' }} />
                      <p>{intl.get('gvl.talkThrough')}</p>
                      <h2 style={{ color: THEME.blue(), margin: '0px' }}>
                        <a href="tel:+15158175703">(515) 817-5703</a>
                      </h2>
                    </div>
                  </GvlCard>
                  <br />
                  <GvlCard title={intl.get('vet.details')}>
                    <div>
                      <Alert
                        message={intl.get('register.vetInfo')}
                        type="info"
                        showIcon
                        style={{ marginBottom: '24px' }}
                      />
                      <Form.Item label={intl.get('firstName')}>
                        {getModelFieldDecorator(
                          form,
                          'firstName',
                          UserRegistrationModel,
                          true
                        )(
                          <Input
                            onChange={() => {
                              this.setSignatureDebounced();
                              selfSignupAnalytics.firstName.send();
                            }}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('lastName')}>
                        {getModelFieldDecorator(
                          form,
                          'lastName',
                          UserRegistrationModel,
                          true
                        )(
                          <Input
                            onChange={() => {
                              this.setSignatureDebounced();
                              selfSignupAnalytics.lastName.send();
                            }}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={intl.get('email')}
                        extra={intl.get('register.email.info')}
                      >
                        {getModelFieldDecorator(
                          form,
                          'username',
                          UserRegistrationModel,
                          true
                        )(
                          <Input
                            onChange={() => selfSignupAnalytics.username.send()}
                            onBlur={this.checkUsernameAvailability}
                          />
                        )}
                      </Form.Item>
                      <PasswordFormItem
                        form={form}
                        model={UserRegistrationModel}
                        onChange={() => selfSignupAnalytics.password.send()}
                      />
                    </div>
                    <br />
                    <SignatureInput
                      form={form}
                      signatureInputMode={this.state.signatureInputMode}
                      setInputMode={mode =>
                        this.setState({ signatureInputMode: mode })
                      }
                    />
                    {isGVLUniversity ? (
                      // Move the submit button since the billing card is hidden
                      <React.Fragment>
                        <br />
                        <Button
                          style={{ float: 'right', margin: '0px 0px 24px' }}
                          type="primary"
                          onClick={() =>
                            form.validateFieldsAndScroll(
                              { force: true },
                              (err, formValues) => {
                                this.validateAccountInformationPage(
                                  err,
                                  formValues
                                );
                              }
                            )
                          }
                        >
                          {intl.get('account.create.new')}
                        </Button>
                      </React.Fragment>
                    ) : null }
                  </GvlCard>
                  {isGVLUniversity ? (
                    undefined
                  ) : (
                    <React.Fragment>
                      <br />
                      <LicenseDetailsCard
                        form={form}
                        onAddLicnese={() =>
                          this.setState({ modalVisible: 'LICENSE' })
                        }
                        modalVisible={this.state.modalVisible}
                        onLicenseEditCancel={() => {
                          this.setState({
                            modalVisible: 'NONE',
                            license: null
                          });
                        }}
                        license={this.state.license}
                        licenseList={this.state.licenseList}
                        addLicense={this.addLicense}
                        onLicenseEditRequest={license =>
                          this.setState({
                            license,
                            modalVisible: 'LICENSE'
                          })
                        }
                        removeLicense={license =>
                          this.setState({
                            license,
                            modalVisible: 'LICENSE'
                          })
                        }
                        validateAccountInformationPage={
                          this.validateAccountInformationPage
                        }
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            <div
              id="billingInformation"
              style={currentStep !== 1 ? { display: 'none' } : undefined}
            >
              {/* If GVL U, we should show a thank you rather than the billing info page. */}
              {isGVLUniversity ? (
                <GvlCard title={intl.get('register.gvlUniversity.complete')}>
                  <p>
                    Thank you for registering for a GlobalVetLink University
                    Account. Please check your email to confirm your account and
                    for instructions to login.
                  </p>
                  <br />
                  <Button
                    style={{ float: 'right', margin: '0px 24px 24px 0px' }}
                    type="primary"
                    onClick={() => window.location('../login')}
                  >
                    {intl.get('account.create.new')}
                  </Button>
                </GvlCard>
              ) : (
                <GvlCard title={intl.get('billing.details')}>
                  <Alert
                    type="info"
                    style={{ marginBottom: '24px' }}
                    showIcon
                    message={intl.get('register.chargeAlert.header')}
                    description={
                      <>
                        {intl.getHTML('register.chargeAlert.authorization')}
                        {intl.getHTML(
                          this.state.accountRegistrationModel?.ratePlanCode ===
                            clinicPricebooks.noSubFee.ratePlanCode
                            ? 'register.chargeAlert.noSubFee'
                            : 'register.chargeAlert.withSubFee'
                        )}
                      </>
                    }
                  />
                  <div id="zuora_payment"></div>
                </GvlCard>
              )}
            </div>
          </Form>
          <ReviewInfoModal
            accountRegistration={this.state.accountRegistrationModel}
            userRegistration={this.state.userRegistrationModel}
            visible={this.state.modalVisible === 'REVIEW'}
            onCloseModal={() => this.setState({ modalVisible: 'NONE' })}
            onConfirm={this.submitRegistrations}
          />
          <Modal
            title={null}
            footer={null}
            closable={false}
            width={'325px'}
            maskClosable={false}
            visible={creatingAccount || creatingUser}
          >
            <div style={{ textAlign: 'center' }}>
              <Spin
                spinning={true}
                size="large"
                style={{ margin: '16px 0px' }}
              />
              <h4>
                {intl.get('register.creatingAccount.line1')}
                <br />
                {intl.get('register.creatingAccount.line2')}
              </h4>
            </div>
          </Modal>
        </Spin>
      </RegistrationLayout>
    );
  }
}

const AccountRegistrationForm = Form.create({
  name: 'accountRegistrationForm'
})(AccountRegistrationPage);

const mapStateToProps = ({ app, registration }) => ({
  viewMode: app.data.viewMode,
  loading: registration.account.isRefreshing,
  creatingAccount: registration.account.isCreatingAccount,
  creatingUser: registration.user.isCreatingUser,
  addingPayment: registration.account.isAddingPayment
});

const mapDispatchToProps = {
  loadStartupData,
  loadRegistrationData,
  submitAccountRegistration,
  completeAccountRegistration,
  validateUsername,
  validateClinicEmail,
  validateFederalLicense,
  sendInternalAnalyticsEvent,
  resumeRegistration
};

const AccountRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRegistrationForm);

function AddressFields({ form, viewMode, prefix, selfSignupAnalytics }) {
  return (
    <React.Fragment>
      <Form.Item
        label={intl.get(
          prefix === 'locationAddress' ? 'clinicAddress' : 'address'
        )}
      >
        {getModelFieldDecorator(
          form,
          prefix + '.addressLine1',
          LocationModel,
          true,
          { useSubFormValidator: true, preserve: false }
        )(
          <Input
            onChange={() => selfSignupAnalytics[prefix].addressLine1.send()}
          />
        )}
      </Form.Item>
      <Row gutter={8}>
        <Col span={viewMode === 'Desktop' ? 12 : 24}>
          <Form.Item label={intl.get('city')}>
            {getModelFieldDecorator(
              form,
              prefix + '.city',
              LocationModel,
              true,
              { useSubFormValidator: true, preserve: false }
            )(
              <Input onChange={() => selfSignupAnalytics[prefix].city.send()} />
            )}
          </Form.Item>
        </Col>
        <Col span={viewMode === 'Desktop' ? 6 : 12}>
          <Form.Item label={intl.get('state')}>
            {getModelFieldDecorator(
              form,
              prefix + '.state',
              LocationModel,
              true,
              { useSubFormValidator: true, preserve: false }
            )(
              <StateSelect
                selectedCountry={form.getFieldValue('locationAddress.country')}
                onChange={() => selfSignupAnalytics[prefix].state.send()}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={viewMode === 'Desktop' ? 6 : 12}>
          <Form.Item label={intl.get('zip')}>
            {getModelFieldDecorator(
              form,
              prefix + '.postalCode',
              LocationModel,
              true,
              { useSubFormValidator: true, preserve: false }
            )(
              <Input
                onChange={() => selfSignupAnalytics[prefix].postalCode.send()}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
}

AddressFields.propTypes = {
  form: PropTypes.shape(antdFormShape).isRequired,
  viewMode: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  selfSignupAnalytics: PropTypes.object.isRequired
};

export {
  AccountRegistration as default,
  CurrentAccountField,
  ManagedAccountField,
  SameAsLocationCheckbox,
  LicenseDetailsCard,
  AccountRegistrationPage,
  AddressFields
};
