export const clinicPricebooks = {
  withSubFee: {
    ratePlanCode: 'STNDRD-US-S-2022',
    sysFeatureSetName: 'Clinic/Hospital Feature Set - Multi-Vet'
  },
  noSubFee: {
    ratePlanCode: 'STNDRD-US-A-2022',
    sysFeatureSetName: 'Clinic/Hospital Feature Set - Multi-Vet'
  }
};
