import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Button } from 'antd';
import moment from 'moment';
import { THEME } from 'style';
import { BorderedDiv } from 'style/commonEmotions';
import GvlIcon from 'elements/GvlIcon';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

export default function ProductInlineShow({
  product,
  onRemove,
  removable,
  currentUser
}) {
  return (
    <BorderedDiv>
      <h4 style={{ margin: '12px' }}>
        <Row>
          <Col
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>{product.name}</span>
            {removable ? (
              <span style={{ float: 'right' }}>
                <Button
                  style={{
                    border: 'none',
                    margin: 'auto'
                  }}
                  onClick={onRemove}
                >
                  <GvlIcon type="close" style={{ color: THEME.red() }} />
                </Button>
              </span>
            ) : null}
          </Col>
        </Row>
      </h4>
      <Divider style={{ marginTop: 0 }} />
      <Row style={{ marginLeft: '16px', marginTop: '16px' }} gutter={8}>
        <Col span={12}>
          <IsolateVal
            name="product.initial.order.date"
            val={moment(product.initialOrderDate).format('M/D/Y')}
          />
        </Col>
        {hasFeatureAccess(
          currentUser,
          FeatureEnum.ProductLabInterface,
          'read'
        ) ? (
          <Col span={12}>
            <IsolateVal
              name="ship.date"
              val={
                product.shipDate
                  ? moment(product.shipDate).format('M/D/Y')
                  : null
              }
            />
          </Col>
        ) : null}
      </Row>
    </BorderedDiv>
  );
}

ProductInlineShow.propTypes = {
  product: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  removable: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired
};
