/* eslint-disable react/display-name */
import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { generateAlphaSorter, generateNumberSorter } from 'utils/sortHelpers';
import { BASENAME } from 'utils/config';
import GvlIcon from 'elements/GvlIcon';

const isolateDetailsColumn = () => ({
  title: intl.get('isolate.details'),
  dataIndex: 'isolateNbr',
  render: (_text, record, _index) => (
    <div>
      <a
        className="semibold"
        href={`${BASENAME}/isolates/${record.isolateId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.isolateNbr}
      </a>
      <br />
      {record.name}
    </div>
  ),
  sorter: generateAlphaSorter('isolateNbr')
});

const premiseIdColumn = () => ({
  title: intl.get('premiseID'),
  width: 120,
  dataIndex: 'premiseID',
  render: (_text, record, _index) => record.herdLocation?.pin,
  sorter: generateAlphaSorter(record => record.herdLocation?.pin)
});

const producerColumn = () => ({
  title: intl.get('isolate.producer'),
  dataIndex: 'producer',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        <div className="semibold">{record.producer?.displayName}</div>
        <div>{record.producer?.primaryPhone}</div>
      </React.Fragment>
    );
  },
  sorter: generateAlphaSorter(record => record.producer?.displayName)
});

const herdLocationColumn = () => ({
  title: intl.get('herd.location'),
  dataIndex: 'herdLocation',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        <div className="semibold">{record.herdLocation?.name}</div>
        {record.herdLocation?.addressLine1 && (
          <div>{record.herdLocation?.addressLine1}</div>
        )}
        {(record.herdLocation?.city ||
          record.herdLocation?.state ||
          record.herdLocation?.postalCode) && (
          <div>
            {record.herdLocation?.city}
            {record.herdLocation?.state &&
              `, ${record.herdLocation?.state}`}{' '}
            {record.herdLocation?.postalCode}
          </div>
        )}
        {(record.herdLocation?.latitude || record.herdLocation?.longitude) && (
          <div>{`(${record.herdLocation?.latitude}, ${record.herdLocation?.longitude})`}</div>
        )}
        {record.herdLocation?.phone && <div>{record.herdLocation.phone}</div>}
      </React.Fragment>
    );
  },
  sorter: generateAlphaSorter(record =>
    record.herdLocation?.name + record.herdLocation?.addressLine1
      ? `${record.herdLocation?.addressLine1} ${record.herdLocation?.city}, ${record.herdLocation?.state} ${record.herdLocation?.postalCode}`
      : `(${record.herdLocation?.latitude}, ${record.herdLocation?.longitude})`
  )
});

const herdStateColumn = () => ({
  title: intl.get('herd.state'),
  width: 120,
  dataIndex: 'herdState',
  render: (_text, record, _index) => record.herdLocation?.state,
  sorter: generateAlphaSorter(record => record.herdLocation?.state)
});

const herdSizeColumn = () => ({
  title: intl.get('herd.size'),
  width: 120,
  dataIndex: 'headCount',
  render: (_text, record, _index) => record.headCount,
  sorter: generateNumberSorter('headCount')
});

const speciesColumn = () => ({
  title: intl.get('species'),
  width: 120,
  dataIndex: 'species',
  render: (_text, record, _index) => record.species,
  sorter: generateAlphaSorter('species')
});

const isolationColumn = () => ({
  title: intl.get('isolate.isolation'),
  dataIndex: 'isolationDate',
  render: (_text, record, _index) =>
    moment(record.isolationDate).format('M/D/Y'),
  sorter: generateAlphaSorter('isolationDate')
});

const expirationColumn = () => ({
  title: intl.get('expiration'),
  dataIndex: 'expirationDate',
  render: (_text, record, _index) =>
    moment(record.expirationDate).format('M/D/Y'),
  sorter: generateAlphaSorter('expirationDate')
});

const extendedColumn = () => ({
  title: intl.get('isolate.extended'),
  dataIndex: 'extended',
  render: (_text, record, _index) =>
    record.isExtended ? intl.get('yes') : intl.get('no'),
  sorter: generateAlphaSorter(record =>
    record.isExtended ? intl.get('yes') : intl.get('no')
  )
});

const tissueColumn = () => ({
  title: intl.get('tissue'),
  dataIndex: 'tissue',
  render: (_text, record, _index) => record.tissue,
  sorter: generateAlphaSorter('tissue')
});

const labColumn = () => ({
  title: intl.get('lab.info'),
  dataIndex: 'diagnosticLab',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        <div className="semibold">{record.diagnosticLab?.name}</div>
        <div>
          {record.labTechnician
            ? record.labTechnician.commonName
            : intl.get('unknown')}
        </div>
      </React.Fragment>
    );
  },
  sorter: generateAlphaSorter(
    record => record.diagnosticLab?.name + record.labTechnician?.commonName
  )
});

const harvestColumn = () => ({
  title: intl.get('isolate.first.harvest.date'),
  dataIndex: 'harvestDate',
  render: (_text, record, _index) =>
    record.harvestDate ? moment(record.harvestDate).format('M/D/Y') : null,
  sorter: generateAlphaSorter('harvestDate')
});

const lastUpdatedColumn = () => ({
  title: intl.get('lastupdated'),
  dataIndex: 'lastUpdated',
  render: (_text, record, _index) => moment(record.lastUpdated).format('M/D/Y'),
  sorter: generateAlphaSorter('lastUpdated')
});

const stateColumn = () => ({
  title: intl.get('State'),
  dataIndex: 'state',
  render: (_text, record, _index) => record.stateAbbr
});

const statusColumn = () => ({
  title: intl.get('status'),
  dataIndex: 'status',
  render: (_text, record, _index) => {
    if (record.stateAbbr === 'CA') {
      // California has chosen not to approve nonadjacent herd requests through the system
      return (
        <div className="semibold">
          <GvlIcon
            type="check-circle"
            theme="filled"
            style={{ color: '#00ADE5', paddingRight: '5px' }}
          />
          {intl.get('naRequest.no.approval')}
        </div>
      );
    }
    return (
      <React.Fragment>
        {record.dateSigned ? (
          <div className="semibold">
            <GvlIcon
              type="check-circle"
              theme="filled"
              style={{ color: '#66CC00', paddingRight: '5px' }}
            />
            {intl.get('approved')}
          </div>
        ) : (
          <div>{intl.get('await.approval')}</div>
        )}
      </React.Fragment>
    );
  }
});

const stateVetColumn = () => ({
  title: intl.get('state.vet'),
  dataIndex: 'vet',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        <div className="semibold">{record?.name}</div>
        <div>{record?.email}</div>
      </React.Fragment>
    );
  }
});

const generateColumns = () => {
  return {
    herdsOfOrigin: [
      isolateDetailsColumn(),
      premiseIdColumn(),
      producerColumn(),
      herdLocationColumn(),
      herdStateColumn(),
      herdSizeColumn(),
      speciesColumn()
    ],
    isolateHistory: [
      isolateDetailsColumn(),
      isolationColumn(),
      expirationColumn(),
      extendedColumn(),
      tissueColumn(),
      labColumn()
    ],
    productShowIsolateHistory: [
      isolateDetailsColumn(),
      isolationColumn(),
      harvestColumn(),
      expirationColumn(),
      extendedColumn(),
      tissueColumn(),
      labColumn(),
      lastUpdatedColumn()
    ],
    stateApprovals: [stateColumn(), statusColumn(), stateVetColumn()]
  };
};

const exportFunctions = {
  generateColumns,
  isolateDetailsColumn,
  premiseIdColumn,
  producerColumn,
  herdLocationColumn,
  herdStateColumn,
  herdSizeColumn,
  speciesColumn,
  isolationColumn,
  expirationColumn,
  extendedColumn,
  tissueColumn,
  labColumn,
  stateColumn,
  statusColumn,
  stateVetColumn
};

export default exportFunctions;
