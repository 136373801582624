// list constants
export const LIST_REQUEST = 'contact/LIST_REQUEST';
export const LIST_SUCCESS = 'contact/LIST_SUCCESS';
export const LIST_FAILURE = 'contact/LIST_FAILURE';

//Save constant
export const SAVE_REQUEST = 'contact/SAVE_REQUEST';

// General constants
export const IS_REFRESHING = 'contact/IS_REFRESHING';

// refresh constants
export const REFRESH_REQUEST = 'contact/REFRESH_REQUEST';
export const REFRESH_SUCCESS = 'contact/REFRESH_SUCCESS';
