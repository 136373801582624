import * as types from 'modules/Location/store/locationActionTypes';
import { createAction } from 'utils/reduxUtil';

// Generic Actions
export const isRefreshing = isRefreshing =>
  createAction(types.IS_REFRESHING, { isRefreshing });

// List Actions
export const listRequest = (params = null) =>
  createAction(types.LIST_REQUEST, { params });
export const listSuccess = locations =>
  createAction(types.LIST_SUCCESS, { locations });
export const listFailure = () => createAction(types.LIST_FAILURE);

// Refresh Actions
export const refreshSuccess = location =>
  createAction(types.REFRESH_SUCCESS, { location });

// Save Actions
export const saveRequest = (location, callback = null) =>
  createAction(types.SAVE_REQUEST, { location, callback });
