import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual, uniq, without } from 'lodash';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  notification
} from 'antd';
import GvlCard from 'elements/GvlCard';
import GvlIcon from 'elements/GvlIcon';
import herdListColumns from 'modules/NonAdjacentHerdRequest/components/herdListColumns';
import { Herd, HerdEditModal } from 'modules/Herd/herdIndex';
import { HerdList } from 'modules/HerdList/herdListIndex';
import { deleteRequest as deleteHerdRequest } from 'modules/Herd/store/herdActions';
import { saveRequest as saveHerdListRequest } from 'modules/HerdList/store/herdListActions';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';
import { InlineButtonLink } from 'style/commonEmotions';
import { exportCsv } from 'utils/csvUtil';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';

class NonAdjacentHerdListTableComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    saveHerdListRequest: PropTypes.func.isRequired,
    deleteHerdRequest: PropTypes.func.isRequired,
    stateAbbreviationsMap: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    herdList: PropTypes.object.isRequired,
    vet: PropTypes.object.isRequired,
    naRequest: PropTypes.object,
    enableEdit: PropTypes.bool,
    onHerdListSaved: PropTypes.func
  };

  state = {
    herdsByState: [],
    expandedRows: [],
    selectedHerd: null,
    herdModalVisible: false,
    editHerdListName: false
  };

  componentDidMount() {
    this.getHerdsByState();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.herdList, this.props.herdList)) {
      this.getHerdsByState();
    }
  }

  headCountReducer = (accumulator, currentValue) => accumulator + currentValue;

  getHerdsByState = () => {
    // Clone deep so columns can be sorted
    const herdList = cloneDeep(this.props.herdList);
    const herdsByState = [];

    const states = uniq(herdList?.herds.map(herd => herd?.herdState));
    states.forEach(state => {
      const herdsInState = herdList.herds.filter(
        herd => herd.herdState === state
      );
      const herdSizeInState = herdsInState.filter(
        herd => herd.headCount !== null
      );
      herdsByState.push({
        state: this.props.stateAbbreviationsMap[state],
        stateAbbreviation: state,
        totalLocations: herdsInState.length,
        totalHeadCount: herdSizeInState
          .map(herd => parseInt(herd.headCount))
          .reduce(this.headCountReducer, 0),
        herds: herdsInState
      });
    });

    this.setState({ herdList, herdsByState });
  };

  // State vets can only expand their state. Pharma company users can expand any state
  getRowClassName = state => {
    return hasFeatureAccess(
      this.props.currentUser,
      FeatureEnum.NonAdjacentRegulatoryApprove,
      'update'
    ) &&
      this.props.currentUser.info.regulatoryAuthority !==
        state.stateAbbreviation
      ? 'hide-expand'
      : '';
  };

  expandedRowRender = state => {
    const herdColumns = herdListColumns.generateColumns(
      this.props.currentUser,
      this.props.enableEdit,
      this.props.vet,
      this.onActionClicked
    ).herdList;
    return (
      <Table
        dataSource={state.herds}
        columns={herdColumns}
        rowKey="herdId"
        pagination={false}
        hideSearch={true}
        noRowActions={true}
        size="small"
      />
    );
  };

  exportHerdListCsv = () => {
    const layout = [
      // Note: The order of these fields is not verified and is implied by the server.
      // They must match the order expected by the API.
      { heading: 'name', property: 'producer.name' },
      {
        heading: 'physicalAddress',
        property: 'contactLocation.addressLine1'
      },
      { heading: 'city', property: 'contactLocation.city' },
      { heading: 'state', property: 'contactLocation.state' },
      { heading: 'herdState', property: 'herdState' },
      { heading: 'postalCode', property: 'contactLocation.postalCode' },
      { heading: 'latitude', property: 'contactLocation.latitude' },
      { heading: 'longitude', property: 'contactLocation.longitude' },
      { heading: 'phone', property: 'producer.phone' },
      { heading: 'species', property: 'species' },
      { heading: 'size', property: 'headCount' },
      { heading: 'premiseId', property: 'contactLocation.premisesId' },
      { heading: 'comments', property: 'comments' }
      // Not included: name (herdName?), description, flow, herdId (UUID, Read only), dateCreated (Read only), lastUpdated (Read only)
    ];
    const data = this.props.herdList.herds;
    exportCsv(layout, data, `${this.props.herdList.name}.csv`);
  };

  // This is called when sort order of the table changes, if state vet close the expanded row so they don't see info from other states
  onTableSorted = () => {
    if (
      hasFeatureAccess(
        this.props.currentUser,
        FeatureEnum.NonAdjacentRegulatoryApprove,
        'update'
      )
    ) {
      this.setState({ expandedRows: [] });
    }
  };

  onActionClicked = (type, rows) => {
    const record = rows[0];
    switch (type) {
      case 'edit':
        // This puts the herd back into the format that the model/api expects.
        this.setState({
          selectedHerd: new Herd({
            ...record,
            ...record.contactLocation,
            ...record.producer,
            producerName: record.producer.name
          }),
          herdModalVisible: true
        });
        break;
      case 'delete':
        this.deleteHerd(record);
        break;
      default:
        console.log('unhandled action clicked', type, record);
    }
  };

  deleteHerd = herdToDelete => {
    // Cannot remove last herd from a herd list.
    if (this.props.herdList.herds.length === 1) {
      notification.warning({
        message: intl.get('error.saving.noun', {
          noun: intl.get('herd.list')
        }),
        description: intl.get('herd.list.delete.error')
      });
      return;
    }

    const onDeleteHerdConfirmed = () => {
      if (
        hasFeatureAccess(
          this.props.currentUser,
          FeatureEnum.NonAdjacentClinicInterface,
          'update'
        )
      ) {
        const herdIds = this.props.herdList.herds.map(herd => herd.herdId);
        const herdList = new HerdList({
          herdListId: this.props.herdList.herdListId,
          herds: without(herdIds, herdToDelete.herdId)
        });

        const callback = updatedHerdList => {
          // delete the herd, then update the form
          this.props.deleteHerdRequest(
            herdToDelete.herdId,
            () => this.props.onHerdListSaved(updatedHerdList),
            false
          );
        };

        // Remove the herd from the herd list
        this.props.saveHerdListRequest(herdList, callback);
      } else {
        // Remove the herd list from the herd
        let herdList = cloneDeep(this.props.herdList);
        herdList.herds = herdList.herds.filter(
          herd => herd.herdId !== herdToDelete.herdId
        );

        // Update the form value of herd list so changes only persist to the nonadjacent request
        this.props.onHerdListSaved(herdList);
      }
    };

    Modal.confirm({
      title: intl.get('delete.thing', { thing: intl.get('herd.lc') }),
      content: intl.get('delete.cantBeUndone'),
      okText: intl.get('delete'),
      okType: 'danger',
      onOk() {
        onDeleteHerdConfirmed();
      }
    });
  };

  saveHerdListName = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('herd.list')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      // Only need to update the name so only need the id from the existing herd list.
      const herdList = new HerdList({
        herdListId: this.props.herdList.herdListId,
        ...formValues
      });

      const callback = updatedHerdList => {
        this.setState({ editHerdListName: false });
        this.props.onHerdListSaved(updatedHerdList);
      };

      this.props.saveHerdListRequest(herdList, callback);
    });
  };

  renderHerdListTitle = () => {
    const { form, herdList } = this.props;

    return this.state.editHerdListName ? (
      <Row>
        <Col
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Form layout="vertical">
            <Form.Item label={intl.get('name')}>
              {getModelFieldDecorator(form, 'name', HerdList, true)(<Input />)}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    ) : (
      herdList.name
    );
  };

  renderHerdListExtraContent = () => {
    const { form, herdList } = this.props;
    return this.state.editHerdListName ? (
      <React.Fragment>
        <InlineButtonLink type="link" onClick={this.saveHerdListName}>
          {intl.get('save')}
        </InlineButtonLink>
        <Divider type="vertical" style={{ marginLeft: 8, marginRight: 8 }} />
        <InlineButtonLink
          type="link"
          onClick={() => this.setState({ editHerdListName: false })}
        >
          {intl.get('cancel')}
        </InlineButtonLink>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {hasFeatureAccess(
          this.props.currentUser,
          FeatureEnum.NonAdjacentClinicInterface,
          'update'
        ) && this.props.naRequest?.status === 'CLINIC_DRAFT' ? (
          <React.Fragment>
            <InlineButtonLink
              type="link"
              onClick={() =>
                this.setState({ editHerdListName: true }, () => {
                  form.setFieldsValue({ name: herdList.name });
                })
              }
            >
              {intl.get('edit.herd.list.name')}
            </InlineButtonLink>
            <Divider
              type="vertical"
              style={{ marginLeft: 8, marginRight: 8 }}
            />
          </React.Fragment>
        ) : null}
        {hasFeatureAccess(
          this.props.currentUser,
          FeatureEnum.NonAdjacentRegulatoryInterface,
          'read'
        ) ? null : (
          <InlineButtonLink type="link" onClick={this.exportHerdListCsv}>
            {intl.get('exportAsCsv')}
          </InlineButtonLink>
        )}
      </React.Fragment>
    );
  };

  renderHerdListFooter = () => {
    const herdsByState = this.state.herdsByState;
    return (
      <Row>
        <Col
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <p className="semibold" style={{ marginBottom: 0 }}>
            {intl.get('total.head.count.number', {
              number: herdsByState.length
                ? herdsByState
                    .map(state => state.totalHeadCount)
                    .reduce(this.headCountReducer)
                : 0
            })}
          </p>
          {hasFeatureAccess(
            this.props.currentUser,
            FeatureEnum.NonAdjacentClinicInterface,
            'update'
          ) && this.props.naRequest?.status === 'CLINIC_DRAFT' ? (
            <Button
              style={{ float: 'right' }}
              type="primary"
              onClick={() =>
                this.setState({
                  herdModalVisible: true,
                  selectedHerd: new Herd()
                })
              }
            >
              <GvlIcon type="plus" />
              {intl.get('herd.add')}
            </Button>
          ) : null}
        </Col>
      </Row>
    );
  };

  render() {
    const stateColumns = herdListColumns.generateColumns().stateList;
    const herdsByState = this.state.herdsByState;

    return (
      <GvlCard
        className="full-width"
        title={this.renderHerdListTitle()}
        condensed={true}
        extra={this.renderHerdListExtraContent()}
      >
        <Table
          dataSource={herdsByState}
          columns={stateColumns}
          expandedRowRender={this.expandedRowRender}
          expandIconAsCell={false}
          rowClassName={this.getRowClassName}
          rowKey="state"
          pagination={false}
          scroll={{ x: 'max-content' }}
          onChange={this.onTableSorted}
          onExpandedRowsChange={expandedRows => this.setState({ expandedRows })}
          expandedRowKeys={this.state.expandedRows}
          footer={this.renderHerdListFooter}
        />
        {this.props.enableEdit ? (
          <HerdEditModal
            visible={this.state.herdModalVisible}
            herd={this.state.selectedHerd}
            herdList={this.props.herdList}
            vet={this.props.vet}
            closeModal={() => this.setState({ herdModalVisible: false })}
            onUpdateComplete={this.props.onHerdListSaved}
            naRequestId={this.props.naRequest?.id}
          />
        ) : null}
      </GvlCard>
    );
  }
}

const mapStateToProps = state => {
  return {
    stateAbbreviationsMap:
      state.accounts.common.startupData.lookupLists.usaStateAbbreviationsMap,
    currentUser: state.app.user
  };
};

const mapDispatchToProps = {
  saveHerdListRequest,
  deleteHerdRequest
};

const NonAdjacentHerdListForm = Form.create({ name: 'herdListForm' })(
  NonAdjacentHerdListTableComponent
);

const NonAdjacentHerdListTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(NonAdjacentHerdListForm);

export {
  NonAdjacentHerdListTable as default,
  NonAdjacentHerdListTableComponent
};
