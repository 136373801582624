import * as types from './clinicActionTypes';
import { createAction } from 'utils/reduxUtil';

export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });
export const listVetsForClinicRequest = clinicId =>
  createAction(types.LIST_VETS_FOR_CLINIC_REQUEST, { clinicId });
export const listVetsForClinicSuccess = (clinicId, vetsList) =>
  createAction(types.LIST_VETS_FOR_CLINIC_SUCCESS, { clinicId, vetsList });

export const savePracticeType = ({ clinicId, params, callback }) =>
  createAction(types.SAVE_PRACTICE_TYPE, { clinicId, params, callback });
