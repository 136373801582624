// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Model from 'models/Model';
import * as api from 'utils/api';
// import { stringRequired } from 'utils/validation/common';

export default class FeedMill extends Model {
  static get fields() {
    // TODO: add validators
    return {
      ...super.fields,
      addressLine1: {
        initialValue: ''
      },
      addressLine2: {
        initialValue: ''
      },
      bonusServices: {
        initialValue: []
      },
      city: {
        initialValue: ''
      },
      email: {
        initialValue: ''
      },
      enabled: {
        initialValue: true
      },
      fax: {
        initialValue: ''
      },
      id: {
        initialValue: undefined
      },
      label: {
        initialValue: ''
      },
      latitude: {
        initialValue: undefined
      },
      longitude: {
        initialValue: undefined
      },
      name: {
        initialValue: ''
      },
      phone: {
        initialValue: ''
      },
      postalCode: {
        initialValue: ''
      },
      serviceLevel: {
        initialValue: -1
      },
      state: {
        initialValue: ''
      },
      userAgreeVersion: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'feedMill';
  }

  static get domain() {
    return 'FeedMill';
  }

  static fetchMillList = async () => await api.get(`${this.apiPath}/listMills`);
}
