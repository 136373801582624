import * as types from './herdListActionTypes';
import { createAction } from 'utils/reduxUtil';

export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });

export const listRequest = params =>
  createAction(types.LIST_REQUEST, { params });
export const listSuccess = herdLists =>
  createAction(types.LIST_SUCCESS, { herdLists });
export const listFailure = () => createAction(types.LIST_FAILURE);

export const saveRequest = (
  herdList,
  callback = null,
  showSuccessMessage = true
) =>
  createAction(types.SAVE_REQUEST, { herdList, callback, showSuccessMessage });

export const refreshSuccess = herdList =>
  createAction(types.REFRESH_SUCCESS, { herdList });
