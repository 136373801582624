import PropTypes from 'prop-types';

const actionInfoPropTypes = {
  actionsTitle: PropTypes.string.isRequired, // Usually "Actions"
  onActionClicked: PropTypes.func.isRequired, // Called with action type and array of object(s)
  allowMultiSelect: PropTypes.bool.isRequired,
  multipleSelectedText: PropTypes.string, // IE: "Animals selected"
  singleSelectedText: PropTypes.string, // IE: "Animal selected"
  noSelectedText: PropTypes.string, // IE: "Nothing selected"
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired, // Sent back as first parameter to onActionClicked.  IE: "sign"
      text: PropTypes.any.isRequired, // For the button, such as "Sign Document"
      highlightDanger: PropTypes.boolean, // Set to true if you want the link text to be highlighted as danger (IE: red)
      disabled: PropTypes.boolean,
      onlyMultiSelect: PropTypes.boolean, // Set to true if you only want the action to appear on multi-select Action menu
      onlyRow: PropTypes.boolean, // Set to true if you only want the action to appear on row Action menu
      haveSubMenu: PropTypes.boolean,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string
        })
      )
    })
  ).isRequired
};

export default actionInfoPropTypes;
