import * as types from 'modules/Herd/store/herdActionTypes';
import { createAction } from 'utils/reduxUtil';

// Generic Actions
export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });

// List Actions
export const listRequest = (params = null) =>
  createAction(types.LIST_REQUEST, { params });
export const listSuccess = herds => createAction(types.LIST_SUCCESS, { herds });
export const listFailure = () => createAction(types.LIST_FAILURE);

// Update Actions
export const saveRequest = (herd, callback = null, showSuccessMessage = true) =>
  createAction(types.SAVE_REQUEST, { herd, callback, showSuccessMessage });

// Delete Actions
export const deleteRequest = (
  herdId,
  callback = null,
  showSuccessMessage = true
) =>
  createAction(types.DELETE_REQUEST, { herdId, callback, showSuccessMessage });
