import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { FONT, THEME } from 'style';

export default function IsolateVal({ name, val }) {
  return (
    <div style={{ color: THEME.isolateBlack }}>
      <div style={{ fontWeight: FONT.weight.bold }}>{intl.get(name)}</div>
      <div>{val ? val : '-'}</div>
      <br />
    </div>
  );
}

IsolateVal.propTypes = {
  name: PropTypes.string.isRequired,
  val: PropTypes.any
};
