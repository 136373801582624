import React, { useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import {
  LocationLookup,
  LocationInlineShow,
  LocationEditModal
} from 'modules/Location/locationIndex';

const LocationLookupFormItem = React.forwardRef((props, _ref) => {
  const {
    searchParams,
    disabled,
    tooltipText,
    onChange,
    onCreateNew,
    onRemoveLocation,
    value
  } = props;
  const [editingLocation, setEditingLocation] = useState(false);
  return value ? (
    <React.Fragment>
      <LocationEditModal
        visible={editingLocation}
        location={value}
        closeModal={() => setEditingLocation(false)}
        onChange={onChange}
      />
      <LocationInlineShow
        location={value}
        onRemoveLocation={onRemoveLocation}
        onChange={onChange}
        onEditClicked={() => setEditingLocation(true)}
      />
    </React.Fragment>
  ) : (
    <Row gutter={8}>
      <Col md={19} sm={16}>
        <LocationLookup
          searchParams={searchParams}
          disableSelect={disabled}
          tooltipText={tooltipText}
          onChange={onChange}
        />
      </Col>
      <Col md={5} sm={8}>
        <Button
          disabled={disabled}
          block={true}
          type="default"
          onClick={() => onCreateNew()}
        >
          {intl.get('create.new')}
        </Button>
      </Col>
    </Row>
  );
});

LocationLookupFormItem.displayName = 'LocationLookupFormItem';
LocationLookupFormItem.propTypes = {
  onChange: PropTypes.func,
  onCreateNew: PropTypes.func.isRequired,
  onRemoveLocation: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  searchParams: PropTypes.object,
  value: PropTypes.object
};

export default LocationLookupFormItem;
