import Model from 'models/Model';
import {
  stringRequired,
  minLength,
  maxLength,
  isEmail,
  passwordStrengthValidator,
  containsUpperAndLowerCaseLetters,
  containsNumber,
  containsSymbol
} from 'utils/validation/common';
import * as api from 'utils/api';
import { UserRoleEnum } from 'modules/Registration/User/UserRoleEnum';

export default class UserRegistration extends Model {
  static get fields() {
    return {
      firstName: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      lastName: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      username: {
        initialValue: undefined,
        validate: value =>
          stringRequired(value, 'required') ||
          isEmail(value, 'validation.email.invalid') ||
          maxLength(value, 120)
      },
      password: {
        initialValue: undefined,
        validate: (value, instance) => {
          if (instance) {
            return (
              stringRequired(value, 'required') ||
              minLength(value, 8) ||
              containsUpperAndLowerCaseLetters(value) ||
              containsNumber(value) ||
              containsSymbol(value) ||
              passwordStrengthValidator(value)
            );
          } else {
            return stringRequired(value, 'required');
          }
        }
      },
      licenses: {
        initialValue: undefined
      },
      signature: {
        initialValue: undefined
      },
      address: {
        initialValue: undefined
      },
      premisesId: {
        initialValue: undefined
      },
      role: {
        initialValue: UserRoleEnum.VET,
        validate: value => stringRequired(value, 'required')
      },
      accountId: {
        initialValue: undefined
      },
      account: {
        initialValue: undefined
      },
      appUser: {
        initialValue: undefined
      },
      verificationCode: {
        initialValue: undefined
      },
      status: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'registration/user';
  }

  static get domain() {
    return 'UserRegistration';
  }

  static read = async (registrationId, verificationCode) =>
    await api.get(
      `${UserRegistration.apiPath}/${registrationId}/${verificationCode}`
    );

  // https://community.sonarsource.com/t/refactor-this-redundant-await-on-a-non-promise-sonarlint-javascript-s4123/43485/3
  submit = async () =>
    await api.post(`${UserRegistration.apiPath}`, this.toJson());

  static verify = async (registrationId, params) =>
    await api.put(
      `${UserRegistration.apiPath}/${registrationId}/verify`,
      params
    );

  static resendVerification = async registrationId =>
    await api.post(
      `${UserRegistration.apiPath}/${registrationId}/sendVerification`
    );

  static renewVerification = async registrationId =>
    await api.post(`${UserRegistration.apiPath}/${registrationId}/renew`);

  static validateUsername = async params =>
    await api.post(`${UserRegistration.apiPath}/validateUsername`, params);
}
