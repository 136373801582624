import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './userRegistrationActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  isCreatingUser: false,
  verificationStatus: 'VERIFYING'
});

export const onActionInProgress = (state, { action, inProgress }) =>
  Immutable.setIn(state, [action], inProgress);

export const onSetVerificationStatus = (state, { status }) =>
  Immutable.setIn(state, ['verificationStatus'], status);

export const userRegistrationReducer = createReducer(initialState, {
  [types.ACTION_IN_PROGRESS]: onActionInProgress,
  [types.SET_VERIFICATION_STATUS]: onSetVerificationStatus
});
