import * as api from 'utils/api';
import Model from 'models/Model';
import {
  stringRequired,
  genericArrayRequired,
  maxLength
} from 'utils/validation/common';

export default class IsolateExtension extends Model {
  validate() {
    return super.validate();
  }

  static get fields() {
    return {
      ...super.fields,
      isolateExtensionId: {
        initialValue: undefined
      },
      isolateId: {
        initialValue: undefined,
        validate: value => stringRequired(value, 'required')
      },
      justifications: {
        initialValue: [],
        validate: value => genericArrayRequired(value, 'required')
      },
      efficacies: {
        initialValue: [],
        validate: value => genericArrayRequired(value, 'required')
      },
      hasAdverseReaction: {
        initialValue: false
      },
      reactionDetails: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.hasAdverseReaction
            ? stringRequired(value, 'required') || maxLength(value, 255)
            : null;
        }
      },
      clinic: {
        initialValue: undefined
      },
      vet: {
        initialValue: undefined
      },
      status: {
        initialValue: undefined
      },
      requestFeedback: {
        initialValue: undefined,
        validate: value => {
          return value ? maxLength(value, 255) : null;
        }
      },
      acknowledgedDate: {
        initialValue: undefined
      },
      approvedDate: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/isolate';
  }

  static get domain() {
    return 'IsolateExtension';
  }

  async saveExtension() {
    let result;
    if (this.isolateExtensionId) {
      result = await api.put(
        `${IsolateExtension.apiPath}/${this.isolateId}/extension/${this.isolateExtensionId}`,
        this.toJson()
      );
    } else {
      result = await api.post(
        `${IsolateExtension.apiPath}/${this.isolateId}/extension`,
        this.toJson()
      );
    }
    this.constructor.initialize(this, result);
  }

  static async signExtension(isolateId, isolateExtensionId) {
    await api.put(
      `${IsolateExtension.apiPath}/${isolateId}/extension/${isolateExtensionId}/sign`
    );
  }

  async requestChanges() {
    let result;
    result = await api.put(
      `${IsolateExtension.apiPath}/${this.isolateId}/extension/${this.isolateExtensionId}/requestChanges`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }

  static async approveExtension(isolateId, isolateExtensionId) {
    await api.put(
      `${IsolateExtension.apiPath}/${isolateId}/extension/${isolateExtensionId}/approve`
    );
  }

  static async deleteExtension(isolateId, isolateExtensionId) {
    await api.deleteRec(
      `${IsolateExtension.apiPath}/${isolateId}/extension/${isolateExtensionId}`
    );
  }
}
