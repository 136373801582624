import { getValue, setValue } from 'utils/settingStorage';

export const getUserSettings = id => {
  try {
    const userId = id;
    const allUserSettings = getValue('userSettings') || {};
    return allUserSettings[userId] || {};
  } catch (err) {
    console.error('Error reading user settings');
    return;
  }
};

export const setUserSettings = (userId, newSettings) => {
  try {
    let allUserSettings = getValue('userSettings') || {};
    const settings = allUserSettings[userId] || {};
    allUserSettings[userId] = { ...settings, ...newSettings };
    setValue('userSettings', allUserSettings);
  } catch (err) {
    console.error('Error writing user settings');
    return;
  }
};
