import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import * as types from 'modules/User/store/userActionTypes';

const initialState = Immutable.from({
  processing: false,
  data: {}
});

export const onActionInProgress = (state, { value }) => {
  return state.merge({
    processing: value
  });
};

export const onLoadUsersSuccess = (state, { users }) => {
  let data = {};
  for (const user of users) {
    data[user.id] = user;
  }
  return state.merge({
    data
  });
};

export const onSaveUserSuccess = (state, { user }) => {
  return Immutable.setIn(state, ['data', user.id], user);
};

export const userReducer = createReducer(initialState, {
  [types.ACTION_IN_PROGRESS]: onActionInProgress,
  [types.LOAD_USERS_SUCCESS]: onLoadUsersSuccess,
  [types.SAVE_USER_SUCCESS]: onSaveUserSuccess
});
