import { combineReducers } from 'redux';
import * as types from 'containers/registration/store/registrationActionTypes';
import { accountRegistrationReducer } from 'modules/Registration/Account/store/accountRegistrationReducers';
import { userRegistrationReducer } from 'modules/Registration/User/store/userRegistrationReducers';

export default function registrationReducer() {
  const registrationCombinedReducers = combineReducers({
    account: accountRegistrationReducer,
    user: userRegistrationReducer
  });

  const registrationCombinedReducer = (state, action) => {
    if (action.type === types.REGISTRATION_RESET) {
      state = undefined;
    }
    return registrationCombinedReducers(state, action);
  };

  return registrationCombinedReducer;
}
