import { get } from 'utils/api';

export const retrieveData = async (url, params, onLimitReached) => {
  let result = [];
  try {
    result = await get(url, { params });
    // deal with apiVersion 1 api list json
    if (result.results) {
      result = result.results;
    }
  } catch (err) {
    // TODO:Add a better notification
    console.error('Error connecting to server');
  }
  if (onLimitReached && params.limit && result.length >= params.limit)
    onLimitReached();
  return result;
};
