import 'react-app-polyfill/ie11'; //if we drop IE11 support, remove this, and remove the polyfill from package.json
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './generated/index.css';
import App from 'containers/app/App';
import * as serviceWorker from './serviceWorker';

import store from 'store';

import LanguageProvider from 'containers/app/LanguageProvider';

/* istanbul ignore file */

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </Provider>,
  MOUNT_NODE
);

// If you want your containers.app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
