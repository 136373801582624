import React from 'react';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import { GvlMenu } from 'style/commonEmotions';
import * as userRoles from 'utils/userRoles';
import * as userUtil from 'utils/userUtil';

function LabMenuComponent({ location, user }) {
  const nvslUser = userUtil.hasOneOfRoles(user, [userRoles.ROLE_NVSL_LAB_TECH]);
  return (
    <GvlMenu selectedKeys={[location.pathname]} mode="inline">
      <GvlMenuItem
        key="/inbox"
        link="/inbox"
        isTopLevel={true}
        icon="inbox"
        title={intl.get('inbox')}
      />
      <GvlMenuItem
        key="/tests"
        link="/tests"
        isTopLevel={true}
        icon="unordered-list"
        title={intl.get('tests')}
      />
      {!nvslUser && (
        <GvlMenuItem
          key="/certificates"
          link="/certificates"
          isTopLevel={true}
          icon="search"
          title={intl.get('certificates')}
        />
      )}

      <GvlSubMenu key="help" title={intl.get('help')} icon="gvl_help">
        <GvlMenuItem
          key="/helpArticles"
          link="http://help.globalvetlink.com"
          title={intl.get('helpArticles')}
          external={true}
        />
      </GvlSubMenu>
    </GvlMenu>
  );
}

LabMenuComponent.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const LabMenu = withRouter(LabMenuComponent);

export { LabMenu as default, LabMenuComponent };
