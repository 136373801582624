import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from 'antd';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { generateSelectOptions } from 'utils/appUtil';

class StateSelectComponent extends Component {
  static propTypes = {
    alternateOptions: PropTypes.arrayOf(
      PropTypes.exact({
        value: PropTypes.string,
        text: PropTypes.string,
        disabled: PropTypes.bool
      })
    ),
    areAlternateOptionsShown: PropTypes.bool,
    disabledStatesList: PropTypes.array,
    reasonForDisablingStates: PropTypes.oneOfType([
      PropTypes.object, // Object displaying individual reasons for different states. ex: ['NJ': 'Doesn't participate in EECVI program', 'NY': 'We do not service that area']
      PropTypes.string // catch-all reason for ALL disabled states in this component. ex: 'Doesn't participate in EECVI program'
    ]),
    selectedCountry: PropTypes.string,
    stateList: PropTypes.object.isRequired,
    stateAbbreviationsMaps: PropTypes.object.isRequired
  };

  static defaultProps = {
    alternateOptions: [],
    areAlternateOptionsShown: false,
    disabledStatesList: [],
    selectedCountry: 'USA'
  };

  state = {
    searchInput: ''
  };

  stateAbbrToObject = abbr => {
    const {
      disabledStatesList,
      reasonForDisablingStates,
      selectedCountry,
      stateAbbreviationsMaps
    } = this.props;
    var defaultText = `${abbr} - ${stateAbbreviationsMaps[selectedCountry][abbr]}`;
    // if reasonForDisablingStates prop exists, check if type is string or object and set reason appropriately
    var disabledText = reasonForDisablingStates
      ? typeof reasonForDisablingStates === 'string'
        ? `${reasonForDisablingStates}`
        : `${reasonForDisablingStates[abbr]}`
      : '';
    return {
      value: abbr,
      text:
        disabledStatesList.includes(abbr) && disabledText !== ''
          ? `${defaultText} - ${disabledText}`
          : defaultText,
      disabled: disabledStatesList.includes(abbr)
    };
  };

  getFilteredOptions = () => {
    const searchInput = this.state.searchInput.toLowerCase();
    const {
      stateList,
      selectedCountry,
      areAlternateOptionsShown,
      alternateOptions
    } = this.props;
    const stateOptions = areAlternateOptionsShown
      ? alternateOptions
      : stateList[selectedCountry].map(this.stateAbbrToObject);
    // filter options against the search input
    let filteredStates = cloneDeep(stateOptions).filter(
      state => state.text.toLowerCase().indexOf(searchInput) >= 0
    );

    // If there is a state matching the abbr, move it to the front of the select options
    if (searchInput.length === 2 && !areAlternateOptionsShown) {
      const stateMatchingAbbr = filteredStates.find(
        state => state.value.toLowerCase() === searchInput
      );
      if (stateMatchingAbbr) {
        filteredStates = filteredStates.filter(
          state => state !== stateMatchingAbbr
        );
        filteredStates.unshift(stateMatchingAbbr);
      }
    }

    return filteredStates;
  };

  render() {
    const {
      alternateOptions,
      areAlternateOptionsShown,
      disabledStatesList,
      reasonForDisablingStates,
      selectedCountry,
      stateList,
      stateAbbreviationsMaps,
      ...rest
    } = this.props;
    return ['USA', 'Canada', 'Mexico'].includes(selectedCountry) ? (
      <Select
        showSearch
        showAction={['focus', 'click']}
        filterOption={false}
        onSearch={searchInput => this.setState({ searchInput })}
        {...rest}
      >
        {generateSelectOptions(this.getFilteredOptions())}
      </Select>
    ) : (
      <Input {...rest} dispatch={undefined} />
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  stateList: {
    USA: accounts?.common?.startupData?.lookupLists?.usaAbbreviationsList || [],
    Canada:
      accounts?.common?.startupData?.lookupLists?.canadaAbbreviationsList || [],
    Mexico:
      accounts?.common?.startupData?.lookupLists?.mexicoAbbreviationsList || []
  },
  stateAbbreviationsMaps: {
    USA:
      accounts?.common?.startupData?.lookupLists?.usaStateAbbreviationsMap ||
      [],
    Canada:
      accounts?.common?.startupData?.lookupLists?.canadaStateAbbreviationsMap ||
      [],
    Mexico:
      accounts?.common?.startupData?.lookupLists?.mexicoStateAbbreviationsMap ||
      []
  }
});

const StateSelect = connect(mapStateToProps, null, null, { forwardRef: true })(
  StateSelectComponent
);

export { StateSelect as default, StateSelectComponent };
