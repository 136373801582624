// Holds data that is used by whole application
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import { notification } from 'antd';

import {
  API_FAILURE,
  CONNECTION_ERROR,
  REMOVE_API_ERROR,
  SET_VIEW_MODE
} from 'containers/app/store/data/dataConstants';

export const initialState = Immutable.from({
  apiErrors: [],
  connectionError: false,
  viewMode: 'Desktop'
});

export const onApiFailure = (state, { error, title }) => {
  let description;
  try {
    if (error.isAxiosError) {
      description = error.response.data.errors[0].message;
    } else if (Array.isArray(error)) {
      description = error.join(',');
    }
  } catch (err) {
    // Catch new errors due to the original error not being structured how we thought it was.
  }

  // TODO
  // 1) move this to a saga since it isn't updating the react store.
  // 2) pass intl through as part of this action so the messages can be translated.

  notification.error({
    message: title || 'Something went wrong',
    description:
      description || "Ensure that you're online, then try reloading the page"
  });

  const apiError = Immutable.from({
    timestamp: new Date().getTime(),
    error: error
  });

  return state.merge({ apiErrors: state.apiErrors.concat(apiError) });
};

export const onRemoveApiError = (state, { timestamp }) => {
  const remainingApiErrors = state.apiErrors.filter(
    apiError => apiError.timestamp !== timestamp
  );
  return state.merge({ apiErrors: remainingApiErrors });
};

export const onSetViewMode = (state, { viewMode }) =>
  state.merge({ viewMode: viewMode });

// CONNECTION_ERROR is used to indicate a major problem in the app, like not being able to load startup data
export const onConnectionError = state =>
  state.merge({ connectionError: true });

const dataReducer = createReducer(initialState, {
  [API_FAILURE]: onApiFailure,
  [REMOVE_API_ERROR]: onRemoveApiError,
  [CONNECTION_ERROR]: onConnectionError,
  [SET_VIEW_MODE]: onSetViewMode
});

export default dataReducer;
