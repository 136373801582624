import Model from 'models/Model';
import {
  maxLength,
  isNumber,
  numberInRange,
  stringRequired
} from 'utils/validation/common';

export default class Location extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    const validatePhysicalAddressWithMaxLength = (value, instance, length) => {
      return instance.longitude || instance.latitude
        ? null
        : maxLength(value, length);
    };
    return {
      ...super.fields,
      locationId: {
        initialValue: undefined
      },
      displayName: {
        initialValue: undefined
      },
      contactId: {
        initialValue: undefined
      },
      name: {
        initialValue: undefined,
        validate: (value, instance) => {
          return value ? null : stringRequired(value, 'required');
        }
      },
      description: {
        initialValue: undefined
      },
      phone: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      fax: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      addressLine1: {
        initialValue: undefined,
        validate: value => {
          return null;
        }
      },
      addressLine2: {
        initialValue: undefined
      },
      city: {
        initialValue: undefined,
        validate: (value, instance) => {
          return validatePhysicalAddressWithMaxLength(value, instance, 60);
        }
      },
      state: {
        initialValue: undefined,
        validate: (value, instance) => {
          return validatePhysicalAddressWithMaxLength(value, instance, 2);
        }
      },
      postalCode: {
        initialValue: undefined,
        validate: (value, instance) => {
          return validatePhysicalAddressWithMaxLength(value, instance, 12);
        }
      },
      county: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 60);
        }
      },
      country: {
        initialValue: undefined
        // validate: (value, instance) => {
        //   return instance.longitude || instance.latitude
        //     ? null
        //     : stringRequired(value, 'required') || maxLength(value, 120);
        // }
      },
      longitude: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.addressLine1 || !value
            ? null
            : isNumber(value, 'validation.latitude.number') ||
                numberInRange(value, -180, 180);
        }
      },
      latitude: {
        initialValue: undefined,
        validate: (value, instance) => {
          return instance.addressLine1 || !value
            ? null
            : isNumber(value, 'validation.latitude.number') ||
                numberInRange(value, -90, 90);
        }
      },
      pin: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 25);
        }
      },
      lid: {
        initialValue: undefined,
        validate: value => {
          return !value ? null : maxLength(value, 25);
        }
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      },
      version: {
        initialValue: undefined
      }
    };
  }

  static list = async params => await super.list(params);

  static read = async id => await super.read(id);

  static get apiPath() {
    return 'records/location';
  }

  static get domain() {
    return 'LocationV2';
  }

  static get idFieldName() {
    return 'locationId';
  }

  isValidLocation() {
    return (
      (this.addressLine1 &&
        this.city &&
        this.state &&
        this.postalCode &&
        this.country) ||
      (this.latitude && this.longitude)
    );
  }
}
