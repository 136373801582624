export const ACTION_IN_PROGRESS = 'accountRegistration/ACTION_IN_PROGRESS';

export const LOAD_REGISTRATION_DATA =
  'accountRegistration/LOAD_REGISTRATION_DATA';

export const LOAD_REGISTRATION_DATA_SUCCESS =
  'accountRegistration/LOAD_REGISTRATION_DATA_SUCCESS';

export const IS_CLINIC_EMAIL_DUPLICATE =
  'accountRegistration/IS_CLINIC_EMAIL_DUPLICATE';

export const RESUME_REGISTRATION = 'accountRegistration/RESUME_REGISTRATION';

export const SEND_INTERNAL_ANALYTICS_EVENT =
  'accountRegistration/SEND_INTERNAL_ANALYTICS_EVENT';

export const SUBMIT_REGISTRATION = 'accountRegistration/SUBMIT_REGISTRATION';
export const COMPLETE_REGISTRATION =
  'accountRegistration/COMPLETE_REGISTRATION';
