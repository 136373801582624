import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GvlMenu } from 'style/commonEmotions';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import intl from 'react-intl-universal';
import { isVaccineFeatureEnabled } from 'containers/app/store/user/userSelectors';

function RegulatoryMenuComponent({
  location,
  user,
  ehcParticipants,
  regulatoryAuthority,
  autogenousVaccinesFeatureEnabled
}) {
  const isCountryUS = user.info.userCountry === 'USA';
  const ehcStates = Object.keys(ehcParticipants);
  const isEhcParticipant =
    isCountryUS && ehcStates.includes(regulatoryAuthority);

  const smartEngineMenu = (
    <GvlSubMenu
      key="smartEngineRegulatory"
      title={intl.get('smartEngine')}
      icon="gvl-double-check"
    >
      <GvlMenuItem
        key="/rule"
        link="/smartEngine/rules/"
        title={intl.get('rules')}
      />

      <GvlMenuItem
        key="/animalreg"
        link="https://animalregs.com/"
        title={intl.get('animalRegs.com')}
        external={true}
      />
    </GvlSubMenu>
  );

  const ehcMenu = (
    <GvlSubMenu
      key="/eecvi"
      title={intl.get('eecvis')}
      icon="gvl-arrows-opposite"
    >
      <GvlMenuItem
        key="/permit"
        link="/eecvis/permits"
        title={intl.get('permits')}
      />
      <GvlMenuItem
        key="/travelSetting"
        link="/eecvis/travelSettings/"
        title={intl.get('travelSettings')}
      />
    </GvlSubMenu>
  );

  const customVaccinesMenu = (
    <GvlSubMenu
      key="autogenousVaccines"
      title={intl.get('vaccines.customMade')}
      icon="gvl-syringe"
    >
      <GvlMenuItem
        key="/products"
        link="/products"
        title={intl.get('isolate.products')}
      />
    </GvlSubMenu>
  );

  // TODO: Update the menu to only show menu items available to specific role(s).  See AdminRoute.jsx
  return (
    <GvlMenu selectedKeys={[location.pathname]} mode="inline">
      <GvlSubMenu key="records" title={intl.get('records')} icon="gvl-records">
        <GvlMenuItem
          key="/certificate"
          link="/certificates/"
          title={intl.get('certificates')}
        />
        <GvlMenuItem key="/test" link="/tests/" title={intl.get('tests')} />
      </GvlSubMenu>
      {isCountryUS ? smartEngineMenu : null}
      {isEhcParticipant ? ehcMenu : null}
      {isCountryUS && autogenousVaccinesFeatureEnabled
        ? customVaccinesMenu
        : null}
      <GvlSubMenu key="/help" title={intl.get('help')} icon="gvl-help">
        <GvlMenuItem
          key="/helpArticle"
          link="http://help.globalvetlink.com/en//"
          title={intl.get('helpArticles')}
          external={true}
        />
      </GvlSubMenu>
    </GvlMenu>
  );
}

RegulatoryMenuComponent.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  ehcParticipants: PropTypes.object.isRequired,
  regulatoryAuthority: PropTypes.string,
  autogenousVaccinesFeatureEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    ehcParticipants:
      state.accounts.common.startupData.lookupLists.ehcParticipants || {},
    regulatoryAuthority: state.app.user.info.regulatoryAuthority,
    autogenousVaccinesFeatureEnabled: isVaccineFeatureEnabled(state)
  };
};

const RegulatoryMenuWithRedux = connect(mapStateToProps)(
  RegulatoryMenuComponent
);
const RegulatoryMenu = withRouter(RegulatoryMenuWithRedux);
export { RegulatoryMenu as default, RegulatoryMenuComponent };
