import moment from 'moment';
import Document from 'models/documents/Document';
import Location from 'models/Location';
import Animal from 'models/Animal';
import * as api from 'utils/api';
import {
  validateEecviQualifyingEia,
  officialIdType,
  validateOfficialIdValue,
  validateEecviOwner
} from 'utils/validation/document';
import {
  pojoRequired,
  isNotPoBox,
  dateInRangeNoTime,
  dateIsBefore,
  stringRequired,
  dateIsFutureDate,
  objectRequired,
  isDate
} from 'utils/validation/common';
import { downloadFile } from 'utils/downloadUtil';

export default class Eecvi extends Document {
  // Instance Methods
  validate() {
    const results = super.validate();

    // TODO: Put complex validations here.  If none, then delete this whole validate() method.
    // Example of how you would indicate an error:
    // results.push(Model.formatError('Account problem', {messageId: 'account.problem', values: {something: 'some value'}}));

    return results;
  }

  // Static Methods
  static get fields() {
    return {
      ...super.fields,
      validationLevel: {
        // This property will not be persisted and is only used to provide varying levels of validation feedback eg minimum required to save draft vs ready-to-commit
        initialValue: 'save'
      },
      id: {
        initialValue: undefined,
        validate: value => {
          return null;
        }
      },
      animal: {
        initialValue: {},
        validate: value => {
          // (optional)
          // API: Must be a valid Animal, could just send id or no id
          // neptr: must have id
          return !value ? null : new Animal(value).validate();
        }
      },
      owner: {
        initialValue: {},
        validate: owner => {
          return (
            objectRequired('owner', owner) || validateEecviOwner(owner) || null
          );
        }
      },
      ownerPremises: {
        initialValue: {},
        validate: (value, instance) => {
          // (optional)
          // Must be a valid Premises object, including state and addressLine1
          // cannot be a PO Box
          // state must be in ehcParticipants
          const ehcParticipantError =
            Object.keys(instance.ehcParticipants).indexOf(
              (value || {}).state
            ) === -1
              ? {
                  messageId: 'signing.origin.ehcParticipant.error',
                  values: {}
                }
              : null;
          return !value
            ? null
            : new Location(value).validate() ||
                isNotPoBox(value.addressLine1) ||
                ehcParticipantError;
        }
      },
      qualifyingEIA: {
        initialValue: {},
        validate: (value, instance, subfieldName) => {
          switch (subfieldName) {
            case 'accession':
              return stringRequired(value.accession, 'required');
            case 'lab':
              return stringRequired(value.lab, 'required');
            case 'bloodDrawn':
              return (
                isDate(value.bloodDrawn, 'MM/DD/YYYY', 'required') ||
                dateInRangeNoTime(
                  value.bloodDrawn,
                  moment().subtract(1, 'years'),
                  moment()
                )
              );
            case 'issued':
              return (
                isDate(value.issued, 'MM/DD/YYYY', 'required') ||
                dateIsFutureDate(value.issued, 'issued.notFuture') ||
                dateIsBefore(
                  value.issued,
                  instance.qualifyingEIA.bloodDrawn ||
                    moment().subtract(1, 'years'),
                  instance.qualifyingEIA.bloodDrawn
                    ? 'issued.notBeforeBloodDrawn'
                    : 'issued.withinAYear'
                )
              );
            default:
              // (optional? 500 error if not provided)
              // API: if id is given:
              //  must be a persisted EIA in GVL
              //  qualifyingEIA.owner.id = owner.id
              //  qualifyingEIA.animal.id = animal.id
              //  qualifyingEIA.status = "SIGNED"
              //  qualifyingEIA.expirationDate > Date.now()
              return (
                pojoRequired('qualifyingEIA', value) ||
                validateEecviQualifyingEia(value)
              );
          }
        }
      },
      officialId: {
        initialValue: {},
        validate: (value, instance) => {
          // TODO: Add more validation for LBI: valid state, date not > today, others???
          let ehcIdTypesAllowed = [
            'eiaAccessionNumber',
            'lifetimeBrandInspection',
            'microchip',
            'photoEia'
          ];
          // Verify selected officialId is accepted in ehc participating state
          if (instance.ownerPremises) {
            const isParticipatingState =
              Object.keys(instance.ehcParticipants).indexOf(
                instance.ownerPremises.state
              ) !== -1;
            if (isParticipatingState) {
              ehcIdTypesAllowed =
                instance.ehcParticipants[instance.ownerPremises.state]
                  .ehcIdTypesAllowed;
            }
          }

          return (
            pojoRequired('officialId', value) ||
            officialIdType(value, ehcIdTypesAllowed) ||
            validateOfficialIdValue(value)
          );
        }
      },
      ownerAffirmDate: {
        // TODO: It's always null in neptr and cricket... What the ?
        initialValue: null,
        validate: value => {
          return null;
        }
      },
      species: {
        initialValue: 'Equine',
        validate: value => {
          // TODO: Probably shouldn't allow anything besides Equine?
          return null;
        }
      },
      lifetimeBrandInspection: {
        // read-only
        initialValue: undefined,
        validate: value => {
          return null;
        }
      },
      permits: {
        // read-only
        initialValue: [],
        validate: value => {
          return null;
        }
      },
      ehcParticipants: {
        initialValue: {}
      }
    };
  }

  static get apiPath() {
    return 'documents';
  }

  static get docType() {
    return 'EHC';
  }

  static get domain() {
    return `${super.domain}.${this.docType}`;
  }

  static read = async id => {
    return super.read(id);
  };

  static getDrafts = async () => {
    return super.getDrafts(this.docType);
  };

  static getRecentlyCompleted = async () => {
    const data = await super.list({
      'metadata.status': 'SIGNED',
      signedSince: moment()
        .subtract(14, 'days')
        .toJSON(),
      'metadata.archived': false,
      type: this.docType,
      limit: 0
    });
    return data.results;
  };

  static getRequests = async () => {
    return super.getRequests(this);
  };

  static checkCommittingCredentials = (ehcParticipants, state, user) => {
    if (!user.roles.includes('ROLE_VET')) {
      return 'signing.role.error';
    } else if (!state) {
      return 'signing.origin.error';
    } else if (!user.info.licenseStates.includes(state)) {
      return 'signing.license.error.properNoun';
    } else if (!user.info.canSignCerts) {
      return 'signing.cert.error';
    } else if (!(state in ehcParticipants)) {
      return 'signing.origin.ehcParticipant.error';
    } else {
      return '';
    }
  };

  static commitPermit = async (eecviId, permitData) => {
    return await api.put(
      `${this.apiPath}/${eecviId}?protectedAction=commitEhcPermit`,
      permitData
    );
  };

  static downloadPermit = (eecviId, permitSerialNumber) =>
    downloadFile(`documents/${eecviId}/resources/${permitSerialNumber}/pdf`);
}
