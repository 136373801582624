import React from 'react';
import PropTypes from 'prop-types';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';

export default function ClinicAddress({ clinic, showName = false }) {
  return (
    <IsolateValWithIcon
      icon="environment"
      val={
        <React.Fragment>
          {showName ? <div>{clinic.name}</div> : null}
          <div>{clinic.addressLine1}</div>
          <div>{`${clinic.city}, ${clinic.state} ${clinic.postalCode}`}</div>
          <br />
        </React.Fragment>
      }
    />
  );
}

ClinicAddress.propTypes = {
  clinic: PropTypes.object.isRequired,
  showName: PropTypes.bool
};
