import styled from '@emotion/styled';
import { FONT, THEME } from 'style';
import { Button, Col, Divider } from 'antd';

// TODO: move all this css to src.less/components/AnimalInlineShow.less

/* istanbul ignore next */
export const UnborderedButton = styled(Button)({
  fontSize: FONT.size.xlarge + ' !important',
  verticalAlign: 'middle',
  padding: 0
});

/* istanbul ignore next */
export const VerticalDivider = styled(Divider)({
  marginLeft: '8px',
  marginRight: '8px',
  backgroundColor: THEME.dividerColor()
});

/* istanbul ignore next */
export const HorizontalDivider = styled(Divider)({
  margin: '0 0 1px 0',
  backgroundColor: THEME.dividerColor()
});

/* istanbul ignore next */
export const AnimalNameText = styled('h4')({
  margin: '0'
});

/* istanbul ignore next */
export const AdditionalContentText = styled('h4')({
  margin: '0',
  display: 'inline-block',
  float: 'left'
});

/* istanbul ignore next */
export const AdditionalContentEdit = styled('div')({
  marginTop: '-1px'
});

/* istanbul ignore next */
export const AnimalText = styled('div')({
  fontSize: FONT.size.small,
  color: THEME.secondaryText()
});

/* istanbul ignore next */
export const TtvText = styled('div')({
  fontSize: FONT.size.small,
  color: THEME.secondaryText(),
  margin: '2px 0'
});

// following semantic HTML principals this should not be a anchor, probably a button.
/* istanbul ignore next */
export const TtvLink = styled('a')({
  fontSize: FONT.size.small,
  color: THEME.blue(),
  margin: '2px 0'
});

/* istanbul ignore next */
export const DividerCol = styled(Col)({
  paddingTop: '16px'
});

/* istanbul ignore next */
export const FullRowCol = styled(Col)({
  paddingTop: '14px',
  paddingLeft: '16px'
});

/* istanbul ignore next */
export const MarkingsBlankRow = styled(Col)({
  paddingTop: '2px',
  paddingLeft: '16px'
});

/* istanbul ignore next */
export const FullRowColImages = styled(Col)({
  paddingTop: '6px', // Needs to be less since images have 8px top padding
  paddingLeft: '16px'
});

/* istanbul ignore next */
export const LeftCol = styled(Col)({
  paddingLeft: '16px'
});

/* istanbul ignore next */
export const RightCol = styled(Col)({
  textAlign: 'right',
  paddingRight: '16px',
  marginTop: '-4px' // Lines up with Animal Name
});
