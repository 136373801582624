export const LOGIN_REQUEST = 'cricket/App/Login/LOGIN_REQUEST'; // Start the login process
export const LOGIN_STARTED = 'cricket/App/Login/LOGIN_STARTED';
export const LOGIN_SUCCESS = 'cricket/App/Login/LOGIN_SUCCESS';
export const LOGIN_INVALID = 'cricket/App/Login/LOGIN_INVALID'; // User login credentials were invalid
export const LOGIN_TOKEN_INVALID = 'cricket/App/Login/LOGIN_TOKEN_INVALID'; // Validation of local storage token failed during login

export const LOGIN_EXPIRED_TOKEN_REQUEST =
  'cricket/App/Login/LOGIN_EXPIRED_TOKEN_REQUEST'; // Start the process of handling an expired token found during API call
export const LOGIN_EXPIRED_TOKEN_STARTED =
  'cricket/App/Login/LOGIN_EXPIRED_TOKEN_STARTED';
export const LOGIN_EXPIRED_TOKEN_SUCCESS =
  'cricket/App/Login/LOGIN_EXPIRED_TOKEN_SUCCESS';

export const LOGIN_CONNECTION_ERROR =
  'cricket/App/Login/LOGIN_CONNECTION_ERROR'; // Something went wrong during login process.  IE: Offline, server down, etc.
export const LOGIN_ACCOUNT_DISABLED =
  'cricket/App/Login/LOGIN_ACCOUNT_DISABLED'; // Users account is disabled

export const LOGOUT_REQUEST = 'cricket/App/Login/LOGOUT_REQUEST';
export const LOGOUT_STARTED = 'cricket/App/Login/LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'cricket/App/Login/LOGOUT_SUCCESS';

export const LOGIN_VALIDATE_TOKEN_REQUEST =
  'cricket/App/Login/LOGIN_VALIDATE_TOKEN_REQUEST';
export const LOGIN_VALIDATE_TOKEN_STARTED =
  'cricket/App/Login/LOGIN_VALIDATE_TOKEN_STARTED';

export const LOGIN_FETCH_USER_LICENSE_AGREEMENT =
  'cricket/App/Login/LOGIN_FETCH_USER_LICENSE_AGREEMENT';
export const LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS =
  'cricket/App/Login/LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS';
export const LOGIN_ACCEPT_USER_LICENSE_AGREEMENT =
  'cricket/App/Login/LOGIN_ACCEPT_USER_LICENSE_AGREEMENT';

// Constants for tracking status of login
export const LOGIN_STATUS = {
  accountDisabled: 'accountDisabled',
  connectionError: 'connectionError',
  exceededMaxAttempts: 'exceededMaxAttempts',
  invalid: 'invalid', // User's login was invalid
  tokenExpired: 'tokenExpired', // Token in store was found to be expired (invalid) during an API call
  forgotPassword: 'forgotPassword' // Login was invalid 3+ times but less than maximum attempts
};
