import * as actions from './isolateReleaseActions';
import * as types from './isolateReleaseActionTypes';
import { IsolateReleaseModel as IsolateRelease } from 'modules/Isolate/isolateIndex';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { unionBy } from 'lodash';

export function* onRefreshReleasesForIsolate({ isolate }) {
  try {
    yield put(actions.isRefreshing(true));
    let params = {
      isolateNbr: isolate.isolateNbr,
      status: 'APPROVED'
    };
    const approverReleases = yield call(IsolateRelease.list, {
      ...params,
      'approvingClinic.gvl_id': isolate.clinicOwner.id
    });
    const recipientReleases = yield call(IsolateRelease.list, {
      ...params,
      'recipientClinic.gvl_id': isolate.clinicOwner.id
    });
    let mergedReleases = unionBy(
      approverReleases.results,
      recipientReleases.results,
      'isolateReleaseId'
    );

    yield put(
      actions.refreshReleasesForIsolateSuccess(
        isolate.isolateId,
        mergedReleases
      )
    );
  } catch (err) {
    yield put(apiFailure(err, "Error loading Isolate's releases"));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* isolateSagas() {
  yield takeLatest(
    types.REFRESH_RELEASES_FOR_ISOLATE,
    onRefreshReleasesForIsolate
  );
}
