import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { THEME } from 'style';

export default function FormField({
  fieldTemplate = <Input />,
  isRequired = false,
  label,
  form,
  fieldName,
  model,
  specialRules = {},
  noDecorators = false
}) {
  const getOptionalLabelTag = !noDecorators ? (
    <span>
      {label}{' '}
      <span style={{ color: THEME.tabText() }}>({intl.get('optional')})</span>
    </span>
  ) : (
    <span>{label}</span>
  );
  return (
    <Form.Item label={isRequired ? label : getOptionalLabelTag}>
      {getModelFieldDecorator(
        form,
        fieldName,
        model,
        isRequired,
        specialRules
      )(fieldTemplate)}
    </Form.Item>
  );
}

FormField.propTypes = {
  fieldTemplate: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  model: PropTypes.func.isRequired,
  specialRules: PropTypes.object,
  noDecorators: PropTypes.bool
};
