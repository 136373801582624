import React from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';
import { clinicPricebooks } from 'modules/Registration/Account/availablePricebooks';
import { DEPLOY_PLATFORM } from 'utils/config';

function ReviewInfoModalComponent({
  registrationData,
  accountRegistration,
  userRegistration,
  visible,
  onCloseModal,
  onConfirm
}) {
  return (
    <Modal
      title={intl.get('register.review.info')}
      visible={visible}
      closable={false}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onCloseModal}>
          {intl.get('cancel')}
        </Button>,
        <Button key="create" type="primary" onClick={onConfirm}>
          {intl.get('account.create.new')}
        </Button>
      ]}
    >
      <Row>
        <Col span={12}>
          <IsolateVal
            name="vet.details"
            val={
              <React.Fragment>
                {`${userRegistration.firstName} ${userRegistration.lastName}`}
                <br />
                {userRegistration.username}
              </React.Fragment>
            }
          />
        </Col>
        {DEPLOY_PLATFORM !== 'university' ? (
          <Col span={12}>
            <IsolateVal
              name="plan.details"
              val={
                <React.Fragment>
                  {intl.get('register.monthly.price', {
                    price:
                      accountRegistration.ratePlanCode ===
                      clinicPricebooks.noSubFee.ratePlanCode
                        ? registrationData.multiVetNoSubFee
                        : registrationData.singleVetSubFee
                  })}
                </React.Fragment>
              }
            />
          </Col>
        ) : null}
      </Row>
      <Row>
        <IsolateVal
          name="clinic.details"
          val={
            <React.Fragment>
              <Col span={12}>
                {accountRegistration.name}
                <br />
                {accountRegistration.billingAddress ? (
                  <React.Fragment>
                    <br />
                    <IsolateVal
                      name="address.physical"
                      val={
                        <LocationDetails
                          location={accountRegistration.locationAddress}
                        />
                      }
                    />
                    <IsolateVal
                      name="address.billing"
                      val={
                        <LocationDetails
                          location={accountRegistration.billingAddress}
                        />
                      }
                    />
                  </React.Fragment>
                ) : (
                  <LocationDetails
                    location={accountRegistration.locationAddress}
                  />
                )}
              </Col>
              <Col span={12}>
                <IsolateValWithIcon
                  icon="mail"
                  val={accountRegistration.email}
                />
                <IsolateValWithIcon
                  icon="phone"
                  val={accountRegistration.locationAddress?.phone}
                />
                <IsolateValWithIcon
                  icon="printer"
                  val={accountRegistration.fax}
                />
              </Col>
            </React.Fragment>
          }
        />
      </Row>
    </Modal>
  );
}

ReviewInfoModalComponent.propTypes = {
  registrationData: PropTypes.object.isRequired,
  accountRegistration: PropTypes.object,
  userRegistration: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const mapStateToProps = ({ registration }) => ({
  registrationData: registration.account.data
});

const ReviewInfoModal = connect(
  mapStateToProps,
  null
)(ReviewInfoModalComponent);

export { ReviewInfoModal as default, ReviewInfoModalComponent };

export function LocationDetails({ location = {} }) {
  return (
    <React.Fragment>
      {location.addressLine1}
      <br />
      {`${location.city}, ${location.state} ${location.postalCode}`}
      <br />
      {location.country}
    </React.Fragment>
  );
}

LocationDetails.propTypes = {
  location: PropTypes.object
};
