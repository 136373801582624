import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Button, Col, Divider, Form, Row, Select, Spin } from 'antd';
import { THEME } from 'style';
import { BorderedDiv, FadedText } from 'style/commonEmotions';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';
import GvlIcon from 'elements/GvlIcon';
import OrgContactSelect from 'components/OrgContactSelect';
import {
  NewportClinicShippingAddress,
  NewportClinicBillingAddress
} from 'modules/NewportClinic/newportClinicIndex';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

class NewportClinicInlineShowComponent extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    newportClinics: PropTypes.object.isRequired,
    clinic: PropTypes.object.isRequired,
    vetDisplay: PropTypes.oneOf(['select', 'full', 'basic', 'none']).isRequired,
    // For use on show pages
    vet: PropTypes.object,
    primaryEmail: PropTypes.string,
    // For use on edit pages
    showEdit: PropTypes.bool,
    showRemove: PropTypes.bool,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    form: PropTypes.object,
    contacts: PropTypes.array,
    allowMultipleSelections: PropTypes.bool,
    contactFormField: PropTypes.string,
    selectedVets: PropTypes.array,
    shouldRequireVets: PropTypes.bool,
    loadingVets: PropTypes.bool.isRequired,
    onVetChange: PropTypes.func
  };

  static defaultProps = {
    showEdit: false,
    showRemove: false
  };

  renderVetInfo = () => {
    const {
      allowMultipleSelections,
      contactFormField,
      contacts,
      form,
      loadingVets,
      primaryEmail,
      selectedVets,
      shouldRequireVets,
      vet,
      vetDisplay,
      onVetChange
    } = this.props;
    switch (vetDisplay) {
      case 'select':
        return (
          <Spin size="default" spinning={loadingVets}>
            {contacts?.length ? (
              <OrgContactSelect
                form={form}
                onChange={onVetChange}
                contactLabel={intl.get('isolate.vet.label')}
                contacts={contacts}
                contactFormField={contactFormField}
                allowMultipleSelections={allowMultipleSelections}
                selectedContacts={selectedVets}
                shouldRequireContacts={shouldRequireVets}
              />
            ) : (
              <React.Fragment>
                {/* Show empty select while loading vets for clinic */}
                {loadingVets ? (
                  <Form.Item
                    label={intl.get('isolate.vet.label')}
                    style={{ margin: '0px 12px 12px 12px' }}
                  >
                    <Select />
                  </Form.Item>
                ) : (
                  <div style={{ margin: '16px' }}>
                    <i style={{ color: THEME.tabText(), fontSize: '12px' }}>
                      {intl.get('noun.no.contacts', {
                        noun: intl.get('clinic').toLowerCase(),
                        contacts: intl.get('vets').toLowerCase()
                      })}
                    </i>
                  </div>
                )}
              </React.Fragment>
            )}
          </Spin>
        );
      case 'full':
        return (
          <React.Fragment>
            <IsolateVal name="email.primary" val={primaryEmail} />
            <Row gutter={8}>
              <Col span={10}>
                <IsolateVal name="naRequest.vet.label" val={vet?.commonName} />
              </Col>
              <Col span={4}>
                <IsolateVal
                  name="state"
                  val={vet?.licenses.map((license, index) => (
                    <p key={index} style={{ marginBottom: 0 }}>
                      {license.state}
                    </p>
                  ))}
                />
              </Col>
              <Col span={10}>
                <IsolateVal
                  name="distributor.license.number"
                  val={vet?.licenses.map((license, index) => (
                    <p key={index} style={{ marginBottom: 0 }}>
                      {license.licenseNbr}
                    </p>
                  ))}
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      case 'basic':
        return (
          <IsolateVal
            name="isolate.vet.label"
            val={
              vet.length
                ? vet.map(v => v?.commonName).join(',')
                : vet?.commonName
            }
          />
        );
      case 'none':
      default:
        return null;
    }
  };

  render() {
    const {
      currentUser,
      clinic,
      newportClinics,
      onEdit,
      onRemove,
      showEdit,
      showRemove
    } = this.props;
    const newportClinic = clinic.newport_id
      ? clinic
      : newportClinics[clinic.id];
    return (
      <BorderedDiv>
        <Row>
          <Col
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <h4 style={{ margin: '12px' }}>
              {hasFeatureAccess(
                currentUser,
                FeatureEnum.NewportClinicList,
                'read'
              ) && newportClinic?.gvl_id ? (
                <React.Fragment>
                  {clinic.name}{' '}
                  <FadedText>{newportClinic.newport_id}</FadedText>
                </React.Fragment>
              ) : (
                clinic.name
              )}
            </h4>
            <span style={{ float: 'right' }}>
              {showEdit ? (
                <Button
                  type="link"
                  style={{
                    border: 'none',
                    margin: 'auto',
                    padding: 0
                  }}
                  onClick={onEdit}
                >
                  {intl.get('edit')}
                </Button>
              ) : null}
              {showEdit && showRemove ? (
                <Divider
                  type="vertical"
                  style={{ marginLeft: 8, marginRight: 8 }}
                />
              ) : null}
              {showRemove ? (
                <Button
                  style={{
                    border: 'none',
                    margin: 'auto',
                    paddingLeft: 0
                  }}
                  onClick={onRemove}
                >
                  <GvlIcon type="close" style={{ color: THEME.red() }} />
                </Button>
              ) : null}
            </span>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <div style={{ margin: '0px 16px 24px' }}>
          {clinic?.gvl_id ? (
            <Row>
              <Col span={12}>
                <NewportClinicShippingAddress clinic={clinic} />
                {clinic.billingAddress.address_line_1 ? (
                  <NewportClinicBillingAddress clinic={clinic} />
                ) : null}
              </Col>
              <Col span={12}>
                <IsolateValWithIcon icon="mail" val={clinic.email} />
                <IsolateValWithIcon icon="phone" val={clinic.phone} />
                <IsolateValWithIcon icon="printer" val={clinic.fax} />
              </Col>
            </Row>
          ) : newportClinic?.gvl_id ? (
            <Row>
              <Col span={12}>
                <NewportClinicShippingAddress clinic={newportClinic} />
                {newportClinic.billingAddress.address_line_1 ? (
                  <NewportClinicBillingAddress clinic={newportClinic} />
                ) : null}
              </Col>
              <Col span={12}>
                <IsolateValWithIcon icon="mail" val={newportClinic.email} />
                <IsolateValWithIcon icon="phone" val={newportClinic.phone} />
                <IsolateValWithIcon icon="printer" val={newportClinic.fax} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={12}>
                <IsolateValWithIcon
                  icon="environment"
                  val={
                    <React.Fragment>
                      <div>{clinic.addressLine1}</div>
                      <div>
                        {`${clinic.city}, ${clinic.state} ${clinic.postalCode}`}
                      </div>
                    </React.Fragment>
                  }
                />
              </Col>
              <Col span={12}>
                <IsolateValWithIcon icon="mail" val={clinic.email} />
                <IsolateValWithIcon icon="phone" val={clinic.phone} />
                <IsolateValWithIcon icon="printer" val={clinic.fax} />
              </Col>
            </Row>
          )}
          {this.renderVetInfo()}
        </div>
      </BorderedDiv>
    );
  }
}

const mapStateToProps = state => {
  return {
    newportClinics: state.accounts.newportClinics.data,
    loadingVets: state.accounts.clinics.isRefreshing,
    currentUser: state.app.user
  };
};

const NewportClinicInlineShow = connect(
  mapStateToProps,
  null
)(NewportClinicInlineShowComponent);

export { NewportClinicInlineShow as default, NewportClinicInlineShowComponent };
