import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import * as api from 'utils/api';
import intl from 'react-intl-universal';
import { message } from 'antd';
import Account from 'models/Account';
import Subscription from 'models/Subscription';
import Clinic from 'models/relatedRecords/Clinic';
import Lab from 'models/relatedRecords/Lab';
import {
  adminLoadStartupDataSuccess,
  adminSetRetrievingStartupData,
  // Accounts
  adminRefreshAccountRequest,
  // Subscriptions
  adminRefreshArSubscriptionRequest,
  adminRefreshArSubscriptionSuccess,
  // Generic
  adminRefreshDomainRequest,
  adminSetRefreshDomain,
  adminDomainActionSuccess
} from 'containers/accounts/admin/store/accountsAdminActions';
import {
  ADMIN_LOAD_STARTUP_DATA_REQUEST,
  // Accounts
  ADMIN_ACTIVATE_ACCOUNT,
  ADMIN_REFRESH_ACCOUNT_REQUEST,
  ADMIN_SAVE_ACCOUNT_REQUEST,
  ADMIN_CREATE_AR_ACCOUNT_REQUEST,
  // Subscriptions
  ADMIN_SAVE_SUBSCRIPTION_REQUEST,
  ADMIN_SYNC_SUBSCRIPTION_REQUEST,
  ADMIN_ACTIVATE_AR_SUBSCRIPTION,
  ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST,
  // Generic
  ADMIN_ACTIVATE_DOMAIN,
  ADMIN_REFRESH_DOMAIN_REQUEST,
  ADMIN_SAVE_REQUEST
} from 'containers/accounts/admin/store/accountsAdminConstants';
import { connectionError } from 'containers/app/store/data/dataActions';
import { apiFailure } from 'containers/app/store/data/dataActions';

export function* onAdminLoadStartupDataRequest(action) {
  try {
    yield put(adminSetRetrievingStartupData(true));

    const [features, subscriptionLookups] = yield all([
      call(api.get, '/feature'),
      call(api.get, '/accountSubscription/lookup')
    ]);

    yield put(
      adminLoadStartupDataSuccess({
        features,
        subscriptionLookups
      })
    );
  } catch (err) {
    yield put(connectionError());
  } finally {
    yield put(adminSetRetrievingStartupData(false));
  }
}

// Accounts

export function* onAdminActivateAccount(action) {
  const { id } = action;
  let storeAccounts = yield select(
    state => state.accounts.admin.dataLists.account.data.accounts
  );
  if (!storeAccounts[id]) {
    yield put(adminRefreshAccountRequest(id));
  }
}

export function* onAdminRefreshAccountRequest(action) {
  const { id } = action;
  try {
    yield put(adminSetRefreshDomain('account', true));
    const account = yield call(Account.read, id);
    yield call(account.fetchAdditionalData);
    yield put(adminDomainActionSuccess('account', account));
  } catch (err) {
    yield put(apiFailure('Error loading Account data'));
  } finally {
    yield put(adminSetRefreshDomain('account', false));
  }
}

export function* onAdminSaveAccountRequest(action) {
  const { account, history } = action;
  try {
    yield call([account, account.save]); //Perform account.save()
    yield call(account.fetchAdditionalData); // TODO: we are only setting billingEmail, do we really nned to fetch everything again?
    yield put(adminDomainActionSuccess('account', account));
    if (history) {
      history.push(`/accounts/${account.id}/show`);
    }
    yield call(
      message.success,
      intl.get('savedWithNoun', {
        noun: intl.get('account')
      })
    );
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', { noun: intl.get('account') })
      )
    );
  }
}

export function* onAdminCreateArAccountRequest(action) {
  const { id } = action;
  try {
    yield put(adminSetRefreshDomain('accountsReceivable', true));
    const account = yield call(Account.createArAccount, id);
    yield call(account.fetchAdditionalData);
    yield call(message.success, intl.get('account.ar.created'));
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.creating.ar')));
  } finally {
    yield put(adminSetRefreshDomain('accountsReceivable', false));
  }
}

// Subscriptions

// TODO: revisit this at some point, feel like we could trim the
// number of api calls down
export function* onAdminSaveSubscriptionRequest(action) {
  const { subscription, relatedRecord, onSave } = action;
  try {
    yield put(adminSetRefreshDomain('subscription', true));
    if (relatedRecord) {
      yield call([relatedRecord, relatedRecord.save]); //Perform relatedRecord.save()
      yield put(
        adminDomainActionSuccess(
          subscription.sysFeatureSet.solutionClass.toLowerCase(),
          relatedRecord
        )
      );
    }
    yield call([subscription, subscription.save]); //Perform subscription.save()
    // TODO: do we need to call the account refresh? we should have the updated
    // subscription already from the call above.
    yield call(onAdminRefreshAccountRequest, {
      id: subscription.ownerAccountId
    });
    // If a subscription has just been disabled/enabled, need to refresh the clinic so it has the most accurate informaiton
    // NOTE: can we pull the current state and set the enabled value instead of the api call?
    if (relatedRecord && relatedRecord.enabled !== subscription.enabled) {
      yield put(
        adminRefreshDomainRequest(
          subscription.sysFeatureSet.solutionClass.toLowerCase(),
          relatedRecord.id
        )
      );
    }
    onSave(subscription);
    yield call(
      message.success,
      intl.get('savedWithNoun', {
        noun: intl.get('subscription')
      })
    );
  } catch (err) {
    console.log(err);
    // Handle the error
    yield put(
      apiFailure(
        err,
        intl.get('error.saving.noun', {
          noun: intl.get('subscription')
        })
      )
    );
  } finally {
    yield put(adminSetRefreshDomain('subscription', false));
  }
}

export function* onAdminSyncSubscriptionRequest(action) {
  const { subscription, data, afterSync } = action;
  try {
    yield put(adminSetRefreshDomain('subscription', true));
    yield call(Subscription.sync, subscription.id, data);
    //yield put(adminRefreshDomainRequest('arSubscription', subscription.id));
    yield call(onAdminRefreshDomainRequest, {
      domain: 'arSubscription',
      id: subscription.id
    });
    // TODO: do we need to fetch the account again?
    // the arProperties would be the only changes, use a helper method??
    //yield put(adminRefreshAccountRequest(subscription.ownerAccountId));
    yield call(onAdminRefreshAccountRequest, {
      id: subscription.ownerAccountId
    });
    yield call(message.success, intl.get('subscription.ar.updated'));
    afterSync();
  } catch (err) {
    // Handle the error
    yield put(apiFailure(err, intl.get('error.updating.ar')));
  } finally {
    yield put(adminSetRefreshDomain('subscription', false));
  }
}

export function* onAdminActivateArSubscription(action) {
  const { id, arSubscriptionId } = action;
  let storeArSubscriptions = yield select(
    state => state.accounts.admin.dataLists.arSubscription.data.arSubscriptions
  );
  if (
    arSubscriptionId &&
    !storeArSubscriptions[arSubscriptionId.replace(/-/g, '')]
  ) {
    yield put(adminRefreshArSubscriptionRequest(id));
  }
}

export function* onAdminRefreshArSubscriptionRequest(action) {
  const { id } = action;
  try {
    yield put(adminSetRefreshDomain('arSubscription', true));
    const arSubscription = yield call(Subscription.getArSubscription, id);
    yield put(adminRefreshArSubscriptionSuccess(arSubscription));
  } catch (err) {
    // Handle the error
    yield put(apiFailure(err, 'Error loading Zuora subscription data'));
    // NOTE: insert an error object so that the show pages can indicate that
    // the zuora subscription data was not loaded and prevent all the reloading attempts
    let storeAccounts = yield select(
      state => state.accounts.admin.dataLists.account.data.accounts
    );
    // TODO: this gets hairy :o( will need to revist at some point, perhaps
    // have the subscriptions stored in redux outside the account object
    for (let accountId in storeAccounts) {
      for (let sub of storeAccounts[accountId].subscriptions) {
        if (sub.id !== id) continue;
        yield put(
          adminRefreshArSubscriptionSuccess({
            success: false,
            ratePlans: [
              { isProduct: true, id: sub.arSubscriptionId.replace(/-/g, '') }
            ]
          })
        );
      }
    }
  } finally {
    yield put(adminSetRefreshDomain('arSubscription', false));
  }
}

// Generic

export function* onAdminActivateDomain(action) {
  const { domain, id } = action;
  let storeModels = yield select(
    state => state.accounts.admin.dataLists[domain].data[domain + 's']
  );
  if (id && !storeModels[id]) {
    yield put(adminRefreshDomainRequest(domain, id));
  }
}

export function* onAdminRefreshDomainRequest(action) {
  const { domain, id } = action;
  try {
    yield put(adminSetRefreshDomain(domain, true));

    // Read the model based on the domain
    let model;
    if (domain === 'clinic') {
      model = yield call(Clinic.read, id);
    } else if (domain === 'lab') {
      model = yield call(Lab.read, id);
    } else {
      // TODO: thow an error if invalid domain?
      // Right now this catches distributors and pharmas since they don't have records
      yield put(adminSetRefreshDomain(domain, false));
      return;
    }

    yield put(adminDomainActionSuccess(domain, model));
  } catch (err) {
    // Handle the error(s)
    yield put(apiFailure('Error loading ' + domain + ' data'));
  } finally {
    yield put(adminSetRefreshDomain(domain, false));
  }
}

export function* onAdminSaveRequest(action) {
  const { domain, model } = action;
  try {
    yield call([model, model.save]); //Perform model.save()
    yield put(adminDomainActionSuccess(domain, model));
  } catch (err) {
    // Handle the error(s)
    yield put(
      apiFailure(err, intl.get('error.saving.noun', { noun: intl.get(domain) }))
    );
  }
}

export default function* accountsAdminSagas() {
  yield takeLatest(
    ADMIN_LOAD_STARTUP_DATA_REQUEST,
    onAdminLoadStartupDataRequest
  );
  // Account
  yield takeLatest(ADMIN_ACTIVATE_ACCOUNT, onAdminActivateAccount);
  yield takeLatest(ADMIN_REFRESH_ACCOUNT_REQUEST, onAdminRefreshAccountRequest);
  yield takeLatest(ADMIN_SAVE_ACCOUNT_REQUEST, onAdminSaveAccountRequest);
  yield takeLatest(
    ADMIN_CREATE_AR_ACCOUNT_REQUEST,
    onAdminCreateArAccountRequest
  );
  // Subscription
  yield takeLatest(
    ADMIN_SAVE_SUBSCRIPTION_REQUEST,
    onAdminSaveSubscriptionRequest
  );
  yield takeLatest(
    ADMIN_SYNC_SUBSCRIPTION_REQUEST,
    onAdminSyncSubscriptionRequest
  );
  yield takeLatest(
    ADMIN_ACTIVATE_AR_SUBSCRIPTION,
    onAdminActivateArSubscription
  );
  yield takeLatest(
    ADMIN_REFRESH_AR_SUBSCRIPTION_REQUEST,
    onAdminRefreshArSubscriptionRequest
  );
  // Generic
  yield takeLatest(ADMIN_ACTIVATE_DOMAIN, onAdminActivateDomain);
  yield takeLatest(ADMIN_REFRESH_DOMAIN_REQUEST, onAdminRefreshDomainRequest);
  yield takeLatest(ADMIN_SAVE_REQUEST, onAdminSaveRequest);
}
