import Cvi from 'models/documents/Cvi';
import Eia from 'models/documents/Eia';
import Ihc from 'models/documents/Ihc';
import Vfd from 'models/documents/Vfd';
import Rabies from 'models/documents/Rabies';
import Script from 'models/documents/Script';
import FeedMill from 'models/FeedMill';
import moment from 'moment';
import { cloneDeep } from 'lodash';

// Logic in this file derived from Neptr commonBootstrap\app\shared\documentHelper.coffee

export const getDocumentModelForCertificateType = certificateType => {
  switch (certificateType) {
    case 'OCVI':
    case 'Ecvi': // TODO: Not sure if this is needed, just putting it here since it was in the Neptr code
    case 'CVI':
      return Cvi;
    case 'Coggins':
    case 'Eeia': // TODO: Not sure if this is needed, just putting it here since it was in the Neptr code
    case 'EIA':
      return Eia;
    case 'VFD':
      return Vfd;
    case 'Script':
      return Script;
    case 'Rabies':
      return Rabies;
    case 'IHC':
      return Ihc;
    default:
      // Certificate type is not supported for "Create from Certificate"
      return null;
  }
};

export const copyContactFromOriginal = (
  originalDocument,
  draft,
  contactType
) => {
  draft[contactType] = {
    id: originalDocument[contactType]['id']
  };

  const premisesKey = `${contactType}Premises`;
  draft[premisesKey] = {
    id: originalDocument[premisesKey]['id']
  };
};

export const createEiaDraft = originalDocument => {
  const draft = {
    type: 'EIA',
    animal: {
      id: originalDocument.animal.id
    }
  };

  copyContactFromOriginal(originalDocument, draft, 'origin');
  copyContactFromOriginal(originalDocument, draft, 'owner');

  return draft;
};

export const createCviDraft = originalDocument => {
  const draft = {
    type: 'CVI',
    animals: originalDocument.animals,
    species: originalDocument.species,
    speciesType: originalDocument.speciesType,
    carrierType: originalDocument.carrierType,
    purposeOfMovement: originalDocument.purposeOfMovement
  };

  copyContactFromOriginal(originalDocument, draft, 'consignee');
  copyContactFromOriginal(originalDocument, draft, 'consignor');
  copyContactFromOriginal(originalDocument, draft, 'origin');
  copyContactFromOriginal(originalDocument, draft, 'destination');
  copyContactFromOriginal(originalDocument, draft, 'carrier');

  if (draft.animals) {
    draft.animals.forEach(animal => {
      animal.tests = [];
      animal.vaccinations = [];
      animal.treatments = [];
    });
  }

  return draft;
};

export const createRabiesDraft = originalDocument => {
  const draft = cloneDeep(originalDocument);
  delete draft.id;
  delete draft.version;
  delete draft.serialNumber;
  delete draft.effectiveDate;
  delete draft.expirationDate;
  delete draft.signingDate;
  delete draft.signingTimestamp;
  delete draft.lastUpdated;
  delete draft.metadata;
  delete draft.vaccination.id;
  return draft;
};

export const createVfdDraft = async originalDocument => {
  const draft = {
    type: 'VFD',
    headCount: originalDocument.headCount,
    species: originalDocument.species,
    minorSpecies: originalDocument.minorSpecies,
    drug_id: originalDocument.drug_id,
    additionalEmail: originalDocument.additionalEmail,
    groupName: originalDocument.groupName,
    feedSites: originalDocument.feedSites
  };

  copyContactFromOriginal(originalDocument, draft, 'owner');

  // Give draft the same number of "valid for" days.
  // This should be fairly accurate unless the drug's effectiveDaysMax has changed.
  const validForDays = moment(originalDocument.expirationDate).diff(
    moment(originalDocument.effectiveDate),
    'days'
  );
  draft.expirationDate = moment()
    .startOf('day')
    .add(validForDays, 'days');

  // If needed, get the latest feed mill info
  if (originalDocument.feedMill && originalDocument.feedMill.id) {
    // feedMill can be an empty object {}
    try {
      const feedMill = await FeedMill.read(originalDocument.feedMill.id);
      if (feedMill.enabled) {
        draft.feedMill = feedMill;
        draft.feedMillEmail = originalDocument.feedMillEmail;
      }
    } catch {
      // TODO: Neptr didn't handle failed read. Should we handle it here?  If so, how?
    }
  }

  return draft;
};

export const createScriptDraft = originalDocument => {
  const draft = {
    type: 'Script',
    headCount: originalDocument.headCount,
    species: originalDocument.species,
    distributor: {
      id: originalDocument.distributor.id
    },
    distributorEmail: originalDocument.distributorEmail,
    additionalEmail: originalDocument.additionalEmail,
    productionClass: originalDocument.productionClass,
    groupName: originalDocument.groupName,
    feedSites: originalDocument.feedSites
  };

  copyContactFromOriginal(originalDocument, draft, 'owner');

  return draft;
};

export const createIhcDraft = originalDocument => {
  const draft = cloneDeep(originalDocument);
  delete draft.id;
  delete draft.rabiesDoc;
  delete draft.cviDoc;
  delete draft.vet;
  delete draft.signingDate;
  delete draft.signingTimestamp;
  delete draft.lastUpdated;
  delete draft.metadata;
  delete draft.version;
  delete draft.expirationDate;
  delete draft.animal.vacs;
  return draft;
};

export const createDraftFromDocument = async originalDocument => {
  switch (originalDocument.type) {
    case 'EIA':
      return createEiaDraft(originalDocument);
    case 'CVI':
      return createCviDraft(originalDocument);
    case 'VFD':
      return await createVfdDraft(originalDocument);
    case 'Script':
      return createScriptDraft(originalDocument);
    case 'Rabies':
      return createRabiesDraft(originalDocument);
    case 'IHC':
      return createIhcDraft(originalDocument);
    default:
      throw Error(`Unsupported document type: ${originalDocument.type}`);
  }
};
