// Holds info relevant to user Login process
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';

import {
  LOGIN_STARTED,
  LOGIN_INVALID,
  LOGIN_ACCOUNT_DISABLED,
  LOGIN_SUCCESS,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGIN_CONNECTION_ERROR,
  LOGIN_VALIDATE_TOKEN_STARTED,
  LOGIN_TOKEN_INVALID,
  LOGIN_STATUS,
  LOGIN_EXPIRED_TOKEN_STARTED,
  LOGIN_EXPIRED_TOKEN_SUCCESS,
  LOGIN_FETCH_USER_LICENSE_AGREEMENT,
  LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS
} from 'containers/app/store/login/loginConstants';

export const initialState = Immutable.from({
  loginStatus: null, // Will be one of LOGIN_STATUS to indicate status of login attempt
  validatingLogin: false, // Call is being made to backend login validation
  validatingToken: false, // Call is being made to backend token validation
  loginAttempts: 0, // Will be incremented for each login started
  tokenValidated: false, // Set to true after successful login, or if the token from local storage was validated after page refresh
  loggingOut: false, // Set to true when logout is started.
  handlingExpiredToken: false, // Set to true when processing an expired token that was found during an API request
  fetchingUserLicenseAgreementInfo: false,
  userLicenseAgreementInfo: {}
});

export const onLoginStarted = state =>
  initialState.merge({
    validatingLogin: true,
    loginAttempts: state.loginAttempts + 1
  });

export const onLoginAccountDisabled = () =>
  initialState.merge({
    loginStatus: LOGIN_STATUS.accountDisabled
  });

export const onLoginInvalid = state => {
  let loginStatus = LOGIN_STATUS.invalid;
  let loginAttempts = state.loginAttempts;

  if (state.loginAttempts >= 3) {
    loginStatus = LOGIN_STATUS.forgotPassword;
  }

  if (state.loginAttempts > 5) {
    loginStatus = LOGIN_STATUS.exceededMaxAttempts;
    loginAttempts = 0; // Reset for next login
  }

  return initialState.merge({
    loginStatus,
    loginAttempts
  });
};

// The login process found that the token in local storage was invalid.
// So just revert to initial state so it's as if the user needs to login fresh
export const onLoginTokenInvalid = () => initialState;

export const onLoginExpiredTokenStarted = state =>
  state.merge({
    handlingExpiredToken: true,
    tokenValidated: false
  });

// In-store token was found to be expired(invalid).
export const onLoginExpiredTokenSuccess = state =>
  state.merge({
    loginStatus: LOGIN_STATUS.tokenExpired,
    handlingExpiredToken: false
  });

export const onLoginSuccess = state =>
  state.merge({
    tokenValidated: true,
    loginStatus: null,
    validatingLogin: false,
    validatingToken: false,
    loginAttempts: 0,
    loggingOut: false
  });

export const onLoginConnectionError = state =>
  state.merge({
    loginStatus: LOGIN_STATUS.connectionError,
    validatingLogin: false,
    validatingToken: false,
    loginAttempts: 0,
    tokenValidated: false,
    loggingOut: false
  });

export const onLogoutStarted = state =>
  state.merge({
    loggingOut: true,
    tokenValidated: false,
    loginStatus: null,
    validatingLogin: false,
    validatingToken: false,
    loginAttempts: 0
  });

export const onLogoutSuccess = () => initialState;

export const onLoginValidateTokenStarted = state =>
  state.merge({
    validatingToken: true,
    loginStatus: null,
    validatingLogin: false,
    loggingOut: false,
    tokenValidated: false
  });

export const onLoginFetchUserLicenseAgreement = state =>
  Immutable.set(state, 'fetchingUserLicenseAgreementInfo', true);

export const onLoginFetchUserLicenseAgreementSuccess = (state, payload) =>
  Immutable.merge(state, {
    fetchingUserLicenseAgreementInfo: false,
    userLicenseAgreementInfo: {
      content: payload.content,
      versionNumber: payload.versionNumber,
      id: payload.id
    }
  });

const loginReducer = createReducer(initialState, {
  [LOGIN_STARTED]: onLoginStarted,
  [LOGIN_SUCCESS]: onLoginSuccess,
  [LOGIN_INVALID]: onLoginInvalid,
  [LOGIN_TOKEN_INVALID]: onLoginTokenInvalid,
  [LOGIN_CONNECTION_ERROR]: onLoginConnectionError,
  [LOGIN_ACCOUNT_DISABLED]: onLoginAccountDisabled,
  [LOGOUT_STARTED]: onLogoutStarted,
  [LOGOUT_SUCCESS]: onLogoutSuccess,
  [LOGIN_VALIDATE_TOKEN_STARTED]: onLoginValidateTokenStarted,
  [LOGIN_EXPIRED_TOKEN_STARTED]: onLoginExpiredTokenStarted,
  [LOGIN_EXPIRED_TOKEN_SUCCESS]: onLoginExpiredTokenSuccess,
  [LOGIN_FETCH_USER_LICENSE_AGREEMENT]: onLoginFetchUserLicenseAgreement,
  [LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS]: onLoginFetchUserLicenseAgreementSuccess
});
export default loginReducer;
