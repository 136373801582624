import {
  FeaturesByRole,
  // FeaturesByAccount,
  FeaturesBySubscriptionType
} from 'FeatureAccess/CurrentFeatureList';

export const hasFeatureAccess = (user, feature, accessLevel) => {
  if (!user || !feature || !accessLevel) return false;

  const accountType = user.info.account.type; // used to check the "subscription" level permissions
  // const accountName = user.info.account.name; // used to check the "account" level permissions
  // const accountId = user.info.account.id; // used to check the "account" level permissions

  if (!checkSubscriptionAccess(accountType, feature, accessLevel)) return false;

  /**
   * Access to the content currently managed by this service is not restricted from any clinic or group of clinics.
   * Any other type of subscription should have its account checked against the list maintained in the .js const (i.e. Newport)
   * This IF statement must be refactored when an API exposes the `FeaturesByAccount` value
   */
  // if (!['Regulatory Authority', 'Clinic'].includes(accountType)) {
  //   if (!checkAccountAccess(accountId, feature, accessLevel)) return false;
  // }

  // the list of a user's current roles is maintained in redux as an object.
  // converting that to an array allows us to .some() its contents looking for truthy values
  const rolesArray = Object.values(user.roles);
  try {
    return rolesArray.some(role => {
      // if a user has 'full' access, we may immediately return true for that role, regardless of accessLevel arg's value.
      if (FeaturesByRole[role] && FeaturesByRole[role][feature]) {
        return (
          FeaturesByRole[role][feature].includes('full') ||
          FeaturesByRole[role][feature].includes(accessLevel)
        );
      } else {
        return false;
      }
    });
  } catch (err) {
    console.warn(err);
    return false;
  }
};

/**
 * This method introduces "subscription" and "accountType" somewhat interchangeably in its name/arguments.
 * The value is a subscription type in the DB, but currently accessed via user.info.account.type in Redux.
 * It seems cleanest to consistently refer to it as an accountType when accessed using that notation.
 */
export const checkSubscriptionAccess = (accountType, feature, accessLevel) => {
  if (!accountType || !FeaturesBySubscriptionType?.[accountType]?.[feature]) {
    return false;
  }
  return (
    FeaturesBySubscriptionType[accountType][feature].includes(accessLevel) ||
    FeaturesBySubscriptionType[accountType][feature].includes('full')
  );
};

// export const checkAccountAccess = (account, feature, accessLevel) => {
//   if (!account || !FeaturesByAccount?.[account]?.[feature]) {
//     return false;
//   }
//   return (
//     FeaturesByAccount[account][feature].includes(accessLevel) ||
//     FeaturesByAccount[account][feature].includes('full')
//   );
// };

export const FeatureEnum = {
  //Newport Clinics
  NewportClinicList: 'nptCncLst',
  //Isolates
  IsolateExtensionSign: 'ismExtSgn',
  IsolateExtensionApprove: 'ismExtApr',
  IsolateExtension: 'ismExt',
  IsolateHistory: 'ismHst',
  IsolateLabInterface: 'ismLab',
  IsolateListTags: 'ismTag',
  IsolateManagement: 'ism',
  IsolateRelease: 'ismRls',
  IsolateReleaseApprove: 'ismRlsApr',
  IsolateCsvExport: 'ismCsv',
  IsolateDocsAndNotes: 'ismDoc',
  //Products
  ProductLabInterface: 'prmLab',
  ProductManagement: 'prmMgt',
  //Nonadjacents
  NonAdjacentManagement: 'nam',
  NonAdjacentLabInterface: 'namLab',
  NonAdjacentClinicInterface: 'namCnc',
  NonAdjacentRegulatoryInterface: 'namReg',
  NonAdjacentSubmitToClinic: 'namStc',
  NonAdjacentSignRequest: 'namSgn',
  NonAdjacentRegulatoryApprove: 'namRegApr',
  NonAdjacentDocsAndNotes: 'namDoc',
  NonAdjacentHerdList: 'namHrd',
  NonAdjacentLabTags: 'namTag',
  NonAdjacentLabNote: 'namNB'
};
