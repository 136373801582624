export const REFRESH_LAB_TEST_INBOX_SUCCESS =
  'cricket/Accounts/Lab/REFRESH_LAB_TEST_INBOX_SUCCESS';
export const REFRESH_LAB_TEST_LIST_REQUEST =
  'cricket/Accounts/Lab/REFRESH_LAB_TEST_LIST_REQUEST';
export const REFRESH_LAB_TEST_LIST_SUCCESS =
  'cricket/Accounts/Lab/REFRESH_LAB_TEST_LIST_SUCCESS';
export const REFRESH_LAB_TEST_LIST_FAILURE =
  'cricket/Accounts/Lab/REFRESH_LAB_TEST_LIST_FAILURE';
export const REFRESH_LAB_TEST_REQUEST =
  'cricket/Accounts/Lab/REFRESH_LAB_TEST_REQUEST';
export const SIGN_LAB_TESTS = 'cricket/Accounts/Lab/SIGN_LAB_TESTS';
export const LAB_CLEAR_TEST_ERRORS =
  'cricket/Accounts/Lab/LAB_CLEAR_TEST_ERRORS';
export const RESULT_LAB_TESTS = 'cricket/Accounts/Lab/RESULT_LAB_TESTS';
export const SEND_BACK_LAB_TEST = 'cricket/Accounts/Lab/SEND_BACK_LAB_TEST';
export const REMOVE_LAB_TEST_FROM_LIST =
  'cricket/Accounts/Lab/REMOVE_LAB_TEST_FROM_LIST';
export const SEARCH_LAB_TESTS = 'cricket/Accounts/Lab/SEARCH_LAB_TESTS';
export const SEARCH_LAB_TESTS_SUCCESS =
  'cricket/Accounts/Lab/SEARCH_LAB_TESTS_SUCCESS';
export const SEARCH_LAB_CERTIFICATES =
  'cricket/Accounts/Lab/SEARCH_LAB_CERTIFICATES';
export const SEARCH_LAB_CERTIFICATES_SUCCESS =
  'cricket/Accounts/Lab/SEARCH_LAB_CERTIFICATES_SUCCESS';
export const SIGN_LAB_TEST = 'cricket/Accounts/Lab/SIGN_LAB_TEST';
export const NVSL_RESULT_LAB_TEST = 'cricket/Accounts/Lab/NVSL_RESULT_LAB_TEST';
export const CLEAR_LAB_CERTIFICATE_SEARCH =
  'cricket/Accounts/Lab/CLEAR_LAB_CERTIFICATE_SEARCH';
export const CLEAR_LAB_TEST_SEARCH =
  'cricket/Accounts/Lab/CLEAR_LAB_TEST_SEARCH';
export const LAB_SEND_TO_NVSL_REQUEST =
  'cricket/Accounts/Lab/LAB_SEND_TO_NVSL_REQUEST';
export const LAB_SEND_TO_NVSL_FINISHED =
  'cricket/Accounts/Lab/LAB_SEND_TO_NVSL_FINISHED';
export const LAB_ATTACH_NVSL_REPORT_REQUEST =
  'cricket/Accounts/Lab/LAB_ATTACH_NVSL_REPORT_REQUEST';
export const LAB_ATTACH_NVSL_REPORT_FINISHED =
  'cricket/Accounts/Lab/LAB_ATTACH_NVSL_REPORT_FINISHED';
export const LAB_REMOVE_NVSL_REPORT_REQUEST =
  'cricket/Accounts/Lab/LAB_REMOVE_NVSL_REPORT_REQUEST';
export const LAB_REMOVE_NVSL_REPORT_FINISHED =
  'cricket/Accounts/Lab/LAB_REMOVE_NVSL_REPORT_FINISHED';
