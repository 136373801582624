import { put, call, takeLatest } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import { sortBy } from 'lodash';
import { apiFailure } from 'containers/app/store/data/dataActions';
import { AccountRegistration } from 'modules/Registration/Account/accountRegistrationIndex';
import * as actions from 'modules/Registration/Account/store/accountRegistrationActions';
import * as types from 'modules/Registration/Account/store/accountRegistrationActionTypes';
import { setVerificationStatus } from 'modules/Registration/User/store/userRegistrationActions';

export function* onLoadRegistrationData() {
  try {
    yield put(actions.actionInProgress('isRefreshing', true));
    const pricebooks = yield call(AccountRegistration.pricebooks);
    // Format the data for the plan details table (allows the table to be dynamic should prices change)
    const sortedCharges = sortBy(pricebooks.charges, [
      'noSubFeePrice',
      'singleVetPrice',
      'type'
    ]);
    let tableData = [];
    for (let i = 0; i < sortedCharges.length; i++) {
      const currentCharge = sortedCharges[i];
      let name = intl.get('docType.' + currentCharge.type);
      for (let j = i + 1; j <= sortedCharges.length; j++) {
        if (j === sortedCharges.length) {
          i = j;
          break;
        }
        const anotherCharge = sortedCharges[j];
        if (
          currentCharge.noSubFeePrice === anotherCharge.noSubFeePrice &&
          currentCharge.singleVetPrice === anotherCharge.singleVetPrice
        ) {
          name += ', ' + intl.get('docType.' + anotherCharge.type);
        } else {
          i = j - 1;
          break;
        }
      }
      tableData.push({
        name,
        type: currentCharge.type,
        noSubFeePrice: currentCharge.noSubFeePrice,
        singleVetPrice: currentCharge.singleVetPrice
      });
    }
    tableData = sortBy(tableData, ['name']);
    yield put(actions.loadRegistrationDataSuccess(pricebooks, tableData));
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.loading.pricingInfo')));
  } finally {
    yield put(actions.actionInProgress('isRefreshing', false));
  }
}

export function* onSubmitRegistration({
  accountRegistration,
  userRegistration,
  callback
}) {
  try {
    yield put(actions.actionInProgress('isCreatingAccount', true));
    const response = yield call(
      [accountRegistration, accountRegistration.submit],
      userRegistration
    );
    if (callback) {
      console.log('>>>> ' + response);
      callback(response);
    }
  } catch (err) {
    yield put(
      apiFailure(
        err,
        intl.get('error.creating.noun', { noun: intl.get('account.lc') })
      )
    );
  } finally {
    yield put(actions.actionInProgress('isCreatingAccount', false));
  }
}

export function* onCompleteRegistration({ params, history }) {
  try {
    yield put(actions.actionInProgress('isAddingPayment', true));
    yield call(AccountRegistration.complete, params);
    yield put(setVerificationStatus('COMPLETED'));
    history.push('/login');
  } catch (err) {
    yield put(apiFailure(err, intl.get('error.adding.payment')));
  } finally {
    yield put(actions.actionInProgress('isAddingPayment', false));
  }
}

export function* onResumeRegistration({ params, callback }) {
  try {
    yield put(actions.actionInProgress('isRefreshing', true));
    const result = yield call(AccountRegistration.resume, params);
    callback(result);
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress('isRefreshing', false));
  }
}

export function* onValidateClinicEmail({ params, callback }) {
  try {
    yield call(AccountRegistration.validateClinicEmail, params);
    callback({ fieldName: 'email' });
  } catch (err) {
    if (err.isAxiosError) {
      const status = err.response.status;
      const error = err.response.data?.errors?.[0].message;
      if (
        status === 400 &&
        error === 'There is an outstanding registration for this email.'
      ) {
        callback({
          fieldName: 'email',
          isDuplicate: true,
          hasExistingRegistration: true
        });
      } else if (status === 400 && error === 'This email is already in use.') {
        callback({
          fieldName: 'email',
          isDuplicate: true,
          hasExistingRegistration: false
        });
      }
    } else {
      console.error(err);
    }
  }
}

export function* onSendInternalAnalyticsEvent({ params, callback }) {
  try {
    yield call(AccountRegistration.sendInternalAnalyticsEvent, params);
    callback();
  } catch (err) {
    console.error(err);
  }
}

export default function* accountRegistrationSagas() {
  yield takeLatest(types.LOAD_REGISTRATION_DATA, onLoadRegistrationData);
  yield takeLatest(types.SUBMIT_REGISTRATION, onSubmitRegistration);
  yield takeLatest(types.COMPLETE_REGISTRATION, onCompleteRegistration);
  yield takeLatest(types.IS_CLINIC_EMAIL_DUPLICATE, onValidateClinicEmail);
  yield takeLatest(types.RESUME_REGISTRATION, onResumeRegistration);
  yield takeLatest(
    types.SEND_INTERNAL_ANALYTICS_EVENT,
    onSendInternalAnalyticsEvent
  );
}
