import React from 'react';
import PropTypes from 'prop-types';
import HeaderButton from './HeaderButton';
import HeaderDropdownMenu from './HeaderDropdownMenu';

const generateButtons = (actions, viewMode = 'Desktop', loadingDoc) => {
  return actions.map(action => {
    return (
      <HeaderButton
        action={action}
        key={action.id}
        viewMode={viewMode}
        disabled={loadingDoc || action.disabled}
      />
    );
  });
};

export default function HeaderActions({
  viewMode,
  actions,
  menuActions,
  predecessorComponents,
  loadingDoc,
  ...rest
}) {
  let primary = actions.filter(action => action.type === 'primary');
  let secondary = actions.filter(action => action.type === 'default');
  let menu = menuActions;
  let predecessor = predecessorComponents;

  if (viewMode === 'Mobile') {
    menu = primary.filter(action => !action.showOnMobile);
    menu = menu.concat(secondary.filter(action => !action.showOnMobile));
    menu = menu.concat(menuActions);
    primary = primary.filter(action => action.showOnMobile);
    secondary = secondary.filter(action => action.showOnMobile);

    // Move predecessor to dropdown menu
    menu = menu.concat(predecessorComponents);
    // empty predecessor component
    predecessor = null;
  }
  return (
    <div className="header-actions" {...rest}>
      {predecessor}
      {generateButtons(secondary, viewMode, loadingDoc)}
      {generateButtons(primary, viewMode, loadingDoc)}
      {menu && menu.length > 0 ? (
        <HeaderDropdownMenu
          viewMode={viewMode}
          menuActions={menu}
          disabled={loadingDoc}
        />
      ) : null}
    </div>
  );
}

HeaderActions.propTypes = {
  viewMode: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  menuActions: PropTypes.array.isRequired,
  loadingDoc: PropTypes.bool,
  predecessorComponents: PropTypes.array
};

HeaderActions.defaultProps = {
  viewMode: 'Desktop',
  actions: [],
  menuActions: [],
  predecessorComponents: []
};
