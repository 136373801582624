import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Form, Input, Select } from 'antd';

function SignatureFormItem({ form }) {
  const SelectOption = style => (
    <Select.Option value={style.fontFamily} style={style}>
      {form.getFieldValue('signatureText') || intl.get('name.placeholder')}
    </Select.Option>
  );

  return (
    <React.Fragment>
      <Form.Item label={intl.get('signature')}>
        {form.getFieldDecorator('signatureText', {
          rules: [{ required: true, validator: SignatureValidator }],
          preserve: false
        })(<Input placeholder={intl.get('name.placeholder')} />)}
      </Form.Item>
      <Form.Item>
        {form.getFieldDecorator('signatureFont', {
          initialValue: 'Dancing Script',
          preserve: false
        })(
          <Select
            style={{
              width: '100%',
              fontFamily: form.getFieldValue('signatureFont')
            }}
          >
            {SelectOption({ fontFamily: 'Dancing Script', fontSize: '22px' })}
            {SelectOption({ fontFamily: 'Satisfy', fontSize: '22px' })}
            {SelectOption({
              fontFamily: 'Dawning of a New Day',
              fontSize: '22px'
            })}
            {SelectOption({ fontFamily: 'Mr Dafoe', fontSize: '22px' })}
            {/* Homemade Apple is a large font which is why it's 16px when the others are 22px so all option are relatively the same in the dropdown */}
            {SelectOption({ fontFamily: 'Homemade Apple', fontSize: '16px' })}
          </Select>
        )}
      </Form.Item>
    </React.Fragment>
  );
}

SignatureFormItem.propTypes = {
  form: PropTypes.object.isRequired
};

const SignatureValidator = (_rule, _value, cb) => {
  if (!_value) {
    cb(intl.get('required'));
  } else if (_value.length > 40) {
    cb(intl.get('signature.length.validator'));
  } else {
    cb();
  }
};

export { SignatureFormItem, SignatureValidator };
