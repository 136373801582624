// Distributor Constant

export const DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_LOAD_STARTUP_DATA_REQUEST';
export const DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_LOAD_STARTUP_DATA_STARTED';
export const DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_LOAD_STARTUP_DATA_SUCCESS';
export const DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_LOAD_STARTUP_DATA_FINISHED';

export const DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_VFD_LIST_REQUEST';
export const DISTRIBUTOR_REFRESH_VFD_LIST_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_VFD_LIST_STARTED';
export const DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_VFD_LIST_SUCCESS';
export const DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_VFD_LIST_FAILURE';
export const DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_VFD_SAVE_LOG_SHIPMENT_REQUEST';
export const DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_VFD_SAVE_OVERAGE_REASON_REQUEST';

export const DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_VFD_TRANSFER_LOCATION_REQUEST';
export const DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS =
  'cricket/Accounts/Distributor/  DISTRIBUTOR_VFD_TRANSFER_LOCATION_SUCCESS';
export const DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_VFD_TRANSFER_LOCATION_FAILURE';
export const DISTRIBUTOR_VFD_LIST_READY =
  'cricket/Accounts/Distributor/DISTRIBUTOR_VFD_LIST_READY';
export const DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATE_REQUEST';
export const DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATE_STARTED';
export const DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATE_SUCCESS';
export const DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATE_FAILURE';
export const DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATE_CLEAR';

export const DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_CLAIM_CERTIFICATE_REQUEST';
export const DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_CLAIM_CERTIFICATE_STARTED';
export const DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_CLAIM_CERTIFICATE_SUCCESS';
export const DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_CLAIM_CERTIFICATE_FAILURE';
export const DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_CLAIM_CERTIFICATE_ENDED';

export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_REQUEST';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_STARTED';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_SUCCESS';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_LIST_FAILURE';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_LOG_SHIPMENT_REQUEST';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_SAVE_OVERAGE_REASON_REQUEST';

export const DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_CERTIFICATE_REQUEST';
export const DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_CERTIFICATE_STARTED';
export const DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_CERTIFICATE_SUCCESS';
export const DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_CERTIFICATE_FAILURE';
export const DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_CERTIFICATE_ENDED';

export const DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATES_REQUEST';
export const DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATES_STARTED';
export const DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATES_SUCCESS';
export const DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SEARCH_CERTIFICATES_FAILURE';

export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_REQUEST';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_STARTED';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_SUCCESS';
export const DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADED_DOCUMENT_SEARCH_LIST_FAILURE';

export const DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_DEACTIVATE_UPLOADED_DOCUMENT_REQUEST';

export const DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_DEACTIVATE_UPLOAD_REQUEST';
export const DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_UPLOADS_REQUEST_STARTED';
export const DISTRIBUTOR_UPLOAD_REQUEST_UPDATED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_REQUEST_UPDATED';
export const DISTRIBUTOR_UPLOADS_LIST_READY =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADS_LIST_READY';
export const DISTRIBUTOR_DELETE_UPLOAD_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_DELETE_UPLOAD_REQUEST';
export const DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOAD_REMOVED_FROM_LIST';

export const DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_DELETE_UPLOADED_DOCUMENT_REQUEST';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_REMOVED_FROM_LIST';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_UPDATED';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY =
  'cricket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_LIST_READY';

export const DISTRIBUTOR_UPLOADED_DOCUMENT_DELETE_REQUEST =
  'crecket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_DELETE_REQUEST';
export const DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED =
  'crecket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENT_DELETED';
export const DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY =
  'crecket/Accounts/Distributor/DISTRIBUTOR_UPLOADED_DOCUMENTS_LIST_READY';

//requests
export const DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_REQUESTS_LIST_REQUEST';
export const DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_REQUESTS_LIST_STARTED';
export const DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_REQUESTS_LIST_SUCCESS';
export const DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_REQUESTS_LIST_FAILURE';

// request show
export const DISTRIBUTOR_REFRESH_REQUEST_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_FEEDMILL_SHOW_REQUEST_REQUEST';
export const DISTRIBUTOR_REFRESH_REQUEST_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_FEEDMILL_SHOW_REQUEST_SUCCESS';
export const DISTRIBUTOR_REFRESH_REQUEST_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_FEEDMILL_SHOW_REQUEST_FAILURE';
// VFD Request
export const DISTRIBUTOR_REQUEST_VFD_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REQUEST_VFD_SUCCESS';
export const DISTRIBUTOR_REQUEST_VFD_FAILURE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REQUEST_VFD_FAILURE';
export const DISTRIBUTOR_REQUEST_VFD_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REQUEST_VFD_REQUEST';

// CERTIFICATE SHOW
export const DISTRIBUTOR_REFRESH_CERTIFICATE =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_CERTIFICATE';

export const DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SAVE_LOG_SHIPMENT_REQUEST';
export const DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS =
  'cricket/Accounts/Distributor/DISTRIBUTOR_REFRESH_CERTIFICATE_SUCCESS';
export const DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_SAVE_OVERAGE_REASON_REQUEST';
export const DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_EDIT_LOG_SHIPMENT_REQUEST';
export const DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST =
  'cricket/Accounts/Distributor/DISTRIBUTOR_EDIT_OVERAGE_REASON_REQUEST';
