import * as analytics from 'utils/analytics/analytics';
import Model from 'models/Model';

/* This model being specific to Google rather than analytics in general is intentional.
 * Discussed making it generic and decided it was preferable to have this model
 * wrap interactions with Google to avoid over-complicating the logic.
 */
export default class GoogleDataPoint extends Model {
  static get fields() {
    return {
      category: {
        initialValue: ''
      },
      action: {
        initialValue: ''
      },
      label: {
        initialValue: ''
      },
      sendTiming: {
        initialValue: false
      },
      initializedTime: {
        initialValue: new Date()
      },
      sent: {
        initialValue: false
      }
    };
  }

  send = () => {
    if (this.sent) return;

    analytics.trackEvent(
      this.category,
      this.action,
      this.label,
      {},
      analytics.serviceEnum.GOOGLE
    );

    if (this.sendTiming) {
      const timingValue = new Date().getTime() - this.initializedTime.getTime();
      analytics.trackTiming(
        this.category,
        this.action,
        this.label,
        timingValue
      );
    }

    this.sent = true;
  };
}
