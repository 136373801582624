import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip } from 'antd';
import GvlIcon from 'elements/GvlIcon';

export default function HeaderButton({
  viewMode,
  action,
  isDropdown,
  disabled,
  ...rest
}) {
  // This goofy thing.
  // GvlPopover needs to not set onClick so that popover menu can recieve click events.
  let onClickProp = {};
  if (action.onClick) {
    onClickProp = { onClick: action.onClick };
  }

  let buttonProper;
  let buttonClassName = 'header-button';

  if (viewMode === 'Mobile') {
    buttonClassName = `${buttonClassName} mobile`;
    if (action.icon || action.showTextOnMobile) {
      if (action.showTextOnMobile) {
        // DEBT: These should each be a component of their own and moved to
        // a switch based on viewMode & if it should always show text.
        buttonProper = (
          <Button
            {...rest}
            type="link"
            size="small"
            key={action.id}
            {...onClickProp}
            disabled={disabled}
            className={buttonClassName}
          >
            {action.icon ? <GvlIcon type={action.icon} /> : null}
            {action.title}
          </Button>
        );
      } else {
        let mobileIconProps = {
          ...rest,
          theme: 'outlined',
          type: action.icon,
          key: action.id,
          className: buttonClassName
        };
        if (disabled) {
          mobileIconProps.className = `${mobileIconProps.className} disabled`;
        } else {
          mobileIconProps = { ...mobileIconProps, ...onClickProp };
        }
        buttonProper = <GvlIcon {...mobileIconProps} />;
      }
    } else {
      console.warn(
        'Cannot render buttons in mobile without icons. set showOnMobile to false'
      );
      buttonProper = null;
    }
  } else {
    buttonProper = (
      <Button
        {...rest}
        type={action.type}
        key={action.id}
        {...onClickProp}
        size="small"
        disabled={disabled}
        className={buttonClassName}
      >
        {action.icon ? <GvlIcon type={action.icon} /> : null}
        {action.title}
        {isDropdown ? <Icon type="down" /> : ''}
      </Button>
    );
  }

  return (
    <Tooltip
      title={disabled ? action.disabledTooltipTitle : undefined}
      placement="bottom"
    >
      {buttonProper}
    </Tooltip>
  );
}

HeaderButton.propTypes = {
  viewMode: PropTypes.string.isRequired,
  action: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired, // index or unique ID in HTML
    title: PropTypes.string, // name string to display
    icon: PropTypes.string, // string w/ icon ID
    type: PropTypes.string, // type of button
    onClick: PropTypes.func, // callback passed in from component
    showOnMobile: PropTypes.bool, // show on mobile (Doesn't work if no icon is provided)
    showTextOnMobile: PropTypes.bool, // show on mobile using text if no icon is provided.
    // disabled: PropTypes.bool, // Used in predecessor component to specify button state
    disabledTooltipTitle: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  isDropdown: PropTypes.bool.isRequired
};

HeaderButton.defaultProps = {
  viewMode: 'Desktop',
  action: {},
  isDropdown: false,
  disabled: false
};
