import { createSelector } from 'reselect';
import * as features from 'utils/features';
import * as userRoles from 'utils/userRoles';

export const selectUser = state => state.app.user;
export const getUserInfo = state => selectUser(state).info || {};
export const getUserCountry = state => getUserInfo(state).userCountry;
export const getUserId = state => getUserInfo(state).appUserId;
export const getUserNetworkedLab = state => getUserInfo(state).networkedLab;
export const getCurrentUserAccountId = state => getUserInfo(state).account.id;
export const getNotifications = state => selectUser(state).notifications.data;
export const getUserIncludeImage = state => getUserInfo(state).includeImage;
export const getEnabledFeatures = state =>
  getUserInfo(state).enabledFeatures || [];
export const getUserRoles = state => getUserInfo(state).userRole;
export const hasAccountManagerRole = state =>
  selectUser(state).roles.includes(userRoles.ROLE_ACCOUNT_MANAGER);

export const isNvslFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures => enabledFeatures.includes(features.FEATURE_NVSL)
);

export const isRabiesFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures => enabledFeatures.includes(features.FEATURE_RABIES)
);

export const isIhcFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures => enabledFeatures.includes(features.FEATURE_INTERNATIONAL)
);

// We need this check in place to keep the feature flag disabled for all users who are not in a dev environment,
// independent of what is returned from calls to the FeatureAccessService.
export const isVaccineFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures => enabledFeatures.includes(features.FEATURE_VACCINE)
);

export const isLicenseValidationFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures =>
    enabledFeatures.includes(features.FEATURE_LICENSE_VALIDATION)
);

export const doesUserHaveRoleFactory = role =>
  createSelector(getUserRoles, userRoles => userRoles.includes(role));

export const doesUserHaveNVSLRole = doesUserHaveRoleFactory(
  userRoles.ROLE_NVSL_LAB_TECH
);

export const isAgentEnhancementFeatureEnabled = createSelector(
  getEnabledFeatures,
  enabledFeatures =>
    enabledFeatures.includes(features.FEATURE_AGENTCVIENHANCEMENTS)
);

export const isBillingPageEnabled = createSelector(
  getEnabledFeatures,
  hasAccountManagerRole,
  (enabledFeatures, accountManager) =>
    accountManager === true &&
    enabledFeatures.includes(features.FEATURE_BILLINGPAGE)
);
