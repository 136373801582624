import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  StyledOptionsHeader,
  StyledOptionsSubText
} from 'components/GvlSearch/commonSearchEmotions';

export default function NoResult({ name, headerMsgId, subTextMsgId }) {
  // TODO: The styling used for these messages doesn't match what Ian wanted for the Image Gallrey search "Empty" results
  //       Refactor this component to use improved styling, and then   Refactor GvlEmpty to use it and refactor
  //       other places that use NoResult inside Empty to use GvlEmpty

  return (
    <React.Fragment>
      <StyledOptionsHeader>
        {intl.get(headerMsgId, { name: name })}
      </StyledOptionsHeader>
      {subTextMsgId ? (
        <StyledOptionsSubText disabled>
          {intl.get(subTextMsgId)}
        </StyledOptionsSubText>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

NoResult.propTypes = {
  name: PropTypes.string,
  headerMsgId: PropTypes.string.isRequired,
  subTextMsgId: PropTypes.string
};
