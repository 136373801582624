import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, notification } from 'antd';
import {
  sendToClinic as sendRequestToClinic,
  returnToLab as returnRequestToLab
} from 'modules/NonAdjacentHerdRequest/store/nonAdjacentHerdRequestActions';
import NonAdjacentModel from 'modules/NonAdjacentHerdRequest/NonAdjacentHerdRequest';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import NoteModel from 'modules/Note/Note';

class NonAdjacentSendBackModalComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    sendRequestToClinic: PropTypes.func.isRequired,
    returnRequestToLab: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    actionCallback: PropTypes.func.isRequired,
    naRequest: PropTypes.object.isRequired
  };

  sendNaRequestBack = () => {
    this.props.form.setFieldsValue({ validationLevel: 'sendBack' });
    this.props.form.validateFields({ force: true }, async (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        return;
      }

      const naRequest = new NonAdjacentModel({
        ...this.props.naRequest,
        ...formValues
      });

      const note = new NoteModel({
        note: formValues.requestFeedback,
        category: 'Send Back Reason',
        longReferenceId: this.props.naRequest.id
      });
      await note.save();

      const modelValidationErrors = naRequest.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('naRequest.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      this.dispatchSendBackAction(naRequest);
    });
  };

  dispatchSendBackAction = naRequest => {
    if (naRequest.status === 'LAB_REVIEW') {
      this.props.sendRequestToClinic(naRequest, this.props.actionCallback);
    } else if (naRequest.status === 'REG_REVIEW') {
      this.props.returnRequestToLab(naRequest, this.props.actionCallback);
    }
  };

  render() {
    const { visible, form, closeModal } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        destroyOnClose={true}
        title={intl.get('extension.sendBack.withComments')}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            {intl.get('cancel')}
          </Button>,
          <Button key="save" type="primary" onClick={this.sendNaRequestBack}>
            {intl.get('extension.sendBack')}
          </Button>
        ]}
      >
        <div>{intl.get('extension.sendBack.body')}</div>
        <br />
        <div style={{ display: 'none' }}>
          {getModelFieldDecorator(
            form,
            'validationLevel',
            NonAdjacentModel,
            false,
            { preserve: false }
          )(<Input />)}
        </div>
        <Form.Item label={intl.get('comments')}>
          {getModelFieldDecorator(
            form,
            'requestFeedback',
            NonAdjacentModel,
            true,
            { preserve: false }
          )(<Input.TextArea rows={4} />)}
        </Form.Item>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  sendRequestToClinic,
  returnRequestToLab
};

const NonAdjacentSendBackModal = connect(
  null,
  mapDispatchToProps
)(NonAdjacentSendBackModalComponent);

export {
  NonAdjacentSendBackModal as default,
  NonAdjacentSendBackModalComponent
};
