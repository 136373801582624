import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Col, Form, Input, Row } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { FadedText } from 'style/commonEmotions';
import OtherableSelect from 'elements/OtherableSelect';
import StateSelect from 'elements/StateSelect';
import { generateSelectOptions } from 'utils/appUtil';
import { LocationFormFields } from 'modules/Location/locationIndex';
import { Herd } from 'modules/Herd/herdIndex';

function HerdFormFieldsComponent({ form, herd = new Herd(), speciesList }) {
  return (
    <React.Fragment>
      <Form.Item label={intl.get('producer.name')}>
        {getModelFieldDecorator(form, 'producerName', Herd, true)(<Input />)}
      </Form.Item>
      <h4> {intl.get('herd')} </h4>
      <LocationFormFields form={form} location={herd} isLocation={false} />
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label={intl.get('Species')}>
            {getModelFieldDecorator(
              form,
              'species',
              Herd,
              true
            )(
              <OtherableSelect showAction={['focus', 'click']}>
                {generateSelectOptions(speciesList)}
              </OtherableSelect>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <span>
                {intl.get('herd.size')}
                <FadedText> ({intl.get('optional')}) </FadedText>
              </span>
            }
          >
            {getModelFieldDecorator(form, 'headCount', Herd, false)(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={intl.get('herd.state')}>
        {getModelFieldDecorator(form, 'herdState', Herd, true)(<StateSelect />)}
      </Form.Item>
      <Form.Item label={intl.get('comments')}>
        {getModelFieldDecorator(form, 'comments', Herd, false)(<Input />)}
      </Form.Item>
    </React.Fragment>
  );
}

HerdFormFieldsComponent.propTypes = {
  form: PropTypes.object.isRequired,
  herd: PropTypes.object.isRequired,
  speciesList: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    speciesList: state.accounts.common.startupData.lookupLists.speciesList
  };
};

const HerdFormFields = connect(mapStateToProps, null)(HerdFormFieldsComponent);

export { HerdFormFields as default, HerdFormFieldsComponent };
