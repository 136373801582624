import Model from 'models/Model';
import { Contact } from 'modules/Contact/contactIndex';
import {
  arrayRequired,
  objectRequiredWithId,
  stringRequired,
  maxLength
} from 'utils/validation/common';
import * as api from 'utils/api';
import moment from 'moment';

export default class Isolate extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      isolateId: {
        initialValue: undefined
      },
      isolateNbr: {
        initialValue: '',
        validate: isolateNbr => stringRequired(isolateNbr, 'required')
      },
      name: {
        initialValue: '',
        validate: name => stringRequired(name, 'required')
      },
      genusSpecies: {
        initialValue: '',
        validate: genusSpecies => stringRequired(genusSpecies, 'required')
      },
      subtype: {
        initialValue: undefined
      },
      requireTyping: {
        initialValue: false
      },
      isolationDate: {
        initialValue: undefined,
        validate: isolationDate => stringRequired(isolationDate, 'required')
      },
      harvestDate: {
        initialValue: undefined
      },
      expirationDate: {
        initialValue: undefined
      },
      extensionDate: {
        initialValue: undefined
      },
      isExtended: {
        initialValue: false
      },
      isExtensionElectronic: {
        initialValue: false
      },
      initialShipDate: {
        initialValue: undefined
      },
      // material transfer agreement received date
      mtaReceivedDate: {
        initialValue: undefined
      },
      status: {
        initialValue: undefined
      },
      diagnosticLab: {
        initialValue: undefined,
        validate: val => objectRequiredWithId(val)
      },
      labTechnician: {
        initialValue: undefined
      },
      accessionNbr: {
        initialValue: undefined
      },
      tissue: {
        initialValue: '',
        validate: tissue => stringRequired(tissue, 'required')
      },
      locationBox: {
        initialValue: undefined
      },
      locationSlot: {
        initialValue: undefined
      },
      producer: {
        initialValue: undefined,
        validate: val => (val.contactId ? null : new Contact(val).validate())
      },
      headCount: {
        initialValue: undefined
      },
      species: {
        initialValue: undefined,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      herdLocation: {
        initialValue: undefined
      },
      clinicOwner: {
        initialValue: undefined,
        validate: val => objectRequiredWithId(val)
      },
      additionalClinicEmail: {
        initialValue: undefined
      },
      vets: {
        initialValue: [],
        validate: val => arrayRequired('vets', val)
      },
      isolateOwner: {
        initialValue: true
      },
      isolateShared: {
        initialValue: false
      },
      extensionRequest: {
        initialValue: undefined
      },
      releaseRequest: {
        initialValue: undefined
      },
      products: {
        initialValue: []
      },
      notes: {
        initialValue: undefined
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      dateArchived: {
        initialValue: undefined
      },
      dateDeleted: {
        initialValue: undefined
      },
      version: {
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/isolate';
  }

  static get domain() {
    return 'Isolate';
  }

  static get idFieldName() {
    return 'isolateId';
  }

  static calculateStatus = isolate => {
    // Copied from the server to reduce API chatter
    /**
     * Workflow to determine an isolate's status is as follows:
     * 1. Are we creating a new isolate? i.e. is this a new isolateId in the DB? If so -> NOT_ELIGIBLE
     * 2. Is the isolate's expiration date in the past? If so -> EXPIRED (NOTE: this is prioritized even with outstanding extension reviews!)
     * 3. Does the isolate have an existing extension date? i.e. was it a shared isolate whose initial clinic extended it? If so -> NOT_ELIGIBLE_FOR_EXTENSION (EXTENDED)
     * 4. Does the isolate meet the basic business rules for extension? (has products, has been harvested, product has shipped, < 60 days from expiring, has a subtype if necessary)? If not -> NOT_ELIGIBLE
     * 5. Does the isolate have an outstanding extension request? If so -> EXTENSION_REVIEW
     * 6. All isolates that have fallen through these questions can be assumed to be ELIGIBLE_FOR_EXTENSION
     * @param isolate Isolate object whose status needs to be set
     */
    // Isolates must be within 60 days of expiring to be eligible for extension:
    let expiresInMoreThanSixtyDays = moment(isolate.expirationDate).isAfter(
      moment().add(61, 'days')
    );

    // Isolates must have shipped more than 30 days ago to be eligible for extension:
    let shippedLessThanThirtyDays = moment(isolate.initialShipDate).isAfter(
      moment().subtract(30, 'days')
    );

    let isolateStatus = '';

    if (!isolate.isolateId) {
      isolateStatus = 'NOT_ELIGIBLE_FOR_EXTENSION';
    } else if (moment(isolate.expirationDate).isBefore(moment())) {
      isolateStatus = 'EXPIRED';
    } else if (isolate.extensionDate) {
      isolateStatus = 'NOT_ELIGIBLE_FOR_EXTENSION';
    } else if (
      !isolate.products ||
      isolate.products.length === 0 ||
      !isolate.harvestDate ||
      !isolate.initialShipDate ||
      expiresInMoreThanSixtyDays ||
      shippedLessThanThirtyDays ||
      (isolate.requireTyping && !isolate.subtype)
    ) {
      /*
        Isolates can only be extended if:
        1. The isolate was grown and harvested, packaged into a product, and shipped,
        2. The isolate will expire in two months or less.
          ie - can't extend until they're about to expire, and can't extend after expired.
        3. Shipped within the past month - Isolate must have shipped product, but the initial ship date must not be so recent that we probably don't have good data on vaccine efficacy.
        4. Certain isolate genus/species need to have extra description (subtype) before they can be extended.
          List provided by client. See lookup-lists.json, isolateGenusMap, requireTyping fields.
      */
      isolateStatus = 'NOT_ELIGIBLE_FOR_EXTENSION';
    } else if (
      ['REQUESTED', 'CHANGES_REQUESTED'].includes(
        isolate.extensionRequest?.status
      )
    ) {
      isolateStatus = 'EXTENSION_REVIEW';
    } else {
      isolateStatus = 'ELIGIBLE_FOR_EXTENSION';
    }
    return isolateStatus;
  };

  static list = async params => await super.list(params);

  static read = async isolateId => await super.read(isolateId);

  static delete = async isolateId => await super.deleteRec(isolateId);

  static archive = async isolateId =>
    await api.put(`${Isolate.apiPath}/${isolateId}/archive`);

  static auditReport = async (isolateId, params) =>
    await super.auditReport(isolateId, params);

  static products = async isolateId =>
    await api.get(`${Isolate.apiPath}/${isolateId}/products`);

  static linkProduct = async (isolateId, productId) =>
    await api.post(`${Isolate.apiPath}/${isolateId}/product/${productId}/link`);

  static removeProduct = async (isolateId, productId) =>
    await api.deleteRec(
      `${Isolate.apiPath}/${isolateId}/product/${productId}/link`
    );

  static getMatchingAccessionIsolates = async (isolateNbr, isolateId = '') =>
    api.get(`${Isolate.apiPath}/getMatchingAccessionIsolates`, {
      params: {
        isolateNbr,
        isolateId: isolateId || undefined
      }
    });

  static getSharedIsolates = async (isolateNbr, isolateId = '') =>
    api.get(`${Isolate.apiPath}/getSharedIsolates`, {
      params: {
        isolateNbr,
        isolateId: isolateId || undefined
      }
    });
}
