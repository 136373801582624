import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './newportClinicActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  dataRetrieved: false,
  refreshFailure: false
});

export const onIsRefreshing = (state, { isRefreshing }) =>
  Immutable.setIn(state, ['isRefreshing'], isRefreshing);

export const onListSuccess = (state, { clinicList }) => {
  let newState = state.asMutable({ deep: true });
  for (const clinic of clinicList) {
    newState.data[clinic.gvl_id] = clinic;
  }
  newState.dataRetrieved = true;
  newState.refreshFailure = false;

  return Immutable.from(newState);
};

export const onListFailure = state =>
  state.merge({
    dataRetrieved: false,
    refreshFailure: true
  });

export const newportClinicReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsRefreshing,
  [types.LIST_SUCCESS]: onListSuccess,
  [types.LIST_FAILURE]: onListFailure
});
