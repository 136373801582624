import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from 'modules/License/store/licenseActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false
});

// general methods
export const onLicenseRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

// list methods
export const onLicenseListSuccess = (state, payload) => {
  let licenses = {};
  Object.values(payload.licenses).forEach(license => {
    licenses[license.id] = license;
  });
  return state.merge({
    data: licenses,
    lastSuccessfulRefreshedTime: new Date().getTime(),
    refreshFailure: false
  });
};

export const onRefreshLicenseSuccess = (state, payload) =>
  Immutable.setIn(state, ['data', payload.license.id], payload.license);

export const onLicenseListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onDeleteLicenseSuccess = (state, payload) => {
  let newState = onLicenseRefreshing(state, false);
  return newState.updateIn(['data'], data => data.without(payload.licenseId));
};

// exported reducer
export const licenseReducer = createReducer(initialState, {
  [types.LIST_SUCCESS]: onLicenseListSuccess,
  [types.LIST_FAILURE]: onLicenseListFailure,
  [types.IS_REFRESHING]: onLicenseRefreshing,
  [types.REFRESH_SUCCESS]: onRefreshLicenseSuccess,
  [types.DELETE_SUCCESS]: onDeleteLicenseSuccess
});
