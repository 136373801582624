import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Col, Form, Icon, Input, Row, Table } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { AccountRegistration as AccountRegistrationModel } from 'modules/Registration/Account/accountRegistrationIndex';
import { clinicPricebooks } from 'modules/Registration/Account/availablePricebooks';
import { THEME, FONT } from 'style';

function PlanSelectTable({ form }) {
  const registrationData = useSelector(
    state => state.registration.account.data
  );
  const tableData = useSelector(state => state.registration.account.tableData);

  const columns = [
    {
      dataIndex: 'name',
      render: (_text, record, _index) =>
        record.type === 'ihc' ? renderTravelPassName() : record.name
    },
    {
      dataIndex: 'noSubFeePrice',
      render: (_text, record, _index) =>
        record.type === 'ihc'
          ? renderTravelPassPricing('noSubFeePrice')
          : renderCostColum(record.noSubFeePrice)
    },
    {
      dataIndex: 'singleVetPrice',
      render: (_text, record, _index) =>
        record.type === 'ihc'
          ? renderTravelPassPricing('singleVetPrice')
          : renderCostColum(record.singleVetPrice)
    }
  ];

  const renderCostColum = cost =>
    cost === 0 ? (
      intl.get('free.capitalized')
    ) : (
      <span>
        ${cost.toFixed(2)}
        <span style={{ color: THEME.tabText() }}>
          /{intl.get('certificate.lowerCase')}
        </span>
      </span>
    );

  const renderTravelPassName = () => (
    <span>
      <p>{intl.get('docType.ihc')}</p>
      <div
        style={{
          color: THEME.tabText(),
          fontSize: FONT.size.small,
          marginLeft: '16px'
        }}
      >
        <p>{intl.get('travelPass.international')}</p>
        <p>{intl.get('travelPass.domestic')}</p>
      </div>
    </span>
  );

  const renderTravelPassPricing = planName => (
    <div style={{ marginTop: '20px' }}>
      {renderCostColum(tableData.find(doc => doc.type === 'ihc')[planName])}
      <div style={{ marginBottom: '10px' }} />
      {renderCostColum(tableData.find(doc => doc.type === 'cvi')[planName])}
    </div>
  );

  return (
    <div style={{ overflow: 'auto' }}>
      {/* The headers and select options aren't part of the table. To get it to look decent on mobile I mad the whole thing scroll and maintain the same size as on desktop. */}
      <div style={{ width: '630px' }}>
        <Row type="flex" align="bottom" style={{ paddingBottom: '8px' }}>
          <Col
            span={8}
            style={{
              color: THEME.tabText(),
              paddingLeft: '16px'
            }}
          >
            {intl.get('document.costs')}
          </Col>
          <PlanPriceVal
            subscriptionType={intl.get('register.payAsYouGo')}
            price={registrationData.multiVetNoSubFee}
          />
          <PlanPriceVal
            subscriptionType={intl.get('register.monthlySubscription')}
            price={registrationData.singleVetSubFee}
          />
        </Row>
        <Table
          columns={columns}
          dataSource={tableData}
          bordered
          pagination={false}
          showHeader={false}
          tableLayout="fixed"
          rowKey="type"
        />
        <Form.Item style={{ paddingTop: '8px' }}>
          {getModelFieldDecorator(
            form,
            'ratePlanCode',
            AccountRegistrationModel,
            true
          )(
            <Row>
              <Col span={8} offset={8}>
                <RatePlanButton
                  form={form}
                  ratePlanCode={clinicPricebooks.noSubFee.ratePlanCode}
                  sysFeatureSetName={
                    clinicPricebooks.noSubFee.sysFeatureSetName
                  }
                />
              </Col>
              <Col span={8}>
                <RatePlanButton
                  form={form}
                  ratePlanCode={clinicPricebooks.withSubFee.ratePlanCode}
                  sysFeatureSetName={
                    clinicPricebooks.withSubFee.sysFeatureSetName
                  }
                />
              </Col>
            </Row>
          )}
        </Form.Item>
        <div style={{ display: 'none' }}>
          {/* Managed along with ratePlanCode above */}
          {getModelFieldDecorator(
            form,
            'sysFeatureSetName',
            AccountRegistrationModel,
            true
          )(<Input />)}
        </div>
      </div>
    </div>
  );
}

PlanSelectTable.propTypes = {
  form: PropTypes.object.isRequired,
  registrationData: PropTypes.object,
  tableData: PropTypes.object
};

export { PlanSelectTable as default };

function PlanPriceVal({ subscriptionType, price }) {
  return (
    <Col span={8} style={{ color: THEME.tabText(), textAlign: 'center' }}>
      {subscriptionType}
      <span style={{ color: THEME.purple() }}>
        <br />
        <span style={{ fontSize: FONT.size.xlarge }}>${price?.toFixed(2)}</span>
        <br />
        {'/' + intl.get('month.lc')}
        <br />
      </span>
    </Col>
  );
}

PlanPriceVal.propTypes = {
  subscriptionType: PropTypes.string.isRequired,
  price: PropTypes.number
};

function RatePlanButton({ form, ratePlanCode, sysFeatureSetName }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {form.getFieldValue('ratePlanCode') === ratePlanCode ? (
        <Button type="default" style={{ color: THEME.blue() }}>
          <Icon type="check" />
          {intl.get('register.currentPlan')}
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() =>
            form.setFieldsValue({ ratePlanCode, sysFeatureSetName })
          }
        >
          {intl.get('register.selectPlan')}
        </Button>
      )}
    </div>
  );
}

RatePlanButton.propTypes = {
  form: PropTypes.object.isRequired,
  ratePlanCode: PropTypes.string.isRequired,
  sysFeatureSetName: PropTypes.string.isRequired
};
