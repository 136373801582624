import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Col, Row, Icon } from 'antd';
import { THEME } from 'style';
import styled from '@emotion/styled';
import { FadedText, BoldSpan } from 'style/commonEmotions';
import IsolateExpirationVal from 'elements/AutogenousVaccines/IsolateExpirationVal';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';
import { addMonths } from 'utils/newportUtil';
import moment from 'moment';

const BorderedDiv = styled.div({
  width: '100%',
  border: THEME.mainBorderColor(),
  borderStyle: 'solid',
  borderWidth: '1px',
  margin: 'auto'
});

class IsolateExpirationComponent extends Component {
  static propTypes = {
    harvestDate: PropTypes.object,
    expirationDate: PropTypes.object,
    isolationDate: PropTypes.object,
    status: PropTypes.string,
    onEditView: PropTypes.bool,
    currentUser: PropTypes.object.isRequired
  };

  static defaultProps = {
    onEditView: false
  };

  render() {
    const {
      harvestDate,
      expirationDate,
      isolationDate,
      status,
      currentUser
    } = this.props;

    const twelveMonthsFromHarvest = harvestDate
      ? addMonths(harvestDate, 12).format('MM/DD/YY')
      : '-';

    const fifteenMonthsFromIsolation = addMonths(isolationDate, 15)?.format(
      'MM/DD/YY'
    );

    const twentyFourMonthsFromIsolation = addMonths(isolationDate, 24)?.format(
      'MM/DD/YY'
    );

    const expiredIsolateView = (
      <Col span={12}>
        <BoldSpan>{intl.get('isolate.expired.on')}</BoldSpan>
        <h2 style={{ margin: 0 }}>
          {expirationDate ? moment(expirationDate).format('MM/DD/YY') : '-'}
        </h2>
      </Col>
    );

    const activeIsolateView = (
      <IsolateExpirationVal expirationDate={expirationDate} />
    );

    // Is this actually newport-specific, or is this business logic the same industry-wide (9-CFR)?
    // This logic is Newport specific, and handles their business rules for managing isolate expiration.
    // The dates are hardwired & linked to their specific needs.
    const datesDetailView = (
      <React.Fragment>
        <Row type="flex" gutter={8}>
          <Col>
            <BoldSpan>{twelveMonthsFromHarvest}</BoldSpan>
          </Col>
          <Col>
            <FadedText>
              {`12 ${intl.get('isolate.months.from.first.harvest')}`}
            </FadedText>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col>
            <BoldSpan>{fifteenMonthsFromIsolation}</BoldSpan>
          </Col>
          <Col>
            <FadedText>
              {`15 ${intl.get('isolate.months.from.isolation')}`}
            </FadedText>
          </Col>
        </Row>
        <Row type="flex" gutter={8}>
          <Col>
            <BoldSpan>{twentyFourMonthsFromIsolation}</BoldSpan>
          </Col>
          <Col>
            <FadedText>
              {`24 ${intl.get('isolate.months.from.isolation')}`}
            </FadedText>
          </Col>
        </Row>
      </React.Fragment>
    );

    /**
     * returns a Row with text in a grid/table like this:
     * `isolate expires in`               Date   12 mo from harvest
     * `HUGE COUNTDOWN`                   Date   15 mo from isolation
     * `on M/DD/YYYY`                     Date   24 mo from isolation
     */
    return (
      <BorderedDiv>
        <h4 style={{ margin: '12px' }}>{intl.get('isolate.expiration')}</h4>
        <hr
          style={{
            borderWidth: '0.1px',
            color: THEME.mainBorderColor(),
            marginLeft: '6px',
            marginRight: '6px'
          }}
        />
        {expirationDate || isolationDate ? (
          <Row style={{ color: THEME.isolateBlack(), margin: '12px' }}>
            <Col span={12}>
              {status === 'EXPIRED' ? expiredIsolateView : activeIsolateView}
            </Col>
            <Col span={12}>
              {hasFeatureAccess(
                currentUser,
                FeatureEnum.IsolateLabInterface,
                'read'
              ) && datesDetailView}
            </Col>
          </Row>
        ) : (
          <Row style={{ minHeight: '100px' }}>
            <Col
              align={'middle'}
              style={{ marginTop: '20px', color: THEME.tabText() }}
            >
              <Icon
                type="inbox"
                style={{
                  display: 'block',
                  fontSize: '30px',
                  marginBottom: '10px'
                }}
              />
              {intl.get('isolate.missing.expiration.date')}
            </Col>
          </Row>
        )}
      </BorderedDiv>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  currentUser: app.user
});

const IsolateExpiration = connect(mapStateToProps, null, null, {
  forwardRef: true
})(IsolateExpirationComponent);

export { IsolateExpiration as default, IsolateExpirationComponent };
