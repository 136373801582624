import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Input, Modal, Spin, notification } from 'antd';
import { getModelFieldDecorator } from 'utils/validation/antdHelpers';
import { Herd, HerdFormFields } from 'modules/Herd/herdIndex';
import { HerdList } from 'modules/HerdList/herdListIndex';
import { saveRequest as saveHerdListRequest } from 'modules/HerdList/store/herdListActions';

class HerdListCreateModalComponent extends Component {
  static propTypes = {
    saveHerdListRequest: PropTypes.func.isRequired,
    refreshingHerdList: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onHerdListCreated: PropTypes.func.isRequired,
    naRequest: PropTypes.object.isRequired
  };

  saveErrorNotification = () => {
    notification.warning({
      message: intl.get('error.saving.noun', {
        noun: intl.get('herd.list.lc')
      }),
      description: intl.get('form.entries.error')
    });
  };

  handleSave = () => {
    this.props.form.validateFields({ force: true }, (err, formValues) => {
      if (err) {
        console.warn('save form err:', err);
        this.saveErrorNotification();
        return;
      }

      // Ensure vet is licensed in the herd state before saving.
      const licensedStates = this.props.naRequest.vet?.licenses?.map(
        license => license.state
      );
      if (!licensedStates.includes(formValues.herdState)) {
        console.warn('save form err:', err);
        notification.warning({
          message: intl.get('herd.state.error', {
            state: formValues.herdState
          })
        });
        return;
      }

      const herd = new Herd({
        ...formValues
      });

      const modelValidationErrors = herd.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        this.saveErrorNotification();
        return;
      }

      const herdList = new HerdList({
        name: formValues.name,
        herds: [herd],
        naRequestId: this.props.naRequest.id
      });

      this.props.saveHerdListRequest(herdList, this.herdListSavedCallback);
    });
  };

  herdListSavedCallback = herdList => {
    this.props.closeModal();
    this.props.onHerdListCreated(herdList);
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      const closeModalCallback = () => {
        this.props.closeModal();
      };
      Modal.confirm({
        title: intl.get('cancel.edit'),
        content: intl.get('cancel.edit.body'),
        okText: intl.get('discard'),
        onOk() {
          closeModalCallback();
        }
      });
    } else {
      this.props.closeModal();
    }
  };

  render() {
    const { form, visible, refreshingHerdList } = this.props;
    return (
      <Modal
        visible={visible}
        onCancel={this.handleCancel}
        forceRender={true}
        destroyOnClose={true}
        title={intl.get('herd.list.create')}
        footer={
          <Spin spinning={refreshingHerdList} size="small">
            <Button key="cancel" onClick={this.handleCancel}>
              {intl.get('cancel')}
            </Button>
            <Button
              id="herd-list-create-form-submit"
              type="primary"
              form="herdListCreateForm"
              htmlType="submit"
            >
              {intl.get('save')}
            </Button>
          </Spin>
        }
      >
        <Form
          layout="vertical"
          id="herdListCreateForm"
          onSubmit={this.handleSave}
        >
          <Form.Item label={intl.get('herd.list.name')}>
            {getModelFieldDecorator(form, 'name', HerdList, true)(<Input />)}
          </Form.Item>
          <HerdFormFields form={form} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    refreshingHerdList: state.accounts.herdLists.isRefreshing
  };
};

const mapDispatchToProps = {
  saveHerdListRequest
};

const HerdListCreateModalForm = Form.create({ name: 'herdListCreateForm' })(
  HerdListCreateModalComponent
);

const HerdListCreateModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(HerdListCreateModalForm);

export { HerdListCreateModal as default, HerdListCreateModalComponent };
