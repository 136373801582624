import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import HeaderBreadcrumb from './HeaderBreadcrumb';
import HeaderActions from './HeaderActions';
import { THEME } from 'style';

const { Header } = Layout;

/* istanbul ignore next */
const StyledHeader = styled(Header, { shouldForwardProp: isPropValid })(
  ({ viewMode, sidebarCollapsed }) => {
    let baseStyling = {
      alignItems: 'center',
      background: THEME.mainBackgroundColor(),
      display: 'flex',
      whiteSpace: 'nowrap',
      justifyContent: 'space-between',
      padding: '0 32px',
      height: '70px', // If this is changed, please update class .fill-view-vertically and Home content styling as needed
      lineHeight: '100%',
      position: 'fixed',
      zIndex: 20,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08);'
    };

    if (viewMode === 'Mobile') {
      baseStyling.width = '100%';
      baseStyling.padding = '0 0 0 46px'; // for menu unfold toggle
    } else {
      baseStyling.width = `calc(100% - ${sidebarCollapsed ? '80px' : '254px'})`;
      baseStyling.marginLeft = sidebarCollapsed ? '80px' : '254px';
    }

    return baseStyling;
  }
);

function GvlHeaderComponent({
  sidebarCollapsed,
  viewMode,
  breadcrumbs,
  actions,
  menuActions,
  additionalActions,
  loadingDoc,
  predecessorComponents,
  ...rest
}) {
  return (
    <StyledHeader
      {...rest}
      sidebarCollapsed={sidebarCollapsed}
      viewMode={viewMode}
    >
      <HeaderBreadcrumb viewMode={viewMode} breadcrumbs={breadcrumbs} />
      <HeaderActions
        viewMode={viewMode}
        actions={actions}
        menuActions={menuActions}
        predecessorComponents={predecessorComponents}
        loadingDoc={loadingDoc}
      />
      {additionalActions.map(action => {
        return action;
      })}
    </StyledHeader>
  );
}

GvlHeaderComponent.propTypes = {
  sidebarCollapsed: PropTypes.bool.isRequired,
  viewMode: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  menuActions: PropTypes.array.isRequired,
  additionalActions: PropTypes.array.isRequired,
  loadingDoc: PropTypes.bool,
  predecessorComponents: PropTypes.array
};

GvlHeaderComponent.defaultProps = {
  predecessorComponents: []
};

/* istanbul ignore next */
const mapStateToProps = ({ accounts, app }) => ({
  breadcrumbs: accounts.common.gvlHeaderItems.breadcrumbs,
  actions: accounts.common.gvlHeaderItems.actions,
  menuActions: accounts.common.gvlHeaderItems.menuActions,
  additionalActions: accounts.common.gvlHeaderItems.additionalActions,
  predecessorComponents: accounts.common.gvlHeaderItems.predecessorComponents,
  viewMode: app.data.viewMode,
  loadingDoc: accounts.clinic.dataLists.documentModels.refreshingActiveDocument
});
/* istanbul ignore next */
const GvlHeader = connect(mapStateToProps, null)(GvlHeaderComponent);
export { GvlHeader as default, GvlHeaderComponent };
