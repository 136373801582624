// This store holds data used by Lab accounts
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import {
  REFRESH_LAB_TEST_LIST_REQUEST,
  REFRESH_LAB_TEST_LIST_SUCCESS,
  REFRESH_LAB_TEST_LIST_FAILURE,
  RESULT_LAB_TESTS,
  NVSL_RESULT_LAB_TEST,
  LAB_CLEAR_TEST_ERRORS,
  REMOVE_LAB_TEST_FROM_LIST,
  SEARCH_LAB_TESTS,
  SEARCH_LAB_CERTIFICATES,
  SEARCH_LAB_TESTS_SUCCESS,
  SEARCH_LAB_CERTIFICATES_SUCCESS,
  CLEAR_LAB_CERTIFICATE_SEARCH,
  CLEAR_LAB_TEST_SEARCH,
  LAB_SEND_TO_NVSL_REQUEST,
  LAB_SEND_TO_NVSL_FINISHED,
  LAB_ATTACH_NVSL_REPORT_REQUEST,
  LAB_ATTACH_NVSL_REPORT_FINISHED,
  LAB_REMOVE_NVSL_REPORT_REQUEST,
  LAB_REMOVE_NVSL_REPORT_FINISHED
} from 'containers/accounts/lab/store/accountsLabConstants';

export const initialState = Immutable.from({
  dataLists: {
    labTests: {
      entities: {},
      refreshing: false,
      lastRefreshedTime: null,
      refreshFailure: false,
      searching: false,
      searchResultIds: null,
      runTestSearchReport: false,
      nvslAttachmentStatus: null, // Will be either 'attached', 'attachAndSign' or 'removed' after a request to attach or remove report
      nvslAttachmentId: null // Will be set to attachment ID when nvslAttachmentStatus is 'attached' or 'attachedAndSign'
    },
    labCerts: {
      entities: {},
      searching: false,
      searchComplete: false
    },
    sendingToNvsl: false
  }
});

export const onRefreshLabTestListRequest = state =>
  state.updateIn(['dataLists', 'labTests'], labTests => ({
    ...labTests,
    entities: labTests.entities,
    refreshing: true,
    lastRefreshedTime: null,
    refreshFailure: false
  }));

export const onRefreshLabTestListSuccess = (state, payload) => {
  return state.updateIn(['dataLists', 'labTests'], labTests => ({
    ...labTests,
    entities: payload.labTests.reduce(
      (entities, labTest) => ({ ...entities, [labTest.id]: labTest }),
      labTests.entities
    ),
    refreshing: false,
    lastRefreshedTime: !payload.refreshingActiveLabTest
      ? new Date().getTime()
      : null,
    refreshFailure: false
  }));
};

export const onRemoveLabTestFromList = (state, payload) => {
  const labTest = payload.labTest;
  return state.updateIn(['dataLists', 'labTests', 'entities'], entities =>
    entities.without(labTest.id)
  );
};

export const onRefreshLabTestListFailure = state =>
  state.setIn(['dataLists', 'labTests'], {
    ...initialState.dataLists.labTests,
    refreshFailure: true
  });

export const setLabTestsRefreshing = state =>
  state.setIn(['dataLists', 'labTests', 'refreshing'], true);

export const onLabClearTestErrors = (state, payload) => {
  const tests = Object.values(state.dataLists.labTests.entities).filter(
    test => test.tableRowClass
  );

  // if no errors, nothing to change
  if (tests.length === 0) {
    return state;
  }

  // create list of documents to change
  let mergeData = {};
  for (const test of tests) {
    mergeData[test.id] = test.without('tableRowClass');
  }

  // return new state
  return state.setIn(
    ['dataLists', 'labTests', 'entities'],
    state.dataLists.labTests.entities.merge(mergeData)
  );
};

export const onSearchLabTests = (state, payload) =>
  state.updateIn(['dataLists', 'labTests'], labTests =>
    labTests.merge({
      searching: true,
      runTestSearchReport: payload.searchParams.report
    })
  );

export const onSearchLabCertificates = (state, payload) =>
  state.updateIn(['dataLists', 'labCerts'], labCerts =>
    labCerts.merge({
      searching: true
    })
  );

export const onSearchLabTestsSuccess = (state, payload) =>
  state.updateIn(['dataLists', 'labTests'], labTests => ({
    ...labTests,
    searching: false,
    searchResultIds: payload.labTestIds
  }));

export const onSearchLabCertificatesSuccess = (state, payload) =>
  state.updateIn(['dataLists', 'labCerts'], labCerts => ({
    ...labCerts,
    entities: payload.labCerts.reduce(
      (entities, labCert) => ({ ...entities, [labCert.id]: labCert }),
      {}
    ),
    searching: false,
    searchComplete: true
  }));

export const onClearLabCertificateSearch = state =>
  state.updateIn(['dataLists', 'labCerts'], labCerts =>
    labCerts.merge({
      searching: false,
      searchComplete: false
    })
  );

export const onClearLabTestSearch = state =>
  state.updateIn(['dataLists', 'labTests'], labTests =>
    labTests.merge({
      searching: false,
      searchResultIds: null
    })
  );

export const onLabSendToNvslRequest = state =>
  state.setIn(['dataLists', 'sendingToNvsl'], true);

export const onLabSendToNvslFinished = state =>
  state.setIn(['dataLists', 'sendingToNvsl'], false);

export const onLabNvslReportRequestStarted = state => {
  let newState = state.setIn(['dataLists', 'labTests', 'refreshing'], true);
  newState = newState.setIn(
    ['dataLists', 'labTests', 'nvslAttachmentId'],
    null
  );
  return (newState = newState.setIn(
    ['dataLists', 'labTests', 'nvslAttachmentStatus'],
    null
  ));
};

export const onLabAttachNvslReportFinished = (state, payload) => {
  const { attachmentId, signAfterAttach } = payload;
  let newState = state;

  // An attachmentId indicates the attachment was successful
  if (attachmentId) {
    newState = state.setIn(
      ['dataLists', 'labTests', 'nvslAttachmentId'],
      attachmentId
    );

    let attachmentStatus = signAfterAttach ? 'attachedAndSign' : 'attached';
    newState = newState.setIn(
      ['dataLists', 'labTests', 'nvslAttachmentStatus'],
      attachmentStatus
    );
  }

  return newState.setIn(['dataLists', 'labTests', 'refreshing'], false);
};

export const onLabRemoveNvslReportFinished = (state, payload) => {
  const { removed } = payload;
  let newState = state;

  if (removed) {
    newState = newState.setIn(
      ['dataLists', 'labTests', 'nvslAttachmentStatus'],
      'removed'
    );
  }

  return newState.setIn(['dataLists', 'labTests', 'refreshing'], false);
};

const accountsLabReducer = createReducer(initialState, {
  [REFRESH_LAB_TEST_LIST_REQUEST]: onRefreshLabTestListRequest,
  [REFRESH_LAB_TEST_LIST_SUCCESS]: onRefreshLabTestListSuccess,
  [REFRESH_LAB_TEST_LIST_FAILURE]: onRefreshLabTestListFailure,
  [RESULT_LAB_TESTS]: setLabTestsRefreshing,
  [NVSL_RESULT_LAB_TEST]: setLabTestsRefreshing,
  [LAB_CLEAR_TEST_ERRORS]: onLabClearTestErrors,
  [REMOVE_LAB_TEST_FROM_LIST]: onRemoveLabTestFromList,
  [SEARCH_LAB_TESTS]: onSearchLabTests,
  [SEARCH_LAB_CERTIFICATES]: onSearchLabCertificates,
  [SEARCH_LAB_TESTS_SUCCESS]: onSearchLabTestsSuccess,
  [SEARCH_LAB_CERTIFICATES_SUCCESS]: onSearchLabCertificatesSuccess,
  [CLEAR_LAB_CERTIFICATE_SEARCH]: onClearLabCertificateSearch,
  [CLEAR_LAB_TEST_SEARCH]: onClearLabTestSearch,
  [LAB_SEND_TO_NVSL_REQUEST]: onLabSendToNvslRequest,
  [LAB_SEND_TO_NVSL_FINISHED]: onLabSendToNvslFinished,
  [LAB_ATTACH_NVSL_REPORT_REQUEST]: onLabNvslReportRequestStarted,
  [LAB_ATTACH_NVSL_REPORT_FINISHED]: onLabAttachNvslReportFinished,
  [LAB_REMOVE_NVSL_REPORT_REQUEST]: onLabNvslReportRequestStarted,
  [LAB_REMOVE_NVSL_REPORT_FINISHED]: onLabRemoveNvslReportFinished
});

export default accountsLabReducer;
