import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './nonAdjacentHerdRequestActionTypes';

// store structure
const initialState = Immutable.from({
  data: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false,
  searchResults: {}
});

export const onNonAdjacentHerdRequestRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

export const onNonAdjacentHerdRequestListSuccess = (state, payload) => {
  const { nonAdjacentHerdRequests } = payload;
  let newState = state.asMutable({ deep: true });

  /*
  TODO: Do we still want to clear out the list,
  potentially as a first step in the list request?
  The behavior here mirrors isolateReducers.js
  */
  for (const naRequest of nonAdjacentHerdRequests) {
    newState.data[naRequest.id] = naRequest;
  }
  newState.lastSuccessfulRefreshedTime = new Date().getTime();
  newState.refreshFailure = false;

  return Immutable.from(newState);
};

export const onNonAdjacentHerdRequestListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onNonadjacentSearchSuccess = (state, { naRequests }) => {
  let searchResults = {};
  naRequests.forEach(naRequest => (searchResults[naRequest.id] = naRequest));
  return state.merge({
    searchResults
  });
};

export const onNonadjacentSearchFailure = state =>
  state.merge({
    searchResults: {}
  });

export const onRefreshSuccess = (state, { naRequest }) =>
  Immutable.setIn(state, ['data', naRequest.id], naRequest);

export const onNonAdjacentHerdRequestDeleteSuccess = (state, { id }) => {
  return Immutable.updateIn(state, ['data'], nonAdjacentHerdRequests =>
    nonAdjacentHerdRequests.without(id)
  );
};

export const nonAdjacentHerdRequestReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onNonAdjacentHerdRequestRefreshing,
  [types.LIST_SUCCESS]: onNonAdjacentHerdRequestListSuccess,
  [types.LIST_FAILURE]: onNonAdjacentHerdRequestListFailure,
  [types.SEARCH_SUCCESS]: onNonadjacentSearchSuccess,
  [types.SEARCH_FAILURE]: onNonadjacentSearchFailure,
  [types.REFRESH_SUCCESS]: onRefreshSuccess,
  [types.DELETE_SUCCESS]: onNonAdjacentHerdRequestDeleteSuccess
});
