import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';

export default function TooltipWrapper({ label, tooltipTitle }) {
  return (
    <span>
      {label}
      <Tooltip title={tooltipTitle}>
        <Icon style={{ padding: '0 5px' }} type="info-circle" />
      </Tooltip>
    </span>
  );
}

TooltipWrapper.propTypes = {
  label: PropTypes.any,
  tooltipTitle: PropTypes.any.isRequired
};
