import PropTypes from 'prop-types';
import { Modal } from 'antd';
import intl from 'react-intl-universal';

export const contactShape = PropTypes.shape({
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  orgName: PropTypes.string,
  label: PropTypes.string,
  mvlConnected: PropTypes.bool,
  cellPhone: PropTypes.string,
  ownerEmail: PropTypes.string
});

export const premisesShape = PropTypes.shape({
  name: PropTypes.string,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  country: PropTypes.string,
  fax: PropTypes.string,
  premisesId: PropTypes.string,
  locationId: PropTypes.string
});

export const getContactCommonName = (contact, lastFirst = false) => {
  let name;
  if (!contact) {
    name = '';
  } else if (lastFirst) {
    name = `${contact.lastName}, ${contact.firstName}`;
  } else if (contact.label) {
    name = contact.label;
  } else if (contact.orgName) {
    name = contact.orgName;
  } else if (contact.firstName && contact.lastName) {
    name = `${contact.firstName} ${contact.lastName}`;
  } else if (contact.name) {
    name = contact.name;
  }
  return name;
};

export const showMvlRevokeAccessModal = (contacts, onOk) => {
  Modal.confirm({
    title: intl.get('revokeMvlAccess.title'),
    content: intl.get('revokeMvlAccess.message'),
    okText: intl.get('revoke'),
    cancelText: intl.get('cancel'),
    onOk: () => {
      onOk(contacts);
    }
  });
};
