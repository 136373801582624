import * as types from './isolateReleaseActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = newStatus =>
  createAction(types.IS_REFRESHING, { isRefreshing: newStatus });

// refresh
export const refreshReleasesForIsolate = isolate =>
  createAction(types.REFRESH_RELEASES_FOR_ISOLATE, { isolate });
export const refreshReleasesForIsolateSuccess = (isolateId, releases) =>
  createAction(types.REFRESH_RELEASES_FOR_ISOLATE_SUCCESS, {
    isolateId,
    releases
  });
