import store from 'store';
import { DEPLOY_PLATFORM } from 'utils/config';

export const getEnvironment = () => {
  let env;
  env = store.getState().accounts.common.startupData.environment;
  return env ? env : DEPLOY_PLATFORM;
};

// HIMS Access
/*
Don't need any check of thisPath since this is not an actual front-end app, but a url to external site
We are using HIMS url, and they will check authentication on their end, and redirect to our /sso/login if not authenticated in HIMS
*/
export const getHimsUrl = () => {
  const env = getEnvironment();
  let himsUrl = '';
  if (env === 'production') himsUrl = 'https://www.himsdx.com/';
  else if (env === 'releasecandidate') himsUrl = 'https://staging.himsdx.com/';
  else if (env === 'testing') himsUrl = 'https://dev.himsdx.com/';
  else if (env === 'local') himsUrl = 'https://dev.himsdx.com/';
  return himsUrl;
};

export const getZuoraHost = () => {
  const env = getEnvironment();
  const zuoraHost =
    env === 'production'
      ? 'https://www.zuora.com/'
      : 'https://apisandbox.zuora.com/';
  return zuoraHost;
};

export const getRecaptchaSiteKey = () => {
  const env = getEnvironment();
  const siteKey =
    env === 'production'
      ? '6LcMsL4aAAAAAIqLQJSfKwN3jUWpm1jAdMSm-CrV'
      : '6Le9lMgaAAAAAMK5K3eWfQb7Hax9hDVbXETe-MHV';
  return siteKey;
};

export const getSalesforceHost = () => {
  const env = getEnvironment();
  const zuoraHost =
    env === 'production'
      ? 'https://globalvetlinksales.lightning.force.com'
      : 'https://globalvetlinksales--apitest1.my.salesforce.com';
  return zuoraHost;
};
