import moment from 'moment';

/**
 * Newport has specific requirements for the length of time they give Clinics to work with Isolates.
 * In particular, this affects isolates expiring one day before Newport would like.
 * E.g. 11/30 -> 2/28. Newport would like to give the clinic 1 extra day, making the new value 3/1.
 * @param Date startDate - Date to count from
 * @param Integer monthsAdded - Number of months to count off
 * @return Date the new date with Newport's logic applied
 */
export const addMonths = (startDate, monthsAdded) => {
  // we convert to a moment explicitly here so we can treat values the same,
  // whether they are strings from an API call or a moment we're working with in a form.
  const momentStartDate =
    typeof startDate !== 'object' ? moment(startDate) : startDate;
  const startingDayOfMonth = momentStartDate?.date();
  const endDate = moment(momentStartDate?.clone().add(monthsAdded, 'months'));
  const endingDayOfMonth = endDate.date();

  if (startingDayOfMonth > endingDayOfMonth) {
    endDate.add(1, 'days');
  }

  return endDate;
};
