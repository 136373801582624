import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { listRequest as locationListRequest } from 'modules/Location/store/locationActions';
import { generateSelectOptions } from 'utils/appUtil';

class LocationLookupComponent extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    locationListRequest: PropTypes.func.isRequired,
    locations: PropTypes.object,
    disableSelect: PropTypes.bool,
    tooltipText: PropTypes.string,
    searchParams: PropTypes.object.isRequired,
    value: PropTypes.object
  };

  static defaultProps = {
    disableSelect: true
  };

  componentDidMount() {
    this.props.locationListRequest(this.props.searchParams);
  }

  componentDidUpdate(prevProps) {
    const { searchParams } = this.props;
    if (
      searchParams.contactId &&
      prevProps.searchParams.contactId !== searchParams.contactId
    ) {
      this.props.locationListRequest(searchParams);
    }
  }

  onLocationChange = id => {
    const { locations, onChange } = this.props;
    const location = locations[id];
    onChange(location);
  };

  render() {
    const { locations, disableSelect, tooltipText } = this.props;
    return (
      <Tooltip title={disableSelect ? tooltipText : null}>
        <Select
          showSearch
          showAction={['focus', 'click']}
          onChange={this.onLocationChange}
          disabled={disableSelect}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {generateSelectOptions(
            Object.values(locations).map(location => ({
              value: location.locationId,
              text: location.displayName
            }))
          )}
        </Select>
      </Tooltip>
    );
  }
}

const mapStateToProps = ({ accounts }) => ({
  locations: accounts.locations.data
});

const mapDispatchToProps = { locationListRequest };

const LocationLookup = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationLookupComponent);
export { LocationLookup as default, LocationLookupComponent };
