// list constants
export const LIST_REQUEST = 'herd/LIST_REQUEST';
export const LIST_SUCCESS = 'herd/LIST_SUCCESS';
export const LIST_FAILURE = 'herd/LIST_FAILURE';

// General constants
export const IS_REFRESHING = 'herd/IS_REFRESHING';

// Update constants
export const SAVE_REQUEST = 'herd/SAVE_REQUEST';

// Delete constants
export const DELETE_REQUEST = 'herd/DELETE_REQUEST';
