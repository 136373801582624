import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { Modal, Button, Spin } from 'antd';

import {
  logoutRequest,
  loginAcceptUserLicenseAgreement
} from 'containers/app/store/login/loginActions';

function LicenseAgreementComponent({
  visible,
  content,
  version,
  onAgree,
  onDisagree,
  loading
}) {
  return (
    <Modal
      visible={visible}
      centered={true}
      mask={true}
      maskClosable={false}
      closable={false}
      title={<h2>{intl.get('userLicenseAgreement.title', { version })}</h2>}
      bodyStyle={{ maxHeight: '50vh', overflow: 'auto' }}
      footer={[
        <Button key="back" onClick={onDisagree}>
          {intl.get('userLicenseAgreement.disagree')}
        </Button>,
        <Button key="submit" type="primary" onClick={onAgree}>
          {intl.get('userLicenseAgreement.agree')}
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Spin>
    </Modal>
  );
}

LicenseAgreementComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  content: PropTypes.string,
  version: PropTypes.string,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const userLicenseAgreementInfo = state.app.login.userLicenseAgreementInfo;
  return {
    content: userLicenseAgreementInfo.content,
    version: userLicenseAgreementInfo.versionNumber,
    loading: state.app.login.fetchingUserLicenseAgreementInfo
  };
};

const mapDispatchToProps = {
  onDisagree: logoutRequest,
  onAgree: loginAcceptUserLicenseAgreement
};

const LicenseAgreement = connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseAgreementComponent);
export { LicenseAgreement as default, LicenseAgreementComponent };
