// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
//This model is shared between CVI and and ForSale

import Model from 'models/Model';
import {
  dateIsAfter,
  stringRequired,
  maxLength,
  isDate,
  isNumber,
  numberInRange
} from 'utils/validation/common';

function warnAboutRabiesModel(shouldWarn) {
  if (shouldWarn) {
    // NOTE: wherever we hit this isVaccineForRabies we might instead want to be using the RabiesVac model, which extends this model.
    console.warn(
      'Using generic Vaccination model but detected a rabies vaccination'
    );
  }
}

export default class Vaccination extends Model {
  // Instance Methods

  // TODO Rewrite these validations to use the inline validation approach with instance
  /*  validate() {
    let superValidation = super.validate();
    let results = superValidation ? superValidation : [];

    // Put complex validations here.  If none, then delete this whole validate() method.

    // boostersDate should be after vaccinationDate
    if (this.boostersDate != null) {
      let boostersDateError = dateIsAfter(
        this.vaccinationDate,
        this.boostersDate
      );
      if (boostersDateError)
        results.push(Model.formatError('boosters Date', boostersDateError));
    }

    return results;
  }*/

  // Static Methods

  static get fields() {
    return {
      ...super.fields,

      animal_id: {
        initialValue: null,
        validate: value =>
          !value
            ? null
            : isNumber(value, 'validation.id.number') ||
              numberInRange(value, 0, Number.MAX_SAFE_INTEGER)
      },
      expirationDate: {
        initialValue: null,
        validate: (val, instance) => {
          if (!val) return null;

          const unparseableDateError = isDate(val, 'MM/DD/YYYY', 'required');
          if (unparseableDateError) return unparseableDateError;

          if (instance && instance.vaccinationDate) {
            return dateIsAfter(
              instance.vaccinationDate,
              val,
              'vaccination.expirationDate.error.beforeVaccinationDate'
            );
          }

          return null;
        }
      },
      name: {
        initialValue: null,
        validate: val => {
          return stringRequired(val, 'required') || maxLength(val, 60);
        }
      },
      type: {
        initialValue: undefined,
        validate: (val, instance) => {
          const isCompanionSpecies = ['feline', 'canine', 'avian'].includes(
            (instance.species || '').toLowerCase()
          );
          const isVaccineForRabies =
            (instance.name || '').toLowerCase().trim() === 'rabies';
          warnAboutRabiesModel(isVaccineForRabies);
          if (!isCompanionSpecies || isVaccineForRabies) {
            return stringRequired(val, 'required') || maxLength(val, 50);
          }
          return null;
        }
      },
      exposure: {
        initialValue: undefined,
        validate: (val, instance) => {
          const isVaccineForRabies =
            (instance.name || '').toLowerCase().trim() === 'rabies';
          if (isVaccineForRabies) {
            warnAboutRabiesModel(isVaccineForRabies);
            if (instance.species !== 'equine')
              return stringRequired(val, 'required');
          }
          return null;
        }
      },
      remarks: {
        initialValue: undefined,
        validate: val => (!val ? null : maxLength(val, 1020))
      },
      manufacturer: {
        initialValue: undefined,
        validate: (val, instance) => {
          const isVaccineForRabies =
            (instance.name || '').toLowerCase().trim() === 'rabies';
          warnAboutRabiesModel(isVaccineForRabies);
          // NOTE: ideally this check is unnecessary.
          if (!isVaccineForRabies) {
            return stringRequired(val, 'required') || maxLength(val, 100);
          }
        }
      },
      product: {
        initialValue: undefined,
        validate: val => (val ? maxLength(val, 120) : null)
      },
      lotExpirationDate: {
        initialValue: null,
        validate: (val, instance) => {
          if (!val) {
            return null;
          } else if (instance && instance.vaccinationDate) {
            return dateIsAfter(
              instance.vaccinationDate,
              val,
              'vaccination.lotExpirationDate.error.beforeVaccinationDate'
            );
          } else {
            return null;
          }
        }
      },
      lotNumber: {
        initialValue: null,
        validate: val => maxLength(val, 50)
      },
      boostersDate: {
        initialValue: undefined,
        validate: val => {
          return !val ? null : isDate(val, 'MM/DD/YYYY');
        }
      },
      vaccinationDate: {
        initialValue: null,
        validate: (val, instance) => {
          const unparseableDateError = isDate(val, 'MM/DD/YYYY', 'required');
          if (unparseableDateError) return unparseableDateError;

          if (instance && instance.expirationDate) {
            //Check with expiration date
            const afterExpirationError = dateIsAfter(
              val,
              instance.expirationDate,
              'vaccination.vaccinationDate.error.afterExpirationDate'
            );
            if (afterExpirationError) return afterExpirationError;

            if (instance.lotExpirationDate) {
              //Check with lot expiration date if present
              return dateIsAfter(
                val,
                instance.lotExpirationDate,
                'vaccination.vaccinationDate.error.afterLotExpirationDate'
              );
            }
          }

          return null;
        }
      },
      version: {
        // TODO probably a good idea to move version to model.js since its from GORM
        initialValue: null
      },
      species: {
        // This is a temp value used during validation of the vaccination type and is not saved to the database.
        initialValue: undefined
      }
    };
  }

  static get apiPath() {
    return 'vaccination';
  }

  static get domain() {
    return 'vaccination';
  }
}
