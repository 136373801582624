import { put, call, takeLatest } from 'redux-saga/effects';
import AccountModel from 'models/Account';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as actions from 'modules/Billing/store/billingActions';
import * as types from 'modules/Billing/store/billingActionTypes';

export function* onRequestDefaultPaymentMethod({ accountId }) {
  try {
    yield put(actions.actionInProgress(true));
    const account = yield call(AccountModel.read, accountId);
    yield call(account.fetchAdditionalData);
    const paymentMethod = yield call([
      account,
      account.getDefaultPaymentMethod
    ]);
    yield put(actions.loadDefaultPaymentMethodSuccess(paymentMethod));
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress(false));
  }
}

export function* onRequestPaymentMethods({ accountId }) {
  try {
    yield put(actions.actionInProgress(true));
    const account = yield call(AccountModel.read, accountId);
    yield call(account.fetchAdditionalData);
    yield call(account.getPaymentMethods);

    yield put(
      actions.loadPaymentMethodsSuccess(account.arAccountSummary.paymentMethods)
    );
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress(false));
  }
}

export function* onRequestBillingInformation({ accountId }) {
  try {
    yield put(actions.actionInProgress(true));
    const account = yield call(AccountModel.read, accountId);

    yield call(account.fetchAdditionalData);

    const success = account.arAccountSummary.success;
    const billingAddress = account.billingAddress;
    const paymentMethods = account.arAccountSummary.success
      ? account.arAccountSummary.paymentMethods
      : [];
    const defaultPaymentMethod = account.getDefaultPaymentMethod();
    const subscriptions = account.subscriptions;

    yield put(
      actions.loadBillingInformationSuccess({
        success,
        billingAddress,
        paymentMethods,
        defaultPaymentMethod,
        subscriptions
      })
    );
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.actionInProgress(false));
  }
}

export default function* billingSagas() {
  yield takeLatest(
    types.LOAD_DEFAULT_PAYMENT_METHOD_REQUEST,
    onRequestDefaultPaymentMethod
  );
  yield takeLatest(types.LOAD_PAYMENT_METHODS_REQUEST, onRequestPaymentMethods);
  yield takeLatest(
    types.LOAD_BILLING_INFORMATION_REQUEST,
    onRequestBillingInformation
  );
}
