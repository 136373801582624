import React from 'react';
import PropTypes from 'prop-types';
import { FadedText } from 'style/commonEmotions';
import { Row, Col } from 'antd';

export default function IsolateNameHeading({ name, genusSpecies, isolateNbr }) {
  return (
    <Row type="flex" gutter={24}>
      <Col>{name.length > 40 ? genusSpecies + '...' : name}</Col>
      <Col>
        <FadedText>{'#' + isolateNbr}</FadedText>
      </Col>
    </Row>
  );
}

IsolateNameHeading.propTypes = {
  name: PropTypes.string.isRequired,
  genusSpecies: PropTypes.string.isRequired,
  isolateNbr: PropTypes.string.isRequired
};
