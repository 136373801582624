import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Divider, List } from 'antd';
import { FadedText } from 'style/commonEmotions';
import GvlCard from 'elements/GvlCard';
import * as api from 'utils/api';

/* To disable the right-click to prevent saving/copying the signature image */
const onContextMenu = e => {
  e.preventDefault();
};

export default class NonAdjacentVetAssessmentShowCard extends Component {
  static propTypes = {
    naRequest: PropTypes.object.isRequired
  };

  state = {
    signatureImage: null
  };

  componentDidMount() {
    // Grab the vet's signature if nonadjacent has been signed
    if (this.props.naRequest.acknowledgedDate) {
      this.getVetSignature();
    }
  }

  getVetSignature = async () => {
    let signature = await api.get(
      `autogenousVaccines/nonAdjacentHerdRequest/${this.props.naRequest.id}/getVetSignature`
    );
    signature = Object.values(signature);
    this.setState({ signatureImage: signature });
  };

  render() {
    const naRequest = this.props.naRequest;
    const signatureImage = this.state.signatureImage;

    return (
      <GvlCard
        title={`${naRequest.vet.commonName} - ${intl.get(
          'attending.vet.assessment'
        )}`}
      >
        <div>
          <div className="semibold">
            {intl.get('naRequest.clinicalObservations.label')}
          </div>
          <div style={{ marginLeft: '16px' }}>
            <List
              size="large"
              dataSource={naRequest.clinicalObservations || []}
              renderItem={item => <List.Item>{item}</List.Item>}
            />
            <Divider style={{ marginTop: 0 }} />
          </div>
          <div className="semibold">
            {intl.get('naRequest.epidemiologicalLinks.label')}
          </div>
          <div style={{ marginLeft: '16px' }}>
            <List
              size="large"
              dataSource={naRequest.epidemiologicalLinks || []}
              renderItem={item => <List.Item>{item}</List.Item>}
            />
            <Divider style={{ marginTop: 0 }} />
          </div>
          <br />
          {naRequest.acknowledgedDate ? (
            <React.Fragment>
              <p>{intl.get('naRequest.vet.acknowledgement')}</p>
              <br />
              {signatureImage ? (
                <img
                  className="half-or-full-width"
                  src={`data:image/jpeg;base64, ${signatureImage}`}
                  alt="user signature"
                  onContextMenu={onContextMenu}
                />
              ) : null}
              <div className="semibold">{naRequest.vet.commonName}</div>
              <div>
                <FadedText>
                  {intl.get('digitally.signed', {
                    date: moment(naRequest.acknowledgedDate).format('M/D/Y'),
                    time: new Date(
                      naRequest.acknowledgedDate
                    ).toLocaleTimeString('en-US', {
                      timeZone: 'America/Chicago'
                    }),
                    vet: naRequest.vet.commonName,
                    clinic: naRequest.clinic.name
                  })}
                </FadedText>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </GvlCard>
    );
  }
}
