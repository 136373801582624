import * as types from './nonAdjacentHerdRequestActionTypes';
import { createAction } from 'utils/reduxUtil';

// actions
export const isRefreshing = status =>
  createAction(types.IS_REFRESHING, { isRefreshing: status });

// list
export const listRequest = () => createAction(types.LIST_REQUEST);
export const listSuccess = nonAdjacentHerdRequests =>
  createAction(types.LIST_SUCCESS, { nonAdjacentHerdRequests });
export const listFailure = () => createAction(types.LIST_FAILURE);

// search
export const searchRequest = (searchType, searchParams, callback = null) =>
  createAction(types.SEARCH_REQUEST, { searchType, searchParams, callback });
export const searchSuccess = naRequests =>
  createAction(types.SEARCH_SUCCESS, { naRequests });
export const searchFailure = () => createAction(types.SEARCH_FAILURE);

// refresh
export const refreshRequest = (id, callback = null) =>
  createAction(types.REFRESH_REQUEST, { id, callback });
export const refreshSuccess = naRequest =>
  createAction(types.REFRESH_SUCCESS, { naRequest });

// workflow
export const sendToClinic = (naRequest, callback = null, attachments = null) =>
  createAction(types.SEND_TO_CLINIC, { naRequest, callback, attachments });
export const updateHerdlistInformation = (
  naRequest,
  successMessage = null,
  callback = null
) =>
  createAction(types.UPDATE_HERDLIST_INFO, {
    naRequest,
    successMessage,
    callback
  });
export const signNARequest = (id, username, password, callback = null) =>
  createAction(types.SIGN_REQUEST, {
    id,
    username,
    password,
    callback
  });
export const saveRequest = (naRequest, callback = null) =>
  createAction(types.SAVE_REQUEST, { naRequest, callback });
export const saveTagsAndNotes = (naRequest, closeModalCallback) =>
  createAction(types.SAVE_TAGS_AND_NOTES, { naRequest, closeModalCallback });
export const sendToStateVet = (naRequest, callback = null) =>
  createAction(types.SEND_TO_STATE_VET, { naRequest, callback });
export const returnToLab = (naRequest, callback = null) =>
  createAction(types.RETURN_TO_LAB, { naRequest, callback });
export const approveNaRequest = (naRequest, callback = null) =>
  createAction(types.APPROVE_NA_REQUEST, { naRequest, callback });
export const markNaRequestComplete = id =>
  createAction(types.MARK_NA_REQUEST_COMPLETE, { id });

// delete
export const deleteRequest = id => createAction(types.DELETE_REQUEST, { id });
export const deleteSuccess = id => createAction(types.DELETE_SUCCESS, { id });
