import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { THEME } from 'style';

export default function IconWithLink({ type, link }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontSize: '36px', padding: '0px 8px' }}
    >
      <Icon type={type} style={{ color: THEME.white() }} />
    </a>
  );
}

IconWithLink.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
