import React from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import PropTypes from 'prop-types';

import LogoImageColor from 'images/logos/GVL/GVL_logo.png';
import LogoImageWhite from 'images/logos/GVL/GVL_logo_white.png';
import LogoImageBlack from 'images/logos/GVL/GVL_logo_black.png';
import MvlLogoPurple from 'images/logos/MVL/MVL_logo_purple.png';
import Herdpoint from 'images/logos/Herdpoint/Herdpointlogo.svg';
import HerdpointPoweredByGVL from 'images/logos/Herdpoint/Herdpoint-poweredbyGVL.svg';

import GVLULogoImageColor from 'images/logos/university/GVL_U.png';
import GVLUniversityLogoImageColor from 'images/logos/university/GVL_University.png';
import GVLUniversityLongLogoImageColor from 'images/logos/university/GVL_University_Long.png';

const getStyleBasedOnProps = ({ size, type, height, width }) => {
  let elHeight, elWidth, marginPerSize;

  switch (size) {
    case 'small':
      elHeight = height || '20px';
      elWidth = width || '70px';
      marginPerSize = '6px';
      break;
    case 'medium':
      elHeight = height || '34px';
      elWidth = width || '170px';
      marginPerSize = '24px';
      break;
    case 'large':
      elHeight = height || '50px';
      elWidth = width || '250px';
      marginPerSize = '24px';
      break;
    default:
      elHeight = height || '34px';
      elWidth = width || '170px';
      marginPerSize = '24px';
      break;
  }

  let backgroundImage;
  switch (type) {
    case 'color':
      backgroundImage = `url(${LogoImageColor})`;
      break;
    case 'black':
      backgroundImage = `url(${LogoImageBlack})`;
      break;
    case 'white':
      backgroundImage = `url(${LogoImageWhite})`;
      break;
    case 'mvlPurple':
      backgroundImage = `url(${MvlLogoPurple})`;
      break;
    case 'herdpoint':
      backgroundImage = `url(${Herdpoint})`;
      break;
    case 'herdpointPowered':
      backgroundImage = `url(${HerdpointPoweredByGVL})`;
      break;
    case 'gvlU':
      backgroundImage = `url(${GVLULogoImageColor})`;
      break;
    case 'gvlUniversity':
      backgroundImage = `url(${GVLUniversityLogoImageColor})`;
      break;
		case 'gvlUniversityLong':
      backgroundImage = `url(${GVLUniversityLongLogoImageColor})`;
      break;
    default:
  }

  return {
    width: elWidth,
    height: elHeight,
    backgroundImage,
    backgroundSize: 'contain',
    margin: '10px ' + marginPerSize,
    backgroundRepeat: 'no-repeat'
  };
};

const StyledLogo = styled('div', { shouldForwardProp: isPropValid })(
  getStyleBasedOnProps
);

function Logo(props) {
  return <StyledLogo {...props} />;
}

Logo.propTypes = {
  type: PropTypes.oneOf([
    'color',
    'black',
    'mvlPurple',
    'white',
    'herdpoint',
    'herdpointPowered',
    'gvlU',
    'gvlUniversity'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  height: PropTypes.string,
  width: PropTypes.string
};

export { Logo as default, getStyleBasedOnProps };
