export const ACTION_IN_PROGRESS = 'billing/ACTION_IN_PROGRESS';

export const LOAD_DEFAULT_PAYMENT_METHOD_REQUEST =
  'billing/LOAD_DEFAULT_PAYMENT_METHOD_REQUEST';
export const LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS =
  'billing/LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS';

export const LOAD_PAYMENT_METHODS_REQUEST =
  'billing/LOAD_PAYMENT_METHODS_REQUEST';
export const LOAD_PAYMENT_METHODS_SUCCESS =
  'billing/LOAD_PAYMENT_METHODS_SUCCESS';

export const LOAD_BILLING_INFORMATION_REQUEST =
  'billing/LOAD_BILLING_INFORMATION_REQUEST';
export const LOAD_BILLING_INFORMATION_SUCCESS =
  'billing/LOAD_BILLING_INFORMATION_SUCCESS';
