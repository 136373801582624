import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GvlMenuItem from 'components/GvlSidebar/GvlMenuItem';
import GvlSubMenu from 'components/GvlSidebar/GvlSubMenu';
import { GvlMenu } from 'style/commonEmotions';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';
import { isVaccineFeatureEnabled } from 'containers/app/store/user/userSelectors';

function IsolatesMenuComponent(props) {
  const { location, currentUser, autogenousVaccineEnabled } = props;

  const showIsolateMenu =
    autogenousVaccineEnabled &&
    hasFeatureAccess(currentUser, FeatureEnum.IsolateManagement, 'read');

  return (
    <GvlMenu
      selectedKeys={[location.pathname]}
      defaultOpenKeys={['/isolates']}
      mode="inline"
    >
      <GvlMenuItem
        key="home"
        link="/"
        isTopLevel={true}
        icon="gvl-home"
        title={intl.get('home')}
      />
      {showIsolateMenu ? (
        <GvlSubMenu
          key="autogenousVaccines"
          title={intl.get('vaccines.customMade')}
          icon="gvl-syringe"
        >
          <GvlMenuItem
            key="/isolates"
            link="/isolates"
            title={intl.get('active.isolates')}
          />
          <GvlMenuItem
            key="/products"
            link="/products"
            title={intl.get('isolate.products')}
          />
          <GvlMenuItem
            key="/records"
            link="/records"
            title={intl.get('records')}
          />
        </GvlSubMenu>
      ) : null}
      {/* Something like this help menu will likely exist in the final product,
          but needs to wait until we have final designs for the isolate side menu. */}
      {/* <GvlSubMenu key="help" title={intl.get('help')} icon="gvl_help">
        <GvlMenuItem
          key="/helpArticles"
          link="http://help.globalvetlink.com"
          title={intl.get('helpArticles')}
          external={true}
        />
        <GvlMenuItem
          key="/animalRegs"
          link="https://animalregs.com"
          title={intl.get('animalRegs.com')}
          external={true}
        />
      </GvlSubMenu> */}
    </GvlMenu>
  );
}

IsolatesMenuComponent.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  autogenousVaccineEnabled: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    currentUser: state.app.user,
    autogenousVaccineEnabled: isVaccineFeatureEnabled(state)
  };
};

const isolateMenuWithRedux = connect(mapStateToProps)(IsolatesMenuComponent);
const IsolatesMenu = withRouter(isolateMenuWithRedux);

export { IsolatesMenu as default, IsolatesMenuComponent };
