// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Document from 'models/documents/Document';
import { objectRequired } from 'utils/validation/common';
export default class RecentActivity extends Document {
  static get fields() {
    return {
      ...super.fields,
      animal: {
        initialValue: {} //  animal objects
      },
      owner: {
        initialValue: {},
        validate: val => objectRequired('owner', val)
      },
      ownerPremises: {
        initialValue: {},
        validate: val => objectRequired('ownerPremises', val)
      }
    };
  }

  static get apiPath() {
    return 'documents';
  }

  static getHomeData = async param => {
    return super.getHomeData(param);
  };
}
