import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Radio, Spin } from 'antd';
import { savePracticeType } from 'modules/Clinic/store/clinicActions';

function SpeciesCollection({ form, visible, closeModal }) {
  const dispatch = useDispatch();
  const clinicId = useSelector(state => state.app.user.info.clinicId);
  const processing = useSelector(state => state.accounts.clinics.isRefreshing);

  const saveClinicDetails = () => {
    form.validateFields({ force: true }, (err, formValues) => {
      if (err) return;

      dispatch(
        savePracticeType({
          clinicId,
          params: { practiceType: formValues.practiceType },
          callback: closeModal
        })
      );
    });
  };

  return (
    <Modal
      visible={!!visible}
      closable={false}
      maskClosable={false}
      footer={[
        <Spin key="spin" spinning={processing}>
          <Button type="primary" onClick={saveClinicDetails}>
            {intl.get('save.clinicDetails')}
          </Button>
        </Spin>
      ]}
    >
      <Form>
        <h2>{intl.get('hello.again')}</h2>
        <h4 style={{ margin: '16px 0px' }}>
          {intl.get('speciesCollection.message')}
        </h4>
        <Form.Item
          label={
            <span>
              {intl.get('speciesCollection.question')}
              <span className="subtext">{` (${intl.get('selectOne')})`}</span>
            </span>
          }
        >
          {form.getFieldDecorator('practiceType', {
            rules: [
              {
                required: true,
                message: intl.get('required')
              }
            ]
          })(
            <Radio.Group disabled={processing}>
              <Radio value="Companion">{intl.get('companion')}</Radio>
              <br />
              <Radio value="Equine">{intl.get('equine')}</Radio>
              <br />
              <Radio value="Production">{intl.get('production')}</Radio>
              <br />
              <Radio value="Other/Mixed">{intl.get('other.mixed')}</Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

SpeciesCollection.propTypes = {
  form: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

const SpeciesCollectionModal = Form.create({ name: 'speciesCollectionForm' })(
  SpeciesCollection
);

export { SpeciesCollectionModal as default };
