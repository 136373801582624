import * as api from 'utils/api';

export default class Model {
  constructor(data) {
    this.constructor.initialize(this, data); // Populate instance with property fields from Model, as well as children
  }

  // Instance Methods

  async save() {
    let result;
    if (this[this.constructor.idFieldName]) {
      // Existing record
      result = await api.put(
        this.constructor.apiPath + '/' + this[this.constructor.idFieldName],
        this.toJson()
      );
    } else {
      // New record
      result = await api.post(this.constructor.apiPath, this.toJson());
    }
    // Update the instance with the saved record that is returned so we can get things like id, version, etc.
    this.constructor.initialize(this, result);
  }

  toJson() {
    return Object.keys(this.constructor.fields).reduce(
      (accumulator, key) => ({ ...accumulator, [key]: this[key] }),
      {}
    );
  }

  // Run all validations on instance fields
  validate(...fieldArgs) {
    const results = [];
    Object.keys(this.constructor.fields).forEach(fieldName => {
      const error = this.constructor.validateField(
        fieldName,
        this[fieldName],
        this,
        null,
        ...fieldArgs
      );
      if (error) {
        results.push(error);
      }
    });
    return results.length > 0 ? results : null;
  }

  // validateField may allow some vals to be null that eventually want to be set.
  // for example to sign or commit a Document
  checkFieldsPresent(modelClass, fields, results, errorMessage) {
    for (var field of fields) {
      let fieldVal = this[field];
      if (typeof fieldVal == 'undefined' || fieldVal == null) {
        let fieldName = field.replace(/([A-Z])/g, ' $1');
        fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        results.push(
          // TODO: should be able to use this.prototype.formatError or something similar, and negate need to pass in modelClass
          modelClass.formatError(fieldName, {
            messageId: errorMessage
          })
        );
      }
    }
  }

  // Additional info functions
  // TODO: move this into a mixin or something so they are only availablel for documents and requests.
  getHistory = async () =>
    await api.get(`${this.constructor.apiPath}/${this.id}/history`);

  getMessages = async () =>
    await api.get(`${this.constructor.apiPath}/${this.id}/comments`);

  sendMessage = async msg =>
    await api.post(`${this.constructor.apiPath}/${this.id}/comments`, {
      comment: msg
    });

  addAttachment = async file => {
    const formData = new FormData();
    formData.append('file', file);
    return await api.postFile(
      `${this.constructor.apiPath}/${this.id}/attachments`,
      formData
    );
  };

  getAttachments = async () =>
    (await api.get(`${this.constructor.apiPath}/${this.id}/attachments`))
      .results || [];

  // Static Methods

  // Override in derived class and add child fields
  static get fields() {
    return {
      id: {
        initialValue: null
      }
    };
  }

  static initialize(record, data) {
    // If data passed in, use it to populate instance, otherwise populate with default values.
    if (data) {
      Object.keys(this.fields).forEach(key => {
        if (key in data) {
          // Use value from data because it is there
          record[key] = data[key];
        } else {
          // Use initial value for any properties not in the data
          record[key] = this.fields[key].initialValue;
        }
      });
    } else {
      Object.keys(this.fields).forEach(key => {
        record[key] = this.fields[key].initialValue;
      });
    }
  }

  // Override in derived class to set path to API for domain
  static get apiPath() {
    return '';
  }

  // Override in derived class to name the Domain.  IE: "Documents", "Animals", etc
  static get domain() {
    return '';
  }

  // Override in derived class to name the id on the domain.  IE: "isolateId", "contactId", etc
  // Usually just id but in newer models using uuids it follows the pattern `domainId`
  static get idFieldName() {
    return 'id';
  }

  static async read(id, params) {
    const record = await api.get(this.apiPath + '/' + id, { params });
    return new this(record);
  }

  static async deleteRec(id) {
    await api.deleteRec(this.apiPath + '/' + id);
  }

  static async list(params) {
    const listResponse = await api.get(this.apiPath, { params });
    if (listResponse.results) {
      const instanceResults = listResponse.results.map(
        result => new this(result)
      );
      listResponse.results = instanceResults;
    }
    return listResponse;
  }

  static async auditReport(id, params) {
    const eventsPath = this.apiPath + '/' + id + '/events';
    const auditReportResponse = await api.get(eventsPath, { params });
    if (auditReportResponse.results) {
      const instanceResults = auditReportResponse.results.map(
        result => new this(result)
      );
      auditReportResponse.results = instanceResults;
    }
    return auditReportResponse;
  }

  // Use rawList to get the result objects rather than having them converted to model class
  static async rawList(params) {
    return await api.get(this.apiPath, { params });
  }

  // Validate a specific field
  static validateField(fieldName, value, instance, subfieldName, ...rest) {
    const field = this.fields[fieldName];
    if (!field) {
      return this.formatError(fieldName, {
        messageId: 'model.fieldNameInvalid'
      });
    }
    if (!field.validate) {
      return null;
    }
    const error = field.validate(value, instance, subfieldName, ...rest);
    if (error) {
      return this.formatError(fieldName, error);
    } else {
      return null;
    }
  }

  static formatError(fieldName, error) {
    return {
      domain: this.domain,
      field: fieldName,
      error
    };
  }
}
