import { createSelector, createStructuredSelector } from 'reselect';
import {
  getUserInfo,
  getCurrentUserAccountId
} from 'containers/app/store/user/userSelectors';
import LabTest from 'models/LabTest';
import { getRoute } from 'utils/appUtil';

const isReadyToSign = labTest =>
  labTest.result === 'Negative' &&
  !new LabTest({ ...labTest, validationLevel: 'sign' }).validate();

const getLabTests = state => state.accounts.lab.dataLists.labTests.entities;

const getLabCertifcates = state =>
  state.accounts.lab.dataLists.labCerts.entities;

export const getLabTestsList = state =>
  Object.values(state.accounts.lab.dataLists.labTests.entities);

//This is primarily used to detect duplicate accession number
export const getPendingLabTestsList = createSelector(
  getLabTestsList,
  labTests => labTests.filter(labTest => labTest.cogginsStatus === 'pending')
);

const getSearchResultIds = state =>
  state.accounts.lab.dataLists.labTests.searchResultIds;

export const isSearchingLabTests = state =>
  state.accounts.lab.dataLists.labTests.searching;

export const isSearchingLabCertificates = state =>
  state.accounts.lab.dataLists.labCerts.searching;

export const isCertificateSearchComplete = state =>
  state.accounts.lab.dataLists.labCerts.searchComplete;

export const didRunTestSearchReport = state =>
  state.accounts.lab.dataLists.labTests.runTestSearchReport;

export const getNetworkTests = createSelector(
  getLabTestsList,
  getUserInfo,
  getCurrentUserAccountId,
  (labTests, userInfo, currentUserAccountId) =>
    labTests.filter(
      labTest =>
        !LabTest.isResulted(labTest) &&
        labTest.gvlLab_id !== currentUserAccountId &&
        !labTest.nvslSubmittalDate
    )
);

export const getPendingTests = createSelector(
  getPendingLabTestsList,
  getCurrentUserAccountId,
  (labTests, currentUserAccountId) => {
    const pendingTests = labTests.filter(
      labTest =>
        !LabTest.isResulted(labTest) &&
        labTest.gvlLab_id === currentUserAccountId &&
        !isReadyToSign(labTest) &&
        !labTest.nvslSubmittalDate
    );

    return flagDuplicateAccessions(pendingTests, labTests);
  }
);

export const flagDuplicateAccessions = (testsToCheck, allTests) => {
  testsToCheck.forEach((checkTest, checkIdx) => {
    if (checkTest.accession) {
      const duplicateFound = allTests.some(test => {
        return checkTest.id === test.id
          ? false
          : checkTest.accession === test.accession;
      });

      if (duplicateFound) {
        testsToCheck[checkIdx] = {
          ...testsToCheck[checkIdx],
          duplicateAccession: true
        };
      }
    }
  });

  return testsToCheck;
};

export const getReadyTests = createSelector(
  getLabTestsList,
  getUserInfo,
  getCurrentUserAccountId,
  (labTests, userInfo, currentUserAccountId) => {
    const readyTests = labTests.filter(
      labTest =>
        !LabTest.isResulted(labTest) &&
        labTest.gvlLab_id === currentUserAccountId &&
        isReadyToSign(labTest) &&
        !labTest.nvslSubmittalDate
    );

    return flagDuplicateAccessions(readyTests, labTests);
  }
);

export const getAwaitingNvslTests = createSelector(
  getLabTestsList,
  getUserInfo,
  getCurrentUserAccountId,
  (labTests, userInfo, currentUserAccountId) =>
    labTests.filter(
      labTest =>
        labTest.gvlLab_id === currentUserAccountId &&
        labTest.nvslSubmittalDate &&
        !labTest.nvslResult
    )
);

export const getAttachNvslReportTests = createSelector(
  getLabTestsList,
  getUserInfo,
  getCurrentUserAccountId,
  (labTests, userInfo, currentUserAccountId) =>
    labTests.filter(
      labTest =>
        !LabTest.isResulted(labTest) &&
        labTest.gvlLab_id === currentUserAccountId &&
        labTest.nvslSubmittalDate &&
        labTest.nvslResult
    )
);

export const getNvslInboxTests = createSelector(getLabTestsList, labTests =>
  labTests.filter(labTest => labTest.nvslSubmittalDate && !labTest.nvslResult)
);

export const getTestSearchResults = createSelector(
  getLabTests,
  getSearchResultIds,
  (labTests, searchResultIds) =>
    searchResultIds
      ? searchResultIds.map(labTestId => labTests[labTestId])
      : null
);

export const getCertificateSearchResults = createSelector(
  getLabCertifcates,
  labCerts => (labCerts ? Object.values(labCerts) : null)
);

export const testSelector = (state, id) =>
  state.accounts.lab.dataLists.labTests.entities[id];

export const getLabTestLists = createStructuredSelector({
  network: getNetworkTests,
  pending: getPendingTests,
  ready: getReadyTests,
  awaitingNvslResult: getAwaitingNvslTests,
  attachNvslReport: getAttachNvslReportTests
});

export const labTestSelector = createSelector(
  getLabTestsList,
  // The component using this selector must have a `match.params.p1` prop.
  (_state, props) => parseInt(getRoute(props).id, 10),
  (labTests, id) => labTests.filter(test => test.id === id)[0]
);
