import { createAction } from 'utils/reduxUtil';
import {
  LOGIN_STARTED,
  LOGIN_INVALID,
  LOGIN_ACCOUNT_DISABLED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  LOGIN_CONNECTION_ERROR,
  LOGIN_VALIDATE_TOKEN_REQUEST,
  LOGIN_VALIDATE_TOKEN_STARTED,
  LOGIN_TOKEN_INVALID,
  LOGIN_EXPIRED_TOKEN_REQUEST,
  LOGIN_EXPIRED_TOKEN_STARTED,
  LOGIN_EXPIRED_TOKEN_SUCCESS,
  LOGIN_FETCH_USER_LICENSE_AGREEMENT,
  LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS,
  LOGIN_ACCEPT_USER_LICENSE_AGREEMENT
} from 'containers/app/store/login/loginConstants';

/* istanbul ignore file */

export const loginRequest = (username, password) =>
  createAction(LOGIN_REQUEST, {
    username,
    password
  });

export const loginSuccess = (authToken, username, roles, info) =>
  createAction(LOGIN_SUCCESS, {
    authToken,
    username,
    roles,
    info
  });

export const loginValidateTokenRequest = authToken =>
  createAction(LOGIN_VALIDATE_TOKEN_REQUEST, { authToken });

export const loginStarted = () => createAction(LOGIN_STARTED);
export const loginInvalid = () => createAction(LOGIN_INVALID);
export const loginConnectionError = () => createAction(LOGIN_CONNECTION_ERROR);
export const loginTokenInvalid = () => createAction(LOGIN_TOKEN_INVALID);
export const loginExpiredTokenRequest = () =>
  createAction(LOGIN_EXPIRED_TOKEN_REQUEST);
export const loginExpiredTokenStarted = () =>
  createAction(LOGIN_EXPIRED_TOKEN_STARTED);
export const loginExpiredTokenSuccess = () =>
  createAction(LOGIN_EXPIRED_TOKEN_SUCCESS);
export const loginAccountDisabled = () => createAction(LOGIN_ACCOUNT_DISABLED);
export const logoutRequest = () => createAction(LOGOUT_REQUEST);
export const logoutStarted = () => createAction(LOGOUT_STARTED);
export const logoutSuccess = () => createAction(LOGOUT_SUCCESS);
export const loginValidateTokenStarted = () =>
  createAction(LOGIN_VALIDATE_TOKEN_STARTED);

export const loginFetchUserLicenseAgreement = () =>
  createAction(LOGIN_FETCH_USER_LICENSE_AGREEMENT);
export const loginFetchUserLicenseAgreementSuccess = (
  content,
  versionNumber,
  id
) =>
  createAction(LOGIN_FETCH_USER_LICENSE_AGREEMENT_SUCCESS, {
    content,
    versionNumber,
    id
  });
export const loginAcceptUserLicenseAgreement = () =>
  createAction(LOGIN_ACCEPT_USER_LICENSE_AGREEMENT);
