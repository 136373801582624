// See README for info on Models:  https://github.com/globalvetlink/cricket-ui#models
import Model from 'models/Model';
import {
  boolRequired,
  stringRequired,
  maxLength,
  isNumber,
  numberInRange,
  postalCodeMatchesCountry,
  stateMatchesCountry
} from 'utils/validation/common';
import * as api from 'utils/api';

export default class Location extends Model {
  // Instance Methods

  validate() {
    const results = super.validate();
    // Put complex validations here.  If none, then delete this whole validate() method.

    // TODO: (do we really need this?)
    // let nationalIdError = this.housesAnimals && this.requireNationalId && !this.nationalId
    // if (nationalIdError) results.push(Model.formatError('Location problem', {messageId: 'validation.location.invalid.national.id', values: {nationalId: this.nationalId}}));

    return results;
  }

  // Static Methods

  static get fields() {
    return {
      ...super.fields,
      id: {
        initialValue: undefined
        // validate: (value) => {}
      },
      label: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 60);
        }
      },
      addressLine1: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 120);
        }
      },
      addressLine2: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 120);
        }
      },
      city: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      state: {
        initialValue: null,
        validate: (value, instance) => {
          // State abbreviations must match country ['Canada', 'USA', 'United States of America'];
          return (
            stringRequired(value, 'required') ||
            maxLength(value, 60) ||
            (instance
              ? stateMatchesCountry(instance.state, instance.country)
              : undefined)
          );
        }
      },
      postalCode: {
        initialValue: null,
        validate: (value, instance) => {
          // Postal code must match country ['Canada', 'USA', 'United States of America']
          return (
            stringRequired(value, 'required') ||
            maxLength(value, 15) ||
            (instance
              ? postalCodeMatchesCountry(instance.postalCode, instance.country)
              : undefined)
          );
        }
      },
      plus4: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 4);
        }
      },
      county: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 60);
        }
      },
      country: {
        initialValue: null,
        validate: value => {
          return stringRequired(value, 'required') || maxLength(value, 60);
        }
      },
      phone: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      fax: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 16);
        }
      },
      latitude: {
        initialValue: null,
        validate: value => {
          return value === undefined || value === null
            ? null
            : isNumber(value, 'validation.latitude.number') ||
                numberInRange(value, -90, 90);
        }
      },
      longitude: {
        initialValue: null,
        validate: value => {
          return value === undefined || value === null
            ? null
            : isNumber(value, 'validation.longitude.number') ||
                numberInRange(value, -180, 180);
        }
      },
      nationalId: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 15);
        }
      },
      locationId: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 255);
        }
      },
      isPrimary: {
        initialValue: false,
        validate: value => {
          boolRequired(value, 'validation.location.is.primary.required');
        }
      },
      premisesName: {
        initialValue: null,
        validate: value => {
          return !value ? null : maxLength(value, 60);
        }
      },
      version: {
        initialValue: null
        // validate: (value) => { }
      },
      housesAnimals: {
        initialValue: false,
        validate: value => {
          boolRequired(value, 'validation.location.houses.animals.required');
        }
      },
      origin_id: {
        initialValue: null
      },
      requireNationalId: {}
    };
  }

  static get apiPath() {
    return 'premises';
  }

  static get domain() {
    return 'Location';
  }

  async updatePremises(premises) {
    return await api.put(`/premises/${this.id}`, premises);
  }

  async deletePremises() {
    await api.deleteRec(`/premises/${this.id}`);
  }
}
