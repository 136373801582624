import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GvlSider from 'components/GvlSidebar/GvlSider';
import AdminMenu from 'containers/accounts/admin/AdminMenu';
import ClinicMenu from 'containers/accounts/clinic/ClinicMenu';
import RegulatoryMenu from 'containers/accounts/regulatory/RegulatoryMenu';
import LabMenu from 'containers/accounts/lab/LabMenu';
import MvlOwnerMenu from 'containers/accounts/mvlOwner/MvlOwnerMenu';
import DistributorMenu from 'containers/accounts/distributor/DistributorMenu';
import IsolatesMenu from 'containers/accounts/clinic/AutogenousVaccines/IsolatesMenu';
import * as userUtil from 'utils/userUtil';
import * as appAccounts from 'utils/appAccounts';
import { getEnvironment } from 'utils/envUtil';
import styled from '@emotion/styled';

const StyledEnvDiv = styled.div({
  position: 'absolute',
  bottom: 0,
  height: '35px',
  fontSize: '20px',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 'bold',
  lineHeight: '35px',
  color: '#FFF',
  backgroundColor: '#595959',
  width: '100%'
});

// TODO: this is a good candidate for a functional component
export default class SideMenu extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    logoutRequest: PropTypes.func.isRequired,
    collapsedChanged: PropTypes.func.isRequired
  };

  getUserAppMenu(user) {
    const appAccountForUser = userUtil.getAppAccountForUser(user);
    switch (appAccountForUser) {
      case appAccounts.APP_ACCOUNT_ADMIN:
        return AdminMenu;
      case appAccounts.APP_ACCOUNT_CLINIC:
        return ClinicMenu;
      case appAccounts.APP_ACCOUNT_LABTECH:
      case appAccounts.APP_ACCOUNT_NVSL_LABTECH:
        return LabMenu;
      case appAccounts.APP_ACCOUNT_REGULATORY:
        return RegulatoryMenu;
      case appAccounts.APP_ACCOUNT_MVL_OWNER:
      case appAccounts.APP_ACCOUNT_AGENT:
        return MvlOwnerMenu;
      case appAccounts.APP_DISTRIBUTOR_VFD:
        return DistributorMenu;
      case appAccounts.APP_ACCOUNT_ISOLATES:
        return IsolatesMenu;
      default:
        return null;
    }
  }

  render() {
    const { collapsedChanged, user, location, logoutRequest } = this.props;

    const UserAppMenu = this.getUserAppMenu(user);
    const environment = getEnvironment();
    let platformIndicator =
      environment !== 'production' ? (
        <StyledEnvDiv>{environment}</StyledEnvDiv>
      ) : null;

    return (
      <GvlSider
        collapsedChanged={collapsedChanged}
        user={user}
        logoutRequest={logoutRequest}
        accountMenuItem={userUtil.getAppAccountLinkForUser(user)}
      >
        {UserAppMenu ? (
          <UserAppMenu location={location} user={user} />
        ) : (
          <h3>Menu not found for user.</h3>
        )}
        {platformIndicator}
      </GvlSider>
    );
  }
}
