import { createAction } from 'utils/reduxUtil';
import * as types from 'modules/Billing/store/billingActionTypes';

export const actionInProgress = inProgress =>
  createAction(types.ACTION_IN_PROGRESS, { inProgress });

export const loadPaymentMethodsRequest = accountId =>
  createAction(types.LOAD_PAYMENT_METHODS_REQUEST, { accountId });

export const loadPaymentMethodsSuccess = methods =>
  createAction(types.LOAD_PAYMENT_METHODS_SUCCESS, { methods });

export const loadDefaultPaymentMethodRequest = accountId =>
  createAction(types.LOAD_DEFAULT_PAYMENT_METHOD_REQUEST, { accountId });
export const loadDefaultPaymentMethodSuccess = defaultPaymentMethod =>
  createAction(types.LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS, {
    defaultPaymentMethod
  });

export const loadBillingInformationRequest = accountId =>
  createAction(types.LOAD_BILLING_INFORMATION_REQUEST, { accountId });
export const loadBillingInformationSuccess = billingInformation =>
  createAction(types.LOAD_BILLING_INFORMATION_SUCCESS, { billingInformation });
