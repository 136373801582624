import React from 'react';
import { Layout } from 'antd';
import Logo from 'elements/Logo';
import intl from 'react-intl-universal';

import { THEME } from 'style';

const headerStyle = {
  color: THEME.white()
};

// TODO: Add property and logic to watch viewMode for mobile an adjust css as needed.
export default function ConnectionError() {
  return (
    <Layout>
      <Layout.Content
        style={{
          background: THEME.blue(),
          color: THEME.white(),
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <Logo size="medium" type="white" width="130px" />
        <div style={{ marginTop: '150px', marginLeft: '130px' }}>
          <h1 className="title" style={headerStyle}>
            {intl.get('connectionError.title')}
          </h1>
          <h1 style={headerStyle}>{intl.get('connectionError.heading')}</h1>
          <p>
            {intl.get('connectionError.body')}{' '}
            <a
              href="tel:+1-515-817-5704"
              style={{ color: THEME.white(), textDecoration: 'underline' }}
            >
              (515) 817-5704
            </a>
          </p>
        </div>
      </Layout.Content>
    </Layout>
  );
}
