import Model from 'models/Model';
import {
  genericArrayRequired,
  objectRequiredWithId,
  objectRequiredWithField,
  stringRequired,
  maxLength,
  arrayElementsMaxLength
} from 'utils/validation/common';
import * as api from 'utils/api';

export default class NonAdjacentHerdRequest extends Model {
  validate() {
    const results = super.validate();
    return results;
  }

  static get fields() {
    return {
      ...super.fields,
      validationLevel: {
        // This property will not be persisted and is only used to provide varying levels of validation feedback eg minimum required to save vs sign
        initialValue: 'save'
      },
      id: {
        initialValue: undefined
      },
      product: {
        initialValue: undefined,
        validate: product => objectRequiredWithField(product, 'productId')
      },
      herdList: {
        initialValue: undefined,
        validate: (herdList, instance) => {
          if (instance.validationLevel === 'sign') {
            return objectRequiredWithField(herdList, 'herdListId');
          }
        }
      },
      usageStates: {
        initialValue: undefined
      },
      clinic: {
        initialValue: undefined,
        validate: clinic => objectRequiredWithId(clinic)
      },
      alternateClinicAddress: {
        initialValue: undefined
      },
      vet: {
        initialValue: undefined
      },
      clinicContactEmails: {
        initialValue: undefined,
        validate: emails => genericArrayRequired(emails, 'required')
      },
      status: {
        initialValue: ''
      },
      productDescription: {
        initialValue: '',
        validate: productDescription =>
          stringRequired(productDescription, 'required')
      },
      additionalDetails: {
        initialValue: ''
      },
      requestFeedback: {
        initialValue: '',
        validate: (requestFeedback, instance) => {
          if (instance.validationLevel === 'sendBack') {
            return stringRequired(requestFeedback, 'required');
          }
        }
      },
      acknowledgedDate: {
        initialValue: undefined
      },
      approvedDate: {
        initialValue: undefined
      },
      epidemiologicalLinks: {
        initialValue: undefined,
        validate: (epidemiologicalLinks, instance) => {
          if (instance.validationLevel === 'sign') {
            return genericArrayRequired(epidemiologicalLinks, 'required');
          }
        }
      },
      clinicalObservations: {
        initialValue: undefined,
        validate: (clinicalObservations, instance) => {
          if (instance.validationLevel === 'sign') {
            return genericArrayRequired(clinicalObservations, 'required');
          }
        }
      },
      requestedDosage: {
        initialValue: ''
      },
      vaccinationSchedule: {
        initialValue: undefined,
        validate: vaccinationSchedule =>
          genericArrayRequired(vaccinationSchedule, 'required')
      },
      dateCreated: {
        initialValue: undefined
      },
      lastUpdated: {
        initialValue: undefined
      },
      // Fields only populated before saving
      clinicId: {
        initialValue: undefined,
        validate: (clinicId, instance) => {
          if (instance.validationLevel === 'sendToClinic') {
            return stringRequired(clinicId, 'required');
          }
        }
      },
      vetId: {
        initialValue: undefined,
        validate: (vetId, instance) => {
          if (instance.validationLevel === 'sendToClinic') {
            return stringRequired(vetId, 'required');
          }
        }
      },
      productId: {
        initialValue: undefined,
        validate: (productId, instance) => {
          if (instance.validationLevel === 'sendToClinic') {
            return stringRequired(productId, 'required');
          }
        }
      },
      labNote: {
        initialValue: undefined,
        validate: note => (note ? maxLength(note, 240) : null)
      },
      labTags: {
        initialValue: undefined,
        validate: tags =>
          tags?.length ? arrayElementsMaxLength(tags, 30) : null
      },
      // This field is to check if the non-adjacent request should be Ready to Sign
      isMarkReadytoSign: {
        initialValue: false
      },
      isolates: {
        initialValue: undefined
      },
      dosageReferenceLabel: {
        initialValue: undefined,
        validate: dosageReferenceLabel =>
          dosageReferenceLabel ? maxLength(dosageReferenceLabel, 7) : null
      }
    };
  }

  static get apiPath() {
    return 'autogenousVaccines/nonAdjacentHerdRequest';
  }

  static get domain() {
    return 'NonAdjacentHerdRequest';
  }

  static list = async params => await super.list(params);

  static read = async id => await super.read(id);

  static deleteRec = async id => await super.deleteRec(id);

  async sendToClinic() {
    let result;
    result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/sendToClinic`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }
  async updateHerdListInformation() {
    let result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/updateHerdListInformation`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }

  async sendToStateVet() {
    let result;
    result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/sendToStateVet`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }

  static signNARequest = async (id, username, password) => {
    let result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${id}/signNARequest`,
      {
        username,
        password
      }
    );
    return result;
  };

  async returnToLab() {
    let result;
    result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/returnToLab`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }

  async approveNaRequest() {
    let result;
    result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/approveNARequest`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }

  static markNaRequestComplete = async id =>
    await api.put(`${this.apiPath}/${id}/markNaRequestComplete`);

  async updateTagsAndNotes() {
    let result = await api.put(
      `${NonAdjacentHerdRequest.apiPath}/${this.id}/updateTagsAndNotes`,
      this.toJson()
    );
    this.constructor.initialize(this, result);
  }
}
