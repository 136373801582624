import React, { Component } from 'react';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, Form, Input, Modal, notification } from 'antd';
import PropTypes from 'prop-types';
import { IsolateExtensionModel as IsolateExtension } from 'modules/Isolate/isolateIndex';
import { extensionChangesRequest } from 'modules/Isolate/store/isolateActions';

const { TextArea } = Input;
const FormItem = Form.Item;

class ExtensionRequestChangesModalComponent extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    extensionChangesRequest: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }),
    isolate: PropTypes.object.isRequired
  };

  requestChangesOnExtension = () => {
    this.props.form.validateFields({ force: true }, async (err, formValues) => {
      const { isolate, history, extensionChangesRequest } = this.props;
      if (err) {
        console.warn('save form err:', err);
        return;
      }

      const extension = new IsolateExtension({
        ...isolate.extensionRequest,
        isolateId: isolate.isolateId,
        ...formValues
      });

      const modelValidationErrors = extension.validate();
      if (modelValidationErrors) {
        console.warn('save modelValidation err:', modelValidationErrors);
        notification.warning({
          message: intl.get('error.saving.noun', {
            noun: intl.get('isolate.extension.lc')
          }),
          description: intl.get('form.entries.error')
        });
        return;
      }

      extensionChangesRequest(extension, history);
    });
  };

  render() {
    const { visible, form, closeModal } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={intl.get('extension.sendBack.withComments')}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            {intl.get('cancel')}
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={this.requestChangesOnExtension}
          >
            {intl.get('extension.sendBack')}
          </Button>
        ]}
      >
        <Form layout="vertical">
          <div>{intl.get('extension.sendBack.body')}</div>
          <br />
          <FormItem label={intl.get('comments')}>
            {form.getFieldDecorator('requestFeedback', {
              rules: [{ required: true, message: intl.get('required') }]
            })(<TextArea rows={4} />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  extensionChangesRequest
};

const ExtensionRequestChangesModalForm = Form.create()(
  ExtensionRequestChangesModalComponent
);

const ExtensionRequestChangesModal = connect(
  null,
  mapDispatchToProps
)(ExtensionRequestChangesModalForm);

export {
  ExtensionRequestChangesModal as default,
  ExtensionRequestChangesModalComponent
};
