import React from 'react';
import { Dropdown } from 'antd';
import { isMobile } from 'react-device-detect';

function MobileAwareDropdown(props) {
  return <Dropdown trigger={[isMobile ? 'click' : 'hover']} {...props} />;
}

MobileAwareDropdown.propTypes = {};

export default MobileAwareDropdown;
