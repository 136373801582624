import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './noteActionTypes';

const initialState = Immutable.from({
  data: {},
  isRefreshing: false
});

export const onIsRefreshing = (state, { isRefreshing }) =>
  Immutable.setIn(state, ['isRefreshing'], isRefreshing);

export const onListSuccess = (state, { referenceId, notes }) => {
  let newState = state.asMutable({ deep: true });

  newState.data = {
    ...newState.data,
    [referenceId]: notes
  };

  return Immutable.from(newState);
};

export const noteReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsRefreshing,
  [types.LIST_SUCCESS]: onListSuccess
});
