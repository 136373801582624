export const USER_UPDATE = 'cricket/App/User/USER_UPDATE';
export const USER_RESET = 'cricket/App/User/USER_RESET';
export const USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS =
  'cricket/App/User/USER_ACCEPT_LICENSE_AGREEMENT_SUCCESS';
// Notifications
export const USER_NOTIFICATIONS_REQUEST =
  'cricket/App/User/USER_NOTIFICATIONS_REQUEST';
export const USER_REFRESH_NOTIFICATIONS_STARTED =
  'cricket/App/User/USER_REFRESH_NOTIFICATIONS_STARTED';
export const USER_NOTIFICATIONS_SUCCESS =
  'cricket/App/User/USER_NOTIFICATIONS_SUCCESS';
export const USER_NOTIFICATIONS_FAILURE =
  'cricket/App/User/USER_NOTIFICATIONS_FAILURE';

export const USER_TOGGLE_SMART_ENGINE_STATUS =
  'cricket/Accounts/Clinic/USER_TOGGLE_SMART_ENGINE_STATUS';

export const UPDATE_USER_SETTINGS = 'cricket/App/User/UPDATE_USER_SETTINGS';

export const USER_GET_PROFILE_REQUEST =
  'cricket/App/User/USER_GET_PROFILE_REQUEST';
export const USER_GET_PROFILE_SUCCESS =
  'cricket/App/User/USER_GET_PROFILE_SUCCESS';
export const USER_GET_PROFILE_FAILURE =
  'cricket/App/User/USER_GET_PROFILE_FAILURE';
export const USER_UPDATE_PROFILE_REQUEST =
  'cricket/App/User/USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_DONE =
  'cricket/App/User/USER_UPDATE_PROFILE_DONE';
export const USER_GET_INFO_SUCCESS = 'cricket/App/User/USER_GET_INFO_SUCCESS';
