/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import appReducer from 'containers/app/store/appReducer';
import { getLanguage } from 'utils/appUtil';
import accountsReducer from 'containers/accounts/store/accountsReducer';
import registrationReducer from 'containers/registration/store/registrationReducer';
import Immutable from 'seamless-immutable';

export default function rootReducer() {
  const rootReducer = combineReducers({
    app: appReducer(),
    accounts: accountsReducer(),
    registration: registrationReducer(),
    language: () =>
      Immutable.from({
        locale: getLanguage()
      })
  });

  return rootReducer;
}
