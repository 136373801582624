/* eslint-disable react/display-name */
import React from 'react';
import intl from 'react-intl-universal';
import { generateAlphaSorter, generateNumberSorter } from 'utils/sortHelpers';
import TableActionsMenu from 'components/GvlTable/TableActionsMenu';
import {
  hasFeatureAccess,
  FeatureEnum
} from 'FeatureAccess/FeatureAccessService';

const herdStateColumn = () => ({
  title: intl.get('herd.state'),
  dataIndex: 'state',
  render: (_text, record, _index) => (
    <React.Fragment>
      {record.state}{' '}
      <i>
        ({record.totalLocations}{' '}
        {record.totalLocations > 1
          ? intl.get('locations.lc')
          : intl.get('location.lowerCase')}
        )
      </i>
    </React.Fragment>
  ),
  sorter: generateAlphaSorter('state')
});

const totalHeadCountColumn = () => ({
  title: intl.get('total.head.count.byState'),
  dataIndex: 'totalHeadCount',
  render: (_text, record, _index) =>
    record.totalHeadCount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
  sorter: generateNumberSorter('totalHeadCount')
});

const premiseIdColumn = () => ({
  title: intl.get('premiseID'),
  dataIndex: 'premisesId',
  render: (_text, record, _index) => record.contactLocation?.premisesId,
  sorter: generateAlphaSorter('premisesId')
});

const producerColumn = () => ({
  title: intl.get('isolate.producer'),
  dataIndex: 'producerName',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        <div className="semibold">{record.producer?.name}</div>
        <div>{record.producer?.phone}</div>
      </React.Fragment>
    );
  },
  sorter: generateAlphaSorter('producerName')
});

const contactLocationColumn = () => ({
  title: intl.get('contact.location'),
  dataIndex: 'contactLocation',
  render: (_text, record, _index) => {
    return (
      <React.Fragment>
        {record.contactLocation?.addressLine1 && (
          <div>{record.contactLocation?.addressLine1}</div>
        )}
        {(record.contactLocation?.city ||
          record.contactLocation?.state ||
          record.contactLocation?.postalCode) && (
          <div>
            {record.contactLocation?.city}
            {record.contactLocation?.state
              ? `, ${record.contactLocation?.state}`
              : null}{' '}
            {record.contactLocation?.postalCode}
          </div>
        )}
        {(record.contactLocation?.latitude ||
          record.contactLocation?.longitude) && (
          <div>{`(${record.contactLocation?.latitude}, ${record.contactLocation?.longitude})`}</div>
        )}
      </React.Fragment>
    );
  },
  sorter: generateAlphaSorter(record =>
    record.contactLocation.addressLine1
      ? `${record.contactLocation.addressLine1} ${record.contactLocation.city}, ${record.contactLocation.state} ${record.contactLocation.postalCode}`
      : `(${record.contactLocation.latitude}, ${record.contactLocation.longitude}) ${record.contactLocation.state}`
  )
});

const herdSizeColumn = () => ({
  title: intl.get('herd.size'),
  dataIndex: 'headCount',
  render: (_text, record, _index) =>
    record.headCount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
  sorter: generateNumberSorter('headCount')
});

const speciesColumn = () => ({
  title: intl.get('species'),
  dataIndex: 'species',
  render: (_text, record, _index) => record.species,
  sorter: generateAlphaSorter('species')
});

const vetColumn = vet => ({
  title: intl.get('vet'),
  dataIndex: 'vet',
  render: (_text, record, _index) => vet.commonName,
  sorter: generateAlphaSorter(() => vet.commonName)
});

const commentsColumn = () => ({
  title: intl.get('comments'),
  dataIndex: 'comments',
  render: (_text, record, _index) => record.comments,
  sorter: generateAlphaSorter('comments')
});

const actionColumn = onActionClicked => ({
  title: intl.get('action'),
  width: 100,
  render: (_text, record, _index) => {
    const actions = [
      {
        type: 'edit',
        text: intl.get('edit.herd.information')
      },
      {
        type: 'delete',
        text: intl.get('delete.herd.information'),
        highlightDanger: true
      }
    ];
    return (
      <TableActionsMenu
        actionInfo={actionInfo(actions, onActionClicked)}
        onClickData={[record]}
      />
    );
  }
});

const actionInfo = (actions, onActionClicked) => {
  return {
    actionsTitle: intl.get('actions'),
    onActionClicked: onActionClicked,
    allowMultiSelect: false,
    actions
  };
};

const generateColumns = (currentUser, enableEdit, vet, onActionClicked) => {
  return hasFeatureAccess(
    // To restrict action column access for reg users
    currentUser,
    FeatureEnum.NonAdjacentHerdList,
    'update'
  ) && enableEdit
    ? {
        stateList: [herdStateColumn(), totalHeadCountColumn()],
        herdList: [
          premiseIdColumn(),
          producerColumn(),
          contactLocationColumn(),
          herdSizeColumn(),
          speciesColumn(),
          vetColumn(vet),
          commentsColumn(),
          actionColumn(onActionClicked)
        ]
      }
    : {
        stateList: [herdStateColumn(), totalHeadCountColumn()],
        herdList: [
          premiseIdColumn(),
          producerColumn(),
          contactLocationColumn(),
          herdSizeColumn(),
          speciesColumn(),
          vetColumn(vet),
          commentsColumn()
        ]
      };
};

const exportFunctions = {
  generateColumns,
  herdStateColumn,
  totalHeadCountColumn,
  premiseIdColumn,
  producerColumn,
  contactLocationColumn,
  herdSizeColumn,
  speciesColumn,
  vetColumn,
  commentsColumn,
  actionColumn
};

export default exportFunctions;
