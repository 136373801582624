// This store holds data used by Admin accounts
import Immutable from 'seamless-immutable';
import { createReducer } from 'utils/reduxUtil';
import {
  ADMIN_LOAD_STARTUP_DATA_SUCCESS,
  ADMIN_SET_RETRIEVING_STARTUP_DATA,
  // Accounts
  ADMIN_ACTIVATE_ACCOUNT,
  // Subscription
  ADMIN_ACTIVATE_AR_SUBSCRIPTION,
  ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS,
  // Generic
  ADMIN_ACTIVATE_DOMAIN,
  ADMIN_DOMAIN_ACTION_SUCCESS,
  ADMIN_SET_REFRESH_DOMAIN
} from 'containers/accounts/admin/store/accountsAdminConstants';

export const initialState = Immutable.from({
  retrievingStartupData: false,
  startupDataRetrieved: false,
  startupData: {
    features: null,
    promoRatePlanCodes: null,
    ratePlanCodes: null,
    sysFeatureSets: null,
    nonElectronicPaymentMethods: null
  },
  dataLists: {
    account: {
      data: {
        accounts: {}
      },
      refreshing: false
    },
    accountsReceivable: {
      refreshing: false
    },
    subscription: {
      refreshing: false
    },
    arSubscription: {
      data: {
        arSubscriptions: {}
      },
      refreshing: false
    },
    clinic: {
      data: {
        clinics: {}
      },
      refreshing: false
    },
    lab: {
      data: {
        labs: {}
      },
      refreshing: false
    },
    distributor: {
      data: {
        distributors: {}
      },
      refreshing: false
    },
    pharma: {
      data: {
        pharmas: {}
      },
      refreshing: false
    }
  }
});

export const onAdminLoadStartupDataSuccess = (state, startupData) =>
  state.merge({
    startupDataRetrieved: true,
    startupData: state.startupData.merge({
      features: startupData.features,
      promoRatePlanCodes: startupData.subscriptionLookups.promoRatePlanCodes,
      ratePlanCodes: startupData.subscriptionLookups.ratePlanCodes,
      sysFeatureSets: startupData.subscriptionLookups.sysFeatureSets,
      nonElectronicPaymentMethods:
        startupData.subscriptionLookups.nonElectronicPaymentMethods
    })
  });

export const onAdminSetRetrievingStartupData = (state, payload) =>
  state.merge({
    retrievingStartupData: payload.value
  });

// Accounts

export const onAdminActivateAccount = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'account', 'refreshing'],
    payload.id && !(payload.id in state.dataLists.account.data.accounts)
  );

// Subscriptions

export const onAdminActivateArSubscription = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', 'arSubscription', 'refreshing'],
    payload.id &&
      !(
        payload.arSubscriptionId in
        state.dataLists.arSubscription.data.arSubscriptions
      )
  );

export const onAdminRefreshArSubscriptionSuccess = (state, payload) => {
  const arRatePlanId = payload.arSubscription.ratePlans.find(
    it => it.isProduct === true
  ).id;
  return Immutable.setIn(
    state,
    ['dataLists', 'arSubscription', 'data', 'arSubscriptions', arRatePlanId],
    payload.arSubscription
  );
};

// Generic

export const onAdminActivateDomain = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', payload.domain, 'refreshing'],
    payload.id &&
      !(
        payload.id in state.dataLists[payload.domain].data[payload.domain + 's']
      )
  );

export const onAdminSetRefreshDomain = (state, payload) =>
  Immutable.setIn(
    state,
    ['dataLists', payload.domain, 'refreshing'],
    payload.value
  );

export const onAdminDomainActionSuccess = (state, payload) => {
  return Immutable.setIn(
    state,
    [
      'dataLists',
      payload.domain,
      'data',
      payload.domain + 's',
      payload.model.id
    ],
    payload.model
  );
};

const accountsAdminReducer = createReducer(initialState, {
  [ADMIN_LOAD_STARTUP_DATA_SUCCESS]: onAdminLoadStartupDataSuccess,
  [ADMIN_SET_RETRIEVING_STARTUP_DATA]: onAdminSetRetrievingStartupData,
  // Accounts
  [ADMIN_ACTIVATE_ACCOUNT]: onAdminActivateAccount,
  // Subscriptions
  [ADMIN_ACTIVATE_AR_SUBSCRIPTION]: onAdminActivateArSubscription,
  [ADMIN_REFRESH_AR_SUBSCRIPTION_SUCCESS]: onAdminRefreshArSubscriptionSuccess,
  // Generic
  [ADMIN_ACTIVATE_DOMAIN]: onAdminActivateDomain,
  [ADMIN_DOMAIN_ACTION_SUCCESS]: onAdminDomainActionSuccess,
  [ADMIN_SET_REFRESH_DOMAIN]: onAdminSetRefreshDomain
});
export default accountsAdminReducer;
