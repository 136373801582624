import React from 'react';
import { Menu, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import GvlIcon from 'elements/GvlIcon';
import { THEME, FONT } from 'style';

const { SubMenu } = Menu;
const { Text } = Typography;

// TODO: Need to simplify menu item styles. This could and should be refactored post project Helen completion.

const StyledSubMenu = styled(SubMenu, { shouldForwardProp: () => true })({
  color: THEME.text() + '!important',
  fontSize: FONT.size.medium,
  fontWeight: FONT.weight.bold,
  lineHeight: '32px'
});

const StyledIcon = styled(GvlIcon)({
  fontSize: '20px !important',
  marginRight: '12px !important',
  color: THEME.text() + '!important'
});

const StyledText = styled(Text)({
  color: THEME.text() + '!important'
});

export default function GvlSubMenu({
  children,
  title,
  icon,
  superScript,
  ...rest
}) {
  return (
    <StyledSubMenu
      mode="inline"
      theme="light"
      title={
        // TODO: This is to get around not being able to manage title component.
        // The padding on the left size causes the div to overscroll.
        <span>
          <StyledIcon type={icon} />
          <StyledText>{title}</StyledText>
          {superScript ? <sup>{superScript}</sup> : null}
        </span>
      }
      {...rest}
    >
      {children}
    </StyledSubMenu>
  );
}

GvlSubMenu.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  superScript: PropTypes.string
};
