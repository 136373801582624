import { createReducer } from 'utils/reduxUtil';
import Immutable from 'seamless-immutable';
import * as types from './isolateActionTypes';
import Isolate from 'modules/Isolate/Isolate';

// store structure
const initialState = Immutable.from({
  data: {},
  auditReport: {},
  isRefreshing: false,
  lastSuccessfulRefreshedTime: null,
  refreshFailure: false,
  searchResults: {},
  defaultDiagnosticLab: {}
});

export const onIsolateRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshing'], payload.isRefreshing);

export const onStoreDefaultDiagnosticLab = (state, payload) =>
  Immutable.setIn(
    state,
    ['defaultDiagnosticLab'],
    payload.defaultDiagnosticLab
  );

export const onIsolateListSuccess = (state, payload) => {
  const { isolates } = payload;
  let newState = state.asMutable({ deep: true });

  /*
  This simply adds to the existing list.
  Earlier implementation cleared out the list first,
  which is no longer happening anywhere.
  TODO: Do we still want to clear out the list,
  potentially as a first step in the list request?
  */
  for (const isolate of isolates) {
    newState.data[isolate.isolateId] = isolate;
  }
  newState.lastSuccessfulRefreshedTime = new Date().getTime();
  newState.refreshFailure = false;

  return Immutable.from(newState);
};

export const onIsolateListFailure = state =>
  state.merge({
    refreshFailure: true,
    lastSuccessfulRefreshedTime: null
  });

export const onIsolateSearchSuccess = (state, { isolates }) => {
  let searchResults = {};
  isolates.forEach(isolate => (searchResults[isolate.isolateId] = isolate));
  return state.merge({
    searchResults
  });
};

export const onIsolateSearchFailure = state =>
  state.merge({
    searchResults: {}
  });

export const onActivateIsolate = (state, payload) =>
  Immutable.setIn(
    state,
    ['isRefreshing'],
    payload.isolateId && !(payload.isolateId in state.data)
  );

export const onRefreshIsolateSuccess = (state, payload) => {
  const existingExtensionRequest =
    state?.data?.[payload?.isolate?.isolateId]?.extensionRequest;
  const newExtensionRequest = payload?.isolate?.extensionRequest;
  let newState = Immutable.setIn(
    state,
    ['data', payload.isolate.isolateId],
    payload.isolate
  );
  if (!!existingExtensionRequest && !newExtensionRequest) {
    // there was an extension request, now there is not. Also remove the reference to it from shared isolates.
    Object.values(state.data).forEach(isolate => {
      if (
        isolate.extensionRequest?.isolateExtensionId ===
        existingExtensionRequest.isolateExtensionId
      ) {
        let mutableIsolate = isolate.asMutable();
        mutableIsolate.extensionRequest = null;
        mutableIsolate.status = Isolate.calculateStatus(mutableIsolate);
        newState = Immutable.setIn(
          newState,
          ['data', isolate.isolateId],
          mutableIsolate
        );
      }
    });
  }
  return newState;
};

export const onRefreshIsolatesForProductSuccess = (state, payload) => {
  let isolates = {};
  Object.values(payload.isolates).forEach(
    isolate => (isolates[isolate.isolateId] = isolate)
  );
  const updatedIsolates = state.data.merge(isolates);
  return state.merge({
    data: updatedIsolates
  });
};

export const onRemoveIsolateFromStore = (state, { isolateId }) =>
  state.updateIn(['data'], data => data.without(isolateId));

export const onIsolateAuditReportSuccess = (state, payload) =>
  Immutable.setIn(state, ['auditReport', payload.isolateId], payload.events);

export const onIsolateEventsRefreshing = (state, payload) =>
  Immutable.setIn(state, ['isRefreshingEvents'], payload.isRefreshingEvents);

export const isolateReducer = createReducer(initialState, {
  [types.IS_REFRESHING]: onIsolateRefreshing,
  [types.LIST_SUCCESS]: onIsolateListSuccess,
  [types.LIST_FAILURE]: onIsolateListFailure,
  [types.SEARCH_SUCCESS]: onIsolateSearchSuccess,
  [types.SEARCH_FAILURE]: onIsolateSearchFailure,
  [types.ACTIVATE_ISOLATE]: onActivateIsolate,
  [types.REFRESH_SUCCESS]: onRefreshIsolateSuccess,
  [types.REFRESH_ISOLATES_FOR_PRODUCT_SUCCESS]: onRefreshIsolatesForProductSuccess,
  [types.REMOVE_ISOLATE_FROM_STORE]: onRemoveIsolateFromStore,
  [types.AUDIT_REPORT_SUCCESS]: onIsolateAuditReportSuccess,
  [types.IS_REFRESHING_EVENTS]: onIsolateEventsRefreshing,
  [types.DEFAULT_DIAGNOSTIC_LAB_REQUEST]: onStoreDefaultDiagnosticLab
});
