import React from 'react';
import PropTypes from 'prop-types';
import IsolateVal from 'elements/AutogenousVaccines/IsolateVal';
import IsolateValWithIcon from 'elements/AutogenousVaccines/IsolateValWithIcon';

export default function NewportClinicBillingAddress({ clinic }) {
  return (
    <IsolateVal
      name="account.billingaddress"
      val={
        <IsolateValWithIcon
          icon="environment"
          val={
            <React.Fragment>
              <div>{clinic?.billingAddress?.address_line_1}</div>
              <div>
                {/* Some addresses only have city and sometimes a postal code if address is a PO Box */}
                {clinic?.billingAddress?.city}
                {clinic?.billingAddress?.state
                  ? `, ${clinic.billingAddress.state}`
                  : null}{' '}
                {clinic?.billingAddress?.postal_code}
              </div>
            </React.Fragment>
          }
        />
      }
    />
  );
}

NewportClinicBillingAddress.propTypes = {
  clinic: PropTypes.object.isRequired
};
