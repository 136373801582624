import * as actions from './clinicActions';
import * as types from './clinicActionTypes';
// import { Clinic } from 'modules/Clinic/clinicIndex';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiFailure } from 'containers/app/store/data/dataActions';
import * as api from 'utils/api';

export function* onListVetsForClinic({ clinicId }) {
  try {
    yield put(actions.isRefreshing(true));
    const vetsList = yield call(api.get, `/clinic/${clinicId}/vets`);
    yield put(actions.listVetsForClinicSuccess(clinicId, vetsList));
  } catch (err) {
    yield put(apiFailure('Error loading vets for clinic'));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export function* onSavePracticeType({ clinicId, params, callback }) {
  try {
    yield put(actions.isRefreshing(true));
    yield call(api.put, `/clinic/${clinicId}/practiceType`, params);
    callback();
  } catch (err) {
    yield put(apiFailure(err));
  } finally {
    yield put(actions.isRefreshing(false));
  }
}

export default function* clinicSagas() {
  yield takeLatest(types.LIST_VETS_FOR_CLINIC_REQUEST, onListVetsForClinic);
  yield takeLatest(types.SAVE_PRACTICE_TYPE, onSavePracticeType);
}
